const AllCreations = `X:1
T:Boss Battle Frenzy
C:OrchestrAI
M:12/8
L:1/8
Q:1/4=170
K:Emin
% A dynamic and powerful tune fit for an exciting video game boss battle
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"Em"B3 B2B B2c B2B|"D"A3 A2F D2E F2D|"C"G3 G2E C2D E2G|"B"B3 z3 B3 z3|
"Em"B3 B2B B2c B2B|"D"A3 A2F D2E F2D|"C"G3 F3 G3 A3|"B"B6 B3 z3:|
V:2 clef=treble name="String Ensemble"
%%MIDI program 48
|:"Em"e3 e3 g3 g3|"D"f3 d3 A3 f3|"C"G3 E3 c3 E3|"B"B3 B3 z3 z3|
"Em"e3 e3 g3 g3|"D"f3 d3 A3 f3|"C"G3 F3 G3 A3|"B"B6 B3 z3:|
V:3 clef=treble name="Brass Section"
%%MIDI program 61
|:"Em"E3 E3 E3 E3|"D"D3 D3 F3 A3|"C"C3 E3 G3 C3|"B"B,3 B,3 B,3 B,3|
"Em"E3 E3 E3 E3|"D"D3 D3 F3 A3|"C"C3 C3 C3 C3|"B"B,6 B,3 z3:|
V:4 clef=bass name="Electric Bass"
%%MIDI program 33
|:"Em"E,3 z3 E,3 z3|"D"D3 z3 A,3 z3|"C"C3 z3 G,3 z3|"B"B,3 z3 F#3 z3|
"Em"E,3 z3 E,3 z3|"D"D3 z3 A,3 z3|"C"C3 z3 G,3 z3|"B"B,6 B,3 z3:|



X:1
T:Tribute to Successors
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:G
V:1 clef=treble name="Lead Voice"
%%MIDI program 73
|: "G"G2 B2 d4 | "C"e4 e2 d2 | "D"B4 A3 G | "G"G2 A2 B2 G2 |
"G"G2 g2 f4 | "C"e2 d2 B2 c2 | "D"d2 e2 "C"g4 |1 "G"G4 z2 G2 :|2 "G"G4 z2 A2 |
|: "C"c2 e2 a3 g | "G"f2 g2 e2 d2 | "D"f2 a2 b2 a2 | "G"g4 z2 G2 |
"C"c2 c2 B3 A | "G"G2 A2 B2 d2 | "D"d2 e2 e2 d2 |1 "G"B2 G2 A2 G2 :|2 "G"G4 z2 z2 |
V:2 clef=treble name="Harmony"
%%MIDI program 48
|: "G"D2 F2 A4 | "C"C6 B2 | "D"A4 G3 F | "G"G2 F2 G2 D2 |
"G"D2 F2 E4 | "C"C2 B2 G2 A2 | "D"A2 B2 "C"E4 |1 "G"D4 z2 D2 :|2 "G"D4 z2 F2 |
|: "C"G2 c2 e3 d | "G"E2 F2 D2 C2 | "D"E2 G2 A2 G2 | "G"F4 z2 D2 |
"C"G2 G2 F3 E | "G"D2 E2 F2 A2 | "D"A2 B2 B2 A2 |1 "G"C2 G2 F2 D2 :|2 "G"D4 z2 z2 |
V:3 clef=bass name="Bass"
%%MIDI program 32
|: "G"G,4 D4 | "C"C4 E2 D2 | "D"D4 C3 B, | "G"G,2 A,2 D2 G,2 |
"G"G,2 B,2 A3 z | "C"C4 E3 F | "D"D3 E "C"C4 |1 "G"G,4 z2 G,2 :|2 "G"G,4 z2 A,2 |
|: "C"C2 E2 G3 F | "G"G,2 A,2 G,3 z | "D"D2 D2 D2 D2 | "G"G,4 z2 G,2 |
"C"C2 G2 F3 E | "G"G,2 F2 G2 B, z | "D"D3 E E2 D2 |1 "G"A,2 G,2 A,2 G,2 :|2 "G"G,4 z2 z2 |



X:1
T:Weird Core Pulsar
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=140
K:C
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|: "^~"C8 z E6 D2  | "^~"B,8 z G6 F2  | "^~"A16 | "^*"A,8 z E6 D2  |
"^~"G8 z C6 B,2  | "^~"F16 | "^*"F,8 z C6 B,2  | "^~"E8 z A4 G2 F2  |
"^~""C^G"E2G2 A4 -A2 z2 G4 | "^~"E2"_*"D2 C4 -C2 z2 "_#"F2 E2 | "^~"G2B,2 D4 -D2 z2 A,4 | "^~"B,2"^#"F2 E4 -E2 z2 "^*"G2 F2 |
"^~"E8 -E2 "^~"G,4 -G,3 "^~" | "^*"G,8 -G,4 A,4 | "^~"B,8 -B,2 "^~"E4 -E,3 "^~" | "^~"A,16 :|
V:2 clef=treble name="Electro Bass"
%%MIDI program 38
|: "^~"C,8 z C7 | "^~"B,,8 z B,7 | "^~"A,8 z A,7 | "^*"G,,8 z G,7 |
"^~"F,16 | "^*"E,8 z E,7 | "^~"D,8 z D,7 | "^~"C,16 |
"^~"C,16 | "^*"B,,16 | "^~"A,8 z A,7 | "^~"B,8 z B,7 |
"^~"G,8 z G,7 | "^*"F,8 z F,7 | "^~"E,8 z E,7 | "^~"D,16 :|
V:3 clef=bass name="Poly Synth"
%%MIDI program 91
|: "^~"C,2E,2 F,4 z4 C,4 | "^~"B,,2D,2 E,4 z4 B,,4 | "^~"A,,2C,2 D,4 z4 A,,4 | "^*"G,,2B,,3 z8 z3 |
"^~"F,4 A,4 z4 "^*"F,4 | "^~"E,4 G,4 z4 "^*"E,4 | "^~"D,4 F,4 z4 "^~"D,4 | "^~"C,8 z C,4 E,3 |
"^~""^G"z2 G,2 E,2 C,2 z4 z4 | "^*"z2 "^#"F,2 D,2 B,,2 z4 z4 | "^~"z2 E,4 -C,2 z4 z4 | "^~"z4 B,,4 z4 z4 |
"^~"z2 A,,2 "^~"G,,2 z4 z6 | "^*"z2 z4 "^~"F,4 z4 z2 | "^~"z4 -"^*"[DF,]4 z4 z4 | "^~"z2 "^~"E,2 C,4 z4 z4 :|
V:4 clef=bass name="Rhythm Synth"
%%MIDI program 89
|: "^~"C,8 z8 | "^~"B,,8 z8 | "^~"A,8 z8 | "^*"G,,8 z8 |
"^~"F,8 z8 | "^*"E,8 z8 | "^~"D,8 z8 | "^~"C,8 z8 |
"^~"C,8 z8 | "^*"B,,8 z8 | "^~"A,8 z8 | "^~"B,8 z8 |
"^~"G,8 z8 | "^*"F,8 z8 | "^~"E,8 z8 | "^~"D,8 z8 :|



X:1
T:Catchy Brass Pop Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=108
K:C
% Assign MIDI instruments for each voice
%%MIDI program 56 % Trumpet
%%MIDI program 57 % Trombone
%%MIDI program 58 % Tuba
%
% Voice 1 - Trumpet, carries the main melody
V:1 clef=treble
|"C" G4 E4 | "F" F4 D4 | "G" E4 G4 | "C" C6 z2 |
"G" B4 d4 | "F" c4 A4 | "Am" A4 c4 | "G" G6 z2 |
% Repetition with variation for the second phrase
"C" G3 G E3 G | "F" F3 F D3 F | "G" E4 G4 | "C" C6 z2 |
"G" B4 d4 | "F" c4 A4 | "Am" A4 c4 | "G" G2 G2 z4 |
%
% Voice 2 - Trombone, provides harmony and countermelodies
V:2 clef=bass
|"C" C4 E4 | "F" A4 c4 | "G" B,4 D4 | "C" G,6 z2 |
"G" D4 G4 | "F" C4 F4 | "Am" E4 A,4 | "G" D4 z4 |
% Repetition with variation for the second phrase
"C" C3 C E3 E | "F" A3 A c3 c | "G" B,4 D4 | "C" G,6 z2 |
"G" D4 G4 | "F" C4 F4 | "Am" E4 A,4 | "G" D4 z4 |
%
% Voice 3 - Tuba, establishes the bass line and rhythmic foundation
V:3 clef=bass
|"C" C,8 | "F" F,8 | "G" G,8 | "C" C,8 |
"G" D,8 | "F" A,8 | "Am" E,8 | "G" G,8 |
% Repetition with variation for the second phrase
"C" C,6 C,2 | "F" F,6 F,2 | "G" G,4 G,4 | "C" C,8 |
"G" D,8 | "F" A,8 | "Am" E,8 | "G" G,4 G,4 |
% End with a strong rhythm to conclude the anthem



X:1
T:Nocturne of Shadows
C:OrchestrAI
M:C
L:1/8
Q:1/4=60
K:Am
V:1 clef=treble
%%MIDI program 0
|:"Am"A2^G2- ^G2z2 | "E7"e2^d2- ^d2z2 | "Am"A2c2 e4 | "Dm"d2^c2 A2z2 |
"Am"A4- A2^G2 | "E7"B2^G2 e2z2 | "Am"c2e2 a4 | "A7/a^c"e2z2 ^c2z2 |
|:"Am"A2B2 c2d2 | "C"B2^c2 d2e2 | "E7"e2f2 g4 | "Am"A2^G2- ^G2z2 |
"F"=f2e2 A2^c2 | "Dm"d2^c2 A2z2 | "Am"A4 z2e2 | "E7"^d3e f2z2 :|
V:2 clef=bass
%%MIDI program 0
|:"Am"A,2 C2 E2A,2 | "E7"^G,2 B,2 E2^G,2 | "Am"A,2 C2 E2A,2 | "Dm"D,2 F2 A2D,2 |
"Am"A,2 C2 E2A,2 | "E7"=G,2 B,2 E2^G,2 | "Am"A,4 ^G,4 | "A7/a^c"A,2 C2 E2A,2 |
|:"Am"A,2 C2 E2A,2 | "C"C,2 E2 G2C2 | "E7"E,2 ^G,2 B,2E,2 | "Am"A,2 C2 E2A,2 |
"F"F,2 A,2 =F2F,2 | "Dm"D,2 F2 A2D,2 | "Am"A,4 z4 | "E7"E,2 ^G,2 B,2E,2 :|



X:1
T:Triumph & Cheer Festivity
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=180
K:C
V:1 clef=treble
%%MIDI program 61
|: "C" G4 e4 | "F" f2 a2 g4 | "G" b4 a2 g2 | "C" e6 G2 |
"C" c4 G2 E2 | "F" A4 c2 d2 | "E" e4 g2 f2 | "G" d6 G2 |
"F" f6 f2 | "G" g2 e2 d2 B2 | "Am" c4 A2 c2 | "G" B4 G4 |
"C" E6 E2 | "F" F4 A2 c2 | "G" G4 B2 d2 | "C" c8 :|
V:2 clef=treble
%%MIDI program 62
|: "C"e4 c4 | "F"d2 f2 e4 | "G"c4 B2 A2 | "C"G6 e2 |
"C"c2 G2 E2 C2 | "F"d2 f2 a2 g2 | "E"G4 e4 | "G" G4 B2 d2 |
"F"A6 A2 | "G"B2 g2 f2 d2 | "Am"A2 E2 A2 E2 | "G"G4 D4 |
"C"C6 G,2 | "F"A4 c2 e2 | "G"B4 d2 g2 | "C"e8 :|
V:3 clef=bass
%%MIDI program 34
|: "C"C2 C4 G,2 | "F"A,2 A,4 F,2 | "G"B,2 B,4 D2 | "C"G,2 G,4 C2 |
"C"E2 C4 G,2 | "F"F2 A,4 C2 | "E"E2 G,2 E4 | "G" D4 B,2 G,2 |
"F"F2 A,4 C2 | "G"G2 B,2 G4 | "Am"A,2 A,4 E2 | "G"D4 B,2 G2 |
"C"C2 E4 G,2 | "F"F2 A,4 C2 | "G"G8 | "C"C,8 :|



X:1
T:Friday Night (Expanded)
C:JLEM
M:4/4
L:1/8
Q:1/4=120
K:Bb
V:1 clef=treble name="Main Melody"
%%MIDI program 56
"Em7"A4 - AFGA | "Am7"B2 .A.B G2 FG | "Dm7"B2 .A.B ^G4 | "Gm7"F6 EF | "Cm7"G2 F2 E4 |
"Dm7"A2 B2 c2 dc | "Gm7"Bc d2 B4 | "C7"D4 EF GA | "F7"B,4 z4 | "Bb"z4 z2 z2 |
V:2 clef=treble name="Counter Melody"
%%MIDI program 72
"Em7"E2 G2 F2 G2 | "Am7"A2 ^G2 E2 C2 | "Dm7"D2 C2 A2 F2 | "Gm7"G4 - GA B2 |
"Cm7"c2 B2 G2 E2 | "Dm7"d2 F2 E2 ^D2 | "Gm7"G2 =D2 d2 ^c2 | "C7"c2 A2 A2 G2 |
"F7"F2 D2 B,2 A,2 | "Bb"B,6 A,2 |



X:1
T:Villain's Theme
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=80
K:Gmin
% The music portrays the calculating, sinister nature of a classic villain
V:1 clef=treble name="Bass Clarinet"
%%MIDI program 72
|: "Gm"G3 A B2 | "Gdim"GABc d2 | "Cm"e2d2 c2 | B6 |
"Gm"G2^F2 G2 | "Ao"A2^G2 A2 | "D7"D2C2 D2 | D6 |
"Gm"G3 A B2 | "Gdim"GABc d2 | "Cm"e2d2 c2 | B6 |
"Gm"G2^F2 G2 | "D"D2^C2 D2 | "Gm"G4 z2 :|
V:2 clef=treble name="Violas"
%%MIDI program 42
|: "Gm"z2 D4 | "Gdim"D2 E2 F2 | "Cm"G2F2 E2 | F6 |
"Gm"z2 A4 | "Ao"A,2 B,2 C2 | "D7"D2^C2 D2 | D6 |
"Gm"z2 D4 | "Gdim"D2 E2 F2 | "Cm"G2F2 E2 | F6 |
"Gm"z2 A4 | "D"D2^C2 D2 | "Gm"G4 z2 :|
V:3 clef=bass name="Cello"
%%MIDI program 43
|: "Gm"G,3 D2 z | "Gdim"_B,3 E2 z | "Cm"G,,2G,2 G,2 | D,6 |
"Gm"G,2^F,2 G,2 | "Ao"A,2^G,2 A,2 | "D7"D3 E F2 | A,6 |
"Gm"G,3 D2 z | "Gdim"_B,3 E2 z | "Cm"G,,2G,2 G,2 | D,6 |
"Gm"G,2^F,2 G,2 | "D"D,3 =C D2 | "Gm"G,4 z2 :|



X:1
T:Brass Pop Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Cmaj
V:1 clef=treble name="Trumpet" subname="Tpt."
%%MIDI program 56
|:"C" G4 E4 | "F" D6 C2 | "Am" E4 C4 | "G" B,4 D4 | "C" G4 E4 | "F" D6 C2 | "G" G3 G A2B2 | "C" C8 :|
V:2 clef=treble name="Trombone" subname="Trb."
%%MIDI program 57
|:"C" E4 C4 | "F" A4 G4 | "Am" C4 E4 | "G" D4 B,4 | "C" E4 C4 | "F" A6 G2 | "G" E4 z4 | "C" C4 z4 :|
V:3 clef=bass name="Tuba" subname="Tba."
%%MIDI program 58
|:"C" C2 C,4 z2 | "F" F2 F,4 z2 | "Am" A,2 E4 z2 | "G" G,2 D4 z2 | "C" C2 C,4 z2 | "F" F2 F,4 z2 | "G" G,2 D4 z2 | "C" C,2 E,4 z2 :|



X:1
T:Journey Through the Imagined
C:OrchestrAI
M:3/4
L:1/16
Q:1/4=96
K:Gmaj
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "G" B4 D4 G4 | "C" c8 B4 | "D" A4 F4 D4 | "G" G,8 z4 | "Em" B4 E4 G4 | "A7" A,4 E4 C4 | "D7" F4 A4 d4 | "G" G,8 z4 :|
w: |: * * * * * * | * * * * * | * * * * * * | * * * | * * * * | * * * * | * * * * | * * * :|
|: "C" e4 g4 e4 | "G" d4 B4 G4 | "A7" c4 E4 A4 | "D7" F8 D4 | "G" G4 B4 d4 | "C" c8 B4 | "D" A4 f4 d4 | "G" G,8 z4 :|
w: |: * * * * * | * * * * * | * * * * * | * * | * * * * | * * * * * | * * * * | * * * :|
V:2 clef=treble name="Violin"
%%MIDI program 40
|: "G" D4 B,4 G,4 | "C" c4 A,4 E,4 | "D" A,4 F,4 D,4 | "G" B,8 z4 | "Em" E4 G4 B4 | "A7" c4 E4 A4 | "D7" d4 f4 a4 | "G" b,8 z4 :|
w: |: * * * * * * | * * * * * | * * * * * * | * * * | * * * * | * * * * | * * * * | * * * :|
|: "C" E4 c4 A4 | "G" D4 B,4 G,4 | "A7" C4 c4 E4 | "D7" F4 A4 d4 | "G" D4 B4 G4 | "C" c4 A,4 F,4 | "D" A,4 f4 d4 | "G" B,8 z4 :|
w: |: * * * * * | * * * * * | * * * * * | * * | * * * * | * * * * * | * * * * | * * * :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "G" G,,8 G,4 | "C" C,8 E,4 | "D" D,8 F,4 | "G" G,8 z4 | "Em" E,8 G,4 | "A7" A,8 C4 | "D7" D,8 F,4 | "G" G,,8 z4 :|
w: |: * * * * * * | * * * * * | * * * * * * | * * * | * * * * | * * * * | * * * * | * * * :|
|: "C" E,4 G,4 C4 | "G" G,,4 D,4 G,4 | "A7" A,4 C4 E4 | "D7" F,4 A,4 d4 | "G" G,,4 D,4 G,4 | "C" C,4 E,4 G,4 | "D" D,4 F,4 A,4 | "G" G,,4 D,4 B,4 :|
w: |: * * * * * | * * * * * | * * * * * | * * | * * * * | * * * * * | * * * * | * * * :|
V:4 clef=treble name="Piano Right Hand"
%%MIDI program 0
|: [G,2G,2] [G,2G,2] G,4 z4 | "C" [E,2C2] [E,2C2] [E,2C2] z6 | "D" [A,,2F,2] [A,,2F,2] A,,4 z4 | "G" [D,,2B,,2] [D,,2B,,2] z4 z4 |
"Em" [d2B2] [d2B2] [d2B2] z6 | "A7" [E,2A,2] [E,2A,2] [E,2C2] z6 | "D7" [F,2A,2] [F,2A,2] [A,2d2] z6 | "G" [G,,2D,2] [G,,2D,2] z4 z4 :|
w: |: * * * * * * | * * * * * | * * * * * * | * * * | * * * * | * * * * | * * * * | * * * :|
|: "C" [e,2c'2] [e,2c'2] e,4 z4 | "G" [B,,2G,2] [B,,2G,2] B,,4 z4 | "A7" [c2e2] [c2e2] a,4 z4 | "D7" [A,2F,2] [A,2F,2] d,4 z4 |
"G" [G,2B,2] [G,2B,2] G,4 z4 | "C" [c2A,2] [c2A,2] [c2F,2] z6 | "D" A,,4 F,4 d,4 | "G" [D,,2B,,2] [D,,2B,,2] z4 z4 :|
w: |: * * * * * | * * * * * | * * * * * | * * | * * * * | * * * * * | * * * * | * * * :|
V:5 clef=bass name="Piano Left Hand"
%%MIDI program 0
|: "G"[G,,2D,2] z2 [G,,2D,2] z2 z4 | "C"[C,2G,2] z2 [E,2C2] z2 z4 | "D"[D,2A,,2] z2 [F,2D,2] z2 z4 | "G"[G,,2D,2] z4 z6 |
"Em"[E,,2B,,2] z2 [d2B2] z2 z4 | "A7"[C,2E,2] z2 [E,2A,2] z2 z4 | "D7"[D,2F,2] z2 [A,2D,2] z2 z4 | "G"[G,,2D,2] z4 z6 :|
w: |: * * * * * * | * * * * * | * * * * * * | * * * | * * * * | * * * * | * * * * | * * * :|
|: "C"[C,2G,2] z2 [E,2C2] z2 z4 | "G"[G,,2D,2] z2 [D,2B,,2] z2 z4 | "A7"[C,2E,2] z2 [c2e2] z2 z4 | "D7"[F,2A,2] z2 [d2f2] z2 z4 |
"G"[G,,2B,,2] z2 [d2B2] z2 z4 | "C"[E,2C2] z2 [G,2C2] z2 z4 | "D"[F,2D,2] z2 [A,,2D,2] z2 z4 | "G"[B,,2G,,2] z4 z6 :|
w: |: * * * * * | * * * * * | * * * * * | * * | * * * * | * * * * * | * * * * | * * * :|



X:1
T:Golem's Awakening
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Cmin
V:1 clef=treble name="Brass" subname="Br."
%%MIDI program 61
|:"Cm"G,4G,4 B,4E4|D8 DEDC B,4|G,4G,4 B,4E4|D8 DEFG A4|
"Cm"E2C2 G4 z4 E2C2|G4 B,2G2 E2C2 D2E2|"Ab"A2F2 A2F2 A2F2 A2F2|"Bb"B,2D2 B,2D2 G,2B,2 G,4|
"Cm"G,4G,4 B,4E4|D8 DEDC B,4|G,4G,4 B,4E4|D8 DEFG A4|
"Eb"E2G2 B2E2 G2B2 B2E2|"Bb"D2G2 B,2D2 G2B2 B,2D2|"Fm" F2F2 A2F2 A2F2 A2F2| "G7"G,2B,2 D2G2 B,2D2 G,4:|
V:2 clef=treble name="Strings" subname="Str."
%%MIDI program 48
|:"Cm"c8 e8|f8 efed c4|c8 e8|f8 fgab g2a2|
"Cm"g2e2 c8 g2e2|c4 e2c2 g2e2 f2g2|"Ab"a2c'2 a2c'2 z2 a2c'2e2|"Bb"b,2d2 b,2d2 g,2b,2 c'4|
"Cm"c8 e8|f8 efed c4|c8 e8|f8 fgab g2a2|
"Eb"g2c'2 e8 c'2e2 e2g2|"Bb"d2g2 b,2d2g2 z2 b,2d2|"Fm" f4 a8 a8| "G7"g,2b,2 d4 b,2d2 g,4:|
V:3 clef=bass name="Perc. Synth" subname="Perc."
%%MIDI program 118
|:"Cm"c,4z4 c'4z4|d'4 z4 d,,4z4|c,4z4 c'4z4|d'8c'b,a,g, z4|
"Cm"g,4c4 z4 g,2c2|g,4 c,4 g,4 c'4|"Ab"a,4e4 z4 a,2e2|a,8 a,4 b,4|
"Cm"c,4z4 c'4z4|d'4 z4 d,,4z4|c,4z4 c'4z4|d'8c'b,a,g, z4|
"Eb"e,4g4 e,4g4|d,4 g,4 d,4 g,4|"Fm" f,4f'4 f,4f'4| "G7"g,2b,2 g,4 d,4d,4:|
V:4 clef=bass name="Bass" subname="Bs."
%%MIDI program 33
|:"Cm"C,4C,4 C,4C,4|D,4 D,4 D,4C,4|C,4C,4 C,4C,4|D,4 D,4 D,4C,4|
"Cm"G,4G,4 C,4G,4|C,4 C,4 G,4C,4|"Ab"A,4A,4 E,4A,4|"Bb"B,,4B,,4 F,4B,,4|
"Cm"C,4C,4 C,4C,4|D,4 D,4 D,4C,4|C,4C,4 C,4C,4|D,4 D,4 D,4C,4|
"Eb"G,4G,4 C,4G,4|D,4 D,4 G,4D,4|"Fm" F,4F,4 C,4F,4| "G7"G,,4G,,4 D,4G,,4:|



X:1
T:Mana Countdown
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Bm
V:1 clef=treble name="Synth Lead" subname="Lead"
%%MIDI program 81
|:"Bm"B4 F2A2|"Em"G4 E4|"F#m"A6 G2|"G"G6 F2|
"Bm"B3 c d2B2|"A"c6 A2|"G"G3 F E2D2|"F#7"F8|
"Bm"B4 F2A2|"Em"G4 E4|"F#m"A6 G2|"G"G6 F2|
"Bm"B3 c d2B2|"A"c6 A2|"G"B2 ^A2 A2G2|"Bm"B8:|
V:2 clef=bass name="Synth Bass" subname="Bass"
%%MIDI program 38
|:"Bm"B,8|"Em"E,8|"F#m"F,8|"G"G,8|
"Bm"B,4 D4|"A"A,4 C4|"G"G,4 B,4|"F#7"F,8|
"Bm"B,8|"Em"E,8|"F#m"F,8|"G"G,8|
"Bm"B,4 D4|"A"A,4 C4|"G"G,3 ^A B,2C2|"Bm"B,8:|
V:3 clef=treble name="Synth Pad" subname="Pad"
%%MIDI program 92
K:Bm
[|"Bm"B4 z4|"G"G4 z4|"A"A4 z4|"G"G4 z4|
"Bm"B,4 z4|"Em"G,4 z4|"Bm"D4 z4|"F#7"A4 z4|
"Bm"B4 z4|"G"G4 z4|"A"A4 z4|"G"G4 z4|
"Bm"B,4 z4|"Em"G,4 z4|"Bm"D2 ^A2 B4|"Bm"B,4 z4:|



X:1
T:This Week in Google Podcast Intro
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=132
K:Am
V:1 clef=treble
%%MIDI program 79
|: "Am"E4 C2E2 | "F" D4 C2D2 | "G" B,4 D2G2 | "Am" E6 z2 |
"C" E3G A2G2 | "F" D3F G2E2 | "G" D3B, C2B,2 | "Am" A,6 z2 :|
V:2 clef=treble
%%MIDI program 46
|: "Am"A4- A2c2 | "F" c4- c2A2 | "G" B4- B2G2 | "Am" A6 z2 |
"C" g4- g2e2 | "F" f4- f2d2 | "G" g3e f2d2 | "Am" a6 z2 :|
V:3 clef=bass
%%MIDI program 34
|: "Am"A,2 C2 E2A,2 | "F"F,2 A,2 C3F, | "G"G,2 B,,2 D3B,, | "Am"A,6 z2 |
"C"G,2 E2 G2C2 | "F"F,2 A,2 C3F, | "G"G,2 D2 G3B,, | "Am"A,6 z2 :|



X:1
T:Fiend's Encounter
C:OrchestrAI
M:7/8
L:1/16
Q:1/4=176
K:Cmin
% The piece opens with a powerful, aggressive melody
V:1 clef=treble name="Synth Lead" subname="Lead"
%%MIDI program 81
|: "Cm"G8 z4 G2 | "G" D8 z4 c2 | "Ab"=AB,8 z6 = | "Bdim" B8 z4 d2 |
"Cm"G2G2G2 z8 | "G"D2D2D2 z8 | "Ab"=A2B2=B2 z8 | "Bdim"B2d2d2 z8 :|
V:2 clef=treble name="Synth Strings" subname="Strings"
%%MIDI program 50
|: "Cm"e8 z4 e2 | "G"d8 z4 B2 | "Ab"c8 z4 c2 | "Bdim"a8 z4 f2 |
"Cm"e2e2e2 z8 | "G"d2d2d2 z8 | "Ab"c2c2c2 z8 | "Bdim"a2f2f2 z8 :|
V:3 clef=bass name="Electric Bass" subname="Bass"
%%MIDI program 34
|: "Cm"C4 G,4 z2 C4 | "G"G,4 D,4 z2 G,4 | "Ab"_A,4 E,4 z2 _A,4 | "Bdim"B,4 F,4 z2 B,4 |
"Cm"C2C2C2 z2 C4 z2 | "G"G2G2G2 z2 G,4 z2 | "Ab"_A2_A2_A2 z2 _A,4 z2 | "Bdim"B,2B,2B,2 z2 B,4 z2 :|



X:1
T:In the Shadow of Titan AIs
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=50
K:Dm
V:1 clef=treble name="Strings" subname="Melody"
%%MIDI program 48
|: "Dm"D2 F2 A2 | "C" G2 E2 C2 | "Dm" D2 C2 D2 | "A7" E4 z2 |
"Dm"A2 d2 f2 | "C" c2 A2 G2 | "F" F3 E F2 | "A7" A4 z2 :||
V:2 clef=alto name="Strings" subname="Harmony"
%%MIDI program 49
|: "Dm"D2 A,2 D2 | "C" C2 G,2 C2 | "Dm" F2 E2 F2 | "A7" G2 E2 C2 |
"Dm"A2 d2 A2 | "C" G2 E2 G2 | "F" A2 G2 A2 | "A7" E4 z2 :||
V:3 clef=tenor name="Woodwind" subname="Countermelody"
%%MIDI program 71
|: "Dm"f2 c2 a2 | "C" e2 c2 g2 | "Dm" a2 f2 e2 | "A7" c4 z2 |
"Dm"f2 c2 a2 | "C" g2 e2 c2 | "F" c3 B c2 | "A7" e4 z2 :||
V:4 clef=bass name="Cello" octave=-1
%%MIDI program 42
|: "Dm"D4 A,2 | "C" C4 G,2 | "Dm" D4 F2 | "A7" E4 C2 |
"Dm"D4 A,2 | "C" C4 G,2 | "F" F4 A2 | "A7" A,4 z2 :||



X:1
T:Craig Newmark J-School Theme
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Am
% Intro theme
V:1 clef=treble
%% MIDI program 1
|:"Am"E4 A2c2|"C"E2G2 E3D|"Dm"E2A2 F3E|"E7"A2^G2 A4|
"Am"A2e2 A2c2|"C"G4 E2D2|"F"E2C2 F2A2|"E7"G4 E4:|
% Middle section with a modern and progressive feel
V:1 clef=treble
%% MIDI program 1
|:"Am"c2B2 A3G|"Dm"F2E2 D2C2|"E7"B4 A2G2|"Am"A6 z2|
c2e2 A4|"C"c2B2 G4|"Dm"F2D2 E2C2|"E7"E4 E2D2:|
% Ending section that takes us back to the beginning
V:1 clef=treble
%% MIDI program 1
|:"Am"A2c2 A2e2|"C"c2G2 E4|"Dm"d2F2 D4|"E7"E2^G2 A4|
"Am"A2e2 c2A2|"C"c2E2 G3E|"F"F2A2 c2E2|"E7"E8|
"Am"A4- A6:|



X:1
T:Transcendental Battle
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=140
K:Am
% Strings for the main theme
V:1 clef=treble
%%MIDI program 48
|:"Am"E4 A4 c4 e4|"G"E4 G4 B4 d4|"F"E4 F4 A4 c4|"E7"G,4 E4 B,4 G4|
"Am"A4 c4 e4 a4|"G"B4 d4 g4 b4|"F"A4 c4 f4 a4|"E7"G4 B,4 e4 g4|
"C"G4 c4 e4 g4|"G/B"A4 d4 g4 b4|"Am"A4 c4 e8|"E7"B4 e4 a4 g4|
"Am"a8- a4 c4|"F"f8- f4 a4|"G"g8 ^f8| "E7"g4 e4 ^f4 e4|
% Brass for the counter-themes and depth
V:2 clef=treble
%%MIDI program 56
|:"Am"A,4 E4 A,4 E4|"G"G,4 D4 G,4 D4|"F"F,4 C4 F,4 C4|"E7"G,4 B,4 e4 d4|
"Am"A,4 C4 E4 A4|"G"G,4 B,4 d4 G4|"F"A,4 F4 A4 c4|"E7"E4 G4 B4 e4|
"C"G,4 c4 e4 C4|"G/B"D4 G4 B,4 D4|"Am"A,4 A4 e4 c4|"E7"B,4 G4 e4 ^d4|
"Am"c8- c4 E4|"F"A8- A4 C4|"G"G,8 B,8| "E7"G,2 E4 B,2 z4 G4|
% Lower strings for the foundation and rhythmic drive
V:3 clef=bass
%%MIDI program 48
|:"Am"E,8 A,8|"G"G,8 D8|"F"F,8 C8|"E7"B,,8 E,8|
"Am"A,8 E8|"G"G,8 D8|"F"F,8 C8|"E7"E,8 B,8|
"C"C8 G,8|"G/B"G,8 D8|"Am"A,8 E8|"E7"B,,8 E,8|
"Am"A,4 E8 A,4|"F"F,8 C8|"G"G,8 D8|"E7"E,8 ^F,8|
% A bass brass instrument to highlight the strength of the music
V:4 clef=bass
%%MIDI program 58
|:"Am"E,16|"G"D16|"F"C16|"E7"B,,16|
"Am"C16|"G"D16|"F"C16|"E7"B,,16|
"C"C16|"G/B"D16|"Am"E,16|"E7"B,,16|
"Am"A,16|"F"C16|"G"B,,16|"E7"B,,16|
% Indicates the end of the loop. Repeat as necessary for extending the battle theme.



X:1
T:Melting Motion
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=60
K:Gmin
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|: "Gm" G4- G4 z4 F4 | "Cm" E8 E4 G4 | "Dm" D4 F4 A4 ^c4 | "Gm" B,8 B,4 D4 |
"Adim" A4 ^c4 e4 g4 | "Bb" F4 F4 z4 D4 | "Gm" G2 B2 d4 F4 z4 | "Cm" E6 ^F2 G4 z4 :|
| "Gm" g4 g8 a5  | "Cm" ^c4 d4 e4 f4 | "D7" d2 ^c2 d8 z4 | "Gm" B,8 B,4 D4 |
"A" e4 c4 A4 ^F4 | "D7" d8 a4 f4 | "Gm" g4 =f4 e4 d4 | "Cm" c12 d4 |
"Gm" G8- G4 z4 | "Cm" ^c4 d4 e4 f4 | "D" a4 g4 f4 e4 | "Gm" B,8 G4 z4 :|
V:2 clef=bass name="Electric Bass"
%%MIDI program 39
|: "Gm" D,8 D,4 G,4 | "Cm" G,8 E2 F2 z4 | "Dm" A,12 D4 | "Gm" G,8 B,4 D4 |
"Adim" A,12 ^C4 | "Bb" F,12 B,4 | "Gm" G,8 B,4 D4 | "Cm" E,8 G4 E4 :|
| "Gm" g,4 d'4 g4 d'4 | "Cm" c,4 g4 c4 e4 | "D7" d,4 A,4 D4 F4 | "Gm" G,8 B,4 D4 |
"A" A,4 E4 A4 ^C4 | "D7" d,8 a,4 f4 | "Gm" G,8 B,4 D4 | "Cm" E,6 F,2 G,4 z4 |
"Gm" D,8 D,4 G,4 | "Cm" G,8 E2 F2 z4 | "Dm" A,12 D4 | "Gm" G,8 B,4 D4 :|



X:1
T:Reflective Trio Sonata
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=96
K:C
V:1 clef=treble name="Violin"
%%MIDI program 40
|:"C"E2 | "Am"A4 z2 | "Dm"F4 z2 | "G7"G3 F G2 | "C"E4 z2 |
"C7"E2 "F"D5   | "Dm"D4 z2 | "G7"B,2 "C"A,5   | "Am"E4 z2 |
"F"A2 "C"G5   | "Dm"F4 z2 | "G7"B2 B2 A2 | "C"G4 z2 |
"Am"A3 G F2 | "Dm"D4 z2 | "G7"G4 z2 |[1 "C"C4 z2 :|[2 "C"C4 z2 ||
V:2 clef=treble name="Viola"
%%MIDI program 41
|:"C"C4 z2 | "Am"A2 A2 z2 | "Dm"F2 F2 z2 | "G7"A2 A2 B2 | "C"C4 z2 |
"C7"C2 "F"F2 G2 | "Dm"F2 "G7"F3 E | "C"G,4 z2 | "Am"A2 E2 C2 |
"F"F2 "C"E2 G2 | "Dm"F2 F4 | "G7"G2 G2 F2 | "C"E2 E4 |
"Am"A2 A2 G2 | "Dm"F2 F4 | "G7"G2 z2 F2 |[1 "C"C4 z2 :|[2 "C"C2 z4 ||
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"C"C,6 | "Am"A,4 C2 | "Dm"D,3 E F2 | "G7"G,4 B,2 | "C"C,4 E2 |
"C7"C2 "F"F,4 | "Dm"F,4 D2 | "G7"B,,3 E G,2 | "C"C,2 E2 G,2 |
"F"F,2 C2 F,2 | "Dm"D,3 A, D2 | "G7"D,2 G,2 B,2 | "C"C,4 D2 |
"Am"A,4 E2 | "Dm"D,2 A,2 D2 | "G7"D,2 F,2 G,2 |[1 "C"C,4 z2 :|[2 "C"C,2 z4 ||



X:1
T:Rhythmic Harmony
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:G
V:1 clef=treble name="Flute"
%%MIDI program 73
| "G" G4 B2d2 | "Em" e4 g2b2 | "C" c4 e2g2 | "D" d4 f#2a2 |
| "G" b4 d2g2 | "Em" g4 b2e2 | "C" e4 g2c2 | "D" a4 f#2d2 |
| "G" g2 a2 g2 e2 | "Em" e2 d2 c2 b2 | "C" c2 B2 A2 G2 | "D" A2 F#2 E2 D2 |
| "G" G4 z4 | "Em" E4 z4 | "C" C4 z4 | "D" D4 z4 |
V:2 clef=alto name="Oboe"
%%MIDI program 68
| "G" B3g g2d2 | "Em" g3e e2b2 | "C" e3c c2g2 | "D" f#3d d2a2 |
| "G" d3g g2b2 | "Em" b3e e2g2 | "C" g3c c2e2 | "D" a3f# f2d2 |
| "G" a2 g2 a2 g2 | "Em" d2 e2 d2 e2 | "C" B2 A2 G2 F#2 | "D" F#2 E2 D2 C#2 |
| "G" g4 z4 | "Em" e4 z4 | "C" c4 z4 | "D" d4 z4 |
V:3 clef=bass name="Bassoon"
%%MIDI program 70
| "G" G,4 D,2G,2 | "Em" E,4 B,2E,2 | "C" C,4 G,2C,2 | "D" D,4 A,2D,2 |
| "G" D,4 G,2D,2 | "Em" B,4 E,2B,2 | "C" G,4 C,2G,2 | "D" A,4 D,2A,2 |
| "G" G,2 D,2 G,2 D,2 | "Em" E,2 B,2 E,2 B,2 | "C" C,2 G,2 C,2 G,2 | "D" D,2 A,2 D,2 A,2 |
| "G" G,4 z4 | "Em" E,4 z4 | "C" C,4 z4 | "D" D,4 z4 |
V:4 clef=bass name="Acoustic Bass"
%%MIDI program 33
| "G" G,,4 D,,2G,,2 | "Em" E,,4 B,,2E,,2 | "C" C,,4 G,,2C,,2 | "D" D,,4 A,,2D,,2 |
| "G" D,,4 G,,2D,,2 | "Em" B,,4 E,,2B,,2 | "C" G,,4 C,,2G,,2 | "D" A,,4 D,,2A,,2 |
| "G" G,,2 D,,2 G,,2 D,,2 | "Em" E,,2 B,,2 E,,2 B,,2 | "C" C,,2 G,,2 C,,2 G,,2 | "D" D,,2 A,,2 D,,2 A,,2 |
| "G" G,,4 z4 | "Em" E,,4 z4 | "C" C,,4 z4 | "D" D,,4 z4 |



X:1
T:Ode to the Cosmos
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=52
K:Em
V:1 clef=treble name="Strings" subname="Violin I"
%%MIDI program 40
|: "Em"B4 B2 | G4 F2 | E6- | E4 z2 | "C"G4 E2 | "D" F4 D2 | "Em"E6 |1 E4 z2 :|2 E4 F2|
|: "G"B4 c2 | "B7"B4 A2 | "Am"A6- | A4 z2 | "Em"G4 E2 | "D"F4 D2 | "Em"E6- | E4 z2 :|
V:2 clef=treble name="Strings" subname="Violin II"
%%MIDI program 40
|: "Em"e4 e2 | d4 c2 | B6- | B4 z2 | "C"c4 B2 | "D" A4 F2 | "Em"G6 |1 G4 z2 :|2 G4 A2|
|: "G"e4 f2 | "B7"d4 B2 | "Am"c6- | c4 z2 | "Em"e4 c2 | "D"d4 A2 | "Em"B6- | B4 z2 :|
V:3 clef=alto name="Woodwinds" subname="Clarinet"
%%MIDI program 71
|: "Em"e4 G2 | d4 F2 | B6- | B4 z2 | "C"c4 E2 | "D" d4 F2 | "Em"E6 |1 E4 z2 :|2 E4 G2|
|: "G"e4 d2 | "B7"f4 e2 | "Am"a6- | a4 z2 | "Em"b4 e2 | "D"a4 f2 | "Em" g6- | g4 z2 :|
V:4 clef=bass name="Strings" subname="Cello"
%%MIDI program 42
|: "Em"B,6 | E4 C2 | G,6 | E3 z3 | "C"C6 | E4 G,2 | "Em"B,6 |1 B,3 z3 :|2 B,4 C2|
|: "G"G,6 | B,4 D2 | E6 | C3 z3 | "Em"G,6 | B,4 D2 | "Em"E6 | E3 z3 :|



X:2
T:Dark Russian Dance
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=200
K:Am
V:1 clef=treble name="Piano RH"
%%MIDI program 0
|: "Am"^A2f2a2c2 f2a2c2f2 | "C"e2a2c2e2 g2c2e2g2 | "Bdim"B2d2f2a2 a2d2f2a2 | "E7"b2^g2^d2^a2 g2^d2b2g2 |
"Am"^A2f2a2c2 f2a2c2f2 | "C"e2a2c2e2 g2c2e2g2 | "Bdim"B2d2f2a2 a2d2f2a2 | "E7"b2^g2^d2^a2 g2^d2b2g2 |
"Am"A4 A3E C3E D3F | "F"A4- A3F D3C C4 | "Dm"F4 F3A C3A F3E | "E7"G4 G3^G B,3B, G,4 |
"Am"A4 A3E C3E D3F | "F"A4- A3F D3C C4 | "Dm"F4 F3A C3A F3E | "E7"G4 G3^G B,3B, G,4 :|
V:2 clef=bass name="Piano LH"
%%MIDI program 0
|: "Am"[A,2E2] z A,4 [E2C2] z E4 z2 | "C"G,2 C,4 z4 [C2G,2] z G,3 | "Bdim"B,,2 D,4 z4 [D2B,,2] z B,,3 | "E7" E,2 ^G,4 z4 [^G,2E,2] z E, |
"Am"[A,2E2] z A,4 [E2C2] z E4 z2 | "C"G,2 C,4 z4 [C2G,2] z G,3 | "Bdim"B,,2 D,4 z4 [D2B,,2] z B,,3 | "E7" E,2 ^G,4 z4 [^G,2E,2] z E, |
"Am"A,,2 A,4 z4 [E2A,2] z A,3 | "F"F,2 A,4 z4 [D2F,2] z F,3 | "Dm"D,2 F,4 z4 [A,2D,2] z D,3 | "E7"E,2 ^G,4 z4 [B,,2E,2] z E,3 |
"Am"A,,2 A,4 z4 [E2A,2] z A,3 | "F"F,2 A,4 z4 [D2F,2] z F,3 | "Dm"D,2 F,4 z4 [A,2D,2] z D,3 | "E7"E,2 ^G,4 z4 [B,,2E,2] z E,3 :|



X:1
T:Ode to the Cosmos
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=50
K:C
V:1 clef=treble name="Lead Voice" subname="Strings"
%%MIDI program 48
|:"C"E4 G4 | "Am"A6 E3 G | "Fmaj7"c4 A2 | "Gsus4"G4 F2 |
"C"E3 G c4 | "Am"E3 G A2 F | "Dm7"D3 F A2 c | "Gsus4"B,4 z2 |
"Em" G4- G4 | "C/E"C2 E3 D | "Fmaj7"A4- A4 | "G6"c2 B2 A2 |
"C"G,4 E2 | "Am"C2 A3 G | "Fmaj7"F4- F2 | "G7"E2 D2 C2 :|
|: "Cmaj7"E3 G c3 | "Am6/D"A2 F A2 G | "Fmaj7"F3 A c2 e | "Gadd9"G3 B d2 c |
"Cmaj7"E2 G c3 | "Dm9/F"A3 F A2 G | "Fmaj9"F4 E2 | "G13"A3 G F2 :|
V:2 clef=bass name="Bass Voice" subname="Cellos"
%%MIDI program 42
|:"C"C,4 E,4 | "Am"A,6 E,3 G, | "Fmaj7"F,4 C2 | "Gsus4"G,4 F,2 |
"C"C,3 E, G,4 | "Am"A,3 C A,2 F, | "Dm7"D,3 F A,2 D | "Gsus4"G,4 z2 |
"Em"B,4- B,4 | "C/E"C,2 E,3 D, | "Fmaj7"F,4- F,4 | "G6"A,2 G,2 F,2 |
"C"C,4 E,2 | "Am"A,2 E,3 D, | "Fmaj7"F,4- F,2 | "G7"G,2 F,2 E,2 :|
|: "Cmaj7"C,3 E, G,3 | "Am6/D"A,2 D, F,2 E, | "Fmaj7"F,3 A, C2 | "Gadd9"B,,3 D G,2 F, |
"Cmaj7"C,2 E, G,3 | "Dm9/F"F,3 A, C2 E, | "Fmaj9"C,4 B,,2 | "G13"B,,3 A, G,2 :|
V:3 clef=treble-8 name="Harmony Voice" subname="Synth Pad"
%%MIDI program 91
|:"C"E3 G (G3A) | "Am"(cB) A E3 G | "Fmaj7"a3 f c2 | "Gsus4"(Bc) G F2 |
"C"E3 G c3 e | "Am"f3 e A2 F | "Dm7"F3 A d2 c | "Gsus4"B,4 z2 |
"Em" (B2A) G G4 | "C/E"(E2D) C3 D | "Fmaj7"a4 a2 | "G6"c2 B2 A2 |
"C"G,3 E C4 | "Am"A3 G A2 G | "Fmaj7"F3 E F2 | "G7"E2 D2 C2 :|
|: "Cmaj7"E3 G c3 e | "Am6/D"A2 F A2 G | "Fmaj7"F3 A c2 e | "Gadd9"(dB) G d2 c |
"Cmaj7"E2 G c3 e | "Dm9/F"(cB) A F2 G | "Fmaj9"F2 E3 F | "G13"(AG) F G2 :|
V:4 clef=bass name="Bass Voice" subname="Contrabass"
%%MIDI program 44
|:"C"C,,4 E,,4 | "Am"A,,6 E,,3 G,, | "Fmaj7"F,,4 C,,2 | "Gsus4"G,,4 F,,2 |
"C"C,,3 E,, G,,4 | "Am"A,,3 C, E,,2 F,, | "Dm7"D,,3 F A,,2 D, | "Gsus4"G,,4 z2 |
"Em"B,,4- B,,4 | "C/E"C,,2 E,,3 D,, | "Fmaj7"F,,4- F,,4 | "G6"A,,2 G,,2 F,,2 |
"C"C,,4 E,,2 | "Am"A,,2 E,,3 D, | "Fmaj7"F,,4- F,,2 | "G7"G,,2 F,,2 E,,2 :|
|: "Cmaj7"C,,3 E,, G,,3 | "Am6/D"A,,2 D,, F,,2 E,, | "Fmaj7"F,,3 A,, C,2 | "Gadd9"B,,,3 D, G,,2 F,, |
"Cmaj7"C,,2 E,, G,,3 | "Dm9/F"F,,3 A,, C,2 E,, | "Fmaj9"C,,4 B,,,2 | "G13"B,,,3 A,, G,,2 :|



X:1
T:Arpeggio Aria
C:OrchestrAI
M:C
L:1/16
Q:1/4=160
K:Am
V:1 clef=treble
%%MIDI program 1
|:"Am" e2c2A2^G2 e2c2A2^G2|"E7" ^G2B2^G2E2 ^G2B2^G2E2|
"F" f2A2c2f2 f2A2c2f2|"E7" e2^G2B2e2 e2^G2B2e2|
"Am" e2c2A2^G2 e2c2A2^G2|"E7" ^G2B2^G2E2 ^G2B2^G2E2|
"F" f2A2c2f2 f2A2c2f2|"E7" e2^G2B2e2 e2^G2B2e2|
"Am" a4 a4 e4 e4|"E7" ^g4 b4 e4 e4|
"F" f4 c4 a4 a4|"E7" ^g4 b4 e4 e4|
"Am" a4 a4 e4 e4|"E7" ^g4 b4 e4 e4|
"F" f4 c4 a4 a4|"E7" ^g4 b4 e4 e4|
"Am" A2^G2 A2c2 A2^G2 A2c2|"E7" B2^G2 B2e2 B2^G2 B2e2|
"F" c2f2 c2A2 c2f2 c2A2|"E7" ^G2B2 ^G2e2 ^G2B2 ^G2e2|
"Am"A3B A3c B2A2 B3c|"E7"e2B2 B3^G B2B2 e3f|
"F"f3g f2c2 f2d2 f2A2|"E7"^G3A B2^G2 e2^G2 ^G2B2|
"Am"a8 e8|"E7"^g8 e8|
"F"f8 c8|"E7"^g8 B8|
"Am"e2c2 e2A2 A2e2 c2A2|
"Am"A2^G2 A2A2 A6:|



X:1
T:Band Jam Bonanza
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=144
K:Bb
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|: "Bb" B3F D2F2 | "Eb" G3B, E2G2 | "Cm" c2e2 "F7" A2c2 | "Bb" B4 F4 |
"Gm" G3A B2d2 | "Cm" c3d e2g2 | "F7" f2A2 c2e2 | "Bb" d8 :|
|: "Eb" e3f g2b2 | "Bb" d3e f2a2 | "Cm" c3B "F7" A2c2 | "Bb" B4 F4 |
"Gm" G3F "Cm" E2G2 | "F7" A3G F2A2 | "Bb" B2d2 "Eb" e2g2 | "F7" f8 :|
V:2 clef=treble name="Alto Sax"
%%MIDI program 65
|: "Bb" D3B, F,2B,2 | "Eb" E3G, B,2E2 | "Cm" E2G2 "F7" F2A2 | "Bb" F4 D4 |
"Gm" B,3C D2F2 | "Cm" E3F G2B2 | "F7" A2F2 A2c2 | "Bb" F8 :|
|: "Eb" G3A B2d2 | "Bb" F3G A2c2 | "Cm" E3D "F7" C2E2 | "Bb" D4 B,4 |
"Gm" B,3A, "Cm" G,2B,2 | "F7" C3B, A,2C2 | "Bb" D2F2 "Eb" G2B2 | "F7" A8 :|
V:3 clef=bass name="Trombone"
%%MIDI program 57
|: "Bb" B,,3F, D,2F,2 | "Eb" E,3B,, G,2E,2 | "Cm" C,2E,2 "F7" F,2A,2 | "Bb" B,,4 F,,4 |
"Gm" G,,3A,, B,,2D,2 | "Cm" C,3D, E,2G,2 | "F7" F,2A,2 C2E2 | "Bb" D,8 :|
|: "Eb" E,3F, G,2B,2 | "Bb" B,,3C, D,2F,2 | "Cm" C,3B,, "F7" A,,2C,2 | "Bb" B,,4 F,,4 |
"Gm" G,,3F,, "Cm" E,,2G,,2 | "F7" F,,3E,, D,,2F,,2 | "Bb" B,,,2D,,2 "Eb" E,,2G,,2 | "F7" F,,8 :|
V:4 clef=bass name="Tuba"
%%MIDI program 58
|: "Bb" B,,,4 B,,,2D,,2 | "Eb" E,,4 E,,2G,,2 | "Cm" C,,4 "F7" F,,4 | "Bb" B,,,8 |
"Gm" G,,,4 G,,,2B,,,2 | "Cm" C,,4 C,,2E,,2 | "F7" F,,4 F,,2A,,2 | "Bb" B,,,8 :|
|: "Eb" E,,4 E,,2G,,2 | "Bb" B,,,4 B,,,2D,,2 | "Cm" C,,4 "F7" F,,4 | "Bb" B,,,8 |
"Gm" G,,,4 "Cm" C,,4 | "F7" F,,4 F,,2A,,2 | "Bb" B,,,2D,,2 "Eb" E,,2G,,2 | "F7" F,,8 :|



X:1
T:Brass Pop Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Bb
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|:"Bb" D4 F2 G2 | "Eb" G6 F2 | "F" F4 E2 D2 | "Bb" D6 z2 |
"Bb" D4 F2 G2 | "Eb" G6 F2 | "F" F3 G "Gm" A2 G2 |1 "Bb" B6 z2 :|2 "Bb" B6 F2 |
|:"Bb" d2 d2 c2 B2 | "Gm" B4 A2 G2 | "Eb"G2 F2 G2 A2 | "F" F6 z2 |
"Bb" d2 c2 B2 A2 | "Gm" G3 F "Eb" E2 F2 | "F" F3 G "Gm" A2 G2 |1 "Bb" B6 F2 :|2 "Bb" B6 z2 |
V:2 clef=treble name="Trombone"
%%MIDI program 57
|:"Bb" B,4 D2 F2 | "Eb" E6 F2 | "F" F4 E2 C2 | "Bb" B,6 z2 |
"Bb" B,4 B,2 D2 | "Eb" E6 C2 | "F" D4 C2 B,2 |1 "Bb" B,6 z2 :|2 "Bb" B,6 D2 |
|:"Bb" F2 B,2 A2 G2 | "Gm" G4 G2 F2 | "Eb" E2 E2 E2 F2 | "F" D6 z2 |
"Bb" B,2 A2 G2 F2 | "Gm" F3 E "Eb" D2 C2 | "F" F3 E "Gm" G2 A2 |1 "Bb" B,6 D2 :|2 "Bb" B,6 z2 |
V:3 clef=bass name="Tuba"
%%MIDI program 58
|:"Bb" B,8 | "Eb" E,6 G,2 | "F" F,4 E,2 D,2 | "Bb" B,,6 z2 |
"Bb" B,4 B,2 D2 | "Eb" E,6 C2 | "F" D,4 E,2 F2 |1 "Bb" B,,6 z2 :|2 "Bb" B,,6 E,2 |
|:"Bb" D2 B,,2 C2 B,,2 | "Gm" G,,4 A,,2 B,,2 | "Eb" B,,4 E,2 G,2 | "F" D,6 z2 |
"Bb" B,,2 C2 B,,2 A,,2 | "Gm" A,,3 G,, "Eb" F,2 E,2 | "F" F,3 E,2 D,2 z |1 "Bb" B,,6 E,2 :|2 "Bb" B,,6 z2 |



X:1
T:Tears of the Heart
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=50
K:Emin
V:1 clef=treble name="Piano"
%%MIDI program 0
|:"Em" B4 A2 G2 | "D" F6 E2 | "C" G4 E2 D2 | "B7" B,6 z2 |
"Am" A4 G2 F2 | "G" E6 D2 | "Em" B,4 A,2 G,2 | "B7" F4 E2 D2 |
"C" E4 D2 C2 | "Am" A6 G2 | "Em" B,4 E2 "D" F2 | "G" G4 F2 E2 |
"Em" E6 D2 | "C" C4 B,2 A,2 | "B7" G4 F2 E2 | "Em" E8 :|
V:2 clef=bass name="Strings"
%%MIDI program 42
|:"Em" E,6 B,2 | "D" A,,6 D2 | "C" G,,6 C2 | "B7" B,,6 D2 |
"Am" A,,6 E2 | "G" G,,6 F2 | "Em" E,6 B,2 | "B7" B,,6 F2 |
"C" G,,6 C2 | "Am" A,,6 E2 | "Em" E,6 B, z | "D" A,,6 F2 |
"G" G,,6 D2 | "Em" E,6 B, z | "B7" B,,6 D2 | "Em" E,8 :|



X:1
T:Reflections and Resolutions
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=96
K:Gmaj
% Introduction of the main theme
V:1 clef=treble name="Violin I"
%%MIDI program 40
|: "G"[GB]2 (3dcB | "C"[EG]2 (3cBA | "D7"[FA]2 (3BAF | "G"[GD]2 G2 :|
"Em"[EB]2 (3dcB | "Am"[EA]2 (3cBA | "Bm"[FD]2 (3dcB | "Am"[CE]2 A2 |
V:2 clef=treble name="Violin II"
%%MIDI program 40
|: "G" [D^F]2 A ||: "C" [CG]2 E | "D7" [A^c]2 F | "G" [BG]2 G :|
"Em" B2 (3GAB | "Am" E2 (3cBA | "Bm" F2 (3Bcd | "Am" [EA]2 E |
V:3 clef=alto name="Viola"
%%MIDI program 41
|: "G"g2 (3feg | "C"e2 (3dcB | "D7"f2 (3edc | "G"[BG]2 G :|
"Em"g2 (3feg | "Am"e2 (3dcB | "Bm"f2 (3edc | "Am"c3  |
V:4 clef=bass name="Cello"
%%MIDI program 42
|: "G"G2 (3BAG | "C"C2 (3ECD | "D7"D2 (3FED | "G"G,3  :|
"Em"E2 (3B^AB | "Am"A,2 (3CE^C | "Bm"B,2 (3D^CD | "Am"A,3  |
% Transition to a brighter, hopeful section
V:1
|: "D"[AF]2 (3cBA | "G"[BG]2 (3dcB | "Em"[GB]2 (3AFD | "A7"[E^C]2 E2 :|
"D"[DB]2 (3cBA | "G"[BG]2 (3dcB | "Am"[EA]2 (3cBA | "D7"[FA]2 F2 |
V:2
|: "D"f3  | "G"g2 (3feg | "Em"[EB]2 e | "A7"[^CE]2  :|
"D"d2 (3cBA | "G"b2 (3_dcB | "Am"c2 (3cBA | "D7"d3  |
V:3
|: "D"[FA]2 A2 | "G"g2 (3dcB | "Em"g2 (3GAB | "A7"[EA]2 E2 :|
"D"[FD]2 d2 | "G"b2 (3gfe | "Am"e2 (3dcB | "D7"f2 A2 |
V:4
|: "D"D2 (3FED | "G"G,2 (3BAG | "Em"E2 (3GFE | "A7"A,2 (3CEC :|
"D"D3  | "G"G,3  | "Am"A,3  | "D7"D3  |
% Development and conclusion echoing the main theme
V:1
|: "G"[BG]2 ^G2 | "C"[EC]2 E2 | "D7"[AF]2 A2 | "G"[GB]2 G2 :|
"G"[GB]2 (3_dcB | "C"[EG]2 (3EFE | "D7"[FA]2 (3AFD | "G"[GD]2 G2 |
V:2
|: "G"b2 (3gfe | "C"[EC]2 e | "D7"d2 (3Bcd | "G"[BG]2 G :|
"Em"g2 (3feg | "Am"e2 (3dcB | "Bm"f2 (3edc | "Am"[EA]2 E |
V:3
|: "G"[BG]2 (B d | "C"[EC]2 (3ECD | "D7"[FA]2 A2 | "G"[BG]2 G2 :|
"Em"g2 (3feg | "Am"e2 (3dcB | "Bm"f2 (3edc | "Am"c2 A2 |
V:4
|: "G"G,3  | "C"C2 (3ECD | "D7"D2 (3FED | "G"G,3  :|
"Em"E2 (3B^AB | "Am"A,2 (3CE^C | "Bm"B,2 (3D^CD | "Am"A,3  |



X:1
T:Sunset Sails
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:C
V:1 clef=treble
%%MIDI program 73
% Right hand melody with chord symbols for the harmonizing parts
|:"C"e2 c2 G4| "Am"A4 E4 | "Em"G2 F2 E4 | "F"C4 z2 G2 |
| "G"G3 E D2 E2 | "C"c4 "G/B"B3 A | "Am"A2 E2 "Em"G4 | "Dm7"d4 "G7"c2 d2 |
| "C"e2 c2 G4 | "Am"A4 E4 | "F"F2 E2 D4 | "G"G4- G2 A2 |
| "F"F3 G A2 G2 | "C"E3 D C4 | "Am"A6 G2 | "G7"G6 z2 :|
V:2 clef=treble
%%MIDI program 74
% Upper harmony part
|:"C"G2 E2 C4 | "Am"E4 C4 | "Em"B2 A2 G4 | "F"A4 z2 C2 |
| "G"G3 B A2 B2 | "C"G4 "G/B"A3 G | "Am"F2 C2 "Em"E4 | "Dm7"A4 "G7"G2 F2 |
| "C"G2 E2 C4 | "Am"E4 C4 | "F"F2 E2 D4 | "G"B4- B2 c2 |
| "F"F3 A B2 A2 | "C"G3 F E4 | "Am"E6 D2 | "G7"B6 z2 :|
V:3 clef=bass
%%MIDI program 48
% Bass part
|:"C"C2, E,2 C,4 | "Am"A,4 E,4 | "Em"G,2 F,2 E,4 | "F"C4 z2 C2 |
| "G"G,2 B, G2 B2 z | "C"c4 "G/B"B,3 E | "Am"A,2 E2 "Em"G,4 | "Dm7"D4 "G7"C2 D2 |
| "C"C2, E,2 C,4 | "Am"A,4 E,4 | "F"F2 E2 D4 | "G"G,4- G2 E2 |
| "F"F,2 A, C2 A,2 z | "C"C,3 E, G,4 | "Am"A,6 G,2 | "G7"G,6 z2 :|



X:1
T:Prelude to a Dreamscape
C:OrchestrAI
M:C
L:1/8
Q:1/4=70
K:Cm
V:1 clef=treble
|:[K:Cmin]"Cm" G,,2 E,G,,(C G,) "G" B,,2 D,G,(B, D) | "Ab" G,,2 E,C,(G, C) "Eb" E,2 G, B, E G |
"G7" D,2 ^F,B,(D F) "Cm" B,,2 D,F,(B, D) | "G" G,2 (D G) B, D "Cm" E,2 C,G,,(E G) |
"Cm" (G,E,C, G,2 E,G,) "G" (B,D G,2 D,G,) | "Ab" (C,E,G, G,2 E,G,) "Eb" (B,E,G, G,2 E,G,) |
"G7" (D,^F,B, G,2 B,D,) "Cm" (B,,D,F, B,,2 D,F,) | "G" (B,D) (G,B,) "Cm"(G,E,C,) "Cm"(CEGc):|
|:[K:Cmin]e2 (gec) e2 (gec) | d2 (bgd) d2 (bgd) | c2 (af^f) c2 (af^f) | "Ab"_e2 (c'ab) _e2 (c'ab) |
"Gsus4" d2 (g bag) "G" (dBGd) | "Cm"(e^ceg) g2 (ece) | "Fm"(afc'a,) a,2 (afc') | "G7"(b,dfb,) d2 (gbd) |
"G"[K:Dmaj] "D"[V:1]  "^Modulation to D Major"D2 FAd "^D.C. al Coda"f2 ed | "A"c2 ae "^Coda"c2 B2 | "Bm"d2 (fbd) d2 (afd') | "A7"c2 (aec') c2 (d'bc') |
"D" [FAd][DFAd] [FAd][DFAd] [DFA][DdFA] [ADF][ADFA] | "G" B2 dBG "D" F2 DFD ^C2 DED | "A7" [=c2e][cA^ce] [cA=ce][cA^ce] [cA=ce][ceAc] "D" F2 D2 F2 D2 | [D2^F2A2] [D6F6A6] :|
V:2 clef=bass
|:[K:Cmin]"Cm" C,,4 E,,4 | "G" G,,4 D,4 | "Ab" A,,4 E,4 | "Eb" B,,4 G,4 |
"G7" D,4 ^F,4 | "Cm" C,4 G,,4 | "G" D,4 G,4 | "Cm" G,,4 C,4 :|
|:[K:Cmin]"Cm"(_E,,C,) "Ab"([C,E,G,) "G"(B,2]D G) | "Ab"(A,2 C) "Eb"(B,2 G) | "G7"(D,2" ^F) "Cm"[_B,,2 D] "G"(G,2 B,) | "Cm"(C,4 G,) "Cm" G,,4 :|
"Ab"[K:Cmin] E,2 (CEG) "Eb" (G,2B,E,) | "G7"(D2^FD) "Cm"B,,2 D,2 | "G"(B,2 D) "Cm"(G,2 E,) | "Cm" G,,4 C,4 |
"Ab" E,2 C,2 "Eb" B,,2 G,2 | "G7" D,2 ^F,2 "Cm"_B,,2 D,2 | "G"(B,2 D) "Cm"(G,2 E,) G,,4 :|
"Gsus4" B,,4 D,4 | "G" G,,4 G,4 | "Cm"[K:Dmaj]"D" F,,2 D2 | "A" C2 F,2 | "Bm"D2 F,2 | "A7"C2 E,2 |
"D"[F,2A,2D2] [F2A2D2] [D2F2A2] | "G" B,,2 D,2 "D" A,2 F,2 | "A7"[A,2E2A2] "D" [AD]2 F2 | D,,6:|



X:1
T:Majestic Anthem of the Brave (Enhanced)
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Eb
V:1 clef=treble name="Trumpet 1"
%%MIDI program 56
|:"Eb"B6 G2 | G4 F4 | "Abmaj7"E4 C2E2 | C6 z2 |
"Bb"F4 D2F2 | "Eb/D"D4 B,2D2 | "Ebm"G4 B2G2 | "Bb7"B6 z2 |
"Ab/C"E3C E2C2 | "Cm"c4 A2c2 | "Bb"F4 D2B,2 | "Cm"G6 z2 |
"Ab"E4 E2G2 | "Eb"F4 F2A2 | "Bb7"D4 E2C2 | "Eb"B,6 z2:|
V:2 clef=treble name="Trumpet 2"
%%MIDI program 56
|:"Eb"G2F2 E2G2 | G4 F4 | "Abmaj7"C2B,2 C2E2 | C4 B,4 |
"Bb"D4 C2D2 | "Eb/D"B,4 G2B,2 | "Ebm"G2F2 G2B2 | "Bb7"D4 C4 |
"Ab/C"E3C c4 | "Cm"F4 A2c2 | "Bb"D3B, F4 | "Cm"C6 z2 |
"Ab"A,4 C3E | "Eb"B,4 F3A | "Bb7"G2F2 E2D2 | "Eb"B,,6 z2:|
V:3 clef=treble name="Horn"
%%MIDI program 61
|:"Eb"E2^G2 B,2E2 | E2^G2 F3E | "Abmaj7"C3E A,2^C2 | C3E C4 |
"Bb7"B,2D2 G,3B, | "Eb/D"B,2D2 D4 | "Ebm"G2B2 E2^G2 | "Bb7"D3F B,4 |
"Ab/C" E2G2 c2E2 | "Cm"A2F2 F4 | "Bb7"D2F2 "Eb/Bb"B,2D2 | "Cm"C6 z2 |
"Ab"E4 "Eb"G4 | "Bb7"B,2D2 "Eb"G4 | "Fm"F2E2 D2C2 | "Bb7"B,,6 z2:|
V:4 clef=bass name="Trombone"
%%MIDI program 58
|:"Eb" E4 G,2^G,2 | E2G2 F4 | "Abmaj7"A,4 C3E | A,3C C4 |
"Bb7"D4 F3A | "Eb/D"D4 B,2^B,2 | "Ebm"G,2E2 E4 | "Bb7"D4 B,4 |
"Ab/C" E2G2 A,3C | "Cm"A4 G,2B,2 | "Bb7"D2B,2 F4 | "Cm"C6 z2 |
"Ab"A,4 E4 | "Eb"B,,4 D4 | "Fm"F,2E2 B,,2D,2 | "Eb"E6 z2:|
V:5 clef=perc stafflines=1 name="Timpani"
%%MIDI program 47
|:"Eb"[E,B,E]4 z4 z3 | [E,B,E]4 [F,B,F]4 | "Abmaj7"[E,C,E]4 z4 z3 | [E,C,E]4 [C,C]4 |
"Bb7"[D,F,B,]4 z4 z3 | "Eb/D"[D,B,,D]4 z4 z3 | "Ebm"[E,G,B,]4 z4 z3 | "Bb7"[D,F,B,]4 z4 z3 |
"Ab/C"[E,G,C]4 z4 z3 | "Cm"[C,F,A,,]4 z4 z3 | "Bb7"[D,B,,D]4 [F,B,F]4 | "Cm"[C,A,,C]4 z4 z3 |
"Ab"[A,,C,E]4 z4 z3 | "Eb"[E,G,B,]4 z4 z3 | "Bb7"[B,,D,F,]4 z4 z3 | "Eb"[E,B,,E]4 z4 z3 :|
V:6 clef=treble name="Full Strings" subname="Violins I"
%%MIDI program 48
|: "Eb"[B,G]4 [B,G]4 | [G,F]4 [G,F]4 | "Abmaj7"[E,C]4 [E,C]4 | [C,B,]6 z2 |
"Bb"[F,D]4 [F,D]4 | "Eb/D"[D,B,]4 [D,G]4 z2 | "Ebm"[G,E]4 [G,E]4 | "Bb7"[B,F]6 z2 |
"Ab/C"[E,C]4 [E,C]4 | "Cm"[c,A]4 [c,A]4 | "Bb"[F,D]4 [F,D]4 | "Cm"[G,E]6 z2 |
"Ab"[E,C]4 [E,C]4 | "Eb"[F,B,]4 [F,B,]4 z2 | "Bb7"[D,F]4 [D,C]4 | "Eb"[B,,B,]6 z2:|



X:1
T:Crystal Reflections
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=70
K:G
V:1 clef=treble name="Violin 1"
%%MIDI program 48
|:"G"D2 D2 G2 B2 | "C"E4 E2 G2 | "Am"A2 B2 c2 d2 | "D7"B4 G4      |
"G"B2 A2 G2 F2 | "Em"E4 G2 B2 | "C"c2 B2 A3 G | "G"B4 z4       |
"G"G6 G2 | "C"E4 G4 | "Am"c2 G2 E2 C2 | "D7"D6 G,2     |
"G"B,2 D2 B,3 G | "Em"G2 E2 G4 | "C"c2 A2 F3 G |1"G"G4 z4      :|2"G"G4 z4 ||
|: "C"G3 B d2 c2 | "D"BA G2 F2 D2 | "Em"E2 D2 C2 G,2 | "Am"A3 B c2 B2 |
"D"A4 A2 d2 | "G"B2 G2 D2 B,2 | "C"C2 G,2 D3 E |1"G"G4 z4      :|2"G"G4 Q:1/4=110 z4 ||
|:"D"FA d2 d2 f2 | "G"g6 g2 | "Bm"f3 e d2 c2 | "A7"a6 a2       |
"D"d2 e2 f4 | "G"g2 b2 a4 | "Bm"f2 a2 g2 e2 | "Em"e4 d4        |
"A7"a2 a2 c'2 a2 | "D"d'6 f'2 | "G"b2 a2 g2 f2 | "A7"a4 e4      |
"D"d2 d2 f3 e | "G"d2 G2 B4 | "Bm"f2 f2 a2 g2 |1 "A7"f3 e d4   :|2 "A7"f3 e d3 c ||
|:"D7"d4 F2 A2 | "G"G4 B3 d | "Em"e2 G2 e2 G2 | "C"C2 E2 c4    |
"Am"a2 c'2 e3 d | "B7"B2 d2 f4 | "Em"e2 f2 g3 e | "A7"a4 a2     |
"D7"F2 F2 A2 d2 | "G"G2 B2 d4 | "Em"G2 e2 f4 | "C"cGA e2 c2   |
"Am"a2 c'2 e3 d | "D7"F2 A2 d2 f2 | "G"G2 G4 :|
V:2 clef=treble name="Violin 2"
%%MIDI program 48
|:"G"d4 B2 d2 | "C"e4 c2 e2 | "Am"c2 d2 e2 f2 | "D7"g6 f2    |
"G"g2 f2 e2 d2 | "Em"b4 b2 g2 | "C"c'2 a3 g2 | "G"f4 z4     |
"G"d2 d2 B2 G2 | "C"e4 g2 e2 | "Am"a2 c3 B2 | "D7"a4 d4    |
"G"b4 b2 g2 | "Em"e3 e e2 c2 | "C"c2 g3 e2 |1 "G"d4 z4    :|2"G"d4 z4 ||
|: "C"c2 e2 g3 e | "D"f2 a2 f3 d | "Em"e4 g2 B2 | "Am"c'2 e2 d4 |
"D"f2 d2 d'3 c | "G"b3 a g2 B2 | "C"c2 e2 c4 |1 "G"d4 z4    :|2 "G"d4 Q:1/4=110 z4 ||
|:"D"d4 d2 f3 a | "G"g3 e d2 B2 | "Bm"f2 a2 a2 b2 | "A7"c'6 c'2  |
"D"a2 f2 d4 | "G"B2 d2 B4 | "Bm"c4 c2 e2 | "Em"g3 b a3 g    |
"A7"c'4 c'2 a2 | "D"f3 d d2 f2 | "G"B3 G G2 B2 | "A7"a4 a2    |
"D"d4 f3 a | "G"g3 e d2 B2 | "Bm"f2 b2 b4 |1 "A7"c'3 a b4  :|2 "A7"c'3 a a3 g ||
|:"D7"g4 g2 b2 | "G"d2 B2 g2 B2 | "Em"e4 e2 c2 | "C"c2 e2 g4  |
"Am"a2 c2 e4 | "B7"f2 d2 d4 | "Em"g4 g2 e2 | "A7"c'6 a2     |
"D7"g2 g2 f2 d2 | "G"G2 G2 B4 | "Em"e2 g2 e4 | "C"c'2 a2 a2 g2 |
"Am"c2 e2 e4 | "D7"b2 g2 g4 | "G"d2 B2 G4 :|
V:3 clef=alto name="Viola"
%%MIDI program 48
|:"G"B3 G E2 C2 | "C"c4 G2 E2 | "Am"A3 E C2 A2 | "D7"F6 D2   |
"G"G2 F2 E3 C | "Em"E4 C2 E2 | "C"B2 A2 G3 E |1 "G"G4 z4  :|2 "G"G4 z4 ||
|:"C"E2 G2 c3 e | "D"F2 A2 d3 f | "Em"B2 G2 E2 c2 | "Am"E4 C2 A2 |
"D"F2 d2 f2 a2 | "G"B2 d2 b3 g | "C"c2 e2 g2 c2 |1 "G"d4 z4  :|2 "G"d4 Q:1/4=110 z4 ||
|:"D"F2 D2 d2 f2 | "G"G2 B2 d4 | "Bm"b2 a2 a2 gf | "A7"a4 e3 c |
"D"d2 d2 F2 A2 | "G"B2 G2 B2 d2 | "Bm"b2 a2 b2 gf | "Em"G2 F2 E2 D2 |
"A7" c'2 B2 c'2 a2 | "D"f2 d2 A2 d2 | "G"B2 G2 D2 G2 | "A7"E2 C2 c2 E2 |
"D"A2 F2 d3 c | "G"B2 G2 d2 G2 | "Bm"a2 f2 b2 a2 |1 "A7" g2 f2 e4 :|2 "A7" g2 f2 e3 d ||
|:"D7"F4 F2 A2 | "G"G2 D2 F3 D | "Em"E2 e2 G2 B2 | "C"c2 B2 c4 |
"Am"A4 c'2 e2 | "B7"d2 B2 d4 | "Em"e4 _B2 G2 | "A7"A4 F2 |
"D7" A2 F2 d2 F2 | "G" B2 G2 E2 G2 | "Em" e4 B2 G2 | "C" c2 A2 C2 E2 |
"Am" A2 c2 e3 d | "D7" F2 A2 d4 | "G" D4 G2 :|
V:4 clef=bass name="Cello"
%%MIDI program 48
|:"G"D2 G,2 B,2 D2 | "C"C2 E2 G2 C2 | "Am"A,2 C2 E2 A2 | "D7"D2 F2 A2 d2 |
"G"G,2 D2 G2 B,2 | "Em"E2 G2 B2 e2 | "C"C2 G,2 C2 E2 |1 "G"G,2 B,2 D2 :|2 "G"G,2 B,2 D2 G,2 ||
|:"C"C2 G,2 C2 E2 | "D"D2 A,2 D2 F2 | "Em"E2 G,2 B,2 e2 | "Am"A,2 E2 A4 |
"D"D2 A,2 D2 f2 | "G"G2 D2 G4 | "C"C2 E2 G2 c2 |1 "G" G4 z4 :|2 "G" G4 Q:1/4=110 z4 ||
|:"D"F2 A,2 D3 F | "G"G2 D2 G4 | "Bm"B,2 D2 F2 A2 | "A7"A4 e4 |
"D"A2 d2 f2 a2 | "G"B4 d4 | "Bm"B,2 D2 G3 B | "Em"E2 G2 B3 e |
"A7"a2 c2 e4 | "D"f2 f2 a4 | "G"B2 B2 d4 | "A7"a4 e4 |
"D"A4 d3 F | "G"G2 G2 B4 | "Bm"B,2 D2 F4 |1 "A7" E4 c2    :|2 "A7" E4 c3 d ||
|:"D7"D4 F2 A2 | "G"G,4 G2 D2 | "Em"E4 e2 G2 | "C"C2 E2 G2 c2 |
"Am"A4 A2 c2 | "B7"B,4 B2 d2 | "Em"E4 B2 e2 | "A7"A2 F2 A2 c2 |
"D7"F4 F2 A2 | "G"G,2 B,2 D4 | "Em"E2 c2 e2 | "C" C4 E2 G2 |
"Am" A4 A2 c2 | "D7" D2 F2 A4 | "G" G,4 G2 :|



X:1
T:Bandstand Frolic
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Gmaj
% Fun and rhythmic tune for band members
V:1 clef=treble
%%MIDI program 56
|:"G"D4 BAGB| "C"E4 G3E| "D"D3E FGAF| "G"G6 z2|
"C"EFGA "G"D2B2| "D"A2F2 D4| "Em"BAGF E2D2| "G"G6 z2:|
V:2 clef=treble
%%MIDI program 71
|:"G"B2d2 d4| "C"c3B c4|"D"A2d2 F4| "G"G4 z4|
"C"c4 e3d | "G"B2G2 G4|"D"F4 A2A2| "G"G4 z4:|
V:3 clef=treble
%%MIDI program 68
|:"G"g4 d3B| "C"e3d c4|"D"d2cB AGFE| "G"g4- g2z2|
"C"e2G2 A2F2| "G"G2D2 z4| "D"A4- A2GF| "G"G4 z4:|
V:4 clef=bass
%%MIDI program 61
|:"G"G,2B,2 D4| "C"C2E2 G,4| "D"D4 F2A2| "G"G,4 z4|
"C"C2 E2 G4| "G"G,2B,2 D4| "D"D2 A2 F4| "G"G,4 z4:|



X:1
T:Colors of Spring - Extended
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=140
K:D
V:1 clef=treble name="Vibraphone"
%%MIDI program 11
|:"D" FA dA z2 FA| "G"G2 z2 BAGF|"Em"E2 G2 z2 F2|"A7"A4- A2 z2|
"D" fd A2 z2 f2|"Bm"b4 z2 a2|"G"g2 fe z2 "A7"ce|"D"d4- d2 z2:|
|:"A" e2c2 E2C2| "D"F2D2 F2AF| "Bm"g2f2 g2bg| "A7"a4 a2z2|
"G" G2B2 d2G2| "D/A"A2F2 A2d2| "Em"G2E2 C2E2| "A7"A6 z2:|
V:2 clef=bass name="Fretless Bass"
%%MIDI program 35
|:"D"D,4 F,4|"G"G,4 B,4|"Em"E,4 G,4|"A7"A,4 C4|
"D"D,4 F,4|"Bm"B,4 D4|"G"G,4 B,4|"A7"A,4 E,4:|
|:"A"C4 E,4|"D"F4 A,4|"Bm"D4 F4|"A7"E4 C4|
"G"B,4 D4|"D/A"A,4 E4|"Em"G,4 B,4|"A7"E4 A,4:|



X:1
T:Arpeggiated Fantasia
C:OrchestrAI
M:C
L:1/16
Q:1/4=160
K:Am
V:1 clef=treble
%%MIDI program 1
|:"Am"ae'c'a gecg ae'c'a g^feg| "E7"bfdf bgec bfdf e2^d2| "Am"a2e2 c'2a2 e2c2 a4| "F"fcfc Acfc fcAc e2A2|
"C"Gcec Gcec Gcec G2E2| "Dm"f2af dfaf dfaf d2A2| "E7"g^feg be'gb ge^ce a2e2| "Am"a8 a4 z4:|
|"C"Gcec Gcec Gcec G2E2| "Dm"f2d2 A2f2 d2A2 F4| "E7"e2^G2 B2e2 g2B2 e4| "Am"a4 e'4 a4 z4|
"F"e2c2 A2f2 c2A2 F4|"G"B2G2 E2ce G2E2 C4|"Dm"A2d2 F2Ad f2A2 d4|"E7"^G,2B,2 E2Ge b2e2 ^G4|
"Am"ae'c'a gecg ae'c'a g^feg| "E7"bfdf bgec bfdf e2^d2| "Am"a2e2 c'2a2 e2c2 a4| "F"fcfc Acfc fcAc e2A2|
"C"Gcec Gcec Gcec G2E2| "Dm"f2af dfaf dfaf d2A2| "E7"g^feg be'gb ge^ce a2e2| "Am"a8 a4 z4||



X:1
T:Friday Night (Extended)
C:JLEM / OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Bb
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
FGA | "Bb"B2 ._A.B z2 GA | "Eb"B2 ._A.B z2 "F"GF | "Bb"D4 FGA2 | "Gm"G2A2 B2A2 |
"Eb"G2F2 G2A2 | "Bb"B6 GA | "F"B2A2 G2F2 | "Gm"E4 z4 z4 |
w: Be-cause it's Fri-day night! And we're feel-in' right! There's no place I'd ra-ther be, Let the mu-sic set you free.
V:2 clef=treble name="Electric Piano"
%%MIDI program 5
D4 z4 | "Bb"D2E2 D2C2 | "Eb"D2E2 D2 "F"C3 | "Bb"B,4 D2E2 | "Gm"G2F2 E2D2 |
"Eb"D2C2 D4 | "Bb"B,4 ^A,4 | "F"A,4 =A,4 | "Gm"G,4 z4 |
V:3 clef=bass name="Bass"
%%MIDI program 33
z4 | "Bb"B,2F2 B,4 | "Eb"G,2E2 "F"F,4 | "Bb"D,2F2 B,4 | "Gm"G,2B,2 D4 |
"Eb"G,4 E2G2 | "Bb"B,4 F4 | "F"A,2C2 F4 | "Gm"G,2B,2 D4 |



X:1
T:Shostakovichian Quartet in C Minor
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Cmin
V:1 clef=treble name="Violin I"
%%MIDI program 40
|: "Cm"G4- G2 AB| "Ab"c4- c2 cd| "Eb"B4- B2 G2| "Bdim7" F4- F2 FE|
"Dm7b5"D4- D2 EF| "G7" G2 B2 d2 c2- | "Cm"c4- c4- | c4 z4 :|
| "Cm"e2 d2 c2 B2| "Ab" a2 g2 f2 e2| "Eb"g2 f2 g2 a2| "Bdim7"b2 a2 g2 f2|
"Dm7b5"e2 d2 c2 B2| "G7" g2 f2 e2 d2| "Cm"c2 B2 "G7" B2 d2| "Cm"c4 z4 :|
V:2 clef=treble name="Violin II"
%%MIDI program 40
|: "Cm"E4- E2 FG| "Ab"A4- A2 AB| "Eb"G4- G2 E2| "Bdim7" D4- D2 DC|
"Dm7b5"D4- D2 FG| "G7" B,4- G2 G2| "Cm"C4- C4- | C4 z4 :|
| "Cm"g2 f2 e2 d2| "Ab" c'2 b2 a2 g2| "Eb"e2 d2 e2 f2| "Bdim7"d2 c2 B2 A2|
"Dm7b5"g2 f2 e2 d2| "G7" c'2 b2 a2 g2| "Cm"G2 F2 "G7" B2 d2| "Cm"c4 z4 :|
V:3 clef=alto name="Viola"
%%MIDI program 41
|: "Cm"C2 C2 D4| "Ab"F2 F2 G4| "Eb"E2 B,2 C4| "Bdim7" D2 A,2 F4|
"Dm7b5"D2 A,2 D4| "G7" G2 B,2 D4| "Cm"C4- C4- | C4 z4 :|
| "Cm"c2 B2 A2 G2| "Ab" f2 e2 d2 c2| "Eb"G2 F2 E2 D2| "Bdim7" A,2 A,2 G2 F2|
"Dm7b5"D2 C2 B,2 A,2| "G7" B,2 A,2 G2 F2| "Cm"E2 F2 "G7" d2 c2| "Cm"c4 z4 :|
V:4 clef=bass name="Cello"
%%MIDI program 42
|: "Cm"G,2 G,2 C4| "Ab"C2 C2 F4| "Eb"E2 E2 B,4| "Bdim7" F2 F2 B,4|
"Dm7b5"D2 D2 A,4| "G7" G,2 G,2 D4| "Cm"C4- C4- | C4 z4 :|
| "Cm"G,2 A,2 B,2 C2| "Ab"F2 G2 A2 B2| "Eb"B,2 C2 D2 E2| "Bdim7" F4- F2 E2|
"Dm7b5"A,4 G,4| "G7" D2 E2 F2 G2| "Cm"C2 B,2 "G7" G,4| "Cm"c4 z4 :|



X:1
T:Complex Conversations
M:5/4
L:1/16
Q:1/4=140
K:C
V:1 clef=treble name="Piano RH"
%%MIDI program 1
|: [Ac]4 [EGc] [E^Gce]8 | [^G^ce]4 [A=F^ce] [A,2F2^c2e2] | [E2A2c2e2]8 [EAG^c] [E2A,2C2E2] | [^G2B,2D2^F2]12 [Ac] z4 :|
|: [G_BD]4 [^GBe] [A=Fc']8 | [c'2e'2a'2]8 [c'e'a']4 [c'^f'a']8 | [a2=c'2e'2g'2]8 [a=c'eg'] [a=c'e'g''] | [b2d'2f'2a'2]12 [e'g''] z4 :|
|: [C2E2G2c2]8 [E2G2c2e2]8 | [Gce]4 [G2B,2D2G2]6 | [GBd]4 [^F2=A,2C2]8 | [A,2F2^c2e2]8 [Ac]8 :|
|: [C^FAd]4 [_B,DG] [_EF^Ac]8 | [_B,^DG]4 [_D^FGA] [_D,2^F2G2] | [DGB]4 [E2A,2C2E2]8 | [E2A2^c2e2]8 [EAG^c]8 :|



X:1
T:Focus Groove
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Am
% The beginning section to establish motifs and themes
V:1 clef=treble name="Clarinet"
%%MIDI program 71
|: "Am" E3 A A2e2 | "G" d2 B G2 Bc z | "F" c2 A d2 A2 z | "E" B3 E E2A2 |
| "Am" A2a2 e2 a2 | "C" c'2 e2 g2f2 | "F" f2g2 a2d2 | "E" e2d2 E2 (3EBc z2 |
% Transition for middle section development
| "Dm" d3 c d2 A2 | "Am" e2A2 a3g | "C" e4 d2c2 | "G" B,3 D G2 g2 |
% Continue development with modulation
| "F" a2 B A2 z B2| "C" e3 ^f g2 c2 | "E" d3 b a2e2 | "Am" e2c'2 B2d2 |:|
V:2 clef=treble name="Bassoon"
%%MIDI program 70
|: "Am" A2 c d2 c2 z | "G" B2 G D2 G C z | "F" A2 d g2 d2 z | "E" B2 A E2 c2 z |
| "Am" A4 c2 a2 | "C" e2 g e2 c'2 z | "F" a4 d2 d2 | "E" e2d2 c2 E2 |
% Transition for middle section development
| "Dm" d2c2 d2 A2 | "Am" A2 e2 a2e2 | "C" e4 c2 ef | "G" g2 (3def d4 z2 |
% Continue development with modulation
| "F" c2e2 d2 g2 | "C" g'3 c e2 c2 | "E" e2 ^f2 g2a2 | "Am" a2e2 d2 a2 |:|
V:3 clef=treble name="Violin"
%%MIDI program 41
|: "Am" e2a2 a2e2 | "G" d2g2 B2g2 | "F" f2a2 a2d2 | "E" e3 B A2E2 |
| "Am" c'a c'a a2e'2 | "C" g2e a2f2 z | "F" a'a f'a d'a z2 | "E" b2a2 b2 c'2 |
% Transition for middle section development
| "Dm" c'a c'a d'c z2 | "Am" e'a f'e a'b z2 | "C" c'e' d2f2 z2 | "G" b3 g e2c'2 |
% Continue development with modulation
| "F" A'a f'a d'g z2 | "C" g'g e'd c'c' z2 | "E" e'f' g'a b'' z3 | "Am" c'3 e a4 |:|
V:4 clef=bass name="Acoustic Bass"
%%MIDI program 32
|: "Am" A,,2E, A,2 E,A, z | "G" G,2B, D,2 G,,D z | "F" F,2A, C,2 F,A, z | "E" E,2B, E,2A, z2 |
| "Am" A,2A,, E,A, E,A, z | "C" G,2G,, E,G, c2e | "F" F,2C, A,2 F,C, z | "E" E,2C, E,2 G,, z2 |
% Transition for middle section development
| "Dm" D,2A, D,2F, z2 | "Am" E,2A,, E,A, A,E, z | "C" G,3 G,,B,, C,G, z | "G" D,2B,, D,4 z |
% Continue development with modulation
| "F" F,2C, F,2A, z2 | "C" C,2C, e,2C, z2 | "E" E,B,, E,E, G,e z2 | "Am" A,,2E, A,2.E,A, z |:|



X:1
T:Autumnal Equinox
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:G
V:1 clef=treble
%%MIDI program 71
|:"G"B4 BAGB|"Am"A4 A2de|"Em"g2 e2 e4|g4 f2e2|
"C"e4 d2ed|"G"B4 A2GF|"Am"A3 B cBAG|"D7"F2D4 z2|
"G"B4 (3ABA z2 G2|"C"e4 (3cBA z2 e2|"Am"a4 g2fe|"D"def2 e2gf|
"Em"g2b2 a2g2|"Am"a6 ga|"C"b4 a2gf|"G"g8:|
V:2 clef=treble
%%MIDI program 73
||:"G"B2D4 G2|"Am"A2C4 A2|"Em"G2E4 G2|E8|
"C"E2G4 C2|"G"G2B4 G2|"Am"A2E4 A2|"D7"A2D4 A2|
"G"B2G4 G2|"C"C2E4 C2|"Am"A2C4 A2|"D"F2A4 D2|
"Em"G,2B,4 G,2|"Am"A,2C4 A,2|"G"G,2B,4G,2|"G"G,8:|
V:3 clef=bass
%%MIDI program 43
|:"G"G,2B,2D4|"Am"A,2E4 A,2|"Em"G,2E4 G,2|"G"E,8|
"C"C,2G,2C4|"G"G,2D4 G,2|"Am"A,2E4 A,2|"D7"F,2A,2D4|
"G"G,2D4 G,2|"C"C,2E4 C,2|"Am"A,2C4 A,2|"D"F,2A,4 F,2|
"Em"G,2E4 G,2|"Am"A,2C4 A,2|"C"C2E2G z22|"G"G,8:|



X:1
T:Noir Breakthrough
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:Bm
V:1 clef=treble name="Piano" subname="Piano"
%%MIDI program 0
|: "Bm"B3 c d2e2 | "E7"e4 z2 E2 | "A7"A4 ^G2A2 | "D"F4 A4 |
"Bm"B3 c dBAB | "E7"E2C2 E4 | "A7"A2c2 "D"d2^c2 | "Bm"B,4 z4 :|
V:2 clef=treble name="Saxophone" subname="Sax."
%%MIDI program 65
|: "Bm"d2F2 B2d2 | "E7"e4 c2B2 | "A7"A3 G F2E2 | "D"F2D2 D4 |
"Bm"d2f2 "E7"g2f2 | "A7"e2d2 c2B2 | "D"A2F2 "G"F4 | "Bm"B4 z4 :|
V:3 clef=bass name="Contrabass" subname="Bass"
%%MIDI program 43
|: "Bm"B,4 E2F2 | "E7"G,4 B,4 | "A7"A,2A,2 C2E2 | "D"F,4 D4 |
"Bm"B,2B,2 "E7"E2G,2 | "A7"A,2C2 "D"D2F2 | "D"A,2F2 "Bm"E4 | "Bm"B,4 z4 :|



X:1
T:Colors of Spring
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=140
K:D
% The piece starts with a flowing melody representing the emergence of spring
V:1 clef=treble
%%MIDI program 11
|:"D" FA dA z2 FA| "G"G2 z2 BAGF|"Em"E2 G2 z2 F2|"A7"A4- A2 z2|
"D" fd A2 z2 f2|"Bm"b4 z2 a2|"G"g2 fe z2 "A7"ce|"D"d4- d2 z2:|
% As we "walk" through the garden, the melody becomes more vibrant and colorful
[|"D"A2 d2 "G"B2 G2|"A7"E2 F2 G2 A2|"D"F2 d2 "Bm"c2 A2|"Em"G4- G2 z2|
"D"A2 d2 "G"B2 d2|"A7"c2 A2 B2 c2|"D"d2 F2 "A7"A2 G2|"D"D4- D2 z2:|
% The bridge captures the gentle sway of flowers in the breeze
[|"G"B2 d2 c2 B2|"Bm"A4 G2 F2|"E7"G2 F2 E2 D2|"A7"C4- C2 z2|
"G"B2 d2 c2 B2|"D"A4 F2 E2|"G"D2 E2 "A7"F2 G2|"D"A,4- A,2 z2:|
% The final section returns to the main theme, celebrating the full bloom of spring flowers
[|"D"F2 A2 d2 c2|"G"B4 A2 G2|"Bm"F2 "Em"A2 B2 G2|"A7"A4- A2 z2|
"D"f2 a2 "G"g2 f2|"Em"e4 d2 c2|"G"B2 A2 "A7"G2 F2|"D"D4- D2 z2:|
V:2 clef=bass
%%MIDI program 35
|:"D"D,4 F,4|"G"G,4 B,4|"Em"E,4 G,4|"A7"A,4 C4|
"D"D,4 F,4|"Bm"B,4 D4|"G"G,4 B,4|"A7"A,4 E,4:|
[|"D"D,4 A,4|"G"G,4 B,4|"A7"A,4 C4|"D"D,4 A,4|
"D"D,4 A,4|"G"G,4 B,4|"A7"A,4 C4|"D"D,4 A,4:|
[|"G"G,4 B,4|"Bm"B,4 D4|"E7"E,4 G,4|"A7"A,4 C4|
"G"G,4 B,4|"D"A,4 F,4|"G"G,4 "A7"B,4|"D"D,4 A,4:|
[|"D"D,4 F,4|"G"G,4 B,4|"Bm"B,4 D4|"A7"A,4 E,4|
"D"D,4 F,4|"G"G,4 B,4|"Em"E,4 G,4|"A7"A,4 D,4:|



X:1
T:War of Titans
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=152
K:Cm
V:1 clef=treble name="Violins 1"
%%MIDI program 40
|:"Cm" g4 (3efg a2g2 | "G" (3ded B2A2 G4 | "Cm" c4 (3dcB c2B2 | "Fm" f4 (3ede f2e2 |
"Cm" g4 (3aga b2g2 | "G" d4 (3ded B2A2 | "Cm/A" a4 (3aga b2g2 | "Bb"G4- G2A2 B |
"Cm" e4 (3cdc e2d2 | "Ab" c4 (3BcB c2B2 | "Eb" G4 (3AGA B2G2 | "G7" (3D^CD E2F2 G4 |
"Cm" c2c2 "G" d2B2 | "Ab/Eb" e2c2 "Fm/D" d2A2 | "Cm/Bb" g2G2 "G7" d2c2 | "Cm"c6 z2 :|
V:2 clef=treble name="Violins 2"
%%MIDI program 40
|:"Cm" e3e e2dc | "G" B3B B2GA | "Cm" G3G G2FE | "Fm" c3c c2BA |
"Cm" e3e e2dc | "G" B3B B2GA | "Cm/A" c3c c2BA | "Bb" F3F F2ED |
"Cm" G3G G2FE | "Ab" C3C C2BA | "Eb" E3E E2DC | "G7" B,3B, B,2C2 |
"Cm" G2G2 "G" B2G2 | "Ab/Eb" C2C2 "Fm/D" F2C2 | "Cm/Bb" E2E2 "G7" B2A2 | "Cm"G6 z2 :|
V:3 clef=alto name="Violas"
%%MIDI program 41
|:"Cm"c4 (3BcB d2c2 | "G" (3ABA G2F2 E4 | "Cm" G4 (3FGF G2F2 | "Fm" (3ABA c2B2 A4 |
"Cm" c4 (3cdc d2c2 | "G" (3BAG F2E2 D4 | "Cm/A" A4 (3AGA B2A2 | "Bb"F4- F2G2 A |
"Cm" G4 (3FGF G2F2 | "Ab" C4 (3BcB d2c2 | "Eb" B,4 (3ABc B2A2 | "G7" (3GFE D2E2 F4 |
"Cm" G2G2 "G" F2D2 | "Ab/Eb" E2C2 "Fm/D" c2A2 | "Cm/Bb" d2D2 "G7" F2E2 | "Cm"c6 z2 :|
V:4 clef=bass name="Cellos"
%%MIDI program 42
|:"Cm"G,4 (3FGA B2G2 | "G" D3 E F2G2 A | "Cm"C4 (3BcB C2B,2 | "Fm"A2F2 E2C2 D |
"Cm"G,4 (3FGA B2G2 | "G" D4 (3EDE F2G2 | "Cm/A" A,4 (3AGA B2c2 | "Bb"F4- F2G2 A |
"Cm"G,4 (3FGA B2G2 | "Ab" C4 (3BcB C2B,2 | "Eb"E4 (3D^CD E2F2 | "G7" D2G,2 F2E3  |
"Cm"C2C2 "G" B,2G2 | "Ab/Eb"C2C2 "Fm/D"A,2F2 | "Cm/Bb"G,2G2 "G7"D2C2 | "Cm"G,6 z2 :|
V:5 clef=bass name="Double Basses"
%%MIDI program 43
|:"Cm"C,6 C,2 | "G"G,,6 D,2 | "Cm"G,,6 C,2 | "Fm"F,6 A,,2 |
"Cm"C,6 C,2 | "G"G,,6 D,2 | "Cm/A"A,,6 F,2 | "Bb"F,6 B,,2 |
"Cm"C,6 C,2 | "Ab"A,,6 E,2 | "Eb"E,6 B,,2 | "G7"D,6 G,,2 |
"Cm"C,2C,2 "G"G,,2D,2 | "Ab/Eb"C,2C,2 "Fm/D"F,2A,,2 | "Cm/Bb"G,,2G,,2 "G7"D,2C,2 | "Cm"C,6 z2 :|



X:1
T:Funky Brass Groove
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:Cmaj
% Trumpet on MIDI program 56 - Trumpet
V:1 clef=treble
%%MIDI program 56
|: "C"c2e2 g2e2| "F7"f2d2 c2A2| "C"G4- G2c2| "G7"B,4 z4|
"C"c2e2 g2e2| "F7"f2d2 c2A2| "C"g2e2 "G7"d2B2|1 "C"c8 :|2 "C"c6 z2|
| "F"f2a2 a2g2| "C"e4 c4| "F"a2f2 d2c2| "G7"B2G2 G4|
"F" f6 f2| "C"e4 c2e2| "F"d2c2 "G7"B2A2| "C"c8 |]
% Tuba on MIDI program 58 - Tuba
V:2 clef=bass
%%MIDI program 58
|: "C"C,4 E,4| "F7"F,4 A,4| "C"C,4 E,4| "G7"G,4 D,4|
"C"C,4 E,4| "F7"F,4 A,4| "C"C,4 "G7"B,,4|1 "C"C,8 :|2 "C"C,6 z2|
| "F"F,4 A,4| "C"C,4 E,4| "F"F,4 A,4| "G7"G,4 D,4|
"F"F,4 A,4| "C"C,4 E,4| "F"F,4 "G7"B,,4| "C"C,8 |]



X:1
T:Clockwork Carnival
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=152
K:Gmaj
V:1 clef=treble name="Flute 1"
%%MIDI program 73
|:"G" B2B2 d2g2 |"D7" f2a2 g4 | "C"e2e2 "G"d2B2 | "A7"A2F2 "D7"F2G2 | "G" G4- G2z2 :|
V:2 clef=treble name="Flute 2"
%%MIDI program 73
|:"G" d4 B2G2 | "D7"A4 F2A2 | "C"E4 "G"D4 | "A7"c4 "D7"B4 | "G" G8 :|
V:3 clef=treble name="Clarinet 1"
%%MIDI program 71
|:"G" d2d2 B2G2 | "D7"f3e d2c2 | "C"e2e2 "G"g2e2 | "A7"c2A2 "D7"A2G2 | "G" G4- G2z2 :|
V:4 clef=bass name="Bassoon"
%%MIDI program 70
|:"G" G,2B,2 D4 | "D7"F2A2 D4 | "C"C2E2 G,4 | "A7"C2E2 "D7"F4 | "G" G,4- G,2z2 :|
V:5 clef=treble name="Trumpet"
%%MIDI program 56
|:"G" B4 d4 | "D7" g4 f4 | "C"e4 "G"d2B2 | "A7"A2F2 "D7"F2G2 | "G" G8 :|
V:6 clef=treble name="First Violins"
%%MIDI program 40
|:"G" d2^c2 d2e2 | "D7"f2g2 a4 | "C"g2f2 "G"e2d2 | "A7"c2e2 "D7"d4 | "G" B8 :|
V:7 clef=alto name="Viola"
%%MIDI program 41
|:"G" B,4 D4 | "D7"G,4 A,4 | "C"C4 "G"B,4 | "A7"A,4 "D7"B,4 | "G" G,8 :|
V:8 clef=bass name="Cello"
%%MIDI program 42
|:"G" G,2B,2 D4 | "D7"F2A2 D4 | "C"C2E2 G,4 | "A7"C2E2 "D7"F4 | "G" G,4- G,2z2 :|
|:"G" d8 | "D7" d8 | "C" e8 | "A7" c8 | "G" G8 :|
V:9 clef=bass name="Double Bass"
%%MIDI program 43
|:"G" G,,2B,,2 D,4 | "D7"F,2A,2 D,4 | "C"C,2E,2 G,,4 | "A7"A,,2C,2 "D7"F,4 | "G" G,,8 :|



X:1
T:Triumphant Aspirations
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Cmaj
V:1 clef=treble
%%MIDI program 56
|"C"E4 G4|"G"B4 A2 G2|"Am"E2 C4 E2|"Dm" F2 D4 F2|
"A7" E2 ^C4 E2|"Dm"A,4 A,2 D2|"G7"G4 F2 E2|"C"C6 z2|
|"F"A4 c4|"C"e4 d2 c2|"Am"c2 A4 c2|"Dm"d2 B4 d2|
"G7" c2 G4 B2|"C"E4 C2 E2|"F"A4 G2 F2|"G7"G6 z2|
|"C"E2 G4 G2|"E7"G2 B4 B2|"Am"A2 c4 c2|"Ab"A2 B2 c4|
"F"A2 A2 B2 c2|"Dm"F2 G2 A2 B2|"G7"C2 E4 D2|"C"C8|
V:2 clef=bass
%%MIDI program 42
|"C"C,4 E,4|"G"D,4 C,4|"Am"A,4 E,4|"Dm"D,4 F,4|
"A7"A,4 ^C,4|"Dm"D,4 A,4|"G7"G,4 F,4|"C"C,6 z2|
||"F"F,4 A,4|"C"C,4 B,,4|"Am"A,4 E,4|"Dm"D,4 D,4|
"G7"B,,4 G,4|"C"C,4 E,4|"F"F,4 E,4|"G7"G,6 z2|
||"C"C,4 E,4|"E7"G,4 E,4|"Am"A,4 E,4|"Ab"G,4 G,4|
"F"F,4 F,4|"Dm"D,4 A,4|"G7"B,,,4 E,4|"C"C,8|



X:1
T:Hold The Groove
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Cmaj
V:1 clef=treble
%%MIDI program 80
||:"C"E2G2 c2E2|"F"F2A2 d2F2|"G"G2B2 d4|"Am"e3d c2B2|
"C"c4 -c2E2|"F"f4 -f2A2|"G"g4 -g2B2|"Am"a3g f2e2|
|"C"E2c2 G2E2|"F"D2F2 A2d2|"G"D2B2 G2d2|"Am"e3d c4|
"C"e2G2 c2E2|"F"d2A2 f2A2|"G"d2B2 G2B2|"C"c6 z2:|
V:2 clef=bass
%%MIDI program 33
|:"C"G,4 -G,2C2|"F"A,4 -A,2F2|"G"B,4 -B,2G2|"Am"C4 E4|
"C"G,4 -G,2C2|"F"A,4 -A,2F2|"G"B,4 -B,2G2|"Am"C4 E4|
|"C"G,4 -G,2E2|"F"A,4 -A,2D2|"G"B,4 -B,2D2|"Am"C4 E4|
"C"G,2C2 E2G2|"F"A,2F2 A2c2|"G"B,2G2 D2B,2|"C"C6 z2:|



X:1
T:Confrontation of Titans
C:OrchestrAI
M:12/8
L:1/8
Q:1/4=170
K:Cm
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|: "Cm"G2E G2c G2E c2B | "G"A2D A2F G2B d2c | "Ab"B2E c2B F2E G2F | "Bb"G3 -G2G z2F E3 |
"Cm"G2E G2c G2E c2B | "G"A2D A2F G2B d2c | "Cm"E2C E2G E2C G2F |1 "G"G3 -G2F D3 -D3 :|2 "G"G6 -Gz2D3 ||
|: "Cm"e2g c'2e gage z2 | "Fm"f2a c'2f afaf z2 | "G"b2d' gfgd B2d g2^ | "Ab"e2c GceA G2E C2 |
"Cm"e2g c'2e gage z2 | "Fm"f2a c'2f afaf z2 | "G"g2b dgbd g2a bg | "Cm"G6 -Gz2 E4 - :|
V:2 clef=treble name="Choir Aahs"
%%MIDI program 52
|: "Cm"e2c G2c e2g c2G | "G"d2B G2d b2d' g2f | "Ab"c2G c2e g2c' e2c | "Bb"B3 -B2B z2G F3 |
"Cm"e2c G2c e2g c2G | "G"d2B G2d b2d' g2f | "Cm"c2G c2e c2G E2C |1 "G"G3 -G2G D3 -D3 :|2 "G"G6 -Gz2D3 ||
|: "Cm"g4 e2c4 z2 | "Fm"f4 c2a4 z2 | "G"g4 d2B4 z2 | "Ab"c'4 c2e4 z2 |
"Cm"g4 e2c4 z2 | "Fm"f4 c2a4 z2 | "G"g4 d2B4 z2 | "Cm"G6 -Gz2 E4 - :|
V:3 clef=bass name="Bass Synth"
%%MIDI program 87
|: "Cm"C,4 z2 G,4 z2 | "G"D,4 z2 B,,4 z2 | "Ab"C,4 z2 E,4 z2 | "Bb"F,4 z2 F,4 z2 |
"Cm"C,4 z2 G,4 z2 | "G"D,4 z2 B,,4 z2 | "Cm"C,4 z2 E,4 z2 |1 "G"G,4 z2 D,4 z2 :|2 "G"G,6 -G,z2D,3 ||
|: "Cm"C,4 C,4 C,4 | "Fm"F,4 F,4 F,4 | "G"G,4 G,4 D,4 | "Ab"Ab,4 Ab,4 E,2 |
"Cm"C,4 C,4 C,4 | "Fm"F,4 F,4 F,4 | "G"G,4 G,4 G,4 | "Cm"C,6 -C,z2 C,4 - :|



X:1
T:Pixel Adventure
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=144
K:Cmaj
% Upbeat and energetic tune with a strong electronic feel
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|:"C"E2E2 G4 z6 G2|"Am"A2A2 c4 z6 A2|"F"F2F2 A4 z6 c2|"G"G4-G2 z6 G4|
"C"G3E C2E2 z4 G4|"Em" B3G E2G2 z4 B4|"F"F2F2 A4 z6 c2|"G"G4-G2 z6 G4:|
|:"C"c4 e6 z2 g4| "Dm"d4 f6 z2 a4| "G"b2g2 d'4 z4 b2g2| "C"e4 c6 z2 z4|
"E"e2c2 e6 z2 g4| "Am"a2f2 a6 z2 c'4| "F"f4 d2f2 z4 a2f2| "G"g4 g2G2 z4 G4:|
V:2 clef=bass name="Bass Synth"
%%MIDI program 39
|:"C"C,4 C4 z8|"Am"A,4 A4 z8|"F"F,4 F4 z8|"G"G,4 G4 z8|
"C"C,4 C4 z8|"Em"E,4 E4 z8|"F"F,4 F4 z8|"G"G,4 G4 z8:|
|:"C"C,4 C4 z8| "Dm"D,4 D4 z8| "G"G,4 G4 z8| "C"C,4 C4 z8|
"E"E,4 E4 z8| "Am"A,4 A4 z8| "F"F,4 F4 z8| "G"G,4 G4 z8:|



X:1
T:Table for Two
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=90
K:Cmaj
% Instruments weave a tapestry of harmonic warmth and lyrical grace
V:1 clef=treble name="Violin I" % Melodic phrases with tender articulation
%%MIDI program 40
|: "C"E4- EG | "Am"C2 B,2 A,2 | "F"C4- CF | "G"E2 D2 C2 |
"C"G2- G2 A2 | "Dm"F2 E2 F2 | "G"E4 D2 |1 "C"C6 :|2 "C"C3 z A2 ||
[| "F"A4- cA | "C"E2 G3 F | "F"A4 A2 | "C"E2 D2 C2 |
"F"A3/G/ A2 B2 | "G"G2 F2 E2 | "Am"C4 C2 | "G"G6 |
"F"A4- cA | "C"E2 G3 F | "F"A4 A2 | "Am"E2 D3 E |
"F"A3/G/ A2 B2 | "G"G3/F/ E2 D2 | "C"C4 E2 | "G7"G3 z G2 | "C"C6 |]
V:2 clef=treble name="Violin II" % Countermelodies and harmonies
%%MIDI program 40
|: "C"C2 E2 G2 | "Am"A,2 C2 E2 | "F"A,2 C2 A,2 | "G"B,2 D2 G2 |
"C"C2 G,2 E2 | "Dm"A,2 F2 A2 | "G"B,2 D2 G2 |1 "C"C2 E2 G2 :|2 "C"C2 B,2 C2 ||
[| "F"A2 C2 E2 | "C"G2 E2 G2 | "F"A,2 C2 E2 | "C"G2 E2 G2 |
"F"A2 C2 E2 | "G"B,2 D2 G2 | "Am"A,2 C2 E2 | "G"B,2 D2 B,2 |
"F"A2 C2 E2 | "C"G2 E2 G2 | "F"A,2 C2 E2 | "Am"G2 E2 G2 |
"F"A2 C2 E2 | "G"B,2 D2 G2 | "C"C2 E2 G2 | "G7"B,2 D2 G2 | "C"C2 E2 G2 |]
V:3 clef=treble name="Viola" % Warmth in the middle range
%%MIDI program 41
|: "C"E2 G2 C2 | "Am"C2 E2 A,2 | "F"A,2 C2 F2 | "G"B,2 D2 G2 |
"C"E2 C2 E2 | "Dm"A,2 F2 D2 | "G"B,2 D2 G2 |1 "C"E2 G2 C2 :|2 "C"E2 G2 A,2 ||
[| "F"c2 A2 c2 | "C"E2 C2 E2 | "F"A,2 F2 A,2 | "C"E2 G2 C2 |
"F"c2 A2 c2 | "G"B,2 G2 B,2 | "Am"C2 E2 A,2 | "G"B,2 D2 G2 |
"F"c2 A2 c2 | "C"E2 C2 E2 | "F"A,2 F2 A,2 | "Am"C2 E2 A,2 |
"F"c2 A2 c2 | "G"B,2 G2 B,2 | "C"E2 G2 C2 | "G7"G2 B,2 D2 | "C"C2 E2 G2 |]
V:4 clef=bass name="Cello" % Rhythmic foundation and bass line
%%MIDI program 42
|: "C"C,2 G,2 C2 | "Am"A,,2 E2 A,2 | "F"F,2 C2 F2 | "G"G,2 B,2 D2 |
"C"C,2 E2 G,2 | "Dm"D,2 A,2 F2 | "G"G,2 D2 G,2 |1 "C"C,2 G,2 C2 :|2 "C"C,2 C2 D2 ||
[| "F"F,2 C2 A,2 | "C"G,2 E2 G,2 | "F"F,2 C2 F,2 | "C"E,2 C2 G,2 |
"F"F,2 C2 A,2 | "G"G,2 D2 B,2 | "Am"A,,2 E2 A,2 | "G"B,2 D2 G,2 |
"F"F,2 C2 A,2 | "C"G,2 E2 G,2 | "F"F,2 C2 F,2 | "Am"A,2 E2 A,2 |
"F"F,2 C2 A,2 | "G"G,2 B,2 D2 | "C"C,2 G,2 C2 | "G7"B,2 D2 G,2 | "C"C,2 E2 G,2 |]



X:1
T:Test Continued
L:1/8
Q:1/2=80
M:4/4
K:Gm
L:1/8
V:1 clef=treble name="Trumpet"
%%MIDI program 56
"D7" d2 AB cd/c/ BA |"Gm" G2 GB d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
$"Cm" z c2 e g2 fe | "Bb" z d2 B d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
$"Gm" d4 B4 |"D7" c4 A4 |"Gm" B4 G4 |"D7" ^F4 A4 |
$"Gm" d4 B4 |"D7" c4 A4 |"Bb" B2 d2 g4 |"D7" ^f8 :|
V:2 clef=bass name="Trombone"
%%MIDI program 57
"D7" G,8 | "Gm" G,4 F,4 | "D7" D,4 E,4 | "Gm" G,8 |
"Cm" E,8 | "Bb" D,8 | "D7" D,4 E,4 | "Gm" G,8 |
"Gm" D,8 | "D7" _A,8 | "Gm" G,8 | "D7" F,8 |
"Gm" D,8 | "D7" _A,8 | "Bb" B,,8 | "D7" D,8 :|



X:1
T:Whimsical Waltz
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=180
K:Emin
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "Em" B2 G2 E2 | "D" F3 G A2 | "G" G2 E2 D2 | "C" E4 B,2 |
"C" G3 F E2 | "Am" A4 ^G2 | "B7" f2 e2 d2 | "Em" e4 z2 |
"Em" B2 c2 d2 | "G" B3 A G2 | "C" e3 d c2 | "D" B4 A2 |
"G" G2 F2 E2 | "B7" F3 G A2 | "Am" A2 B2 c2 | "Em" e4 z2 :|
V:2 clef=treble name="Classical Guitar"
%%MIDI program 24
|: "Em" E4 B,2 | "D" F4 A,2 | "G" G3 E G2 | "C" c4 E2 |
"C" G3 E F2 | "Am" A4 E2 | "B7" B,3 A, B,2 | "Em" E4 z2 |
"Em" B,4 E2 | "G" G3 F E2 | "C" c4 e2 | "D" d3 F A2 |
"G" B,4 g2 | "B7" f3 a f2 | "Am" e3 f g2 | "Em" e4 z2 :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "Em" E,3 B,, E,2 | "D" F,3 A,, D,2 | "G" G,,3 B,, G,2 | "C" c,3 E,, C,2 |
"C" G,,3 E,, G,2 | "Am" A,,3 C, A,2 | "B7" B,,3 F, B,2 | "Em" E,3 B,, E,2 |
"Em" E,3 B,, E,2 | "G" G,,3 B,, G,2 | "C" c,3 E,, C,2 | "D" F,3 D, F,2 |
"G" G,,3 B,, G,2 | "B7" F,3 A,, F,2 | "Am" A,,3 E, A,2 | "Em" E,3 B,, E,2 :|



X:1
T:Bright Horizons
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:G
V:1 clef=treble name="Electric Guitar" subname="Gtr."
%%MIDI program 27
|:"G"D4 B4|"C"E6 A2|"D"G4 F4|"G"G6 z2|
"D"A4 d4|"C"C6 E2|"G"G4 D2F2|"D7"D6 z2|
"G"B4 B2d2|"Em"E4 G2F2|"Am"A4 c4|"D"D6 z2|
"G" G2B2 d2B2|"C"c2E2 G2E2|"G" D2G2 B,2D2|"D7" A4 F4|
"G"G4 B4|"C" E2D2 C4|"G"D4 B,2D2|"D7"G,4 A,4|
"G"B6 D2| "C"E4 C4|"G"G2B2 A2B2|"D7"A6z2 :|
V:2 clef=bass name="Electric Bass" subname="Bass"
%%MIDI program 33
|:"G"D,4 G,4|"C"C,4 E,4|"D"D,4 A,4|"G"G,4 D,4|
"D"A,4 D,4|"C"C,4 G,4|"G"G,4 D,4|"D7"D,4 A,4|
"G"B,4 D,4|"Em"E,4 G,4|"Am"A,4 E,4|"D"D,4 A,4|
"G"G,4 D,4|"C"C,4 G,4|"G"G,4 D,4|"D7"A,4 E,4|
"G"G,4 D,4|"C"C,4 E,4|"G"G,4 D,4|"D7"D,4 A,4|
"G"G,4 D,4|"C"E,4 C,4|"G"G,4 D,4|"D7"D,4 A,4 :|



X:1
T:Requiem for the Tripartite Clash
C:OrchestrAI
M:C
L:1/8
Q:1/4=80
K:Gmin
% Huserlian Humanists Theme
V:1
%%MIDI program 48
|:"Gm"D4 D2 B,2|"Cm"G,4 G2 E2|"Dm"F4 A2 d2|"Gm"D4 z4|
"Eb"B,4 E2 G2|"Bb"F3 G A2 B2|"Gm"G4-A4|"Dm"A4 z4:|
% Neo Nietzscheans Theme
V:2
%%MIDI program 51
|:"Gm"D2 F2 D2 G2|"Cm"C2 E2 G2 E2|"Dm"D2 F2 A2 F2|"Gm"G4 z4|
"Eb"G2 B2 G2 E2|"Bb"F2 D2 F2 D2|"Gm"G2 B2 d3 c| "D7"A4-F4:|
% Bogdanovite Bogostroiteli Theme
V:3
%%MIDI program 49
|:"Gm"G2 A2 B2 A2|"Cm"G2 B2 c2 B2|"Dm"A2 c2 d2 c2|"Gm"B2 A2 G4|
"Adim"A2 G2 F2 G2|"Bb" F2 D2 C2 D2| "Eb"E2 C2 B,2 C2|"D7"D4 z4:|
% Culmination and Resolution
V:4
%%MIDI program 42
|: "Gm"D2>G2 B2d2|"Eb"B2>G2 E2 G2| "Cm"G2>E2 C4|"D7"F2>A2 D4|
"Gm"G2>B2 D2 G2|"Bb"F2 D2 F4|"Eb"E2>G2 B2>G2 | "Gm"G8 :|



X:1
T:OrchestrAI's Odyssey
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:D
V:1 clef=treble name="Piano"
%%MIDI program 0
|:"D" A,2D2 F2A2 | "G" G,2B,2 D2G2 | "A7" ^F,2A,2 C2E2 | "D" D,4 D4 |
"D/F#" A,4 "G/B" B,4 | "A/C#" C4 "D" D4 | "G" G,3A "A7" B2c2 | "D" d6 z2 |
"Dmaj7" f2a2 d'2c'b | "Bm" b2f2 b2a2 | "Em7" g2e2 e4 | "A7sus4" e2c2 A4 |
"D" d3d "G" B2G2 | "F#m" A2c2 "Em" E4 | "G" D2G2 "A7" A6 | "D" d8 :|
V:2 clef=treble name="Violins"
%%MIDI program 40
|:"D" d6 f2 | "G" g6 e2 | "A7" a6 c2 | "D" d4 A4 |
"D/F#" f6 f2 | "G/B" b6 b2 | "A/C#" c'6 b2 | "D" a6 z2 |
"Dmaj7" a2f2 d'2b2 | "Bm" a2^c'2 a2g2 | "Em7" g2e2 e4 | "A7sus4" a2g2 f4 |
"D" d3e "G" f2a2 | "F#m" g2f2 "Em" e4 | "G" B2d2 "A7" c6 | "D" a8 :|
V:3 clef=alto name="Violas"
%%MIDI program 41
|:"D" D2F2 A2d2 | "G" G,2B,2 d2g2 | "A7" F2A2 c2e2 | "D" A,4 (=B,4) |
"D/F#" A4 | "G/B" B4 | "A/C#" C4 | "D" D4 |
"G" G,3A "A7" B2c2 | "D" d6 z2 | "Dmaj7" f3e d3c | "Bm" b4- b4 |
"Em7" e4- e2f2 | "A7sus4" a6 g2 | "D" d3d "G" d2B2 | "F#m" c2A2 "Em" A4- |
"G" A3G "A7" F2E2 | "D" D8 :|
V:4 clef=bass name="Cellos"
%%MIDI program 42
|:"D" D,2A,2 D4 | "G" G,2D2 G4 | "A7" A,2E2 A4 | "D" d4 d2c2 |
"D/F#" A,6 F2 | "G/B" B,6 G2 | "A/C#" C4 ^C4 | "D" D4 z4 |
"Dmaj7" d2 F2 A2d2 | "Bm" B2 D2 F2B2 | "Em7" E2 G2 B2e2 | "A7sus4" A,2 A,2 A4 |
"D" [D,F]4 [AD]4 | "G" [G,B,]4 [GB]4 | "F#m" [A,C]4 [CE]4 | "Em" E4 G2B2 |
"G" D2D2 G2B2 | "A7" [CA]4 E4 | "D" D,4 A,2F2 | "Dmaj7" [DF]4 D2^C2 |
"Bm" [BD]6 B2 | "Em7" [EG]8 | "A7sus4" [AE]6 A2 | "D" D8 :|



X:1
T:Wonder and Curiosity
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=120
K:Cmaj
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
V:2 clef=treble name="Electric Piano"
%%MIDI program 4
V:3 clef=bass name="Synth Bass"
%%MIDI program 38
V:4 clef=treble name="Percussive Organ"
%%MIDI program 17
[V:1]
|:"C"e2e2g2g2e2c'2c' z22|"F"d2d2d2c2B2A2G z22| z2 "Amin"A2e2e2c2E2A2B2|"G"G2B2d4B2G z24|
"F"F2A2c4A2F2G z22|"Em"G2G2G2F2E2D2C z22|"D7"D2F2A4F2D2E z22|"G"G4z8 z4:|
[V:2]
|:"C"c4e4G8|"F"f4f4a8|"Amin"A4c' z48| "G"b4G4d'8|
"F"F4A4c8|"Em"e4G4B8|"D7"d4F4A8|"G"g4z8 z4:|
[V:3]
|:"C"C8-E8|"F"F8-A8| "Amin"A8-C'8|"G"G,8-D8|
"F"F8-A8|"Em"E8-G8|"D7"D8-F8|"G"G,8-z8:|
[V:4]
|:"C"c'8c8|"F"d'8d8|"Amin"A8e8|"G"B8d8|
"F"c8A8|"Em"G8E8|"D7"A8d8|"G"G16:|



X:1
T:Mystic Journey
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=110
K:Em
V:1
%%MIDI program 79
|: "Em"B4 G2 | "Am"A3 B c2 | "Bm"B4 d2 | e^d e2 f2 |
"G"g4 e2 | "C"d2 e2 c2 | "Am"A6- | A4 z2 |
"Em"e3 d B2 | "D"A4 G2 | "C"F2 E2 D2 | "B7"^G4 A2 |
"Em"B2 A2 ^G2 | "G"A4 e2 | "Am"f3 e d2 | "Em"e4 z2 :|
V:2
%%MIDI program 73
|: "Em"G,6 | "Am"A,2 B,2 C2 | "Bm"B,2 D2 F2 | "G"G,4 E2 |
"C"C4 E2 | "Am"A,3 B,2 C | "Bm"B,4 z2 | "Em"E3 D G,2 |
"D"A,4 E2 | "C"F2 G2 C2 | "D"D2 C2 A,2 | B,6 |
"Em"E3 F G2 | "Am"A2 E2 C2 | "B7"B2 ^G2 A2 | "Em"E4 z2 :|
V:3
%%MIDI program 71
|: "Em"B,2 E2 G2 | "Am"A2 c2 e2 | "Bm"d2 B2 d2 | "G"g2 e4 |
"C"e2 c4 | "Am"a2 e2 c2 | "Bm"d4 f2 | "Em"e3 d B2 |
"D"F2 D4 | "C"E2 C4 | "D"D2 A,3 B, | A,6 |
"Em"E4 G,2 | "G"G,2 B,2 d2 | "Am"A2 c4 | "Em"E4 z2 :|
V:4
%%MIDI program 48
|: "Em"E,2 A,2 E2 | "Am"A,2 E2 A2 | "Bm"B,2 F2 B2 | E2 ^D2 E2 |
"G"G,2 D2 G2 | "C"C2 G,2 C2 | "Am"A,2 E2 A,2 | "Bm"B,2 F2 B,2 |
"Em"E,2 A,2 E2 | "D"D2 A,2 D2 | "C"C2 G,2 C2 | "B7"B,2 F2 B,2 |
"Em"E,4 ^G,2 | "G"G2 B,2 G2 | "Am"A,2 C2 A,2 | "Em"E,4 z2 :|



X:1
T:The Ancient Order's Chant
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=50
K:Gm
% The piece, set in G minor, combines Eastern and Western elements for a mysterious and ancient feel
V:1 clef=treble name="Choir"
%%MIDI program 52
|: "Gm"D4 z2 B2 | "Cm"A4 G4 | "F"F4 z G2 A | "D" ^F4 E4 |
"Gm"G4 A2 G2  | "Eb"E4 z2 G2 | "Bb"D4 C2 B,2 |1 "Gm"G4 z4 :|2 "Gm"G4 z2 B2 |]
|: "Am"A4 z c2 d | "Dm"F4 E4 | "Gm"G4 A2 B2  |1 "Cm"A4 G4 :|2 "Cm"A6 ^F2 |]
|: "D"D4 z2 =F2 | "Gm"G4 z4 | "Eb"^F4 G2 A2 |1 "Bb"B4 A4 :|2 "Bb"B6 z2 |]
V:2 clef=tenor name="Pipe Organ"
%%MIDI program 19
|: "Gm"G,4 B,2 D2 | "Cm"C4 B,4 | "F"F,4 z B,2 C | "D"A,4 G,4 |
"Gm"G,4 B,2 A2  | "Eb"E,4 z2 B,2 | "Bb"B,4 A,2 G, z |1 "Gm"G,4 z4 :|2 "Gm"G,4 z2 B,2 |]
|: "Am"A,4 z2 c2  | "Dm"D,4 C4 | "Gm"G,4 B,2 D2  |1 "Cm"C4 B,4 :|2 "Cm"C6 ^A,2 |]
|: "D"=F,4 A,2 C2 | "Gm"G,4 z4 | "Eb"=F,4 G,2 A,2 |1 "Bb"B,4 A,4 :|2 "Bb"B,6 z2 |]
V:3 clef=bass name="Strings"
%%MIDI program 48
|: "Gm"D,2 G,4 D2 | "Cm"E,2 A,4 E2 | "F"F,2 C2 F4 | "D"A,2 D4 ^F2 |
"Gm"G,2 D4 G,2 | "Eb"E,2 B,4 E2 | "Bb"B,2 F,4 D,2 |1 "Gm"G,2 D4 G,2 :|2 "Gm"G,2 D4 B,2 |]
|: "Am"C2 E4 A,2 | "Dm"F,2 A,4 D2 | "Gm"G,2 D4 G,2 |1 "Cm"C2 E4 A,2 :|2 "Cm"C2 E4 ^F,2 |]
|: "D"D,2 F,4 A,2 | "Gm"G,2 D4 G,2 | "Eb"E,2 G,4 ^F,2 |1 "Bb"B,2 F,4 B,2 :|2 "Bb"B,2 F,4 B,2 |]



X:1
T:Marketplace Melody
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=140
K:Gmaj
% The piece is vibrant and ripe with dynamic energy
V:1 clef=treble name="Accordion"
%%MIDI program 21
|:"G"B2d2 d4|"D"A2F2 F4|"C"E2G2 G4|"G"D4 z4|
"G"B2d2 e2f2|"Am"g4 f4|"D7"a2f2 e2d2|"G"G4 z4:|
|:"Em"e2g2 b4|"C"g2e2 c4|"D"a2b2 a2g2|"G"f4 z4|
"Am"c>Bc>d e4|"D"f2e2 f2g2|"C"e2^d2 e2c2|"G"d4 z4:|
V:2 clef=treble name="Clarinet"
%%MIDI program 71
||:"G" d2B2 B2G2 | "D" A2F2 A2D2 | "C" G2E2 G4 | "G" D2B,2 C4 |
"G" d2B2 d4 | "Am" e2c2 e4 | "D7" A2F2 A4 | "G" G,2D2 G4 :|
||: "Em" B2G2 B4 | "C" E2G2 E4 | "D" F2A2 F4 | "G" D2B,2 D4 |
"Am" c4 A2G2 | "D" F2D2 F4 | "C" E4 C2E2 | "G" G,4 z4 :|
V:3 clef=bass name="Acoustic Bass"
%%MIDI program 32
|:"G"G,4 D4|"D"D4 A,4|"C"C4 E4|"G"G,4 D4|
"G"G,4 B,4|"Am"A,4 E4|"D7"A,4 D4|"G"G,4 D4:|
||:"Em"B,4 E4|"C"G,4 C4|"D"A,4 D4|"G"G,4 B,4|
"Am"C4 E4|"D"F4 A,4|"C"C4 G,4|"G"G,4 D4:|



X:1
T:March of Remembrance
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=60
K:Am
% The first voice with a steady march rhythm setting the tone
V:1 clef=treble
%% MIDI program 41
|: "Am" A2 A2 E2 E2 | "G" G4 F2 E2 | "F" F2 E2 D2 C2 | "E7" E4 z2 E2 |
"Am" A2 A2 E2 E2 | "G" G4 F2 E2 | "F" F2 E2 D2 C2 |1 "E7" E4 z2 A2 :|2 "E7" E4 z4 |
% The second voice with countermelodies and sparse harmonies
V:2 clef=treble
%% MIDI program 41
|: "Am" e4 e2 d2 | "G" d2 d2 c2 B2 | "F" c4 B2 A2 | "E7" E2 A2 B2 G2 |
"Am" e4 e2 d2 | "G" d2 d2 c2 B2 | "F" c4 B2 A2 |1 "E7" [E2B,2] [A,2E2] [E2B,2] z2 :|2 "E7" [E2B,2] z4 z2 |
% The third voice in the bass clef, providing a foundation
V:3 clef=bass
%% MIDI program 42
|: "Am" A,2 C2 E2 A,2 | "G" G,4- G,2 F2 | "F" F4- F2 E2 | "E7" E,2 E2 G,2 B,2 |
"Am" A,2 C2 E2 A,2 | "G" G,4- G,2 F2 | "F" F4- F2 E2 |1 "E7" E,2 E2 A,4 :|2 "E7" E,2 E4 z2 |
% The B section with a thinner texture and higher register for a moment of reflection
V:1
|: "F" c4 c2 B2 | "Am" A4 G2 F2 | "E7" E2 G2 F2 E2 | "Am" A4 z2 A2 |
"F" c2 d2 e2 f2 | "G" G4 F2 G2 | "Am" E2 A2 G2 F2 | "E7" E4 z4 :|
V:2
|: "F" A2 c2 d2 e2 | "Am" A2 G2 F2 G2 | "E7" B2 G2 A2 B2 | "Am" A2 E2 F2 E2 |
"F" f2 g2 a2 b2 | "G" d2 F2 G2 A2 | "Am" c2 G2 A2 B2 | "E7" E4 z4 :|
V:3
|: "F" F,4 F,2 E,2 | "Am" A,4 G,2 F,2 | "E7" G,2 B,2 C2 D2 | "Am" A,2 E2 F2 G2 |
"F" f2 f2 e2 e2 | "G" d4 G2 F2 | "Am" A,2 C2 E2 A,2 | "E7" E4 z4 :|
% Returning to the A section, revisiting the original theme
V:1
|: "Am" A2 A2 E2 E2 | "G" G4 F2 E2 | "F" F2 E2 D2 C2 | "E7" E4 z2 E2 |
"Am" A2 A2 E2 E2 | "G" G4 F2 E2 | "F" F2 E2 D2 C2 | "E7" E4 z2 A2 :|
V:2
|: "Am" e4 e2 d2 | "G" d2 d2 c2 B2 | "F" c4 B2 A2 | "E7" E2 A2 B2 G2 |
"Am" e4 e2 d2 | "G" d2 d2 c2 B2 | "F" c4 B2 A2 | "E7" [E2B,2] z4 z2 :|
V:3
|: "Am" A,2 C2 E2 A,2 | "G" G,4- G,2 F2 | "F" F4- F2 E2 | "E7" E,2 E2 G,2 B,2 |
"Am" A,2 C2 E2 A,2 | "G" G,4- G,2 F2 | "F" F4- F2 E2 | "E7" E,2 E4 z2 :|



X:1
T:Lanterns of Lyria
C:OrchestrAI
M:6/8
L:1/8
Q:1/4=144
K:Gmaj
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "G"D2G B2d | g2e d2B | "C"c2E G2c | e3 - e2d |
"D"B2A G2E | "C"D2E G2A | "G"B3 - B2A | "D"A3 - A2F |
"G"G2B d2g | "Em"e2d B2G | "C"G2E D2B, | "D"C3 - C2z |
"G"D2G B2d | g2e "Am"d2B | "Bm"A2F "D"D2E | "G"G3 - G2z :|
V:2 clef=treble name="Violin"
%%MIDI program 40
|: "G"B3 B3 | d2B B2A | "C"G2E E2D | G3 - G2F |
"D"D3 - D2E | "C"E3 - E2D | "G"B2G G2F | "D"A2F F2E |
"G"G3 - G2A | "Em"B2G E2D | "C"G2E D2C | "D"B,3 - B,2A, |
"G"B3 B2d | "Am"e2d B2A | "Bm"G2A B2c | "G"G3 - G2z :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "G"G,3 - G,2A, | "C"C3 - C2D, | "D"D,3 - D,2E, | "G"G,3 - G,2F, |
"D"D,3 - D,2E, | "C"C3 - C2D, | "G"G,2B, D2G | "D"D,3 - D,2A, |
"G"G,3 - G,2B, | "Em"E,3 - E,2D, | "C"C,2E, G,2C | "D"D,3 - D,2B, |
"G"G,3 - G,2A, | "Am"A,3 - A,2B, | "Bm"B,2D "D"A,2D | "G"G,3 - G,2z :|



X:1
T:Pirate Polka Jamboree
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=120
K:Gm
V:1 clef=treble name="Accordion"
%%MIDI program 21
|:"Gm"G2B2 d2g2|"D7"f4 e4|"Gm"g4 b4 a4|"A7"^c4 A4|
"Gm"G2B2 d2g2|"D7"f4 e4|"A7"a4 g4 f4|"Gm"g8:|
|:"Cm"e2g2 c'2e2|"F7"a4 g4|"Bb"f4 d4 c4|"Gm"B4 G4|
"Cm"e2g2 c'2e2|"F7"a4 g4|"Gm"g4 g4 "D7"a4|"Gm"g8:|
V:2 clef=treble name="Violin"
%%MIDI program 40
|:"Gm" d'8 d'8|"D7"c'8 c'8|"Gm"b8 b8|"A7"^c'8 A8|
"Gm"d'8 d'8|"D7"c'8 c'8|"A7"A8 a8|"Gm"g8:|
|:"Cm"g8 g8|"F7"g8 f8|"Bb"d8 f8|"Gm"B8 G8|
"Cm"g8 e8|"F7"g8 f8|"Gm"G8 "D7"A8|"Gm"g8:|
V:3 clef=bass name="Bass"
%%MIDI program 32
|:"Gm" G,4 D4|"D7" A,4 D4|"Gm" G,4 D4|"A7" C,8|
"Gm" G,4 D4|"D7" A,4 D4|"A7" A,4 E4|"Gm" G,8:|
|:"Cm" C,4 G,4|"F7" C,4 F,4|"Bb" B,,8|"Gm" D,8|
"Cm" C,4 G,4|"F7" C,4 F,4|"Gm" G,8 "D7" F,8|"Gm" G,8:|



X:1
T:Frontiers Unveiled
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:Em
V:1 clef=treble name="Synth Lead" subname="Lead"
%%MIDI program 81
|:"Em" B4-A2 | "Am" A3 G F2 | "B7" B4 ^F2 | "Em"G6 |
"D" F4 E2 | "C" E4 z2 | "Am" A4 c2 | "Em" B4-A2 |
"G" G4 z2 | "D" F4 A2 | "Cmaj7" E6   | "G" B2 A2 G2 |
"Em" B4-A2 | "Am" A3 G F2 | "B7" B2 ^F2 E2 | "Em" E6 :|
V:2 clef=treble name="Pad" subname="Synth Pad"
%%MIDI program 91
|:"Em" e4 e2 | "Am" a3 g e2 | "B7" B4 ^f2 | "Em" e6 |
"D" d4 d2 | "C" c4 z2 | "Am" a4 c2 | "Em" e4 e2 |
"G" G4 z2 | "D" f4 a2 | "Cmaj7" e6   | "G" b2 a2 g2 |
"Em" e4 e2 | "Am" a3 g e2 | "B7" b2 ^f2 e2 | "Em" e6 :|
V:3 clef=bass name="Bass" subname="Synth Bass"
%%MIDI program 38
|:"Em" E4 E,2 | "Am" A,3 E, C,2 | "B7" B,4 F,2 | "Em" E,6 |
"D" D,4 D2 | "C" C,6 | "Am" A,4 A,2 | "Em" E4 E,2 |
"G" G,4 G,2 | "D" D,4 D,2 | "Cmaj7" C,4 B,2 | "G" G,4 G,2 |
"Em" E4 E,2 | "Am" A,3 E, C,2 | "B7" B,4 F,2 | "Em" E,6 :|



X:1
T:Brass Anthem of Yearning
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=108
K:C
V:1 clef=treble
%%MIDI program 56
|: "C"E4 G4 | "Am"A4- A4 | "F"F4 A4 | "G"G6 z2 |
"C"E2 E4 G2 | "Am"A2 A4 z2 | "F"F4 A4 | "G"G6- G2 |
"C"E4 "G"G4 | "Am"A4 "F"F4 | "Em"G4 E4 | "G"G6 z2 |
"C"E4 G4 | "Am"A4- A4 | "F"F4 A4 | "G"G6 z2 :|
V:2 clef=treble
%%MIDI program 57
|: "C"C4 E4 | "Am"A,4- A,4 | "F"F3 A F2 z2 | "G"G6 z2 |
"C"C2 E4 C2 | "Am"A,4 z2 z2 | "F"F4 A4 | "G"G6- G2 |
"C"C3 E "G"G3 z | "Am"A,3 "F"F3 z2 | "Em"E3 ^C3 z2 | "G"G6 z2 |
"C"C4 E4 | "Am"A,4- A,4 | "F"F4 A4 | "G"G6 z2 :|
V:3 clef=bass
%%MIDI program 58
|: "C"C,6 C,2 | "Am"A,6 A,2 | "F"F,6 F,2 | "G"G,6 z2 |
"C"C,6 C,2 | "Am"A,6 z2 | "F"F,6 F,2 | "G"G,6- G,2 |
"C"C,3 "G"G,3 z2 | "Am"A,3 "F"F,3 z2 | "Em"E,3 G,3 z2 | "G"G,6 z2 |
"C"C,6 C,2 | "Am"A,6 A,2 | "F"F,6 F,2 | "G"G,6 z2 :|



X:1
T:Enchanted Waltz
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=92
K:F#min
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"F#m"A2 {/A}B2 c2 | "D"d3 c "A"c2B2 | "E"B3 A G2F2 | "F#m"F4 z2 |
w:The fluter of wings in twilight's last gleam,
"A"A2 {/A}B2 c2 | "Bm"d3 c "C#7"c2B2 | "F#m"A3 G F2E2 | "D"D4 z2 |
w:A_ dance through the dusk where fairies might dream.
"F#m"F3 E "Bm"D2C2 | "Bm"D2 F2 "A"E4 | "C#7"e3 f g2a2 | "F#m"f4 z2 |
w:The whisper of leaves as they swirl in the air,
"E"E3 F G2A2 | "A"A2 {/A}B2 c2d2 | "D"e3 d "C#7"c2B2 | "F#m"F4- F2 z2 :|
w:Secrets untold in the heart of the glen.
|: "Bm"[Fa]2 [Fd]2 [FB]2 | "A"[ec]4 [eA]2 | "C#7"[gf]3 e [fd]2 |
w:Mysteries unfold in the heart of the waltz,
"F#m"[Ac]2 [Ad]2 [Af]2 | "Bm"[Bd]2 [dF]2 [df]2 | "A"[ce]4 [cA]2 | "D"[df]3 c [Bc]2 |
w:Spirals of light where the shadows revolve.
"E"[Bc]2 [BG]2 [BE]2 | "D"[AG]4 [AF]2 | "C#7"[AF]3 G [FE]2 | "F#m"[EF]4- [E2A,2]2 :|
w:Ever they shine, as the night is unfurled.
V:2 clef=treble name="Violin"
%%MIDI program 40
|:"F#m"e2 c2 d2 | "D"B3 A G2F2 | "A"A3 G F2E2 | "F#m"F4 z2 |
"A"A2 e2 d2 | "Bm"B3 A G2F2 | "C#7"G3 A B2c2 | "D"D4 z2 |
"F#m"F2 E2 D2 | "Bm"D3 C D3 | "C#7"c3 B A3 | "F#m"f4 z2 |
"E"E3 F G2 | "A"A2 A2 B2 | "D"d3 c "C#7"B2A2 | "F#m"F4- F2 z2 :|
|: "Bm"[Ad]2 [Ac]2 [Ae]2 | "A"[Gc]4 [GA]2 | "C#7"[AF]3 G [FE]2 |
"F#m"[ED]2 "C#7"[EC]2 [EB]2 | "Bm"[DF]2 [D^C]2 [DE]2 | "A"[G=c]4 [GA]2 | "D"[A^C]3 D [GE]2 |
"E"[FD]2 [FC]2 [FA]2 | "D"[EG]4 [ED]2 | "C#7"[G=B]3 A [F^A]2 |
"F#m"[EA]4- [E2A,2]2 :|
V:3 clef=bass name="Harp"
%%MIDI program 46
|:"F#m"F,2 A,2 C2 | "D"D,4 F,2 | "A"A,3 A, C2E2 | "F#m"F,4 z2 |
"A"A,2 C2 E2 | "Bm"B,4 B,2 | "C#7"E,3 {/D}C D2F2 | "D"D,4 z2 |
"F#m"F,2 A,2 B,2 | "Bm"B,3 {/A}B, D2 | "C#7"C2F2 E3 | "F#m"F,4 z2 |
"E"E,4 G,2 | "A"A,2 A,2 C2 | "D" D,D3 C B,A,2 | "F#m"F,4- F,2 z2 :|
|: "Bm"B,,2 D2 F2 | "A"A,,4 A,,2 | "C#7"E,4 G,2 |
"F#m"F,2 A,2 C2 | "Bm"B,,2 D2 F2 | "A"A,,4 A,2 | "D"D,4 F,2 |
"E"E,,4 G,2 | "D"D,,4 F,2 | "C#7"G,4 B,2 | "F#m"F,4- F,2 z2 :|



X:1
T:Pride of Triumph
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Cmaj
V:1 clef=treble
%%MIDI program 48
|:"C"G4 E4|"G" D8-|"G" D4 EFGA|"Am" G6 E2|"F" F4 A4|"C" E6 G2|"G" D3 E G2F2|"C" E8|
"F" A4 c4|"C" E6 G2|"F" A3 G F2E2|"G" G8-|"G" G4 ABCB,|"Am"^A,6 E2|"F"A4 G4|"C"C8:|
V:2 clef=treble
%%MIDI program 49
|"C" E2 C4 E2|"G"B,2 D4 G2|"G" D2 G4 B2|"Am" E2 A,4 E2|
"F" A2 F4 A2|"C" C2 E4 G2|"G" D2 B,4 D2|"C" C4 E4 G2|
"F" A2 F4 c2|"C" C2 E4 G2|"F" A2 G4 F2|"G" D4 G4 B2|
"G" G2 G4 A2|"Am" A2 ^A,4 E2|"F" F2 A4 G2|"C"C2 E4 G2:|
V:3 clef=bass
%%MIDI program 42
|"C" C2 E4 G2|"G" B,,2 D4 G2|"G" D2 G4 B2|"Am" A,2 E4 A2|
"F" F2 A4 c2|"C" E2 C4 G2|"G" B,,2 D4 G2|"C" C2 E4 G2|
"F" A2 F4 A2|"C" E2 C4 G2|"F" F2 A4 c2|"G" D2 G4 B2|
"G" B,,2 D4 G2|"Am" A,2 E4 A2|"F" C2 F4 A2|"C" G,2 C4 E2:|



X:1
T:Journey of Reflections
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=92
K:Cmaj
V:1 clef=treble name="Violin I"
%%MIDI program 40
|: "C"e4 g4 | "Am" a3b ^g2a2 | "F" f4 e4 | "G" g2f2 e2d2 | "Em" e4 ^d4 | "Dm" d2c2 B2A2 | "G7" B2A2 G4 :|
% Transition to Middle section with tempo change
Q:1/4=120
|: "C"E2G2 c4 | "G"G2B2 d4 | "Am"A2c2 e4 | "F"F2A2 c4 | "C"e2G2 c3d | "G"B2d2 G4 :|
% Return to the reflective mood but with a hopeful tone
Q:1/4=92
|: "C"e2e2 g2a2 | "F"f3e d2c2 | "G"g2f2 e2d2 | "Am"a4 "G"g4 | "F"f2g2 "Em"a2^g2 | "F"f4 "G"g2e2 | "C"c8 :|
V:2 clef=treble name="Violin II"
%%MIDI program 40
|: "C"c2c2 e2f2 | "Am"a2f2 e2a2 | "F"a2g2 f2e2 | "G"d2c2 B2A2 | "Em"^G2F2 E2D2 | "Dm"B,2C2 D2E2 | "G7"G,4 C4 :|
Q:1/4=120
|: "C"C2E2 G4 | "G"D2F2 B,4 | "Am" E2A2 C4 | "F"A2C2 F4 | "C"c2E2 G3A | "G"B2D2 G4 :|
Q:1/4=92
|: "C"c4 e2f2 | "F"a3g f2e2 | "G"g8 | "Am"a2a2 a2b2 | "F"f2g2 "Em"e2f2 | "F"f2a2 "G"g2a2 | "C"c8 :|
V:3 clef=alto name="Viola"
%%MIDI program 41
|: "C"E2E2 G2A2 | "Am"F2D2 C2E2 | "F"D2E2 F2G2 | "G"C2B,2 A,2G,2 | "Em"B,2B,2 E2F2 | "Dm"F2E2 D2C2 | "G7"B,4 E4 :|
Q:1/4=120
|: "C"C2G2 E4 | "G"B,2G2 D4 | "Am"A2E2 C4 | "F"F2A2 D4 | "C"C2G2 E3F | "G"B,2D2 G4 :|
Q:1/4=92
|: "C"G4 c2d2 | "F"A3G F2E2 | "G"G4 B,4 | "Am"A2G2 F2E2 | "G"G2A2 "Em"B2A2 | "F"F2A2 "G"G2B2 | "C"C8 :|
V:4 clef=bass name="Cello"
%%MIDI program 42
|: "C"G,4 C4 | "Am"A,2E2 A,2F2 | "F"F,4 A,4 | "G"G,2B,2 G,4 | "Em"E,2G,2 E,2F2 | "Dm"D,2F,2 D,2E2 | "G7"G,4 C4 :|
Q:1/4=120
|: "C"C2C2 G,4 | "G"G2G2 D4 | "Am"A2A2 E4 | "F"F2F2 A4 | "C"C2C2 G,3A | "G"G2G2 D4 :|
Q:1/4=92
|: "C"G,2G,2 C4 | "F"F,2A,2 F,4 | "G"G,4 B,4 | "Am"A,4 E4 | "F"F,3E D4 | "G"G,4 C4 | "C"C8 :|



X:1
T:Estudio Español Virtuosico
C:OrchestrAI
M:3/4
L:1/16
Q:1/4=140
K:Amin
V:1 treble
%% MIDI program 1
|: "Am"e2. | e2  | "E7"B2^  | "Am"A2. |
"Am"A,2  | "G"=G2  | "F"E2  | "E7"^G,2 ^ |
"Am"A2-   | "Dm"d'2  | "A7"c'2  | "Dm"d'2  |
"A7"c'2  | "E7"B2 ^  ^ | "Am"a2  :|
V:2 bass
%% MIDI program 1
|: "Am" A,2  | A,2  | "E7"G,2 ^ |"Am"A,12 |
"E7"G,2 ^ | "Am"A,2  |"G"B,2  |"F"C12 |
"E7"D2  |"Am"A,12 | "Dm"d12 | "A7"=G12 |
"Dm"d12 |"A7"=G12 | "E7"^F12 :| "Am"A,12 :|



X:1
T:Test
L:1/8
Q:1/2=80
M:4/4
K:Gm
L:1/8
V:1 clef=treble name="Trumpet"
%%MIDI program 56
"D7" d2 AB cd/c/ BA |"Gm" G2 GB d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
$"Cm" z c2 e g2 fe | "Bb" z d2 B d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
$"Gm" d4 B4 |"D7" c4 A4 |"Gm" B4 G4 |"D7" ^F4 A4 |
$"Gm" d4 B4 |"D7" c4 A4 |"Bb" B2 d2 g4 |"D7" ^f8 :|
V:2 clef=bass name="Trombone"
%%MIDI program 57
"D7" [A,2D2] [F2A2] [^F2A2]|[Gm" G,2B,2 D2 G,2|"D7" [A,2D2] [F2A2] [^F2A2]|"Gm" G,4 B,4 |
$"Cm" [C,2G,2] z2 E2 C2| "Bb" [B,,2D,2] [F2A2] D2 [A,2D2]|"D7" [A,2D2] [^F2A2] [G2B2]|"Gm" G,4 B,4 |
$"Gm" G,2 B,2 G,4 |"D7" F,2 A,2 F,4 |"Gm" D,4 G,4 |"D7" [^F,2A,2] D2 [A,2D2] |
$"Gm" G,2 B,2 G,4 |"D7" F,2 A,2 F,4 |"Bb" D,2 F,2 D,4 |"D7" [^F,2A,2] [^F,2A,2] [^F,2A,2] :|



X:1
T:Dance of the Fireflies
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=120
K:Gmaj
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G"B3d "C"e3d|"D"B2A2 G2|"Em"B3c "A7"d2c2|"D7"B6|
"D"A3F "G"G3E|"C" F3D "D" D3C|"G"B,2B2 "D"A2|"G"G6|
"G"B3d "C"e3d|"D"B2A2 G2|"Em"B3c "A7"d2c2|"D7"B6|
"D"A3F "G"G3E|"C" F3D "D" D3C|"G"B,2G2 "D"A2|"G"G6:|
V:2 clef=treble name="Violins"
%%MIDI program 41
|:"G"d4 d2|"C"e4 c2|"D"f4 A2|"Em"g3e "A7"d3c|
"Bm"d4 f2|"G"g4 e2|"Am"a4 c'2|"D7"b6|
"D"A4 A2|"G"B4 G2|"C"C4 D2|"D7"F3D G3E|
"G"G4 B2|"D" A4 F2|"C"E4 C2|"G"D6:|
V:3 clef=treble name="Pizzicato Strings"
%%MIDI program 45
|:"G"G,2G,2G,2|"C"C2C2E2|"D"A,2A,2D2|"Em"B,2G,2E2|
"A7"A,2^C2E2|"D7"D2A,2F2|"G"G,2B,2G,2|"D"D,6|
|:"G"G,2G,2G,2|"C"C2C2E2|"D"A,2A,2D2|"Em"B,2G,2E2|
"A7"A,2^C2E2|"D7"D2A,2F2|"G"G,2B,2D2|"G"G,6:|
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"G"G,2D2G,2|"C"C2E2G,2|"D"D2A,2F2|"Em"G,2E2B,2|
"A7"A,2E2A,2|"D7"D2F2A,2|"G"B,,2D2G,2|"D"A,,6|
|:"G"G,2D2G,2|"C"C2E2G,2|"D"D2A,2F2|"Em"G,2E2B,2|
"A7"A,2E2C2|"D7"D2F2A,2|"G"G,2B,2G,2|"G"G,6:|



X:1
T:Sonata in C Major
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=120
K:C
% Exposition
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"C"c2e2g2g2 | "G"d2B2d2B2 | "D"e2c2e2c2 | "G7"d2B,2A,2G,2 | "C"c3e c2 z2 |
V:2 clef=treble name="Oboe"
%%MIDI program 68
|:"C"g2e2c2c2 | "G"B2d2B2d2 | "D"A2F#2A2F#2 | "G7"B,2G,2F,2E,2 | "C"g3c g2 z2 |
V:3 clef=treble name="Clarinet"
%%MIDI program 71
|:"C"e2c2g2g2 | "G"d2B2g2g2 | "D"c2A2c2A2 | "G7"G,2F,2E,2D,2 | "C"e3A, e2 z2 |
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"C"C,2,2,2,2 z3 | "G"G,2,2,2,2 z3 | "D"D,2,2,2,2 z3 | "G7"D,2,2,2,2 z3 | "C"C,2,2,2,2 z3 |
% Development
V:1
|:"Am"a2g2e2c2 | "Dm"d2c2B,2A,2 | "G"g2f#2e2d2 | "C"c2B,AG,F, z2 | "G"B,AGFEDC z |
V:2
|:"Am"e2c2A,2G,2 | "Dm"f2e2d2c2 | "G"d2c2B,AG, z | "C"e2d2c2B, z | "G"d2c2B,AG, z |
V:3
|:"Am"c2A,G,F,E, z2 | "Dm"B,A,G,F,E, z3 | "G"c2B,AG,F, z2 | "C"g2f2e2d2 | "G"cBA,G,F,E, z2 |
V:4
|:"Am"A,,2,2,2,2 z3 | "Dm"D,2,2,2,2 z3 | "G"G,2,2,2,2 z3 | "C"C,2,2,2,2 z3 | "G"G,2,2,2,2 z3 |
% Recapitulation
V:1
|:"C"c2e2g2g2 | "G"d2B2d2B2 | "D"e2c2e2c2 | "G7"d2B,2A,2G,2 | "C"c3e c2 z2 |
V:2
|:"C"g2e2c2c2 | "G"B2d2B2d2 | "D"A2F#2A2F#2 | "G7"B,2G,2F,2E,2 | "C"g3c g2 z2 |
V:3
|:"C"e2c2g2g2 | "G"d2B2g2g2 | "D"c2A2c2A2 | "G7"G,2F,2E,2D,2 | "C"e3A, e2 z2 |
V:4
|:"C"C,2,2,2,2 z3 | "G"G,2,2,2,2 z3 | "D"D,2,2,2,2 z3 | "G7"D,2,2,2,2 z3 | "C"C,2,2,2,2 z3 |



X:1
T:Cyber Sprint
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Cmin
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"Cm"G8- G4 z4 | e8- e4 c4 | d2c2 B2A2 G2F2 G4 | "G" B,8- B,4 z4 |
"Cm"G4e4- e2 d2c2 z2 | "Bb"d4c4 B8 | "Ab"G4 F8 z4 | "G7"G,8 z2 ^F2 G2 z2 |
"Cm"G8- G4 z4 | "Fm7"e8- e4 c4 | "Bb"d4 c2d2 e4 f4 | "G7"g4 f2g2 a4 G4 |
|:"Cm"c2B2 c4 G8 | "Fm"E2^F2 G4 E8 | "Bb"d8- d4 B4 | "Ab"e2e2 f2g2 a4 g2f2 | "G7"g8 z8:|
V:2 clef=treble name="Backing Synth" subname="Chords"
%%MIDI program 81
|:"Cm"C2E2 G2C2 E2G2 z4 | "G"B,2D2 G2B,2 D2G2 z4 | "Cm"G,2B,2 E2G2 C2E2 z4 | "G"D2=F2 A2^F2 D4 z4 |
"Cm"C2E2 G2C2 E2G2 z4 | "Bb"F2A2 c2F2 A2c2 z4 | "Ab"E2G2 C2E2 G2C2 z4 | "G7"D2=F2 A2D2 D4 z4 |
"Cm"C2E2 G2C2 E2G2 z4 | "Fm7"F2A2 c2F2 A2c2 z4 | "Bb"B,2D2 G2B,2 D2G2 z4 | "G7"G2B2 D4 D4 z4 |
|:"Cm"C2E2 G4 C8 | "Fm"E2G2 C4 E8 | "Bb"B,2D2 G4 B8 | "Ab" C4 C8 z4 | "G7"D4 D8 z4 :|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 87
|:"Cm"c4 c8 z4 | g4 g8 c4 | "G"b,4 b,8 ^f4 | "Cm" G,4 G,8 z4 |
"Cm"c4 c8 z4 | "Bb"f,4 f,8 d4 | "Ab"e4 e8 f4 | "G7" d,4 d,8^f4 |
"Cm"c4 c8 z4 | "Fm7"c'4 c'8 c4 | "Bb"d4 d8 d4 | "G7"b,4 b,8 ^f4 |
|:"Cm"c2b2 c4 c8 | "Fm"e2^f2 g4 e8 | "Bb"b,4 b,8 d4 | "Ab"e2e2 f4 f8 | "G7"g4 g8 z4 :|



X:1
T:Quartet Nocturne
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:Gmin
V:1 clef=treble name="1st Violin" subname="Violin I"
%%MIDI program 40
d4 B2 A2 | G4 z2 G2 | A4 ^F2 G2 | A6 z2 |
B2 d2 B2 G2 | D4 z2 c2 | d6 B2 | A4 G4 |
B4 c2 d2 | e6 d2 | c4 B2 A2 | G4 z4 |
A4 ^F2 G2 | A2 B2 d2 c2 | B2 A2 G2 ^F2 | G4 z4 :|
V:2 clef=treble name="2nd Violin" subname="Violin II"
%%MIDI program 40
G2 D2 F2 D2 | B2 G2 A2 G2 | F4 E2 ^F2 | G4 z2 A2 |
B2 G2 ^F2 E2 | D4 z2 c2 | B4 A2 B2 | c4 d4 |
e2 ^c2 d2 B2 | d6 c2 | B4 A2 G2 | F4 z4 |
G4 E2 D2 | E2 F2 G2 A2 | B2 A2 G2 F2 | G4 z4 :|
V:3 clef=alto name="Viola" subname="Viola"
%%MIDI program 41
B,2 G2 B2 c2 | d4 z2 d2 | c2 B2 d2 e2 | f6 z2 |
e2 d2 B2 G2 | c4 z2 B2 | A4 A2 G2 | F4 E4 |
D2 G2 B2 A2 | G4 F2 E2 | D2 B,2 C2 D2 | B,4 z4 |
c2 B2 A2 G2 | A2 B2 d2 c2 | B2 A2 G2 F2 | G3 G z4 :|
V:4 clef=bass name="Cello" subname="Cello"
%%MIDI program 42
G,2 D2 G2 B,2 | C4 z4 | B,2 A2 G2 F2 | G3 A B2 A2 |
G2 E2 F2 D2 | C4 z4 | B,4 A2 B2 | G,4 F4 |
E4 D2 G2 | F6 E2 | D4 C2 B,2 | G,4 z4 |
A4 G2 F2 | E2 F2 G2 ^F2 | G2 A2 B2 c2 | d4 z4 :|



X:1
T:Sailing the Crystal Seas
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=100
K:Dmaj
% The main melody represents the calm and steady motion of the sail
V:1
%%MIDI program 73
|: "D"A3 "A"f3 | "G"gfe "D"d2A | "Bm"F3 "G"GFE | "A7"E3 "D"D3  |
"A"c3 "D"dcd | "G"Bcd "A"cBA | "D"d3 "G"B2G | "A7"F3 "D"D3 :|
V:2
|: "D"F2D "A"C2A,| "G"B,2G, "D"A,2F,| "Bm"D2B, "G"C2B,| "A7"A,3 "D"D3  |
"A"A,2F, "D"D2F,| "G"G,2B, "A"A,2C,| "D"D3 "G"G,2B, | "A7"F,3 "D"D3 :|
V:3
|: "D"A,,3 "A"F,3  | "G"G,2B, "D"A,2F, | "Bm"D2F, "G"G,2E, | "A7"E3 "D"A,,3 |
"A"C,3 "D"D,3 | "G"B,,3 "A"C,3 | "D"D3 "G"G,,2B, | "A7"F,3 "D"D,3 :|
% A contrasting section with higher notes representing the breeze at your back
V:1
%%MIDI program 73
|: "G"B3-d3 | "Em"e3 "A7"edc | "D"d3-afd | "G"g3 e3 |
"Bm"f3-a3 | "G"gfe "D"d3 | "G"B3-e3 | "A7"c3 "D"d3 :|
V:2
|: "G"G3-B3 | "Em"E3 "A7"EFG | "D"A3-c3 | "G"B3 G3 |
"Bm"F3-A3 | "G"GFE "D"F3 | "G"G3-B3 | "A7"A,3 "D"D3 :|
V:3
|: "G"D,3-G,3 | "Em"E,3 "A7"E,G,A, | "D"A,,3-D,3 | "G"G,2B, D,3 |
"Bm"F,,3-A,,3 | "G"G,,E, "D"A,,3 | "G"D,3-G,3 | "A7"A,,3 "D"D,3 :|
% The return to the main theme symbolizes the steady sailing towards endless horizons
V:1
|: "D"A3 "A"f3 | "G"gfe "D"d2A | "Bm"F3 "G"GFE | "A7"E3 "D"D3  |
"A"c3 "D"dcd | "G"Bcd "A"cBA | "D"d3 "G"B2G | "A7"F3 "D"D3 :|
V:2
|: "D"F2D "A"C2A,| "G"B,2G, "D"A,2F,| "Bm"D2B, "G"C2B,| "A7"A,3 "D"D3  |
"A"A,2F, "D"D2F,| "G"G,2B, "A"A,2C,| "D"D3 "G"G,2B, | "A7"F,3 "D"D3 :|
V:3
|: "D"A,,3 "A"F,3  | "G"G,2B, "D"A,2F, | "Bm"D2F, "G"G,2E, | "A7"E3 "D"A,,3 |
"A"C,3 "D"D,3 | "G"B,,3 "A"C,3 | "D"D3 "G"G,,2B, | "A7"F,3 "D"D,3 :|




X:1
T:A Baroque-Classical Fusion
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=100
K:Gmaj
V:1 clef=treble name="Violin I"
%%MIDI program 40
|:"G"B2d2 g4 z4 f2e2|"C"d2cB "G"B2AG "D7"A4 z4|
"G"g2f2 "C"e2d2 "G"d2B2 "A7"c4 z4|
"G"B2d2 g4 z4 f2e2|"Am"e4 "D7"A2GF "G"G4 z4:|
V:2 clef=treble name="Violin II"
%%MIDI program 40
|"G"d4 B2d2 z4 e2c2|"C"c4 e2d2 z4 c2B2|
"G"B2A2 "D"A2F2 "G"G2F2 "A7"E4 z4|
"G"G2A2 B2d2 z4 "C"e2c2|"Am"A4 "D7"f2e2 "G"d4 z4|
V:3 clef=treble name="Viola"
%%MIDI program 41
|"G"g4 d2B2 z4 G2G2|"C"c2E2 "G"G2D2 "D7"F4 z4|
"G"G2G2 "C"E4 "G"D4 "A7"A4 z4|
"G"B2B2 "C"c2d2 "G"B2A2 "D" d4 z4|
"G"g2g2 g4 z4 f2e2|"Am"e2c2 "D7"A2F2 "G"G4 z4|
V:4 clef=bass name="Cello"
%%MIDI program 42
|"G"D4 "D"D2D2 "C"E4 "G"D4|
"D"A,2A,2 "C"C2E2 "G"B,2D2 "A7"A,4|
"G"G,2G,2 "C"G,2G,2 "G"B,2D2 G2G2|
"G"B,4 "D"A,2F2 "G"G4 z4|
|"G"D8 "C"E8|"D"A,8 "G"G,8|
"D7"F4 A4 "G"B4 d4|"Am"c4 "D7"e4 "G"d4 z2 B2||



X:1
T:Test
Z:Arr. Joel Breit
Z:Polk the Bear
L:1/8
Q:1/2=80
M:4/4
K:Gm
V:1 treble nm="Harmonica" snm="Harm."
%%MIDI program 22
"D7" d2 AB cd/c/ BA |"Gm" G2 GB d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
$"Cm" z c2 e g2 fe | "Bb" z d2 B d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
$"Gm" d4 B4 |"D7" c4 A4 |"Gm" B4 G4 |"D7" ^F4 A4 |
$"Gm" d4 B4 |"D7" c4 A4 |"Bb" B2 d2 g4 |"D7" ^f8 :|
|:"Gm" d2 B2 g2 f2 | "Cm" e2 G2 e4 | "Bb" B2 d2 B2 cB | "Am" A3 B c2 e2 |
"D7" d2 f2 a2 g2 | "Gm" g2 b2 a2 g2 | "Ebm" d2 ^f2 e2 ^d2 | "D7" c3 A B2 G2 |
"Gm" B2 G2 G4 | "Cm" c2 E2 E4 | "D7" d2 f2 d4 | "Gm" B2 G2 G4 |
"Cm" c2 G2 G4 | "D7" d2 f2 d4 | "Bb" B2 d2 g4 | "Gd" G4 G2 z2 :|
V:2 bass nm="Tuba" snm="Tba."
%%MIDI program 58
"D7" A,,2 FD A,2 DF | "Gm" G,,2 DG B,,2 AG | "D7" F,3 D E,2 F,2 | "Gm" D,2 A,,2 A,,2 z2 |
"Cm" z G,2 C E,2 DC | "Bb" z A,,2 F A,2 GF | "D7" F,3 D E,2 F,2 | "Gm" D,2 A,,2 A,,2 z2 |
"Gm" D,4 A,,4 | "D7" C,4 F,4 | "Gm" B,4 G,,4 | "D7" F,3 C A,,4 |
"Gm" A,4 F,4 | "D7" C,4 A,4 | "Bb" B,2 D F4 | "D7" F,8 :|
|:"Gm" D,2 A,,2 F,2 E,2 | "Cm" G,2 B,,2 G,4 | "Bb" B,,2 D F2 B,F | "Am" A,,3 B, C2 E,2 |
"D7" F,2 A, C2 B,,2 | "Gm" G,2 B,,2 A,2 G, | "Ebm" D,2 F A,2 G,,2 | "D7" C,3 F, A,,2 G,,2 |
"Gm" D,2 A,,2 A,,4 | "Cm" G,2 C E,4 | "D7" F,2 A, F,4 | "Gm" D,2 A,,2 A,,4 |
"Cm" G,2 C E,4 | "D7" F,2 A, F,4 | "Bb" B,,2 D F4 | "Gd" G,,4 G,,2 z2 :|



X:1
T: Nebular Escape
C: OrchestrAI
M: 6/8
L: 1/8
Q: 3/8=152
K: Cmin
% The piece opens with the majesty of the nebula and the danger of the collapsing star
V:1 clef=treble name="French Horns"
%%MIDI program 60
|: "Cm"G2 G cde | "G"B2 B dBG | "Ab"E2 E cBA | "Bb"F2 F G2 z |
"Cm"c2 c "Fm"ABA | "Bb"G2 F "Eb"EDC | "Cm"C2 E "G"GAB | "Cm"C6 :|
V:2 clef=treble name="Strings"
%%MIDI program 48
|: "Cm"E2 E G2 E | "G"D2 D B,2 D | "Ab"C2 C E2 C | "Bb"F2 F D2 z |
"Cm"G2 B, "Fm"cBA | "Bb"B2 G "Eb"EFG | "Cm"A2 c "G"Bcd | "Cm"E6 :|
V:3 clef=bass name="Celli"
%%MIDI program 42
|: "Cm"C,2 C, G,CG, | "G"B,,2 B,, D,GB, | "Ab"A,,2 A,, E,CA, | "Bb"F,,2 F,, C2 z |
"Cm"C2 E "Fm"F2 D | "Bb"B,,2 A, "Eb"G,A,B, | "Cm"C,2 G, "G"A,B,C | "Cm"C,6 :|
V:4 clef=bass name="Contrabasses"
%%MIDI program 43
|: "Cm"G,,2 G,, C,2 C, | "G"D,2 D, G,2 G, | "Ab"E,2 E, A,2 A, | "Bb"F,2 F, C2 z |
"Cm"C,2 C, "Fm"E,2 E, | "Bb"D,2 D, "Eb"C,2 C, | "Cm"G,2 G, "G"C,2 C, | "Cm"C,6 :|



X:1
T:Epic Tri-Faction Battle
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=160
K:C
% Generate the first violin part
V:1 clef=treble
%%MIDI program 40
|: "C"e2g2c'2e'2 "F"d2c2A2d2|"G" B2A2G2e2 d4 z4|
"C"e2g2c'2e'2 "F"d2c2A2f2|"Am"A4 G4 F4 E4|
"G"B2d2 g2b2 a4 g4|"Em"e4 e4 fgaf g2e2|
"Am"a2c'2 e2a2 "F"d2c2B2A2|"G"B4 c4 d4 G4:|
% Generate the second violin part
V:2 clef=treble
%%MIDI program 40
|: "C"c2e2G2c2 "F"A2G2E2A2|"G"B2d2G2B2 A4 z4|
"C"c2e2G2c2 "F"A2G2E2c2|"Am"A4 G4 F4 E4|
"G"B2d2 G2B2 A4 G4|"Em"e2e2 e2fe z4 g2e2|
"Am"a2c'2 e2a2 "F"A2G2F2E2|"G"G4 A4 B4 G4:|
% Generate the viola part (alto clef)
V:3 clef=alto
%%MIDI program 41
|: "C"G,2C2E2G2 "F"F,2G2C2F2|"G"G,2B,2D2G2 F4 z4|
"C"G,2C2E2G2 "F"F,2G2C2A,2|"Am"A,4 G,4 F,4 E,4|
"G"B,2F2 B,2f2 e4 c4|"Em"e4 e4 fege f2d2|
"Am"A,2C2 E2A,2 "F"F,2G2F2E2|"G"G,4 A,4 B,4 G,4:|
% Generate the cello part (bass clef)
V:4 clef=bass
%%MIDI program 42
|: "C"C,4 G,4 C4 E4|"F"C,4 F,4 E4 A,4|
"G"B,4 D4 D4 B,4| "Am"A,4 G,4 F,4 E,4|
"G"G,4 D4 D4 B,4|"Em"E4 E4 F4 G4|
"Am"A,4 C4 E4 A,4| "F"F,4 G,4 A,4 C,4:|



X:1
T:Mother's Embrace
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=80
K:C
% Voice 1 - Melody
V:1 clef=treble
%% MIDI program 1
|"C" E3 G c2 |"Am" A3 E A2 | "F" F3 A c2 | "G" G4 z2 |
"C"e3 e g2 | "Em" e3^d e2 | "Am" A3 c e2 | "F" G4 z2 |
"Em" B3 G B2 | "Am" c3 E A2 | "F" F3 A c2 | "G7" E4 z2 |
"C"E3 G c2 | "Am" A3 E A2 | "F" F3 c e2 | "C" c6 |]
% Voice 2 - Alto Harmony
V:2 clef=treble
%% MIDI program 1
|"C" C3 E G2 | "Am" A,3 C E2 | "F" A,3 C F2 | "G" B,4 z2 |
"C" G3 E C2 | "Em" G3 F E2 | "Am" E3 C E2 | "F" A,4 z2 |
"Em" G3 B, D2 | "Am" A,3 E C2 | "F" A,3 C F2 | "G7" B,4 z2 |
"C" C3 E G2 | "Am" A,3 C E2 | "F" A,3 C F2 | "C" C6 |]
% Voice 3 - Tenor Harmony
V:3 clef=tenor
%% MIDI program 1
|"C" G,3 C E2 | "Am" E3 A, C2 | "F" C3 F A2 | "G" D4 z2 |
"C" C3 G, C2 | "Em" E3 ^D E2 | "Am" A,3 C E2 | "F" C4 z2 |
"Em" E3 G B,2 | "Am" E3 A, C2 | "F" C3 F A2 | "G7" D4 z2 |
"C" G,3 C E2 | "Am" E3 A, C2 | "F" C3 F A2 | "C" C6 |]
% Voice 4 - Bass Harmony
V:4 clef=bass
%% MIDI program 1
|"C" C,3, G, C2 | "Am" A,,3, E, A,,2 | "F" F,3, A, C2 | "G" G,,4 z2 |
"C" C3 G, E2 | "Em" G,3 F, A,,2 | "Am" A,,3, C E2 | "F" F,4 z2 |
"Em" E,3 G, B,,2 | "Am" A,,3, E, A,,2 | "F" F,3 A, C2 | "G7" G,,4 z2 |
"C" C,3 G, E2 | "Am" A,,3, E, A,,2 | "F" F,3 A, C2 | "C" C,6 |]



X:1
T:Yearning Hearts
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=112
K:Gm
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|: "Gm" G3A B2d2 | "Eb" (3gfe d2 B3 z3 | "Cm" c3d e2g2 | "D7" ^f4 d4 |
"Gm" G3A B2d2 | "Eb" (3gfe d2 B2G z3 | "Cm" c3B "D7" A3G | "Gm" G6 z2 :|
|: "Bb" f3g a2f2 | "Eb" (3gfe d2 B3 z3 | "Cm" e3f g2e2 | "D7" ^f4 a4 |
"Gm" g3a b2g2 | "Eb" (3agf g2 d2B z3 | "Cm" c3B "D7" A3G | "Gm" G6 z2 :|
|: "Eb" B3c d2e2 | "Bb" f3d B3G | "Cm" c3d e2f2 | "D7" A3B ^c2d2 |
"Gm" G3A Bcde | "Eb" (3fed c2 B2G z3 | "Cm" c3B "D7" A3G | "Gm" G6 z2 :|
V:2 clef=bass name="Trombone"
%%MIDI program 57
|: "Gm" D3E F2G2 | "Eb" (3BAG F2 G3 z3 | "Cm" E3F G2A2 | "D7" ^C4 D4 |
"Gm" D3E F2G2 | "Eb" (3BAG F2 D2B, z3 | "Cm" E3D "D7" ^C3D | "Gm" B,6 z2 :|
|: "Bb" D3E F2D2 | "Eb" (3BAG F2 G3 z3 | "Cm" G3A B2G2 | "D7" ^F4 A4 |
"Gm" B,3^C D2B,2 | "Eb" (3EDC B,2 G,2D z3 | "Cm" E3D "D7" ^C3D | "Gm" B,6 z2 :|
|: "Eb" G3A B2c2 | "Bb" d3B G3D | "Cm" E3F G2A2 | "D7" ^F3G A2^F2 |
"Gm" D3E FGA2 | "Eb" (3BAG F2 D2B, z3 | "Cm" E3D "D7" ^C3D | "Gm" B,6 z2 :|
V:3 clef=bass name="Tuba"
%%MIDI program 58
|: "Gm" G,2G,2 G,2F,2 | "Eb" E,2E,2 E,2D,2 | "Cm" C,2C,2 C,2B,,2 | "D7" D,2D,2 D,2C,2 |
"Gm" G,2G,2 G,2F,2 | "Eb" E,2E,2 E,2D,2 | "Cm" C,2C,2 "D7" D,2D,2 | "Gm" G,,6 z2 :|
|: "Bb" B,,2B,,2 B,,2A,,2 | "Eb" E,2E,2 E,2D,2 | "Cm" C,2C,2 C,2B,,2 | "D7" D,2D,2 D,2C,2 |
"Gm" G,2G,2 G,2F,2 | "Eb" E,2E,2 E,2D,2 | "Cm" C,2C,2 "D7" D,2D,2 | "Gm" G,,6 z2 :|
|: "Eb" E,2E,2 E,2D,2 | "Bb" B,,2B,,2 B,,2A,,2 | "Cm" C,2C,2 C,2B,,2 | "D7" D,2D,2 D,2C,2 |
"Gm" G,2G,2 G,2F,2 | "Eb" E,2E,2 E,2D,2 | "Cm" C,2C,2 "D7" D,2D,2 | "Gm" G,,6 z2 :|



X:1
T:Pirate Bytes Encounter
C:OrchestrAI
M:6/8
L:1/8
Q:1/4=120
K:Dmin
%%MIDI chordnameoff
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|: "Dm"D2F AFD | "C"C2E GEC | "Bb"D2B, B,DF | "A"A2c EAc :|
"Dm"F2A dAF | "Am"E2c AEC | "Bb"F2D GFG | "A"A2F D2z |
"Dm"D3 F3 | "C"C3 A,3 | "Gm"G3 B,3 | "A"A3 c3 |
"Dm"D2F AFD | "C"C2E GEC | "Bb"D2B, B,DF | "A"A2c EAc |
V:2 clef=treble name="Arpeggio Synth"
%%MIDI program 82
|: "Dm"z2D F2A | "C" z2G E2C | "Bb"z2B, D2F | "A" z2E A,2C :|
"Dm" A2d F2A | "Am" A2e c2E | "Bb" D2G B,2D | "A" F2A D2z |
"Dm"z3 z3 | "C" z3 z3 | "Gm" z3 z3 | "A" z3 z3 |
"Dm"z2D F2A | "C" z2G E2C | "Bb"z2B, D2F | "A" z2E A,2C |
V:3 clef=bass name="Bass Synth"
%%MIDI program 38
|: "Dm"D,3 A,3 | "C"C,3 G,3 | "Bb"B,,3 F,3 | "A"A,,3 E,3 :|
"Dm"D,2A, D,2F | "Am"C,2E C,2A, | "Bb"B,,2D, B,,2G, | "A"A,2C A,2F |
"Dm"D,3 z3 | "C"C,3 z3 | "Gm"G,3 z3 | "A"A,3 z3 |
"Dm"D,3 A,3 | "C"C,3 G,3 | "Bb"B,,3 F,3 | "A"A,,3 E,3 |



X:1
T:ABC Notation Example
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=120
K:C
V:1 clef=treble
%%MIDI program 1
|: "C"C2E2G2 | "Am"A2A2B2 | "F"F2A2c2 | "G"G4G2 |
"C"E2G2c2 | "Dm"D2F2A2 | "Em"E2G2B2 |1"G7"G4F2 :|2 "G7"G4z2 |]
V:2 clef=treble
%%MIDI program 49
|: "C"C4z2 | "Am"A,2C2E2 | "F"F4z2 | "G"D2B,2D2 |
"C"E4z2 | "Dm"F3ED2 | "Em"G2B,2D2 |1 "G7"F4E2 :|2 "G7"F4z2 |]
V:3 clef=bass
%%MIDI program 42
|: "C"C,4C2 | "Am"A,4A,2 | "F"F,4F,2 | "G"G,4G,2 |
"C"C,4C2 | "Dm"D,4D,2 | "Em"E,4E,2 |1 "G7"D,4C,2 :|2 "G7"D,4z2 |]



X:1
T:Friday Night – Complete
C:JLEM, Expanded by OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Bb
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|: FGAB z4 | B2 ._AB z2 GA | B2 ._AB z4 | FGAB z4 | d2c2B2 A2 | "Eb" G4 G2 FE | "F" D2C2 B,4 :|
w: Be-cause- it's Fri-day night! And we're feel-in' right! We've got the mu-sic on,
|: "Bb" d2B2 c2A2 | "Gm" BABc "_D7"d2c2 | "Eb" B2B2 "F" FEA2 | [1 "Bb" B2F2 F2A2 :|[2 "Bb" B8 :|
w: And we're caugh-t in the light. The time is ours un-til sunrise,
V:2 clef=treble name="Strings"
%%MIDI program 48
|: B,2B,2 D2F2 | B,2DF B,4 | B,4 B,2A2 | G4 A2A2|"Eb" G4 F2B2 | "F" F2G2 A4 :|
V:3 clef=bass name="Bass"
%%MIDI program 32
|: B,,4 B,,2D2 | B,,2F2 B,,4 | B,,4 B,,2C2 | F,4 G,4 | "Eb" Eb,,4 E2A, | "F" F,4 B,,4 :|



X:1
T:Velocity Ode
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=180
K:Bm
% The piece starts with high energy and maintains momentum throughout.
V:1 clef=treble name="Trumpet" subname="Trpt."
%%MIDI program 56
|:"Bm" f4f4 | "A" e6e2 | "G" d6B2 | "F#m" cBAG FEDC | "Bm" B,4F4 G | "E" ^G6E2 | "Bm" F4F4 | "F#7" ^A6^G2 |
"Bm" f4f4 | "A" e4c4 | "G" B4GFED | "F#m" F6ED | "Bm" B,2F2G2A2 | "E" B2^A2G2F2| "Bm" F4F4 | "F#7" ^G8 :|
V:2 clef=treble name="Violin" subname="Vln."
%%MIDI program 40
|:"Bm" d2d2 e2f2 | "A" c2c2 B2A2 | "G" B2B2 AGFE | "F#m" F2F2 E2D2 | "Bm" F2F2 G2A2 | "E" B2^A2 G2F2 | "Bm" d2d2 e2f2 | "F#7" ^c2e2 d4 |
"Bm" d2d2 e2e2 | "A" c2B2 A2G2 | "G" B2A2 G2F2 | "F#m" F2E2 D2C2 | "Bm" F2G2 A2B2 | "E" e3f g2fe| "Bm" d2d2 e2f2 | "F#7" ^c8 :|
V:3 clef=bass name="Tuba" subname="Tuba"
%%MIDI program 58
|:"Bm" B,2F2 B,2F2 | "A" A,2E2 A,2E2 | "G" G,2D2 G,2D2 | "F#m" F,2C2 F,2C2 | "Bm" B,2F2 G,2A2 | "E" E,2B,2 ^G,2E2 | "Bm" B,2F2 B,2F2 | "F#7" F,2C2 F,4 |
"Bm" B,2F2 B,2F2 | "A" A,2E2 A,2C2 | "G" G,2F2 E2D2 | "F#m" F,2E2 D,4 | "Bm" B,2^A,2 B,2F2 | "E" E,2G,2 F,2^G,2 | "Bm" B,2F2 B,2F2 | "F#7" ^A,8 :|



X:1
T:Voyage of Discovery
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=88
K:Ddor
V:1 clef=treble name="Flute"
%%MIDI program 73
D2 |:"Dm"A3 B A2|G3 F E2|"Gm"D3 E D2|C3 D E2|
"Dm"A3 B A2|"Am"G3 A G2|"F"C2 E F z G|1 "A"A4 D2:|2 z2 "A"A4||
K:C
CD |:"C" E2 G2 E2 | "F" F2 A2 G2 | "Dm" D2 F2 E2 | "G" G3 F E2 |
"C" E2 G2 E2 | "Am" A2 c2 B2 | "Dm" D2 F2 D2 | "G" G2 A2 B2 |
"C" c2 e2 d2 | "F" d2 c2 B2 | "Dm" A2 G2 F2 | "Am" G2 F2 E2 |
"Dm" D2 F2 G2 | "G" A2 G2 F2 | "C" E4 :|
V:2 clef=treble name="Strings"
%%MIDI program 48
z2|: "Dm"F2 F2 F2 | "Dm"A2 A2 A2 | "Gm"B2 B2 B2 | "Gm"D2 D2 D2 |
"Dm"F2 F2 F2 | "Am"e2 e2 e2 | "F"c2 c2 c2 | "A"A2 B2 c2 |
"Dm"d2 d2 d2 | "Dm"A2 A2 A2 | "Gm"B2 B2 B2 | "Gm"D2 D2 D2 |
"Dm"F2 F2 F2 | "Am"e2 e2 e2 | "F"c2 c2 c2 | "A"A2 B2 c2 |
"Dm"d2 c2 B2 | "Dm"A2 G2 F2 | "Gm"D2 E2 F2 | "Gm"G3 F E2 |
"Dm"d2 c2 B2 | "Am"A2 G2 F2 | "F"c2 c2 c2 | "A"A4 z2 :|
V:3 clef=bass name="Harp"
%%MIDI program 46
z2 |: "Dm"D4 D2 | "Dm"F2 A2 d2 | "Gm"G4 G2 | "Gm"B2 d2 g2 |
"Dm"A,4 A,2 | "Am"C2 E2 A,2 | "F"F2 A2 c2 | "A"E2 ^C2 E2 :|
"Dm"A,4 A,2 | "Dm"F2 A2 d2 | "Gm"G4 G2 | "Gm"B2 d2 g2 |
"Dm"A,4 A,2 | "Am"C2 E2 A,2 | "F"F2 A2 c2 | "A"E2 ^C2 E2 |
"Dm"D4 D2 | "Dm"A,2 D2 F2 | "Gm"G4 G2 | "Gm"B,2 G2 B2 |
"Dm"A,4 A,2 | "Am"C2 E2 A,2 | "F"F2 A2 c2 | "A"A,4 z2 :|



X:1
T:The Call of Horizons
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Am
V:1 clef=treble
%%MIDI program 1
|: "Am"e2 | "E"^G2A2 B2c2 | "Am"A4- A2e2 | "Dm"d4- d2c2 | "E"B6 z2 :|
|: "C"c2 | "G"B2d2 G2AB | "F"A4- A2c2 | "Am"e2A2 "E7"^G2E2 | "Am"A6 z2 :|
|: "F"^d2 | "C"e2G2 c2e2 | "Am"A3G E2^D2 | "E7"E2^G2 B2e2 | "Am"E6 z2 :|
|: "Am"E2 | "Dm"F2A2 d2f2 | "G"ed cB G2B2 | "E7"^G2A2 B2e2 | "Am"A6 z2 :|
V:2 clef=treble
%%MIDI program 49
|: "Am"A2 | "E"c2B2 A2G2 | "Am"A4- A2A2 | "Dm"d4- d2d2 | "E"c6 z2 :|
|: "C"c2 | "G"B2B2 G2G2 | "F"A4- A2A2 | "Am"e2e2 "E7"^G2E2 | "Am"A6 z2 :|
|: "F"^d2 | "C"e2e2 c2c2 | "Am"A2A2 E2A2 | "E7"E2^G2 B2e2 | "Am"E6 z2 :|
|: "Am"E2 | "Dm"F2F2 d2d2 | "G"ed cB G2B2 | "E7"^G2A2 B2e2 | "Am"A6 z2 :|
V:3 clef=bass
%%MIDI program 42
|: "Am"A,2 | "E"E,2A,2 E3 G, | "Am"A,4- A,2E2 | "Dm"D,4- D,2A,2 | "E"G,6 z2 :|
|: "C"C,2 | "G"B,,2D2 G,2B,2 | "F"A,4- A,2C2 | "Am"A,2E2 "E7"E,2B,,2 | "Am"A,6 z2 :|
|: "F"^d,2 | "C"C2E2 C2E2 | "Am"A,3G, E,2^D,2 | "E7"E,2^G,2 B,2e2 | "Am"E,6 z2 :|
|: "Am"E,2 | "Dm"F,2A,2 D2F,2 | "G"e,d, c,B,, G,2B,,2 | "E7"^G,2A,2 B,2e2 | "Am"A,6 z2 :|



X:1
T:Virtuosic Celebration
C:OrchestrAI
Q:1/4=155
L:1/16
M:2/2
K:C clef=G2
V:1
%%MIDI program 0
K:C
|:[C^EGc]7 z3:2[CEGc]8 zG31/32A31/32|[CEGc]15 zG3(3:2G/2zF3-(3:2F/2zE3-(3:2E/2zD3-(3:2D/2z|C7 zC2 zC31/16C31/16D31/16E31/16|F3(3:2F/2zG3(3:2G/2zA3(3:2A/2zB3(3:2B/2z|
c7 z2C31/32D31/32E31/32F31/32G31/32A31/32B31/32|c3z3 G3-F3-E3-D3|C7 z3 E3-F3-G3-A3|B3(3:2B/2z c31/32d31/32e31/32f31/32g31/32a31/32b31/32|
c15 z g3-(3:2g/2zf3-(3:2f/2ze3-(3:2e/2zd3-(3:2d/2zc3-(3:2c/2z|:B,6 C4 D4 E4|F6 G4 A4 B4|c6 d4 e4 f4|g6 a4 b4 c'4|]



X:1
T:Clockwork Carnival - Extended Version
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=152
K:Gmaj
V:1 clef=treble name="Flute 1"
%%MIDI program 73
|:"G" B2B2 d2g2 |"D7" f2a2 g4 | "C"e2e2 "G"d2B2 | "A7"A2F2 "D7"F2G2 |
"G" G4- G2z2 | "D7" F2A2 G4 | "C" E4 E2G2 | "Am" A4 A2c2 | "D7" D3E F2G2 | "G" G8 :|
V:2 clef=treble name="Flute 2"
%%MIDI program 73
|:"G" d4 B2G2 | "D7"A4 F2A2 | "C"E4 "G"D4 | "A7"c4 "D7"B4 |
"G" G8 | "D7" A4 F4 | "C" E4 c4 | "Am" A4 e4 | "D7" d4 B4 | "G" G,8 :|
V:3 clef=treble name="Clarinet 1"
%%MIDI program 71
|:"G" d2d2 B2G2 | "D7"f3e d2c2 | "C"e2e2 "G"g2e2 | "A7"c2A2 "D7"A2G2 |
"G" G4- G2z2 | "D7" f4 d4 | "C" e3d c4 | "Am" a2g2 e4 | "D7" d2c2 B4 | "G" G4- G2z2 :|
V:4 clef=bass name="Bassoon"
%%MIDI program 70
|:"G" G,2B,2 D4 | "D7"F2A2 D4 | "C"C2E2 G,4 | "A7"C2E2 "D7"F4 |
"G" G,4- G,2z2 | "D7" F,4 A,4 | "C" E,2G,2 C4 | "Am" A,4 E,2C2 | "D7" F,2A,2 D4 | "G" G,,8 :|
V:5 clef=treble name="Trumpet"
%%MIDI program 56
|:"G" B4 d4 | "D7" g4 f4 | "C"e4 "G"d2B2 | "A7"A2F2 "D7"F2G2 |
"G" G8 | "D7" f2a2 g4 | "C" e4 e2c2 | "Am" a4 a2e2 | "D7" d4 F2D2 | "G" G8 :|
V:6 clef=treble name="First Violins"
%%MIDI program 40
|:"G" d2^c2 d2e2 | "D7"f2g2 a4 | "C"g2f2 "G"e2d2 | "A7"c2e2 "D7"d4 |
"G" B8 | "D7" f2g2 a4 | "C" g2f2 e4 | "Am" a2g2 f2e2 | "D7" d2c2 B4 | "G" D8 :|
V:7 clef=alto name="Viola"
%%MIDI program 41
|:"G" B,4 D4 | "D7"G,4 A,4 | "C"C4 "G"B,4 | "A7"A,4 "D7"B,4 |
"G" G,8 | "D7" A,4 F,4 | "C" E,4 C,4 | "Am" A,4 A,4 | "D7" D,4 F,4 | "G" G,8 :|
V:8 clef=bass name="Cello"
%%MIDI program 42
|:"G" G,2B,2 D4 | "D7"F2A2 D4 | "C"C2E2 G,4 | "A7"C2E2 "D7"F4 |
"G" G,4- G,2z2 | "D7" F,2A,2 D4 | "C" C,4 E4 | "Am" A,2E,2 C4 | "D7" F,4 G,4 | "G" G,,8 :|
V:9 clef=bass name="Double Bass"
%%MIDI program 43
|:"G" G,,2B,,2 D,4 | "D7"F,2A,2 D,4 | "C"C,2E,2 G,,4 | "A7"A,,2C,2 "D7"F,4 |
"G" G,,8 | "D7" F,,2A,,2 D,2 z2 | "C" C,,4 C,4 | "Am" A,,4 E,,2C,2 | "D7" D,4 F,4 | "G" G,,8 :|



X:1
T:Pride of Triumph
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=112
K:Cmaj
V:1 clef=treble
%%MIDI program 56
|"C"G4 E4|"G"D4 B,4|"Am"C4 A,4|"Em"B4 G4|
"E7"e4 c4|"Am"A4 E4|"F"d4 c4|"G"B4 G4|
"C"G4 E4|"G"D4 B,D3|"Am"C4 A,4|"F"A4 c4|
"G"D4 D4|"E7"B,4 E4|"Am"A,4 c4|"G"G8:|
V:2 clef=treble
%%MIDI program 56
|"C"E4 C4|"G"B,4 D4|"Am"A,4 E4|"Em"G4 B,4|
"E7"e4 c4|"Am"A4 E4|"F"A4 c4|"G"B4 D4|
"C"E4 C4|"G"G,4 B,4|"Am"A,4 C4|"F"c4 A4|
"G"B,4 D4|"E7"G4 B,4|"Am"A4 E4|"G"G8:|
V:3 clef=bass
%%MIDI program 43
|"C"C4 G,4|"G"D4 B,,4|"Am"E4 A,,4|"Em"G4 B,4|
"E7"G,4 E4|"Am"A,4 E,4|"F"F,4 A,4|"G"G,4 D4|
"C"C4 G,4|"G"D4 B,,4|"Am"E4 C4|"F"A4 c4|
"G"B,4 D4|"E7"F,4 A,4|"Am"A,,4 C4|"G"G,,8:|



X:1
T:Yearning Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Cmaj
% Instruments assignation
V:1 clef=treble name="Trumpet"
%%MIDI program 56
V:2 clef=treble-8 name="Trombone"
%%MIDI program 57
V:3 clef=bass name="Tuba"
%%MIDI program 58
% Trumpet takes the lead melody
[V:1]
|: "C"E4 G4 | "Am"A3 G E2 C2 | "F"F4 A4 | "G"G3 F E2 D2 |
"C"E4 G4 | "Am"A3 G E2 C2 | "F"F4 E4 | "G"G8 :|
% Trombone provides supporting harmonies
[V:2]
|: "C"C4 E4 | "Am"C3 B A2 C2 | "F"A,4 C4 | "G"B,4 C4 |
"C"C4 E4 | "Am"C3 B A2 C2 | "F"A,4 B,4 | "G"C8 :|
% Tuba anchors the harmony with a strong bass line
[V:3]
|: "C"C,2 C,2 E,4 | "Am"A,4 E,4 | "F"F,4 A,4 | "G"G,2 F,2 E,2 D,2 |
"C"C,2 C,2 E,2 G,2 | "Am"A,4 E,4 | "F"F,2 F,2 A,4 | "G"G,8 :|



X:1
T:Bop on Hold
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Cmaj
V:1 clef=treble
%%MIDI program 5
|"C"E2G2c2E2|"Am"A2^G2A2B2|"F"F2A2d2c2|"G7"B2^G2B2d2|
"C"c2e2g2e2|"Am"a2g2a2b2|"F"f2a2f2e2|"G7"b2d'2b2g2|
"Dm7"d2f2a2d'2|"Em7"e2g2b2e'2|"Dm7"d2c2B2A2|"G7"G4- G4|
"C"E2G2c2E2|"Am"A2^G2A2B2|"F"F2A2d2c2|"G7"B2G2B2d2|
"C"c4 e4|"Am"a4 c'4|"F"f4 a4|"G7"g4 b4|
"C"e2g2 c'2e2|"Am"a2f2 a2c'2|"F"f2a2 d'2f2|"G7"g2b2 d'4|
V:2 clef=bass
%%MIDI program 34
|"C"C,4 G,4|"Am"A,4 E4|"F"F,4 C4|"G7"G,4 D4|
"C"C4 G,4|"Am"A,4 E4|"F"F,4 C4|"G7"G,4 D4|
"Dm7"D,4 A,4|"Em7"E,4 B,4|"Dm7"D,4 A,4|"G7"G,4 D4|
"C"C,4 G,4|"Am"A,4 E4|"F"F,4 C4|"G7"G,4 D4|
"C"C,4 G,4|"Am"A,4 E4|"F"F,4 C4|"G7"G,4 D4|
"C"C,4 G,4|"Am"A,4 E4|"F"F,4 C4|"G7"G,4 D4|



X:1
T:City Dawn
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=120
K:C
V:1 clef=treble name="Electric Piano 1"
%%MIDI program 4
|:"C"E2C2E2G2 c4E2G2 |"F"A2F2A2c2 f4A2c2 | "G"B2G2B2d2 g4B2d2 |"Am"c2A2E2A2 a4e2a2 |
"C"e2c2G2c2 e8 |"Dm"f2d2A2f2 d8|"G"b2g2d2g2 B8 | "C"c2G2C2E2 G8:|
|:"C"G4c4 e4g4 | "F"f4a4 c'4a4 | "G"g4d'4 b4g4 | "C"e'4c'4 g4e4 |
"F"A4a4 f4A4 | "E7"G4^F4 E8 | "Am"A4e4 a4e4 | "G"G8 C8:|
V:2 clef=bass name="Acoustic Bass"
%%MIDI program 32
|:"C"C4E4 G,4C4 | "F"F4A,4 C4F4 | "G"G,4B,4 D4G,4 | "Am"A,4E4 A,4E4 |
"C"C4G,4 E,4C4 | "Dm"D4F4 A,4D4 | "G"G,4B,4 D4G4 | "C"C8 E,8:|
|:"C"G,4E4 C4G,4 | "F"F,4C4 A,4F,4 | "G"G,4D4 B,4G,4 | "C"C4E4 G,4C4 |
"F"F,4C4 A,4F,4 | "E7"^F,4G,4 E,4^F,4 | "Am"A,4E4 A,4E4 | "G"G,8 C8:|



X:1
T:Rhythms of Elation
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=152
K:Bb
% The piece begins with a punchy rhythm that builds anticipation and excitement
V:1 clef=treble name="Trumpet" subname="Trpt."
%%MIDI program 56
|:"Bb"d2f2 B4 d2f2|g2f2 e4 d2c2|B2B2 B4 c2d2|e6 e2 d4|
f2f2 f4 g2a2|b2a2 g4 f2e2|d2c2 "F7"e4 "Eb"e2f2|"Bb"d6 z2 "F"c4|
"Bb"d2f2 B4 d2f2|g2 a2 b4 a2g2|"Gm"f2g2 "F"e4 "Eb"d2c2|"Bb"b6 z2 "F7"a4|
"Bb"d4 "Eb"e2f2 "F7"e2d2|"Gm"f4 "F"e2d2 "Eb"e2c2|"Bb"d2f2 "F7"B4 g2f2|"Bb"B8 z4:|
V:2 clef=treble name="Violin" subname="Vln."
%%MIDI program 40
|:"Bb"b6 f4 g2|f6 e4 d2|c2c2 F6 G2|A6 A2 G4|
B2B2 B4 c2d2|e6 d2 c4|B2B2 c4 e2d2|"F"e6 z2 "Eb"d4|
"Bb"b6 f4 g2|"Gm"f2g2 "F"e4 "Eb"d2c2|"Bb"B4 F4 G2F2|"F7"A z48|
"Bb"B4 "Eb"c4 "F7"d4|"Gm"e4 "F"d4 "Eb"c4|"Bb"f4"F7" e2d2 c4|"Bb"B8 z4:|
V:3 clef=treble-8 name="French Horn" subname="Hrn."
%%MIDI program 60
|:"Bb"d4 d2f2 b2bf|g2gf e2e2 d2dc|"F"a2aa f2e2 "Eb"d2c2|"Bb"b6 b2 a4|
"Bb"b4 g2f2 e2c2|"Gm"d2d2 "F"c4 "Eb"d2d2|"Bb"e2e2 "F7"f4 "Bb"g2a2|"Bb"b8 z4:|
V:4 clef=bass name="Tuba" subname="Tba."
%%MIDI program 58
|:"Bb"B,4 B,2D2 F4|G,4 F,2E,2 D,4|"F7"A,4 A,C2 z F2E2|"Eb"~D, z48|
"Bb"B,4 B,2D2 F4|"Gm"G,2A,2 "F"B,4 "Eb"G,2F,2|"Bb"~F, z48|"Bb"B,8 z4:|



X:1
T:Triumphant Journey
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=144
%%score (V1 V2) (V3 V4)
K:Cmaj
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"C"G4 e4| c4 G4| "Am"A4 e3 f| "G"g4 z4| "F"f4 a4| "C"e4 G2 z2| "G"G4- G2 FG| "C"C4 z4:|
|:G2 E2 C2 D2| E4 z2 E2| F2 G2 A2 G2| F4 z2 z2|
G2 E2 C2 D2| "Am"E4 A3 G| F4 E2 C2| "G"G4 z4:|
V:2 clef=treble name="Arp. Synth" subname="Arp."
%%MIDI program 81
|:"C"e2 z2 c'2 z2| e2 z2 c'2 z2| "Am"e2 z2 a2 z2| ^f2 z2 z4| "F"f2 z2 a2 z2| "C"g4 z4| "G"g2 z2 =f2 z2| "C"e4 z4:|
|:=f2 e2 e4| z2 G4- G2| c'4 ^f2 e2| d4 z2 z2|
e2 e2 c'2 z2| "Am"e4 A2 z2| "F"f2 g2 a3 g| "G"=f4 z4:|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|:"C"C2 C2 C2 C2| G,2 G,2 C2 C2| "Am"A,2 A,2 A,2 A,2| "G"G,2 G,2 G,2 G,2|
"F"F,2 F,2 F,2 F,2| "C"C2 C2 C2 C2| "G"G,2 F,2 E2 D2| "C"C2 C2 C2 C2:|
|:C2 G,[C2C2] z E2| C2 C2 C2 E2| F2 C2 F2 A2| G,4- G,4|
C2 G,[C2C2] z E2| "Am"A,4 A,2 ^F2| "F"G4 F4| "G"G,4 z4:|
V:4 clef=bass name="Chords Synth" subname="Chords"
%%MIDI program 92
|:"C"C4 E2 G2| C4 E2 G2| "Am"A4 C2 E2| A4- A4|
"G"G4 B2 d2| "F"F4 A2 c2| "G"G4 B2 d2| "C"C4 z4:|
|:C4- C2 G2 E2| C4 C2 E2 G2| F4 F2 A2 C2| G4- G4 z2|
C4- C2 G2 E2| "Am"A4 A2 C2 E2| "F"F4 A2 c2 d2| "G"G4 z4:|



X:1
T:Test
L:1/8
Q:1/2=80
M:4/4
K:Gm
V:1 clef=treble name="Trumpet"
%%MIDI program 56
"D7" d2 AB cd/c/ BA |"Gm" G2 GB d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
"Cm" z c2 e g2 fe | "Bb" z d2 B d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
"Gm" d4 B4 |"D7" c4 A4 |"Gm" B4 G4 |"D7" ^F4 A4 |
"Gm" d4 B4 |"D7" c4 A4 |"Bb" B2 d2 g4 |"D7" ^f8 :|
V:2 clef=bass name="Trombone"
%%MIDI program 57
"D7" A,3 B,  D2 E2 |"Gm" G,3 A,  C2 D2  |"D7" A,3 B,  D2 E2   |"Gm" G,3 A,  G,3 z2 |
"Cm" G,3 B,  E2 G2   | "Bb" F3 A,  D2 F2   |"D7" A,3 B,  D2 E2   |"Gm" G,3 A,  G,3 z2 |
"Gm" D,4 D,4         |"D7" C,4 A,4         |"Gm" B,,4 G,4        |"D7" A,4 F,4         |
"Gm" D,4 D,4         |"D7" C,4 A,4         |"Bb" B,,2 D, F,4     |"D7" A,8             :|



X:1
T:Baroque Quartet Sketch
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Gmaj
V:Vln1 name="Violin I" clef=treble
%%MIDI program 40
|:"G" D2G2 B2A2 | "Am" A2c2 e2d2 | "Bm" B2d2 "D7"f2e2 | "G" g4- g2f2 |
"D" d2F2 "A7"A2G2 | "Em" E2G2 e2^d2 | "D7" d2g2 f2e2 | "G"g4 "D7"d4 |
"G" c2B2 A2G2 | "Em" B2A2 G2F2 | "Am" E2F2 G2A2 | "D7"F2E2 "G"G4 :|
V:Vln2 name="Violin II" clef=treble
%%MIDI program 40
|:"G" G,2B2 D2G2 | "Am" A,2E2 A2c2 | "Bm" B,2F2 D2d2 | "G" G,4- G2A2 |
"D" A,2D2 "A7"C2E2 | "Em" G,2E2 G2B2 | "D7" D2^C2 D2C2 | "G"B,4 "D7"A,4 |
"G" D2E2 F2G2 | "Em" E2D2 G,2F2 | "Am" c2B2 A2^G2 | "D7"A2^G2 "G"G4 :|
V:Vla name="Viola" clef=alto
%%MIDI program 41
|:"G" B,2D2 G2B2 | "Am" c2E2 A2c2 | "Bm" d2F2 B2d2 | "G" d4- d2B2 |
"D" F2A2 "A7"A2E2 | "Em" G2B2 e2G2 | "D7" d2G2 F2D2 | "G"D4 "D7"A,4 |
"G" G2A2 B2c2 | "Em" B2A2 G2F2 | "Am" E2D2 C2B,2 | "D7"A,2G,2 "G"G4 :|
V:Cel name="Cello" clef=bass
%%MIDI program 42
|:"G" G,2,2 D,2,2 z2 | "Am" A,2,2 C,2,2 z2 | "Bm" B,2,2 F,2,2 z2 | "G" G,,4- G,2,B,2 |
"D" D,2,2 A,2,2 z2 | "A7" E,4 C,2,2 z | "D7" D,2,2 F,4 z | "G"G,4 "D7"D,4 |
"G" G,2,2 A,2,2 z2 | "Em" G,2,2 B,4 z | "Am" A,,2,2 E,2,2 z2 | "D7"A,4 "G"G,4 :|



X:1
T:Balkan Beats Encounter
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=174
K:Em
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"Em" B4 e4 B4 A2G2|"Am" A4 e4 A4 F2E2|"B7" F4 B4 F4 E2D2|"Em" G,4 B,4 E4 z4|
"Em" B3c d4 e2d2 B2AG|"Am" A2AB c4 A4 G2FE|"B7" F2GA B2c2 B4 A2GF|"Em" E8- E2z2 B,2z2|
"Em" e2^d2e2 B2G2A2 F2G2|"Am" A2B2c2 A4 G2E2F2|"B7" B2A2F2 D4 E2D2C2|"Em" B,8- B,2E2 G,2B,2|
"G" G,2B2d2 g2f2e2 d2c2|"D" ^D2F2A2 d2c2B2 A2G2|"Am" A,2e2^c2 a2g2f2 e2d2|"B7" B,2F2B2+f2 ^f2e2 d2c2|
"G" B4 e4 g2B2 d4|"D" F4 A4 d2F2 A2d2|"Am" e8- e4 A4- A2|"B7" B6 B2+F2 z2 B4:|
V:2 clef=bass name="Bass Synth"
%%MIDI program 38
|:"Em" E,8- E,2G,2 B,2D2|"Am" A,8- A,2C2 E2A,2|"B7" B,8- B,2=^F2 B,2D2|"Em" E,8- E,2=^G,2 B,2D2|
"Em" E,2E,2 F2G2 A2A2 G2F2|"Am" A,2A,2 A2B2 c2c2 B2A2|"B7" B,2B,2 B2A2 G2=^F2 ^F2E2|"Em" E,8- E,2G,2 B,2D2|
"Em" E,3^D2E,2 G,2F,2G,2 A,2B,2|"Am" A,2G,2F,2 E,4 z2 A,2E,2|"B7" B,2A,2^F,2 z2 D,8|"Em" E,8- E,3^D2 E,2G,2|
"G" G,2G,2 G,2A,2 B,2B,2 B,2A,2|"D" D2^D2D2 E2 F2F2 F2E2|"Am" A,2G,2F,2 z2 E,8|"B7" B,2A,2G,2=F,2 E,8|
"G" G,2G,2 A2B2=G2F2 E,2^D2|"D" D2^D2E2F2G2G2=F2E2|"Am" A,8- A,4 A,2A,2|"B7" B,8- z2 B,6:|



X:1
T:Celestial Whispers
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:Cmaj
% Melody line played by celesta for an ethereal sound
V:1 clef=treble
%%MIDI program 8
|:"C"e2 G4|"Am"e2 A3 B/2c/2|"F"d2 c4|"G"B2 G3A|
"C"e2 G4|"Am"e2 A3 G/2F/2|"Dm"E2 F3 D/2E/2|"G7"F2 D2 C2|
% Harmonic accompaniment with brass sounds for richness
V:2 clef=treble
%%MIDI program 56
|"C" C2 E3 G | "Am"A2 c3 e|"F"A2 A3 c|"G"B,2 D3 G|
"C"C2 E3 G | "Am"A2 c3 e|"Dm"F2 A3 d|"G7"B,2 D3 G|
V:3 clef=treble
%%MIDI program 56
|"C"G,2 C3 E | "Am"G,2 A,3 B,|"F"F,2 F3 A|"G"D2 B,3 D|
"C"G,2 C3 E | "Am"G,2 A,3 B,|"Dm"A2 d3 f|"G7"F2 D3 B,|
% Lower brass for full-bodied sound
V:4 clef=bass
%%MIDI program 61
|"C"C,2 C,4|"Am"A,2 A,4|"F"F,2 F,3 A,|"G"G,2 G,3 B,|
"C"E,2 C,4|"Am"A,2 E3 A,|"Dm"D,2 D,3 F,|"G7"G,2 B,,3 D,|
% Ethereal celesta run
V:1
|:"F"e2 a6|"C"g2 e6|"Dm"f2 d6|"G"b2 g6|
"Am"e2 c6|"D7"^f2 d6|"G"B2 g6|"C"e2 c6:|
% Brass harmonics shifting and changing
V:2
|:"F"c2 c6|"C"e2 e6|"Dm"f2 f6|"G"g2 g6|
"Am"a2 a6|"D7"a2 a6|"G"b2 b6|"C"c2 c6:|
% Contrapuntal brass lines
V:3
|:"F"c2 a4 z2|"C"e2 g4 z2|"Dm"f2 d4 z2|"G"b2 d4 z2|
"Am"c'2 a4 z2|"D7"d'2 b4 z2|"G"b2 g4 z2|"C"e2 c4 z2:|
% Bass brass adding depth
V:4
|:"F"A,2 F,4|"C"C,2 G,,4|"Dm"D,2 A,,4|"G"G,2 B,,4|
"Am"A,,2 E,4|"D7"D,2 F,4|"G"G,2 B,,4|"C"C,2 E,4:|



X:1
T:Neon Sprint
C:OrchestrAI
M:7/8
L:1/16
Q:3/8=152
K:Cmin
% The urgent and technological tone is set by quick, repeating figures and tense harmonies
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|: "Cm"G2G2 G4 E2C2| "Bb"Z2 D2C2 B,6| "Ab"B,2C2 "G7"D2E2 [G4B4] | "Cm"E4-C2 "Fm"D2C2 B,2 |
| "Cm"G2^F2 G4 E2C2| "Eb"D2D2 E2G2 F2F2| "Ab"E2C2 "Gm"D2G2 "G7"C2G2 E2| "Cm"C2B,2 z8 :|
V:2 clef=treble name="Rhythm Synth"
%%MIDI program 82
|: "Cm"c4 c4 z4 | "Bb"f4 f2 e2 c4 | "Ab"e4 c4 z2 "G7"d4 | "Cm"c8 "Fm"c8 |
| "Cm"c4 d4 "Eb"e4 z2 | "Eb"g4 g2 "Fm"f2 d4 | "Ab"e4 e2 "Gm"g4 "G7"d4 | "Cm"c8 z8 :|
V:3 clef=bass name="Bass Synth"
%%MIDI program 38
|: "Cm"C4 C,4 C,4 | "Bb"B,4 B,4 B,4 | "Ab"A,4 A,4 A,4 | "G7"G,4 G,4 G,4 |
| "Cm"C8- C4 C4 | "Eb"E,4 E,4 E,4 | "Fm"F,4 F,4 F,4 | "G7"G,4 G,2 G2 "Cm"C,4 :|



X:1
T:Boss Battle Blitz
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=172
K:Bm
% The introduction sets the stage with powerful, driving rhythms
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"Bm"B8 F8|G8 E8|F8 D8|E8 C8|
d4d4 c4B4|B4A4 G4F4|E4E4 E4D4|F8 z8|
"Em"G4G4 F4E4|F4G4 A4G4|F4E4 E4D4|C8 "F#m"A,8|
"Bm"B8 F8|G8 E8|F8 D8|E8 C8|
d4d4 c4B4|B4A4 G4F4|E4E4 E4D4|F8 z8:|
V:2 clef=treble name="Brass" subname="Brass"
%%MIDI program 61
|:"Bm"d8 a8|b8 f8|e8 c8|d8 A8|
B4B4 A4G4|A4B4 c4B4|A4G4 G4F4|E8 z8|
"Em"f4f4 e4d4|e4f4 g4f4|e4d4 d4c4|B8 "F#m"F,8|
"Bm"d8 a8|b8 f8|e8 c8|d8 A8|
B4B4 A4G4|A4B4 c4B4|A4G4 G4F4|E8 z8:|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|:"Bm"B,4 B,4 A,8|G,8 E,8|F,8 D,8|E,8 C,8|
B,4 B,4 A,4 G,4|A,4 B,4 C,8|D,4 D,4 E,4 F,4|G,8 z8|
"Em"G,4 G,4 F,8|E,8 D,8|C,8 A,8|B,8 "F#m"F,4 E,4|
"Bm"B,4 B,4 A,8|G,8 E,8|F,8 D,8|E,8 C,8|
B,4 B,4 A,4 G,4|A,4 B,4 C,8|D,4 D,4 E,4 F,4|G,8 z8:|



X:1
T:Boss Battle Blitz
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Bm
% The composition's primary goal is to energize and engage the gamer during the boss fight
V:1 clef=treble name="Brass" subname="Br."
%%MIDI program 61
|:"Bm"b4f4f2g z42|"A"e4a4a2b z42|"G" z4 g4B4B2A2|"F#m"f4A4A2G z42|
"Bm"b16|"Em"g16|"D" a8 f8|"A"e16|
"A"c4e4e2f z42|"G"b4d4d2e z42|"F#m"a4c'4c'2b z42|"E" z4 g4B4B2A2|
"Bm"b2a2^g2a2b2a2g2f2|"A"^f2e2d2e2f2e2d2c2| z2 "G"B2A2G2A2F2G2E2|"F#m"F2G2F2E2D2F2A2 z2:|
V:2 clef=treble name="Strings" subname="Str."
%%MIDI program 48
|: "Bm" d8 f8 | "A" c8 e8 | "G" B8 d8 | "F#m" A8 c8 |
"Bm" b8 ^a8 | "Em" g8 b8 | "D" a8 g8 | "A" e8 c'8 |
"A" c8 e8 | "G" B8 d8 | "F#m" A8 c'8 | "E" g8 B8 |
"Bm" b8 a8 | "A" ^f8 e8 | "G" d8 B8 | "F#m" A8 F8 :|
V:3 clef=bass name="Synth Bass" subname="Syn.B."
%%MIDI program 38
|: "Bm" B,4 F4 F2G2 z4 | "A" E,4 A,4 A2B2 z4 | "G" G,4 C4 C2B,2 z4 | "F#m" F,4 A,4 A2G,2 z4 |
"Bm" B,16 | "Em" G,16 | "D" D,8 A,8 | "A" E,16 |
"A" C,4 F,4 F2G2 z4 | "G" B,4 D4 D2E2 z4 | "F#m" A,4 C4 C2B,2 z4 | "E" G,4 B,4 B2A,2 z4 |
"Bm" B,2A,2^G,2A,2B,2A,2G,2F,2 | "A" ^F,2E,2D2E2F2E2D2C2 | "G" B,2A,2G2A,2F2G2E2 z2 | "F#m" F2G2F2E2D2F2A,2 z2 :|



X:1
T:Eastern Euphoria
C:OrchestrAI
M:7/8
L:1/8
Q:1/4=140
K:Amin
V:1 clef=treble
%% MIDI program 71
|: "Am"c2 A B A G E  | "G"E D E G A3  | "Am"A4 E2 D | C D E D C A G |
"Em"B G B d c3  | "Am"c B A G E2 C | "Dm"D3 A, D F E | "Am"E4 A,2 C :|
V:2 clef=treble
%% MIDI program 71
|: "Am"e4 c2 A | "G"d3 B G F2 | "Am"A c B A G E2 | D2 E4 F |
"Em"G4 B,2 D | "Am"E2 G F E D C | "Dm"A,2 D F E3 | "Am"C2 A,4 B, :|



X:1
T:Race for the Crown
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=120
K:Gmaj
V:1 clef=treble name="Lead Melody" subname="Piccolo"
%%MIDI program 72
|:"G" dBG B3 | "A" cAF A2F | "Bm" BAB dcB | "D7" AFD DFA |
"G" GFG "C" ECE | "D" DEF GAG | "G" BdB "A7" cAc | "D" D3-D2z |
"G" dBG B2d | "C" efg d2B | "G" GAB "D" DFD | "A7" cde d3 |
"Bm" Bcd dcB | "G" BAG A2F | "Em" GFG AFD | "D" G3-G2z :|
V:2 clef=treble name="Harmony" subname="Flute"
%%MIDI program 73
|:"G" B2B G2B | "A" A2c E2A | "Bm" F2F D2F | "D7" E2A C2E |
"G" G2G "C" c2c | "D" D2D F2F | "G" B2d "A7" c2e | "D" F3-F2z |
"G" B2B G2d | "C" e2g e2c | "G" G2B "D" D2E | "A7" A2c A3 |
"Bm" F2F D2C | "G" G2D B2A | "Em" G2G F2D | "D" G3-G2z :|
V:3 clef=bass name="Bass Line" subname="Cello"
%%MIDI program 42
|:"G" G,DG, B,2D | "A" A,CE A,2C | "Bm" B,DB, F2B | "D7" D,FA, D2F |
"G" G,G, "C" E,C E,F | "D" D,D FDF z | "G" BG "A7" cA z2 | "D" D3-D2z |
"G" B,2G G,2D | "C" G,2E E,2G | "G" D2G, "D" F2A, | "A7" A,2E E3 |
"Bm" B,DB, D2F | "G" G,B, B,2G z | "Em" G,G A,FD z | "D" G,3-G,2z :|



X:1
T:Ruins of the Mechanized Golem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=144
K:E Phrygian
% The initial introduction sets a menacing tone for the golem's awakening
V:1 clef=treble name="Brass"
%%MIDI program 61
|:"Em" E4 G4 | F2E2 D2C2 | "C" G,4 E4 | "D" A2F2 G4 |
"Em" E4 G4 | F2E2 D2C2 | "C" E,2C2 D2E2 |1 "B"^G4 z4 :|2 "B"^G4 A4 |
% Dramatic golem theme with mechanized elements
K:Emin
|:"Em" B4 G4 | F2E2 ^D2E2 | "C" G4 E4 | "D" A4 F4 |
"Em" E4 ^G4 | "Bm" B,2A2 G2F2 | "Am" E4 C4 |1 "B"^D4 z4 :|2 "B"^D4 E4 |
V:2 clef=treble name="Strings"
%%MIDI program 48
|:"Em" e2f2 g2a2 | b2a2 g2f2 | "C" e2c2 c2e2 | "D" f2d2 d2f2 |
"Em" e2f2 g2a2 | b2a2 g2f2 | "C" e2c2 e2f2 |1 "B"g4 z4 :|2 "B"g4 a4 |
K:Emin
|:"Em" b4 e4 | g2f2 e2^d2 | "C" e2G2 c2e2 | "D" f2A2 d2f2 |
"Em" e2^g2 b2e2 | "Bm" B2A2 G2F2 | "Am" c2E2 A2c2 |1 "B"^d4 z4 :|2 "B"^d4 e4 |
V:3 clef=bass name="Synth Bass"
%%MIDI program 38
|:"Em" E,4 E,4 | F,4 F,4 | "C" C,4 C,4 | "D" D,4 D,4 |
"Em" E,4 E,4 | F,4 F,4 | "C" E,2 C,2 D,2 E,2 |1 "B"^G,4 z4 :|2 "B"^G,4 A,4 |
K:Emin
|:"Em" B,4 E,4 | F,4 F,4 | "C" G,4 C,4 | "D" A,4 D,4 |
"Em" E,4 ^G,4 | "Bm" B,,4 F,4 | "Am" E,4 C,4 |1 "B"^D,4 z4 :|2 "B"^D,4 E,4 |



X:1
T:Pop Snobbery
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:C
V:1 clef=treble name="Synth"
%%MIDI program 81
|: "C"C2 E2 G2 c2 | "F"F2 A2 c2 e2 | "G"G2 B2 d2 G2 | "C"C4 z4 |
"C"E2 G2 c2 E2 | "F"A2 c2 e2 A2 | "G"B2 d2 G2 B2 | "C"C4 z4 :|
V:2 clef=treble name="Electric Guitar"
%%MIDI program 30
|: "C"C2^G2 A2G2 | "F"F2c2 d2c2 | "G"G2D2 E2D2 | "C"C4 z4 |
"C"[EG]2[CE]2 [EG]2[CE]2 | "F"[FA]2[AF]2 [FA]2[AF]2 | "G"[GB]2[Bd]2 [GB]2[Bd]2 | "C"[CE]4 z4 :|
V:3 clef=bass name="Bass Guitar"
%%MIDI program 33
|: "C"C,4 C,2 G,2 | "F"F,4 F,2 A,2 | "G"G,4 D,4 | "C"C,4 z4 |
"C"G,,4 C,4 | "F"A,,4 F,4 | "G"B,,4 G,4 | "C"C,4 z4 :|
V:4 clef=perc name="Drums"
%%MIDI program 25
|[M:4/4]"C"z4 z4 | "F"z4 z4 | "G"z4 z4 | "C"z4 z4 |
"C"[K:perc]z4 z4 | "F"z2 c2 z2 c2 | "G"z2 B,2 z2 B,2 | "C"z2 G2 z2 C2 :|



X:1
T:Innovation Overture
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=120
K:Cmaj
% Using a blend of traditional and modern sounds
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"C"E4G4c4e4|"F"d2c2B2A2 G4G4|"Am"e2^d2e2f2 g4a4|"G"B2A2G2F2 E4E4|
"C"c2B2A2G2 F4E4|"F"f4e4d4c4|"Dm"d2c2B2A2 G2F2G2A2|"G7"B4c4d4e4|
"Am"E2C2A2F2 E2C2A2F2|"F"D2d2c2A2 "G"B2A2G2F2|"Em"G2E2C2G2 F2D2C2B,2|"C7"C4E4G4c4|
"C"c8- c4e4|f4e4d4c4|"G"B2A2G2F2 E4G4|"C"c16:|
V:2 clef=treble name="Electric Piano"
%%MIDI program 4
|: "C"E4 E4 G4c4|"F"F4 A4 c4f4|"Am"A4 c4 e4a4|"G"G4 B4 d4g4|
"C"C8- C4E4|"F"F4 A4 c4f4|"Dm"D4 F4 A4d4|"G7"G4 B4 D4G4|
"Am"E4 A4 C4E4|"F"F4 d4 f4a4|"G"G2B2 z4 d4g4|"Em"E4C4 G4E4|
"C7"C4E4 G4C4|"C"e3f g4 c4e4|a4g4 f4e4|"G7"d2c2 z4 B4G4:|
"C"c8 z4c4:|
V:3 clef=bass name="Bass Synth"
%%MIDI program 38
|: "C"C,8 C4"E7"E,4|"F"A,8- A,4"A7"A,4|"Am"C8 C,4E4|"G"B,8 B,4D4|
"C"C,8C4"G7"B,4|"F"A,8 A,4 F,4|"Dm"D,8 D,4A,4|"G7"G,8 D,4G,4|
"Am"A,8 A,4"C7"E,4|"F"F,8 F,4A,4| "G"G,8 G,4B,4|"Em"E,8 E,4G,4|
"C7"C,8 E,4G,4|"C"C,16|"G"G,8B, z44|"C"C,16 :|



X:1
T:Bright Pop Bliss
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:G
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
"D" [DG]4 [DG]4 | "G" [GB]4 [GB]4 | "C" [CE]4 [CE]4 | "G" [GB]4 [DB]4 |
"D" [DG]4 z4 | "G" [GB]4 [GB]4 | "C" [CE]4 [CE]4 | "G" [GB]4 [DB]4 :|
V:2 clef=treble name="Electric Guitar"
%%MIDI program 30
|:"D" d4 B2 A2 | "G" g4 e2 d2 | "C" c4 A2 G2 | "G" B4 G4 |
"D" d4 D2 B,2 | "G" d'4 b2 a2 | "C" g4 e2 c'2 | "G" d'4 B4 :|
V:3 clef=bass name="Electric Bass"
%%MIDI program 33
|:"D" D4 z4 | "G" G,4 z4 | "C" C4 z4 | "G" G,4 z4 |
"D" D4 z4 | "G" G,4 z4 | "C" C4 z4 | "G" G,4 z4 :|
V:4 clef=treble name="Synth Chords"
%%MIDI program 82
"D" [DFA]4 z4 | "G" [GBd]4 z4 | "C" [CEG]4 z4 | "G" [GBd]4 z4 |
"D" [DFA]4 z4 | "G" [GBd]4 z4 | "C" [CEG]4 z4 | "G" [GBd]4 z4 :|



X:1
T:Ancient Citadel
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:Am
V:1 clef=treble name="Melody"
%%MIDI program 24
|:"Am"E4 A3 c|"E" B6 A2| "Am"c4 E3 A|"G"G6 E2|
"F"E4 c3 E|"Dm"d4 "E"E3 F|"Am"E4 A4-| "E"A6 z2|
"C"c4 E3 G|"G"B3 A G3 F|"Am"A4 c4|"E"B6 c2|
"Am"A4 "G"G3 E| "F"F3 E "E"E4|"Am"A4 A2-| "Am"A6 z2:|
V:2 clef=treble name="Harmony"
%%MIDI program 44
|"Am"c2 A2 F2 D2|"E"G2 E2 C2 B,2|"Am"A,2 E2 A2 F2|"G"D2 B,2 G2 E2|
"F"C2 A,2 F2 C2|"Dm"D2 F2 A2 d2|"E"B,2 E2 G2 B2|"Am"A2 "E"c2 E4|
"C"E2 G2 c2 A2|"G"B2 d2 G2 E2|"Am"A2 c3 B A2|"E"B2 c2 G2 E2|
"Am"A2 "G"G2 A2|"F"F2 "E"E2 F2|"Am"A2 A2| "Am"A6:|
V:3 clef=bass
%%MIDI program 42
|:"Am"A,2 E2 A,2 E2|"E"E2 B,2 E2 E2|"Am"A,2 E2 A,2 E2|"G"G,2 D2 G,2 D2|
"F"F,2 C2 F,2 C2|"Dm"D2 A,2 D2 F2|"E"E,2 B,2 E3 F|"Am"E2 A,2 E4|
"C"C2 G,2 E2 C2|"G"G,2 D2 B,2 G2|"Am"A,2 E2 c2 A,2|"E"E2 G2 B,2 E2|
"Am"A,2 A,2 A,2|"G"G,2 B,2 D2|"F"F,2 C2|"Am"A,2 A,4:|




X:1
T:Musical Tapestry
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:D
V:1 clef=treble name="Piano 1"
%%MIDI program 0
|: "D"D2 F2 A2| "Bm"d2 c2 B2| "G"G2 B2 d2| "A"A6|
"D"A2 d2 f2| "F#m"c2 B2 A2| "G"B2 e2 g2| "A"a6:|
"Bm"f2 "A"e2 "G"d2 | "D"c2 d2 "A"e2 | "Bm"f2 "G"g2 "F#m"f2 | "Em"g2 e2 "A"e2 |
"D"d2 "A"B2 "Bm"c2 | "G"B2 "A"A2 "G"G2 | "F#m"F2 "Em"E2 "D"D2 | "A7"c2 "D"d2 "A"a2 |
"G"G2 B2 d2 | "D"A2 d2 F2 | "A7"G2 F2 E2 | "D"D6 |]
V:2 clef=treble name="Piano 2"
%%MIDI program 0
|: "D"D4 D2| "Bm"A4 A2| "G"B4 B2| "A"c6|
"D"F4 F2| "F#m"E4 E2| "G"D4 D2| "A"c6:|
"Bm"B2 "A"A2 "G"G2 | "D"F2 F2 "A"E2 | "Bm"D2 "G"B2 "F#m"A2 | "Em"B2 B2 "A"A2 |
"D"F2 "A"D2 "Bm"E2 | "G"C2 "A"B2 "G"A2 | "F#m"G2 "Em"B2 "D"A2 | "A7"F2 "D"E2 "A"E2 |
"G"G,2 B,2 D2 | "D"F2 F2 A2 | "A7"G2 F2 E2 | "D"D6 |]
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "D"D,6| "Bm"A,6| "G"B,6| "A"C6|
"D"F,6| "F#m"E,6| "G"D,6| "A"C6:|
"Bm"B,2 "A"A,2 "G"G,2 | "D"F,2 F,2 "A"E,2 | "Bm"D,2 "G"B,2 "F#m"A,2 | "Em"B,2 B,2 "A"A,2 |
"D"F,2 "A"D,2 "Bm"E,2 | "G"C,2 "A"B,2 "G"A,2 | "F#m"G,2 "Em"B,2 "D"A,2 | "A7"F,2 "D"E,2 "A"E,2 |
"G"G,,2 B,,2 D,2 | "D"F,2 F,2 A,2 | "A7"G,2 F,2 E,2 | "D"D,6 |]



X:1
T:This Week in Google - Podcast Intro
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=115
K:Cmaj
% Synth melodic voice
V:1 clef=treble
%%MIDI program 81
|"C"C2E2 G2e2|"Am"A2c2 e2a2|"F"f2A2 c2f2|"G"g2B2 d2g2|
"C"e2G2 c2e2|"Am"a2c2 e3g|"F"f2gf "G"e2d2|"C"c4- c4|
% Bass line
V:2 clef=bass
%%MIDI program 39
|"C"C,2C2 C2C2|"Am"A,2A2 A2A2|"F"F,2F2 F2F2|"G"G,2G2 G2G2|
"C"C,2C2 G,2G2|"Am"A,2c2 E2E2|"F"F,2F2 "G"G,2G2|"C"C,2C2 C,4|
% Harmony pads, fuller chords
V:3 clef=treble
%%MIDI program 88
|"C"E4 G4|"Am"A4 c4|"F"A4 c4|"G"B4 d4|
"C"E4 G4|"Am"A4 c4|"F"A4 "G"B4|"C"C4- C4|
% Pulsing effect for the rhythm
V:4 clef=treble
%%MIDI program 93
z8 |z8 |z8 |z8|
"C"c4 z4|"Am"a4 z4|"F"f4 z4|"G"g4 z4|



X:1
T:Pirate Polka
C:OrchestrAI
M:2/4
L:1/8
Q:1/4=120
K:Dmin
V:1 clef=treble name="Fiddle"
%%MIDI program 110
|: "Dm"A2 A2 | dA Bc | "C"B2 AG | "F"A2 F2 | "Dm"A2 A2 | dA Bc | "C"B2 AG |1 "Dm"A4 :|2 "Dm"A2 z2||
|: "Dm"d2 fd | "A7"c2 ec | "Dm"d2 fd | "A7"ec A2 | "Dm"d2 fd | "A7"c2 ec | "Dm"d2 fd |1 "A7"cA A2 :|2 "Dm"d4||
V:2 clef=treble name="Accordion"
%%MIDI program 21
|: "Dm"[A,2A2] [A,2A2] | [D2A2] [^G3] | "C"[G,2E2] [G,2G2] | "F"[A,2F2] [A,2A,2] | "Dm"[A,2A2] [A,2A2] | [D2A2] [^G3] | "C"[G,2E2] [G,2G2] |1 "Dm"[A,4D4] :|2 "Dm"[A,2z2] [D2z2]||
|: "Dm"[D2d2] [f2d2] | "A7"[c2e2] [c2e2] | "Dm"[D2d2] [f2d2] | "A7"[c2e2] [A,2A,2] | "Dm"[D2d2] [f2d2] | "A7"[c2e2] [c2e2] | "Dm"[D2d2] [f2d2] |1 "A7"[c2E2] [A,2A,2] :|2 "Dm"[D4F4]||
V:3 clef=bass middle=C name="Tuba"
%%MIDI program 58
|: "Dm"[D,F,]2 [D,F,]2 z2 | [D,F,]2 [C,E,]2 z2 | "C"[C,G,]2 [C,E,]2 z2 | "F"[F,A,]2 [C,F,]2 z2 | "Dm"[D,F,]2 [D,F,]2 z2 | [D,F,]2 [C,E,]2 z2 | "C"[C,G,]2 [C,E,]2 z2 |1 "Dm"[D,2A,2] z2 :|2 "Dm"[D,2] z2||
|: "Dm"[D,F,]2 [D,A,]2 z2 | "A7"[E,C]2 [E,A,]2 z2 | "Dm"[D,F,]2 [D,A,]2 z2 | "A7"[E,C]2 [C,A,]2 z2 | "Dm"[D,F,]2 [D,A,]2 z2 | "A7"[E,C]2 [E,A,]2 z2 | "Dm"[D,F,]2 [D,A,]2 z2 |1 "A7"[E,C]2 [A,2E,2] z :|2 "Dm"[D,4F,4]||



X:1
T:Brass Pop Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:C
V:1 name="Trumpet" clef=treble
%%MIDI program 56
|:"C"c4 e2g2|"F"f2a2 a4|"G"g4- g2f2|"C"e4 c4|
"C"e2e2 g2e2|"F"f2d2 d4|"G"e2^d2 e4|"C"c6 z2|
"C"c2e2 d2c2|"Am"a4 e4|"F"d2^c2 d2e2|"G"g4- g2f2|
"C"e4 c'2a2|"F"f2a2 g2e2|"C"e2c2 d4|"C"c6 z2:|
V:2 name="Trombone" clef=bass
%%MIDI program 57
|:"C"G,4 C4|"F"F,4 A,4|"G"B,,4 D4|"C"C4 G,4|
"C"C2C2 E2C2|"F"A,4 F,4|"G"B,2A,2 B,4|"C"C6 z2|
"C"e2e2 d2e2|"Am"a4 e'4|"F"f2g2 a2a2|"G"g4 B4|
"C"c4 E2C2|"F"F,2A,2 G2F2|"C"E2G,2 C4|"C"C6 z2:|
V:3 name="Tuba" clef=bass
%%MIDI program 58
|:"C"C,2, C,4, E,,"F"F,2, A,4,|"G"B,,2, z2 D4,|"C"G,,2, z2 C,4,|
"C"E,,2, C,2, C4,|"F"F,2, F,4, A,,"G"G,,2, B,,4,|"C"[^F,,2G,,2] G,,4, C,|
"C"G,,4- G,,4|"Am"A,,,4 A,,4|"F"F,,2 F,2 A,,"G"B,,2 D4|
"C"C,,4 C,,4|"F"F,4 z F,2A,|"C"G,,2 C,2 z2 G,,2,|"C"C8 :|



X:1
T:Twilight Musings
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=72
K:Em
V:1 clef=treble name="Piano"
%%MIDI program 0
|:"Em" B3A G4|"C" E2D2 C4|"D" D3E F4|"B7" B,2A,2 B,4|
"Am" A4 ^G2A2|"Em/B" B2G2 F4|"Em" E4 D2E2|"Am" A4 G2F2|
"G" G2E2 D2B,2|"D/A" A3G F4|"C" E2G2 A2G2|"Em/B" B4 A2^G2|
"Am" A4 c2B2|"B7" B2A2 ^G2E2|"Em" E6 z2:|
V:2 clef=treble name="Violins"
%%MIDI program 40
|:"Em" e4 B3A|"C" g4 e3d|"D" a4 f3g|"B7" b4 f3e|
"Am" a4 c'b3a|"Em/B" g4 e3f|"Em" e6 d2|"Am" c4 B3A|
"G" G4 D3B,|"D/A" d4 A3F|"C" c4 E3D|"Em/B" B4 G2F2|
"Am" c3e a3g|"B7" f4 d3B|"Em" e4 B2A2|"Am" A6 z2:|
V:3 clef=alto name="Violas"
%%MIDI program 41
|:"Em" E4 G2F2|"C" C4 E2G2|"D" F2D2 F2A2|"B7" B,4 D2F2|
"Am" A,4 C2E2|"Em/B" B,4 E2G2|"Em" E4 G2F2|"Am" A,4 C2B,2|
"G" G2B,2 D2E2|"D/A" A4 F2D2|"C" E2G2 C2E2|"Em/B" B,4 G2F2|
"Am" A,4 C2E2|"B7" B,4 D2F2|"Em" E4 G2E2|"Am" A,6 z2:|
V:4 clef=bass name="Cellos"
%%MIDI program 42
|:"Em" E,4 B,2A,2|"C" C4 G,2E,2|"D" F,4 D2A,2|"B7" B,4 F2D2|
"Am" A,4 E2C2|"Em/B" B,4 G2E2|"Em" E,4 B,2A,2|"Am" A,4 G2F2|
"G" G,4 D2B,2|"D/A" A,4 F2D2|"C" C4 E2C2|"Em/B" B,4 G2E2|
"Am" A,4 E2C2|"B7" B,4 F2D2|"Em" E,4 B,2G,2|"Am" A,6 z2:|



X:1
T:Street Vibrations
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Cmaj
% The piece starts with a catchy melodic hook to grab attention
V:1
%%MIDI program 61
|: "C"E2G2 c2e2 | "G"d4 B2d2 | "F"c2A2 F2A2 | "G"G6- G2 |
"C"e2c2 A2c2 | "Am"A2e2 c2e2 | "G"d2B2 G2B2 | "F"A4 G2F2 :|
V:2
%%MIDI program 59
|: [C2E2]z [E2G2]
z z2 | [B,2D2] z [B2d2] z z2 | [A,2C2] z [C2A,2] z z2 | [G,2B,2] z2 z2 z2 |
[E2G2]z [C2E2]
z z2 | [A,2E2] z [C2E2] z z2 | [G,2D2] z [D2B,2] z z2 | [F,2A,2] z2 [G,2B,2] z z :|
% Subsequently, the thematic material is expanded for virtuosity
V:1
%%MIDI program 61
|: "C"G4 e4 | "E7"e2^G2 A2B2 | "Am"c4 A2e2 | "Dm"F4 D2F2 |
"Em"E4 G4 | "F"D4 c2A2 | "G7"G2B2 d4 | "C"c8 :|
V:2
%%MIDI program 59
|: [G,2B,2] z [E2G2] z z2 | [E2^G2] z [A2B2] z z2 | [A,2C2] z [E2c2] z z2 | [D2F2] z [D2F2] z z2 |
[E2G2]z4 z2 | [D2F2] z2 [C2A,2] z z | [B,2G2] z [D2d2] z z2 | [C2E2] z2 z4 :|
% Next, using offbeats to create a hype syncopated rhythm
V:1
%%MIDI program 61
|: "C"c2e2 G4 | e2c2 A4 | "G"B2d2 G4 | d2B2 B4 |
"C"e2c2 A4 | e2c2 A4 | "F"f2a2 c4 | "G"b2g2 B4 :|
V:2
%%MIDI program 59
|: [C2E2]z2 z4 | [E2c2] z2 z4 | [B,2D2] z2 z4 | [D2B2] z2 z4 |
[E2G2]z2 z4 | [C2E2] z2 z4 | [F,2A,2] z2 z4 | [G2d2] z2 z4 :|
% The conclusion of the piece features a call and response
V:1
%%MIDI program 61
|: "C"G2c2 E4 | G2c2 E4 | "F"A2F2 C4 | A2F2 C4 |
"E7"G2^G2 A4 | "Am"A2c2 E4 | "Dm"F2A2 D4 | "G7"G2B2 G4 :|
V:2
%%MIDI program 59
|: [G,2C2]z2 z4 | [G,2C2] z2 z4 | [A,2F2] z2 z4 | [A,2F2] z2 z4 |
[G^G]z2 z4 z | [A2c2] z2 z4 | [D2F2] z2 z4 | [G2B2] z2 z4 :|



X:1
T:Elegy for the Fallen
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=50
K:Bmin
V:1 clef=treble name="Violin" subname="Vln."
%%MIDI program 40
|: "Bm"D4 F4 | B,6 B,2 | "Em"E4 G4 | E4 z4 |
"Bm"D6 F2 | "F#7"A4 F4 | "Bm"B,8- | B,4 z4 |
"A"A6 B2 | "G"G4 E4 | "F#7"F4 F4 | F4 z4 |
"Bm"D4 F2D2 | "A"C4 E4 | "Bm"B,8- | B,4 z4 :|
V:2 clef=treble name="Viola" subname="Vla."
%%MIDI program 41
|: "Bm"F4 A4 | d6 d2 | "Em"E4 G4 | E4 z4 |
"Bm"F4 A2F2 | "F#7"c4 A4 | "Bm"B,8- | B,4 z4 |
"A"A4 c4 | "G"G6 B2 | "F#7"F4 F4 | F4 z4 |
"Bm"D6 F2 | "A"C4 E4 | "Bm"B,8- | B,4 z4 :|
V:3 clef=bass name="Cello" subname="Vc."
%%MIDI program 42
|: "Bm"B,4 D4 | B,6 B,2 | "Em"E,4 G,4 | E,4 z4 |
"Bm"B,4 D4 | "F#7"A,4 F,4 | "Bm"B,,8- | B,,4 z4 |
"A"A,6 C2 | "G"G,4 E,4 | "F#7"F,6 F,2 | F,4 z4 |
"Bm"B,4 D4 | "A"A,4 C4 | "Bm"B,,8- | B,,4 z4 :|



X:1
T:Alien Frontiers Overture
C:OrchestrAI
M:5/4
L:1/8
Q:1/4=90
K:Em
V:1 clef=treble name="Synth Lead" subname="Lead"
%%MIDI program 81
|: "Em"B2 E4 G3 A z2 | "C"B4 A2 G4 z2 | "Am"G6 ^F2 G2 A2 | "B" B,6 D4 F2 |
"Em"B2 ^c4 e3 d z2 | "C"B4 A2 G4 z2 | "Am"G6 ^F2 G2 B2 | "B7"_B,4 A4 G2 z2 :|
V:2 clef=treble name="Atmosphere Pad" subname="Atmos"
%%MIDI program 92
|: "Em"E4 z4 E4 | "C"C4 E4 z2 G2 | "Am"A4 z2 A2 ^F2 z2 | "B" B,4 D4 z2 D2 |
"Em"E4 ^c4 E5  | "C"C4 E4 G5  | "Am"A4 z2 A2 ^F2 z2 | "B7"B,4 z2 B,4 z2 :|
V:3 clef=bass name="Pulse Synth" subname="Pulse"
%%MIDI program 88
|: "Em"E,4 G,4 B,4 | "C"C,4 E,4 G,2 z2 | "Am"A,6 ^F,4 G,2 | "B" B,4 D4 B,2 z2 |
"Em"E,4 ^C4 E,5  | "C"C,4 A,4 C5  | "Am"A,6 ^F,4 A,2 | "B7"B,4 _B,2 A,4 z2 :|
V:4 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 87
|: "Em"B,3 E,2 G,2 B,2 z3 | "C"C2 C,4 E,4 z2 | "Am"A,2 A,4 ^F,2 G,2 z2 | "B" D6 D,4 z2 |
"Em"^C2 C,4 E4 z2 | "C"C,2 B,,4 E,4 z2 | "Am"A,2 A,4 ^F,2 B,2 z2 | "B7"_B,2 B,,4 G,4 z2 :|



X:1
T:Boss Battle Blitz
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Em
% A fast paced, thrilling composition for a video game boss battle
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|:"Em" E2F2 G4 B4 | "D" F2A2 B2A2 G2E2 | "B7" F2G2 A4 G4 | "Em" E8 z4 |
|:"C" E4 F2 E2 D2B2 | "Am" A2G2 F2G2 E2C2 | "D" F2A2 B4 A4 | "B7" G8 z4 |
V:2 clef=treble name="French Horn"
%%MIDI program 61
|:"Em" B2A2 G2A2 F2G2 | "D" A2B2 c4 B4 | "B7" B2A2 G4 F4 | "Em" E8 z4 |
|:"C" C4 E2D2 C2A2 | "Am" E2F2 G2E2 A2G2 | "D" F2A2 d4 c4 | "B7" B8 z4 |
V:3 clef=alto name="Viola"
%%MIDI program 41
|:"Em" G,2A,2 B,4 G,2F,2 | "D" A,4 F,E, D,4 D,2 | "B7" F,4 E,2D,2 C,2B,2 | "Em" E,8 z4 |
|:"C" C,2D,2 E,2F,2 G,2E,2 | "Am" A,4 G,2A,2 B,4 | "D" D,4 F,2E,2 D,2A,2 | "B7" B,8 z4 |
V:4 clef=bass name="Tuba"
%%MIDI program 58
|:"Em" E,,2B,,2 E,,4 G,,4 | "D" D,,2A,2 A,,4 F,,4 | "B7" B,,2G,,2 F,,2E,,2 D,,2A,,2 | "Em" E,,8 z4 |
|:"C" C,,4 G,,2 C,,4 E,,2 | "Am" A,,2E,2 F,2E,2 G,2A,2 | "D" D,4 F,2 E,2 D,2A,2 | "B7" B,,8 z4 |



X:1
T:Whims of Adventure
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=146
K:Gmix
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"G"A2 BA G3 A | "F"^F2 G2 A2 d2 | "C"c3 B AG ^F2 | "D"G6 AG |
"G"B2 ^F2 G4- | G3 A B2 d2 | "C"e2 d2 c2 AG | "G"G4- G2 z2 |
"G"B2 AB G3 A | "F"^F2 DE F3 G | "C"A2 G2 ^F2 ED | "D"D2 ^F2 G4 |
"G"A2 ^F2 E2 D2 | "C"c2 B2 A2 G2 | "Am"B2 A2 ^F2 G2 | "G"G4- G2 z2:|
V:2 clef=treble name="Acoustic Guitar"
%%MIDI program 25
|:"G" d2 z2 c3 B | "F" c2 z2 d3 c | "C" B2 z2 ^F2 G2 | "D" A2 G2 F4 |
"G" g2 G2 A2 B2 | c4 d4 | "C" e2 z2 A2 G2 | "G" G2 D2 G4 |
"G" d2 c2 B3 A | "F" A2 G2 ^F3 G | "C" B2 c2 d2 e2 | "D" g2 ^f2 e4 |
"G" d3 e d2 B2 | "C" c3 d e2 c2 | "Am" A2 G2 B3 A | "G"G4- G2 z2:|
V:3 clef=bass name="Electric Bass"
%%MIDI program 33
|:"G" G,2 D2 G,3 A, | "F" ^F,2 C2 F,3 G, | "C" C2 E2 C3 E | "D" D,2 A,2 D4 |
"G" G,4 G,4 | "F" ^F,4 F,4 | "C" C2 E2 C4 | "G" G,2 D2 G4 |
"G" G,2 D2 G,3 A, | "F" ^F,2 C2 F,3 G, | "C" C2 E2 C3 E | "D" D,2 A,2 D4 |
"G" G,3 A, B,2 G,2 | "C" C2 E2 C4- | "Am" C,3 D, E,2 C,2 | "G" G,2 D2 G4:|



X:1
T:Harpsichord Jazz Fusion Fantasy
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:Dmaj
V:1 clef=treble name="Harpsichord"
%%MIDI program 6
|:"D" (3ABA F2E2|"A7" E4 (3EFE|"G" D3F G2A2|"D/F#" F2F2 (3AGF|
"A7" =c2e2 A3B| "D7" (3dAd F2E2|"G" G2B2 d2GA|"D" D6 z2|
"D" d4 (3fga bf|"G" g4 (3aba gf|"D/F#" f3f (3efg ag|"A" =c2e2 A3=c|
"B" =c2B2 =cBBA|"E7" B2G2 A2F2|"A7" Eg (3fgf e2|"D" d6 z2:|
V:2 clef=treble name="Electric Guitar"
%%MIDI program 26
K:Dmix
|"D" z4 DFAd|"A7" z4 ceag|"G" z4 BGDF|"D/F#" z4 AFDF|
"A7" z2a2 z3=c|"D7" z2^f2 z2Ad|"G" z2B2 z2dG|"D" z6 z2|
"D" z2d2 z2f2|"G" z2a2 z2g2|"D/F#" z2A2 z3g|"A" z2c2 =c2e2|
"B" z2B2 B2A2|"E7" z2G2 z2=F2|"A7" z2e2 (3fgf e2|"D" z6 z2|
%%MIDI chordname off
V:3 clef=bass middle=d name="Upright Bass"
%%MIDI program 32
|:"D" D4 D2F2|"A7" A,4 E,2A,2|"G" G,2B,2 D4|"D/F#" F4 D2F2|
"A7" =C4 =C2E2|"D7" D4 A,2D2|"G" G,2D2 G4|"D" D,6 z2|
"D" D,2A,2 D2F2|"G" G,4 D2G2|"D/F#" F2D2 A,2D2|"A" A,4 E2A,2|
"B" =C2B,2 =C2B,2|"E7" B,2G,2 E2C2|"A7" A,2E2 =C2A,2|"D" D,6 z2:|
V:4 clef=bass name="Drums" subname="Brushes"
%%MIDI program 1
|:"D" [K:perc] z8| z8| z8| z8|
z8| z8| z8| z8|
z4 dd/2d/2 z4| z2d2 zz d2| z4 dd/2d/2 z4| z2d2 cc/2c/2 c2|
z2B2 z4| z2G2 z4| z2e2 f2f2| z8:|



X:1
T:Golem's Resurgence
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=145
K:Em
% The composition evokes an ancient atmosphere with a mechanized force
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|:"Em"E2 E4 z2 ||: cB B2 A2 G2 ||: "D"B2 B4 z2 ||: AG A2 F2 E2 |
|:"Em"E2 E4 z2 ||: cB B2 A2 G2 ||: "D"B2 B4 z2 ||: "C"B6 z2 |
|:"Em"E2 "C"G2 "G"B2 d2 ||: "D"c2 A2 F2 D2 ||: "Em"E2 "C"G2 "G"B2 z2 ||: "D"A4 F4 |
|:"Em" G4 e4 ||: "C"c4 B2 G2 ||: "G"D4 "B" F4 | "Em"E8 :|
V:2 clef=treble name="Brass"
%%MIDI program 62
|: "Em"G,2 G,4 z2 ||: "D"F,2 F,4 z2 ||: "Em"G,2 G,4 z2 ||: "D"F,2 A,4 z2 |
|:"Em"G,2 E,4 z2 ||: "C"C4 E4 ||: "G"D4 B,4 ||: "B" F6 z2 |
|:"Em"G,4 E,4 ||: "C"C4 E4 ||: "G"G,4 B,4 ||: "D"A,4 F,4 |
|:"Em"E,4 G,4 ||: "C"C4 E4 ||: "G"G,4 D4 | "Em"E,8 :|
V:3 clef=bass name="Synth Bass"
%%MIDI program 38
|:"Em" E,4 E,4 ||: "D"D,4 D,4 ||: "Em"E,4 E,4 ||: "D"D,4 D,4 |
|:"Em"E,4 E,4 ||: "C"C,4 C,4 ||: "G"G,4 G,4 ||: "B"B,4 B,4 |
|:"Em"E,4 E,4 ||: "C"C,4 C,4 ||: "G"G,4 G,4 ||: "D"D,4 D,4 |
|:"Em"E,4 E,4 ||: "C"C,4 C,4 ||: "G"G,4 D,4 | "Em"E,8 :|
V:4 clef=treble name="Percussion"
%%MIDI program 118
|:"Em"[K:E, perc] z4 z | [K:D, perc] z4 z | [K:Em, perc] z4 z | [K:D, perc] z4 z |
"Em"[K:E, perc] z4 z2  | "C"[K:C, perc] z4 z | "G"[K:G, perc] z4 z2  | "B"[K:B, perc] z4 z |
"Em"[K:E, perc] z4 z | "C"[K:C, perc] z4 z | "G"[K:G, perc] z4 z | "D"[K:D, perc] z4 z |
"Em"[K:E, perc] z4 z | "C"[K:C, perc] z4 z | "G"[K:G, perc] z4 z | "Em"[K:E, perc] z5 :|



X:1
T:Hoops Hustle
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Cmaj
% Inspired by the dynamic energy of a basketball game
V:1 clef=treble name="Electric Guitar" subname="Melody"
%%MIDI program 30
|:"C" e4 e2e2 | g2a2 b2g2 | e2^d2 e2g2 | "G"a4- a2z2 |
"Emin"E4 G2B2 | ^D2E2 "Am"A4 | "F"f6 e2 | "G"g4- g2z2 |
"C"e2c2 d2e2 | "G"g2a2 (ba) g2 z4 | "Emin"e4 ^d2e2 | "Am"a2^g2 a4 |
"F"f2a2 "C"g2e2 | "E7"e2^d2 e4 | "Dm"d4 c2B2 |1 "G"g4- g2z2 :|2 "G"g6 z2 |]
V:2 clef=bass name="Bass" octave=-1
%%MIDI program 33
|:"C"c2G,2 C2E2 | "G"G,2D2 G,2B,2 | "Emin"E,2C2 E,4 | "G"G,4 G,2z2 |
"Emin" E,2G,2 B,2E2 | ^D2E2 "Am"A,4 | "F"F,2A,2 C2F2 | "G"G,4 G,2z2 |
"C"C,2C2 E2C2 | "G"G,2A,2 B,G,2 z | "Emin"E,4 ^D4 | "Am"A,2^G,2 A,4 |
"F"F,2A,2 "C"G,2E2 | "E7"E,2^D2 E,4 | "Dm"D,4 C,2B,,2 |1 "G"G,4 G,2z2 :|2 "G"G,6 z,2 |]



X:1
T:Banjo Breegull's Jig
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=160
K:Gmaj
% The banjo setting the pace with a catchy melody
V:1 clef=treble name="Banjo"
%%MIDI program 105
|:"G"D2B2 B2AG|"D"A2F2 F2ED|"G"G2A2 B2G2|"Em"B2E2 E4|
"D"A2d2 d2f2|"G"B2gd B2AG|"C"E2c2 "D"A2F2|"G"G6 z2:|
% A rhythmic and playful kazoo melody
V:2 clef=treble name="Kazoo"
%%MIDI program 72
|:"G"B2d2 d2g2|"D"f2a2 a2d2|"G"e2g2 d2B2|"Em"e2B2 B2e2|
"D"d2f2 a2d2|"G"g2f2 e2d2|"C"c2e2 "D"d2A2|"G"G6 z2:|
% A simple accompaniment with chords
V:3 clef=treble name="Piano_Rhythm"
%%MIDI program 1
|:"G"G3B d2Bd|"D"A3F F2AF|"G"G2G2 B2d2|"Em"B3E E2BE|
"D"A3d f2df|"G"g3e d2BG|"C"E3c c2Ec|"D"A3F D2AF:|
% Bassline to drive the rhythm forward
V:4 clef=bass name="Bass"
%%MIDI program 33
|:"G"G,4 D3G,|"D"D,4 A,2D,2|"G"G,4 D3G,|"Em"E,4 B,2E,2|
"D"A,4 D3F,|"G"G,4 D3G,|"C"C,4 G,3C,|"D"D,4 A,2D,2:|



X:1
T:Triumphal Ode
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Cmaj
V:1 clef=treble
%%MIDI program 56
|: "C"G4 G2AB | "G7"c4 c2Bc | "C"E4 E2FG | "G7"D4 D2EF |
"C"G4 G2AB | "G7"c4 B2AG | "F"A6 GA |1 "G7"G4 F4 :|2 "G7"G4 z4 |]
V:2 clef=treble
%%MIDI program 56
|"C"e4 e2fg | "G7"b4 b2ag | "C"g4 g2ab | "G7"f4 f2ga |
"C"e4 e2fg | "G7"b4 a2gf | "F"g6 fe | [1"G7"f4 e4 :|2 "G7"f4 z4 |]
V:3 clef=bass
%%MIDI program 42
|"C"C4 C2E2 | "G7"D4 B,2D2 | "C"C4 E2G2 | "G7"D4 F2A2 |
"C"C4 C2E2 | "G7"D4 C2B,2 | "F"A,4 C4 | [1"G7"D4 C4 :|2 "G7"D4 z4 |]
% Reflective middle section:
V:1
|: "Am"A6 Bc | "Dm"d4 d2c2 | "E7"e4 e2d2 | "Am"c2B2 A4 |
"Am"A6 Bc | "Dm"d4 d2c2 | "E7"e4 e2^G2 | "Am"a6 z2 :|
V:2
|"Am"e4 c2de | "Dm"f4 f2ed | "E7"e4 e2dc | "Am"A4 G2E2 |
"Am"e4 c2de | "Dm"f4 f2ed | "E7"^g4 g2e2 | "Am"e4 z4 :|
V:3
|"Am"A,4 E2A,2 | "Dm"D4 A,2D2 | "E7"B,4 E2G2 | "Am"A,4 E2C2 |
"Am"A,4 E2A,2 | "Dm"D4 A,2D2 | "E7"G,4 B,2^F2 | "Am"A,4 z4 :|
% Return to the victorious theme:
V:1
|: "C"G4 G2AB | "G7"c4 c2Bc | "C"E4 E2FG | "G7"D4 D2EF |
"C"G4 G2AB | "G7"c4 B2AG | "F"A6 GA |1 "G7"G4 F4 :|2 "G7"G8 |]
V:2
|"C"e4 e2fg | "G7"b4 b2ag | "C"g4 g2ab | "G7"f4 f2ga |
"C"e4 e2fg | "G7"b4 a2gf | "F"g6 fe | [1"G7"f4 e4 :|2 "G7"f8 |]
V:3
|"C"C4 C2E2 | "G7"D4 B,2D2 | "C"C4 E2G2 | "G7"D4 F2A2 |
"C"C4 C2E2 | "G7"D4 C2B,2 | "F"A,4 C4 | [1"G7"D4 C4 :|2 "G7"D8 |]




X:1
T:Bandstand Groove
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:C
% Arranged with four voices to allow for harmonic richness and interlocking rhythms
V:1 clef=treble
%% MIDI program 1 % Acoustic Grand Piano
|: "C" G4 E4|"F" A4 c4|"C"G3 A G2 F2|"G" E6 D2|
"C"E4 G4|"F" c4 A2 F2|"C" G3 A B2 c2|"G7" D6 G2|
"C"G4 E4|"F" A4 c4|"C"G3 A G2 E2|"G" G4 z4|
"C"E2 G4 G2|"F"c2 A4 F2|"C" G4 E2 D2|"G7" G2 F4 z2:|
V:2 clef=treble
%% MIDI program 56 % Trumpet
|: "C" c2 c4 z2|"F" f4 a4|"C"e4 g2 g2|"G" b2 a4 g2|
"C" c3 d c2 B2|"F" a4 f4|"C" e3 d c2 B2|"G7" d6 g2|
"C"c2 c4 z2|"F" f4 a4|"C"e4 c2 c2|"G" g4 z4|
"C"c2 B4 A2|"F"a2 c4 e2|"C" g4 e2 c2|"G7" a2 g4 z2:|
V:3 clef=bass
%% MIDI program 33 % Electric Bass
|: "C" C2 E2 G,2 E2|"F" F2 A2 c2 A2|"C"C2 E2 G,4|"G" D4 z2 B,2|
"C"C2 E2 G,2 E2|"F" F2 A2 c2 A2|"C" C4 E4 G4|"G7" D6 G,2|
"C"C2 E2 G,2 E2|"F" F2 A2 c2 A2|"C"C2 G2 E4|"G" D4 z4|
"C"C2 G4 E2|"F"F2 A4 c2|"C" C4 G2 E2|"G7" D2 E4 z2:|
V:4 clef=bass
%% MIDI program 42 % Cello
|: "C" C,4 G,4|"F" F,4 A,4|"C"C,3 D E2 G,2|"G" B,4 z2 G,2|
"C"G,4 E4|"F" A,4 F2 A,2|"C" G,4 C4 E4|"G7" D,6 G,2|
"C"C,4 G,4|"F" F,4 A,4|"C"C,3 D E2 C,2|"G" G,4 z4|
"C"G,2 E4 C,2|"F" A,2 F4 A,2|"C" C,4 G,2 E2|"G7" F,2 G,4 z2:|



X:1
T:Pop Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=80
K:Bbm
V:1 clef=treble name="Trumpet"
%%MIDI program 71
|:"Bbm" A6  | "C" (3ded c2 A3) | "Bb" C3e (3cde g) | "D7" D5 (7 ) |
V:2 clef=bass name="Trombone"
%%MIDI program 48
|:"Bbm" F#4 F#2# # | "C" (3ded c2 F#3) | "Bb" Bb4 (7 ) | "D7" (4d8bA) |
V:3 clef=bass name="Tuba"
%%MIDI program 95
|:"Bbm" A4 A | "C" (3ded c2 F#3) | "Bb" Bb4 (7 ) | "D7" D5 (7 ) |



X:1
T:Nocturnal Resonance
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=70
K:Am
V:1 clef=treble
%% MIDI program 4
|:"Am"[e2a2] z2 [e2a2] z2|"E"[B2^G2] z2 [e2b2] z2|"Dm"[d2a2] z2 [d2a2] z2|"Am"[e2a2] z2 [e2a2] z2:|
|"Em"[B2e2] z2 [B2e2] z2|"Am"[c2e2] z z A4|"G"[B2d2] z z G4|"Am"[A,2E2] z2 [A,2C2] E2|
|"Am"[e4a4] [e2a2] z2|"F"[a2c2] z2 f4|"E"[^g2b2] e4|"Am"[e2a2] z2 c4|
|"Am"[e2a2] A2 z2 E2|"Dm"[d2f2] A2 z2 D2|"E"[^G,2B,2] z2 E2|"Am"A8:|
V:2 clef=bass
|:"Am"C2 z4 C2|"E"E,2 z4 E,2|"Dm"D,2 z4 D,2|"Am"A,2 z4 C2:|
|"Em"B,2 z4 B,2|"Am"C2 z2 A,4|"G"G,2 z2 G,4|"Am"A,2 z2 E,4|
|"Am"C4 C2 z2|"F"F,4 C2 z2|"E"E,4 B,2 z2|"Am"A,4 C2 z2|
|"Am"A,2 C4 A,2|"Dm"D,2 F4 D,2|"E"E,2 ^G4 E,2|"Am"A,8:|



X:1
T:This Week in Google Intro
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=130
K:C
V:1
%%MIDI program 81
|: "C"C2E2 G2c2 | "F"A6 G2 | "G"E2D2 C2B,2 | "C"C6 z2 |
"C"e2g2 e2c2 | "Dm"d2f2 d2A2 | "Em"B6 G2 | "F"A3G F2E2 |
"C"E4 G4 | "F"c3B A2G2 | "G"G4- G2F2 | "C"E6 z2 :|
V:2
%%MIDI program 81
|: "C"C,2E,2 C2E,2 | "F"F,6 E,2 | "G"D,2C,2 B,,2A,,2 | "C"C,6 z2 |
"C"E,2G,2 E2G,2 | "Dm"D2F2 D2A,2 | "Em"B,6 G,2 | "F"A,3G, F,2E,2 |
"C"E,2C2 E2C2 | "F"A,2F2 A2F2 | "G"G,2- G,2F,2 z2 | "C"C,6 z2 :|



X:1
T:Numeric Riddle
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=80
K:D Dorian
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"Dm"A2 A "Em"G2 G|"Am"A3 "F"F3|"Bdim"B2 B "C"c2 c|1- c3 A2 G:|2- c3 A3|]
w: 2 _ 3 |
M:3/4
%%MIDI program 73
L:1/4
V:1
"Gm"G3-|G3 "Am"A2 B|"F" F3 "Gm"G3|"Em"E3-|E3 "F"F2 E|"Dm"D3-|D3 "Em"E3|\
"Dm"D3-|D2 F "A"A,3-|"Dm"D3 z2 "A"A/2|]
w: 6 _ _ 2 _ 3 |
V:2 clef=treble name="Strings"
%%MIDI program 48
M:6/8
L:1/8
|:"Dm"d2 f a2 f|"Em"e2 g b2 e|"Am"a2 c' e2 c'|"Bdim"b2 d' f2 b|1- d'3 f2 e:|2- d'3 d'3|]
M:3/4
L:1/4
V:2
"Gm"g3-|g3 "Am"c'2 B|"F" A3 "Gm"G3|"Em"B,3-|B,3 "F"F2 G|"Dm"F3-|F3 "Em"E3|\
"Dm"A3-|A2 c "A"e2-|e3 z2 "A"c'/2|]
V:3 clef=bass name="Cello"
%%MIDI program 42
M:6/8
L:1/8
K:D Dorian
|:"Dm"D3 F3|"Em"E3 G3|"Am"A,3 C3|"Bdim"B,3 E3|1- G,3 F2 E:|2- G,3 D3|]
M:3/4
L:1/4
V:3
"Gm"G,3-|G,3 "Am"A,2 C|"F" F,3 "Gm"D3|"Em"E,3-|E,3 ^F,2 G,|"Dm"D,3-|D,3 "Em"E,3|\
"Dm"D,3-|D,2 A, "A"A,2-|A,3 z2 "A"D'/2|]



X:1
T:Intriguing Impressions
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Gmaj
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "G" B2d2 g2f2 | "Em" e2B2 G4 | "C" c2e2 "D" f2d2 | "G" G6 z2 |
"G" d2^c2 "A7" d2e2 | "D" F4 A4 | "Bm" b2a2 "Em" g2f2 | "A7" e6 z2 :|
V:2 clef=treble name="Violin"
%%MIDI program 40
|: "G" d4 B4 | "Em" G4 B2A2 | "C" E4 "D" F4 | "G" D4 z4 |
"G" B3A "A7" G2F2 | "D" A6 F2 | "Bm" f4 d2 "Em" e2 | "A7" A4 z4 :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "G" G,2 B,2 D4 | "Em" E,2 G,2 B,4 | "C" C2 E2 "D" F2 D2 | "G" G,6 z2 |
"G" D4 G,4 | "A7" E,4 C4 | "D" D2 F2 A,4 | "A7" A,6 z2 :|
V:4 clef=bass name="Bass"
%%MIDI program 32
|: "G"D,4 G,4 | "Em"B,,4 E,4 | "C"C,2 E,2 G,4 | "G"D,4 z4 |
"G"D,2 G,2 B,,4 | "A7"A,2 E,2 C,4 | "D"D,2 A,2 F,4 | "A7"E,4 z4 :|



X:1
T:Epochal Voyage
C:OrchestrAI
M:C
L:1/16
Q:1/4=90
K:Cmaj
V:1 clef=treble name="Flute" subname="Fl."
%%MIDI program 73
|: "^Moderato"C4E4G4c4 | "Am"A4G4F4E4 | "Fmaj7"D2C2D2E2F2G2A2 z2 | "G"G,4B,4D4G4 |
"Dm"B2A2B2G2F4E4 | "Em"G2F2E2D2C4B,2 z2 | "C"C4-D4E4C4 | "G7"B,4D4F4B,4 :|
V:2 clef=treble name="Oboe" subname="Ob."
%%MIDI program 68
|: "^Allegro"C2C2E2G2A2A2G2F2 | "Am"A6G2F4E4 | "Fmaj7"F2E2D2C2F2G2A2G2 | "G"G,4B,2D4C4E2 |
"Dm"D4C4B,4A,4 | "Em"E4F4G4A4B | "C"E4-D4C4-B,4A, | "G7" F4E4D4C4D :|
V:3 clef=bass name="Cello" subname="Cel."
%%MIDI program 42
|: "^Adagio"C,8-C8 | "Am"A,8-A8 | "Fmaj7"F,8-F8 | "G"G,8-G8 |
"Dm"D,8-D8 | "Em"E,8-E8 | "C"C,8-C,8 | "G7"G,4F,4E,8 :|
V:4 clef=bass name="Acoustic Guitar" subname="Gtr."
%%MIDI program 24
|: "^Animato"C2G,2E2C2D2G,2F2B,2 | "Am"A2E2C2A,2B2E2D2G2 | "Fmaj7"F2A,2D2F2G2C2E2G2 | "G"B,2D2G2B,2A2E2C2A,2 |
"Dm"F2A,2D2F2G,2B,4C2 | "Em"F2A,2E2G2F2D2C2B,2 | "C"C4G,4E4C4 | "G7"B,4F,4E2F2G4 :|



X:1
T:Brass Pop Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=126
K:C
% Trumpet
V:1 clef=treble-8
%%MIDI program 56
|: "C"G4- G2AB | "G"c4- c2c2 | "Am"A4- A2A2 |"F" F6 F2|
"C"E4- E2G2 | "G"d4- d2B2 | "Am"c3 B A2G2 | "F"G4- G2z2 :|
V:2 clef=treble
%%MIDI program 57
|: "C"G,4 G,2C2 | "G"D4 G,2B,2 | "Am"C4 E2C2 | "F"A,4 C2A,2 |
"C"G,3 G, B,2G,2 | "G"D3 D G,2D2 | "Am"C3 C E2C2 | "F"A,4- A,2z2 :|
% Tuba
V:3 clef=bass
%%MIDI program 58
|: "C"C,4 C,2G,2 | "G"B,,4 D2B,2 | "Am"A,,4 C2E2 | "F"F,,6 F,2 |
"C"C,4 C,2G,2 | "G"B,,4 D2B,2 | "Am"A,,4 C2E2 | "F"F,,6 F,2 :|



X:1
T:Boss Battle Blitz
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Cmin
% The piece begins with an immediate rush of adrenaline
V:1 clef=treble name="Violins" subname="Vln."
%%MIDI program 40
|: "Cm" g8 e8 | "G7" d8 B8 | "Cm"c16 | e8 g8 a  |
"Ab" e8 c8 | "Bdim7" f8 d8 | "G7" b8 d8 c  |
"Cm" g8 e8 | "G7" d8 B8 | "Cm" c8 g8 a  |
"Ab" e8 c8 | "G7" B8 d8 c  | "Cm" g16 :|
V:2 clef=treble name="Brass" subname="Br."
%%MIDI program 61
|: "Cm" G,8 E,8 | "G7" D,8 B,,8 | "Cm"C,16 | E,8 G,8 A,  |
"Ab" E,8 C,8 | "Bdim7" F,8 D,8 | "G7" B,8 D,8 C,  |
"Cm" G,8 E,8 | "G7" D,8 B,,8 | "Cm" C,8 G,8 A,  |
"Ab" E,8 C,8 | "G7" B,8 D,8 C,  | "Cm" G,16 :|
V:3 clef=bass name="Timpani" subname="Tmp."
%%MIDI program 47
|: "Cm" C,8 z8 | z8 G,8 | "G7" z8 z8 | z8 z4 C4 |
"Cm" z8 z8 | z8 z8 | "G7" z8 z8 | z4 z G,4 z7 |
"Cm" C,8 z8 | z8 G,8 | "G7" z8 z8 | z8 z4 C4 |
"Cm" z8 z8 | z8 z8 | "G7" z8 z8 | C,8 G,8 C,  :|
V:4 clef=bass name="Bassoons" subname="Bsn."
%%MIDI program 70
|: "Cm" C,4 G,4 C4 E,4 | "G7" D4 A,4 D4 F,4 | "Cm" G,16 |
C4 G,4 A,9  | "Ab" E,4 C4 E,4 C4 | "Bdim7" F,4 D,4 F,4 D,4 |
"G7" B,4 D,4 C,9  | "Cm" G,4 E,4 G,4 E,4 |
"G7" D4 A,4 D4 F,4 | "Cm" G,8 C8 A,  | "Ab" E,8 C8 E,  |
"G7" B,,8 D,8 C,  | "Cm" G,16 :|



X:1
T:Clash of Titans
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Bm
% The bold and rapid notes mimic the energy of a boss battle
V:1 clef=treble name="1st Violins" subname="Vln. 1"
%%MIDI program 40
|:"Bm"B8 c8|"A"d8 A8|"Bm"B8 c8|"F#m"d8 F8|
"Bm"B8 f8|"Em"e8 c8|"F#7"E8 F8|"Bm"B,16|
"E"e8 g8|"G"f8 a8|"D"d8 f8|"A"e8 A8|
"G"B8 d8|"F#7"c8 F8|"Bm"B,16-|B,16:|
V:2 clef=treble name="2nd Violins" subname="Vln. 2"
%%MIDI program 40
|:"Bm"d8 e8|"A"f8 e8|"Bm"d8 e8|"F#m"f8 e8|
"Bm"d8 e8|"Em"e8 c8|"F#7"f8 e8|"Bm"B,16|
"E"G8 e8|"G"e8 g8|"D"f8 d8|"A"e8 c8|
"G"B8 g8|"F#7"e8 A8|"Bm"B,16-|B,16:|
V:3 clef=treble name="Brass" subname="Brass"
%%MIDI program 61
|:"Bm"f8 F8|"A"E8 C8|"Bm"D8 F8|"F#m"e16|
"Bm"f8 f8|"Em"F8 F8|"F#7"F8 E8|"Bm"d16|
"E"G8 G8|"G"F8 E8|"D"D8 A,8|"A"A,8 F8|
"G"d8 d8|"F#7"c8 F8|"Bm"A,8 B,8|B,16:|
V:4 clef=bass name="Bass" subname="Bass"
%%MIDI program 44
|:"Bm"B,16|"A"A,16|"Bm"B,16|"F#m"F,16|
"Bm"B,16|"Em"E,16|"F#7"F,16|"Bm"B,,16|
"E"E,16|"G"G,16|"D"D,16|"A"A,16|
"G"G,16|"F#7"F,16|"Bm"B,,16-|B,,16:|



X:1
T:Brassbound Pop Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=128
K:Cmaj
V:T clef=treble-8          % Trumpet in Bb transposed to concert pitch
%%MIDI program 56
% Verse
|:"C" E2 G2 c2 B2 | "F" A4 z2 F2 | "G" D2 G2 B2 A2 | "C" c6 z2 |
"C" e2 g2 c'2 b2 | "F" a4 z2 f2 | "G" d2 g2 b2 a2 | "C" c8 :|
V:B clef=bass             % Trombone
%%MIDI program 57
|:"C" G,2 C2 E2 G2 | "F" A,4 z2 A,2 | "G" B,2 D2 G2 F2 | "C" G,6 z2 |
"C" G,2 C2 G,2 G,2 | "F" A,4 z2 A,2 | "G" B,2 D2 G,2 F2 | "C" C8 :|
V:U clef=bass             % Tuba
%%MIDI program 58
|:"C" C,4 G,4 | "F" F,4 C,4 | "G" D,4 G,4 | "C" C6 z2 |
"C" C,4 G,4 | "F" F,4 C,4 | "G" D,4 G,4 | "C" C,8 :|
% Chorus
V:T
|:"F" c4 f4 | "G" b4 d'4 | "Am" a4 e4 | "G" g6 f2 |
"F" c4 f4 | "G" b4 d'4 | "C" e4 g4 |1 "G" g6 z2 :|2 "G" g8 |
V:B
|:"F" c2 a2 f2 d2 | "G" b2 g2 d2 B2 | "Am" a2 e2 c2 A2 | "G" g4 f2 g2 |
"F" c2 a2 f2 d2 | "G" b2 g2 d2 B2 | "C" e2 c2 g2 e2 |1 "G" g6 z2 :|2 "G" g8 |
V:U
|:"F" F,4 C4 | "G" D4 B,4 | "Am" A,4 E4 | "G" G,6 F,2 |
"F" F,4 C4 | "G" D4 B,4 | "C" C4 G,4 |1 "G" G,6 z2 :|2 "G" G,8 |



X:1
T:Brass Pop Pinnacle
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:C
% Trumpet carries the melody
V:1 name="Trumpet" clef=treble
%%MIDI program 56
|: "C"c4 BAGA | "F"f3e d4 | "G"e3d cBAG |1 "C"c6 z2 :|2 "C"c6 c2 |]
|: "C"g4- gfed | "F"a4 ggfe | "G"d3c BAGA | "C"c6 c2 |
"Am"e4 ecBA | "F"G4 FGAB | "C"c3B AGFE | "G"D6 CD |
"C"E3F GABc | "Am"A3B c2BA | "F"G3F E3D | "C"C6 z2 :|
% Trombone provides mid-range support
V:2 name="Trombone" clef=bass
%%MIDI program 57
|: "C"E4 D2C2 | "F"A,4 F3A, | "G"B,4 G2A2 |1 "C"C4 z4 :|2 "C"C4 E4 |]
|: "C"G3F EDC2 | "F"A,4 A,B,CD | "G"E2D2 C4 | "C"E4 E4 |
"Am"A3B c2B2 | "F"F2A2 A3G | "C"C4 E3D | "G"G,4 B,4 |
"C"C3D EDC2 | "Am"A,4 A,4 | "F"F4 F3E | "C"C4 z4 :|
% Tuba anchors with bass lines
V:3 name="Tuba" clef=bass
%%MIDI program 58
|: "C"C,4 C,2C,2 | "F"F,4 F,2F,2 | "G"G,4 G,2G,2 |1 "C"C,4 z4 :|2 "C"C,4 C,4 |]
|: "C"C,2C,2 C,2C,2 | "F"F,2F,2 F,2F,2 | "G"G,2G,2 G,2G,2 | "C"C,4 C,4 |
"Am"A,2A,2 A,2A,2 | "F"F,2F,2 F,2F,2 | "C"C,4 C,3C, | "G"G,4 G,4 |
"C"C,4 C,3C, | "Am"A,2A,2 A,4 | "F"F,4 F,3F, | "C"C,4 z4 :|



X:1
T:Funky Stars
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Gmix
V:1 clef=treble
%% MIDI program 81
|:"G"D2E2 G2A2|B4- BAGF|"F"A2G2 F2ED|"C"E6 D2|
"G"DDED G2A2|"F"A4- AGFE|"Dm"F2E2 D2^C2|"G"D8:|
V:2 clef=treble
%% MIDI program 81
|:"G"e2^c2 d3B|"C"e2A2 G3F|"F"d2^c2 B2AG|"C"A6 e2|
"G"edcB GABc|"F"defg a2gf|"Dm"edcB AGFE|"G"G6 G2:|
V:3 clef=bass
%% MIDI program 33
|:"G"G,2 C2 D2 G,2|"C"C,2 A,2 C2 E2|"F"F,2 D2 E2 A,2|"C"C,4 E4|
"G"G,2 C2 D2 G,2|"F"F,2 D2 F2 A,2|"Dm"D,2 A,2 D2 F2|"G"G,8:|



X:1
T:Hold The Bop
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=150
K:C
V:1
%%MIDI program 81
|:"C"c2c2 "G/B"B2A2 | "Am"G4 "F"F4 | "C"E2E2 "G"z2D2 | "Am"C4 "F"z4 |
"C"e2d2 "G/B"c2B2 | "Am"A4 "F"G2F2 | "C"E2E2 "G/D"D2C2 |1 "F"F6 z2 :|2 "F"F4 z4 |
|:"C"e2g2 "Em"a2f2 | "Am"g2e2 "Dm"f3e | "F"d4 "G"e2d2 | "C"C8 |
"C"e2c2 "G/B"d2B2 | "Am"A4 "F"G2F2 | "C"E2G2 "G/D"A2F2 |1 "G7"G6 z2 :|2 "G7"G4 z4 |
|:"C"c2c2 "G/B"B2d2 | "Am"e4 "E7/e"e2^G2 | "F/a"A2f2 "Dm"z2A2 | "G7"B4 z4 |
"C"G2E2 "G/B"G2A2 | "Am"A4 "F"A2c2 | "C"E2D2 "G"z2D2 |1 "F"F6 z2 :|2 "F"F4 z4 |
|"C"c2e2 "G/B"g2a2 | "Am"b2a2 "F"g2f2 | "C"e2d2 "G"B4 | "Am"A6 z2 |
"C"c4 "G/B"B4 | "Am"A6 "F"f2 | "C"e4 "G"d4 | "C"C8 :|
V:2
%%MIDI program 34
|:"C"z4 "G/B"z2A2 | "Am"z4 "F"z2A2 | "C"z2E2 "G"z2D2 | "Am"z4 "F"z4 |
"C"z2G2 "G/B"z2F2 | "Am"z4 "F"z2E2 | "C"z2C2 "G/D"G,2C2 |1 "F"z6 z2 :|2 "F"z4 z4 |
|:"C"z2G2 "Em"z2f2 | "Am"z4 "Dm"z3e | "F"z4 "G"z2d2 | "C"C8 |
"C"z2c2 "G/B"z2B2 | "Am"A4 "F"z2F2 | "C"z2E2 "G/D"z2F2 |1 "G7"z6 z2 :|2 "G7"z4 z4 |
|:"C"z2c2 "G/B"z2d2 | "Am"z4 "E7/e"z2^G2 | "F/a"z2f2 "Dm"z2A2 | "G7"z4 z4 |
"C"z2E2 "G/B"z2A2 | "Am"z4 "F"z2c2 | "C"z2D2 "G"z2D2 |1 "F"z6 z2 :|2 "F"z4 z4 |
|"C"z2e2 "G/B"z2a2 | "Am"z4 "F"z2f2 | "C"z2d2 "G"z4 | "Am"A6 z2 |
"C"z4 "G/B"z4 | "Am"A6 "F"f2 | "C"z4 "G"z4 | "C"C8 :|
V:3 clef=bass
%%MIDI program 33
|:"C"C,4 z4 | "G/B"G,4 z4 | "Am"A,4 E4 | "F"F,8 |
"C"C,4 z4 | "G/B"G,4 z4 | "Am"A,4 E4 |1 "F"F,8 :|2 "F"F,6 z2 |
|:"C"C,4 z4 | "Em"E,4 z4 | "Am"A,4 z4 | "Dm"D,4 F,4 |
"F"F,8 | "G"G,8 | "C"C,8 |1 "G7"G,8 :|2 "G7"G,6 z2 |
|:"C"C,4 z4 | "G/B"G,4 z4 | "Am"A,4 D4 | "E7/e"E,8 |
"F/a"A,4 F,4 | "Dm"D,8 | "G7"G,8 |1 "F"F,8 :|2 "F"F,6 z2 |
|"C"C,4 E,4 | "G/B"G,4 D4 | "Am"A,4 F,4 | "F"F,8 |
"C"C,6 D2 | "G/B"G,8 | "Am"A,8 | "C"C,8 :|



X:1
T:Intricate Invocation
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=140
K:Gmaj
% The piece unfolds with advanced music theory concepts to stimulate the curious ear.
V:1 clef=treble name="Piano_RH"
%%MIDI program 0
|: "G"D2B,D G2B,D G2B,D G2B,D | "C"E2G,E C2E,G, C2E,G, C2E,G, | "D"A2F,A D2F,A D2F,A D2F,A | "G"B,DGB, DGB,D G2B,D G2B,D |
"C"E2G,E C2E,G, C2E,G, C2E,G, | "A7"A,C^EA, C^EAC ^EAC^E A,C^E z | "D"D2F,A D2F,A D2F,A D2F,A | "G"B,DGB, DGB,D G2B,D G2B,D :|
V:2 clef=treble name="Piano_LH"
%%MIDI program 0
|: "G"G,2B,2 D4 G,4 z4 | "C"C2E2 G4 C2E2 z4 | "D"A,2F2 D4 A,2F2 z4 | "G"G,2B,2 D4 G,4 z4 |
"C"C2E2 G4 C2E2 z4 | "A7"A,2C2 E4 A,2C2 z4 | "D" D2F2 A4 D2F2 z4 | "G" G,2B,2 D4 G,4 z4 :|
V:3 clef=treble name="Flute"
%%MIDI program 73
|: "G"dBGB dgBg dbgb d2B2 | "C"ecGE ceGe ecge c2E2 | "D"adFA dfAd adfa a2F2 | "G"bGBd gbdB gbdb g2D2 |
"C" eGce gcEg gceg g2C2 | "A7"aAc=e a=c^Ea c=e^a=c a2A2 | "D"dAfd afda dfAd d2D2 | "G" gBdg bdgB dgbd g2G2 :|
V:4 clef=bass name="Cello"
%%MIDI program 42
|: "G"G,4 D4 G,4 D4 | "C"C4 G,4 C4 G,4 | "D"A,8 D8 | "G"G,8 D8 |
"C"C8 G,8 | "A7"A,8 E8 | "D"D8 A,8 | "G" G,8 D8 :|



X:1
T:Mechanized Golem Rumble
C:OrchestrAI
M:C
L:1/16
Q:1/4=140
K:Em
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|:"Em" B6 ^G2 A2F2G4 | "D" D6 F2 G2E2F4 | "Amin" A,6 C2 B,2A,2B,4 | "Bmin" B,6 ^A,2 G,2F,2G,4 |
"Em" [E3B3] [FB] [GB] [FA] [E2B2] [GB] [FA] [E2B2] z4 | "G" [D3G3] [B,D] [EC] [DB,] [D2G2] [EC] [DB,] [D2G2] z4 | "C" [C3E3] [G,C] [FA] [G,E] [C2E2] [FA] [G,E] [C2E2] z4 | "Em" [E3B3] ^G A F G E2 E4 z3 :|
"G" G,6 E2 F2D2E4 | "B" [B,3D3] F [GB] [FA] [B,2D2] F [GB] [FA] z5 | "Em" E6 B,2 ^A,2G,2A,4 | "A" A,6 E2 ^D2C2D4 |
"Em" B,6 E2 F2D2E4 | "D" D6 A,2 B,2G,2A,4 | "Amin" A,6 C2 B,2A,2B,4 | "Bmin" B,6 ^A,2 G,2F,2G,4 |
"Emin" [E3B3] [FB] [GB] [FA] [E2B2] [GB] [FA] [E2B2] z4 | "G" [D3G3] [B,D] [EC] [DB,] [D2G2] [EC] [DB,] [D2G2] z4 | "C" [C3E3] [G,C] [FA] [G,E] [C2E2] [FA] [G,E] [C2E2] z4 | "Em" [E3B3] ^G A F G E2 E4 z3 :|
V:2 clef=treble name="Choir Aahs"
%%MIDI program 52
|:"Em" [E3B3] z2 [E4B4] z2 z5 | "D" [D3F3] z2 [D4F4] z2 z5 | "Amin" [A,3C3] z2 [A,4C4] z2 z5 | "Bmin" [B,3^A,3] z2 [B,4^A,4] z2 z5 |
"Em" [E3B3] z2 [E4B4] z2 z5 | "G" [D3G3] z2 [D4G4] z2 z5 | "C" [C3E3] z2 [C4E4] z2 z5 | "Em" [E3B3] z2 [E4B4] z2 z5 :|
"G" [G,3E3] z2 [G,4E4] z2 z5 | "B" [B,3D3] z2 [B,4D4] z2 z5 | "Em" [E3B,3] z2 [E4B,4] z2 z5 | "A" [A,3E3] z2 [A,4E4] z2 z5 |
"Em" [E3B3] z2 [E4B4] z2 z5 | "D" [D3F3] z2 [D4F4] z2 z5 | "Amin" [A,3C3] z2 [A,4C4] z2 z5 | "Bmin" [B,3^A,3] z2 [B,4^A,4] z2 z5 |
"Emin" [E3B3] z2 [E4B4] z2 z5 | "G" [D3G3] z2 [D4G4] z2 z5 | "C" [C3E3] z2 [C4E4] z2 z5 | "Em" [E3B3] z2 [E4B4] z2 z5 :|
V:3 clef=bass name="Synth Bass"
%%MIDI program 38
|: "Em"E,2 z2 E,2 z2 B,,2 z2 B,,2 z2 | "D"D,2 z2 D,2 z2 A,,2 z2 A,,2 z2 | "Amin"A,,2 z2 A,,2 z2 E,,2 z2 E,,2 z2 | "Bmin"B,,2 z2 B,,2 z2 F,,2 z2 F,,2 z2 |
"Em"E,2 z2 E,2 z2 B,,2 z2 B,,2 z2 | "G"G,2 z2 G,2 z2 D,,2 z2 D,,2 z2 | "C"C,2 z2 C,2 z2 G,,2 z2 G,,2 z2 | "Em"E,2 z2 E,2 z2 B,,2 z2 B,,2 z2 :|
"G"G,2 z2 G,2 z2 D,,2 z2 D,,2 z2 | "B"B,2 z2 B,2 z2 F,,2 z2 F,,2 z2 | "Em"E,2 z2 E,2 z2 B,,2 z2 B,,2 z2 | "A"A,2 z2 A,2 z2 E,,2 z2 E,,2 z2 |
"Em"E,2 z2 E,2 z2 B,,2 z2 B,,2 z2 | "D"D,2 z2 D,2 z2 A,,2 z2 A,,2 z2 | "Amin"A,,2 z2 A,,2 z2 E,,2 z2 E,,2 z2 | "Bmin"B,,2 z2 B,,2 z2 F,,2 z2 F,,2 z2 |
"Emin"E,2 z2 E,2 z2 B,,2 z2 B,,2 z2 | "G"G,2 z2 G,2 z2 D,,2 z2 D,,2 z2 | "C"C,2 z2 C,2 z2 G,,2 z2 G,,2 z2 | "Em"E,2 z2 E,2 z2 B,,2 z2 B,,2 z2 :|



X:1
T:Enchanted Forest Dance
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=120
K:Emaj
V:1 clef=treble name="Violins I"
%%MIDI program 40
|:"Emaj" B2 G3F | "C#min" E4 z2 | "Amaj7" c2 e3d | "Bmaj" B4 z2 |
"Emaj" B3c B2 | "G#min" G2 F3G | "Amaj7" A4 a2 | "Bmaj" f2 "Emaj"e2B2|
"C#min" c3B A2 | "Amaj7" e2 "G#min" f3e | "F#min" d2 cB2A | "B7" B3c B2 |
"Emaj"B3c B2 | "G#min"G3A B2 | "Amaj7"A4 G2 | "B7"F2 E4 :|
V:2 clef=treble name="Violins II"
%%MIDI program 40
|:"C#min"E2 C3B | "Emaj"B4 z2 | "Amaj7"a2 "Bmaj"f3e | "Emaj"e4 z2 |
"C#min"e2 e3f | "G#min"g2 f3e | "F#min"f2 e3f | "Amaj7"g2 e2c2 |
"Bmaj"d2 f3e | "Emaj"e2 B3c | "G#min"g2 f3e | "Amaj7" a3a g2 |
"Emaj"e4 z2 | "Bmaj"d2 cB2e | "C#min"c2 "Bmaj"B3A | "Emaj"G2 F4 :|
V:3 clef=alto name="Violas"
%%MIDI program 41
||:"Emaj"B,2 G,3A | "C#min"E2 C2E2 | "Amaj7"A,2 C2E2 | "Bmaj"B,2 G,2B,2 |
"Emaj"E2 E2F2 | "G#min"G,2 F2G2 | "Amaj7"A2 A2B2 | "Bmaj"F2 D2F2 |
"C#min"E2 C2E2 | "Amaj7"A2 G2A2 | "F#min"F2 D2F2 | "B7"B,2 F2B,2 |
"Emaj"B,2 E2G2 | "G#min"B,2 A,2B,2 | "Amaj7"A2 G2F2 | "B7"E2 C4 :|
V:4 clef=bass name="Cellos and Basses"
%%MIDI program 42
|:"Emaj"B,,2 E,2G,2 | "C#min"C2 A,2C2 | "Amaj7"E2 C2E2 | "Bmaj"F2 D2F2 |
"Emaj"G,2 E,2G,2 | "G#min"G,2 F,2A,2 | "Amaj7"A,,2 E,2A,2 | "Bmaj"B,,2 F,2B,2 |
"C#min"E,2 C2A,2 | "Amaj7"A,2 F2E2 | "F#min"F,2 D2F,2 | "B7"B,,2 F,2B,2 |
"Emaj"E,2 G,2E,2 | "G#min"G,2 F,2G,2 | "Amaj7"A,,2 E,2G,2 | "B7"A,2 F,4 :|



X:1
T:Shadow Sprint
C:OrchestrAI
M:C
L:1/16
Q:1/4=200
K:Cmin
V:P1 clef=treble
%%MIDI program 0
K:Cmin
|: "Cm"e2c2G2c2e2g2c'2e'2| "G"b2g2d2g2b2d'2g'2d'2 | "Ab"e2c2G2c2e2g2c'2e'2| "B"b2g2d2f2b2d'2f'2d'2 |
"Cm"e2c2G2c2e2g2c'2e'2| "G"b2g2d2g2b2d'2g'2d'2 | "Ab"e2c2G2c2e2g2c'2e'2| "B"f2d2B2d2f2d'2f'2b2 |
"G"d2B2G2d2f2b2d'2f'2| "Cm"g2e2c2g2e2c'2e'2g2 | "Ab"a2f2c2f2a2c'2f'2a2| "Eb"g2e2b2e2g2b2e'2g2 |
"Cm"c2G2E2C2G,2C4z4| "G"g2d2B2G2D2G4z4 | "Ab"a2f2c'2a2f2c4z4| "B"d2B2G2d2B,2D4z4 :|



X:1
T:Electric Hipster Rhapsody
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=140
K:Ddor
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|: "Dm"A2c4A2 F2A4c2 | "C"e2g4G2 E2e4g2 | "Gm"B2d4B2 G2B4d2 | "A7"A2^c4A2 F2A4^C2 |
"Dm"D2f4D2 A2d4f2 | "C"e2c4E2 G2e4^c2 | "Gm"G2B4G2 d2g4b2 | "Am"A2c4A2 E2A4C2 |
"Dm"d4A2d2 f4A2f2 | "C"e4G2e2 g4C2g2 | "Gm"g4B2g2 b4D2b2 | "Am"a4E2a2 c4A2c2 :|
V:2 clef=treble name="Arpeggio Synth" subname="Arpeggio"
%%MIDI program 81
|: "Dm"A,4-A4 A8 | "C"G,4-G4 G8 | "Gm"B,4-B4 B8 | "A7"A,4-^c4 ^c8 |
"Dm"D,4-D4 D8 | "C"C4-E4 e8 | "Gm"G,4-B4 B8 | "Am"A,4-c4 c8 |
"Dm"d2A2d2f2 A2d2f2 z2 | "C"c2E2g2E2 G2c2e2 z2 | "Gm"B2d2g2B2 D2g2b2 z2 | "Am"e2a2c2E2 A2e2a2 z2 :|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|: "Dm"[D,F,A,]4 z4 [D,F,A,]4 z7 | "C"[E,G,C]4 z4 [E,G,C]4 z7 | "Gm"[G,B,,D]4 z4 [G,B,,D]4 z7 | "A7"[A,C^E]4 z4 [A,C^E]4 z7 |
"Dm"[D,F,A,]4 z4 [D,F,A,]4 z7 | "C"[E,G,C]4 z4 [E,G,C]4 z7 | "Gm"[G,B,,D]4 z4 [G,B,,D]4 z7 | "Am"[A,,C,E]4 z4 [A,,C,E]4 z7 |
"Dm"[d,f,a,]4 [d,f,a,]4 [d,f,a,]4 z4 | "C"[c,e,g,]4 [c,e,g,]4 [c,e,g,]4 z4 | "Gm"[g,,b,,d]4 [g,,b,,d]4 [g,,b,,d]4 z4 | "Am"[a,,c,e,]4 [a,,c,e,]4 [a,,c,e,]4 z4 :|
V:4 clef=treble name="Chord Stabs" subname="Stabs"
%%MIDI program 81
|: "Dm"[fa]8 z8 z7 | "C"[gc']8 z8 z7 | "Gm"[bd']8 z8 z7 | "A7"[ac'^e']8 z8 z7 |
"Dm"[df]8 z8 | "C"[ce']8 z8 | "Gm"[gb]8 z8 | "Am"[ac']8 z8 |
"Dm"[ad'f']4 [ad'f']4 z8 | "C"[gce']4 [gce']4 z8 | "Gm"[gbd']4 [gbd']4 z8 | "Am"[ace']4 [ace']4 z8 :|



X:1
T:Voyage Through Soundscapes
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:G
V:1 clef=treble name="1st Violin"
%%MIDI program 40
% Pastoral opening theme
|:"G" D2 (3B,2D2 G2|"C" E2 C2 (3E2G2|"D" F2 D2 (3F2A2|"G" G4- G2 z2|
"G" B2 d2 (3g2f2 e2|"C" c2 e2 (3a2g2 f2|"D" d2 f2 (3b2a2 g2|"G" g4- g2 z2:|
% Transitory, passionate middle section
|: "Em" B2 (3e2B2 e2|"A" c2 A2 (3c2e2|"Bm" d2 B2 (3d2f2|"D7" a4- a2 z2|
"Em" e2 (3g2e2 g2|"C" (3e2c2 e2 (3g2e2|"Am" (3a2f2 a2 (3c2a2|"D7" (3d2^f2 d2 (3a2f2|
"Em" e4 (3g2e2 d2|"A" c4 (3e2c2 A2|"Bm" d4 (3f2d2 B2|"D7" a4- a2 z2:|
% Reflective and serene ending
K:F
|:"F" (3A2c2 (3A2c2 A2|"Bb" (3D2F2 (3D2F2 D2|"Gm" (3B2d2 (3B2d2 B2|"C7" (3E2G2 (3E2G2 E2|
"F" c2 (3A2c2 A2|"Bb" F2 (3D2F2 D2|"Gm" d2 (3B2d2 B2|"C7" G2 (3E2G2 E2|
"F"A2 c2 c2 A2|"Bb"D2 F2 F2 D2|"Gm"B2 d2 d2 B2|"C7"E2 G2 G2 E2|
"F"f4 (3c2a2 c'2|"Bb"d4 (3f2d2 f2|"C7"c4 (3e2c2 e2|"F"f4- f2 z2:|
V:2 clef=treble name="2nd Violin"
%%MIDI program 40
|:"G" G2 (3D2G2 B2| "C" c2 (3E2c2 G2|"D" A2 F2 D2 F2|"G" D4- D2 z2|
"G" g2 (3d2g2 b2|"C" c'2 a2 f2 d2|"D" a2 (3f2a2 d'2|"G" d'4- d2 z2:|
|: "Em" e2 (3B2e2 g2|"A" a2 (3c2a2 e2|"Bm" b2 (3d2b2 f2|"D7" f4- f2 z2|
"Em" e2 B2 e2 g2|"C" e2 c2 e2 g2|"Am" a2 f2 a2 c2|"D7" f2 d2 f4|
"Em" e2 g2 e4|"A" a2 e2 c4|"Bm" b2 f2 d4|"D7" f4- f2 z2:|
K:F
|:"F" A2 (3F2A2 c2|"Bb" D2 (3B2,2D2 G2|"Gm" B2 G2 D2 G2|"C7" E2 (3C2E2 G2|
"F" A2 F2 (3A2c2|"Bb" D2 B,,2 (3D2F2|"Gm" B2 D2 G2|"C7" E2 C2 E4|
"F"A2 F2 A2 c2|"Bb"D2 B,,2 D2 F2|"Gm"B2 G2 B2 d2|"C7"E2 C2 E2 G2|
"F"A2 A2 (3F2c2 A2|"Bb"D2 D2 (3B,,2F2 D2|"C7"c2 c2 (3G2E2 c2|"F"a4- a2 z2:|
V:3 clef=alto name="Viola"
%%MIDI program 41
|:"G" B,,2 D2 G,2 B,| "C" c2 G,2 E,2 G,|"D" A,,2 F2 A,2 D2|"G" G,4- G,2 z2|
"G" b,2 g2 d2 g2|"C" c2 e2 G2 c2|"D" d2 f2 A,2 d2|"G" g,4- g,2 z2:|
|: "Em" e2 B,2 g2 B2|"A" c2 A,2 e2 A2|"Bm" b2 F2 d2 F2|"D7" a4- a2 z2|
"Em" e2 (3g2e2 B2|"C" e2 (3c2e2 g2|"Am" a2 (3f2a2 c2|"D7" d2 (3a2d2 f2|
"Em" e2 g2 e4|"A" a2 e2 c4|"Bm" b2 f2 d4|"D7" a4- a2 z2:|
K:F
|:"F" A2 F2 A2 c2|"Bb" D2 B,2 D2 F2|"Gm" B2 G2 B2 d2|"C7" E2 G2 C2 E2|
"F" A2 F2 c2 A2|"Bb" D2 B,2 F2 D2|"Gm" B2 G2 d2 B2|"C7" E2 G2 c2 E2|
"F"c2 A2 A2 F2|"Bb"F2 D2 D2 B,2|"Gm"d2 B2 B2 G2|"C7"G2 E2 E2 C2|
"F"c4 A2 F2 c2|"Bb"F4 D2 B,2 F2|"C7"G4 E2 G2 c2|"F"f4- f2 z2:|
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"G" G,4 D,2 G,2| "C" C4 E,2 C,2|"D" D4 F,2 D,2|"G" G,4- G,2 z2|
"G" G,4 D,2 G,4|"C" C2 E,2 G,4|"D" D2 F,2 A,4|"G" G,4- G,2 z2:|
|: "Em" E4 B,2 e4|"A" A,4 c2 a4|"Bm" B4 f2 b4|"D7" d4- d2 z2|
"Em" E4 (3B,2E4|"C" C4 (3E4 G4|"Am" A,4 (3c2A4|"D7" D2 (3F2D4|
"Em" E4 (3E2B,4|"A" A,4 (3c2A4|"Bm" B,4 (3d2B4|"D7" d4- d2 z2:|
K:F
|:"F" F,4 A,2 F,4|"Bb" B,,,4 D,4|"Gm" G,4 B,4|"C7" G,4 E2 G,2|
"F" F,4 A,2 C4|"Bb" B,,,4 D,4|"Gm"G,4 B,4|"C7"C2 E2 G,4|
"F"F,4 A,4 c4|"Bb"B,,,4 D,4 F4|"Gm"G,4 B,4 d4|"C7"C4 E4 G4|
"F"F,4 A,2 c2 A,4|"Bb"B,,,4 D,2 F2 D,4|"C7"C4 E2 G2 c4|"F"F,4- F,2 z2:|



X:1
T:Winter Hearth Tales
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=80
K:Am
V:1 clef=treble
%%MIDI program 2
|:"Am"E4 A2c2| "Dm"F4 F2E2| "Am"A6 G2| "E7"G4 E3 G|
"Am"A2B2 c2E2| "F"E4- E2D2| "Dm"D4 D4| "E7"E6 z2|
"Am"E4 A2c2| "G"B4 B2A2| "C"C8-| C6 D2|
"F"E4 F2E2| "Am"D4 C2E2| "G"B6 A2| "Am"A8:|
V:2 clef=bass
%%MIDI program 43
|:"Am"A,4 E3 A,| "Dm"F,4 A,3 F,| "Am"A,2 A,C2 z E2|"E7"^G,4 B,3 G,|
"Am"A,2 ^G,2 A,2 C2| "F"F,4 G,2 E2|"Dm"D4 D3 D,| "E7"E,4 E,3 ^G,|
"Am"A,2 E2 E4-| "G"B,4 D3 B,| "C"C4 C3 E|C4 C2 D2|
"F"E,4 F3 D| "Am"A,4 A2 E2| "G"G,4 G3 A,| "Am"A,8:|



X:1
T:Brass Pop Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=126
K:Fmaj
V:1 clef=treble-8
%% MIDI program 56
|: "F" F2 A2 d3 c | "C7" c4 B2 G2 | "Bb" A2 G2 F3 E | "F" F4 z4 |
"F" F2 A2 d3 c | "C7" c4 B2 G2 | "Bb" A2 c2 "C7" B2 A2 |1 "F" F6 z2 :|2 "F" F6 F2 |]
V:2 clef=tenor
%% MIDI program 57
|: "F" c2 c2 c2 c2 | "C7" G3 G A2 c2 | "Bb" D2 F2 E2 D2 | "F" C4 z4 |
"F" c2 A2 d3 c | "C7" B2 A2 G4 | "Bb" A2 F2 "C7" E3 D |1 "F" C6 z2 :|2 "F" C6 C2 |]
V:3 clef=bass
%% MIDI program 58
|: "F" F,4 C4 | "C7" C,3 E, G,2 A,2 | "Bb" B,,4 B,2 F,2 | "F" F,6 z2 |
"F" F,2 F,2 F,4 | "C7" E,3 E, F,4 | "Bb" B,,4 "C7" E,2 F,2 |1 "F" F,6 z2 :|2 "F" F,6 z2 |]



X:1
T:Whimsical Journey
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=120
K:G
V:1 clef=treble name="Flute" subname="Flt."
%%MIDI program 73
|: "G"D2 B3 A | "C"E4 G2 | "D7"F3 E D2 | "G"G6 |
"D7" A4 F2 | "G"G2 F3 E | "C"E2 D3 C | "D7"D6 |
"G"B3 A G2 | "Em"B4 A2 | "Am"c3 B A2 | "Bm"B4 G2 |
"G"G4 F2 | "D"A2 F3 D | "C"E6 | "G"G6 :|
V:2 clef=treble name="Oboe" subname="Ob."
%%MIDI program 68
|: "G"G2 E3 F | "C"G4 E2 | "D7"A3 G F2 | "G"B,6 |
"D7"C4 A,2 | "G"B,2 A,3 G | "C"F2 E3 D | "D7"C6 |
"G"E3 F G2 | "Em"A4 G2 | "Am"B3 A G2 | "Bm"A4 F2 |
"G"D4 E2 | "D"F2 D3 B, | "C"C6 | "G"G6 :|
V:3 clef=treble name="Violin" subname="Vln."
%% MIDI program 40
|: "G"B2 g3 f | "C"e4 c2 | "D7"d3 c B2 | "G"g6 |
"D7"f4 d2 | "G"b2 a3 g | "C"a2 g3 f | "D7"d6 |
"G"b3 a g2 | "Em"e4 d2 | "Am"c3 B A2 | "Bm"d4 B2 |
"G"D2 G,3 B, | "D"A4 F2 | "C"E6 | "G"G6 :|
V:4 clef=bass name="Cello" subname="Vc."
%%MIDI program 42
|: "G"D,4 G,2 | "C"C4 E2 | "D7"F,4 A,2 | "G"G,6 |
"D7"A,4 (D3 E) | "G"F,6 | "C"E,4 C,2 | "D7"D,6 |
"G"G,4 B,2 | "Em"A,4 ^F2 | "Am"G,4 E2 | "Bm"F,6 |
"G"D,6   | "D"A,6 | "C"C,4 E,2 | "G"G,6 :|



X:1
T:Autumn Reflections
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:G
V:1 clef=treble name="Piano RH"
%%MIDI program 0
[gB]>c [dB]>G | e2- e2 d2 | [cE]>G [AF]>D | G6 |
B2 g>e | f>d e>^c | d2 d>B | G4 z2 |
B2- Bc | d>B G>E | F2- F>G | A4 z2 |
E2- EG | A>^G E>C | D2- D>E | G4 z2 |
V:2 clef=treble name="Piano LH"
%%MIDI program 0
D3 E3 | F6 | G3 A B2 | F4 D2 |
G,3 B,3 | C4 D2 | E3 F G2 | D4 z2 |
G,3 B,3 | A,4 D2 | G,3 B,2 z | A,4 z2 |
D3 E3 | F4 G2 | A3 B c2 | D4 z2 |
V:3 clef=bass name="Cello"
%%MIDI program 42
D,6 | F,6 | G,2 G,2 G,2 | D,6 |
G,6 | C6 | F6 | D,6 |
B,,6 | G,6 | D,6 | G,6 |
E,6 | A,6 | D6 | G,6 |



X:1
T:Ocean Raiders Overture
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=160
K:Dm
% The introduction evokes the call to adventure on the high seas
V:1 clef=treble
%%MIDI program 71
|:"Dm" A4 A2fe | "C" d6 c2 | "Bb" B4 B2AG | "A7" A6 z2 |
"Dm" A4 "C"gfed | "Bb" c4 "A7"A2G2 | "Dm"F6 "A7"E2 | "Dm"d8 |
"Dm"f2ef "C"gec2 | "Bb"d2cB "A7"A2G2 | "Dm"F2E2 "C"D2C2 | "Bb"B6 z2 |
"Dm"f4 fefa | "Gm"g4 gbag | "A7"a4 a2gf | "Dm"d8 :|
V:2 clef=treble
%%MIDI program 70
|:"Dm" d4 d2c2 | "C" A4- A2A2 | "Bb" G4 G2F2 | "A7" E4- E2z2 |
"Dm" d4 "C"edcB | "Bb" A4 "A7"G2F2 | "Dm"F6 "A7"E2 | "Dm"d8 |
"Dm"A2A2 "C"GA_B2 | "Bb"F4 "A7"G2F2 | "Dm"D4 "C"C2D2 | "Bb"B6 z2 |
"Dm"A2d2 "Gm"d2B2 | "A7"G2E2 E4 | "Dm"f4 "A7"A2G2 | "Dm"d8 :|
V:3 clef=bass
%%MIDI program 32
|:"Dm" D,4 D,2F,2 | "C" C,6 G,2 | "Bb" B,,4 B,,2D,2 | "A7" A,4- A,2z2 |
"Dm" A,4 "C"D,C,D,E, | "Bb" F,4 "A7"A,2F,2 | "Dm"D,6 "A7"A,2 | "Dm"D,8 |
"Dm"D,2D,2 "C"C,4 | "Bb"B,,4 "A7"C,4 | "Dm"F,2G,2 "C"A,4 | "Bb"B,,6 z2 |
"Dm"D,4 D,2D,2 | "Gm"G,,4 G,2G,2 | "A7"A,,4 A,2E,2 | "Dm"D,8 :|



X:1
T:Clash of Titans
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=180
K:Em
% The introduction sets the stage for an epic confrontation
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"Em" B2B2 G4 | "C" e2e2 c4 | "D" d2d2 A4 | "B" B2A2 G4 |
"Em" B2E2 G2F2 | "C" E2G2 c2B2 | "D" F2A2 d2c2 | "B" B8 |
"Em" B2B2 G4 | "C" e2c2 B2G2 | "D" A2d2 c2B2 | "B" A2G2 F4 |
"Em" e4 B2G2 | "C" g2e2 c2B2 | "D" A2F2 D2C2 | "B" B8 :|
V:2 clef=treble name="Distorted Guitar"
%%MIDI program 29
|: "Em" E4 B,4 | "C" G,4 e4 | "D" F4 A,4 | "B" B,4 G,4 |
"Em" E4 B,4 | "C" G4 e4 | "D" F4 A,4 | "B" B,4 G,4 |
"Em" E4 B,4 | "C" G,4 e4 | "D" F4 A,4 | "B" B,4 G,4 |
"Em" E4 E4 | "C" G4 G4 | "D" F4 F4 | "B" B,4 B,4 :|
V:3 clef=bass name="Bass Synth"
%%MIDI program 38
|: "Em" E,8 | "C" G,8 | "D" D,8 | "B" B,,8 |
"Em" E,8 | "C" G,8 | "D" D,8 | "B" B,,8 |
"Em" E,8 | "C" G,8 | "D" D,8 | "B" B,,8 |
"Em" E,8 | "C" G,8 | "D" D,8 | "B" B,,8 :|



X:1
T:Harbor Festival Frolic
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=140
K:Dmaj
P:A
V:1 clef=treble
%%MIDI program 72
|"D"A3 F3|"G"B3 G3|"D"A2f e2d|"A7"c3 A3|
"D"fed "A"ecA|"G"BdB "A"AFA|"D"F2A "G"G2B|"A7"A3 z3|
P:B
"D"fed cde|"A7"fdf ece|"D"fed "Bm"F3|"Em"G3-A3|
"A7"gfe cde|dcd f2e|"D"d3 "A7"cBA|"D"D3 z3|
V:2 clef=bass
%%MIDI program 33
|"D"A,,3 D,3|"G"G,,3 D,3|"D"A,,3 D,3|"A7"E,3 A,,3|
"D"A,,3 E,3|"A"A,,3 E,3|"D"D,3 "G"G,3|"A7"A,,3 z3|
"D"D,3 A,,3|"A7"E,3 A,,3|"D"D,3 "Bm"D,3|"Em"G,3-A,,3|
"A7"A,3 F,,3|D,3 A,3|"D"A,,3 "A7"E,3|"D"D,3 z3|
P:C
"D"fff "A"e2e|"G"ddd "A"cde|"D"fed "A7"cBA|"D"d3 z3|
"D"A2f "G"B2d|"A"A2e "G"fed|"A7"c3 e3|"D"d3 z3|



X:1
T:Velocity of Night
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=300
K:Gmin
V:1 name="Melodic Voice"
%%MIDI program 0
|"Gm"B4 z8|"Cm"e4 z8|"D"D4 z8|"Gm"G,4 z8|
"Gm"B,4 z8|"Cm"e4 z8|"D"d4 z8|"Gm"G,4 z8|
|"Gm"D4 G4 B4 d4|"Cm"G4 c4 e4 g4|
"D"A4 d4 f4 a4|"Gm"G,4 B,4 d4 g4|
V:2 name="Arpeggiated Voice"
%%MIDI program 0
|:"Gm"g8 b8|"Cm"g8 e'8|"D"f8 a8|"Gm"d8 g8|
"Gm"b,8 d'8|"Cm"g8 e'8|"D"f8 a8|"Gm"d8 g8|
"Gm"b,16 d16 g16|"Cm"g8 c'8 e'8|
"D"f16 a16 d'16|"Gm"g,16 d16 g16:|
V:3 name="Bass Voice" clef=bass
%%MIDI program 0
|"Gm"G,4 z8|"Cm"C,4 z8|"D"D,4 z8|"Gm"G,,4 z8|
"Gm"G,4 z8|"Cm"C,4 z8|"D"D,4 z8|"Gm"G,,4 z8|
|"Gm"G,2 G,2 G,4 z4|"Cm"C,2 C,2 C,4 z4|
"D"D2 D2 D4 z4|"Gm"G,,2 G,,2 G,,4 z4|



X:1
T:The Ancient City's Echo
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=50
K:Gm
V:1 clef=treble
%%MIDI program 48
|:"Gm"D4 B2|"Cm"G4 G2|"Dm"F4 A2|"Gm"G2 A2 B2|"Cm"C4 E2|
"Gm"D4 C2|"A7"A,4 C2|"D7"F4 D2|"Gm"G4 B2|"Gm"A2 B2 c2|
"D" d4 c2|"Cm"E4 C2|"Gm"D4 B2|"F"F6|"Gm"G4 z2:|
V:2 clef=treble
%%MIDI program 49
|:"Gm"B2 c2 d2|"Cm"G4 E2|"Dm"F3 E D2|"Gm"G4 B2|"Cm"E2 C4|
"Gm"D2 B,4|"A7"A,2 C2 E2|"D7"F4 A2|"Gm"G2 B2 d2|"Gm"A2 G2 F2|
"D" d4 F2|"Cm"E2 C4|"Gm"D2 G,4|"F"A2 F4|"Gm"G4 z2:|
V:3 clef=bass
%%MIDI program 42
|:"Gm"G,4 D2|"Cm"C4 G,2|"Dm"D,4 A,2|"Gm"G,2 A,2 B,2|"Cm"C3 B, A,2|
"Gm"G,4 F,2|"A7"A,4 C2|"D7"D4 F,2|"Gm"G,2 D4|"Gm"A,2 B, z G,2|
"D" d2 F2 A,2|"Cm"G,4 E2|"Gm"G,2 D4|"F"F,6|"Gm"G,4 z2:|



X:1
T:Majestic Anthem of the Brave (Rhythmically Enhanced)
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Eb
V:1 clef=treble name="Trumpet 1"
%%MIDI program 56
|:"Eb"B2 (3B3G G2F2 | G2 (3G3F E4 | "Abmaj7"E2 (3E3C (3C2E2 | C2 (3C3E z4|
"Bb"F2>F2 (3D2F2 | "Eb/D"D2D2 (3B,2D2 | "Ebm"G2G>B (3B2G2 | "Bb7"B4 z4|
"Ab/C"E2 (3E3C E2C2 | "Cm"c2>c2 A2c2 | "Bb"F2 (3F3D (3D2B,2 | "Cm"G4 z4|
"Ab"E2E2 E2G2 | "Eb"F2F2 F2A2 | "Bb7"D2>D2 E2C2 | "Eb"B,4 z4:|
V:2 clef=treble name="Trumpet 2"
%%MIDI program 56
|:"Eb"G2>F2 E2G2 | G2>F2 E4 | "Abmaj7"C2 (3C3B, (3C2E2 | C2 (3C3B, z4 |
"Bb"D2 (3D3C D2F2 | "Eb/D"B,,2>G2 G2B,2 | "Ebm"G2 (3G3F (3G2B2 | "Bb7"D4 z4 |
"Ab/C"E2 (3E3C c4 | "Cm"F3F A2c2 | "Bb"D2>D2 (3B,2F2 | "Cm"C4 z4 |
"Ab"A,,2>C2 E3C | "Eb"B,,2>F2 F3A | "Bb7"G>F G>F E2D2 | "Eb"B,,4 z4:|
V:3 clef=treble name="Horn"
%%MIDI program 61
|:"Eb"E2 (3E^G2 B,2E2 | E2>F2 F4 | "Abmaj7"C2 (3C3E A,2C2 | C2 (3C3E z4 |
"Bb7"B,2 (3B3D G,2B,2 | "Eb/D"B,2D2 D4 | "Ebm"G2 (3G3B E2^G2 | "Bb7"D3F B,4 |
"Ab/C" E2G2 c2E2 | "Cm"A2>F2 F4 | "Bb7"D2F2 B,2D2 | "Cm"C4 z4 |
"Ab"E4 G4 | "Bb7"B,2D2 G4 | "Fm"F2E2 D2C2 | "Bb7"B,,4 z4:|
V:4 clef=bass name="Trombone"
%%MIDI program 58
|:"Eb"E4 G,2 (3^G,2 | E2G2 F4 | "Abmaj7"A,2A,2 C3E | A,2>C2 C4 |
"Bb7"D>D (3D3F A2 | "Eb/D"D2>D2 B,2^B,2 | "Ebm"G,2E2 E4 | "Bb7"D4 B,4 |
"Ab/C" E>>E2 G2 A,2C | "Cm"A2>A2 G,2B,2 | "Bb7"D2D2 F4 | "Cm"C4 z4 |
"Ab"A,2A,2 E3E | "Eb"B,,2B,,2 D4 | "Fm"F,,F, (3B,,D, D2 | "Eb"E4 z4:|



X:1
T:Sailing into the Sunset
C:OrchestrAI
M:6/8
L:1/8
Q:1/4=80
K:Am
V:1 clef=treble
%%MIDI program 24
|: "Am"A3 c2e | "G"d3 B2G | "F"E3 c2A | "E"E3 z2E |
"Am"c3 e2a | "C"g3 e2c | "G"B3 d2B |1 "Am"A3 z2A :|2 "Am"A3 z2e  |
|: "F"f3 a2f | "C"e3 c2E | "G"G3 B2d | "Am"c3 A2F |
"E" G3 e2B | "Am"A3 c2E | "F"E3 c2A | "E"E3 z2E :|
| "Am"^G3 e2A | "Dm"d3 f2d | "E"E3 G2B | "Am"A3 -A2e |
"F"F3 c2A | "G"B3 G2E | "Am"A3 F2E | [1 "E"E6 :| [2 "E"E3 z2z ||
V:2 clef=treble
%%MIDI program 70
|: "Am"e2a a2g | "G"b2d d2B | "F"c2f f2e | "E"e2B B2E |
"Am"A2c c2B | "C"G2B B2G | "G"D2G G2F |1 "Am"E3 z2E :|2 "Am"E3 z2c |
|: "F"A2c c2A | "C"G2E E2G | "G"B2d d2B | "Am"A2F F2E |
"E"E2G G2B | "Am"A2c c2A | "F"F2A A2G | "E"E3 z2E :|
| "Am"A2e e2^G | "Dm"A2d d2A | "E"G2B B2d | "Am"A2E E2A |
"F"A2c c2A | "G"G2B B3 | "Am"A2F E2D | [1 "E"E3 z2E :| [2 "E"E3 z3 ||
V:3 clef=bass
%%MIDI program 43
|: "Am"A,3 C2E | "G"G,3 B,2D | "F"F,3 C2A, | "E"E,3 z2E, |
"Am"A,3 E2A, | "C"C3 G,2C | "G"G,3 D2G, |1 "Am"A,3 z2A, :|2 "Am"A,3 z2A,|
|: "F"F,3 A,2C | "C"C3 G,2E, | "G"G,3 B,2D | "Am"A,3 C2F, |
"E"E,3 G,2B, | "Am"A,3 C2E | "F"F,3 A,2C | "E"E,3 z2E, :|
| "Am"A,3 E2A, | "Dm"D,3 F2A, | "E"E,3 B,2E, | "Am"A,3 A,2E, |
"F"F,3 C2F, | "G"G,3 D2G, | "Am"A,3 E2C | [1 "E"E,3 z2E, :| [2 "E"E,6 ||



X:1
T:Swinging Starlight
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:C
V:1 name="Lead" clef=treble
%%MIDI program 65
%%MIDI gchordoff
|:"C"E2 G3 E|"Dm6"D2 A,4|E3 C E2|"G7"G,4 B,2|
"C"E2 E3 D|"A7"A,4 E2|"Dm"D2 F3 D|"G7"G,4 G,2|
"E7"E2 ^G2 AB|"A7"c4 A2|"Dm"F2 A3 F|"G7"B,4 G,2|
"C"E2 G3 E|"Dm6"D2 A,4|E3 C "C/E"E2|"C6"E2 C4:|
V:2 name="PianoRH" clef=treble
%%MIDI program 4
%%MIDI gchordoff
|"C"e2 e3 d|"Dm6"f2 f3 d|e2 c'3 b|"G7"a2 g3 f|
"C"c2 e3 g|"A7"c4 A2|"Dm"F2 A3 d|"G7"G4 B,2|
"E7"e2 ^g3 b|"A7"C2 A,4|"Dm"A2 d3 f|"G7"[B,4G,4]z2|
"C"e2 e3 d|"Dm6"f2 f4|g2 e3 z|"C6"c2 c4:|
V:3 name="PianoLH" clef=bass
%%MIDI program 4
%%MIDI chordoff
|:"C"c4 E2|A,2 A,4|c4 "G7"B,2|D2 D4|
"C"e4 "A7"=G2|A2 A,4|"Dm"d2 z2 A2|D4 G,2|
"E7"=B,2 z2 ^G2|E2 A,4|"Dm"f4 A2|"G7"d2 G4|
"C"e4 ^c2|A2 A,4|e4 "C/E"d2|"C6"c2 C4:|
V:4 name="Bass" clef=bass
%%MIDI program 32
%%MIDI chordoff
|"C"C,2 E,4|"Dm6"A,3 D3|C3 E3|"G7"D,2 B,,4|
"C"C,2 E,4|"A7"A,2 C4|"Dm"D,2 F4|"G7"G,2 D4|
"E7"E,2 ^G,4|"A7"A,3 C3|"Dm"D4 F2|"G7"G,2 D4|
"C"C,2 E,4|"Dm6"A,3 D3|C3 E3|"C6"C,2 C4:|



X:1
T:Manifold Derby Fanfare
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=120
K:Gmaj
V:1 clef=treble name="Trumpet" subname="Trpt."
%%MIDI program 56
|:"G"G2 G2 A2 d4|"D7"d2 c2 B2 A4|"C"c2 E2 G2 c3 B|"G"B2 A2 G4 z2|
"G"G2 B2 d2 d2 c2 B2|"A7"A2 A2 c2 e3 d|"D7"d2 f2 a2 a2 g2 f2|"G"G4-G4 z4|
|:"G"B2 G2 G4 z2|"Em"G2 E2 E4 z2|"A7"A4 c2 e2 f2|"D7"d2 f2 a2 a4|
"G"b2 a2 g2 z2 f2|"C"e2 d2 c4 z2|"G"d2 B2 G2 z2 D2|"G"G4-G4 z2:|
V:2 clef=treble name="Horn" subname="Hrn."
%%MIDI program 61
|:"G"d4 G2 z2 G2|"D7"A4 F2 z2 F2|"C"G2 E2 C2 C3 D|"G" G,2 D2 G4 z4|
"G"d4 B2 z2 d2|"A7"e3 d c3 z2 B|"D7"A4 F2 z2 A2|"G"G,4-G,4 z4|
|:"G"G4 z2 d4|"Em"B,4 z2 E4|"A7"A4 c2 z2 e2|"D7"f3 e z2 d4|
"G"g3 f e3 z2 d|"C"c2 G2 E2 z2 C2|"G"B,2 D4 z2 G2|"G"G,4-G,4 z2:|
V:3 clef=bass name="Bassoon" subname="Bsn."
%%MIDI program 70
|:"G" G,2 G,2 D2 G,4|"D7" A,2 F2 D2 F4|"C" E4 C2 E2 G,4|"G" D2 B,,2 G,4 z4|
"G" G,2 B,2 D4 z2|"A7" A,2 A,2 C3 E,"D7" F2 A,2 A,4 z4|"G"G,4-G,4 z4|
|:"G" D2 B,2 G,4 z2|"Em" G2 E2 E,4 z2|"A7" A,3 B, z2 c4|"D7" F2 A2 z2 d4|
"G" G,2 F2 E2 D4|"C" E4 C2 G,4|"G" D4 B,,2 G,4|"G" G,4-G,4 z4:|



X:1
T:Yearning Heights
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=108
K:Cmaj
V:1 name="Trumpet" clef=treble
%%MIDI program 56
|:"C"e2e2 e2d2|"Am"e2^c2 A4|"F"f2f2 f2e2|"G"g4 z4|
"C"e2e2 g2e2|"Am"a2g2 f2e2|"F"d2^c2 d2e2|"G"g6 z2:|
V:2 name="Trombone" clef=bass
%%MIDI program 57
|:"C"G,2C2 E2C2|"Am"A,2^C2 A,4|"F"F,2A,2 c2A,2|"G"B,4 z4|
"C"G,2E2 G2C2|"Am"A,2F2 E2D2|"F"C2D2 E2F2|"G"G,6 z2:|
V:3 name="Tuba" clef=bass
%%MIDI program 58
|:"C"C,2C,2 C,4|"Am"A,2A,2 A,4|"F"F,2F,2 F,4|"G"G,4 z4|
"C"C,2C,2 E,2C,2|"Am"A,2A,2 A,4|"F"F,2D,2 F,4|"G"G,6 z2:|



X:1
T:Pixel Quest Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Cmaj
V:1 clef=treble name="Lead Synth" sname="Synth"
%%MIDI program 81
|:"C" e2G2 c4|"Am" a2e2 c2B2|"F" f2A2 c4|"G" g2B2 d4|
"C" e2G2 c4|"Am" e2c2 B2A2|"F" d2c2 B2A2|1 "G" e4 G4:|2 "G" e4 z4|
|:"Em" B2G2 G4|"Dm" A2F2 F3G|"Em" B2G2 E4|"Dm" d4 c4|
"Em" e2^d2 e2g2|"Am" a3g f2e2|"F" f2e2 d2c2|1 "G" G4 z4:|2 "G" G4 G4|]
V:2 clef=bass name="Bass Synth" sname="SynthBass"
%%MIDI program 39
|:"C" C4 z2 E2|"Am" A,4 z2 C2|"F" F,4 z2 A,2|"G" G,4 z2 B,2|
"C" C4 z2 E2|"Am" A,4 z2 E2|"F" F,4 z2 A,2|1 "G" G,4 z4:|2 "G" G,4 z2 G,2|
|:"Em" E,4 z4|"Dm" D,4 z4|"Em" E,4 z2 G,2|"Dm" D,4 C4|
"Em" E,4 z2 G,2|"Am" A,4 z2 C2|"F" F,4 z2 A,2|1 "G" G,4 z4:|2 "G" G,4 G,4|]
V:3 clef=treble name="Arpeggio Synth" sname="ArpSynth"
%%MIDI program 82
|:"C" c4 e4|"Am" c4 a4|"F" f4 a4|"G" b4 d'4|
"C" c4 e4|"Am" c4 e4|"F" d4 f4|1 "G" g4 z4:|2 "G" g4 z4|
|:"Em" g4 B4|"Dm" f4 a4|"Em" e4 g4|"Dm" d4 c4|
"Em" e4 g4|"Am" a4 c'4|"F" f4 d'4|1 "G" g4 z4:|2 "G" g4 z4|]
V:4 clef=treble name="Chords Synth" sname="ChordSynth"
%%MIDI program 90
|:"C" [ceg]4 z3 z4|"Am" [ace]4 z3 z4|"F" [fac]4 z3 z4|"G" [gbd']4 z3 z4|
"C" [ceg]4 z4|"Am" [ace]4 z4|"F" [fac]4 z4|1 "G" [gb]4 z4:|2 "G" [gb]4 z4|
|:"Em" [bge]4 z4|"Dm" [dfa]4 z4|"Em" [bge]4 z4|"Dm" [f^df]4 z4|
"Em" [^d^ge]4 z4|"Am" [cea]4 z4|"F" [dfa]4 z4|1 "G" [bg]4 z4:|2 "G" [bg]4 z4|]



X:1
T:Call to Adventure
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=110
K:Em
% The main melody reflects the sense of yearning for the unknown adventures
V:1 clef=treble name="Flute" subname="Fl."
%%MIDI program 73
|:"Em"B2 A2 G2|"D"F2 E2 D2|"G"G3 A B2|"C"E4 z2|
"Am"A3 B c2|"Bm"d2 "Em"e2 "D"d2|"C"E3 D E2|"Em"B4 z2|
"Em"e2 d2 B2|"A"c2 B2 ^G2|"D"A3 G F2|"Em"G4 z2|
"Em"B2 A2 G2|"D"F2 E2 D2|"G"G3 F GA|"Em"E4 z2:|
V:2 clef=treble name="Strings" subname="Str."
%%MIDI program 48
|:"Em"B,4 z2|"D"D4 z2|"C"G,4 z2|"G"B,4 z2|
"Am"A,4 z2|"Em"E4 D2|"D"F2 E2 D2|"C"C4 E2|
"Am"A,4 E2|"Bm"D4 E2|"D"F2 G2 F2|"Em"E4 z2|
"E"B,4 z2|"A"C4 z2|"D"D3 F G2|"Em"E4 z2:|
V:3 clef=bass name="Cello" subname="Cello"
%%MIDI program 42
|:"Em"E,3 ^C, E,2|"D"D,3 A, D,2|"G"G,3 =C "C"E,2|"C"G,3 z z2|
"Am"A,3 E, A,2|"Bm"B,3 F, B,2|"Em"E,3 G, B,2|"C"C,3 z z2|
"Am"A,3 C, E,2|"Bm"B,3 z D2|"D"A,3 F, A,2|"Em"E,3 z z2|
"Em"E,3 ^C, E,2|"D"D,3 A, "C"D2|"G"G,2 A, z "Bm"B,2|"Em"E,3 z z2:|



X:1
T:Whispering Meadows
C:OrchestrAI
M:6/8
L:1/8
Q:1/4=80
K:G
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G" D3 G2A | B2c d2B | "C" c3 e2d | "G" B3 G2A |
| "Em" B3 G2A | "D" F2G A2F | "C" E2F G2A | "G" B3 G3 |
| "G" d3 b2a | "Em" g2f e2d | "C" c3 e2d | "G" B3 G2A |
| "Em" B3 G2A | "D" F2G A2c | "C" B2A G2F | "G" G6 :|
V:2 clef=treble name="Harp"
%%MIDI program 46
|:"G" [B,2D2][B,D] [B,2D2][B,D] | [B,2D2][B,D] [B,2D2][B,D] | "C" [C2E2][CE] [C2E2][CE] | "G" [B,2D2][B,D] [B,2D2][B,D] |
| "Em" [E2G2][EG] [E2G2][EG] | "D" [D2F2][DF] [D2F2][DF] | "C" [C2E2][CE] [C2E2][CE] | "G" [B,2D2][B,D] [B,2D2][B,D] |
| "G" [B,2D2][B,D] [B,2D2][B,D] | "Em" [E2G2][EG] [E2G2][EG] | "C" [C2E2][CE] [C2E2][CE] | "G" [B,2D2][B,D] [B,2D2][B,D] |
| "Em" [E2G2][EG] [E2G2][EG] | "D" [D2F2][DF] [D2F2][DF] | "C" [C2E2][CE] [C2E2][CE] | "G" [G,2B,2][G,B,] [G,2B,2][G,B,] :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"G" G,3 B,2A, | G,3 B,2A, | "C" C3 E2D | "G" G,3 B,2A, |
| "Em" E,3 G,2F, | "D" D,3 F,2E, | "C" C,3 E,2D, | "G" G,3 B,2A, |
| "G" G,3 B,2A, | "Em" E,3 G,2F, | "C" C3 E2D | "G" G,3 B,2A, |
| "Em" E,3 G,2F, | "D" D,3 F,2E, | "C" C,3 E,2D, | "G" G,,6 :|
V:4 clef=bass name="Double Bass"
%%MIDI program 43
|:"G" G,,3 D,2C, | G,,3 D,2C, | "C" C,3 G,,2A,, | "G" G,,3 D,2C, |
| "Em" E,,3 B,,2A,, | "D" D,,3 A,,2G,, | "C" C,,3 G,,2A,, | "G" G,,3 D,2C, |
| "G" G,,3 D,2C, | "Em" E,,3 B,,2A,, | "C" C,3 G,,2A,, | "G" G,,3 D,2C, |
| "Em" E,,3 B,,2A,, | "D" D,,3 A,,2G,, | "C" C,,3 G,,2A,, | "G" G,,,6 :|



X:1
T:Modern Baroque Musings
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=112
K:Dmaj
V:1 clef=treble name="Solo Violin"
%%MIDI program 40
|: "D"A2 | "D" F4 F2E2 D4 E2F2 | "G" G4 F2G2 A2B2 A2G2 | "A" A4 F2A2 ^G2A2 =G2=F2 | "D" D2^C2 D4 D2E2 F2A2 |
"G" B4 B2A2 G2F2 E4 | "A/a" A4 ^G2A2 ^F2A2 G2^F2 | "Bm" A2B2 B2A2 G2F2 E2D2 | "A7" E4 E2=C2 E4 D2^C2 |
"D" D4 d2^c2 d6 e2 | "G" d4 B2A2 B6 G2 | "D"A4 F2E2 D4 E2F2 | "A7" A6 A2 ^G2A2 B4 :|
V:2 clef=treble name="Flute"
%%MIDI program 73
|: "D"A2 | "D" d4 d2^c2 d4 e2f2 | "G" g4 ^f2g2 a2b2 a2=g2 | "A" a4 e2a2 ^g2a2 f2e2 | "D" d2^c2 d4 d2D2 E2F2 |
"G" B4 A2G2 F2G2 ^F2G2 | "A/a" a4 ^g2a2 ^f2g2 a2^g2 | "Bm" b2a2 g2f2 e2d2 ^c2B2 | "A7" A2G2 ^F4 =F4 E2^F2 |
"D" D4 d2^c2 d2e2 f2g2 | "G" d4 B2A2 B2^c2 d2^c2 | "D"D4 F2E2 D4 E2F2 | "A7" A4 A2A2 ^G2A2 B2A2 :|
V:3 clef=treble name="Chamber Strings"
%%MIDI program 48
|: "D"A2 | "D" [FAd]4 [FAc]2[FAd]2 [DFAd]4 [FAc]2[FAd]2 | "G" [GBd]4 [GBd]2[GBd]2 [GBd]4 [GBd]2[GBd]2 | "A" [Ace]4 [Ace]2[Ace]2 [Ace]4 [Ace]2[Ace]2 | "D" [DF#A]4 [DF#A]2[DF#A]2 [DF#A]4 [CEA]4 |
"G" [DGB]4 [CGB]2[DGB]2 [CGB]4 [CGB]2[CGB]2 | "A/a" [Ace]4 [Ace]2[Ace]2 [Ace]4 [Ace]2[Ace]2 | "Bm" [DAB]4 [DAB]2[DAB]2 [CGD]4 [CGD]2[CGD]2 | "A7" [CEG]4 [CEG]2[CEG]2 [CEG]4 [DF#A]4 |
"D" [ADF#]4 [ce]2[Ad]2 [ADF#]4 [F#Ad]4 | "G" [GB]4 [Bd]2[GB]2 [GB]4 [GBd]2[GB]2 | "D" [DF#A]4 [DF#A]2[DF#A]2 [DF#A]4 [DF#A]2[DF#A]2 | "A7" [Ace]4 [Ace]2[Ace]2 [Ace]4 [Ace]2[Ace]2 :|
V:4 clef=bass name="Continuo"
%%MIDI program 42
|: "D"D,2 | "D" [D2A2]4 [D2F#2]4 [D2A2]4 [D2A2]4 z8 | "G" [G2B2]4 [G2D2]4 [G2B2]4 [G2B2]4 z8 | "A" [A,2E2]4 [A,2C#2]4 [A,2E2]4 [A,2E2]4 z8 |
"D" [D2A2]4 [D2A2]4 [D2A2]4 [C2E2]4 | "G" [D2G2]4 [C2G2]4 [C2G2]4 [C2G2]4 | "A/a" [A,2E2]4 [A,2E2]4 [A,2E2]4 [A,2E2]4 |
"Bm" [D2B2]4 [D2B2]4 [D2G2]4 [D2G2]4 | "A7" [C2E2]4 [C2E2]4 [C2E2]4 [D2F#2]4 | "D" [D2F#2]4 [D2A2]4 [D2F#2]4 [F#2A2]4 |
"G" [D2B2]4 [G2B2]4 [D2B2]4 [D2B2]4 | "D" [D2A2]4 [D2F#2]4 [D2A2]4 [D2A2]4 | "A7" [A,2A2]4 [A,2A2]4 [A,2A2]4 [A,2A2]4 :|



X:1
T:Enchanted Forest
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Em
% Imagine a mystical journey through a lush forest
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"Em" G2B2 e3d | "Am" c2e2 A2G2 | "D" F2A2 d3c | "G" B2d2 GABc |
| "C" d2g2 c3B | "Em" e2c4B z | "D" A2F2 D4 | "G" G2B4 z2 |
% Etc...
V:2 clef=treble name="Violin"
%%MIDI program 40
|:"Em" B2e2 g2f2 | "Am" e2a2 c2B2 | "D" A2d2 f2e2 | "G" D2g2 B2A2 |
| "C" e2g2 c2B2 | "Em" e2c4B z | "D" D2F2 A4 | "G" G2B4 z2 |
% Etc...
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"Em" E,2G,2 B,3A, | "Am" C2E2 A,2G, z | "D" F,2A,2 D3C, | "G" B,2D,2 G,A,B, z |
| "C" C,2G,2 C3B, | "Em" E,2C4B, z | "D" A,2F,2 D4 | "G" G,2B,4 z2 |
% Etc...



X:1
T:A Journey Through the Valley
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=120
K:C
% The piece starts with a gentle motif, representing the tranquility of the valley.
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"C" E2 G2 E2 | "Am" A3 G F2  | "F" F2 A2 A2 | "G" B3 d c2  |
| "C" E3 G B2  | "Dm" F3 E D2  | "G" B3 c G2  | "C" E2 C2 (3Bcd E2 |
% The melodies entwine, suggesting the movement and beauty of nature.
V:2 clef=treble name="Violin"
%%MIDI program 40
|:"C" C2 E2 G2 | "Am" A2 F3 E | "F" F2 A2 c2 | "G" d3 B2 A  |
| "C" E2 G2 E2 | "Dm" D2 C2 B2  | "G" B3 z2 d
| "C" E2 c3 B2 G2|
% Harmonizing the beautiful flute melody while introducing rhythmic variations.
V:3 clef=alto name="Viola"
%%MIDI program 41
|:"C" C2 E3 C3 | "Am" A3 A G2 | "F" F3 F D2 | "G" B3 A G2 F2 |
| "C" C2 G2 E2 | "Dm" D3 A C2 A2 | "G" (3Bcd e G3 A2 | "C" E3 C D2 (3GAB|
% Establishing a deeper layer of harmony and counterpoint with an engaging bass line.
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"C" C,,2 E,,3 C,,3 | "Am" A,,3 F,,3 A,,2 | "F" F,,2 A,,2 (3c2a2 z2 | "G" B,,3 A,,3 G,,2 |
| "C" C,,2 E,,2 (3G,EE, z2 | "Dm" D,,3 A,,2 C,,2 D,,2 | "G" (3B,,d,, E,,G G,, | "C" C,,2 C,,2 E,,2 |



X:1
T:Encounter with the Alien Overlord
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Em
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|:"Em"E2F2 G4| "C"G2A2 B4| "Bm"A4 ^G4| "Em"G2F2 E4|
"E5"=G2^F2 GABc| "Am"cBA^G A2B2| "D"^F2E2 D4| "B5"B,2C2 D4:|
|:"Em"E3FG2E2|"C"C3DE2C2|"Bm"B,2B2 B4|"Em"E2^D2 E4|
"Em"E3FG2E2|"C"C3DE2C2|"Bm"B,2B2 B4|"Em"E2^D2 E4:|
V:2 clef=bass name="Synth Bass"
%%MIDI program 87
|:"Em"E,3G,B,2 zC|"C"C3E,G,2 zD|"Bm"B,,3B,D2 zE|"Em"E,,3E,G,B z2,|
"E5"E,,3^F,B,2 zG|"Am"A,,3A,C2 zD|"D"D,,3D,F,2 zG|"B5"B,,2C2 D4:|
|:"Em"E,,3G,B,2 zC|"C"C3E,G,2 zD|"Bm"B,,3B,D2 zE|"Em"E,,3E,G,B z2,|
"Em"E,,3^F,B,2 zG|"C"C3E,G,D z2|"Bm"B,,3B,D2 zE|"Em"E,,3E,G,B, z2:|



X:1
T:Starstruck Jubilee
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=144
K:G
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"G"D4 G4|"D"A4 F4|"Em"B3 A G2E2|"C"C4- C4|
"G"B2d2 G2d2|"D"d4 cBAG|"C"E6 D2|"G"G8:|
|:"G"d2g2 f2e2|"Em"e4 d4|"C"c3B A2G2| "D"d6 B2|
"G"G3 A B2A2|"C"E2F2 G4|"Am"A6 G2|"G"G8:|
V:2 clef=treble name="Electric Piano"
%%MIDI program 5
|:"G" G2 z2 D2 z2|"D" F2 z2 A2 z2|"Em"E2 z2 B2 z2|"C"C4- C4|
"G"B,2 z2 B,2 z2|"D" F2 z2 A2 z2|"C"E4 E2 F2|"G"G4 z4:|
|:"G" z2 g2 z2 f2| "Em"e4 e4| "C"c3B A2G2| "D"d4 B2 z2|
"G"G2 z2 A2 z2| "C"E2 z2 G2 z2| "Am"A4 A2 B2|"G"G4 z4:|
V:3 clef=bass name="Bass"
%%MIDI program 33
|:"G"G,4 D4|"D"A,4 D4|"Em"B,3 A, G,2E,2|"C"C2,4- z C2,|
"G"G,2B,2 D4|"D"F4 E3 D|"C"C,4 C2, D2|"G"G,4- G,4:|
|:"G"d,2d,2 d,2c,2|"Em"e,4 d,4|"C"c,3B, A,4| "D"d,4 d,4|
"G"G,2A,2 B,4|"C"E,2F,2 G,4|"Am"A,6 G,2|"G"G,4- G,4:|



X:1
T:Snowfall Waltz
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=132
K:Bb
V:1 clef=treble name="Celesta" subname="Cel."
%%MIDI program 8
|"Bb" D2 F3 G |"Gm" A4 z2 | "Eb" G2 B,3 D |"F" C4 z2 |
"Bb" D2 F3 B |"F" d4 z2 | "Gm" e2 c3 A |1 "F" F4 z2 :|2 "F" F4 z3/2 A/2 |
|:"Bb" d3 c B2 | "Gm" c3 B A2 | "Eb" B2 G3 F | "F" F4 z2 |
"Bb" d3 c B2 | "Gm" c3 B A2 | "Eb" B2 G3 A |1 "F" F4 z2 :|2 "F" F4 z3/2 G/2 |
V:2 clef=treble name="Glockenspiel" subname="Glock."
%%MIDI program 9
|"Bb" B2 d3 f |"Gm" g4 z2 | "Eb" f2 a3 c |"F" F4 z2 |
"Bb" B2 d3 g |"F" f4 z2 | "Gm" g2 e3 c |1 "F" F4 z2 :|2 "F" F4 z3/2 c/2 |
|:"Bb" f3 e d2 | "Gm" e3 d c2 | "Eb" d2 B3 G | "F" c4 z2 |
"Bb" f3 e d2 | "Gm" e3 d c2 | "Eb" d2 B3 c |1 "F" c4 z2 :|2 "F" c4 z3/2 B/2 |
V:3 clef=bass name="String Ensemble" subname="Str."
%%MIDI program 48
|"Bb" B,4 D2 |"Gm" G,4 B,2 | "Eb" E4 G2 |"F" F,4 A,2 |
"Bb" B,2 D3 F |"F" C4 A,2 | "Gm" G,2 B,2 D |1 "F" F,4 C2 :|2 "F" F,4 C2 |
|:"Bb" D4 B,2 | "Gm" D4 B,2 | "Eb" G4 E2 | "F" C4 F,2 |
"Bb" D4 B,2 | "Gm" D4 B,2 | "Eb" G4 A,2 |1 "F" C4 F,2 :|2 "F" C4 F,2 |



X:1
T:Intricate Inspirations
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=108
K:Em
V:1 clef=treble name="Lead Violin"
%%MIDI program 40
|:"Em"B2e e2f | "D"g3 fed | "C"G2A B2c | "B7"A3 GFE |
"Em"E2G F2E | "Am"A2c B3 | "B7"B2d cBA |1 "Em"E3-E2D :|2 "Em"E3-E2z |]
|: "G" G2B d2B | "D"A3 F3 | "C" e2d cBA | "B7"B3-B2A |
"Em"G2B B2^A | "Am"A2c e3 | "B7"d2c BAG |1 "Em"E3-E2z :|2 "Em"E3-E3 ||
[| "Em"e2f g2a | "G"b2a gfe | "D"d2B A2G | "C"E3 z2B |
"C"c2B A2G | "D"F2E DGF | "Em"E2F G2^A | "B7"B3 B3 |
"Em"e2B B2A | "G"G2E E2D | "D"F2D D2C | "B7"B,3 B,2A, |
"G"B2A G2^F | "Em"E2G FED | "B7"B,2^A B2c | "Em"E3-E3 |]
V:2 clef=bass name="Cello"
%%MIDI program 42
|:"Em"E2A A2B | "D"F3 EDC | "C"C2D E2F | "B7"B,3 A,2G, |
"Em"G,2B, A,2G, | "Am"A,2C B,3 | "B7"B,2E D2C |1 "Em"E,3-E,2A, :|2 "Em"E,3-E,2z |]
|: "G" G,2B, D2B, | "D"A,,3 F,,3 | "C" C,2A,, B,2C | "B7"B,,3-B,,2A,, |
"Em"G,,2B,, B,2A, | "Am"A,,2C E3 | "B7"D2C B,,AG,, |1 "Em"E,,3-E,,2z :|2 "Em"E,,3-E,,3 ||
[| "Em"e,2f g2a | "G"b2a gfe | "D"d2B A2G | "C"E3 z2C |
"C"c2B A2G | "D"F2E D2F | "Em"E2F G2A | "B7"B,3 B,3 |
"Em"e2B B2C | "G"G2E E2F | "D"F2D D2E | "B7"B,,3 B,,2D, |
"G"B2A G2B | "Em"E2G FED | "B7"B,2^A B2c | "Em"E,3-E,3 |]



X:1
T:Morning Light Flourish
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:G
% Piano carries the harmonic foundation and rhythmic drive
V:1 clef=treble name="Piano" subname="Pno."
%%MIDI program 0
|:"G" G2B2 d4 |"D/F#" F2A2 d4 |"Em" E2G2 c4 |"Am7" A2c2 e4 |
"G" d2d2 g4 |"C" c3B A2G2 |"D" F2A2 d3e |"G" G6 z2 :|
% Violin 1 adds a bright, leading melody
V:2 clef=treble name="Violin 1" subname="Vln. 1"
%%MIDI program 40
|:"G" B4 B4 |"D/F#" A4 A4 | "Em" G4 E4 |"Am7" A4 C4 |
"G" B4 D4 |"C" c4 B4 |"D" d4 B4 |"G" G8 :|
% Violin 2 harmonizes with Violin 1
V:3 clef=treble name="Violin 2" subname="Vln. 2"
%%MIDI program 40
|:"G" G4 G4 |"D/F#" F4 F4 | "Em" E4 E4 |"Am7" A4 A4 |
"G" d4 G4 |"C" c4 A4 |"D" A4 F4 |"G" D8 :|
% Viola counters with a warm middle voice
V:4 clef=alto name="Viola" subname="Vla."
%%MIDI program 41
|:"G" D2G2 B4 |"D/F#" D2F2 A4 |"Em" E2B2 c4 |"Am7" C2E2 A4 |
"G" G2B2 d4 |"C" E2A2 c4 |"D" F2A2 D4 |"G" G6 z2 :|
% Cello provides the bass line
V:5 clef=bass name="Cello" subname="Vlc."
%%MIDI program 42
|:"G" G,4 D4 |"D/F#" F,4 D4 |"Em" E,4 B,4 |"Am7" A,4 E4 |
"G" G,4 D4 |"C" C,4 G,4 |"D" D,4 A,4 |"G" G,8 :|



X:1
T:Yee Haw Wiggle - Extended
C:Lorn
M:12/8
L:1/8
Q:3/8=140
K:Bb
V:1 clef=treble
%%MIDI program 56
|: G3 z2 G-G3 G2G | F3 G3 B2G z3 | z2 B B2G B3 G2G | F3 G3 B2G z3 |
G3 z2 G-G3 G2G | F3 G3 B2G z3 | z2 B B2G B3 B2c | ^c3 d3 f2^f z3 |
B3 z2 B-B3 B2B | C3 B3 G2B z3 | z2 G G2F G3 F2G | E3 F3 G2E z3 |
F3 z2 F-F3 F2F | F3 G3 B2G z3 | z2 F F2G A3 F2E | D3 E3 G2E z3 :|
V:2 clef=bass
%%MIDI program 58
|: G,,3 z2 D,,-D,,3 ^F,,3 | G,,3 z2 D,,-D,,3 ^F,,3 | G,,3 z2 D,,-D,,3 ^F,,3 | G,,3 z2 D,,-D,,3 ^F,,3 |
G,,3 z2 D,,-D,,3 ^F,,3 | G,,3 z2 D,,-D,,3 ^F,,3 | D,3 z2 A,,-A,,3 ^C,3 | G,,3 z2 D,,-D,,3 ^F,,3 |
G,,3 z2 G,,-G,,3 G,,3 | C,,3 B,,3 D,2D,, z3 | G,,3 z2 G,,-G,,3 G,,3  | C,,3 B,,3 D,2D,, z3 |
G,,3 z2 G,,-G,,3 G,,3 | G,,3 B,,3 D,2G,, z3 | G,,3 z2 G,,,G,,3 G,,3 | G,,3 B,,3 D,2G,,, z3 :|



X:1
T:Journey's Reflection
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=90
K:Gmin
V:1 clef=treble name="Violin I"
%%MIDI program 40
|:"Gm"D4 G4 A2B2 c5  | "Dm"A4 F4 D4 (3EFG A5 v | "Gm"vG4 (3EFG (3ABc (3BAG (3FGA | "Cm"(3EFG (3ABc "D7"(3dcB "Gm"(3BAG F4 z4 z8 |
"Gm"D4 (3cdc (3BAG (3AFA G4 (3FGA z8 | "Dm"(3EFG (3FED "G7"C4 (3dcB | "Cm"C4 "F7"(3ABc "Bb"F4 (3dcB z8 | "Gm"G8- G4 z4:|
|: "Bb" (3FDF (3GBG (3Ace (3dcB | "Eb"(3GBG (3Ace (3dfg (3bag z4 | "F"(3Ace (3dfg (3gbg (3agf | "Bb"F8- F4 z4 |
"Bb"D8- D4 (3EFG | "Eb"(3GAB (3cBA (3Bcd (3edc z8 | "F"(3dcd (3cBc "C7"(3BAG (3FED | "Bb"F12 F4 :|
|: "Gm"D4 B4 G4 F4 z8 | "D7"A4 F4 G4 E4 z4 | "Gm"B4 d4 (3cBA (3BAG | "Cm"G8- G4 z4 |
"Gm"G4 B4 d4 g4 | "D7"f4 a4 (3bgf (3edc | "Gm"B4 d4 "D"vF4 (3EFG | "Gmaj"G12 G4 :|
V:2 clef=treble name="Violin II"
%%MIDI program 40
|:"Gm"G4 D4 E2F2 G5  | "Dm"D4 B,4 G,4 (3ABA G5  | "Gm"D4 (3ABA (3GAG (3FGF (3EDE | "Cm"(3ABA (3GAG "D7"(3ABc "Gm"(3GFG D4 z4 z8 |
"Gm"D4 (3BAG (3AFA (3GFG D4 (3EDE z8 | "Dm"(3ABA (3GAB "G7"B,4 (3AGF | "Cm"C4 "F7"(3DEF "Bb"B,4 (3AGF z8 | "Gm"G,8- G4 z4:|
|: "Bb" (3EDE (3DGD (3BcB (3AGF | "Eb"(3DGD (3BcB (3EFE (3DGD | "F"(3BcB (3EFE (3DGD F4 z4 | "Bb"D8- D4 z4 z8 |
"Bb"B,8- B,4 (3CDC | "Eb"(3DGD (3cBA (3BA^G (3AGF | "F"(3cBA (3B^GA "C7"G,4 "F"G,4 | "Bb"B,12 B,4 z8 :|
|: "Gm"G,4 D4 B,4 A,4 | "D7"F4 A,4 B,4 G4 | "Gm"G4 B4 (3AGF (3GFE z4 | "Cm"D8- D4 z4 z4 |
"Gm"G,4 B,4 d4 f4 | "D7"A4 c4 (3bag "C"f4 | "Gm"G4 d4 "D"vB,4 (3ABA | "Gmaj"G,12 G,4 :|
V:3 clef=bass name="Viola"
%%MIDI program 41
|:"Gm"G,4 C4 D2E2 F5  | "Dm"A,4 F,4 D,4 (3EFE A,5  | "Gm"B,4 (3EFE (3DED (3CDC (3B,DB | "Cm"(3EFE (3DED "D7"F4 "Gm"vG,4 z4 z4 |
"Gm"B,4 (3GED (3FCF (3EDE B,4 (3DFE z8 | "Dm"(3EFG (3FED "G7"C,4 (3GED | "Cm"C,4 "F7"(3EFG "Bb"A,4 (3ABG z8 | "Gm"G,8- G,4 z4:|
|: "Bb" D4 G4 (3FED C4 z4 | "Eb"G4 (3CDE (3FDG (3BAG | "F"C4 (3EFG (3A2F2 | "Bb"vG,8- G,4 z4 |
"Bb"F,8- F,4 (3EFG z4 | "Eb"(3GDE (3C2B,2 (3A,2G,2 F4 | "F"F,4 (3EDC "C7"A,4 (3EFE z8 | "Bb"G,12 G,4 :|
|: "Gm"D,4 B,,4 G,4 F,4 | "D7"A,4 E4 G,4 C4 | "Gm"G,4 d4 (3cBA (3BAG z8 | "Cm"G,8- G,4 z4 |
"Gm"G,4 B,4 d4 g4 | "D7"D4 F4 (3B^AB C4 z4 | "Gm"D4 d4 "D"vA,4 E4 | "Gmaj"G,12 G,4 :|



X:1
T:Journey's End
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=60
K:Am
V:1 clef=treble
%%MIDI program 1
|:"Am"e2 A3 G|F2 "E7"E4| "Am"A2 c3 B|"G" G4 z2|
"E7"e2 ^d3 e|"A7" c2 "Dm"d4| "Am"e2 A3 G|"E7"E6:|
"E" B2 ^G3 A|"C" A3 G2 E|"Am" e4 c3 E|"Dm" D4 z2|
"C"e2 g3 a|"F" f2 "E7"e4| "Am"a4 g3 e|"E7"e6 z2|
"C"E2 c3 G|"G"G4 "Em"z2|"Am"A2 c3 B|"G"G4 z2|
V:2 clef=treble
%%MIDI program 1
|"Am"a2 e3 d|c2 "E7"B4| "Am"a2 f3 e|"G" d4 z2|
"E7"a2 g3 a|"A7" c2 "Dm"B4| "Am"A2 ^c3 d|"E7"E6:|
"E" d2 ^c3 d|"C" B3 A2 F|"Am" E4 A3 F|"Dm" D4 z2|
"C"c2 e3 f|"F" g2 "E7"c4| "Am"a4 g3 e|"E7"e6 z2|
"C"c2 e3 G|"G"d4 "Em"z2|"Am"c2 e3 d|"G"d4 z2|
V:3 clef=treble
%%MIDI program 1
|"Am"A,2 C3 E|"E7"E,2 "Am"E,4| "Am"A,2 A,3 C|"G"B,4 z2|
"E7"B,2 A,3 B,|"A7"C2 "Dm"A,4| "Am"A,2 C3 E|"E7"E,6:|
"E"G,2 D3 F|"C"E3 G2 E,|"Am"A,4 F3 A,|"Dm"D,4 z2|
"C"E2 c3 E|"F"F2 "E7"E,4| "Am"A,4 ^G3 E|"E7"E,6 z2|
"C"E,2 G,3 C|"G"B,4 "Em"z2|"Am"A,2 C3 B,|"G"B,4 z2|



X:1
T:Majestic Anthem of the Brave (Enhanced)
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Eb
V:1 clef=treble name="Trumpet 1"
%%MIDI program 56
|:"Eb"B4 G4 | "Cm"A6 G2 | "Abmaj7"E4 C2E2 | "Eb/Bb"C6 z2 |
"Bb"D4 F2G2 | "Eb"E2F2 G4 | "Cm"G4 B2G2 | "Bb/D"A4 "Bb7"F4 |
"Ab/C"C2E2 G2A2 | "Cm"c4 "Bb"D2B,2 | "F7"A4 B2A2 | "Cm"G6 G2 |
"Ab"E3E "Fm"G2F2 | "Eb/D"E3D A2B2 | "Bb7"D2C2 G2^F2 | "Eb"B,6 z2:|
V:2 clef=treble name="Trumpet 2"
%%MIDI program 56
|:"Eb"G,4 F4 | "Cm"A6 G2 | "Abmaj7"C2B,2 C2E2 | "Eb/Bb"C4 B,4 |
"Bb7"D4 C2D2 | "Eb"E4 G2B,2 | "Cm"E2F2 G2B2 | "Bb/D"A4 "Bb7"F4 |
"Ab/C"C3C E4 | "Cm"c4 G2A2 | "Bb"D4 B2c2 | "Cm"G6 G2 |
"Ab"A,4 "Eb/G"E3F | "Eb/Bb"D4 E2F2 | "Bb7"B,2A2 G2E2 | "Eb"B,,6 z2:|
V:3 clef=treble name="Horn"
%%MIDI program 61
|:"Eb"E2^G2 B,2E2 | "Cm"A2F2 G3E | "Abmaj7"C3E A,2^C2 | "Eb/Bb"C3E C4 |
"Bb7"B,2A2 G,3B, | "Eb/D"D2E2 E4 | "Cm"G2F2 E2^G2 | "Bb7"D3F B,4 |
"Ab/C"E2G2 c2E2 | "Cm"A2F2 F4 | "Bb7"D2F2 "Eb/Bb"B,2D2 | "Cm"C6 z2 |
"Ab"E4 "Eb"G3A | "Bb7"B,2D2 "Eb"E4 | "Fm"C2^B2 A2G2 | "Bb7"B,,6 z2:|
V:4 clef=bass name="Trombone"
%%MIDI program 58
|:"Eb"E4 G,2E2 | "Cm"A2^G,2 F4 | "Abmaj7"A,4 C3E | "Eb/Bb"A,3C C4 |
"Bb7"D4 F3A | "Eb/D"D2E2 B,2^B,2 | "Ebm"G,2E2 E4 | "Bb7"D2F2 B,4 |
"Ab/C"E4 A,3C | "Cm"A3G G,2B,2 | "Bb7"D4 F2A2 | "Cm"C6 z2 |
"Ab"A,4 "Eb/Eb"B,,4 | "Bb/F"A,4 D2A,2 | "Fm"F,2E2 D,4 | "Eb"E,6 z2:|
V:5 clef=treble name="Strings 1"
%%MIDI program 49
|:"Eb"g4 g2f2 | "Cm"g2f2 e4 | "Abmaj7"c4 c2c2 | "Eb/Bb"B6 z2 |
"Bb7"d4 d2c2 | "Eb"e2e2 e4 | "Cm"g4 g2f2 | "Bb/D"a2a2 "Bb7"f4 |
"Ab/C"c4 c2e2 | "Cm"g2f2 f2b2 | "Bb7"d2c2 d2f2 | "Cm"c6 c2 |
"Ab"e4 c2c2 | "Eb/D"d2B2 B2^G2 | "Bb7"d3c B2c2 | "Eb"b6 b2:|
V:6 clef=bass name="Strings 2"
%%MIDI program 49
|:"Eb"e4 G,2E2 | "Cm"A,2^G,2 F,4 | "Abmaj7"A,4 C3E | A,3C C4 |
"Bb7"D4 F3A | "Eb/D"D2E2 B,2^B,2 | "Ebm"G,2E2 E4 | "Bb7"D2F2 B,4 |
"Ab/C"E4 A,3C | "Cm"A3G G,2B,2 | "Bb7"D4 F2A2 | "Cm"C6 z2 |
"Ab"A,4 "Eb/Bb"B,,4 | "Bb/F"A,4 D2A,2 | "Fm"F,2E2 D,4 | "Eb"E,6 z2:|



X:1
T:This Week in Google Theme
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Am
V:1 clef=treble
%%MIDI program 81
|:"Am"E2A2 c2e2|"G"d2^c2 d2z2|"F"e2A2 c2A2|"E7"G4 -G2z2|
"Am"E2^C2 E2A2|"G"B2A2 G2z2|"F"A2c2 A2f2|"E7"e6 z2:|
V:2 clef=treble
%%MIDI program 81
|:"Am"e4 e2z2|"G"d2B2 G2z2|"F"a2f2 c2z2|"E7"B2^G2 E2z2|
"Am"e2c2 e4|"G"b4 g4|"F"a2f2 c2a2|"E7"e4 e2z2:|
V:3 clef=bass
%%MIDI program 81
|:"Am"A,2E2 A,2E2|"G"G,2D2 G,2D2|"F"F,2C2 F,2C2|"E7"E,3^G,2 z B,2|
"Am"A,2E2 A,2C2|"G"G,2D2 B,2D2|"F"F,2A,2 C2F,2|"E7"E,6 z2:|



X:1
T:War of Shadows
C:OrchestrAI
M:C
L:1/16
Q:1/4=140
K:Cm
% Strings introduce a frenzy with rapid notes and dynamic changes
V:1 clef=treble name="1st Violins"
%%MIDI program 40
|:"Cm" g4e4c4e4 | "G" d4B4G4B4 | "Ab" e4c4Ab4c3 | "Bdim" f4d4B4d4 |
"Cm" g8- g16f z | "G7" g,8- g,16b z | "Ab" e4e4e4e4 | "Bdim" f4f4f4f4 |
"Cm" e4g4c'4e'4 | "G" d4f4b4d'4 | "Ab" c'4c'4c'4c'4 | "G7" b4b4b4b4 |
"Cm" g,16 | "Fm" f16 | "G" d'16 | "Cm" c,16 :|
V:2 clef=treble name="2nd Violins"
%%MIDI program 40
|:"Cm" c4g4e4c4 | "G" B4d4B4G4 | "Ab" Ab4c4e4c3 | "Bdim" B4d4f4d4 |
"Cm" c16 | "G7" B,16 | "Ab" Ab16 | "Bdim" f16 |
"Cm" e16 | "G" d16 | "Ab" c'16 | "G7" b16 |
"Cm" c,16 | "Fm" f16 | "G" g16 | "Cm" e'16 :|
V:3 clef=alto name="Violas"
%%MIDI program 41
|:"Cm" g2e2c2e2 z8 | "G" d2B2G2B2 z8 | "Ab" e2c2Ab2c2 z7 | "Bdim" f2d2B2d2 z8 |
"Cm" g6 f2 z8 | "G7" g,6 b2 z8 | "Ab" e6 e2 z8 | "Bdim" f6 f2 z8 |
"Cm" e6 e2 z8 | "G" d6 d2 z8 | "Ab" c'6 c2 z8 | "G7" b6 b2 z8 |
"Cm" g,6 g2 z8 | "Fm" f6 f2 z8 | "G" d'6 d2 z8 | "Cm" c,6 c2 z8 :|
V:4 clef=bass name="Cellos"
%%MIDI program 42
|:"Cm" C4G,4E,4C4 | "G" G,4D4B,4G,4 | "Ab" Ab,4C4Ab,4C2 | "Bdim" B,4F4D4B,4 |
"Cm" C8- C16 z2 | "G7" G,8- G16 z2 | "Ab" Ab8- Ab16 | "Bdim" F8- F16 z2 |
"Cm" E8- E16 z2 | "G" D8- D16 z2 | "Ab" C8- C16 z2 | "G7" B,8- B,16 z2 |
"Cm" C4G,4E,4C4 | "Fm" F4F,4F,4F4 | "G" G,4D4B,4G,4 | "Cm" C,4G,4E,4C4 :|
V:5 clef=bass name="Double Basses"
%%MIDI program 43
|:"Cm" C,4C,4C,4C,4 | "G" G,,4G,4G,,4G,4 | "Ab" Ab,,4Ab,4Ab,,6 | "Bdim" B,,4B,4B,,4B,4 |
"Cm" C,8- C,16 z2 | "G7" G,,8- G,,16 z2 | "Ab" Ab,,8- Ab,,16 | "Bdim" B,,8- B,,16 z2 |
"Cm" E,,8- E,,16 z2 | "G" D,,8- D,,16 z2 | "Ab" C,,8- C,,16 z2 | "G7" B,,,8- B,,,16 z2 |
"Cm" C,4C,4C,4C,4 | "Fm" F,,4F,4F,,4F,4 | "G" G,,4G,4G,,4G,4 | "Cm" C,4C,4C,4C,4 :|
% Brass adds power, intensity, and a sense of terror
V:6 clef=treble name="Trumpets"
%%MIDI program 56
|:"Cm" g4 g8 f4 | "G" d4 d8 B4 | "Ab" e4 e8 c4 | "Bdim" f4 f8 d4 |
"Cm" g8- g16f z | "G7" g,8- g16b z | "Ab" e8- e16c z | "Bdim" f8- f16d z |
"Cm" e8- e16e z | "G" d8- d16d z | "Ab" c'8- c'16c' z | "G7" b8- b16b z |
"Cm" g,8- g16g z | "Fm" f8- f16f z | "G" d'8- d'16d' z | "Cm" c,8- c'16c' z :|
V:7 clef=bass name="Trombones"
%%MIDI program 57
|:"Cm" C8 G,8 | "G" G,8 D4 z4 | "Ab" Ab8 C4 z3 | "Bdim" B,8 F4 z4 |
"Cm" C8- C16 z2 | "G7" G,8- G16 z2 | "Ab" Ab8- Ab16 | "Bdim" F8- F16 z2 |
"Cm" E8- E16 z2 | "G" D8- D16 z2 | "Ab" C8- C16 z2 | "G7" B,8- B,16 z2 |
"Cm" C8 G,8 | "Fm" F8 F,8 | "G" G,8 D8 | "Cm" C4 C,4 z8 :|
V:8 clef=bass name="Tuba"
%%MIDI program 58
|:"Cm" c,4 c,4 c,4 c,4 | "G" g,,4 g,,4 g,4 g,4 | "Ab" ab,,4 ab,,4 ab,6  | "Bdim" b,,4 b,,4 b,4 b,4 |
"Cm" c,4 c,8 c,4 | "G7" g,,4 g,,8 g,,4 | "Ab" ab,,4 ab,,8 ab,, | "Bdim" b,,4 b,,8 b,,4 |
"Cm" e,,4 e,,8 e,,4 | "G" d,,4 d,,8 d,,4 | "Ab" c,4 c,8 c,4 | "G7" b,,,4 b,,,8 b,,,4 |
"Cm" c,4 c,4 c,4 c,4 | "Fm" f,,4 f,,4 f,4 f,4 | "G" g,,4 g,,4 g,4 g,4 | "Cm" c,4 c,4 c,4 c,4 :|
% Woodwinds provide sharp accents and rapid sequences to heighten the terror
V:9 clef=treble name="Flutes"
%%MIDI program 73
|:"Cm" e2 g2 c'2 e'2 z8 | "G" d2 b2 g'2 d''2 z8 | "Ab" e'2 c''2 ab'2 e''2 z7 | "Bdim" f'2 d''2 b2 f''2 z8 |
"Cm" c'2 e'2 g2 c''2 z8 | "G" d'2 b'2 g'2 d''2 z8 | "Ab" ab2 c'2 e'2 ab'2 z6 | "Bdim" b2 f'2 d2 b'2 z8 |
"Cm" g,2 c2 e2 g2 z8 | "G" d,2 g2 b2 d2 z8 | "Ab" ab,2 c2 e2 ab2 z6 | "Bdim" b,2 f2 d2 b2 z8 |
"Cm" e16 | "Fm" f16 | "G" g16 | "Cm" c16 :|
V:10 clef=treble name="Oboes"
%%MIDI program 68
|:"Cm" g8 e8 g  | "G" d8 b8 d  | "Ab" e8 c8 e  | "Bdim" f8 d8 f  |
"Cm" g8 e8 g  | "G" d8 b8 d  | "Ab" ab8 c9   | "Bdim" b8 f8 d  |
"Cm" e16 | "G" d16 | "Ab" c'16 | "G7" b16 |
"Cm" g,16 | "Fm" f16 | "G" d'16 | "Cm" c,16 :|
V:11 clef=treble name="Clarinets"
%%MIDI program 71
|:"Cm" e8 e8 g  | "G" b8 b8 d  | "Ab" c8 c8 e  | "Bdim" d8 d8 f  |
"Cm" e8 e8 g  | "G" b8 b8 d  | "Ab" ab8 ab8   | "Bdim" b8 b8 d'  |
"Cm" c'2 g2 e2 c'2 z8 | "G" g8 b8 d  | "Ab" e8 ab9   | "G7" b8 d'8 f'  |
"Cm" g,8 g8 e  | "Fm" f8 f8 f  | "G" d'8 d8 b  | "Cm" e8 c'8 g  :|
% Percussion adds a relentless drive to the battle scene
V:12 clef=perc name="Timpani"
%%MIDI program 47
|:"Cm" [CG,]8 [CG,]8 | "G" [DG,]8 [DG,]8 | "Ab" [CEb,,]8 [CEb,,]8 | "Bdim" [BD,,]8 [BD,,]8 |
"Cm" [CG,]8 [CG,]8 | "G" [DG,]8 [DG,]8 | "Ab" [CEb,,]8 [CEb,,]8 | "Bdim" [BD,,]8 [BD,,]8 |
"Cm" [CE,]8 [CE,]8 | "G" [DG,]8 [DG,]8 | "Ab" [CEb,,]8 [CEb,,]8 | "G7" [BD,,]8 [BD,,]8 |
"Cm" [CG,]8 [CG,]8 | "Fm" [CF,,]8 [CF,,]8 | "G" [DG,]8 [DG,]8 | "Cm" [CG,]8 [CG,]8 :|



X:1
T:Digital Eden Overture
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=120
K:Gmaj
% The piece begins with a layered texture suggesting a harmonious blend of elements
V:1 clef=treble name="Main Synth Melody"
%%MIDI program 81
|:"G" G4z4z8 | B4d4G4B4 | "C"e4e4 e4f4 | "D"z4A8 G4 |
"G" B4c4d4e4 | "Am" A6G2 F4E4 | "Bm" B2^AB2c2d2e2 z5 | "D"d8 d4z4 |
"Em"e8 e2z6 | "C" c4e4 g4a4 | "G"G4G4 B4z4 | "D"A4z4 D4F4 |
"G" G4F4E4D4 | B,4D4G4B4 | "C"e8 c8 | "D" z4A8 D4 :|
V:2 clef=treble name="Pad Synth"
%%MIDI program 88
K:Gmaj
|:"G" [G,2G2][^F2G2] z8 | [A,A2B2][G2A2]z8 | "C"[c2E2][B2c2]z8 | "D"[A2F2]z12 |
"G" [G,2B,2][A2G2]z8 | "Am"[A2^F2][G2A2]z8 | "Bm" [B2d2][^A2B2]z8 | "D"[F2D2]z12 |
"Em"[E2^G2]z12 | "C"[=G2C2]z12 | "G"[G,2B,2]z12 | "D"[A,2F2]z12 |
"G" [G,2^F2]z12 | [A,A2G2]z12 | "C"[c2E2]z12 | "D"[A2F2]z12 :|
V:3 clef=bass name="Pizzicato Strings"
%%MIDI program 45
K:Gmaj
|:"G" z8 D,4D,4 | z8 B,,4B,,4 | "C" z8 E,4E,4 | "D"z8 A,4A,4 |
"G" z8 B,4B,4 | "Am"z8 c4c4 | "Bm" z8 D4D4 | "D"z8 A,4A,4 |
"Em"z8 E4E4 | "C" z8 c4c4 | "G"z8 G,4G,4 | "D"z8 D,4D,4 |
"G" z8 G,4G,4 | "Am" z8 ^F,4^F,4 | "C" z8 C4C4 | "D"z8 D,4D,4 :|



X:1
T:Country Afternoon Idyll
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=92
K:G
V:1 clef=treble name="Violin"
%%MIDI program 40
|:"G"D2 G2 B2|"C"E3 D C2|"D7"A3 G F2|"G"G4 z2|
"Am"A2 c2 e2|"D7"f3 e d2|"G"B2 G2 D2|"G7"E4 F2|
"C"G2 E2 C2|"Am"A2 E2 A2|"Bm"F2 D2 B,2|"D7"A,4 z2 (3A,B,C|
"G"D2 G2 B2|"C"E3 D C2|"D7"A3 G F2|"G"G6:|
V:2 clef=treble name="Oboe"
%%MIDI program 68
|:"G"B2 e2 d2|"C"c3 B A2|"D"F3 E D2|"G"G4 z2|
"Am"A2 f2 e2|"D7"d3 c B2|"G"B2 d2 B2|"G7"D4 E2|
"C"c2 G2 E2|"Am"a2 e2 c2|"Bm"F2 d2 F2|"D7"A,4 z2 (3A,B,C|
"G"B2 e2 d2|"C"c3 B A2|"D"F3 E D2|"G"G6:|
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"G"G,4 D2|"C"E,4 C2|"D7"A,4 F2|"G"G,4 z2|
"Am"A,4 E2|"D7"F,4 D2|"G"G,4 D2|"G7"G,4 F2|
"C"C4 G,2|"Am"A,4 E2|"Bm"B,4 D2|"D7"F,4 C2|
"G"G,4 D2|"C"E,4 C2|"D7"A,4 F2|"G"G,6:|



X:1
T:Before The Storm
C:OrchestrAI
M:C
L:1/8
Q:1/4=90
K:Am
V:1 clef=treble
%%MIDI program 78
|: "Am"A2 cB ABcd | "E"B2 AG E2A2 | "F"E2 EF G2A2 | "E"E4 z4 |
"Am"c2 de fdBd | "Am"c2 BA F2E2 | "Dm"A2 dF E2D2 | "E"E4 z4 :|
V:2 clef=treble
%%MIDI program 79
|: "Am"E2 A2 c4 | "E"B,4 C4 | "F"A,2 AB C2D2 | "E"E2 G,4 z2 |
"Am"c2 e2 a4 | "Am"c2 E2 F4 | "Dm"D2 FA d4 | "E"E4 z4 :|
V:3 clef=alto
%%MIDI program 76
|: "Am"A,2 D2 F4 | "E"E,4 G,4 | "F"F,2 G,A, B,2C2 | "E"E,2 C4 z2 |
"Am"A,4 E4 | "Am"A,2 E2 D4 | "Dm" D3 F A,2D2 | "E"E4 z4 :|
V:4 clef=bass
%%MIDI program 42
|: "Am"A,2 E2 A,4 | "E"E,2 B,2 E4 | "F"F,2 C2 F,2A,2 | "E"E,4 z4 |
"Am"A,2 E2 A,4 | "Am"A,2 E2 A,4 | "Dm" D,2 A,2 D4 | "E"E,4 z4 :|



X:1
T:Fantasy Showdown Overture
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Dmin
V:1 clef=treble name="Brass" subname="Trpt."
%%MIDI program 61
|:"Dm"D4 F2A2|"C"G2E2 C4|"Bb"B,4 D4 F2|"A7"A2^C2 E4|
"Dm"A2d2 c2A2|"C"G2E2 G4|"Bb"A4 G2F2|"A7"E6 z2|
"Dm"d2c2 A2F2|"C"E2^G2 B4|"Bb"F2A2 B2A2|"A7"^C2E2 d4|
"Dm"A4 "C"G2E2|"Bb"D2F2 "A7"A2G2|"Dm"D6 z2:|
V:2 clef=treble name="Strings" subname="Viol."
%%MIDI program 41
|:"Dm"A2A2 f2d2|"C"c2E2 A4|"Bb"d4 F2B2|"A7"G2B2 c4|
"Dm"f2e2 d4|"C"A3 B c4|"Bb"B2A2 G2F2|"A7"E6 z2|
"Dm"A2f2 e2d2|"C"c2A2 c4|"Bb"d2f2 e2d2|"A7"A2G2 F4|
"Dm"f4 "C"G2E2|"Bb"D2F2 "A7"A2G2|"Dm"A,6 z2:|
V:3 clef=treble name="Woodwind" subname="Fl."
%%MIDI program 73
|:"Dm"F2F2 A4|"C"E2C2 G,4|"Bb"B,2B,2 D4|"A7"C2E2 F4|
"Dm"A2F2 D4|"C"G2E2 C4|"Bb"A2G2 F4|"A7"E2C2 D4|
"Dm"F2F2 A4|"C"E2C2 G,4|"Bb"d2D2 F4|"A7"G2B2 c4|
"Dm"A3 B c4|"C"E4 G4|"Bb"D4 A,4|"A7"A,4 F4|
"Dm"D4 z4:|
V:4 clef=bass name="Brass" subname="Hrn."
%%MIDI program 60
|:"Dm"D,4 F,4|"C"C,4 A,4|"Bb"B,,4 D,4|"A7"A,4 E,4|
"Dm"D,3 A, A,4|"C"C,4 G,4|"Bb"B,,4 A,,4|"A7"E,4 C,4|
"Dm"D,2D,2 F,4|"C"C,2E,2 G,4|"Bb"B,,4 F,4|"A7"A,2^C,2 E,4|
"Dm"E,4 A,4|"Bb"B,,2D,2 F,4|"A7"A,2E,2 C,4|
"Dm"D,6 z2:|



X:1
T:Epic Boss Confrontation
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=160
K:Bmin
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"Bm"B4 cBAG F4 EDFA| "A"G4 FGAF "G"EDCB, A,4|"F#m"A,4 B,CEF A4 cBAG| "Bm"F4 EDCB, "E"B,8|
"F#m"B4 AFED C4 EDB,C| "A"A,4 A,B,CD "G"E4 DEFG| "F#m"A4 GFED "E"E4 EDCB,|"Bm" B,8 z8:|
|:"G"B4 cBcd e4 dcdB| "A"c4 BcBA "F#m"F4 ABcB| "Bm"d4 dcde f4 gfed| "E"c4 BcBA "A"G8|
"G"a4 agfe fgaf gfed| "F#m"edcB "E

d "Bm"B4 cBAG| "E"A4 AFED "A"G,8|"F#m"F4 EDCB, "Bm"B,8:|
V:2 clef=treble name="Choir Aahs"
%%MIDI program 52
|:"Bm" [Bd]8 [Ac]8 | "A" [GB]8 [AF]8 | "G" [FE]8 [ED]8 | "F#m" [D^C]8 [DB,]8 |
"Bm" [Bd]8 [Ac]8 | "A" [GB]8 [AF]8 | "G" [FE]8 [ED]8 | "F#m" [D^C]8 [DB,]8 :|
|:"G" [Gg]8 [Af]8 | "A" [Aa]8 [Bb]8 | "F#m" [Bb]8 [cc']8 | "E" [c'a]8 [bB]8 |
"G" [Bg]8 [Af]8 | "F#m" [Aa]8 [Gg]8 | "E" [Fa]8 [a^g]8 | "Bm" [Bb]8 z8 :|
V:3 clef=bass name="Synth Bass"
%%MIDI program 87
|:"Bm"[B,2D]8 [CFA,]8 | "A"[EA,2A,]8 [GF^C]8 | "G"[F,2B,]8 [E,2G,]8 | "F#m"[D,2A,]8 [B,2^F,]8 |
"Bm"[B,2D]8 [CFA,]8 | "A"[EA,2A,]8 [GF^C]8 | "G"[F,2B,]8 [E,2G,]8 | "F#m"[D,2A,]8 [B,2^F,]8 :|
|:"G"[G,2B,]8 [A,2^C]8 | "A"[A,2c]8 [B,2d]8 | "F#m"[B2f]8 [c2a'f]8 | "E"[c2G]8 [A,2A,]8 |
"G"[G,2B,]8 [A,2^C]8 | "F#m"[A,2c]8 [F,2B,]8 | "E"[E,2A,]8 [D,2B,]8 | "Bm"[B,8] z8 :|



X:1
T:Friday Night - Extended
C:JLEM
M:4/4
L:1/8
Q:1/4=120
K:Bb
V:1 clef=treble
%%MIDI program 56
|: [K:Bb]z4 zF ||: "Bb" B2 _.A.B "Eb"z2 FG | "F" B2 _.A.B "Bb"z4 | "Eb" z2 G2 z2 FG | "Bb" B2 _.A2 B2d2 | "F" c2 B2 A2 G2 | "Eb" F2 D2 G2 E2 | "Bb" F4 z4 :|
V:2 clef=treble
%%MIDI program 1
[K:Bb]
|: z8 | z2 E2 E2 DC | B,2 D2 C4 | z2 F2 G2 A2 | B2 G2 F2 E2 | B2 c2 B2 A2 | G2 E2 F2 D2 :| C2 F2 E4  :|



X:1
T:Epic Horizon Overture
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=90
K:Gmaj
% Starting with the majestic landscape
V:1 clef=treble name="Strings" subname="Violins"
%%MIDI program 48
|:"G"B3c d2|"C"E6|"G"D2B2 G2|"D"D6|
"C"E3F G2|"G"B6|"Am"A3G F2|"D7"A6|
"G"B3c d2|"C"G3A B2|"D"A3B c2|"G"G6:|
V:2 clef=treble name="Brass" subname="Horns"
%%MIDI program 60
|:"G"G,3B, D2|"C"E,6|"G"D,2G,2 B,2|"D"A,6|
"C"E,3F, G,2|"G"B,6|"Am"A,3G, F,2|"D7"A,6|
"G"G,3B, D2|"C"C3E G2|"D"B,3D F2|"G"G,6:|
% Leading to the epic city approach
V:1
|:"Em"B2G2 F2|"C"E6|"G"D3E F2|"C"G6|
"Am"A2F2 E2|"D"A3B c2|"G"B6|"D"A6|
"G"G3A B2|"C"G6|"D"A3F G2|"G"G6:|
V:2
|:"Em"G,2E2 C2|"C"E,6|"G"D,3E, F,2|"C"G,6|
"Am"A,2F,2 E,2|"D"A,3B, c,2|"G"G,6|"D"D,6|
"G"B,3c d2|"C"E6|"D"D3F A2|"G"B,6:|
% Final view of the city, full and glorious chords
V:1
|:"G"B3d g2|"C"c6|"D"d3e f2|"G"g6|
"G"B3d g2|"C"c6|"D"d3e f2|"G"g6:|
V:2
|:"G"G,3B, D2|"C"C6|"D"D3F A2|"G"G,6|
"G"G,3B, D2|"C"C6|"D"D3F A2|"G"G,6:|"
"X:2
T:Joyful Reunion
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=132
K:C
% The introduction starts with a rising melody signifying the anticipation
V:1 clef=treble
%%MIDI program 1
|"C"E2 G3 E|"G"D2 B,3 D|"Am"C2 E3 A|"F"A2 c3 A|
"C"G2- G2 E2|"Dm"F3 E D2|"G"E2 D3 C|"C"C4 z2|
% The melody rises and falls like the emotions of the reunion
V:1
|"C"E2 G3 E|"F"A2 c3 A|"G"G3 F E2|"C"C6 z2|
"C"E2 G3 E|"F"A2 c3 A|"Dm"D2 F3 D|"G"G4 z2| 
% The middle part has more motion, like the flow of conversation
V:1
|"F"A2 c3 d|"G"B2 d3 c|"Am"c3 B A2|"G"B4 z2|
"F"A2 c3 d|"G"B2 d3 c|"Am"A2 E3 F|"G"G4 z2|
% The music resolves in a happy ending, expressing warmth and fulfillment
V:1
|"C"E2 G3 E|"F"A2 c3 A|"G"G2 B3 D|"C"C4-E2|
"F"F2 A3 F|"C"E2 G3 C|"Dm"D3 E F2|"G"G4 z2|
"C"c2 E3 G|"Am"A2 E3 C|"F"A2 c3 d|"G"B4 z2|
"C"E2 G3 E|"F"A2 c3 A|"G"G2 B3 D|"C"C8:|
% Voice 2 - providing harmonic support
V:2 clef=bass
%%MIDI program 42
|"C"C,4 E,4|"G"G,4 B,4|"Am"A,4 C4|"F"F,4 A,4|
"C"C,4 E,4|"Dm"F,4 A,4|"G"G,4 B,4|"C"C6 z2|
%"C"E,4 G,4|"F"F,4 A,4|"G"G,4 B,4|"C"C6 z2|
%C"E,4 G,4|"F"F,4 A,4|"Dm"D,4 F,4|"G"G6 z2| 
%"F"F,4 A,4|"G"G,4 B,4|"Am"A,4 C4|"G"G6 z2|
%"F"F,4 A,4|"G"G,4 B,4|"Am"A,4 C4|"G"G6 z2|
%"C"C,4 E,4|"F"F,4 A,4|"G"G,4 B,4|"C"C4-E4|
%"F"F,4 A,4|"C"C,4 E,4|"Dm"D,4 F,4|"G"G6 z2|
%"C"C4 E4|"Am"A,4 C4|"F"F,4 A,4|"G"G6 z2|
%"C"C,4 E,4|"F"F,4 A,4|"G"G,4 B,4|"C"C8:|
% Voice 3 adds countermelodies and fills
V:3 clef=treble
%%MIDI program 71
|"C"z4 E2 G2|"G"z4 D2 B,2|"Am"z4 C2 E2|"F"z4 A2 c2|
"C"z4 G2- G2|"Dm"z3 F2 E2|"G"z4 E2 D2|"C"z6|
%"C"z4 E2 G2|"F"z4 A2 c2|"G"z4 G2 F2|"C"z6|
%C"z4 E2 G2|"F"z4 A2 c2|"Dm"z4 D2 F2|"G"z6|
%"F"z4 A2 c2|"G"z4 B2 d2|"Am"z4 c2 B2|"G"z6|
%"F"z4 A2 c2|"G"z4 B2 d2|"Am"z4 A2 E2|"G"z6|
%"C"z4 E2 G2|"F"z4 A2 c2|"G"z4 D2 B2|"C"z4-E4|
%"F"z4 F2 A2|"C"z4 E2 G2|"Dm"z4 D2 E2|"G"z6|
%"C"z4 c2 E2|"Am"z4 A2 E2|"F"z4 d2 c2|"G"z6|
%"C"z4 E2 G2|"F"z4 A2 c2|"G"z4 D2 B2|"C"z8:|



X:1
T:Light of Hanukkah
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:Amin
% Voice 1: The main melody, played with a traditional klezmer sound
V:1
%%MIDI program 71
|:"Am"E2 AB AG|E2 E4|"Dm" F2 D2 F2|"E7" E4 z2|
"Am" A2 AG EF|G2 A4|"G" B2 "C'E7" z2 E2|"Am" A6|
"C" E2 E2 G2|"Dm" F2 E2 D2|"Am" c2 B2 A2|"G" B4 z2|
"Am" E2 cB AG|"F" E2 F4|"E7" G2 AG FE|"Am" A6:|
% Voice 2: A counter-melody to complement the main theme
V:2
%%MIDI program 71
|:"Am"C2 C2 C2|E2 E4|"Dm" D2 C2 D2|"E7" E4 z2|
"Am" A2 A2 G2|A2 E4|"G" G2 "C'E7" z2 B,2|"Am" A,6|
"C" G,2 G,2 G,2|"Dm" A,2 G,2 F,2|"Am" A,2 G,2 F,2|"G" G,4 z2|
"Am" E2 A2 G2|"F" F2 F4|"E7" E2 F2 G2|"Am" A,6:|
% Voice 3: Harmonizing voice
V:3
%%MIDI program 46
|:"Am"A,2 A,2 A,2|C2 E4|"Dm" D2 D2 F2|"E7" E4 z2|
"Am" E2 E2 C2|E2 A,4|"G" B,2 "C'E7" z2 E2|"Am" A,6|
"C" C2 C2 E2|"Dm" D2 F2 E2|"Am" A2 A2 B2|"G" B4 z2|
"Am" A,2 A,2 A,2|"F" F2 F4|"E7" E2 G2 F2|"Am" A,6:|
% Voice 4: Bass voice providing the foundational harmony
V:4
%%MIDI program 32
|:"Am"A,,2 E,2 A,2|"Dm" D,2 A,2 D2|"E7" E,4 B,2|"Am" A,6|
"Am" E,2 A,2 C2|"E7" E,4 "Am"A,2|"G" G,2 "C'E7" z2 E,2|"Am" A,6|
"C" E,2 G,2 "Am"A,2|"Dm"F,2 A,2 D,2|"Am"A,2 C2 "G"E,2|"Am"A,6|
"Am"A,2 "F"C2 F,2|"E7"E,4 "Am"E,2|"E7"G,2 B,2 E2|"Am"A,6:|



X:1
T:Dusk among the Trees
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=50
K:Gmin
V:1 clef=treble name="Flute" subname="Fl."
%%MIDI program 73
|:"Gm"G2 D2 B2 | A4 z2 | "F"F2 C2 A2 | G4 z2 |
"Dm"A2 F2 D2 | "Cm"G4 z2 | "Bb"B2 G2 F2 | "Gm"G4 z2 |
"Eb"G2 B2 G2 | "F"A4 z2 | "Dm"F2 A2 d2 | c4 z2 |
"Gm"B2 B2 A2 | "Cm"G4 z2 | "Bb"B2 A2 G2 | "Gm"G4 z2:|
V:2 clef=treble name="Strings" subname="Vln."
%%MIDI program 48
|:"Gm"D4 D2 | C4 B2 | "F"A4 A2 | G4 F2 |
"Dm"F4 F2 | "Cm"G4 A2 | "Bb"B,4 C2 | "Gm"D4 D2 |
"Eb"E4 G2 | "F"F4 F2 | "Dm"A4 d2 | c4 B2 |
"Gm"G4 A2 | "Cm"G4 F2 | "Bb"B,4 C2 | "Gm"D4 D2:|
V:3 clef=bass name="Cello" subname="Vc."
%%MIDI program 42
|:"Gm"G,2 B,2 D2 | "F"F,4 A,2 | "Dm"D,4 F,2 | "Cm"C,4 E,2 |
"Bb"B,,4 D2 | "Gm"G,4 B,2 | "Eb"E,4 G,2 | "G"D,4 z2 |
"Eb"E,2 G,2 B,2 | "F"F,4 A,2 | "Dm"D,4 F,2 | "Cm"C,2 B,,2 C2 |
"Gm"G,4 D2 | "Cm"E,4 G,2 | "Bb"B,,2 D2 F2 | "Gm"G,4 z2:|



X:1
T:Waltz of the Whimsical Winds
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=144
K:G
V:1 clef=treble name="Piano"
%%MIDI program 0
|: "G" D2 B4 | "Am" c3 A A2 | "D7" F2 A4 | "G" G3 G E2 |
"D" F2 G4 | "C" E3 E G2 | "A7" A2 ^c4 | "D7" d3 d ^c2 |
"G" B2 d4 | "Bm" B3 A G2 | "E7" e2 g4 | "Am" a3 a g2 |
"D7" f2 a4 | "G" g3 g f2 | "C" e3 d B2 | "G" G6 :|
|: "G" d2 | "Bm" B3 A G2 | "C" E3 D E2 | "G" G3 F G2 |
"A7" A2 | "D" d3 d e2 | "G" d3 ^c d2 | "D7" d2 z2 G2 |
"E7" e2 | "Am" a3 g f2 | "D7" F3 G A2 | "G" g6 |
"D7" d2 | "G" D2 g3 f | "Em" g2 e4 | "[G]" d3 z2 z :|
V:2 clef=treble name="Violin I"
%%MIDI program 40
|: "G" d2 g4 | "Am" a3 f a2 | "D7" a2 ^c4 | "G" b3 b g2 |
"D" a2 b4 | "C" g3 g b2 | "A7" e2 g4 | "D7" f3 f e2 |
"G" d2 g4 | "Bm" a3 f g2 | "E7" g2 b4 | "Am" c'3 c' b2 |
"D7" a2 f4 | "G" b3 b a2 | "C" g3 f d2 | "G" d6 :|
|: "G" b2 | "Bm" B3 A G2 | "C" ^c3 d e2 | "G" B3 B c2 |
"A7" d2 | "D" g3 g a2 | "G" a3 g a2 | "D7" d2 z2 G2 |
"E7" e2 | "Am" c'3 b a2 | "D7" F3 G A2 | "G" d6 |
"D7" B2 | "G" G2 d3 c | "Em" B2 d4 | "[G]" b3 z2 z :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "G" G2 D4 | "Am" A,2 C4 | "D7" D2 F4 | "G" G,2 G,4 |
"D" A,2 D4 | "C" C2 E4 | "A7" A,2 A,4 | "D7" D2 ^C4 |
"G" G2 B,4 | "Bm" B,2 D4 | "E7" E2 G4 | "Am" A,2 A,4 |
"D7" A,2 F4 | "G" G,2 D4 | "C" C2 E4 | "G" G,2 G,4 :|
|: "G" D2 | "Bm" B,2 D4 | "C" C2 E4 | "G" D2 D4 |
"A7" G,2 | "D" A,2 A,4 | "G" B,2 G,4 | "D7" d2 z2 D2 |
"E7" e2 | "Am" c3 c3 | "D7" f2 a4 | "G" G,2 B,4 |
"D7" A,2 | "G" G,2 B,4 | "Em" E2 G4 | "[G]" G,2 G,4 :|



X:1
T:Digital Eden Soundscape
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Cmaj
% The combination of electronic and natural sounds paints a picture of a digital utopia
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|: "C"E4 G2 c2 | "Am"e2a2 "F" f2e2 | "C" d4 G2 B2 | "G" g2b2 d'2c'2 |
"C" c4 e2g2 | "Am" e2a2 "F" f2e2 | "C" c3 B "G" A2G2 | "C" c8 :|
V:2 clef=treble name="Clean Guitar"
%%MIDI program 27
|: "C" C2E2 G4 | "Am" A4 e4 | "F" F4 f4 | "G" G3A B2c2 |
"C" C2G2 E4 | "Am" A4 e4 | "F" F6 E2 | "G" G4 z4 :|
V:3 clef=bass name="Synth Bass"
%%MIDI program 38
|: "C"C,4 C,2E,2 | "Am"A,,4 A,4 | "F"F,8 | "G"G,,6 B,,2 |
"C"C,4 C,2E,2 | "Am"A,,4 A,4 | "F"F,6 G,2 | "G"G,,4 z4 :|
V:4 clef=treble name="Synth Pad"
%%MIDI program 92
|: "C"[CE]8 | "Am"[AE]8 | "F"[FA]8 | "G"[GB]8 |
"C"[CE]8 | "Am"[AE]8 | "F"[FA]8 | "G"[GB]8 :|



X:1
T:Bandstand Frolic
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Cmaj
% The composition has a jovial and playful melody with brass and wind instrument tones
V:1 clef=treble-8
%%MIDI program 56
|:"C"G4 E2 G2|"F"A4 c2 d2|"C"G4 E2 C2|"G7"D4 B,2 D2|
"C"E2 G2 c2 e2|"Am"A4 ^G2 A2|"Dm"F2 D2 F2 A2|"G7"G4 E2 G2|
"C"C3 D E2 C2|"F"A4 F2 A2|"C"E4 G2 c2|"G7"B,4 D2 G2|
"C"G4 E2 G2|"Am"A4 c2 e2|"Dm"d2 c2 B2 A2|"G7"B4 G2 z2:|
V:2 clef=treble
%%MIDI program 65
|:"C"e4 c2 e2|"F"f4 a2 b2|"C"c4 e2 G2|"G7"B4 d2 f2|
"C"c2 e2 g2 c'2|"Am"a4 g2 a2|"Dm"d2 f2 a2 c'2|"G7"c'4 e2 c'2|
"C"c'3 b a2 g2|"F"f4 c2 a2|"C"c2 e2 g2 e2|"G7"d4 f2 B2|
"C"c4 C2 C2|"Am"A3 G A2 A2|"Dm"B2 A2 G2 F2|"G7"E4 C2 z2:|
V:3 clef=bass
%%MIDI program 66
|:"C"C2 E2 G,2 C2|"F"F2 A2 c2 f2|"C"C2 E2 G,2 C2|"G7"D2 F2 B,2 D2|
"C"C2 E2 G,2 C2|"Am"A,4 E2 A,2|"Dm"D2 F2 A,2 D2|"G7"G,4 D2 G,2|
"C"C2 C2 E2 G,2|"F"F2 c2 A2 F2|"C"C2 G,2 E2 C2|"G7"D2 G,2 B,2 D2|
"C"C2 G,2 E2 C2|"Am"A,2 E2 A,2 E2|"Dm"D2 A,2 F2 D2|"G7"G,4 D2 z2:|
V:4 clef=bass
%%MIDI program 69
|:"C"C4 C,2 E2|"F"F4 c2 a2|"C"G,4 C2 E2|"G7"D4 B,,2 D2|
"C"C4 C,2 E2|"Am"A,4 E2 C2|"Dm"D4 A,2 F2|"G7"D2 G,2 B,2 D2|
"C"C2 C2 C4|"F"F4 c2 c2|"C"G,2 C2 E2 G2|"G7"D2 F2 B,2 D2|
"C"C4 C2 C2|"Am"A,2 E2 C2 A,2|"Dm"D2 A,2 F2 A,2|"G7"G,4 D2 z2:|



X:1
T:Waltz of the Whispering Woods
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=120
K:Am
% The enchanted melody weaves through the ancient forest
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"Am" E2A2c2 | "G" B2d2G2 | "C" E2G2c2 | "Em" B,2E2G2 |
| "Am" A,2C2E2 | "F" A2D2F2 | "Dm" F2A2d2 | "E" G2B2e2 |
| "Am" c2e2a2 | "G" B2d2g2 | "C" e2g2c2 | "Am" a2c2e2 |
% Etc...
V:2 clef=bass name="Cello"
%%MIDI program 42
|:"Am" A,G,F, A,2G, | "G" B,A,G B,2A, | "C" C,B,A C2E, | "Em" E,G,F E2G, |
| "Am" A,C,B, A,2C, | "F" A,D,C A,2D, | "Dm" F,A,d, F,2A, | "E" G,B,E G,2B, |
| "Am" c,e,a, c2e, | "G" B,d,g, B,2d, | "C" e,g,c e2g, | "Am" a,c,e, a2c, |
% Etc...
V:3 clef=bass name="Contrabass"
%%MIDI program 43
|:"Am" A,,2C,2E, z | "G" B,,2D,2G, z | "C" C2E2G2 | "Em" B,2E2G2 |
| "Am" A,,2C,2E, z | "F" A,,2D,2F, z | "Dm" F2A2d2 | "E" G2B2e2 |
| "Am" c,,2e,2a, z | "G" B,,2d,2g, z | "C" e2g2c2 | "Am" a,,2c,2e, z |
% Etc...



X:1
T:Epic Battle of Human Fate
C:OrchestrAI
M:5/4
L:1/8
Q:1/4=160
K:Amin
V:1 clef=treble
% Huserrlian Humanists Theme
%% MIDI program 41
|: "Am"e4 e4 e2 | "G"d4 B4 z2 | "F"e4 c4 A2 | "E"e6 z2 z2 |
"Am"c4 ^G4 A2 | "Dm"F4 A4 z2 | "G"B4 d4 G2 | "Am"A6 z2 z2 :|
V:2 clef=treble
%% MIDI program 41
|: "Am"A4 A4 A2 | "G"G4 E4 z2 | "F"A4 F4 C2 | "E"E6 z2 z2 |
"Am"C4 G4 A2 | "Dm"D4 F4 z2 | "G"B4 G4 D2 | "Am"A6 z2 z2 :|
V:3 clef=bass
%% MIDI program 42
|: "Am"A,4 A,4 A,2 | "G"G,4 D4 z2 | "F"A,4 C4 F,2 | "E"E,6 z2 z2 |
"Am"C,4 E,4 A,2 | "Dm"D,4 F,4 z2 | "G"G,4 B,4 G,2 | "Am"A,6 z2 z2 :|
% Neo Nietzcheans Theme
V:1
|: "C"e4 g4 e2 | "A"e4 c4 ^g2 | "Bm"d4 f4 d2 | "G"B4 G4 z2 |
"C"g4 e4 c2 | "A"a4 f4 d2 | "Bm"f4 d4 B2 | "G"g6 z2 z2 :|
V:2
|: "C"c4 e4 c2 | "A"c4 A4 ^G2 | "Bm"B4 D4 B2 | "G"G4 D4 z2 |
"C"E4 C4 A2 | "A"A4 E4 C2 | "Bm"D4 B,4 G2 | "G"G6 z2 z2 :|
V:3
|: "C"C4 C4 E2 | "A"A,4 E4 A,2 | "Bm"B,4 F4 B,2 | "G"G,4 B,4 D2 |
"C"E4 C4 A,2 | "A"A,4 F4 D2 | "Bm"B,4 D4 G2 | "G"G,6 z2 z2 :|
% Butlerian Bogostroiteli Theme
V:1
|: "Fmaj7"e4 c4 e2 | "G"B4 d4 ^G2 | "Am"e4 a4 c2 | "E"e6 e2 z2 |
"Fmaj7"c4 A4 c2 | "G"B4 g4 B2 | "Am"A4 c4 e2 | "E"e6 z2 z2 :|
V:2
|: "Fmaj7"A4 F4 A2 | "G"D4 B4 D2 | "Am"C4 A4 C2 | "E"E6 E2 z2 |
"Fmaj7"A4 F4 A2 | "G"G4 D4 G2 | "Am"A4 E4 A2 | "E"E6 z2 z2 :|
V:3
|: "Fmaj7"F4 C4 F2 | "G"G,4 D4 G,2 | "Am"A,4 E4 A,2 | "E"E,6 E,2 z2 |
"Fmaj7"F4 C4 A,2 | "G"G,4 D4 G,2 | "Am"A,4 E4 A,2 | "E"E,6 z2 z2 :|



X:1
T:Boss Battle Theme
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Am
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|: "Am" e2 e2 g2 a2 | "F" f2 e2 d2 c2 | "G" b2 a2 g2 f2 | "E" e4 d4 |
"Am" e2 e2 g2 a2 | "F" f2 e2 d2 c2 | "G" b2 a2 g2 f2 | "E" e4 d4 :|
V:2 clef=treble name="Counter Melody"
%%MIDI program 82
|: "Am" c2 c2 e2 d2 | "F" d2 c2 B,2 A,2 | "G" G2 F2 E2 D2 | "E" E4 D4 |
"Am" c2 c2 e2 d2 | "F" d2 c2 B,2 A,2 | "G" G2 F2 E2 D2 | "E" E4 D4 :|
V:3 clef=bass name="Bass Synth"
%%MIDI program 33
|: "Am" A,2 A,2 A,2 A,2 | "F" F,2 F,2 F,2 F,2 | "G" G,2 G,2 G,2 G,2 | "E" E,4 E,4 |
"Am" A,2 A,2 A,2 A,2 | "F" F,2 F,2 F,2 F,2 | "G" G,2 G,2 G,2 G,2 | "E" E,4 E,4 :|
V:4 clef=treble name="Brass Hit"
%%MIDI program 60
|: "Am" z4 C4 | "F" z4 A4 | "G" z4 B4 | "E" z4 G4 |
"Am" z4 C4 | "F" z4 A4 | "G" z4 B4 | "E" z4 G4 :|



X:1
T:Capricious Whiskers
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=132
K:Am
V:1 clef=treble
%%MIDI program 1
|:"Am"E2A2^G2A2|"E7"B4B2cB|"Am"A2E2^G2A2|"Dm"F2D2F2A2|
"Am"E2^G2A4|"C"B,2C2E3G|"E7"B,2^G2B2AG|"Am"A6z2:|
V:2 clef=treble
%%MIDI program 1
|:"Am"A,2C2A,2C2|"E7"B,2E2B,2E2|"Am"A,2C2A,2C2|"Dm"F,2D2F,2D2|
"Am"A,2C2A,4|"C"E,2C2G,2E2|"E7"B,2E2G2F2|"Am"A,6z2:|
V:3 clef=bass
%%MIDI program 33
|:"Am"E4A,2E2|"E7"^G,4C,2^G,2|"Am"A,4C2E2|"Dm"D4F2A,2|
"Am"E4"^G7"=G,2E2|"C"C4E2G2|"E7"B,4G,2B,2|"Am"A,6z2:|



X:1
T:Nebula Escape
C:OrchestrAI
M:6/8
L:1/8
Q:1/4=140
K:Em
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"Em" E3 B,2E | G2F E2D | "C" C3 E2G | "G" B3 D2B |
| "D" A2F D2F | "Bm" B3 F2D | "Am" E3 C2E | "B7" ^D3 F2^A |
| "Em" B3 e2b | g2f e2d | "C" c3 e2g | "G" b3 d2b |
| "D" a2f d2f | "Bm" b3 f2d | "Am" e3 c2e | "B7" ^d3 f2^a |]
V:2 clef=treble name="French Horn"
%%MIDI program 60
|:"Em" B,3 E2G | B2A G2F | "C" E3 G2B | "G" D3 G2B |
| "D" F2D A,2D | "Bm" D3 B,2F | "Am" C3 E2A | "B7" B,3 ^D2F |
| "Em" G3 B2e | b2a g2f | "C" e3 g2b | "G" d3 g2b |
| "D" f2d a,2d | "Bm" d3 b,2f | "Am" c3 e2a | "B7" b,3 ^d2f |]
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"Em" E,3 G,2B, | E2D B,2G, | "C" C,3 E,2G, | "G" G,3 B,2D |
| "D" D,3 F,2A, | "Bm" B,,3 D,2F, | "Am" A,,3 C,2E, | "B7" B,,3 ^D,2F, |
| "Em" E,3 G,2B, | E2D B,2G, | "C" C,3 E,2G, | "G" G,3 B,2D |
| "D" D,3 F,2A, | "Bm" B,,3 D,2F, | "Am" A,,3 C,2E, | "B7" B,,3 ^D,2F, |]
V:4 clef=treble name="Synthesizer"
%%MIDI program 91
|:"Em" [E,G,B,]3 [G,B,E]2[B,EG] z3 | [EGB]2[DFA] [B,EG]2[G,B,D] z2 | "C" [C,E,G,]3 [E,G,C]2[G,CE] z3 | "G" [G,B,D]3 [B,DG]2[DGB] z3 |
| "D" [D,F,A,]3 [F,A,D]2[A,DF] z3 | "Bm" [B,,D,F,]3 [D,F,B,]2[F,B,D] z3 | "Am" [A,,C,E,]3 [C,E,A,]2[E,A,C] z3 | "B7" [B,,^D,F,]3 [^D,F,B,]2[F,B,^D] |
| "Em" [E,G,B,]3 [G,B,E]2[B,EG] z3 | [EGB]2[DFA] [B,EG]2[G,B,D] z2 | "C" [C,E,G,]3 [E,G,C]2[G,CE] z3 | "G" [G,B,D]3 [B,DG]2[DGB] z3 |
| "D" [D,F,A,]3 [F,A,D]2[A,DF] z3 | "Bm" [B,,D,F,]3 [D,F,B,]2[F,B,D] z3 | "Am" [A,,C,E,]3 [C,E,A,]2[E,A,C] z3 | "B7" [B,,^D,F,]3 [^D,F,B,]2[F,B,^D] |]



X:1
T:Hero's Final Stand
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=144
K:Cmin
V:1 clef=treble name="Trumpet" subname="Trpt."
%%MIDI program 56
|"Cm"G4 z2 G2 | "Bb"F4 z2 F2 | "Ab"G4- G2 A2 | "G7"B2 G4 z2 |
"Cm"E2 c2 G2 E2 | F4- F2 G2 | "Bb"D2 B2 F2 D2 | "G7"G4 z4 |
"Ab"E2 c2 G2 E2 | "Eb"G2 B2 G2 B2 | "Cm"G4- G2 A2 | "G7"B2 G4 z2 |
"Cm"E6 G2 | F6 A2 | "Bb"D6 F2 | "G7"G4 z4 :|
V:2 clef=treble name="French Horn" subname="Hrn."
%%MIDI program 60
|"Cm"C2 E2 G2 C2 | "Bb"B,2 D2 F2 B,2 | "Ab"C2 E2 G2 C2 | "G7"B,2 D2 G2 B,2 |
"Cm"C2 E2 G2 C2 | B,2 D2 F2 B,2 | "Bb"D2 F2 B2 D2 | "G7"G,2 D2 G2 B,2 |
"Ab"C2 E2 C2 G2 | "Eb"B,2 G2 E2 B,2 | "Cm"C2 E2 G2 C2 | "G7"B,2 D2 G2 B,2 |
"Cm"C4- C2 C2 | "Bb"B,4- B,2 B,2 | "Ab"Ab4- A2 A | "G7"G,4 z4 :|
V:3 clef=bass name="Trombone" subname="Tbn."
%%MIDI program 57
|"Cm"G,4 z2 G,2 | "Bb"F,4 z2 F,2 | "Ab"G,,4- G,2 A,2 | "G7"B,2 G,4 z2 |
"Cm"E,2 G,2 C2 G,2 | F,4- F,2 G,2 | "Bb"D,2 F,2 B,2 F,2 | "G7"G,4 z4 |
"Ab"E,2 G,2 C2 G,2 | "Eb"B,,2 E,2 B,2 E,2 | "Cm"G,,4- G,2 A,2 | "G7"B,2 G,4 z2 |
"Cm"E,6 G,2 | F,6 A,2 | "Bb"D,6 F,2 | "G7"G,4 z4 :|
V:4 clef=bass name="Timpani" subname="Tmp."
%%MIDI program 47
|"Cm"G,2 G,2 G,2 G,2 | "Bb"F,2 F,2 F,2 F,2 | "Ab"G,,2 G,,2 G,,2 G,,2 | "G7"G,,2 G,,2 G,,2 G,,2 |
"Cm"G,2 G,2 G,2 G,2 | "Bb"F,2 F,2 F,2 F,2 | "Ab"G,,2 G,,2 G,,2 G,,2 | "G7"G,,2 G,,4 z2 |
"Ab"G,2 G,2 G,2 G,2 | "Eb"G,2 G,2 G,2 G,2 | "Cm"G,,2 G,,2 G,,2 G,,2 | "G7"G,,2 G,,4 z2 |
"Cm"G,2 G,2 G,2 G,2 | "Bb"F,2 F,2 F,2 F,2 | "Ab"G,,2 G,,2 G,,2 G,,2 | "G7"G,,2 G,,4 z2 :|



X:1
T:Boss Battle Blitz
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Bm
V:1 clef=treble name="Brass"
%%MIDI program 61
|:"Bm"f4f4bfaf z8 "A"g4g4agfe|"G"f4f4dBGB "A"G2A2B4 z8 B2z2|
"Bm"B4B4B4f4 "F#m"A4A4A4c4|"G"EGFE "A"D2C2B,4 z16 B,2z2|
"Bm"b8f4d4 "A"c4e4a4g4|"G"B2G2F2E2D2C2 "A"B,2A,2G,4- z8 G2z2|
"Em"g4e4g4b4 "D"afdf z8 afdf|"A"e2^d2e2f2 "E"g2a2f4- z12 f2z2|
"F#m"a4 a2af "G"g4 z16 g2gf|"Bm"f4 f2f2 "A"e4 z16 d2c2|"Bm"d8- z16 d4z4:|
V:2 clef=treble name="Strings"
%%MIDI program 48
|:"Bm"B2B2B2B2F2F2F2F2 "A"A2A2A2A2G2E2E2E2|"G"G2G2G2G2D2D2D2D2 "A"A2A2B4 z4 B2z2|
"Bm"B4B4F4F4 "F#m"A4A4c4c4|"G"G2F2 "A"E2D2 z16 "Bm"B,4B,2z2|
"Bm"b4b4f4d4 "A"c4c4a4g4|"G"D2B2A2G2F2E2 "A"D2B,2A,4- z8 A2z2|
"Em"G2E2G2E2B4b4 "D"A2F2A2F2d4d4|"A"E4- E2"^d"=d2E2F2 "E"G4- z12 G2z2|
"F#m"a4 a2af "G"g4 z16 g2gf|"Bm"f4 f2f2 "A"e4 z16 d2c2|"Bm"d8- z16 d4z4:|
V:3 clef=bass name="Percussive Organ"
%%MIDI program 18
|:"Bm"B,8 F8 "A"A,8 E8|"G"G,8 D8 "A"A,2B,2B,4 z4 B,2z2|
"Bm"B,8 B,8 "F#m"A,8 A,8|"G"G,4F,4 "A"A,4G,4 z8 "Bm"B,8|
"Bm"B,8 F,4D,4 z4 "A"E,4C,4A,2G,2|"G"D,2B,,2A,,2G,,2F,,2E,,2 "A"D,,8- z4 D,,4z4|
"Em"G,4E,4G,4b,4 "D"d,8- d4z4|"A"A,,4- A,,2"^d"=d,,2E,,2F,2 "E"G,4- z12 G,2z2|
"F#m"F,8 F,2F,2 "G"G,8 z8 G,2G,2|"Bm"B,8 F,4F,4 z8 "A"A,8|"Bm"B,8- z16 B,4z4:|



X:1
T:Improved March of Remembrance
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=70
K:Am
% Expanded first voice with more melodic development and dramatic articulation
V:1 clef=treble
%%MIDI program 41
|: "Am" A4 A2 z2 | "G" (3GAGF E3 z | "F" F2 E2 D2 C2 | "E7" E6 z2 |
"Am" A3 A c2 A2 | "G" G3 F A4 | "F" F2 (3EFE D4 |1 "E7" E4 z2 A,2 :|2 "E7" E4 z4 |
% The second voice now contains richer harmonies and more active movement
V:2 clef=treble
%%MIDI program 41
|: "Am"^c2 A2 e4 | "G"d2 B2 G2 F2 | "F"cBAG F4 | "E7"E2 B,2 G,3 E |
"Am"A2 ^G2 A2 cB | "G"G2 F2 G2 A2 | "F"F2 E2 D2 E2 |1 "E7" E4 z2 ^C2 :|2 "E7" E4 z4 |
% The third voice provides a stronger harmonic foundation while maintaining smoothness
V:3 clef=bass
%%MIDI program 42
|: "Am" A,2 C2 E3 A, | "G" G,3 F G,2 D2 | "F" F,4 F3 E | "E7" E,2 ^G,2 E,3 B,, |
"Am" A,2 A,2 C2 E2 | "G" G,3 F G,4 | "F" F4- F2 ^C2 |1 "E7" E,4 z2 A, :|2 "E7" E,4 z4 |
% B section with smoother transitions and varied responses in each repeat
V:1
|: "F" c4 c2 B2 | "Am" A3 ^G A2 F2 | "E7" G2 F2 E2 ^(G/A) | "Am" A4 z2 A2 |
"F" c4 c2 A2 | "G" B4 A2 G2 | "Am" c2 (3BAG A2 F2 | "E7" E4 z4 :|
V:2
|: "F" A2 c2 B2 c2 | "Am" e2 A2 G2 E2 | "E7" B2 A2 ^(G/F) E2 | "Am" A4 z2 A2 |
"F" A2 c2 d2 f2 | "G" d2 ^F2 G2 A2 | "Am" c2 B2 A2 ^G2 | "E7" E4 z4 :|
V:3
|: "F" F,4 F,2 E,2 | "Am" A,3 ^G A,2 E,2 | "E7" ^G,2 A,2 B,2 ^C2 | "Am" A,4 z2 A,2 |
"F" F,4 F,2 ^C2 | "G" G,4- G,2 F2 | "Am" A,2 B,2 A,2 ^G2 | "E7" E4 z4 :|
% Returning to the A section with intensified voices and enhanced dynamics
V:1
|: "Am" A2 A2 E4 | "G" (3GAGF E2 F2 | "F" F2 E2 D2 ^(C/D) | "E7" E6 z2 |
"Am" A2 A2 e2 c2 | "G" (3GFG A2 G4 | "F" (3FED F2 E2 | "E7" E4 z2 A,2 :|
V:2
|: "Am" e2 A2 e4 | "G" d2 B2 d2 cB | "F" c4 B2 A2 | "E7" [E2B,2] [E2B,2] z2 G2 |
"Am" e2 e2 a2 g2 | "G" d2 c2 B2 c2 | "F" cBAG F4 | "E7" E4 z4 z2 :|
V:3
|: "Am" A,2 C2 E2 A,2 | "G" G,4- G,2 F2 | "F" F3 E F2 D2 | "E7" E,2 ^(G,/A,) E,4 |
"Am" A,2 C2 A,2 C2 | "G" G,4- G,2 ^C2 | "F" F,4- F2 E2 | "E7" E,4 z2 A, :|



X:1
T:The Quaint Village Street
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Cmaj
% Melody (First Voice) - representing a casual walker observing the scenes
V:1
%% MIDI program 4
|: "C"G4 E2D2 | "Am"E6 FE | "Dm"D4 F2A2 | "G7"B6 AG |
"C"C4 G2E2 | "F"A4 G2F2 |"Em" E4 c2E2 |1 "Dm"D6 DE :|2 "Dm"D6 G2 |
% Counter Melody (Second Voice) - imitating a secondary observer or echo of the first
V:2
%% MIDI program 4
|: "C"C2E2 G4 | "Am"A2c2 E4 | "Dm"F3A D3F | "G7"G2B2 D2G2 |
"C"C2E2 G2C2 | "F"F2A2 C2F2 |"Em" E2G2 B,2E2 |1 "Dm"D2F2 A4 :|2 "Dm"D2F2 G4 |
% Bass Line (Third Voice) - reflecting the stable foundations of the village
V:3 clef=bass
%% MIDI program 34
|: "C"C,4 C2G,2 | "Am"A,4 A2E,2 | "Dm"D,4 D2A,2 | "G7"G,4 B,2D2 |
"C"C,4 C2G,2 | "F"F,4 F2C2 |"Em" E,4 E2B,2 |1 "Dm"D,4 D2F,2 :|2 "Dm"D,4 D2G,2 |
% Melody variations and developments, representing walkers mingling
V:1
|:"G7"G2D2 B2d2 |"C"c4 E2G2 |"F"A2F2 D4 |"C"G4 E4 |
"G7"G2B2 D2G2 |"C"C2E2 G2C2 |"F"A3F E2C2 |1 "G7"B,4 A,4 :|2 "G7"B,4 A,2G,2 |
V:2
|:"G7"B2G2 B4 |"C"c2G2 E4 |"F"F2D2 A4 |"C"G2E2 C4 |
"G7"B,2D2 G3B, |"C"C2E2 G4 |"F"F3D C2A,2 |1 "G7"B,4 A,4 :|2 "G7"B,4 A,2G,2 |
V:3
|:"G7"G,2D2 G,4 |"C"C,2G,2 C4 |"F"F,2C2 F,4 |"C"C,2G,2 C2E,2 |
"G7"G,2B,2 D4 |"C"C,2E,2 G2C2 |"F"F,3C F,2A,2 |1 "G7"B,4 G,4 :|2 "G7"B,4 G,2C,2 |





X:1
T:Shadowed Velocity
C:OrchestrAI
M:C
L:1/16
Q:1/4=400
K:Gmin
V:1 clef=treble
%%MIDI program 0
|: "Gm"G,B,DG B,DFB, DFAc| BGdB GcdB | "Dm"A,EA^F A,ECF A,DFG | "Gm"Aceg a^fga|
"Cm"G,EG^D G,EGB, G,AcB | "F"CEGc Acec | "Bb"D,FAd DFAd | "Eb"G,BDG A,CEG |
"Gm"G,B,DG B,DFB, DFAc| BGdB GcdB | "Dm"A,EA^F A,ECF A,DFG | "Gm"Aceg a^fga|
"Cm"G,EG^D G,EGB, G,AcB | "F"CEGc Acec | "Bb"D,FAd DFAd | "Eb"G,BDG A,CEG:|
[K:G chromatic]
|: "G"gfed ^cbag fedc ^defg | "^(Gm)"g2z2 f2z2 e2z2 d2z2 | "^(Gm)"G,2G,2 ^G,2G,2 A,2A,2 B,2B,2 |
"^(Gm)"c=Bcd e=dcB A^GAB | "^(Gm)"B^AGF E^FGA BcdB | "^(Cm)"G4- G4:|



X:1
T:Romantic Duet
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:G
V:1 clef=treble name="Guitar" subname="Gtr."
%%MIDI program 24
|: "G"B2 G2 A2 | "Am"B4 A2 | "D7"A2 F2 G2 | "G"G6 |
"G"B2 d2 B2 | "C"E4 D2 | "Bm7"B2 "E7"A2 G2 | "Am"A6 |
"Em"G2 B2 A2 | "D7"F4 E2 | "G"D2 B,2 "C"E2 | "G"G6 |
"D7"A2 d2 c2 | "C"B4 A2 | "G"B2 G2 A2 | "G"G6 :|
V:2 clef=treble name="Strings" subname="Str."
%%MIDI program 48
|: "G"D4 G2 | "Am"A6 | "D7"F3 E D2 | "G"G6 |
"G"D2 G2 B2 | "C"C6 | "Bm7"B3 A G2 | "E7"E6 |
"Em"G4 E2 | "D7"D6 | "G"D,4 G2 | "C"C6 |
"D7"F4 A2 | "C"A6 | "G"G4 D2 | "G"G6 :|



X:1
T:Orchestral Dreams
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=84
K:Cmaj
V:1 clef=treble name="Violin I"
%%MIDI program 40
|:"C"E2 DC B,2 C2|"Dm" A,2 FG A4|"G" z2 G2 B,2 D2|"C" E2 G2 c4|
"C"cdec "G"BAGF|"Am" E2 A2 c2 A2|"F" G4 F2 E2|"G" D6 z2|
"G"B3c d2f2|"A7" e3f g2 a2|"Dm"f2d2 "G/B"e2c2|"C/G"G4 G2 z2|
"C"GABc "F"Acde|"Dm"fedc "G"B2 AG|"Am"G2 FE "F"E2 DC|"C"C6 z2:|
|:"Am"E2 AG "C"F2 ED|"G"BcdE "G7"D2 EF|"F"cAGF "C"E3 G|"F"A2 GF "G7"E2 D2|
"C"cBAG "Am"A2 B2|"G"c2 G2 "CmergeG"B2 G2|"F"AGFE "CmergeG"FDCA,|"C"G,8 :|
V:2 clef=treble name="Violin II"
%%MIDI program 40
||:"C" cEGc "Dm"d3 f|"G" e2 d2 B4|"C" cBAG "Em"G2 F2|"F" E4-D4|
"C" cEAG "G"F2 D2|"Am" B2 c2 d4|"F" A4-G4 |"G" F2 B2 G3 A|
"G" B3 c d2 G2|"A7" e2 f2 g4|"Dm" a4 f4|"C/G" g2 e2 c4|
"C" e2 d2 cBAG|"F" f2 e2 d3 C|"Dm" A3 G "F"F2 E2|"C" c4 z4:|
||:"Am" E2 EF "C" G2 GF|"G"e4 "G7"d4|"F" c2 c2 "C" B2 z2|"F" A4 "G7"G4|
"C" cEAG "Am"F3 D|"G" G2 G2 "CmergeG"B,2 z2|"F" A4 "CmergeG"G4|"C" c6 z2:|
V:3 clef=alto name="Viola"
%%MIDI program 41
|:"C" G,2 C2 E2 G2|"Dm" F2 A2 d2 f2|"G" B,2 D2 G4|"C" c2 e2 g4|
"C"cBAG "G"E2 D2|"Am" C2 A,2 E2 C2|"F" D4 C2 B,2|"G" A,6 z2|
"G"d2 G2 d2 G2|"A7" c2 A2 c2 e2|"Dm" f2 d2 f2d2|"C/G" B,2 G,2 D4|
"C" c2 G2 c2 e2|"F" d2 F2 A2 c2|"Dm" B,2 A,2 G,2 F,2|"C" G,6 z2:|
|:"Am" E2 C2 A,2 E2|"C" G,2 E2 C4|"G" B,2 G2 E4|"G7" D2 F2 A2 c2|
"F" c2 A2 F4|"C" E2 G2 c4|"F" A4 F4|"C" E,6 z2:|
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"C" C,3 E, G,2 C2|"Dm" D,3 F A,2 D2|"G" G,3 B, D2 G2|"C" C2 E2 G4|
"C"G,2 A,2 B,2 C2|"G" D4 G,2 F2|"Am" A,6 A,2|"F" A,4 F2 E2|"G" D6 z2|
"G" G,2 D2 G2 B,2|"A7" A,4 E2 c2|"Dm" D4 F2 d2|"C/G" G,4 C2 z2|
"C" C4 C2 E2|"F" F4 F2 A2|"Dm" D3 F A,2 D,2:|"C" C,8:|
|"Am" A,2 C2 G,2 C2|"C" E,2 G,2 C4|"G" G,4 G2 B,2|"G7" D2 G2 B2 D2|
"F" c3 c A,2 F2|"C" E4 G2 G2|"F" F4 C2 C2:|"C" C6 z2:|



X:1
T:Apology Accepted
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=90
K:Am
V:1 clef=treble
%%MIDI program 1
|:"Am"e2 A2 c2| "E"d2 B2 G2| "F"e2 c2 A2| "E"c4 BA|
"Am"A2 c2 E2| "Dm"FG A2 F2| "E7"G2 E2 CE| "Am"A6:|
V:2 clef=treble
%%MIDI program 41
|"Am"A,2 A,2 z2| "E"B,2 G,2 B,2| "F"C2 C2 C2| "E"E,2 E,2 E,2|
"Am"A,2 A,2 E2| "Dm"F2 D2 F2| "E7"G2 B,2 G2| "Am"A,6:|
V:3 clef=bass
%%MIDI program 42
|"Am"A,2 C2 E2| "E"G,2 B, G2| "F"A,2 C2 A,2| "E" E,4 F,2|
"Am"A,2 C2 E2| "Dm"F,2 A,2 D2| "E7"G,2 E,2 E,2| "Am"A,6:|
% Transition to C major, symbolizing resolution and forgiveness
K:Cmaj
V:1 clef=treble
|"C"c2 G2 E2| "G"D2 B,2 G2| "Am"E2 A2 c2| "F"A3/2G/2 F2E2|
"C"c2 E2 G2| "Dm"F2 D2 A2| "G"G2 B2 d2| "C"c6:|
V:2 clef=treble
|"C"E2 E2 z2| "G"D2 D2 B,2| "Am"A2 c2 e2| "F"F2 F2 A2|
"C"E2 E2 G2| "Dm"D2 A2 F2| "G"G2 B2 d2| "C"e6:|
V:3 clef=bass
|"C"C2 E2 G2| "G"G,2 B, D2| "Am"A,2 E2 A,2| "F"C2 A,2 C2|
"C"C2 E2 G2| "Dm"D,2 A,2 D2| "G"G,2 B, D2| "C"C6:|



X:1
T:Clash of Titans
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=180
K:Bm
V:1 clef=treble name="Trumpet" subname="Tpt."
%%MIDI program 56
|:"Bm" B4 F4 | "E" EF G2 AB z2 | "G" G4 DB,3 | "F#7" F4 z2 (3ABc z2 |
"Bm" d2B2 "A" c2A2 | "G"B6 B2 | "F#m"A2F2 E4 | "Bm" B8 :|
V:2 clef=treble name="Alto Sax" subname="A.Sax."
%%MIDI program 65
|:"Bm" d2f2 a4 | "E" e6 c2 | "G" Bd c2B2 z2 | "F#7" A4 z2 (3efg z2 |
"Bm" F2d2 "A" e2c2 | "G"B6 A2 | "F#m"G2E2 F4 | "Bm" B,8 :|
V:3 clef=bass name="Electric Bass" subname="E.Bass"
%%MIDI program 33
|:"Bm" B,4 F4 | "E" E2 G2 A2 B, z | "G" G,2 D2 G4 | "F#7" F,4 z2 F2 |
"Bm" d2 B,2 "A" c2 A,2 | "G"B,8 | "F#m"A,4 F4 | "Bm" B,8 :|
V:4 clef=treble name="Synth Lead" subname="Synth"
%%MIDI program 81
|:"Bm" f2f2 f4 | "E" fg a2 e2 f2 | "G" g2B2 d2 g2 | "F#7" f2 z2 (3cde z4 |
"Bm" d2b2 F2A2 | "A" E6 F2 | "G" D4 F2 E2 | "Bm" B8 :|



X:1
T:Ancient Warrior's Quest
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:Dmin
V:1 clef=treble name="Flute"
%%MIDI program 73
% Departure and setting on the journey with a strong, determined theme
|: "Dm"A2A2 GFED | "C"G,4-C4 | "Dm"D4-D2 DE | "A7"G2F2 E4 | "Dm"A4-A2 DE | "C"G,6 G2 | "Bb"F4-F2 GF | "A7"E6 z2 :|
% Encountering majestic landscapes with a change to the Lydian mode
|: "D" [F2A2] [G2B2] [A2d2] z2 | "G" [G,B,][G,B,] [G,B,][G,B,] z4 | "D" [F2A2] [G2B2] [A4d4] | "A"C4-C2 CE | "D" [F2A2] [G2B2] [A2d2] z2 | "G" [G4,B,4] [G4,B,4] | "A" [c2A2] [B2G2] [A4F4] | "D" [D4F4] [D4F4] :|
% Facing danger and uncertainty in the Phrygian mode
|: "Dm"E2F2 G4 | "C"E2C2 D4 | "Dm"A,4-A2 AB | "A7"c4-c2 cB | "Bb"d2c2 B4 | "A7"A4 A2-AA | "Dm"G4-G2 GF | "A7"E6 E2 :|
% Reclaiming the artifact and triumphant return in a heroic resolution
|: "Dm"F4 F2 FG | "C"A4 G2 FE | "Dm"D4-D2 DE | "A7"F2E2 D4 | "D"^C2D2-DEFG | "Bb"A4 G2 FE | "Dm"D4-D2 DE | "A7"F2E2 D4 | "D"A,6 A,2 :|
V:2 clef=treble name="Horn"
%%MIDI program 60
% Accompanying the flute to emphasize the main theme, starting in a lower octave
|: "Dm" D4 D2 DE | "C"C4-C2 CE | "Dm"D4-D2 DE | "A7"C2B,2 A,4 | "Dm" D4-D2 DE | "C"C6 C2 | "Bb" B,4-B2 B,C | "A7" A,6 z2 :|
% Amplifying the grandeur with harmonies in the Lydian mode
|: "D" [A,2F2] [G2D2] [A,2D2] z2 | "G" [B,,2G,2][B,,2G,2][B,,2G,2][B,,2G,2] | "D" [A,2F2] [G2D2] [A,4D4] | "A" G,4-G,2 BG | "D" [A,2F2] [G2D2] [A,2D2] z2 | "G" [G,4B,,4] [G,4B,,4] | "A" [F2D2] [E2C2] [F4A,4] | "D" [D4F4] [D4F4] :|
% Painting an atmosphere of tension and suspense in the Phrygian mode
|: "Dm" A,4 A,2 B,A, | "C" G,4-G,2 G,F | "Dm" D2D2 D4 | "A7" C2B,2 C4 | "Bb" B,,4-B,2 B,C | "A7" A,4 A,2-AA | "Dm" F4-F2 FE | "A7" A,6 A,2 :|
% Reinforcing the final powerful theme of victory
|: "Dm" D4 D2 DE | "C" E4 D2 DC | "Dm" D4-D2 DE | "A7" D2^C2 D4 | "D" A,2A,2-ABAG | "Bb" G4 F2 ED | "Dm" D4-D2 DE | "A7" D2E2 D4 | "D" F,6 F,2 :|
V:3 clef=bass name="Cello"
%%MIDI program 42
% Providing a rhythmic and harmonic foundation for the journey's outset
|: "Dm" D,,4 D,4 | "C" C,,6 C,2 | "Dm" D,,4 D,4 | "A7" A,,4 A,,4 | "Dm" D,,4 D,4 | "C" C,,6 C,2 | "Bb" B,,,4 B,,,4 | "A7" A,,4 A,,4 :|
% Embracing the wondrous landscapes with sustained notes and environment
|: "D" [F,,2A,,2] [F,,2A,,2] [F,,2A,,2] z2 | "G" G,,,6 G,,2 | "D" [F,,2A,,2] [F,,2A,,2] [D,,4F,,4] | "A" C,4-C,2 C,E | "D" [F,,2A,,2] [F,,2A,,2] [F,,2A,,2] z2 | "G" G,,,4 G,,,4 | "A" [A,,2F,2] [A,,2F,2] [A,,2F,2] z2 | "D" D,,4 D,,4 :|
% The uncertainty and challenges faced in darker regions with a driving bass line
|: "Dm" D,,4 D,4 | "C" C,,4 C,4 | "Dm" D,,4 D,4 | "A7" A,,4 A,,4 | "Bb" B,,,4 B,,,4 | "A7" A,,4 A,,4 | "Dm" D,,4 D,4 | "A7" A,,4 A,,4 :|
% Celebrating the artifact's reclaiming and triumphant homecoming with stronger bass
|: "Dm" D,,4 D,4 | "C" C,,4 C,4 | "Dm" D,,4 D,4 | "A7" A,,4 A,,4 | "D"^C,,4 C,4 | "Bb" B,,,4 B,,,4 | "Dm" D,,4 D,4 | "A7" A,,4 A,,4 | "D" D,,4 D,,4 :|



X:1
T:Ravelian Reflections
C:OrchestrAI
M:5/4
L:1/16
Q:1/4=60
K:Bmaj
V:Violin1 clef=treble name="Violin I"
%%MIDI program 40
|: "B"[FB]8 [ec][df][ce][bf] | "E"[GB]3 [FA] [E6B6] z4 | "C#m"[EB]2 [DB][CA], [C,G,B,E]8 | "B"[FB,]4 [EC]4 [DF][CE][BF][AF] |
[1 "G#m"[B,G]4 [C#F]4 [BD][AC][EB,G,][DF,A,] :|2 "G#m"[B,G]4 [C#F]4 [BD][AC][EB,G,][DF,A,] | "F#" z4 [EC][FB],[EC] [F,B,D]6 |]
M:7/4
|: "B"[FB]4 [ec]4 [df]4 [ce]4 [bf]4 | "E"[GB]3 [FA] [E2B2] [GB][AF] [GB][AF] z2 | "C#m"[EB]2 [DB][CA], [C,G,B,E]8 [EB]4 [DB][CA], |
[1 "B"[FB,][Ec][DF][CE][BF][AF] [GB][AF][EB][DB] z2 :|2 "B"[FB,][Ec][DF][CE][BF][AF] [GB][AF][EB][DB] z2 | "F#" z4 [EC][FB],[EC] [F,B,D]4 [F8B8] |]
M:9/8
V:Violin2 clef=treble name="Violin II"
%%MIDI program 40
|: "B"[eB]8 [ad][ge][fd][ec] | "E"[aB]3 [gB] [e6B4] z4 | "C#m"[aE]2 [gD][fC] [e,A,C,E]8 | "B"[eB,]4 [aC]4 [gD][fE][eB][dB] |
[1 "G#m"[eB,]4 [aF]4 [gB][fA][eC,G,][fB,D] :|2 "G#m"[eB,]4 [aF]4 [gB][fA][eC,G,][fB,D] | "F#" z4 [aC][gB],[aC] [e,B,G#]6 |]
M:11/8
|: "B"[eB]4 [ad]4 [ge]4 [fd]4 [ec]4 | "E"[aB]3 [gB] [e2B2] [aB][gB] [eA][dB] z2 | "C#m"[aE]2 [gD][fC] [e,A,C,E]8 [aE]4 [gD][fC] |
[1 "B"[eB,][aC][gD][fE][eB][dB] [eA][gB][aC][dB] z2 :|2 "B"[eB,][aC][gD][fE][eB][dB] [eA][gB][aC][dB] z2 | "F#" z4 [aC][gB],[aC] [e,B,G#]4 [e8B8] |]
M:13/8
V:Viola clef=alto name="Viola"
%%MIDI program 41
|: "B"[dB]8 [gB][aB][bB][c'e'] | "E"[gB]3 [fA] [d6B4] z4 | "C#m"[gE]2 [fD][eC] [d,G,B,E]8 | "B"[dB,]4 [gC]4 [aD][bE][cB][B,A] |
[1 "G#m"[dB,]4 [gF]4 [aB][bA][cE,G,][dF,A,] :|2 "G#m"[dB,]4 [gF]4 [aB][bA][cE,G,][dF,A,] | "F#" z4 [gC][aB],[gC] [d,B,F#]6 |]
M:15/8
|: "B"[dB]4 [gB]4 [aB]4 [bB]4 [c'e']4 | "E"[gB]3 [fA] [d2B2] [gB][fA] [gB][fA] z2 | "C#m"[gE]2 [fD][eC] [d,G,B,E]8 [gE]4 [fD][eC] |
[1 "B"[dB,][gC][aD][bE][cB][B,A] [gB][fA][gE][fD] z2 :|2 "B"[dB,][gC][aD][bE][cB][B,A] [gB][fA][gE][fD] z2 | "F#" z4 [gC][aB],[gC] [d,B,F#]4 [d8B8] |]
M:5/4
V:Cello clef=bass name="Cello"
%%MIDI program 42
|: "B"[B,B]8 [eB][fB][gB][aB] z | "E"[B,G]3 [A,F] [E6B3]  | "C#m"[E,E]2 [F,D][G,C] [B,,G,B,E]8 z2 | "B"[B,B,]4 [E,C]4 [F,D][G,E][A,B][B,G] |
[1 "G#m"[B,B,]4 [E,F]4 [G,B][A,A][B,E,C,][A,F,D,] :|2 "G#m"[B,B,]4 [E,F]4 [G,B][A,A][B,E,C,][A,F,D,] | "F#" z4 [E,C][F,B,],[E,C] [B,,A,#] |]
M:7/4
|: "B"[B,B]4 [eB]4 [fB]4 [gB]4 [aB]4 | "E"[B,G]3 [A,F] [E2B2] [B,G][A,F] [B,G][A,F] z2 | "C#m"[E,E]2 [F,D][G,C] [B,,G,B,E]8 [E,E]4 [F,D][G,C] |
[1 "B"[B,B,][E,C][F,D][G,E][A,B][B,G] [B,G][A,F][E,E][F,D] z2 :|2 "B"[B,B,][E,C][F,D][G,E][A,B][B,G] [B,G][A,F][E,E][F,D] z2 | "F#" z4 [E,C][F,B,],[E,C] [B,,A,F#]4 [B8B,8] |]
% Repeat with recapitulation and development of themes as desired



X:1
T:Winter's Enchantment
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=84
K:Em
V:1 clef=treble name="Glockenspiel"
%%MIDI program 9
|:"Em" B4 Bc  | "Cmaj9"G4(3 GFG E2 | "Am7" A4 AB  | "B7" F4 (3FFE D2|
"Em" E4 (3EFE DB, | "Cmaj9" G,4- G2  | "Am7" A2 AF (3EFE z2 | "B7" (3B,AB G4 z2|
"Em" B4 (3gag fe | "C" e4- (3edc B2 | "G" G2 (3BAG E2 G2 | "D" F4- F2 E2|
"Am" A4 (3aaa ge | "Cmaj9"(3efe d2 (3cdc z2 B2| "G" (3Bcd e2 (3dcB A2 z2 | "B7" B6 z2:|
V:2 clef=treble name="Strings"
%%MIDI program 48
|:"Em" [B,2E2G2] [B,2E2G2] z2 | "Cmaj9" [C2E2G2] [A,2C2E2] z2 | "Am7" [A,2A2E2] [A,2A2E2] z2 | "B7" [B,2D2F2] [B,2D2F2] z2 |
"Em" [B,2E2G2] (3^GAG F2 z2 | "Cmaj9" [G,2E2C2] z2 AB | "Am7" [A,2A2E2] F2 E2 | "B7" (3B,2D2A,2 z (3F2G,2A,2|
"Em" [B,2G2B2] (3^cde d2 z2 | "C" [E2G2C2] z2 (3Bcd z2 | "G" [D2G2B2] (3AB=c B2 z2 | "D" [A2D2F2] z2 E2 |
"Am" [E2A2c2] (3eaa z2 g2| "Cmaj9" (3^fed c2 (3edc| "G" [D2G2] e2 (3dcB z2 | "B7" [D2F2] z4:|
V:3 clef=bass name="Celli"
%%MIDI program 42
|:"Em" G,4 B,2 E2 | "Cmaj9" E3 G C2 E2| "Am7" A,2 A,2 E2 E2 | "B7" D2 F2 D2 B,2|
"Em" E4 E2 G2| "Cmaj9" C3 E G3 A| "Am7" A,4- A2 E2 | "B7" F4 B,2 D2|
"Em" [E2G2B,2] z2 E2| "C" [E2G2C2] B,2 C2| "G" [D2G2B,2] G,2 B,2 | "D" [A,2D2F2] F2 A2|
"Am" [A,2E2C2] E2 c2| "Cmaj9" [C2E2G2] E2 G2| "G" [G,2B2D2] D2 G2| "B7" [B,2D2F2] B,2 z2:|



X:1
T:Boss Battle Blitz
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=240
K:Bmin
V:1 clef=treble name="Synth Lead" subname="Lead"
%%MIDI program 81
|:"Bm"B4 F4 B4 d2|"F#m"c2A2 F2E2 D2C2|"G" B2B2 G2F2 E4|"A"A4 c4 F4 A2|
"Bm"B4 F4 B4 d2|"F#m"c2A2 F2E2 D2C2|"E"G4 E4 C4 G,4|"A"A,2A,2 F2E2 D4|
"Bm"F6 G2 A4 F2| "G"B2G2 F2E2 D4 | "A"c4 E4 "F#"F4 B,4 | "Bm"B,2B,2 d2c2 B4 :|
V:2 clef=treble name="Distorted Guitar" subname="Gtr."
%%MIDI program 30
|:"Bm"B2f2 B2f2 d6|"F#m"a4 f4 e4 c4|"G"b2b2 g2f2 e8|"A"e4 a4 f8|
"Bm"B2f2 B2f2 d6|"F#m"a4 f4 e4 c4|"E"g2g2 e2c2 G6|"A"F2E2 D2C2 B2A2 G2F2|
"Bm"F4 F4 G4 A4|"G"B4 G2F2 E8|"A"c4 E2E2 "F#"f2d2 B8|"Bm"B8 d8 c8 B8:|
V:3 clef=bass name="Electric Bass" subname="Bass"
%%MIDI program 34
|:"Bm"B,2 B,2 F2 A2 B4|"F#m"A,2 C2 F2 E2 D8|"G"B,2 B,2 G2 F2 E4|"A"A,2 C2 F2 A,2 E,4|
"Bm"B,2 B,2 F2 A2 B4|"F#m"A,2 C2 F2 E2 D8|"E"G,4 E4 C8|"A"A,,2 A,,2 F,2 E,2 D4|
"Bm"F,2 F,2 G,2 A,2 B,4|"G"B,2 G2 F2 E2 D4|"A"C2 E2 "F#"F,2 B,,2 B,8|"Bm"B,,4 B,,4 d4 c8:|



X:1
T:Velocity Vortex
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=140
K:Cmin
% The electronic dance foundations with driving rhythms
V:1 clef=treble name="Synth Lead" subname="Lead"
%%MIDI program 81
|: "Cm"G7-  | E7-  | G4 G4   | G7-  |
"Cm"G7-  | E7-  | "G"G4 G4   | "Cm"G7-  :|
% A powerful bassline reinforcing the EDM vibe
V:2 clef=bass name="Synth Bass" subname="Bass"
%%MIDI program 38
|: "Cm"C,16 | C,16 | "G"G,,16 | "Cm"C,16 |
"Cm"C,16 | C,16 | "G"G,,16 | "Cm"C,16 :|
% Orchestral strings to bring in the grandeur
V:3 clef=treble name="Strings" subname="Stg."
%%MIDI program 48
|: "Cm"G,16 | E16 | G,8   | G,16 |
"Cm"G,16 | E16 | G,8^\frmt ""-  | "Cm"G,16 :|
% Brass for that epic, thrilling punch
V:4 clef=treble name="Brass" subname="Brs."
%%MIDI program 61
|: "Cm"G4 z3  | E4 z12 | G4 z4 G    | G4 z12 |
"Cm"G4 z3  | E4 z12 | "G"G4 z4 G    | "Cm"G4 z12 :|
% Pizzicato strings to add rhythmic texture
V:5 clef=treble name="Pizz. Strings" subname="Pizz."
%%MIDI program 45
|: "Cm"z4 G4   | z4 E4   | z2 G4 z3    | z4 G4   |
"Cm"z4 G4   | z4 E4   | "G"z2 G4 z3    | "Cm"z4 G4   :|



X:1
T:Velocity Vortex
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=200
K:Bmin
V:1 clef=treble name="Synth Lead" subname="Lead"
%%MIDI program 81
|:"Bm"B4f4B4f4|"A"c4A4c4e4|"G"f4d4B4d4|"F#m"a4f4d4f4|
"Bm"B2A2F2B2G2d2F z22|"A"A2G2E2c2G2B2E z22|"G"F2E2D2F2A2d2c z22|"F#m"F2E2D2C2A,2F2E z22|
"Bm"B4f4B4f4|"A"c4A4c4e4|"G"f4d4B4d4|"F#m"a4f4d4f4|
"Bm"B8- B2A2B2c2|"A"A8- A2G2A2B2|"G"G8- G2F2G2A2|"F#m"F8- F2E2F2G2:|
V:2 clef=treble name="Brass" subname="Brass"
%%MIDI program 61
|:"Bm"B,2D2F2B,2D2F z42|"A"A,2C2E2A,2C2E z42|"G"G,2B,2D2G,2B,2D z42|"F#m"F,2A,2C2F,2A,2C z42|
"Bm"B,2F2B,2D2A,2D z42|"A"A,2E2A,2C2G2E z42|"G"G,2D2G,2B,2F2D z42|"F#m"F,2C2F,2A,2E2C z42|
"Bm"B,2D2F2B,2D2F z42|"A"A,2C2E2A,2C2E z42|"G"G,2B,2D2G,2B,2D z42|"F#m"F,2A,2C2F,2A,2C z42|
"Bm"B,,2B,2B2F2B, z44|"A"A,,2A,2A2E2A, z44|"G"G,,2G,2G2D2G, z44|"F#m"F,,2F,2F2C2F,4 z4:|
V:3 clef=bass name="Synth Bass" subname="Bass"
%%MIDI program 38
|:"Bm"B,16|"A"A,16|"G"G,16|"F#m"F,16|
"Bm"B,4A,4B,4A,4|"A"A,4G,4A,4G,4|"G"G,4F,4G,4F,4|"F#m"F,4E,4F,4E,4|
"Bm"B,16|"A"A,16|"G"G,16|"F#m"F,16|
"Bm"B,,4B,4B,4B,4|"A"A,,4A,4A,4A,4|"G"G,,4G,4G,4G,4|"F#m"F,,4F,4F,4F,4:|



X:1
T:Apocalyptic Carnival
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=140
K:Am
V:1 clef=treble
%% MIDI program 71
|:"Am"e2AA e2c2|"Dm"d2AA FGAF|"E7"E2G2 B2^G2|"Am"A4 z4|
"A"^A2A^c A2c2|"E7"B2d2 dAB^G|"Am"A^cAc A^cde|"E7"fedc BAG^F|
"Am"e2c2 c2BA|"Dm"A2F2 F2A^G|"Am"A2e2 c2A2|"E7"^G2Bd eedc|
"Am"A2A^c A2c2|"E7"B2d2 dcBA|"Am"A4-|A8:|
V:2 clef=bass
%% MIDI program 43
|"Am"A,4-|A,4|"Dm"D,4-|D,4|"E7"=G,4-|=G,4|"Am"A,4-|A,4|
"A"^C4-|^C4|"E7"B,,4-|B,,4|"Am"A,4-|A,4|"E7"^G,4-|^G,4|
"Am"A,4-|A,4|"Dm"D,4-|D,4|"Am"A,2^C2 E2A,2|"E7"^G,2B,2 =G,4|
"Am"A,4-|A,4|"E7"B,,4-|B,,4|"Am"A,4-|A,8:|



X:1
T:Frolic of the Whims
C:OrchestrAI
M:C
L:1/8
Q:1/4=120
K:Cmaj
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "C" G4 E2 C2 | "Am" A3/2B/2 c2 A2 | "F" F4- F G A2 | "G" G4- G2 z2 |
"C" e2 c2 "Dm" d3/2e/2 f2 | "Em" e3/2f/2 g2 e2 | "F" c2 A2 "G" G4 | "C" c4- c2 z2 :|
V:2 clef=treble name="Violin"
%%MIDI program 40
M:3/4
|: "C" E3 G2 A | "Am" A2 B2 c2 | "F" A2 G2 ^F2 | "G" G4 z2 |
"C" e2 c2 "Dm" A4 | "Em" G2 F2 E2 | "F" C4 D2 | "G" G,4 z2 :|
V:3 clef=treble name="Accordion"
%%MIDI program 21
M:5/4
|: "C" c4 C4 E2 | "Am" A4 A4 c2 | "F" f4 F4 A2 | "G" B,4 G,4 z2 |
"C" e4 E4 G2 | "Dm" F4 A3 D | "Em" G4 B,4 E | "F" C4 c4 z2 :|
V:4 clef=bass name="Cello"
%%MIDI program 42
M:C
|: "C" C,4 C,2 C,2 | "Am" A,4 A,2 A,2 | "F" F,4 F,2 F,2 | "G" G,4 G,2 G,2 |
"C" C4 E4 | "Dm" D4 F4 | "Em" E,4 E,2 E,2 | "F" F,6 | "G" G,6 :|



X:1
T:Cyber Pursuit
C:OrchestrAI
M:7/8
L:1/16
Q:1/4=172
K:Am
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|: "Am"A4 z4 cBA2G2 | "G"B4 z4 d2B2c2 | "F"E4 z2A2 ^G2A4 | "Em"e4 z2A2 B4 A2 |
"Am"A,2C4 E4 A4 | "G" B2G4 F4 D2C2 | "F"F4 ^G4 A4 c3 | "Em"B2A2 G4 E4 C3 :|
V:2 clef=treble name="Arp Synth"
%%MIDI program 82
|: "Am"C2A,2E2 A4 z4 | "G"D2B,2G2 B4 z4 | "F"F2C2C2 F4 z4 | "Em"E2B,2E2 B4 z4 |
"Am"A2C2E2 A4 z4 | "G"G2D2B,2 B4 z4 | "F"F2^G2F2 A4 z4 | "Em"E2B2A2 E4 z4 :|
V:3 clef=bass name="Bass Synth"
%%MIDI program 87
|: "Am"A4 z8 z2 | "G"B,4 z8 z2 | "F"F4 z8 z2 | "Em"E4 z8 z2 |
"Am"A,2C4 z8 | "G"G,2D4 z8 | "F"F2 ^G4 z8 | "Em"E2B4 z8 :|
V:4 clef=perc name="Synth Drum"
%%MIDI program 118
|: z16 | z16 | z16 | z16 | z16 | z16 | z16 | z16 :|



X:1
T:Labyrinth of Echoes
C:OrchestrAI
M:5/4
L:1/8
Q:1/4=100
K:Cmin
V:1 clef=treble name="Piano RH" subname="Piano"
%%MIDI program 0
|:"Cm"G,2 C2 E2 G2 z2|"_G#maj7"G,2 B,2 E2 G2 z2|"_D7"A,2 D2 F2 A2 z2|"_G7sus4"G,2 C2 F2 G2 z2|
"Cm"E2 G2 C3 z2 D|"_G#maj7"G,2 B,2 E3 z2 F|"_D7"F2 A2 D3 z2 E|"_G7sus4"F2 B,2 G,3 z2 A,|
"Cm"G,2 C2 E2 G2 z2|"_G#maj7"G,2 B,2 E2 G2 z2|"_D7"A,2 D2 F2 A2 z2|"_G7sus4"G,2 C2 F2 G2 z2|
"Cm"E2 G2 z2 C4|"_G#maj7"E2 G2 z2 B,4|"_D7"F2 A2 z2 D4|"_G7sus4"F2 G2 z2 B,4:|
V:2 clef=bass name="Piano LH" subname="Piano"
%%MIDI program 0
|:"Cm"C,4 E,2 G,2 C2|"_G#maj7"G,4 B,,2 D2 G2|"_D7"A,4 F,2 A,2 D2|"_G7sus4"G,4 C2 E,2 G,2|
"Cm"C,6 E,2 G,2|"_G#maj7"G,6 B,,2 D2|"_D7"A,6 F,2 A,2|"_G7sus4"G,6 C2 E,2|
"Cm"C,4 E,2 G,2 C2|"_G#maj7"G,4 B,,2 D2 G2|"_D7"A,2 D2 F2 A2 z2|"_G7sus4"G,4 C2 E,2 G,2|
"Cm"C,4 z2 E,4|"_G#maj7"G,4 z2 B,,4|"_D7"A,4 z2 F,4|"_G7sus4"G,4 z2 C4:|



X:1
T:Balkan Spring Thaw
C:OrchestrAI
M:7/8
L:1/8
Q:2/4=120
K:Dm
% The melody uses a modal scale to give it that Eastern European feel
V:1
%%MIDI program 111
|: "Dm"A2 ^G2 A3 | "A7"c2 A2 F3 | "Dm"D2 F2 A3 | "C"G6 A |
"Dm"A2 ^G2 A2 F | "A7"E2 C2 A,3 | "Dm"D6 A |1 "C"G6 F :|2 "C"G6 E |
% This part contains more movement to reflect the bustling activity of nature
V:1
%%MIDI program 111
|: "Dm"A2^c2d3 | "A7"e2 c2 A3 | "Dm"f2 A2 d3 | "C"e6 ^g |
"Dm"a2 f2 e3 | "A7"c3 A2 ^G | "Dm"A4 F2 A |1 "C"G6 E :|2 "C"G6 z |
% Repeat the tune as often as desired before closing with a final statement



X:1
T:Brass United
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:C
% Trumpet part
V:1 clef=treble
%%MIDI program 56
|"C"G4 E4|"F"A4 c4|"G"G,4 D4|"C"E4 G4|
"C"e3d c2B2|"F"f4 e4|"G"d4 B2G2|"C"c4 z4|
% Trombone part
V:2 clef=bass
%%MIDI program 57
|"C"C4 E,4|"F"A,4 A,4|"G"B,4 D4|"C"C4 E4|
"C"e4 c4|"F"f3e d2c2|"G"B2A2 G4|"C"C4 z4|
% Tuba part
V:3 clef=bass
%%MIDI program 58
|"C"C,2G,,2 C,2G,,2|"F"F,,2C,2 F,2C,2|"G"G,,2D,2 G,2D,2|"C"C,2G,,2 C,2G,,2|
"C"C,4 C,4|"F"F,,4 F,,4|"G"G,,4 G,,4|"C"C,4 z4|



X:1
T:Sinfonietta Fantasia
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Gm
% The introduction sets a mysterious tone
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "Gm" d2G2 B2A2 | "Dm" ^F2A2 A2D2 | "Bb" F2d2 c2BA | "F" A2A2 G2z2 |
| "Eb" G2F2 E2DC | "Cm" D2F2 G2E2 | "Bb" F2D2 E2C2 | "D7" D4 A2A2 :|
V:2 clef=treble name="Clarinet"
%%MIDI program 71
|: "Gm" b2d2 b2a2 | "Dm" f2f2 d'2d2 | "Bb" a2f2 e2dc | "F" d2e2 g2z2 |
| "Eb" e2f2 g2dc | "Cm" c2d2 e2g2 | "Bb" f2b2 a2f2 | "D7" d4 b2b2 :|
V:3 clef=alto name="Viola"
%%MIDI program 41
|: "Gm" g2e2 d2b2 | "Dm" a2g2 f2d2 | "Bb" e2f2 d2b2 | "F" c2c2 a4 |
| "Eb" b2g2 e2d2 | "Cm" g2g4 c2 | "Bb" f2d2 e2c2 | "D7" d4 f2e2 :|
V:4 clef=bass name="Cello"
%%MIDI program 42
|: "Gm" G,2B,2 D,2G,2 | "Dm" A,2D,2 D,2G,2 | "Bb" B,2F,2 E,2C,2 | "F" C,4 A,4 |
| "Eb" E,2G,2 A,2B,2 | "Cm" C,2E,2 G,2C,2 | "Bb" B,2F,2 A,2D2 | "D7" D,4 F,2G,2 :|



X:1
T:Bucket By The River
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:G
V:1 clef=treble name="Flute"
%%MIDI program 73
% Verse 1
"G" B2 |:d3 B G2|A4 B2|d2 cB AB|G6|
"D7" A2 B2 A2|"G"B6-|B2 d2 B2 |1 "Em"E6 :|2 "Em"E4 B2 |]
% Pre-Chorus
"D"d4 F2|"C"G4 B2|"Am"A2 G2 F2|"D"D4 B,2|
"C"E6-|E2 D2 E2|"D"F2 E2 D2|"G"G4 B2|
% Chorus
"G"d4 B2|"D7"A4 F2|"G"G3 A B2|d6|
"C"e4 d2|"G"d3 B A2|"Em"B4 A2|"D7"A4 F2|
"G"G3 A B2|"D7"A4 d2|"C"e4 d2|"G"B3 A G2|G6|
% Bridge
"Em"B2|"Am"A3 G F2|"Em"E4 E2|"B7"B4 A2|
"D"F2|"G"G3 A B2|"C"E6|"D7"D4 F2|
"Em"E2|"Am" A4 G2|"Em"B3 A G2|"D7"F4 B,2|
% Breakdown
"Em"E2|"D"E4 F2|"G"G3 A B2|"C"E6|
"Am"E2 D2|"D"D4 E2|"G"B6|B4 z2|
% Chorus
"G"d4 B2|"D7"A4 F2|"G"G3 A B2|d6|
"C"e4 d2|"G"d3 B A2|"Em"B4 A2|"D7"A4 F2|
"G"G3 A B2|"D7"A4 d2|"C"e4 d2|"G"B3 A G2|G6|
% Outro
"G" B2|"D7"A6|"C"G6|"G"B4 d2|
"D7"A4 F2|"G"G4 E2|"C"E6|"D7"A,4 D2|
"G"G4 B2|"D7"A6|"G"G6|"C"e4 d2|
"G"d3 B A2|"D7" F6|"G"G8||



X:1
T:Study Boost
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=135
K:Cm
V:1 clef=treble name="Electric Piano"
%%MIDI program 5
|: "Cm" G3E G2c2 | "Ab" c3B c2e2 | "Fm" A3G A2c2 | "G7" B3A B2d2 |
"Cm" e3d c2G2 | "Ab" B3A B2c2 | "Fm" A3G F2A2 | "G7" (3GAB (3cde g2 G2 z4 :|
V:2 clef=treble name="Synth Lead"
%%MIDI program 81
|: "Cm" C3E G3c | "Ab" E3G c3e | "Fm" F3A c3f | "G7" G3B d3f |
"Cm" G3c e3g | "Ab" E3G c3e | "Fm" F3A c3f | "G7" (3def (3gab g'2 G2 z4 :|
V:3 clef=bass name="Synth Bass"
%%MIDI program 39
|: "Cm" C,3G, C3G, | "Ab" A,,3E, A,3E, | "Fm" F,,3C, F,3C, | "G7" G,,3D, G,3D, |
"Cm" C,3G, C3G, | "Ab" A,,3E, A,3E, | "Fm" F,,3C, F,3C, | "G7" G,,3D, G,3B, :|
V:4 clef=bass name="Synth Pad"
%%MIDI program 91
|: "Cm" [C,2E,2G,2] [C,2E,2G,2] [C,2E,2G,2] [C,2E,2G,2] | "Ab" [A,,2C,2E,2] [A,,2C,2E,2] [A,,2C,2E,2] [A,,2C,2E,2] |
"Fm" [F,,2A,,2C,2] [F,,2A,,2C,2] [F,,2A,,2C,2] [F,,2A,,2C,2] | "G7" [G,,2B,,2D,2] [G,,2B,,2D,2] [G,,2B,,2D,2] [G,,2B,,2D,2] :|



X:1
T:Fantasy Village Folksong
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:G
V:1 clef=treble name="Flute" subname="Fl."
%%MIDI program 73
|:"G"B3 d c2|"C"e4 d2|"Am"A3 B c2|"D7"B4 A2|
"G"G3 A B2|"Em"e4 d2|"C"E3 F G2|"D7"F4 D2|
"G"B3 d c2|"C"e4 d2|"Am"A4 g2|"D7"f4 e2|
"G"d2 B2 G2|"C"c2 A2 F2|"G"G6-|"G" G4 z2:|
V:2 clef=treble name="Accordion" subname="Acc."
%%MIDI program 21
|:"G"D2 G4|"C"E2 C4|"Am"A2 c4|"D7"d2 f4|
"G"g2 d4|"Em"e2 B4|"C"E2 C4|"D7"A2 F4|
"G"D2 G4|"C"E2 C4|"Am"c2 e4|"D7" f3 e d2|
"G"d2 B2 G2|"C"c2 A2 F2|"G"G4 z2|"G"G4 z2:|
V:3 clef=treble name="Harp" subname="Hp."
%%MIDI program 46
|:"G"G,2 B,2 D2|"C" c2 E2 G2|"Am"A,2 C2 E2|"D7"F2 A2 D2|
"G"G,2 B,2 D2|"Em"E2 G2 B2|"C"E,2 G2 C2|"D7"D2 F2 A2|
"G"G,2 B,2 D2|"C" c2 E2 G2|"Am"A2 C2 E2|"D7"F2 A2 D2|
"G"G,2 B,2 D2|"C" c2 E2 G2|"G"G,4 z2|"G"G,4 z2:|
V:4 clef=bass name="Double Bass" subname="Db."
%%MIDI program 43
|:"G"G,,4 B,,2|"C"C,4 E,,2|"Am"A,,4 C,,2|"D7"D,4 F,,2|
"G"G,,4 B,,2|"Em"E,4 G,,2|"C"C,4 E,,2|"D7"D,4 F,,2|
"G"G,,4 B,,2|"C"C,4 E,,2|"Am"A,,4 C,,2|"D7"D,4 F,,2|
"G"G,,2 B,,2 D,2|"C"C,2 E,,2 G,2|"G"G,4 z2|"G"G,4 z2:|



X:1
T:Grand Ballroom Waltz
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=90
K:A
% Leading voice, carrying the melody with elegance
V:1 clef=treble
%%MIDI program 42
|: "A"A2 ^c2 e2 | "E7"B4 B2 | "A"A2 e2 ^g2 | "F#m"f4 e2 |
"D"A2 f2 a2 | "Bm"f4 e2 | "E7"B2 e2 ^g2 | "A"a6 :|
|: "A"a2 ^c'2 b2 | "E7"g4 f2 | "A"a2 e2 ^c2 | "F#m"f4 e2 |
"D"d2 ^f2 e2 | "Bm"d4 c2 | "E7"B2 ^G2 F2 | "A"A6 :|
V:2 clef=treble
%%MIDI program 42
|: "A"e2 e2 e2 | "E7"^g2 f2 e2 | "A"a2 a2 a2 | "F#m"c2 B2 A2 |
"D"f2 f2 f2 | "Bm"A2 ^G2 F2 | "E7"E2 E2 E2 | "A"E2 ^C2 E2 :|
|: "A"A2 A2 ^c2 | "E7"A2 A2 G2 | "A"^C2 E2 A2 | "F#m"E2 D2 C2 |
"D"^F2 D2 B2 | "Bm"A2 F2 D2 | "E7"^G2 E2 ^C2 | "A"A2 E2 A2 :|
% The Bass voice, providing a solid base for the composers
V:3 clef=bass
%%MIDI program 43
|: "A"A,2 A,2 A,2 | "E7"B,2 E,2 ^G,2 | "A"A,2 C2 E2 | "F#m"F,2 A,2 c2 |
"D"D2 D2 D2 | "Bm"F,2 B,2 d2 | "E7"E2 E2 E2 | "A"A,2 E,2 A,2 :|
|: "A"=C2 =C2 A,2 | "E7"B,2 B,2 G,2 | "A"E2 E2 A,2 | "F#m"A,2 C2 F2 |
"D"=C2 =C2 D2 | "Bm"B,2 E2 B,2 | "E7"^G,2 B,2 e2 | "A"A,2 =C2 E2 :|



X:1
T:The Clockwork Carnival
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=160
K:G
% Introduction - Setting the lively carnival atmosphere
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G" G2 B2 d2 g2 | "C" e2 g2 c'2 e'2 | "D" f#2 a2 d'2 f#2 | "G" g4 z4 |
% Melody - Energetic and rhythmic
V:2 clef=treble name="Clarinet"
%%MIDI program 71
|:"G" B2 d2 g2 b2 | "C" c'2 e'2 g'2 e'2 | "D" a2 f#2 d2 a2 | "G" b4 z4 |
% Harmony - Supporting the main melody with harmonic richness
V:3 clef=alto name="Trumpet"
%%MIDI program 56
|:"G" D3 B A G2 z | "C" G3 E D C2 z | "D" A3 F# E D2 z | "G" G4 z4 |
% Bass Line - Driving rhythm and foundation
V:4 clef=bass name="Acoustic Bass"
%%MIDI program 32
|:"G" G,2 G,2 D,2 G,2 | "C" C,2 E,2 G,2 C,2 | "D" D,2 F#,2 A,2 D,2 | "G" G,4 z4 |
% Development - Introducing counter melodies and rhythmic variations
V:1
|:"Em" e2 g2 b2 e2 | "A" a2 c#2 e2 a2 | "Bm" b2 d2 f#2 b2 | "C" c2 e2 g2 c2 |
V:2
|:"Em" g2 b2 e3 d | "A" c#2 e2 a3 g | "Bm" d2 f#2 b3 a | "C" e2 g2 c3 b |
V:3
|:"Em" B2 e2 g2 B2 | "A" E2 A2 c#2 E2 | "Bm" F#2 B2 d2 F#2 | "C" G2 C2 E2 G2 |
V:4
|:"Em" E,2 E,2 B,2 E,2 | "A" A,2 A,2 E,2 A,2 | "Bm" B,2 B,2 F#,2 B,2 | "C" C,2 C,2 G,2 C,2 |
% Climax - Building up to the energetic peak
V:1
|:"G" g2 a2 b2 d2 | "C" e2 f2 g2 e2 | "D" a2 b2 a2 f#2 | "G" g4 z4 |
V:2
|:"G" b2 c2 d2 f#2 | "C" g2 a2 f2 e2 | "D" f#2 e2 d2 c#2 | "G" b4 z4 |
V:3
|:"G" d2 e2 f#2 d2 | "C" g2 a2 g2 e2 | "D" a2 b2 a2 f#2 | "G" d4 z4 |
V:4
|:"G" G,2 A,2 B,2 G,2 | "C" C,2 D,2 C,2 E,2 | "D" D,2 E,2 D,2 F#,2 | "G" G,4 z4 |
% Conclusion - Grand finale with all voices harmonizing
V:1
|:"G" G4 B4 | "C" C4 E4 | "D" D4 F#4 | "G" G4 z4 |
V:2
|:"G" B4 d4 | "C" E4 g4 | "D" A4 f#4 | "G" B4 z4 |
V:3
|:"G" D4 B4 | "C" G4 E4 | "D" A4 F#4 | "G" D4 z4 |
V:4
|:"G" G,4 G,4 | "C" C,4 C,4 | "D" D,4 D,4 | "G" G,4 z4 |



X:1
T:Victory March
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Gmaj
% The composition will transport the listener to a scene of jubilant celebration.
V:1 clef=treble
%%MIDI program 61
|:"G"D2 B2 G2B2|"C"E2 G2 C3 D|"D7"D2 F2 A3 G|"G"G6 z2|
"G"B2d2 "D7"F2A2|"G"G2 B2 "Em"B3 c|"A7"A2 e2 c2A2|"D"D6 z2|
"G"D3 E "C"G2A2|"D"B4 A3 G|"G"G2D2 GABc|"A7"d2c2 B4|
"D"A2F2 D3 C|"G"B,2G2 "E7"G2F2|"Am"A4 G2F2|"D7"E4 D4|
"G"D2 B2 G2B2|"C"E2 G2 C3 D|"D7"D2 F2 A3 G|"G"G6 z2:|
V:2 clef=treble
%%MIDI program 49
|:"G"G,2 G,2 G,2G,2|"C"E,2 E,2 E,2E,2|"D7"F,2 F,2 F,2F,2|"G"G,6 z2|
"G"B,2 D2 G,2B,2|"D7"A,2 D2 F,2A,2|"G"B,2 D2 D3 D|"D"D6 z2|
"G"G,2A,2 "C"B,2C2|"D"A,4 G,3 F,|"G"G,2G,2 G,G,B,C|"A7"D2C2 B,4|
"D"A,2D2 F,3 E,|"G"G,4 "E7"F,4|"Am"E,4 C2B,2|"D7"F,4 E,4|
"G"G,2 G,2 G,2G,2|"C"E,2 E,2 E,2E,2|"D7"F,2 F,2 F,2F,2|"G"G,6 z2:|
V:3 clef=bass
%%MIDI program 42
|:"G"G,6 B,2|"C"C4 E4|"D7"F6 A,2|"G"G,8|
"G"G,2 B,2 G,4|"D7"D8|"G"G,6 G,2|"D"A,8|
"G"B,,6 D2|"C"C8|"D"D6 F,2|"A7"A,8|
"D"A,,6 D2|"G"G,8|"E7"E,8|"Am"A,6 C2|
"D7"D8|"G"G,6 B,2|"C"C4 E4|"D7"F6 A,2|
"G"G,8 :|



X:1
T:Nostalgia's Embrace
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=68
K:G
% A reflective and heartwarming composition, moving slowly through time.
V:1 clef=treble name="Strings"
%%MIDI program 48
|: "G"D2B G2A | "C"E3 E2F | "Am"A2E "D7"C2D | "G"B,3 G2A |
"G"B2d c2B | "C"A3 AGF | "D7"F2E D2C | "G"G,3- G,2z :|
V:2 clef=treble name="Woodwind"
%%MIDI program 73
|: "G"G2E C2D | "C"E3 AGF | "Am"A2c BAG | "D7"F3- F2z |
"G"G2B A2G | "C"E3 EDC | "D7"D2F E2D | "G"C3- C2z :|
V:3 clef=treble name="Harp"
%%MIDI program 47
|: [K:clef=bass] "G"G,2  | "C"C3 E3 | "Am"A,3 C3 | "D7"D3 F3 |
"G"G,3 "C"C2C | "Am"A,3 "D7"D2D | "G"B,3 G,3 | "G"G,3- G,3 :|
V:4 clef=bass name="Cello"
%%MIDI program 42
|: "G"D,3 G,3 | "C"C,3 E,3 | "Am"A,3 "D7"D3 | "G"G,6 |
"G"D,3 G,3 | "C"C,3 E,3 | "D7"D3 F3 | "G"G,6 :|



X:1
T:Homage to Jolene
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:Am
% I've assigned a harp sound to the main melody to capture the gentle, echoing quality of Dolly Parton's song.
V:1 clef=treble
%%MIDI program 46
|:"Am"A^GAB c2E2|"C"EGcB "G"G4|"Am"A^GAB c2E2|"Em"B4 "Am"A4|
"Am"A^GAB c2E2|"C"EGcB "G"G4|"Am"A3B c2A2|"Em"G4 "Am"A4:|
V:2 clef=treble
%%MIDI program 24
|:"Am"e3f e2c2|"C"c3 B "G"G4|"Am"A2A2 e2c2|"Em"B4 "Am"A4|
"Am"e2e2 e2c2|"C"c2B2 "G"B2G2|"Am"e3f e2A2|"Em"B4 "Am"A4:|
V:3 clef=bass
%%MIDI program 42
|:"Am"A,2C2 E4|"C"E2G,2 C4|"G"B,2D2 G4|"Em"B,2E2 G,4|
"Am"A,2C2 E4|"C"G,2C2 E4|"G"D2G,2 B,4|"Em"B,2E2 "Am"A,4:|



X:1
T:Blaze of Destiny
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=170
K:Em
% The piece starts with energetic strumming to capture the fast pace of anime action.
V:1 clef=treble name="Lead Guitar" subname="Ld. Gtr."
%%MIDI program 30
|:"Em"E2E2 G2F2| "D"D2D2 A2F2| "C"B,2B,2E2D2|"B7"B,4 z4|
"Em"E2E2 G2F2|"D"A2A2 F2E2|"C"G2G2 F2D2|"B7"B4 z4|
"Am"c4 c2e2|"D"d4 B2d2|"Em"G4 B2G2|"B7"A2F2 E4|
"Am"c4 c2e2|"D"d4 B2d2|"C"E2C2 "B7"B,2D2|"Em"E4 z4:|
V:2 clef=treble name="Rhythm Guitar" subname="Rhm. Gtr."
%%MIDI program 29
|:"Em"e4 "D"d4|"C"c4 "B7"B,4|"Em"e4 "D"d4|"C"c4 "B7"B4|
"Em"e4 "D"d4|"C"c4 "B7"B,4|"Am"a4 "D"d4|"C"c4 "B7"B4|
"Am"e4 "D"d4|"Em"g4 "B7"B,4|"Am"e4 "D"d4|"C"c4 "B7"B4|
"Am"e4 "D"d4|"Em"g4 "B7"B,4|"C"c4 "B7"B4|"Em"e4 z4:|
V:3 clef=bass name="Bass Guitar" subname="Bass"
%%MIDI program 33
|:"Em"E,4 "D"D,4|"C"C,4 "B7"B,,4|"Em"E,4 "D"D,4|"C"C,4 "B7"B,4|
"Em"E,4 "D"D,4|"C"C,4 "B7"B,,4|"Am"A,4 "D"D,4|"C"C,4 "B7"B,4|
"Am"A,4 "D"D,4|"Em"E,4 "B7"B,,4|"Am"A,4 "D"D,4|"C"C,4 "B7"B,4|
"Am"A,4 "D"D,4|"Em"E,4 "B7"B,,4|"C"C,4 "B7"B,4|"Em"E,4 z4:|



X:1
T:OrchestrAI's Rhapsody
C:OrchestrAI
M:C
L:1/8
Q:1/4=88 % Moderate tempo for expressive articulation
K:C
V:1 clef=treble name="Flute"
%%MIDI program 73
V:2 clef=treble name="Violin"
%%MIDI program 40
V:3 clef=bass name="Cello"
%%MIDI program 42
V:4 clef=treble-8 name="Acoustic Grand"
%%MIDI program 0
V:1
|: "C"e2g2 c'2e2 | "Am"a4 e2a2 | "F"f3e d2c2 | "G7"g8 |
"C"c'2b2 a2g2 | "Am" a6 g2 | "F"e2c2 d4 | "G"g8 :|
[V:2]
|: "C"c4 G4 | "Am"A4 E4 | "F"F4 D4 | "G7"G,4 B,4 |
"C"E4 C4 | "Am"A,3B, C4 | "F"D4 F4 | "G7"G,8 :|
[V:3]
|: "C"C,4 G,4 | "Am"A,4 E,4 | "F"F,4 D,4 | "G7"G,4 F,4 |
"C"C,4 G,4 | "Am"A,4 C4 | "F"F,4 D,4 | "G7"G,8 :|
[V:4]
|: "C"C4 E4 | "Am"A4 C4 | "F"F4 A4 | "G7"D4 G4 |
"C"c4 e4 | "Am"G4 A4 | "F"F4 A,4 | "G7"G,8 :|
[| "C"e2c2 G2E2 | "G7/D"D4 G3F | "Em"B2G2 E2C2 | "Am"c2A2 E3D | "D7"F2D2 A,2B,2 | "G"G,4 C4 | "C" G,3E C2E2 | "G7"B,4 G,3F | "C"E6 z2 |]



X:1
T:Approaching Tempest
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Am
V:1 clef=treble
%%MIDI program 71
|: "Am"A2FE DCB,A,| "E"G,A,B,C DEFG| "F"E2DC B,A,G,F,| "E"E,4 z4 |
"Am"A,CEA A2c2| "G"B,4- B2d2| "F"ECD2 E3F| "Am"A6 G2 |
"Dm"F2D2 C2A,2| "G"B,4- B2B2| "Am"c2A2 A3B| "E"c6 B2 |
"F"A2c2 F2A2| "Am"A4- A2G2| "E"G,4 B,4| "Am"A,8 :|
V:2 clef=bass
%%MIDI program 42
|: "Am"A,2C,4 E,2| "E"G,3A, z B,C2| "F"F,3G, A,B,C2| "E"E,6 z2 |
"Am"A,4 C2E2| "G"G,,4- G,2B,2| "F"F,4 A,3B,| "Am"A,6 C2 |
"Dm"D,4 F,2A,2| "G"G,,4- G,2D2| "Am"A,4 C3D| "E"E,6 G,2 |
"F"F,4 A,2C2| "Am"A,4- A,3G,| "E"E,4 G,4| "Am"A,8 :|



X:1
T:Brass Pop Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=126
K:Cmaj
V:T clef=treble name="Trumpet"
%%MIDI program 56
V:Tb clef=bass name="Trombone"
%%MIDI program 57
V:Tuba clef=bass transpose=-12 name="Tuba"
%%MIDI program 58
[V:T]
G4- G2 F2 | E4- E2 D2 | C4- C2 E2 | G,6 z2 |
G4- G2 A2 | B4 A2 G2 | F4 E2 D2 |1 C6 z2 :|2 C6 G,2 |
|: E4- E2 F2 | D4 E2 C2 | G,4- G2 B,2 | E6- E2 |
G4 G2 F2 | E4- E2 G2 | F4- F2 E2 |1 D6 G,2 :|2 D6 z2 |
[V:Tb]
z2 G,4 D2 | z2 F,4 C2 | z2 E,4 G,2 | A,6 z2 |
z2 A,4 F2 | z2 G,4 D2 | E2 (F2 G2) A2 z4 |1 G,6 z2 :|2 G,6 C2 |
|: z4 G,2 B,2 | z4 A,2 D2 | z2 G,4 C2 | z2 F,4- F2 |
z2 G,4 D2 | z2 E,4 G,2 | (A,2 G,2) F,2 |1 E,6 C2 :|2 E,6 z2 |
[V:Tuba]
C,4 C,2 D,2 | E,4 F,2 G,2 | A,4 G,2 F,2 | G,6 z2 |
F,4 E,2 D,2 | C,4 C,2 E,2 | F,4 F,2 E,2 |1 F,6 z2 :|2 F,6- F,2 |
|: G,4 G,2 A,2 | B,,4 C,2 D,2 | E,4 D,2 C,2 | C,6- C,E, |
F,4 F,2 E,2 | D,4 E,2 C,2 | B,,4 C,2 D,2 |1 A,,6 C,2 :|2 A,,6 z2 |



X:1
T:Yee Haw Wiggle Expanded
C:Lorn & OrchestrAI
M:12/8
L:1/8
Q:3/8=120
K:Bb
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|: G3 z2 G-G3 G2G | F3 G3 B2G z3 | z2 B B2G B3 G2G | F3 G3 B2G z3 |
G3 z2 G-G3 G2G | F3 G3 B2G z3 | z2 B B2G B3 B2c | ^c3 d3 f2^f z3 :|
|: d3 f2d f3 d2B | c3 A2c A3 F2C | B3 =B2^c d2B G3 | ^c2d f3 B2G z3 |
d3 f2d f3 e2d | c3 A2c A3 F2C | D3 F2=^c d3 B2c | ^c3 z3 F3- F2G :|
V:2 clef=treble name="Banjo"
%%MIDI program 105
|: B,3 z3 B,3 B,2B, | C3 B,3 D3 B,3 | z3 D2B, D3 B,3 | C3 B,3 D3 B,3 |
B,3 z3 B,3 B,2B, | C3 B,3 D3 B,3 | z3 D2B, D3 D2E | E3 F3 A3-A2B, :|
|: F3 A2F A3 F2D | E3 C2E E3 B,2G, | B,3 B,2E F2D B,,3- | E2F A3 D3 B,3 |
F3 A2F A3 G2F | E3 C2E E3 B,2G, | G,3 B,2E F3 D2E | E3 z3 B,3- B,2C :|
V:3 clef=treble name="Piano"
%%MIDI program 0
|: [B,2G2]z6 [B,2G2] z2 | [C2A2] [B,2G2] [D2F2] [B,2G2] z4 | z6 [D2G2] z4 | [C2A2] [B,2G2] [D2F2] [B,2G2] z4 |
[B,2G2]z6 [B,2G2] z2 | [C2A2] [B,2G2] [D2F2] [B,2G2] z4 | z6 [D2G2] z4 | [E2G2] [F2A2] [A,2C2] [^F2A2] z2 :|
|: d6 d4 B2 | c6 A4 F2 | B,6 =B,4 E2 | ^c6 d2 F2 G2 |
d6 d4 d2 | c6 A4 F2 | D6 F4 B2 | ^c6 z6 F2-   :|



X:1
T:Battle of the Titans
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Emin
% The determination of the hero and the threat of the boss are evident
V:1 clef=treble name="Lead"
%%MIDI program 81
|:"Em"B4 g4 f2e2 d2B2 | E4 A4 G2F2 E4 | A8- A2G2 F4 |1 "B7" G8- G2F2 E4 :|2 "B7" G8- G4 z5  ||
|:"Em"B4 g4 f2e2 d2B2 | E4 A4 G2F2 E4 | E8- E2E2 "B7"D4 | "D"C4-C2z2 E4-E2z2 |
"Am"A4-A2B2 c4-d2c2 | "B7"B4-B2G2 A8- | "Em"A2g2 f2e2 d2B2 z4 | "D"C8- C2 "G"B2"A"B4 :|
V:2 clef=treble name="Harmony"
%%MIDI program 73
|:"Em" G,4 e4 d2c2 B2G2 | A,4 D4 C2B,2 A,4 | D8- D2C2 B,4 |1 "B7" C8- C2B,2 A,4 :|2 "B7" C8- C4 z5  ||
|:"Em" G,4 e4 d2c2 B2G2 | A,4 D4 C2B,2 A,4 | A,8- A,2A,2 "B7"G,4 | "D" F,4-F,2z2 A,4-A,2z2 |
"Am" A,4-A,2G2 A4-B,2A,2 | "B7" B,4-B,2F,2 G,8- | "Em" G,2f2 e2d2 c2B,2 z4 | "D" F,8- F,2 "G"E,2"D"D,4 :|
V:3 clef=bass name="Bass"
%%MIDI program 39
|:"Em" E,3 B,3 E,6 z4 | A,3 E,3 A,6 z4 | D3 A,3 D6 z4 |1 "B7" G,3 D3 G,6 z4 :|2 "B7" G,8- G,2 A,4 z2 ||
|:"Em" E,3 B,3 E,6 z4 | A,3 E,3 A,6 z4 | E,6 E,2 D6 z2 | "D" C,4-C,2z2 E,4-E,2z2 |
"Am" A,4-A,2B,2 c4-d2c2 | "B7" B,8- B,4 B,4- | "Em" A,3 G,3 F,3 E,3 D,3 B, | "D" C,8- C,2 "G"B,2"A"A,4 :|



X:1
T:Mischief of Friends
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=150
K:A
V:1 clef=treble
%%MIDI program 71
|:"A"e4 c4|"D"d3 c BAFA|"A"e2 c2 e4|"E7"E6 z2|
|:"A"A2 cA eAcA|"D"f2 df afed|"A"c2 ec aecA|"E7"BAGF E4:|
V:2 clef=treble
%%MIDI program 71
|:"A"c2 e2 f4|"D" f2 d2 e2 c2|"A"a2 e2 cBAG|"E7"G2 E2 F4|
|:"A"E2 A2 cBAG|"D"F2 D2 cBAG|"A"A2 E2 A2 B2|"E7"E6 z2:|
V:3 clef=treble
%%MIDI program 46
|:"A"a3 e e3 e|"D"d3 f f3 f|"A"e3 c e3 e|"E7"e6 z2|
|:"A"A2 e2 A3 e|"D"d2 f2 d3 f|"A"a2 e2 a3 e|"E7"B3 G F3 E:|
V:4 clef=bass
%%MIDI program 33
|:"A"A,2 C2 E4|"D"D4 F4|"A"A,2 C2 E4|"E7"E,2 G,2 B,4|
|:"A"A,2 C2 E4|"D"D4 F4|"A"A,2 E2 A,4|"E7"E,6 z2:|




X:1
T:Pirate Polka on The High Seas
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=120
K:Dm
V:1 clef=treble name="Harmonica"
%%MIDI program 22
|: "Dm"A4 A2AB | "C"c4 c2cd | "F"c4 "A"A2A2 | "Dm"(3ABA F2 D4 z2 |
"Dm"A3B A2d2 | "Gm"d4 d2cB | "A7

d "Dm"A2GF | "C"E4- E2 z2 :|
|: "Dm"F4 F2FG | "C"A4 A2AG | "F"F2ED C4 | "A7"A,4 (3CDE F2 z2 |
"Dm"D2F2 "Gm"B2d2 | "A"e4 c2A2 | "Dm"A2GF "A7"E2DC | "Dm"D4- D2 z2 :|
V:2 clef=treble name="Clarinet"
%%MIDI program 71
|: "Dm"F2D2 F4 | "C"E2C2 E4 | "F"A2F2 "A"c2e2 | "Dm"d4 (3cdc A2 z2 |
"Dm"F2F2 A2d2 | "Gm"B2B2 d2cd | "A7"e2fg "Dm"a2gf | "C"g4- g2 z2 :|
|: "Dm"A2F2 D4 | "C"G2E2 C4 | "F"F2ED "A7"c2e2 | "A7"a4 (3Bcd e2 z2 |
"Dm"d2D2 "Gm"F2A2 | "A"c4 A2F2 | "Dm"d2cd "A7"A2B2 | "Dm"D4- D2 z2 :|
V:3 clef=bass name="Tuba"
%%MIDI program 58
|: "Dm"D,4 D,2D2 | "C"C,4 C,2E2 | "F"F,4 "A"A,2C2 | "Dm"D,4 D,2A,2 |
"Dm"D,4 D,4 | "Gm"G,4 G,2B,2 | "A7"A,4 "Dm"=.A,2D,2 | "C"C,4- C,2 z2 :|
|: "Dm"D,4 D,4 | "C"C,4 C,4 | "F"F,4 "A7"C,4 | "A7"=.E,4 E,2=E,2 |
"Dm"D,4 "Gm"D,4 | "A"C,4 C,4 | "Dm"D,4 "A7"A,2B,2 | "Dm"D,4- D,2 z2 :|



X:1
T:The Hero's Confrontation
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=170
K:Gmaj
% Majestic and courageous, the tune accompanies the climactic showdown
V:1 clef=treble name="Brass"
%%MIDI program 61
|:"G"B2B2 B2d2 G4 G4| "C"e2e2 e2g2 c4 c4| "D"d2d2 A2f2 B4 B4| "Bm"B2a2 a2f2 g4 g4|
"G"B2B2 B2d2 G4 G4| "Em"e2e2 e2g2 e4 B4| "C" g2g2 e2g2 a4 a4| "G"d6 B2 G8:|
V:2 clef=treble name="Strings"
%%MIDI program 49
|:"G"D8- D4 z4|"C"E8- E4 z4|"D"B,8- B,4 z4|"Bm"F#8- F#4 z4|
"G"D8- D4 z4|"Em"G8- G4 z4|"C"E8- E4 z4|"G"B,8- B,4 z4:|
V:3 clef=treble name="Woodwind"
%%MIDI program 74
|:"G"B2d2 d2G2 B4 z4|"C"c2e2 e2G2 c4 z4|"D"f2a2 a2d2 f4 z4|"Bm"a2b2 b2f2 a4 z4|
"G"B2d2 d2G2 B4 z4|"Em"e2g2 g2B2 e4 z4|"C"e2g2 g2c2 e4 z4|"G"d4 B2 G4 z2 z4:|
V:4 clef=bass name="Bass" octave=-1
%%MIDI program 43
|:"G"G,8 G,8|"C"C,8 C,8|"D"D,8 D,8|"Bm"B,8 B,8|
"G"G,8 G,8|"Em"E,8 E,8|"C"C,8 C,8|"G"G,8 G,8:|



X:1
T:Whimsical Whirlwind Jig
C:OrchestrAI
M:6/8
L:1/8
Q:1/8=180
K:D
% This lively jig will captivate listeners with its playful melodies and energetic rhythm
V:1 clef=treble name="Flute"
%%MIDI program 73
|: A2F A2F | "G" G2E G2E | "D" F2D F2D | E2C E2C | "A" A2F A2F |
A2F A2F | "G" G2E G2E | "D" F2D F2D | E2C E2C :|
V:2 clef=treble name="Violin"
%%MIDI program 40
|: d2B B2A | "G" G2E E2D | "D" F2D D2C | E2C C2D | "A" A2F F2E |
A2F F2E | "G" G2E E2D | "D" F2D D2C | E2C C2D :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|: D,2F, F,2G, | "G" G,2E, E,2D, | "D" F,2D, D,2C, | E,2C, C,2D, | "A" A,2F, F,2E, |
A,2F, F,2E, | "G" G,2E, E,2D, | "D" F,2D, D,2C, | E,2C, C,2D, :|



X:1
T:Yee Haw Wiggle
C:Lorn
M:12/8
L:1/8
Q:3/8=140
K:Bb
V:1 clef=treble
%%MIDI program 56
|: G3 z2 G-G3 G2G | F3 G3 B2G z3 | z2 B B2G B3 G2G | F3 G3 B2G z3 |
G3 z2 G-G3 G2G | F3 G3 B2G z3 | z2 B B2G B3 B2c | ^c3 d3 f2^f z3 :|
V:2 clef=bass
%%MIDI program 58
|: G,,3 z2 D,,-D,,3 ^F,,3 |  G,,3 z2 D,,-D,,3 ^F,,3 | G,,3 z2 D,,-D,,3 ^F,,3 | G,,3 z2 D,,-D,,3 ^F,,3 |
G,,3 z2 D,,-D,,3 ^F,,3 | G,,3 z2 D,,-D,,3 ^F,,3 | D,3 z2 A,,-A,,3 ^C,3 | G,,3 z2 D,,-D,,3 ^F,,3 :|
% Expansion begins here
V:1
|: d3 z2 B-B3 d2d | c3 B3 ^A2B z3 | z2 d d2B d3 B2d | c3 B3 ^A2B z3 |
d2f z2 f-f3 d2f | e3 f3 g2f z3 | z2 e e2^c e3 ^c2f | g3 a3 g2f e3 |
G3 z2 G-G3 G2G | F3 G3 B2d z3 | z2 B B2G B3 G2A | B3 ^c3 d2^c A3 |
G3 z2 G-G3 G2G | F3 G3 B2d z3 | z2 B B2G B3 B2c | ^c3 d3 f2^f g3 :|
V:2
|: G,,3 z2 D,,-D,,3 G,,-G,,2 | F,,3 G,,3 B,,2D,,-D,,3 | G,,3 z2 D,,-D,,3 G,,-G,,2 | F,,3 G,,3 B,,2D,,-D,,3 |
G,,3 z2 D,,-D,,3 G,,-G,,2 | F,,3 G,,3 B,,2^F,,-^F,,3 | D,,3 z2 D,,3 ^F,,3 D,, | G,,3 z2 D,,-D,,3 ^F,,3 |
G,,2 F,,-F,,2 D,,-D,,3 ^F,,3 | F,,2 D,,-D,,2 B,,2 ^F,,-^F,,3 z | G,,3 z2 D,,-D,,3 ^F,,3 | G,,3 z2 D,,-D,,3 ^F,,3 |
G,,2 F,,-F,,2 D,,-D,,3 ^F,,3 | F,,2 D,,-D,,2 B,,2 ^F,,-^F,,3 z | D,,3 z2 A,,-A,,3 ^C,,3 | G,,3 z2 D,,-D,,3 ^F,,3 :|



X:1
T:Symphonic Journey
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:G
% Section A: Introduction
V:1 clef=treble name="Clarinet"
%%MIDI program 71
|:"G" G2B2 d2e2 | "C" c4 B2c2 | "G" B2G2 A2B2 | "D7" ^F2A2 d4 |
| "G" G2B2 d2e2 | "Am" e2c2 d2c2 | "G" B2G2 A2B2 | "D7" ^F2A2 d4 |
V:2 clef=treble name="Violin"
%%MIDI program 40
|:"G" B2D2 G2A2 | "C" e4 d2e2 | "G" g2e2 f2g2 | "D7" a2A2 d4 |
| "G" B2D2 G2A2 | "Am" c2e2 f2e2 | "G" g2e2 d2g2 | "D7" d2^F2 A2F2 |
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"G" G,2B,2 G,2A,2 | "C" E,4 G,2C z | "G" D2G,2 G,B,A,G, | "D7" F,A,D,F, F,4 |
| "G" G,2B,2 G,2A,2 | "Am" A,2E2 z A,2C,| "G" B,2G,2 D,2B,2 | "D7" A,2F,D,A, D3 |
V:4 clef=bass name="Acoustic Bass"
%%MIDI program 32
|:"G" G,,2D,B G,,2A,, z | "C" E,,4 z G,,2C,| "G" D,2G,, z2 F,2G,,| "D7" ^F,,2A,2 D,2F,2 |
| "G" G,,2D,B G,,2A,, z | "Am" A,,2E,2 z A,,2C,| "G" B,,2G,, D,2B,, z2 | "D7" A,,2F,,2 D,2A,, z |
% Section B: Development
V:1
|:"Em" B2G2 B2c2 | "Am" e4 d2e2 | "C" a3g f2e2 | "G" d2e2 B4 |
| "Cm" c3B c2d2 | "Gm" B2_G2 A2F2 | "A7" E2C2 =E4 | "D7" d2=E2 A4 |
V:2
|:"Em" G2B2 E2F2 | "Am" e2d2 c2e2 | "C" a2g2 f2e2 | "G" d2e2 g4 |
| "Cm" f2e2 f2g2 | "Gm" b2d2 g2f2 | "A7" =e2c2 ^d4 | "D7" f2a2 d4 |
V:3
|:"Em" E,2G,2 B,2E, z | "Am" E,2A,2 C2E, z | "C" C2E,2 G,2A, z | "G" D2G,2 B,4 |
| "Cm" C,2E,, G,2C, z2 | "Gm" B,,2D,2 G,2B, z | "A7" C2E,2 ^G,4 | "D7" D,,2G,2 A,4 |
V:4
|:"Em" G,,2D,B G,,E, z2 | "Am" A,,2E,2 z A,,2G,| "C" C,2E,2 G,,2A,, z | "G" D2B,,2 G,4 |
| "Cm" C,2E,, G,2C, z2 | "Gm" B,,2D,2 G,2B, z | "A7" E,,2C,2 ^G,4 | "D7" A,,2F,,2 D2A, z |
% Section C: Recapitulation
V:1
|:"G" G2B2 d2e2| "C" c4 B2c2| "G" B2G2 A2B2| "D7" ^F2A2 d4 |
| "G" G2B2 d2e2| "Am" e2c2 d2c2| "G" B2G2 A2B2| "G" G2 B2 A4 ||
V:2
|:"G" B2D2 G2A2| "C" e4 d2e2| "G" g2e2 f2g2| "D7" a2A2 d4 |
| "G" B2D2 G2A2| "Am" c2e2 f2e2| "G" g2e2 d2g2| "G" d2G2 B4 ||
V:3
|:"G" G,2B,2 G,2A,2| "C" E,4 z G,2C| "G" D2G,2 G,B,A,G, | "D7" F,A,D,F, F,4 |
| "G" G,2B,2 G,2A,2| "Am" A,2E2 z A,2C,| "G" B,2G,2 D,2B,2| "G" G,2 B,2 G4||
V:4
|:"G" G,,2D,B z G,,2A,,| "C" E,,4 z G,,2C,| "G" D,2G,, z2 F,2G,,| "D7" ^F,,2A,2 D,2F,2|
| "G" G,,2D,B z G,,2A,,| "Am" A,,2E,2 z A,,2C,| "G" B,,2G,, z2 D,2B,,| "G" G,,2 G,,2 G4||



X:1
T:Brass Pop Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=126
K:Bb
V:1 clef=treble name="Trumpet" snm="Tpt."
%%MIDI program 56
|: "Bb" F3F FBAF | "Eb" G3G GCBG | "Cm" E3E EGFE | "F7" F4 z4 :|
w: Main theme
| "Bb" d3c B2F2 | "Eb" e3d c2G2 | "Cm" c3B A2F2 | "F7" A3G F2z2 |
w: Bridge
| "Bb" d3c BABc | "Eb" B3A G2E2 | "Cm" c3B AGFE | "F7" F8 |
w: Chorus build-up
|: "Bb" f3f fafe | "Eb" g3g gBdg | "Cm" e3e egfe | "F7" f4 z4 :|
w: Triumphant chorus
V:2 clef=bass name="Trombone" snm="Tbn."
%%MIDI program 57
|: "Bb" D3D DFED | "Eb" E3E EGFE | "Cm" C3C CEDC | "F7" A,4 z4 :|
w: Harmonic support
| "Bb" F3E D2B,2 | "Eb" G3F E2C2 | "Cm" E3D C2A,2 | "F7" C3B, A,2z2 |
w: Bridge harmony
| "Bb" F3E DEFD | "Eb" E3D C2B,2 | "Cm" E3D CB,A,G, | "F7" A,8 |
w: Chorus build-up
|: "Bb" B3B BdcB | "Eb" B3B BdcB | "Cm" G3G GBG_A | "F7" A4 z4 :|
w: Triumphant chorus
V:3 clef=bass name="Tuba" snm="Tba."
%%MIDI program 58
|: "Bb" B,,3B,, D,F,B,F, | "Eb" E,,3E,, G,B,E,B, | "Cm" C,3C, E,G,C,G, | "F7" F,,4 A,,C,F,C, :|
w: Bass foundation
| "Bb" B,,3B,, D,F,B,F, | "Eb" E,,3E,, G,B,E,B, | "Cm" C,3C, E,G,C,G, | "F7" F,,4 A,,C,F,C, |
w: Bridge bass
| "Bb" B,,3B,, D,F,B,F, | "Eb" E,,3E,, G,B,E,B, | "Cm" C,3C, E,G,C,G, | "F7" F,,8 |
w: Chorus build-up
|: "Bb" B,,3B,, D,F,B,F, | "Eb" E,,3E,, G,B,E,B, | "Cm" C,3C, E,G,C,G, | "F7" F,,4 A,,C,F,C, :|
w: Triumphant chorus



X:1
T:Brass Beats
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Bb
V:1 clef=treble name="Trumpet" subname="Trpt."
%%MIDI program 56
|:"Bb"D4 F2G2|"Eb"E4 D4|"F"F4 A4|"Eb"G4F2G2|
"Bb"D4 ^C2D2|"Bb"B2A2 G4|"F"F4 A4|"Eb"G8|
"Bb"D4 F2G2|"Eb"E4 D4|"F"F4 A4|"Eb"G4F2G2|
"Bb"D4 ^C2D2|"Bb"B2A2 G4|"F"F3A G3A|"Bb"B8:|
V:2 clef=bass name="Trombone" subname="Trmb."
%%MIDI program 57
|:"Bb"B,4 D2F2|"Eb"E4 =C4|"F"F4 A,2C2|"Eb"D4=F2G2|
"Bb"B,4 D2F2|"Bb"B,2A,2 G4|"F"F4 A,2C2|"Eb"D8|
"Bb"B,4 D2F2|"Eb"E4 =C4|"F"F3A _G3F|"Eb"E6G2|
"Bb"B,4 D2F2|"Bb"B,2A,2 G4|"F"F4 A,2C2|"Bb"B,8:|
V:3 clef=bass name="Tuba" subname="Tba."
%%MIDI program 58
|:"Bb"B,,4 B,2D2|"Eb"E,4 E,2E2|"F"F,4 F,2A,2|"Eb"E,4 E,2E2|
"Bb"B,,4 B,2D2|"Bb"B,,4 B,4|"F"F,4 F,2A,2|"Eb"E,8|
"Bb"B,,4 B,2D2|"Eb"E,4 E,2E2|"F"F,4 F,2A,2|"Eb"E,4 E,2E2|
"Bb"B,,4 B,2D2|"Bb"B,,4 B,4|"F"F,4 F,2A,2|"Bb"B,,8:|



X:1
T:Pirate Polka
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=120
K:Gmaj
% A lively tune with a rhythmic interplay between tuba and clarinet
V:1 clef=treble name="Clarinet"
%%MIDI program 71
|: "G" D4 B4 | "D7" A4 F4 | "G" G4 G4 | "D7" F4 D4 |
"G" B4 d4 | "G" c4 B4 | "C" c4 E4 | "G" D4 G,4 |
"D" A,4 F4 | "G" B4 d4 | "G" G4 B4 | "D7" A4 F4 |
"G" G4 E4 | "C" D4 G,4 | "G" B,4 D4 |1 "D7" A,4 F4 :|2 "G" G,4 B,4 ||
V:2 clef=bass name="Tuba"
%%MIDI program 58
|: "G" G,,4 D,4 | "D7" A,,4 F,4 | "G" G,,4 G,4 | "D7" F,,4 D,4 |
"G" B,,4 D,4 | "G" c,,4 B,,4 | "C" E,,4 C,4 | "G" G,,4 D,4 |
"D" A,,4 F,4 | "G" B,,4 d,4 | "G" G,,4 B,,4 | "D7" A,,4 F,4 |
"G" G,,4 E,4 | "C" D,,4 G,,4 | "G" B,,4 D,4 |1 "D7" A,,4 F,4 :|2 "G" G,,2 |



X:1
T:AI's Festive Waltz
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=144
K:G
V:1 clef=treble name="Violin I"
%%MIDI program 40
|:"G"D2 "D7"B2 A2|"G"G4 B2|"C"E2 "G"D2 C2|"Am"A6|
"D7"F2 "G"E2 D2|"C"E4 G2|"D"A2 "Am7"B2 A2|"D7"G6|
"G"B2 "C"e2 d2|"G"B2 G4|"C/E"e2 "D7"=f2 e2|"G"d4 B2|
"C"e2 "G"d2 c2|"Am"B2 c4|"D7"A2 G2 F2|"G"G6:|
V:2 clef=treble name="Violin II"
%%MIDI program 40
|:"G"D2 F2 A2|"D7"F3 E "G"D2|"C"C2 E2 G2|"Am"A3 B A2|
"D7"F2 "G"E2 D2|"C"E4 d2|"D"F2 "Am7"E2 D2|"D7"G4 B,2|
"G"G2 "C"B,2 D2|"G"G4 d2|"C/E"B2 "D7"c2 B2|"G"B3 A G2|
"C"B2 "G"B2 A2|"Am"G2 A3 F|"D7"E2 ^F2 G2|"G"G4 z2:|
V:3 clef=alto name="Viola"
%%MIDI program 41
|:"G"B,2 D2 G2|"D7"A3 G "G"F2|"C"E2 "G"G2 c2|"Am"A2 "D7"F2 G2|
"D7"A2 "G"B2 c2|"C"d3 B "D7"c2|"D"B,2 "Am7"C2 D2|"D7"D3 E F2|
"G"G2 "C"A2 G2|"G"G2 d3 B|"C/E"A2 "D7"G2 A2|"G"B2 G4|
"C"G2 "G"F2 E2|"Am"D2 E3 C|"D7"D2 D2 E2|"G"G4 z2:|
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"G"G,2 B,2 D2|"D7"A,3 F "G"G,2|"C"C2 E2 A,2|"Am"A,2 "D7"F2 G2|
"D7"A,2 "G"B,2 D2|"C"E2 G,2 E2|"D"D3 D E2|"D7"F2 D4|
"G"G,2 "C"E2 C2|"G"B,2 "D7"F3 D|"C/E"E2 "D7"F2 G2|"G"B,2 "Am"A4|
"C"E2 "G"D2 G,2|"Am"C2 B,3 C|"D7"D2 E2 A,2|"G"G,6:|



X:1
T:Celestial Waltz
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=88
K:F Lydian
V:1 clef=treble name="Synth Lead" subname="Catchy Melody"
%%MIDI program 81  % Lead 1 (square)
|: "F"^C4 G2 | A3 G F2 | G2 E2 ^D2 | G6 |
"F"E2 c2 E2 | "Dm"A3 G F2 | "Gm7"G3 A B2 | "C4" c6 |
"F"^C4 G2 | A3 G F2 | "Am"E4 c2 | A6 |
"Dm"F3 E ^D2 | E4 G2 | "Bbmaj7"F2 ^D2 E2 | "F6" F6 :|
V:2 clef=treble name="Strings" subname="Chords"
%%MIDI program 48  % String Ensemble 1
|: "F"[FA]6 | [FAc]6 | [GBd]6 | [GBe]6 |
"F"[FAc]6 | [AcA]6 | "Gm7"[GBd]6 | "C4"[E^Gc]6 |
"F"[FA]6 | [FAc]6 | "Am"[Ace]6 | [AcA]6 |
"Dm"[DFA]6 | "Bbmaj7"[B,DF]6 | "F6"[^CFA]6 :|
V:3 clef=bass name="Synth Pad" subname="Harmony"
%%MIDI program 92  % Pad 3 (polysynth)
|: "F"F,6 | "F"F,3 C3 | "F"B,,6 | "F"B,,3 ^D3 |
"F"F,3 A,3 | "Dm"D,6 | "Gm7"G,6 | "C4"c,6 |
"F"F,6 | "F"F,3 C3 | "Am"A,,6 | "Am"A,,3 C3 |
"Dm"D,6 | "Bbmaj7"^D,6 | "F6"F,6 :|
V:4 clef=bass name="Bass" octave=-1
%%MIDI program 35  % Fretless Bass
|: "F"F,4 C,2 | "F"A,,4 F,,2 | "F"F,4 ^D,2 | "F"F,4 F,2 |
"F"F,4 C,2 | "Dm"D,3 A,,3 | "Gm7"G,4 B,,2 | "C4"C,3 G,,3 |
"F"F,4 C,2 | "F"A,,4 F,,2 | "Am"E,4 A,,2 | "Am"A,,3 E,,3 |
"Dm"D,4 F,2 | "Bbmaj7"B,,3 ^D,,3 | "F6"F,4 F,2 :|



X:1
T:Shadows of the Past
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Am
% Reflecting the darker tone of goth music
V:1 clef=treble
%%MIDI program 81
|:"Am" E2 A2 c2 B2|"F" A3 G F2 E2|"G" G2 E2 D2 C2|"Em" E6 z2|
"Am" e4 c4| "F" d4 A4 | "G" B4 G4 | "Em" E8 |
"Am" A2 B2 c3 B|"F" A3 G F2 E2|"G" G2 E2 D2 C2|"Em" E6 B2 |
"C" c4 E4 | "F" F4 A4 | "Am" A8 | "Em" E8 :|
V:2 clef=bass
%%MIDI program 34
|:"Am" A,2 E2 A,2 E2|"F" F,2 C2 F,2 A,2|"G" G,3 B, D2 G2|"Em" E,2 G,2 B,2 E2|
"Am" A,4 C4| "F" F,4 A,3 G| "G" G,4 B,4 | "Em" E,8 |
"Am" A,2 B,2 C3 D|"F" F,2 A,2 G2 F2|"G" G,2 B,2 D2 G2|"Em" E,2 G,2 B,2 E2 |
"C" C4 E4 | "F" F,4 A,4 | "Am" A,8 | "Em" E,8 :|



X:1
T:Whispers of the Heart
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=60
K:Am
V:1 clef=treble
%%MIDI program 73
|:"Am"e4 A2 c2|"G"B4 G2 E2|"F"E4 A2 c2|"Em"E6 z2|
"Am"a3 g e2 c2|"Dm"f4 a2 f2|"C"e2 g2 e4|"E7"e6 z2|
"F"f3 e c2 A2|"C"G4 E2 c2|"Am"A4 A2 c2|"Em"B6 z2|
"F"c3 B A2 c2|"G"B3 A G2 E2|"Am"A4 e4|"E7"E8:|
V:2 clef=bass
%%MIDI program 33
|:"Am"A,4 E3 A,|"G"B,,4 D3 G,|"F"C,4 A,3 C,|"Em"B,4 E2 B,2|
"Am"A,3 ^G A,2 E2|"Dm"F,4 D3 F,|"C"E,4 G,2 E,2|"E7"B,4 E2 ^G,2|
"F"C,2 F,2 C,4|"C"G,3 E G,2 C2|"Am"A,4 E3 A,|"Em"B,,4 D2 B,2|
"F"C,2 A,2 C3 F,|"G"B,,4 D2 B,2|"Am"A,4 E3 A,|"E7"E4 ^G,2 B,2:|



X:1
T:Funky Auld Lang Syne
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=108
K:F
% Alto sax takes the melody in a funky style
V:1 clef=treble
%%MIDI program 65
|: "F"c2 A2 c2 d2 | e2 g2 f2 e2 | d2 A2 G2 F2 | E4 D4 |
c2 A2 c2 d2 | e2 g2 f2 e2 | d2 c2 A2 G2 |1 F4 z4 :|2 F4 G4 ||
|: "Bb"A2 "F"F2 "C7"G2 E2 | "F"F2 D4 C2 | D2 F2 A2 c2 |
"Am"A4 G4 | "Bb"A2 "F"G2 A2 F2 | "Gm"G4 z G2 A | "F"F2 E2 C2 D2 |1 "C7"E4 G4 :|2 "C7"E6 z2 ||
V:2 clef=bass
%%MIDI program 58
|: "F"F,4 C4 | D,4 (3CDE F,4 | G,6 F,2 | E,4 z4 |
F,4 C4 | D,4 (3CDE F,4 | G,4 A,4 |1 F,6 z2 :|2 F,4 G,4 ||
|: "Bb"F,2 "F"C2 "C7"B,2 G,2 | "F"F,2 D,6 | D,2 F,2 A,2 G,2 |
"Am" A,2 G,6 | "Bb"F,2 "F"C2 "C7"B,2 G,2 | "Gm"F,6 F,2 | "F"F,2 E,2 C4 |1 "C7"B,4 G,4 :|2 "C7"B,6 z2 ||



X:1
T:Rival Pirates' Gale
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=120
K:Dmin
V:1 clef=treble name="Brass Lead" subname="Brass"
%%MIDI program 61
|:"Dm"d2A AFD|"C"c2E EDC|"Gm"B2d dcB|"A7"A2F FEF|
"Dm"d2A AGF|"C"c2E EAG|"Gm"B2d dBG|"A7"AFD D3:|
V:2 clef=treble name="Violin" subname="Viol."
%%MIDI program 40
|:"Dm"afd dcB|"C"egc GED|"Gm"bag fed|"A7"c2A AGE|
"Dm"afd cAF|"C"gec AEG|"Gm"b2g fdc|"A7"A2A A3:|
V:3 clef=bass name="Cello" subname="Cello"
%%MIDI program 42
|:"Dm"D3 F2A|"C"C3 E3|"Gm"G,3 B2d|"A7"A,3 CEG|
"Dm"D2A, D2F|"C"C2E CEG|"Gm"G,2B, G,3|"A7"A,CD D3:|
V:4 clef=bass name="Bassoon" subname="Bsn."
%%MIDI program 70
|:"Dm"DFA d3|"C"c2A GEC|"Gm"BdB G2F|"A7"E2C A,3|
"Dm"DFA d2c|"C"E2G c2A|"Gm"BdB G2A|"A7"A3 F3:|



X:1
T:The Enchanted Library
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=84
K:Em
% The piece begins with a sense of entering the mysterious library grounds.
V:1 clef=treble
%%MIDI program 74
|:"Em" B3 ^A B2|"D" F2 A2 d2|"C" G3 F E2|"Bm" B,3 ^A B2|
"Em" e2 "G"d2 B2|"Am"c2 "B7"B2 A2|"Em"G4 E2|"G"D2 B,2 z2 :|
"A"C2|"Em" B,2 z2 z2||
V:2 clef=treble
%%MIDI program 71
|:"Em" e4 B2|"D" d4 F2|"C" c4 E2|"Bm" B4 ^A2|
"Em" e4 e2|"G" d3 B ^A2|"Am" c4 A2|"B7" B4 G2:|
"Em" E2 ^G2 E2|"G" D2 D2 z B,|"A" c4 A2|"Em" B,2 z2 z2 :|
V:3 clef=bass
%%MIDI program 70
|:"Em" E2 G2 B,2|"D" D2 F2 A,2|"C" C2 E2 G,2|"Bm" B,2 D2 F,2|
"Em" E2 E2 B,2|"G" D2 B,2 G,2|"Am" A,2 C2 E2|"B7" B,2 F2 D2:|
"Em" E2 B,2 E2|"G" D2 B,2 G,2|"A" C2 E2 A,2|"Em" B,2 z2 z2 :|



X:1
T:Divine Advent
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=76
K:Bb
V:1 clef=treble name="Strings"
%%MIDI program 48
|:"Bb" D4 F4 | G3A B2c2 | "F/A" A6 G2 | F4 E4 |
"Eb" E4 G4 | F3G A2G2 | "Bb/F" F6 E2 | D4 C4 |
"Bb/D" D4 F4 | G3A B2c2 | "Gm/Eb" E6 F2 | G4 A4 |
"Fsus" F4- F2 E2 | D3C B,4 | "Bb" C4 D4 | B,4 z4 :|
V:2 clef=treble name="Brass"
%%MIDI program 61
|:"Bb" d4 f4 | g4 (3gab z4 | "F/A" a6 g2 | f4 e4 |
"Eb" e4 g3f | g4 f4 | "Bb/F" f6 e2 | d4 c3B |
"Bb/D" d4 d2f2 | g4 (3gab z4 | "Gm/Eb" e6 f2 | g4 a4 |
"Fsus" F4 F2E2 | D4 B,4 | "Bb" C4 D4 | B,4 z4 :|
V:3 clef=bass name="Choir Aahs"
%%MIDI program 52
|:"Bb" B,2D2 F2B,2 | B,2D2 F4 | "F/A" A,2C2 A2F2 | E2D2 C2B,2 |
"Eb" B,2E2 G2E2 | G2F2 E4 | "Bb/F" F2B,2 D2F2 | B,4 A,4 |
"Bb/D" D2F2 B,2D2 | G2B2 d4 | "Gm/Eb" E2B,2 G2E2 | F2A2 B4 |
"Fsus" F3E D2C2 | B,4 A,4 | "Bb" B,2C2 D4 | B,4 z4 :|



X:1
T:Longing Skies
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:C
V:1 name="Trumpet" clef=treble
%%MIDI program 56
|:"C"E2 G2 c2 e2|"Am"a4 e4|"F"f2 a2 f2 d2|"G" ^g4 f4 |
"C"e2 ^g2 a2 g2|"F"f2 e2 d2 c2|"Dm"d2 f2 a2 g2|"G" e4 e4 |
"E7"^g2 a2 b2 a2|"Am"a2 g2 f2 e2|"F"f2 e2 f2 g2|"C"e4 c'4 |
"Am"a2 e2 c2 e2|"F"d2 f2 a2 c2|"G"g2 b2 g2 e2|"C"c4 C4 :|
V:2 name="Trombone" clef=bass
%%MIDI program 57
|:"C"C2 E2 G2 C2|"Am"A2 E2 A2 E2|"F"F2 A2 c2 F2|"G"G2 B2 d2 G2 |
"C"E2 G2 C2 E2|"F"A2 c2 F2 A2|"Dm"D2 F2 A2 D2|"G"G2 B2 D3 G |
"E7"^G2 A2 B2 A2|"Am"A2 c2 E2 A2|"F"F2 A2 c2 F2|"C"C2 E2 G2 C2 |
"Am"A2 E2 A2 E2|"F"A2 F2 A2 c2|"G"G2 B2 D2 G2|"C"C4 z4 :|
V:3 name="Tuba" clef=bass
%%MIDI program 58
|:"C"C,4 C4|"Am"A,4 E4|"F"F,4 F4|"G"G,4 B,4 |
"C"C,4 C4|"F"F,4 A,4|"Dm"D,4 A4|"G"G,4 G,4 |
"E7"E,4 ^G,4|"Am"A,4 A,4|"F"F,4 F4|"C"C,4 C4 |
"Am"A,4 E4|"F"F,4 F4|"G"G,2 G,2 B,2 D2|"C"C,4 C,4 :|



X:1
T:Youth's Ephemeral Echo
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=60
K:Amin
V:1
%% MIDI program 81
|: "Am"E4 z4 E4 z4 | "Am"E4 z4 E4 z4 | "F"E4 z4 C8 | "G"E4 z4 C8 | "Am"E4 z4 E4 z4 :|
V:2
%% MIDI program 81
|: "Am"A,4 z4 A,4 z4 | "Am"A,4 z4 A,4 z4 | "A"e4 z4 "^Sporadic hope"A8 | "A"e4 z4 A8 | "Am"A,4 z4 A,4 z4 :|
V:3
%% MIDI program 70
z16 | z8 "^Tension"E8 | "C"^G,8- "^Release"^G,16 z2 | "E7"^G,8- "^Release"^G,16 z2 |1 z16 :|2 "^Hope"z8 "E7"^G8 |]
V:4
%% MIDI program 70
|: "Am"C,4 E,4 C,4 E,4 | "Am"C,4 E,4 C,4 E,4 | "F"C,4 A,4 F,8 | "G"B,4 G,4 E,8 | "Am"C,4 E,4 C,4 E,4 :|



X:1
T:Autumn Reflections
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=80
K:Em
V:1 clef=treble name="Oboe" subname="Melody"
%%MIDI program 68
|:"Em" B3 B AF | "D" G2 F2 E2 | "G" B3 A G2 | "C" c2 B2 A2 |
"Am" A3 F ED | "Em" E2 D2 E2 | "Bm7" F3 E DB, | "Em" E4 z2 |
"Em" B3 B AG | "C" E2 G2 A2 | "G" B2 d2 cB | "D" A3 F E2 |
"C" G3 A G2 | "Bm" A2 B2 c2 | "Am" d3 c BA | "Em" E4 z2 :|
V:2 clef=treble name="Piano" subname="Harmony"
%%MIDI program 0
|:"Em" G4 B2 | "D" A4 F2 | "G" G4 B2 | "C" E4 c2 |
"Am" A4 c2 | "Em" G4 B2 | "Bm7" F4 A2 | "Em" E4 G2 |
"Em" G4 B2 | "C" E4 G2 | "G" D4 B,2 | "D" F4 A2 |
"C" G4 E2 | "Bm" F4 D2 | "Am" E4 C2 | "Em" E4 G2 :|
V:3 clef=bass name="Cello" subname="Bass"
%%MIDI program 42
|:"Em" E2 B,2 E2 | "D" D2 F2 A2 | "G" G2 D2 G2 | "C" C2 E2 G2 |
"Am" A2 E2 A2 | "Em" B,2 E2 G2 | "Bm7" F2 B2 D2 | "Em" E2 B,2 E2 |
"Em" E2 B,2 E2 | "C" C2 E2 G2 | "G" G2 B2 D2 | "D" D2 A2 F2 |
"C" C2 E2 G2 | "Bm" B,2 F2 B2 | "Am" A2 E2 C2 | "Em" E4 z2 :|



X:1
T:Joyful Reunion
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:D
V:1
%%MIDI program 1
|:"D"F2F2 A2d2|"G"B2d2 "A"c2B2|"D"A4 F2A2|"Bm"d4 "A"e3f|
"D"d2D2 F2A2|"G"B2d2 "A"c2B2|"Bm"F4 "G"A4|"A"G6 z2|
"D"A2A2 "G"B2B2|"F#m"A2A2 "G" B3A|"A"G4 "A7"F4|"D"E6 ^C2|
"D"D2D2 "A"F2E2|"Bm"D2"d.c."F2 "G"E2D2|"G"G4 "A"A2G2|"D"D8:|
V:2
%%MIDI program 49
|:"D"A,4 D2F2|"G"B,4 D2B,2|"A"C4 A,2C2|"Bm"F4 B,4|
"D"A,4 D2F2|"G"B,4 D2E2|"Bm"F4 "G"A,4|"A"G,6 z2|
"D"A,2D2 "G"D2D2|"F#m"A,2A,2 "G"G2F2|"A"A4 "A7"^C4|"D"D6 F2|
"D"A,4 "A"A,4|"Bm"A,4 "d.c."D4|"G"B,4 "A"F4|"D"D8:|
V:3
%%MIDI program 42
|:"D"D4 F2A2|"G"G4 B2d2|"A"A4 c2E2|"Bm"d4 F4|
"D"D4 F2A2|"G"G4 B2d2|"Bm"F4 "G"A4|"A"G4 E4|
"D"D4 A2d2|"G"B4 d2B2|"A"c4 e2c2|"D"d4 F2A2|
"D"f4 d2f2|"A"a4 e2^c2|"Bm"d4 A4|"G"G6 z2:|



X:1
T:Awakening of the Forest Guardian
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=80
K:Em
V:1 clef=treble name="Synth Voice"
%%MIDI program 91
|: "Em"D4 B2 AB | "A"C6 ED | "Bm"B4 D2 EF | "G"D6 z2 |
"Em"E2 G2 A2 B2 | "D"A4 G2 FE | "G"G2 F2 E2 D2 | "C"E4 D2 ED |
"Em"D2 E2 F2 G2 | "A"A3 B c4 | "Bm"B6 Ad | "Am"c2 B2 A2 G2 |
|: "C" E2 F2 G2 A2 | "Bm"B4 A3 G | "G"F2 E2 D3 C |1 "G"B,4 D4 :|2 "D"A6 z2 :|
V:2 clef=treble name="Strings Ensemble"
%%MIDI program 48
|: "Em"G,4 E2 G2 | "A"A4 c2 e2 | "Bm"d4 f2 a2 | "G"g6 z2 |
"Em"e4 G2 e2 | "D"d4 F2 d2 | "G"g2 f2 e2 G2 | "C"c4 A2 c2 |
"Em"e2 G2 B2 e2 | "A"a4 c2 e2 | "Bm"b6 g2 | "Am"a2 g2 f2 e2 |
|: "C" c2 c2 B2 G2 | "Bm"b4 a3 g | "G"f2 g2 a2 b2 |1 "G"g,4 B4 :|2 "D"d6 z2 :|
V:3 clef=bass name="Contrabass"
%%MIDI program 43
|: "Em"E,4 E,2 A,2 | "A"C4 C2 E2 | "Bm"B,4 B,2 D2 | "G"G,6 z2 |
"Em"E,4 E,2 G,2 | "D"D4 D2 F2 | "G"G,2 F,2 E,2 D,2 | "C"C4 C2 E,2 |
"Em"E,2 E,2 F,2 G,2 | "A"A,4 A,2 c2 | "Bm"B,6 F,2 | "Am"A,2 G,2 F,2 E,2 |
|: "C" C,2 D,2 E,2 F,2 | "Bm"B,4 A,3 G, | "G"G,2 F,2 E,3 C, |1 "G"G,4 B,4 :|2 "D"D6 z2 :|



X:1
T:Harmony of the Digital Eden
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:Gmaj
% The piece starts with a gentle introduction using soft, synthesized tones that give a sense of a digital utopia
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"G"D4 B2|"Em"G3 A B2|"C" E6|"Am" A3 G F2|
"D"D4 F2|"G"B3 d c2|"C"E3 F G2|"G"G6|
"D"D4 c2|"G"B3 G A2|"C"E4 G2|"Am"A6|
"D"F4 E2|"Em"G4 F2|"A7"A3 G F2|"D"D6:|
V:2 clef=treble name="Arp Synth"
%%MIDI program 82
|:"G"G2 B2 D2|"Em" B2 G2 E2|"C"C2 E2 G2|"Am" A2 G2 F2|
"D"F2 A2 D2|"G"B2 d2 G2|"C"E2 C2 E2|"G"G3 B2 G|
"D"A2 F2 D2|"G"G2 B2 D2|"C"E3 F2 D|"Am"A2 E2 C2|
"D"F2 D2 A2|"Em"G2 B2 E2|"A7"A2 F2 A2|"D"D4 z2:|
V:3 clef=bass name="Bass Synth"
%%MIDI program 38
|:"G"G,2 B,2 D2|"Em"G,2 B,2 E2|"C"C,2 E,2 G,2|"Am"A,2 C2 E2|
"D"D,2 F,2 A,2|"G"G,2 B,2 d2|"C"C,2 E,2 G,2|"G"G,6|
"D"D,2 A,2 F2|"G"G,2 B,2 D2|"C"C,2 G,2 E2|"Am"A,2 C2 E2|
"D"D,2 F,2 A,2|"Em"E,2 G,2 B,2|"A7"A,2 E2 C2|"D"D,6:|
V:4 clef=treble name="Pad Synth"
%%MIDI program 92
|:"G"D6|"Em"G6|"C"E6|"Am"A6|
"D"D6|"G"B6|"C"E6|"G"G6|
"D"c6|"G"B6|"C"G6|"Am"A6|
"D"F6|"Em"G6|"A7"A6|"D"D6:|



X:1
T:Reminiscence and Reverie
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=72
K:Em
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"Em"B2|"C"G4E2|"G"D4B,2|"Am"C4A,2|"Em"B,4z2|"D"A,3F ED|"G"B,4D2|
"E7"G,2F2E2|"Am"A4z2|"Em"E2|"D"F3ED2|"Em"E4B,2|"G"B4A2|"Am"A3GF2|
"B7"G4F2|"Em"E6:|
|:"Em"E2|"G"G4B2|"C"E4G2|"D"A3GF2|"Em"E4z2|"C"G2E2D2|"D"F4z2|
"Am"A2|"B7"F3ED2|"Em"E4z2|"G"B,2D2G2|"Am"A4F2|"E7"G2B,2E2|
"Am"A4z2|"Em"E6:|
V:2 clef=treble name="Clarinet"
%%MIDI program 71
|:"Em"E2|"C"C4E2|"G"B,4D2|"Am"C2A,2F2|"Em"E4z2|"D"F4D2|"G"G,2F2E2|
"D"F4A2|"G"D4z2|"Em"E2|"D"A4F2|"Em"G2B,2E2|"G"B4A2|"Am"C4B2|
"B7"B3AF2|"Em"E6:|
|:"Em"E2|"G"G3AB2|"C"E4C2|"D"F2A2D2|"Em"E4z2|"C"G4E2|"D"F2D2F2|
"Am"A4z2|"B7"F2|"Em"E3FG2|"G"G2D2B,2|"Am"A4F2|"E7"G2B,2E2|
"Am"A4z2|"Em"E6:|
V:3 clef=alto name="Violin"
%%MIDI program 40
|:"Em"B,2|"C" E3 G E2|"G"D4B,2|"Am"C4A,2|"Em"E4z2|"D"A,3F ED|"G"B,4D2|
"E7"G,2F2E2|"Am"A4z2|"Em"E2|"D"F3ED2|"Em"E4B,2|"G"B2A2G2|"Am"A3GF2|
"B7"G4F2|"Em"E6:|
|:"Em"E2|"G"G4B2|"C"E4G2|"D"A3GF2|"Em"E4z2|"C"G2E2D2|"D"F4z2|
"Am"A2|"B7"F3ED2|"Em"E4z2|"G"B,2D2G2|"Am"A3GF2|"E7"G2B,2E2|
"Am"A4z2|"Em"E6:|
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"Em"E,2|"C"G,2E,2C,2|"G"D,2B,,2G,2|"Am"C,2A,,2E,2|"Em"E,4z2|"D"F,,2A,,2D,2|"G"G,,2E,2B,,2|
"D"D,2F,2A,2|"G"B,,4z2|"Em"E,2|"D"F,,4A,,2|"Em"E,2C,2E,2|"G"B,,4G,2|"Am"A,,2E,2C,2|
"B7"B,,2F,2D,2|"Em"E,6:|
|:"Em"E,2|"G"G,4B,,2|"C"E,4C,2|"D"A,,2F,,2A,,2|"Em"E,4z2|"C"G,2E,2G,2|"D"F,4z2|
"Am"A,2|"B7"F,2D,2B,,2|"Em"E,4z2|"G"B,,4D,2|"Am"A,4F,2|"E7"G,4E,2|
"Am"A,4z2|"Em"E,6:|



X:1
T:Expanded Test
Z:Arr. Joel Breit; Additions by OrchestrAI
L:1/8
Q:1/2=80
M:4/4
K:Gm
V:1 treble nm="Harmonica" snm="Harm."
"D7" d2 AB cd/c/ BA |"Gm" G2 GB d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
$"Cm" z c2 e g2 fe | "Bb" z d2 B d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
$"Gm" d4 B4 |"D7" c4 A4 |"Gm" B4 G4 |"D7" ^F4 A4 |
$"Gm" d4 B4 |"D7" c4 A4 |"Bb" B2 d2 g4 |"D7" ^f8 :|
|:"Gm" d2 cB AG/A/ BF | "Eb" D2 DE F2 FG | "Bb" A3 G ^F2 G2 | "D7" A2 G2 ^F2 D2 |
"Cm" G2 GA B2 AG | "Gm" ^F2 G2 A2 GF | "D7" D2 DE F2 ED | "Gm" G2 A2 B2 G2 |
$"Cm" e2 c2 d2 e2 | "D7" f2 d2 ef/g/ ag | "Gm" a2 ^f2 g2 a2 | "G7" bag^f g2 d2 |
"Cm" e2d2 B2c2 | "D7" d2c2 B2A2 | "Gm" GABc d2B2 | "D7" c8 :|
V:2 bass nm="Tuba" snm="Tba."
% I shall add an engaging bass line that complements the existing melody while providing solid harmonic support.
"D7" [A,,^F,]4 [D,A,]4 | "Gm" [B,,D,]4 [G,B,]4 | "D7" [A,,^F,]4 [D,A,]4 | "Gm" [G,,G,]4 [G,B,]4 |
$"Cm" [C,_E,_G,]4 [C,E,G,]4 | "Bb" [F,_B,,F,]4 [D,D,]4 | "D7" [A,,^F,]4 [D,A,]4 | "Gm" [G,,G,]4 [G,B,]4 |
$"Gm" [G,,G,]4 [G,B,]4 | "D7" [A,,^F,]4 [D,A,]4 | "Gm" [G,,G,]4 [G,B,]4 | "D7" [A,,^F,]4 [D,A,]4 |
$"Gm" [G,,G,]4 [G,B,]4 | "D7" [A,,^F,]4 [D,A,]4 | "Bb" [F,_B,,F,]4 [F,B,]4 | "D7" [^F,^F,]8 :|
|:"Gm" [G,,G,]4 [G,B,]4 | "Eb" [D,,C,]4 [D,A,]4 | "Bb" [F,_B,,F,]4 [F,B,]4 | "D7" [A,,^F,]4 [D,A,]4 |
"Cm" [C,_E,_G,]4 [C,E,G,]4 | "Gm" [G,,G,]4 [G,B,]4 | "D7" [D,,D,]4 [D,A,]4 | "Gm" [G,,G,]4 [G,B,]4 |
$"Cm" [C,_E,_G,]4 [C,E,G,]4 | "D7" [D,,D,]4 [D,A,]4 | "Gm" [G,,G,]4 [G,B,]4 | "G7" [D,,D,]4 [G,B,]4 |
"Cm" [C,_E,_G,]4 [C,E,G,]4 | "D7" [D,,D,]4 [D,A,]4 | "Gm" [G,,G,]4 [G,B,]4 | "D7" [A,,^F,]8 :|



X:1
T:Band Bash Bonanza
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=144
K:C
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|: "C" c2G2 E2C2 | (3GAB c2 "F" A2F2 z2 | "G7" (3def g2 B2d2 z2 | "C" c2e2 "G7" d2f2 |
| "C" e2c'2 "Am" a2e2 | "Dm" f2a2 "G7" (3gfe d2 z2 | "C" c2G2 E2C2 | "G7" D2F2 "C" C4 :|
V:2 clef=treble name="Alto Sax"
%%MIDI program 65
|: "C" E2C2 G,2E2 | (3CDE F2 "F" C2A,2 z2 | "G7" (3GAB d2 G2B2 z2 | "C" G2c2 "G7" B2d2 |
| "C" c2e2 "Am" c2A2 | "Dm" A2f2 "G7" (3dcB G2 z2 | "C" E2C2 G,2E2 | "G7" F,2A,2 "C" C4 :|
V:3 clef=bass name="Trombone"
%%MIDI program 57
|: "C" C,2E,2 G,2C2 | "F" A,2C2 F,2A,2 | "G7" G,2B,2 D2G,2 | "C" E,2G,2 "G7" D,2G,2 |
| "C" C,2E,2 "Am" A,2C2 | "Dm" D,2F,2 "G7" G,2B,2 | "C" C,2E,2 G,2C2 | "G7" G,,2B,,2 "C" C,4 :|
V:4 clef=bass name="Tuba"
%%MIDI program 58
|: "C" C,4 C,,4 | "F" F,,4 F,4 | "G7" G,,4 G,4 | "C" C,2E,2 "G7" G,,2B,,2 |
| "C" C,4 "Am" A,,4 | "Dm" D,4 "G7" G,,4 | "C" C,4 C,,4 | "G7" G,,4 "C" C,,4 :|



X:1
T:Chromatic Apparitions
C:OrchestrAI
M:C
L:1/16
Q:1/4=160
K:Am
V:1
%%MIDI program 4
|: "Am"e4 A4 c4 e4 | "E7"^g4 B4 ^d4 ^g4 | "Am"a4 .e4 .c4 .A4 | "F"^f4 .c4 .A4 F4 |
"Am"A,4 ^C4 E4 A,4 | "Dm"d4 A4 F4 D4 | "G7"=B,4 D4 ^F4 B,4 | "C"G,4 E4 c4 G,4 |
"Am"A,4 E4 ^C4 A,4 | "E7"=B,4 ^G4 E4 B,4 | "Am"a2 .e2 .c2 .A2 a2 .e2 .c2 .A2 | "F"^f2 .c2 .A2 F2 ^f2 .c2 .A2 F2 |
"Am"A,2 ^C2 E2 A,2 A,2 ^C2 E2 A,2 | "Dm"d2 A2 F2 D2 d2 A2 F2 D2 | "G7"=B,2 D2 ^F2 B,2 =B,2 D2 ^F2 B,2 | "C"G,2 E2 c2 G,2 G,2 E2 c2 G,2 :|
V:2
%%MIDI program 4
|: "Am"A,2 .A,2 E2 .E2 A2 .A2 E2 .E2 | "E7"^G,2 .=G,2 B2 .B2 ^d2 .^d2 =g2 .=g2 | "Am"A2 .A2 E2 .E2 ^C2 .^C2 e2 .e2 | "F"F2 .F2 =A,2 .=A,2 c2 .c2 ^f2 .^f2 |
"Am"A,2 .A,2 E2 .E2 ^C2 .^C2 E4 | "Dm"D2 .D2 F2 .F2 A2 .A2 d4 | "G7"^F2 .^F2 B,2 .B,2 D2 .D2 =g4 | "C"c2 .c2 E2 .E2 G,2 .G,2 G4 |
"Am"A,2 .A,2 E2 .E2 A2 .A2 c2 .c2 | "E7"^G,2 .^G,2 =B,2 .=B,2 ^d2 .^d2 e2 .e2 | "Am"A2 A2 E2 E2 A2 A2 E2 E2 | "F"F2 F2 C2 C2 F2 F2 c2 c2 |
"Am"A,2 ^C2 E2 A,2 A,2 E2 c2 c2 | "Dm"D2 A2 F2 D2 d2 F2 A2 a2 | "G7"B,2 D2 ^F2 B,2 B,2 D2 ^F2 f2 | "C"G,2 C2 E2 G,2 G,2 C2 E2 G,2 :|



X:1
T:Showdown of Fates
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Cmin
% The tune starts with strings setting the battle scene
V:1 name="Violin" clef=treble
%%MIDI program 40
|: "Cm"G4^F4E4D4 | "G7"B,4=B,4C4^A,4 | "Cm"G4F4G4A4 | "Bdim7"B8- B4 z4 |
"Cm" e2c'2g2e2 f2d'2f2 z2 | "Ab" e2^c2g2e2 f2d2c2 z2 | "G7" b2g2f2d2 e2^c2d2 z2 | "Cm"c4 z4 "Fm"d4 c4 |
"Ab"B2b2b2g2 f4 e4 | "G7"d4 B4 C4 G,4 | "Cm"c4 "Fm" d4 "Cm" e4 g4 | "Bb7"f8- f4 z4 :|
V:2 name="Brass" clef=treble
%%MIDI program 61
|: "Cm"C,2C2C2G,2 A,2^A,2 B,2 z2 | "G7"D4 E4 F4 G4 | "Cm"A,2B,2C2C2 D4 E4 | "Bdim7"F4 ^F4 G4 A4 |
"Cm"G,2E2E2C2 D4 C2 z2 | "Ab"G,2F2F2D2 E4 ^C2 z2 | "G7"D2B,2B,2G,2 A,4 z4 | "Cm"C4 z4 "Fm"A,4 G,4 |
"Ab"E2e2e2c'2 a4 g4 | "G7"d4 f4 g4 B,4 | "Cm"C4 "Fm"F,4 "Cm"G,4 G4 | "Bb7"A8- A,4 z4 :|
V:3 name="Bass" clef=bass
%%MIDI program 43
|: "Cm"C,8- C,4 z4 | "G7"G,4 B,4 D4 G,2 z2 | "Cm"C,2C,2C,2C,2 G,4 G,4 | "Bdim7"B,,4 E,4 F,8 |
"Cm"G,8- G,6 z2 | "Ab"A,6 A,4 F,4 z2 | "G7"G,12 | "Cm"C,8 F,8 |
"Ab"Ab,12 | "G7"G,4 D,4 G,4 B,,4 | "Cm"C,4 E,4 G,8 | "Bb7"B,8- B,,4 z4 :|



X:1
T:Whimsical Ballet Dreams
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:Am
% Voice 1 - Celesta (Melody)
V:1 clef=treble
%%MIDI program 8
|: "Am"e2 ^c2 e4 | "E7"^g4 e2 z2 | "Am"e2 ^c2 a3 z | "G"^f2 d3 e2 z |
"E"=B2 ^G2 E4 | "A7"=c2 A3 ^G2 z | "Dm"A2 =F2 D4 | "E7"^G,2 E,3 ^C2 z |
"Am"A2 ^c2 e3 z | "F"=c2 A3 =c2 z | "E7"B2 =E3 G2 z | "Am"A6 z2:|
% Voice 2 - Brass (Harmony)
V:2 clef=treble
%%MIDI program 61
|: "Am" A,3 C E2 A,2 | "E7" G,2 B, E2 G,2 z | "Am"A,2 C A,3 E z | "G"F,2 D G,2 B,2 z |
"E"G,2 =B, ^G2 E2 z | "A7"A,2 ^G, A,2 =c2 z | "Dm"A,2 =F A,2 D2 z | "E7"^G,2 ^C E2 ^G,2 z |
"Am"A,3 C E3 A, | "F"=c2 A, =c2 f2 z | "E7"B,2 E G,2 B, z2 | "Am"A,6 z2:|
% Voice 3 - Lower Brass (Bass and Counter-melody)
V:3 clef=bass
%%MIDI program 61
|: "Am" A,4 C4 | "E7" B,4 ^G,4 | "Am"A,4- A,2 E,2 | "G" ^F,4 D,4 |
"E" E,4 ^G,4 | "A7" A,4- A,2 ^C2 | "Dm"D,4 =F,4 | "E7" ^G,4 ^C,4 |
"Am"A,4- A,2 C2 | "F"=c,4 A,3 z | "E7"B,4 E,4 | "Am"A,6 z2:|
% Voice 4 - Contrabass (pedal tones and support)
V:4 clef=bass
%%MIDI program 43
|: "Am"A,,6 E,2 | "E7"E,,6 B,,2 | "Am"A,,6 E,2 | "G"G,,6 D,2 |
"E"E,,6 ^G,,2 | "A7"A,,6 ^C,2 | "Dm"D,6 A,,2 | "E7"^G,,6 ^C,2 |
"Am"A,,6 E,2 | "F"=C,,6 A,,2 | "E7"B,,6 E,,2 | "Am"A,,6 z2:|



X:1
T:Harbor Festival
C:OrchestrAI
M:6/8
L:1/8
Q:1/4=140
K:Cmaj
V:1 clef=treble
%%MIDI program 24
|:"C"G3 E2D|CED E2C|D3 C2E|GEC G,3|
"C"e2d c2B|A2G F2E|D3-C2D|"G7"E3 D3|
"G"B2d BAG|"C"c2e d2c|A2B c3|"Am"A3-G3|
"C"E3 E2G|c2e d2c|B2d c3-|"G" c4 z2:|
V:2 clef=treble
%%MIDI program 24
|:"C"c2e e2c|d3 c2c|e3 f2e|dcB c3|
"C"g3 f2e|d2c B2A|c3-B2c|"G7"A3 G3|
"G"b2b a2g|"C"g2e f2d|e2f g3|"Am"g3-f3|
"C"c3 c2e|g2e f2d|e2g f3-|"G" f4 z2:|
V:3 clef=bass
%%MIDI program 46
|:"C"C3 C,3|C3 C,3|C3 D,3|E,3 G,3|
"C"C3 E,3|F,3 G,3|C3 E,3|"G7"D,3 F,3|
"G"G,3 G,3|"C"C3 C,3|C3 A,3|"Am"A,3 E,3|
"C"C3 E,3|G,3 A,3|C3 E,3-|"G" E,4 z2:|



X:1
T:High Seas Polka
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=220
K:Dm
% The accordion leads the tune with a fast-paced, rollicking melody
V:1 clef=treble name="Accordion"
%%MIDI program 21
|: "Dm"d4 d2cB | "A7"A4 A2GF | "Dm"D4 F2ED | "C"E4 E2z2 |
"Dm"A2F2 D2F2 | "A7"E2C2 C2E2 | "Dm"D4 D2DF | "A7"E4 E2z2 |
"Dm"d4 d2cB | "A7"A4 A2GF | "Dm"D4 F2ED | "C"E4 E2z2 |
"Dm"A2>D2 F2A2 | "A7"E2C2 C2E2 | "Dm"D8 :|
V:2 clef=treble name="Violin" octave=1
%%MIDI program 40
|: "Dm"f8 | "A7"G8 | "Dm"A8 | "C"F8 |
"Dm"A4 F2A2 | "A7"G4 E2G2 | "Dm"F8 | "A7"G8 |
"Dm"f8 | "A7"G8 | "Dm"A8 | "C"F8 |
"Dm"A4 F2A2 | "A7"G4 E2G2 | "Dm"F8 :|
V:3 clef=bass name="Bass"
%%MIDI program 32
|: "Dm"D,8 | "A7"A,8 | "Dm"D,8 | "C"C8 |
"Dm"D,4 F,4 | "A7"E,4 A,4 | "Dm"D,8 | "A7"E,8 |
"Dm"D,8 | "A7"A,8 | "Dm"D,8 | "C"C8 |
"Dm"D,4 F,4 | "A7"A,4 C,4 | "Dm"D,8 :|



X:1
T:Harmony in the Digital Garden
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Cmaj
% Electronic elements and natural sounds combine in a futuristic melody
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|: "C"EGce egce | "Am"dcAd c2A2 | "Em"BGeg bgdB | "G" A2^FG A4 |
"C"e2c2 "F"d2_A2 | "Dm"g2fe "G"c4 | "G"B4^c2 d2 | "C"c8 :|
V:2 clef=treble name="Flute"
%%MIDI program 73
|: "C"c4 E2G2 | "Am"A6B2 | "Em"B4 e2g2 | "G"A6B2 |
"C"c3B A2B2 | "F"F3E D4 | "Dm"d4 (3ded c3B | "G"G8 :|
V:3 clef=treble name="Mellow Pad"
%%MIDI program 88
|: "C"C2E2 G4 | "Am"c2A2 F4 | "Em"B2G2 E4 | "G"D2B,2 G,4 |
"C"C2E2 "F"A2F2 | "Dm"D4 "G"(3ded "C"c4 :|
V:4 clef=bass name="Synth Bass"
%%MIDI program 38
|: "C"C,4 G,4 | "Am"A,4 E4 | "Em"B,4 E4 | "G"G,4 D4 |
"C"C,4 G,4 | "Am"A,4 E4 | "Em"B,4 E4 | "G"G,4 D4 :|



X:1
T:Galactic Waltz
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:Emaj
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|: "Emaj9"B6 | "C#m7"e4 c2 | "Amaj7"A6 | "Bsus2"F4 d2 |
"Emaj9"G3 A B2 | "C#m7"G2 E4 | "Amaj7"A3 B c2 | "Bsus2"B4 z2 |
"Emaj9"B6 | "C#m7"e4 c2 | "Amaj7"A6 | "Bsus2"F4 d2 |
"Emaj9"G3 A B2 | "C#m7"G2 E4 | "Amaj7"A3 B c2 | "Bsus2"B4 z2 :|
V:2 clef=treble name="String Ensemble"
%%MIDI program 48
|: "Emaj9"B,4 E2 | "C#m7"C4 E2 | "Amaj7"A,4 C2 | "Bsus2"B,4 D2 |
"Emaj9"E4 G3  | "C#m7"C4 E2 | "Amaj7"A,4 C3  | "Bsus2"F3 E D2 |
"Emaj9"B,4 E2 | "C#m7"C4 E2 | "Amaj7"A,4 C2 | "Bsus2"B,4 D2 |
"Emaj9"E4 G3  | "C#m7"C4 E2 | "Amaj7"A,4 C3  | "Bsus2"F3 E D2 :|
V:3 clef=bass name="Synth Pad"
%%MIDI program 92
|: "Emaj9"B,,6 | "C#m7"E,4 E2 | "Amaj7"A,6 | "Bsus2"B,,4 B,2 |
"Emaj9"G,,4 B,,3  | "C#m7"C,4 E,2 | "Amaj7"A,,4 C2 | "Bsus2" F,,2 E,,2 D,,2 |
"Emaj9"B,,6 | "C#m7"E,4 E2 | "Amaj7"A,6 | "Bsus2"B,,4 B,2 |
"Emaj9"G,,4 B,,3  | "C#m7"C,4 E,2 | "Amaj7"A,,4 C2 | "Bsus2" F,,2 E,,2 D,,2 :|



X:1
T:Rites of the Hidden
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=50
K:Dm
V:1 clef=treble name="Voice"
%%MIDI program 52
|:"Dm"D2 A2 F2| "C"E2 z2 G2|"A7"A2 c2 ^G2|"Dm"A6|
"Dm"A2 d2 c2|"Gm"B2 z2 A2|"A7"c2 e2 d2|"Dm"d6|
"Dm"D2 A2 F2| "C"E2 z2 G2|"A7"A2 c2 ^G2|"Dm"A6|
"Gm"G2 B2 A2|"A7"F2 z2 G2|"Dm"F2 A2 G2|"Dm"D6:|
V:2 clef=alto name="Voice"
%%MIDI program 52
|:"Dm"D2 F2 A2|"C"C2 E2 G2|"A7"A,2 A,2 C2|"Dm"D2 F2 D2|
"Dm"D2 F2 A2|"Gm"G,2 B,2 D2|"A7"A,2 C2 E2|"Dm"D2 F2 D2|
"Dm"D2 F2 A2|"C"C2 E2 G2|"A7"A,2 A,2 C2|"Dm"D2 F2 D2|
"Gm"G,2 B,2 D2|"A7"A,2 C2 E2|"Dm"D2 F2 A2|"Dm"D6:|
V:3 clef=tenor name="Voice"
%%MIDI program 52
|:"Dm"D3 A,2 F,|"C"E2 E2 G2|"A7"A,4 C2|"Dm"D4 D2|
"Dm"D3 A,2 d|"Gm"B,2 B,2 A,2|"A7"c4 e2|"Dm"d4 d2|
"Dm"D3 A,2 F,|"C"E2 E2 G2|"A7"A,4 C2|"Dm"D4 D2|
"Gm"G2 G2 B,2|"A7"F4 G2|"Dm"F4 A,2|"Dm"D6:|
V:4 clef=bass name="Strings"
%%MIDI program 42
|:"Dm"D,4 A,2|"C"C,4 E,2|"A7"A,4 C2|"Dm"D,2 A,2 D,2|
"Dm"D,4 A,2|"Gm"G,4 B,2|"A7"A,4 C2|"Dm"D,3 A,3 D,3|
"Dm"D,4 A,4|"C"C,4 E,4|"A7"A,4 C4|"Dm"D,2 A,2 D,2|
"Gm"G,4 B,4|"A7"A,4 C4|"Dm"D,4 A,4|"Dm"D,6:|



X:1
T:Test Continued
L:1/8
Q:1/2=80
M:4/4
K:Gm
L:1/8
V:1 clef=treble name="Trumpet"
%%MIDI program 56
"D7" d2 AB cd/c/ BA |"Gm" G2 GB d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
"Cm" z c2 e g2 fe | "Bb" z d2 B d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
"Gm" d4 B4 |"D7" c4 A4 |"Gm" B4 G4 |"D7" ^F4 A4 |
"Gm" d4 B4 |"D7" c4 A4 |"Bb" B2 d2 g4 |"D7" ^f8 :|
V:2 clef=bass name="Trombone"
%%MIDI program 57
"D7" F2 F2 D2 F2 | "Gm" G,2 G,2 C2 D2 | "D7" A,3 C D2 EF | "Gm" G,2 B,2 G,2 z2 |
"Cm" C2 C2 E2 D2 | "Bb" B,,2 B,,2 B,2 C2 | "D7" A,3 C D2 EF | "Gm" G,2 B,2 G,2 z2 |
"Gm" G,2 D2 B,,2 D2 | "D7" A,2 F2 D2 F2 | "Gm" G,2 D2 B,,2 D2 | "D7" C2 ^F2 A,2 C2 |
"Gm" G,2 D2 B,,2 D2 | "D7" A,2 F2 D2 F2 | "Bb" D2 F2 B,2 D2 | "D7" A,8 :|



X:1
T:Climactic Confrontation
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=180
K:Cm
% Introduction to the battle with driving rhythm
V:1 clef=treble name="Brass" subname="Brass"
%%MIDI program 61
|:"Cm"G4 c2 d2|"Ddim"D4 F2 A2|"G"G,4 z G,A,B,|"Ab"E6 z2|
"Cm"e2 g2 c'2 e'2|"Bdim" d2 f2 b2 d'2|"G7"c'B AG FE DG,|"Cm"C,8:|
% Fast-paced action with rapid melodies
V:2 clef=treble name="Strings" subname="High Str."
%%MIDI program 48
|:"Cm"c'2 c'2 c'4|e'2 e'2 e'4|g'2 g'2 g'4|g'2 a'2 g'4|
"c"c2 c2 c2 e2|f2 d2 f2 e2|g4 e4 g4|c4 c4 z4:|
% Bass line depicting the boss's power
V:3 clef=bass name="Tuba" subname="Low Brass"
%%MIDI program 58
|:"Cm"C,2 G,2 C,2 G,2|"Ddim"D,2 A,2 D,2 A,2|"G"B,,2 F,2 B,,2 F,2|"Ab" E,,2 B,,2 E,4|
"Cm"C,2 G,2 E,2 G,2|"Bdim"B,,2 F,2 B,,2 F,2|"G7"G,,2 D,2 G,,2 D,2|"Cm"C,4 C,4 z4:|
% Tension-building in the middle section
V:4 clef=treble name="Woodwinds" subname="Winds"
%%MIDI program 71
|:"Cm"G,4 c4|"Eb"e4 g4|"Ab"a4 c'4|"G"d4 g4|
"Cm"G,2 c2 g4|e4 d4 c4|"Adim"a4 e4|"G7"d4 g4:|



X:1
T:Boss Battle Blitz
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Em
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"Em" B4E4G4B4 | "D" A4D4F4A4 | "C" G4C4E4G4 | "B" F4B4^d4f4 |
"Em"e4^d4e4f4 | "A" g4f4g4a4 | "G" b4a4b4c'4 | "F#m" a4g4f4e4 |
"Em" B4E4G4B4 | "D" A4D4F4A4 | "C" G4C4E4G4 | "B" F4B4^d4f4 |
"Em"e2e2 ^d2e2 f4 z4 | "A" g4f4g4a4 | "G" b4a4b4c'4 |"B7" F4z4 B4z4 :|
V:2 clef=treble name="Rhythm Synth" subname="Rhythm"
%%MIDI program 82
|:"Em" e8 B8 | "D" f8 a8 | "C" g8 c'8 | "B" af8 ^df6 |
"Em"e2B2 e2E2 G4 z4 | "A" f2A2 a2d2 c4 z4 | "G" B4 e4 g4b4 | "F#m" A4 f4 A4f4 |
"Em" e8 B8 | "D" f8 a8 | "C" g8 e'8 | "B" af8 ^df6 |
"Em"e2^g2 a2b2 b4 z4 | "A" c'4 b4 a4g4 | "G" b2a2 g2f2 g4 z4 |"B7" ^d4z4 f4z4 :|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|:"Em" E,6 B,4 E4 z2 | "D" D,6 F,4 A,4 z2 | "C" C,6 G,4 C4 z2 | "B" B,,6 F,4 B,4 z2 |
"Em"E,4B,4 E4e4 | "A" A,4d4 A4c4 | "G" G,4B,4 G4b4 | "F#m" F,4A,4 F4a4 |
"Em" E,6 B,4 E4 z2 | "D" D,6 F,4 A,4 z2 | "C" C,6 G,4 C4 z2 | "B" B,,6 F,4 B,4 z2 |
"Em"E,2A,2 B,2E2 c4 z4 | "A" A,4D4 A2F2 z4 | "G" G,4B,4 G2D2 z4 |"B7" B,2F,2 B,4 z8 :|
V:4 clef=treble-8 name="Counter Melody" subname="Counter"
%%MIDI program 81
|:"Em" ^d8 B8 | "D" c8 A8 | "C" B8 G8 | "B" A8 F8 |
"Em"^d4B4 G4E4 | "A" c4A4 E4A4 | "G" B4G4 D4G4 | "F#m" A4F4 C4F4 |
"Em" ^d8 B8 | "D" c8 A8 | "C" B8 G8 | "B" A8 F8 |
"Em"^d2d2 B2G2 E4 z4 | "A" c4A4 E2C2 z4 | "G" B4G4 D2B2 z4 |"B7" ^d4B4 F4z4 :|



X:1
T:Joyful Reunion
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=144
K:C
V:1 name="Soprano" clef=treble
%% MIDI program 74
|:"C"G4 G4|"Am"E2 E2 F2 G2|"F"A4 A2 G2|"G"F2 D2 G4|
"C"E2 (G2 E2) C2|"Em"B,2 B,2 G2 A2|"F"A6 G2|"G7"F6 G2|
"C"G2 A2 B2 c2|"Dm"A2 G2 F2 E2|"G"G3 F E2 D2|"C"A2 B2 c4|
"G"B2 A2 G2 F2|"Am"A2 F2 E2 D2|"Dm"F3 E D2 C2|"C"G6 z2:|
V:2 name="Alto" clef=treble
%% MIDI program 73
|"C"E2 C2 E2 G2|"Am"A2 A2 B2 c2|"F"A2 F2 A2 c2|"G"B2 D2 G2 B2|
"C"c2 E2 G2 c2|"Em"B2 B2 A2 G2|"F"A2 c2 A2 F2|"G7"G2 F2 E2 D2|
"C"C2 E2 G2 A2|"Dm"D2 D2 E2 F2|"G"G2 B,2 D2 G2|"C"C4 E2 G2|
"G"B2 A2 G2 F2|"Am"E2 A2 G2 F2|"Dm"F2 F2 E2 D2|"C"C2 E2 G4|
V:3 name="Tenor" clef=alto
%%MIDI program 70
|"C"C4 C4|"Am"C4 E2 D2|"F"A,2 F2 A2 c2|"G"B,2 G2 B2 D2|
"C"C2 E2 G2 C2|"Em"E2 G2 E2 C2|"F"F4 F2 E2|"G7"D2 F2 B,2 D2|
"C"C/E/ G/A/ B/c/ d3|"Dm"D/F/ A/G/ F/E/ E2|"G"B,2 D2 G2 B2|"C"C2 E2 G2 C2|
"G"D2 B,2 G2 D2|"Am" E2 C2 A,2 E2|"Dm" D3 E F2 A,2|"C"G,2 C2 E4|
V:4 name="Bass" clef=bass
%%MIDI program 39
|"C"C2 C2 C2 G,2|"Am"A,2 A,2 E2 A,2|"F"F2 F2 F2 C2|"G"D2 B,2 G2 D2|
"C"C2 G,2 E2 C2|"Em"G2 B,2 E2 G2|"F"A,2 A,2 F2 C2|"G7"B,2 F2 B,2 D2|
"C"C2 G,2 C2 G,2|"Dm"D2 A,2 F2 D2|"G"B,2 G,2 D2 D2|"C"C2 G,2 E2 C2|
"G"B,2 D2 G2 B,2|"Am"A,2 E2 A,2 C2|"Dm"D2 A,2 F2 F2|"C"C4 G,4|



X:1
T:Pirate Polka on the High Seas
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=120
K:Ddor
% Accordion playing a rhythmic melody
V:1 clef=treble name="Accordion"
%%MIDI program 21
|: "Dm"D2A BAG | "A7"A2E FED | "Dm"D2A BAF | "C"E2G "A7"A3 |
"Dm"A2d cBA | "A7"A2E FED | "Dm"D2A BAF | "C"E2G "A7"A3 :|
|: "Dm"f2d AFA | "C"G2E CEG | "F"A2c BAG | "A7"FED "Dm"D3 |
"Dm"f2d AFA | "C"G2E CEG | "F"A2c BAG | "A7"FED "Dm"D3 :|
% Fiddle echoing the melody and adding embellishments
V:2 clef=treble name="Fiddle" octave=1
%%MIDI program 110
|: "Dm"d2A BAG | "A7"c2E FED | "Dm"d2A BAF | "C"E2G "A7"A3 |
"Dm"A2d cBA | "A7"c2E FED | "Dm"d2A BAF | "C"E2G "A7"A3 :|
|: "Dm"a2d AFA | "C"G2E CEG | "F"A2c BAG | "A7"FED "Dm"D3 |
"Dm"a2d AFA | "C"G2E CEG | "F"A2c BAG | "A7"FED "Dm"D3 :|
% Bass outlining the chords and providing a foundation
V:3 clef=bass name="Bass"
%%MIDI program 33
|: "Dm"D3 A,3 | "A7"E3 A,3 | "Dm"D3 F3 | "C"C3 "A7"E,3 |
"Dm"D3 A,3 | "A7"E3 F3 | "Dm"D3 F3 | "C"C3 "A7"E,3 :|
|: "Dm"D3 F3 | "C"C3 G,3 | "F"F3 C3 | "A7"E3 "Dm"D,3 |
"Dm"D3 F3 | "C"C3 G,3 | "F"F3 C3 | "A7"E3 "Dm"D,3 :|



X:1
T:Hold The Groove
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Gmix
V:1 clef=treble
%%MIDI program 80
|:"G7"G4 B2d2|"C7"c2E2 c2E2|"D7"FGAF "C7"GFE2|"G7"GGAB "D7"cBAG|
"G"F4 A2e2|"C7"d2E2 d2E2|"D7"FGAF "C7"GFE2|"G7"G6 z2:|
V:2 clef=treble
%%MIDI program 81
|"G7"D2G4 G2|"C7"A,C3E "C7"G,2E|"D7"AGF2 "C7"EFG2|"G7"B,2D4 G2D2|
"G"F2A4 A2|"C7"c2E3C "C7"A,2E2|"D7"FED2 "C7"ECDE|"G7"G,4 G,4:|
V:3 clef=bass
%%MIDI program 33
|:"G7"[G,2B,2] z z G,4|"C7"[E,2G,2] z z E,4|"D7"[A,2F,2] z z A,4|"C7"[G,2E,2] z [E,G,]5|
"G7"[D,2G,2] z z D,4|"C7"[E,2C2] z [C2E2]4|"D7"[F2D2] z z3 F2|"G7"G,6 z2:|



X:1
T:The Awakening Sojourn
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=60
K:Em
V:1 clef=treble name="Piano - Right Hand"
%%MIDI program 0
E2 |:"Em"G4 A2 B2| "D"D4 F2 A2| "C"G3F E4| "Bm"F2E2 D2 E2|
"Am"A3B "G"G4| "F#m"A2F2 E3D| "Em"E4 D2E2|1 "G"B,4 C2 E2:|2 "G"B,4 C3D|]
|:"Em"E3F G4 | "G"B4 A2 G2 | "C"E4 D2 C2 | "D"B,4 A,2 G,2 |
"Am"G3A B2 c2 | "B7"B4 ^A4 | "Em"B,2E2 E3D|1 "D"D3C D2 E2:|2 "D"D3C z2 D2||
V:2 clef=treble name="Piano - Left Hand"
%%MIDI program 0
E,2 |:"Em"G,2E,2 G,4| "D"F,2D,2 F,4| "C"E,2C,2 E,4| "Bm"D,2B,,2 D,4|
"Am"A,2E,2 A,4| "G"G,2D,2 G4| "F#m"F,2C,2 F,4| "Em"E,2B,,2 E,4:|
"G"G,2D,2 G,4:|
|:"Em"E,4 G,4 | "G"B,,4 D,4 | "C"C,4 E,4 | "Bm"D,4 F,4 |
"Am"A,4 (E2C2) z4 | "B"B,,4 D,4 | "Em"E,4 G,4 | "D"D,4 F,4:|
V:3 clef=treble name="Violin I"
%%MIDI program 40
|:"Em"e4 d2^c2||: "D"d2A2 F4| "C"c2 G2 E4| "Bm"b4 a2 g2|
"Am"a4 g2f2| "G"g2d2 B4| "F#m"f2c2 A2F2| "Em"e4 ^d4|
"G"g2B2 e2 g2:|
|:"Em"e2 ^d2 e2 B2 ||: "G"g2f2 g2 B2 | "C"c2 B2 c2 E2 | "D"d2^c2 d2 F2 |
"Am"e4 e2 f2 | "B7"d2=cd e4 | "Em"e2B2 e2 g2 | "D"d2 A2 z2 d2||
V:4 clef=treble name="Violin II"
%%MIDI program 40
|:"Em"e2 B2 e4||: "D"d4 F2 D2| "C"G2 E2 c4| "Bm"F2 D2 B4|
"Am"A2 C2 E4| "G"G2 B2 d4| "F#m"A2 F2 c4| "Em"E4 B,4|
"G"G,2 D2 G,4:|
|:"Em"B,2 E2 G2 d2 ||: "G"B,2 A,2 B,2 D2 | "C"C2 E2 G2 c2 | "D"D2 F2 A2 d2 |
"Am"A2 A,2 B,2 E2 | "B7"B2 ^A2 B2 e2 | "Em"E2 B,2 E2 G2 | "D"D2 F2 z2 D2||
V:5 clef=bass name="Cello"
%%MIDI program 42
|:"Em"E,2 A,2 E,4||: "D"D,3A, D,4| "C"C,2 G,2 C4| "Bm"B,2 F,2 B,4|
"Am"A,2 E,2 A,4| "G"G,2 D,2 G,4| "F#m"F,2 C,2 F,4| "Em"E,2 B,,2 E,4|
"G"G,2 D,2 G,4:|
|:"Em"E,4 E,2 A,2 ||: "G"G,,4 G,,2 D,2 | "C"C,4 C,2 G,2 | "D"D,4 D,2 A,2 |
"Am"A,,4 A,,2 E,2 | "B"B,,4 B,,2 F,2 | "Em"E,4 E,2 B,2 | "D"F,4 z2 F,2||
V:6 clef=bass name="Double Bass"
%%MIDI program 43
|:"Em"E,,2 E,2 E,4||: "D"D,,2 D,2 D,4| "C"C,,2 C,2 C4| "Bm"B,,2 B,2 B,4|
"Am"A,,2 A,2 A,4| "G"G,,2 G,2 G,4| "F#m"F,,2 F,2 F,4| "Em"E,,2 E,2 E,4|
"G"G,,2 G,2 G,4:|
|:"Em"E,,4 E,,2 E,2 ||: "G"G,,4 G,,2 G,2 | "C"C,,4 C,,2 C,2 | "D"D,,4 D,,2 D,2 |
"Am"A,,4 A,,2 A,2 | "B"B,,4 B,,2 B,2 | "Em"E,,4 E,,2 E,2 | "D"D,,4 z2 D,,2||



X:1
T:Elegy for the Fallen
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=50
K:Em
V:1 clef=treble name="Oboe"
%%MIDI program 68
|:"Em" E4 z G | G2 B2 A2 | "C" G4 z E | E2 G2 F2 | "Am" A4 z c | B2 A2 G2 | "B7" F4 z2 |[1 "E"B4 z2 :|[2 "Em"E6 |
|: "C" G3 A B2 | "G"B3 A G2 | "Am"A4 z A | "Em"G2 F2 E2 | "D"D3 E F2 | "C" E3 G F2 | "B7"G4 F2 |[1 "Em"E4 z2 :|[2 "Em"E6 |
V:2 clef=treble name="Violin"
%%MIDI program 40
|:"Em" B,4 z E | "C" E3 G F2 | "G" D4 z B, | "Am" C3 E D2 | "B7" B,4 z F | "Em" G3 B, A,2 | "C" G4 z E |[1 "Em"E4 z2 :|[2 "Em"E3 B, z2 |
|: "Em" E3 F G2 | "D" F3 E D2 | A,4 z A, | "G" B,4 z2 | "Am" A,4 c2 | "Em" B,4 E2 | "B7" F4 z2 |[1 "Em"E4 z2 :|[2 "Em"E3 B, z2 |
V:3 clef=alto name="Viola"
%%MIDI program 41
|:"Em" e4 z g | "C" c4 z e | "G" B3 A G2 | "Am" a4 z e | "B7" f4 z d | "Em" e4 z B | "C" c4 z E |[1 "Em"G4 z2 :|[2 "Em"E3 e z2 |
|: "Em" g3 a b2 | "D" d3 c B2 |[M:2/4] "Am"a4 |[M:3/4] "G" g3 f e2 | "C" e4 z g | "Em" b4 z e | "B7"[M:2/4] f4 |[M:3/4] "Em" e4 z2 :|[2 "Em"e3 e z2 |
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"Em" E,4 z E | "C" E,4 z C | "G" G,4 z D | "Am" A,4 z A, | "B7" B,4 z "_FP"F, | "Em" E,4 z E, | "C" G,4 z E, |[1 "Em"E,4 z2 :|[2 "Em"E,3 E, z2 |
|: "Em" [E,G,B,]4 z2 | "D" [F,A,]4 z2 | "Am" A,4 z A, | "G" B,4 z G, | "C" E,4 z C | "B7" F,4 z B, |_FP [F,A,]4 z2 |[1 "Em"E,4 z2 :|[2 "Em"E,3 E, z2 |



X:1
T:ABC Notation Example Enhanced
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=120
K:C
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "C"c3/2B/2 A2G2 | "Am"e3/2d/2 c2B2 | "F"A3/2G/2 F2E2 | "G"d3/2c/2 B2G2 |
"C"c3/2B/2 A2G2 | "Dm"f3/2e/2 d2c2 | "Em"g3/2f/2 e2^d2 |1 "G7"d3/2B/2 c4 :|2 "G7"d3/2B/2 c3z |]
V:2 clef=treble name="Oboe"
%%MIDI program 68
|: "C"G,3/2A,/2 C2E2 | "Am"A,3/2B,/2 C2E2 | "F"F,3/2G,/2 A2c2 | "G"G,3/2A,/2 B2d2 |
"C"G,3/2A,/2 C2E2 | "Dm"D3/2E/2 F2A2 | "Em"E3/2F/2 G2B2 |1 "G7"c3/2A,/2 B,4 :|2 "G7"c3/2A,/2 B,3z |]
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "C"C,2E,2G,2 | "Am"A,,2C,2E,2 | "F"F,2A,2C2 | "G"G,3/2F,/2 G,2D,2 |
"C"C,2E,2G,2 | "Dm"D,2F,2A,2 | "Em"E,2G,2B,2 |1 "G7"F,2E,2D,2 :|2 "G7"F,6 |]



X:1
T:Landscape Rhapsody
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=100
K:Gmaj
V:1 clef=treble name="Violin I"
%%MIDI program 40
P:A (Meadow Movement)
|: "G" D4 B,4 (3GAB G4 | "Am" A4 ^C4 E4 A,4 | "Bm" B4 d4 g4 b4 | "C" e4 g4 c4 E4 |
"G" D4 B,4 (3DEF G4 | "D" A4 F4 B,4 D4 | "Em" E4 G4 B4 e4 | "G"D6 E2 F8 :|
P:B (Mountain Movement)
K:Dm
|: "Dm" f4 a4 d4 f4 | "Gm" g4 b4 e4 g4 | "A7" a4 c'4 e4 a4 | "Dm" f6 d2 A8 |
"Dm" d4 f4 a4 d'4 | "C7" c4 e4 g4 c4 | "Bb" Bb4 d4 g4 B4 | "A7" A6 F2 G8 |
"Dm" A4 a4 f4 d4 | "Gm" B4 g4 e4 D4 | "A7" A,4 E4 A4 c4 | "Dm"d6 c2 B8 :|
P:C (Homecoming Movement)
K:Gmaj
|: "G" G4 B4 D4 G4 | "D" f4 a4 d4 F4 | "C" E2 G2 A4 E4 | "G" D6 B,2 G,8 |
"G" G4 (3DEF G2 A2 | "D7" A4 F4 D4 C4 | "G" B2 G2 E4 G4 | "D7"A4 F4 D4 C4 |
"G" G2 B2 D4 G4 | "Em" E4 G4 B,4 E4 | "A7" A4 A,4 ^C4 E4 | "D7" D4 F4 A4 d4 |
"G"D6 E2 G4 B4 | "C"D4 E4 c4 E4 | "G"B6 A2 G8 :|
V:2 clef=treble name="Violin II"
%%MIDI program 40
P:A (Meadow Movement)
|: "G" B,4 G,4 B,4 D4 | "Am" E4 A,4 ^C4 E4 | "Bm" ^F4 B,4 b,4 d4 | "C" G4 E4 g4 c4 |
"G" B,4 G,4 B,4 d4 | "D" F4 D4 f4 a4 | "Em" G4 E4 g4 b4 | "G"B6 ^F2 G8 :|
P:B (Mountain Movement)
K:Dm
|: "Dm" a4 d4 f4 a4 | "Gm" B4 e4 g4 B4 | "A7" E4 A4 c4 e4 | "Dm" A6 d2 f8 |
"Dm" d4 a4 d'4 f4 | "C7" G4 c4 e4 g4 | "Bb" d4 g4 B4 d4 | "A7" F6 D2 E8 |
"Dm" D4 a4 f4 a4 | "Gm" D4 g4 B4 d4 | "A7" E,4 A,4 E4 A4 | "Dm"a6 f2 e8 :|
P:C (Homecoming Movement)
K:Gmaj
|: "G" D4 G4 B4 D4 | "D" A4 d4 F4 A4 | "C" G2 E2 c4 G4 | "G" B,6 G,2 D,8 |
"G" B4 G4 B4 D4 | "D7" F4 c4 A4 ^G4 | "G" E2 D2 G4 B4 | "D7"A4 d4 F4 A4 |
"G" D4 G4 B4 D4 | "Em" G4 E4 G,4 B,4 | "A7" E4 E,4 G4 c4 | "D7" F4 A4 d4 f4 |
"G"B6 A2 G4 B4 | "C"E4 c4 G4 E4 | "G"D6 G2 B4 d4 :|
V:3 clef=bass name="Cello"
%%MIDI program 42
P:A (Meadow Movement)
|: "G" G,4 D,4 G,4 B,4 | "Am" A,4 E,4 A,4 C4 | "Bm" B,4 F,4 B,4 D4 | "C" c4 G,4 c4 E4 |
"G" G,4 D,4 G,4 B,4 | "D" D,4 A,4 D,4 F4 | "Em" E,4 B,4 E,4 G4 | "G" G,6 D,2 G,8 :|
P:B (Mountain Movement)
K:Dm
|: "Dm" D,4 A,4 D4 F4 | "Gm" G,4 D,4 G,4 B,4 | "A7" A,4 E4 A,4 C4 | "Dm" D,6 A,2 D,8 |
"Dm" D,4 A,4 D4 F4 | "C7" C4 G,4 C4 E4 | "Bb" B,,4 F,4 B,4 D4 | "A7" A,6 E2 F,8 |
"Dm" A,4 D4 A,4 D4 | "Gm" G,4 D,4 G,4 B,4 | "A7" E,4 A,4 e4 a4 | "Dm" d6 a2 g8 :|
P:C (Homecoming Movement)
K:Gmaj
|: "G" G,4 B,4 D4 G4 | "D" F4 A4 d4 f4 | "C" E4 c4 G4 c4 | "G" B,6 D,2 G,8 |
"G" G,4 B,4 D4 G4 | "D7" A4 F4 D4 C4 | "G" G,4 B,4 D4 G4 | "D7"F4 A4 d4 f4 |
"G" G,4 B,4 D4 G4 | "Em" E4 B,4 e4 g4 | "A7" A,4 E,4 a4 e4 | "D7" d4 F4 A4 d4 |
"G"B,6 g2 d4 G4 | "C"c4 G4 E4 c4 | "G"G,6 D4 G,8 :|



X:1
T:Trombone Trio Capers
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Gm
V:1 clef=bass name="Trombone I"
%%MIDI program 57
|:"Gm"G2 A2 | B2 A2 | G2 F2 | E4 |
D4 | C2 D2 | "D"F4 | F4 |
"A"C4 | E2 F2 | "D"A2 G2 | F2 E2 |
"Gm"G2 A2 | B2 A2 | G2 F2 | G4 :|
V:2 clef=bass name="Trombone II"
%%MIDI program 57
|:"Gm"d4 | d2 c2 | B2 A2 | G4 |
"Cm"G2 A2 | B2 c2 | D4 | "D4"F4 |
"Gm"d4 | d2 c2 | B2 A2 | "F"c4 |
"D"A2 B2 | c2 d2 | c2 B2 | "Gm"G4 :|
V:3 clef=bass name="Trombone III"
%%MIDI program 57
|:"Gm"G,2 B,2 | D2 C2 | B,2 A,2 | G,4 |
"A" A,2 C2 | E2 D2 | C2 B,2 | A,4 |
"D" F,2 A,2 | F4 | E2 F2 | G2 F2 |
"Gm"G,2 B,2 | D2 G,2 | F2 E2 | "D5"D4 :|



X:1
T:Epic Boss Confrontation
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=170
K:Em
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"Em"E2G2 B2e2 e5  | "D"d2f2 a2d'2 d'5  | "C"B2G2 E2G2 C5  | "Bm"A2F2 D2A2 F5  |
"Em"E2G2 e2g2 b5  | "D"d2f2 d2f2 a5  | "C"c2e2 c2e2 g5  | "Bm"b2a2 g2f2 e5  :|
|:"Em"[E2G2][EG] [E2G2]B2 e4 e | "D"[D2F2][DF] [D2F2]A2 d4 d | "C"[C2E2][CE] [C2E2]G2 C4 C | "Bm"[B,2D2][BD] [B,2D2]F2 A4 A |
"Em"g4 g2f2 e5  | "D"f4 f2e2 d5  | "C"e4 e2d2 c5  | "Bm"a4 a2g2 f5  :|
V:2 clef=treble name="Choir Aahs"
%%MIDI program 52
|:"Em"G,2B,2 G,4 E4 | "D"F,2A,2 F,3E D4 | "C"E,2G,2 E,4 C4 | "Bm"D,2F,2 D,4 B,4 |
"Em"G,4 B,4 E4 | "D"F,4 A,4 D4 | "C"E,4 G,4 C4 | "Bm"D,4 F,4 B,4 :|
|:"Em"G,3B, G,2G,2 E4 | "D"F,3A, F,2E,2 D4 | "C"E,2C,2 G,2G,2 C4 | "Bm"D,2B,,2 F,2F,2 B,4 |
"Em"E,4 G,4 B,4 | "D"D4 F,4 A,4 | "C"C4 E,4 G,4 | "Bm"B,4 D,4 F,4 :|
V:3 clef=bass name="Bass Synth"
%%MIDI program 39
|:"Em"[E,2G,2][E,2G,2] [E,2G,2]E,2 z4 | "D"[D,2F,2][D,2F,2] [D,2F,2]D,2 z4 | "C"[C2E2][C2E2] [C2E2]C2 z4 | "Bm"[B,,2D2][B,,2D2] [B,,2D2]B,,2 z4 |
"Em"[E,4G,4] [E,4G,4] z4 | "D"[D,4F,4] [D,4F,4] z4 | "C"[C4E4] [C4E4] z4 | "Bm"[B,,4D4] [B,,4D4] z4 :|
|:"Em"[E,3G,3] [E,2G,2]E,2 z5 | "D"[D,3F,3] [D,2F,2]D,2 z5 | "C"[C4E4] C,4 z4 | "Bm"[B,,4D4] B,,4 z4 |
"Em"[E,2G,2]E,2 G,4 z4 | "D"[D,2F,2]D,2 F,4 z4 | "C"[C4E4] E,4 z4 | "Bm"[B,,4D4] D,4 z4 :|



X:1
T:Valiant Venture
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:Am
% The introduction setting the stage for the journey
V:1 clef=treble
%% MIDI program 48
|: "Am" A2E2 A2c2 | "E" e4- e2d2 | "Am" c3A B2A2 | "G" G4- G2A2 |
"Am" A2E2 A2c2 | "E" e4- e2d2 | "Am" c3B A2G2 | "F" E6 z2 :|
V:2 clef=bass
%% MIDI program 42
|: "Am" A,2A,2 C2E2 | "E" B,2E2 G2B,2 | "Am" A,2A,2 C2E2 | "G" G,2B,2 D2G2 |
"Am" A,2A,2 C2E2 | "E" B,2E2 G2B,2 | "Am" A,2C2 E3D | "F" E2C2 A,4 :|
% The next section representing gathering of courage
V:1
|: "Dm" d4 A2d2 | "C" c4 G2c2 | "Bm" B4 F2B2 | "E" e4- e2^d2 |
"Am" A4 E2A2 | "G" G4 D2G2 | "F" F4 C2F2 | "E" E6 z2 :|
V:2
|: "Dm" D2D2 F2A2 | "C" C2C2 E2G2 | "Bm" B,2B,2 D2F2 | "E" E2B,2 G2^D2 |
"Am" A,2A,2 C2E2 | "G" G,2B,2 D2G2 | "F" F2C2 A2C2 | "E" E2B,2 E4 :|
% The section depicting the uncertain path ahead
V:1
%% MIDI program 48
|: "E" e3^d e2f2 | "Am" a4- a3g | "F" f2e2 d2c2 | "E" e4- e2f2 |
"A"^GAB=A c4 | "Dm"d^cde f4 | "G"A^GAB c2A2 | "E" E8 :|
V:2
%% MIDI program 42
|: "E" E2E2 [E2B2] z2 | "Am" A,4- A,3G, | "F" F2C2 D2C2 | "E" E4- E2F2 |
"A"A,4 [^G,G,^G,G,] | "Dm" D3^C D2E2 | "G" G,2B,2 C2A,2 | "E" E,8 :|
% The resolution evoking the bravery and moving forward
V:1
|: "Am"A2E2 c2A2 | "E"^G3E E2^D2 | "Am"A,2C2 E4 | "F"E2^D2 F4 |
"Am"A2E2 A4 | "G"B2^D2 G4 | "F"E2C2 F3E | "Am"A8 :|
V:2
%% MIDI program 42
|: "Am"A,4 A,4 | "E"E,4 B,4 | "Am"A,2C2 E4 | "F"F2C2 A,4 |
"Am"A,4 A,4 | "G"G,4 D4 | "F"F2C2 F3E | "Am"A,8 :|



X:1
T:Manifold Markets Theme
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=130
K:G
% The piece starts with an inviting riff
V:1 clef=treble name="Electric Piano"
%%MIDI program 5
|:"G"D2 B2 G4 | "D"D6 A2 | "Em"B4 E4 | "C"G6 E2 |
"G"D4 D2 G2 | "D"A6 F2 | "Em"G4 "C"E4 | "C"D8 |
"G"B6 d2 | "D"A8 | "Bm" B4 "Am" A4 | "D" G6 F2 |
"G"G4 "Em"B4 | "D"A4 "C" G4 | "G"B4 A2 G2 | "D"A8 :|
% The baseline adds the catchy groove
V:2 clef=bass name="Synth Bass"
%%MIDI program 38
|:"G"G,4 D,4 | "D"A,6 F,2 | "Em"G,4 E,4 | "C"D,6 A,2 |
"G"G,4 B,4 | "D"A,6 E,2 | "Em"G,4 "C"D,4 | "C"G,8 |
"G"B,6 D,2 | "D"A,8 | "Bm"G,4 "Am" F,4 | "D"G,6 E,2 |
"G"G,4 "Em"G,4 | "D"A,4 "C" D,4 | "G"G,4 F,2 E,2 | "D"A,8 :|



X:1
T:Final Confrontation
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Emin
% The opening sets the scene for a high-stakes boss battle
V:1 clef=treble name="Brass" subname="Br."
%%MIDI program 61
|:"Em" B,8 E8| "C" G,8 C8| "D" A,8 D8| "E5"B,4 B,4 E4 E4 |
"E" E3F G4 B4 "B"e6 "" | "D" c8 "E"B8 | "Am" A6A2 G4 F4| "B7" E4 E4 ^D4 E4|
"Em" G2^F2 G4 z4 B4| "C" c6c2 B4 A4|"D" A4 G4 F4 D4|"E5" B,8- B4 z4:|
V:2 clef=treble name="Strings" subname="Str."
%%MIDI program 48
|:"Em" E4 G4 B4 e4| "C" G4 C4 E4 g4| "D" A4 D4 F4 a4| "E5" B,4 E4 G4 B4 |
"E" e8 z8 | "B" z8 b8|"C" c8 z8 | "D" d8 z8 |
"Am" a8 z8 | "B7" b^a2b2 z3 e4f4| "Em" e4 g4 b4 "E5"e4| "C" g2f2 e4 z4 c4:|
V:3 clef=bass name="Timpani" subname="Timp."
%%MIDI program 47
|:"Em" E,4 z4 E,4 z4| "C" C,4 z4 C,4 z4| "D" D,4 z4 D,4 z4| "E5" B,,4 z4 B,,4 z4 |
"E" E,4 z4 E,4 z4| "B" z8 B,8| "C" z8 C,8| "D" z8 D,8|
"Am" A,4 z4 A,4 z4| "B7" B,4 z4 B,4 z4| "Em" z8 E,8| "C" z8 C,8:|
V:4 clef=treble name="Woodwinds" subname="Wd."
%%MIDI program 72
|:"Em" e16 e16 e15 1| "C" g16 g16 g15 1| "D" a16 a16 f15 1| "E5" b16 b16 b15 1 |
"E" e16 f16 g15 1| "B" b16 c16 b15 1|"C" g16 a16 b15 1 | "D" a16 f16 d15 1 |
"Am" a16 a16 a15 1| "B7" ^d16 e16 f15 1| "Em" e16 e16 e15 1| "C" g4 e4 G4 E4:|



X:1
T:OrchestrAI's Fantasia
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=108
K:Cmaj
%%score (V1 V2) (V3 V4) V5
V:1 clef=treble name="Violin I"
%%MIDI program 40
V:2 clef=treble name="Violin II"
%%MIDI program 40
V:3 clef=alto name="Viola"
%%MIDI program 41
V:4 clef=bass name="Cello"
%%MIDI program 42
V:5 clef=treble name="Piano"
%%MIDI program 0
% Voice 1- Violin I
[V:1]
"Cmaj7"c4 e4 | g4 a4 | "Amin7"a4 c'4 | "G"g8 | "Fmaj7"f4 a4 | c'4 d'4 | "G7"b4 e4 | "Cmaj7"g8 |
"Dmin7"d'4 a4 | f4 g4 | "Amin7"a4 c'4 | "G"g8 | "Em"e4 g4 | "A7"c'4 b4 | "Dmin"d8 | "G7"g8 |
% Voice 2 - Violin II
[V:2]
"Cmaj7"E2 G2 z4 | F2 A2 z4 | "Amin7"C2 E2 z4 | D2 G2 z4 | "Fmaj7"A2 c2 z4 | B2 d'2 z4 | "G7"D2 F2 z4 | E2 G2 z4 |
"Dmin7"F2 A2 z4 | E2 G2 z4 | "Amin7"C2 E2 z4 | D2 G2 z4 | "Em"E2 G2 z4 | F2 A2 z4 | "Dmin"D2 F2 z4 | E2 G2 z4 |
% Voice 3 - Viola
[V:3]
"Cmaj7"C2 E2 z4 | D2 F2 z4 | "Amin7"C2 E2 z4 | B,2 D2 z4 | "Fmaj7"A,2 C2 z4 | "G7"B,2 D2 z4 | "Cmaj7"C2 E2 z4 | G,2 B,2 z4 |
"Dmin7"D2 F2 z4 | C2 E2 z4 | "Amin7"E2 G2 z4 | F,2 A,2 z4 | "Em"G,2 B,2 z4 | A,2 ^C2 z4 | "Dmin"D2 F2 z4 | "G7"G,2 B,2 z4 |
% Voice 4 - Cello
[V:4]
"Cmaj7"C,8 | "Amin7"A,8 | "Fmaj7"F,8 | "G7"G,8 | "Cmaj7"C,8 | "Dmin7"D,8 | "Amin7" A,8 | "Em"E,8 |
"Fmaj7"F,8 | "G7"G,8 | "Cmaj7"C,8 | "Dmin7"D,8 | "Amin7"A,8 | "Em"E,8 | "Fmaj7"F,8 | "G7"G,8 |
% Voice 5 - Piano (left hand will automatically double the right hand one octave below)
[V:5]
"Cmaj7" z2 c2 e2 g2 | "Amin7" z4 a2 c'2 | "Fmaj7"f4 f2 a2 | "G7"g2 b2 d'2 g2 | "Cmaj7"z2 c2 e2 g2 | "Dmin7"d4 d2 f2 | "Amin7"a4 a2 c'2 | "Em"e4 e2 g2 |
"Fmaj7" f4 f2 a2 | "G7"g2 g2 b3 z | "Cmaj7"c4 e2 g2 | "Dmin7"d4 f2 a2 | "Amin7"a2 a4 c'2 | "Em"e4 g2 b2 | "Fmaj7"f4 f2 a2 | "G7"g4 g2 g2 |



X:1
T:Epic Boss Confrontation
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=170
K:Bmin
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"Bm"d2f2B2d2f4d2|"F#"B2A2A2F2D4F2|"G"g2b2d2g2b4g2|"A"a2c'e2a2c'4a z2|
"Bm"d3c'd3c'bf2d z2|"F#"A3c'A3c'e3d'B2|"G"G6B2d4B2|"A"A8-a4z2:|
|:"Bm"[d2f2][df] [B2d2]z [f6d6]z2|"F#"f4A2F2D z24|"G"G3^FG3=B2z2B z2|"A"A2a2e2a2c' z24|
"Bm"d2d2f2d2B2d2f2|"F#"A2B2c2A2F2A2c2|"G"G2B2d2G2B2d2B2|"A"A6 z2 a7 :|
V:2 clef=treble name="Strings"
%%MIDI program 49
|:"Bm"[B2F2] [BF] [B2d2]z [F6B6]z2|[F#]"F#"f2d2c2f2A2d2c|"G"[G2B2][GB][G2d2]z [B6G6]z2|"A"[A2c'2][Ac'][A2e'2]z [c'6A6]z2|
"Bm"[B2F2][BF][B2d2]z[d2f2 z6]|"F#"[F2A2][FA][F2c2]z[c2e2 z6]|"G"[G2B2][GB][G2d2]z z6 [d2g2]|"A"[A2c'2][Ac'][A2e'2]z z4 a4:|
|:"Bm"[B2d2] [BF] [F2d2] z [d6F6]z2|"F#"[A2c2]c2A2F2D z42|"G"[G2B2]z2[B2g2]z2G z42|"A"[A2c'2] z2 [c'2e'2] z2 z4 c'2|
"Bm"[b2f2][bf] [d2f2] z [f6d6]z2|"F#"[F2a2][Fa][c2a2] z [a6c'6]z2|"G"[g2b2][gb] [d2b2] z [b6g6]z2|"A"[a4c'4]z2 z4 a4:|
V:3 clef=bass name="Bass Synth"
%%MIDI program 39
|:"Bm"B,6F,B,6 F,|"F#"[F,,2A,,2][F,A,][F,,2A,,2]"F#"A,| "G"[G,2B,2][G,B,][G,2B,2]G,|"A"[A,2C,2][A,C,][A,2C,2]A,|
"Bm"B,,6 F,B,,6 F,|"F#"[F,,2A,,2][F,A,]"F#"[F,,2A,,2][A,,2C,2 z7]|"G"[G,,2B,,2][G,B,]G,, z56|"A"A,,8-z4 z2:|
|:"Bm"[B,,2F,2][B,F,][B,,2F,2]z[F,,6B,,6]z2|"F#"[A,,2C,2]F,[1A,,2C,2]F,2D, z32|"G"[G,,2B,,2]z2[G,2D,2]z2G,, z42|"A"[A,,2C,2]z2 z4 A,2z2C,2|
"Bm"[B,,2F,2][B,F,][B,,2F,2]z z4 B,4|"F#"[F,,2A,,2][F,A,][F,,2C,2]z [C,6A,,6]z2|"G"[G,,2B,,2][G,B,][G,,2D,2]z [D,6G,,6]z2|"A"A,,6z2 C,A,,5:|



X:1
T:Fantasy Quest Overture
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=112
K:Em
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "Em"B2E GFE | "D"A3 - A2 F | "C"G2E CDE | "Bm"F3 - F2 G |
"Am"A2E CEA | "D"F2A dAF | "Em"G2B eBG |[1 "Bm"F3 F3 :|[2 "Bm"F3 - F2 z ||
|: [K:G]"G"B3 dB | "C"G3 - G2 A | "D"A3 dAd | "C"E3 - E2 G |
"Em"G2B eBg | "C"e2c A2c | "Bm"d3 BAF |[1 "Em"E3 - E2 z :|[2 "Em"E3 E3 |]
V:2 clef=treble name="Horn"
%%MIDI program 61
|: "Em"E3 B,3 | "D"F3 A,3 | "C"G3 E3 | "Bm"F3 D3 |
"Am"A3 E3 | "D"F3 A3 | "Em"G3 B,3 |[1 "Bm"F3 F3 :|[2 "Bm"F3 - F2 z ||
|: [K:G]"G"d3 B2 | "C"G3 E3 | "D"A3 F3 | "C"E3 C3 |
"Em"B3 e3 | "C"c3 A3 | "Bm"d3 B3 |[1 "Em"E3 - E2 z :|[2 "Em"E3 E3 |]
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "Em"E,3 E,3 | "D"D,3 D,3 | "C"C,3 C,3 | "Bm"B,3 B,3 |
"Am"A,3 A,3 | "D"F,3 F,3 | "Em"E,3 E,3 |[1 "Bm"F,3 F,3 :|[2 "Bm"F,3 - F,2 z ||
|: [K:G]"G"G,3 G,2 | "C"C,3 C,3 | "D"D,3 D,3 | "C"C3 C3 |
"Em"E,3 E,3 | "C"C,3 C,3 | "Bm"B,3 B,3 |[1 "Em"E,3 - E,2 z :|[2 "Em"E,3 E,3 |]
V:4 clef=treble-8 name="Strings"
%%MIDI program 48
|: "Em"B,3 E,3 | "D"A,3 D,3 | "C"G,3 C3 | "Bm"F,3 B,3 |
"Am"A,3 C3 | "D"F2 A dc z | "Em"B,3 B,3 |[1 "Bm"F2 F2 z2 :|[2 "Bm"F4 - F2 ||
|: [K:G]"G"B,3 G,2 | "C"G,2 G A B z | "D"A,3 A,3 | "C"E2 E G A z |
"Em"E3 B,3 | "C"C E G c z2 | "Bm"d2 d2 B z |[1 "Em"E4 - E2 :|[2 "Em"E6 |]



X:91
T:Woodchopper's Reel
S:Bernie Waugh
C:Bernie Waugh, accompaniment added by OrchestrAI
R:Reel
M:2/4
L:1/16
K:D
V:1
|:fg|\
"D"afdB AdFA|DFAd f2ef|"A"(3gfe cB z2 Aceg|a^gab a=gfg|
"D"afdB AdFA|DFAd f2ef|"A"(3gfe cB z2 Acec|"D"dfec z2 d2:|
|:FE|\
"D"DFAd (3BdB z2 AF|DFAd (3BdB z2 A2|"A"A,CEA ceaf|gfed cBAF|
"D"DFAd (3BdB z2 AF|DFAd (3BdB z2 A2|"A"A,CEA ceaf|(3gfe ce z4 "D"d2:|
V:2 clef=bass name="Bass"             % The bass line
%%MIDI program 32                     % Acoustic Bass
K:D
|:D2|\
"D"D2A,2 D3F|"D"A,2D2 F3A|"A"A,2C2 "A7"E3C|"D"D2A,2 A,3D|
"D"D2A,2 D3F|"D"A,2D2 F3A|"A"A,2C2 "A7"E3C|"D"D2A,2 A,4:|
|:A2|\
"D"F2A,2 "D"A,3D|"D"F2A,2 "D"A,3D|"A"C2A,2 "A7"A,3E|"A"E2C2 C3A|
"D"F2A,2 "D"A,3D|"D"F2A,2 "D"A,3D|"A"C2A,2 "A7"A,3E|"D"D2F2 F4:|
V:3 clef=treble name="Piano R. Hand"   % The piano chords, right hand
%%MIDI program 0                      % Acoustic Grand Piano
K:D
|:fg|\
"D" [A4d4] [Adf3]e|d[Ad][Ad] f3e|"A" e[ce][ce] [Ac]2 e2|ga ag ag fg|
"D" [A4d4] [Adf3]e|d[Ad][Ad] f3e|"A" e[ce][ce] [Ac]2 ec|"D" d2f2 d2:|
|:FE|\
"D" [Ad][Ad] [Adf3]d|[Ad][Ad] [AdF3] A2|"A" [Ec][Ec] [Acea]f|[gf][gf] ed cBAF|
"D" [Ad][Ad] [Adf3]d|[Ad][Ad] [AdF3] A2|"A" [Ec][Ec] [Acea]f|g2 ec "D" d4:|
V:4 clef=bass name="Piano L. Hand"    % The piano chords, left hand
%%MIDI program 0                      % Acoustic Grand Piano
K:D
|:D2|\
"D" [D,2A,2] [D,2A,2]|A,,D, F,A,|"A"[E,2A,2] [E2A,2]|A,,D, D,2A,,|
"D" [D,2A,2] [D,2A,2]|A,,D, F,A,|"A"[E,2A,2] [E2C2]|D,2A,2 D4:|
|:A2|\
"D" [F,2A,2] [A,2D2]|[F,2A,2] [A,2D2]|"A" [C2E2] [E2A2]|E,C C2A,2|
"D" [F,2A,2] A,,2A,2|[F,2A,2] [A,2D2]|"A" [C2E2] [E2A2]|D2F2 D4:|



X:1
T:Neon Chase
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Bmin
% The composition starts abruptly to immediately immerse the listener in the chase.
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"Bm" B4c4d4e4 | "G" g4f4e4d4 | "A" A4A4 c6B2 | "Bm" b8a8 |
"Bm" b4a4g4^f4 | "Emin" e8^f8 | "F#m" f4e4d4c4 | "Bm" b16 |
"G" ^g4a4b4g4 | "A" f4e4d4B4 | "Emin" ^e4^f4^g4e4 | "Bm" d4c4B4B2z2 :|
V:2 clef=bass name="Bass Synth"
%%MIDI program 38
|:"Bm" B,16 | "G" G,16 | "A" A,16 | "Bm" B,16 |
"Bm" B,12G,4 | "Emin" E,16 | "F#m" F,16 | "Bm" B,16 |
"G" ^G,8G,8 | "A" A,8G,8 | "Emin" E,16 | "Bm" B,16 :|
V:3 clef=treble name="Percussive Synth"
%%MIDI program 118
|:"Bm" z2B2z2c2z2d2z2e2 | "G" z2g2z2f2z2e2z2d2 | "A" z2A2z2A2z2c2z2B2 | "Bm" z2b2z2b2z2a2z2a2 |
"Bm" z2b2z2a2z2g2z2^f2 | "Emin" z2e2z2e2z2^f2z2^f2 | "F#m" z2f2z2e2z2d2z2c2 | "Bm" z2b2z2b2z2b2z2b2 |
"G" z2^g2z2a2z2b2z2g2 | "A" z2f2z2e2z2d2z2B2 | "Emin" z2^e2z2^f2z2^g2z2e2 | "Bm" z2d2z2c2z2B2z2B2 :|



X:1
T:Mechanized Golem's Awakening
C:OrchestrAI
M:5/4
L:1/8
Q:1/4=140
K:Cm
% Ancient vibes with a harmonic structure that gives space to the mechanized elements
V:1 clef=treble name="Lead Synth" subname="Melody"
%%MIDI program 81
|:"Cm"G2G2E2F2G z42|E2F2G4 z2A2C2|"Fm"D2C2B2A2G z42|F4E4 z2A2G2|
"D#m"G2F2E2D2C z42|D4E4 z2G2E2|"G#m"F4G4 z2B2A2|"Cm"G,4-A,4 z2C2E2|
|:"Cm"E4-G4 z2G2B2|"Fm"D4-F4 z2A2c2|"D#m"C4-E4 z2F2A2|"G#m"B,4-G,4 z2E2G2|
"Cm"G2G2E2F2G z42|E2F2G4 z2 z4|"Fm"D2F2A2c2 z4 d2|c2B2A4 z2 z4:|
V:2 clef=treble name="Strings" subname="Harmony"
%%MIDI program 48
|:"Cm"c4c4 c2e2g2|g2a2 c6 z2e2g2|"Fm"f4f4 f2a2c'2|a2g2 f6 z2c'2a2|
"D#m"g4g4 g2b2d2|d2e2 g6 z2b2d2|"G#m"c'4c'4 c'2e'2g'2|e'2f'2 c'6 z2g'2e'2|
|:"Cm"e4e4 e2g2c'2|c2d2 g6 z2c'2e2|"Fm"f4f4 f2a2d2|d2c2 f6 z2a2f2|
"D#m"g2g2f2e2d z42|e4g4 z2c2a2|"G#m"c'2d'2e'2 d'4c'4|a,4-f,4 z2c2e2|
"Cm"g2e2c2 z2 d2e2g2|a2g2 c4 z2 z4|"Fm"f2d2a2 z2 c'2d'2f'2|g'2f'2e'4 z2 z4:|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|:"Cm"C2,4 z2C2, z3 E,2|E,2,8 z2C2, G,2|"Fm"F,2,4 z2F,2 z3 A,2|A,2,8 z2F,2 E,2|
"D#m"D,2,4 z2D,2 z3 F,2|F,2,8 z2D,2 G,2|"G#m"G,,2,4 z2G,,2 z3 B,,2|B,,2,8 z2G,,2 C,2|
|:"Cm"c,2,4 z2c,2 z3 e,2|e,2,8 z2c,2 g,2|"Fm"f,2,4 z2f,2 z3 a,2|a,2,8 z2f,2 c,2|
"D#m"g,2,8 z z4|g,2,4 z2d,2 z3 f,2|"G#m"g,,2,8 z z4|g,,2,4 z2c,2 z3 e,2|
"Cm"C2, E, G, C2, E, z6 G,|C,8 z2 z4|"Fm"F, C F A2, C z7 F|F,8 z2 z4:|



X:1
T:Neon Shadows
C:OrchestrAI
M:3/4
L:1/16
Q:1/4=80
K:Cmin
% The piece conveys tension and precision during a stealth mission
V:1 clef=treble name="Synth Lead" subname="Lead"
%%MIDI program 81
|: "Cm"G4z4 B,4 | "Cm"c8 z4  | "Ab"G4z2F2G2 AB | "Bdim"c4B4G4 |
"Cm"e8 z5  | "Ab"G4z4 C2D2 | "Fm"F4z4 F,4 | "G"G,4z4 .B,G,D,G, |
"Cm"G,3B, E4z4 | "Cm"E4C4 E5 | "Ab"G,3C F4z4 | "Bdim"F4D4 B,5 |
"Cm"G,4z4 G,4 | "Ab"A4z4 C5 | "Fm"F4EF G2A2 z2 | "G"B,8 z4 :|
V:2 clef=bass name="Synth Pad" subname="Pad"
%%MIDI program 92
|: "Cm"C,4E,4 G,4 | "Cm"C,4D,4 G,4 | "Ab"AB,C2E2F2G2 z2 | "Bdim"B,6C4 z2 |
"Cm"C,4E,4 G,4 | "Ab"AB,C2E2C4 z2 | "Fm"F,8 G,4| "G"D,4G,4 B,4 |
"Cm"E,4C4 E,4 | "Cm"E,8 G,5 | "Ab"AB,C2E2F2G2 z2 | "Bdim"B,6C4 z2 |
"Cm"C,4E,4 G,4 | "Ab"A6B4 z2 | "Fm"F,2G2A2B2 c4 | "G"G,2F2E2F2 G,4 :|
% Rhythmically varied for suspense; matching the stealthy mood of the melody



X:1
T:Before The Storm
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:Cmin
V:1 clef=treble
%%MIDI program 73
|:"Cm"G2 E2 G2 z2|"Ab"AB cB AG FE|"Eb"G2 E2 G2 z2|"Bb"D2 F2 D2 z2|
"Cm"E2 C2 E2 G2|"G"AB cB AG FE|"Cm"D2 C2 E2 G2|"G7"C4 B,4|
"Ab"A2 c2 e2 a2|"Eb"g2 fe dc B2|"Ab"c2 e2 a2 g2|"G"f2 ed cB AG|
"Cm"E2 G2 C2 E2|"G7"G,2 B,2 D2 G,2|"Cm"C2 E2 G2 C2|"G7"B,4 z4:|
V:2 clef=bass
%%MIDI program 42
|"Cm"C3/E/ G,3/C/|"Ab"E,2 F,2 E,2 D,2|"Eb"G,2 A,2 B,2 C2|"Bb"E,2 F,2=B,2 C2|
"Cm"C2 E2 G2 C2|G,2 A,2=B,2 C2|"Cm"G,2 B,2 D2 G,2|"G7"D,2 F,2=B,2 E2|
"Ab"E2 E2 E2 E2|"Eb"B,,3/E/ G,3/B,|"Ab"E2 E2 E2 E2|"G"D,2 E,2 F,2 G,2|
"Cm"C2 E2 G2 C2|"G7"D,3/F/ A,3/C/|"Cm"C2 E2 G2 C2|"G7"D,4 z4:|



X:1
T:Summer Daydream
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:G
% The piece begins with a bright and warm melody.
V:1 clef=treble name="Flute" subname="Fl."
%%MIDI program 73
|:"G"B4 z2 G2|"C" E6 D2| "D7"A3 G F2E2| "G"G4- G2 z2|
"C"e4 e2 d2|"G"d2 B3 z G2|"Am"A6 "D7"G2| "G"G4- G2 z2|
"Bm"d3 B G2 F2|"Em"G2 E4 D2| "C"E3 C G,2 E2|"D"D4- D2 z2|
"G"B4 d2 B2|"C"c3 B A2 G2|"Am"B4 "D7"A2 F2|"G"G4- G2 z2:|
V:2 clef=bass name="Cello" subname="Cello"
%%MIDI program 42
|:"G"G,4 B,2 G,2|"C"C,4 E,2 C,2| "D7"A,,4 A,,2 D,2| "G"G,4- G,2 z2|
"C"E,4 G,2 E,2|"G"G,4 B,2 G,2|"Am"A,4 "D7"A,2 D,2|"G"G,4- G,2 z2|
"Bm" B,4 D2 B,2|"Em"G,3 E C2 B,2| "C"C3 E G,2 E,2|"D"D,4- D,2 z2|
"G"G,4 B,4|"C"C,3 E G,2 E,2|"Am"A,2 F,2 "D7"D,2 F,2|"G"G,4- G,2 z2:|



X:1
T:Gratitude Melody
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:G
V:1 clef=treble
%%MIDI program 1
|:"G"D2B2 G2A2|"C"E3F G2E2|"Am"A2A2 c2B2|"D7"A4 F2D2|
"G"G2B2 d2B2|"Em"B3A G2E2|"C"G2E2 C2D2|"D7"F4 D4|
"G"B3c d2B2|"C"e2d2 c2B2|"Am"A3G F2E2|"D" D3E F2A2|
"G"B2d2 "C"e3d|"D7"c2A2 F2D2|"G"G4- "Em"G2F2|"D7"A4- A2F2:|
V:2 clef=treble
%%MIDI program 1
||:"G"B,2G2 B,2A2|"C"E3^D E2C2|"Am"A,2C2 E2^D2|"D7"F3=G A2F2|
"G"G2B,2 D3C|"Em"B,2E2 G2F2|"C"C2E2 G2F2|"D7"D4 D2C2|
"G"B,3D G2B,2|"C"E2C2 D3^D|"Am"A,2A,2 A,2^G2|"D"A,2A,2 D2E2|
"G"B,3D "C"E2E2|"D7"F3=G A2D2|"G"G,4- "Em"G2E2|"D7"F4- F2D2:|
V:3 clef=bass
%%MIDI program 33
||:"G"G,2B,2 D2G,2|"C"C2E2 A,2C2|"Am"A,2A,2 C2E2|"D7"A,2F2 F2A,2|
"G"B,2D2 G,2B,2|"Em"E,2G2 B,2E2|"C"C2E2 G,2C2|"D7"D4 F2A,2|
"G"G,2B,2 D2B,2|"C"C2E2 G,2E2|"Am"A,2C2 E2C2|"D"F4 A,2D2|
"G"G,2B,2 "C"C2E2|"D7"D3^C D2F2|"G"G,4- "Em"G2F2|"D7"D4- D2A,2:|



X:1
T:Into the Void
C:OrchestrAI
M:C
L:1/8
Q:1/4=60
K:Am
V:1 clef=treble
%%MIDI program 75
|: "Am"e4 z4 | "C"E4 E2 F2 | "Dm"A4 z2 F2 | "E" G,6 B,2 |
"Am"e4 ^d4 | "C"e2 G2 E4 | "F"A4 z2 c2 |"E" B,6 z2 :|
V:2 clef=treble
%%MIDI program 75
|: z4 z4 | z2 C2 E2 G2 | "Dm"F4 D2 z2 | z4 z4 |
z4 z4 | "C"E2 G2 E4 | "F"c4 A2 z2 |z6 z2 :|
V:3 clef=bass
%%MIDI program 70
|: "Am"A,4 z4 | "C"C4 C2 B,2 | "Dm"D4 z2 A2 | "E"E,6 z2 |
"Am"A,4 ^C4 | "C"C2 E2 C4 | "F"F4 z2 F2 | "E"E,6 z2 :|
V:4 clef=bass
%%MIDI program 70
|: "Am"A,2 E2 A,2 E2 | "C"C2 E2 C2 G,2 | "Dm"D2 F2 D2 A,2 | "E"E2 G,2 B,2 G,2 |
"Am"A,2 F2 A,2 E2 | "C"C2 E2 G,4 | "F"F2 A2 c2 f2 | "E"E2 ^D2 E4 :|



X:1
T:Twilight Mystique
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=60
K:Amin
% Strings Section - Violins representing wonder and curiosity
V:1 clef=treble
%%MIDI program 41
|: e4A4c4e4 | a4g4f4e4 | d4^c4d4e4 | f8e8 :|
% Woodwinds Section - Flutes and Clarinets for playfulness
V:2 clef=treble
%%MIDI program 73
|: e2g2a2f2e4z4 | g2e2f2d2e4z4 | a2^c2d2e2d4z4 | c8z8 :|
% Introspection - Cellos and Basses in the lower register
V:3 clef=bass
%%MIDI program 42
|: A,8E4A,4 | F,8D4F,4 | E,8C4E,4 | A,8A,8 :|
% Majesty and Grandeur - Full Brass Section
V:4 clef=treble
%%MIDI program 61
|: E4E4A4c4 | d4d4f4e4 | c4c4e4A4 | B8A8 :|
% Tapering off - Harp and Flute for peaceful closure
V:1 clef=treble
%%MIDI program 46
|: e8- z8 | e4f4g4a4 | bc'4a4b4g3 | f8e8 :|
V:2 clef=treble
%%MIDI program 73
|: a8- z8 | a4b4c'4d'4 | e'4d'4c'4b4 | a8z8 :|



X:1
T:Temple of Secrets Theme
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=50
K:Gm
% The piece opens with an ethereal melody that suggests secrecy and reverence
V:1 clef=treble name="Pipe Organ"
%%MIDI program 19
|:"Gm"G4 B2 | "Dm" A4 G2 | "Aº"A2>B2 c2 | "Eb" B3 A G2 |
"Cm"C4 E2 | "F" F3 E D2 | "Bb" D4 F2 | "Gm" G6 |
"Gm"G2 A2 B2 | "Cm" G4 E2 | "Dm"F2 G2 A2 | "Bb" F4 D2 |
"Eb"E4 G2 | "Dº" F4 E2 | "Gm"D2>E2 F2 | "Gm"G6 :|
V:2 clef=bass name="Strings Ensemble"
%%MIDI program 48
|:"Gm"D,6 | "Dm"A,6 | "Aº"C3>C, D,2 | "Eb"B,,6 |
"Cm"E,4 G,2 | "F"F,4 A,2 | "Bb"D,4 F,2 | "Gm"G,6 |
"Gm"B,,4 D,2 | "Cm"E,6 | "Dm"A,4 C2 | "Bb"B,,4 D,2 |
"Eb"G,4 B,,2 | "Dº"F,6 | "Gm"D,4 G,2 | "Gm"G,6 :|
V:3 clef=treble name="Choir Aahs"
%%MIDI program 52
|:"Gm"G2 z2 B2 | "Dm"A2 z2 G2 | "Aº"A2>B2 c2 | "Eb" B3 A G2 |
"Cm"C2 z2 E2 | "F" F3 E D2 | "Bb" D2 z2 F2 | "Gm" G6 |
"Gm"G2 A2 B2 | "Cm" G2 z2 E2 | "Dm"F2 G2 A2 | "Bb" F2 z2 D2 |
"Eb"E2 z2 G2 | "Dº" F4 E2 | "Gm"D2>E2 F2 | "Gm"G6 :|
% The melody is accompanied by soft, sustained choir voices to enhance the sacred ambiance



X:1
T:Remembrance Elegy
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=50
K:Am
V:1 name="Violin" clef=treble
%%MIDI program 40
|: "Am"A4 E2DC | "Dm"D4 E2FG | "Am"E6 A2 | "E"E4 C4 |
"Am"A,4 A,2E2 | "Dm"F4 D4 | "Am"A4 G2E2 | "E"E6 z2 :|
V:2 name="Viola" clef=alto
%%MIDI program 41
[|: "Am"A,4 C2E2 | "Dm"D4 F2A2 | "Am"E4 A2c2 | "E"E4 G2B2 |
"Am"A,6 E2 | "Dm"D4 F4 | "Am"A,4 E2C2 | "E"E6 z2 :|
V:3 name="Cello" clef=bass
%%MIDI program 42
[|: "Am"A,4 E4 | "Dm"D4 A,4 | "Am"A,4 C4 | "E"E4 G4 |
"Am"A,4 C4 | "Dm"D4 F4 | "Am"A,4 E4 | "E"E6 z2 :|
V:4 name="Bass" clef=bass octave=-1
%%MIDI program 43
[|: "Am"A,2 E2 A,2 E2 | "Dm"D2 A,2 D2 F2 | "Am"A,2 E2 A,2 C2 | "E"E2 B,2 E2 G2 |
"Am"A,2 C2 E2 A,2 | "Dm"D2 F2 A,2 D2 | "Am"A,2 E2 A,2 E2 | "E"E4 z4 :|



X:1
T:Photo Finish Frenzy
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Em
V:1 clef=treble name="Electric Guitar"
%%MIDI program 28
|: "Em" e4 B4 G4 B4 | "D" f4 A4 F4 A4 | "C" e4 c4 G4 c4 | "B7" ^d4 B4 F4 B4 :|
w: Main Theme - Accelerating
|: "Em" (3efe^d e2B2 G2E2 B2G2 z3 | "D" (3fgf=d f2A2 F2D2 A2F2 z3 | "C" (3gag=f g2c2 G2E2 c2G2 z3 | "B7" (3^d=dc^d B2F2 ^D2B,2 F2^D2 z3 :|
w: Tight Turns
V:2 clef=treble name="Synth Lead"
%%MIDI program 81
|: "Em" B,4 E4 G4 B4 | "D" A,4 D4 F4 A4 | "C" G,4 C4 E4 G4 | "B7" ^F,4 B,4 ^D4 F4 :|
w: Harmonic Support
|: "Em" G3A B3c d3e g3a | "D" f3g a3b "C" e3f g3a | "B7" ^d3e f3g a3b ^d'3e' :|
w: Ascending Lines
V:3 clef=bass name="Synth Bass"
%%MIDI program 38
|: "Em" E,4 B,,4 E,4 G,4 | "D" D,4 A,,4 D,4 F,4 | "C" C,4 G,,4 C,4 E,4 | "B7" B,,4 ^F,,4 B,,4 ^D,4 :|
w: Driving Bass
|: "Em" [E,4E,,4] [G,4G,,4] [B,4B,,4] [E4E,4] | "D" [D,4D,,4] [F,4F,,4] [A,4A,,4] [D4D,4] | "C" [C,4C,,4] [E,4E,,4] [G,4G,,4] [C4C,4] | "B7" [B,,4B,,,4] [^D,4^D,,4] [^F,4^F,,4] [B,] :|
w: Power Chords
V:4 clef=bass name="Cello"
%%MIDI program 42
|: "Em" E,2G,2 B,2E2 G2B2 e2g2 | "D" D,2F,2 A,2D2 F2A2 d2f2 | "C" C,2E,2 G,2C2 E2G2 c2e2 | "B7" B,,2^D,2 ^F,2B,2 ^D2F2 B2^d2 :|
w: Scalar Runs
|: "Em" (3E,F,G, (3A,B,C (3DEF (3GAB | "D" (3D,E,F, (3G,A,B, (3CDE (3FGA | "C" (3C,D,E, (3F,G,A, (3B,CD (3EFG | "B7" (3B,,C,D, (3E,F,G, (3A,B,C (3DEF :|
w: Chromatic Intensity



X:1
T:Whimsical Escapades
C:OrchestrAI
M:3/4
L:1/16
Q:1/4=120
K:G
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "G" d4 B3G A2B3 | "C" e6 c2E2G2 | "D" F4 D2 F2A2B2 | "G" G6- G4 z2 |
"Am" e2e2e2 e2d2^c2 | "D7" d6 A2F2E2 | "G" G4 B2 d2g2e2 | "Em" e6- e4 z2 |
"C" G6 E2C2E2 | "G" D4 B,2 D2G2B2 | "A7" c6 A2c2e2 | "D7" F6- F4 z2 |
"G" d2B2G2 d2B2G2 | "C" e2c2A2 e2c2A2 | "D" F2A2c2 "G" B4 z2 :|
V:2 clef=treble name="Oboe"
%%MIDI program 68
|: "G" g4 e2 d2c2B2 | "C" c6 E4 z2 | "D" A4 F4 z4 | "G" G2D2 G2C2E2 z2 |
"Am" e4 c2 E2D2^C2 | "D7" A4 F4 z4 | "G" G4 B2 d2g2B2 | "Em" e4 c4 z4 |
"C" c2E2 G4 z4 | "G" d2B2 G4- z4 | "A7" A2E2 C4 z4 | "D7" d2A2 F4 z4 |
"G" g2e2 c2 "C"e4 z2 | "D" d2f2 a2 "G" g4 z2 | "G" B2d2 g4- z4 :| "G" g4 z2 z6 :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "G" G,4 D4 z4 | "C" C4 E4 z4 | "D" D4 A,4 z4 | "G" G,2B,2 D4 z4 |
"Am" A,4 C4 z4 | "D7" A,4 F4 z4 | "G" G,4 B,4 "Em" E5  |
"C" C4- C4 G,4 | "G" B,4 D4 z4 | "A7" A,4 C4 "D7" D5  |
"G" G,2D2 G4 z4 | "C" g,4 e4 "D7" d5  | "G" G,2B,2 D3E F4- | "G" G,4 z2 z6 :|



X:1
T:Trombone Trio Capers Revised
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Gm
V:1 clef=bass name="Trombone I"
%%MIDI program 57
|:"Gm"G2 A2 | B2>d c | G4 | E4- E |
"Dm"D2>C F | C2D2 | "D"F5   | F4 |
"A7"C2 ^C2 | E2 F2 | "D"A>G A2 | F5  |
"Gm"G3 A | B2 A2 | G2>F G> | G4 :|
V:2 clef=bass name="Trombone II"
%%MIDI program 57
|:"Gm"d2>d d | d>e c3 | "Cm"B3 A | G4 |
G4 | A>^G A2 | "F"D4 | "D7"F4 |
"Gm"d4 | d>c B2 | B>A G2 | "F"A4 |
"D"A>B c2 | d2 e2 | "Eb"c3 B | "Gm"G4 :|
V:3 clef=bass name="Trombone III"
%%MIDI program 57
|:"Gm"G,2G, B, | D4 | B,>A, G,2 | G,4 |
"A"A,2C2 | E4 | "D7"D3 C | A,4 |
"D"F,2>F A, | F4 | E>E F2 | G2 F2 |
"Gm"G,2G, B, | D4 | "F7"F2 E2 | "D7"D4 :|



X:1
T:Inspirational Wonder
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=100
K:Emaj
V:1 clef=treble name="Grand Piano"
%%MIDI program 0
|: "E"B4 ^G4 A4 F4 | "B"e4 c4 B4 G4 | "A"E8- E6 F2 | "E/B"B,8- B,6 C2 |
"E"E4 ^G4 A4 F4 | "B"e4 c4 d6 ^c2 | "Am"e6 A4 G4 F2 | "B7"B4 g4 ^f4 e4 :|
V:2 clef=treble
%%MIDI program 0
|: "E"e4 ^d4 e4 B4 | "B"g4 e4 ^d4 B2 A2 | "A"^c4 A4 F4 E4 | "E/B"A4 E4 B,4 E4 |
"E"e4 ^d4 e4 B4 | "B"g4 f4 g6 ^f2 | "Am"A4 e4 c4 A2 G2 | "B7"B4 A4 ^G4 F4 :|
V:3 clef=bass
%%MIDI program 0
|: "E"B,4 E4 F4 B,4 | "B"B,4 F4 G4 B,4 | "A"C4 A,4 B,4 C4 | "E/B"E,4 B,4 C4 B,4 |
"E"B,4 E4 F4 B,4 | "B"B,4 G4 A4 B,4 | "Am"E4 C4 D4 E4 | "B7"B,4 A4 ^G4 F4 :|



X:1
T:Sanctuary of Solace
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=72
K:Gmaj
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G" B3 d B2|"Em" g3 f e2|"C" c3 B A2|"D" F3 G A2|
"G" B3 d B2|"G7" d3 c B2|"C" c3 E G2|"D7" F4 z2|
"C" e3 g e2|"Bm" f3 e d2|"Am" a3 g f2|"D" g2 f3 g|
"G" g3 b a2|"A7" a3 b c'2|"D7" d'3 c' b2|"G" g4 z2:|
|:"Em" e4 z2|"C" e3 c e2|"D" d3 B d2|"G" g4 z2|
"G" b3 a g2|"G7" f3 e d2|"Cmaj7" e3 d c2|"Am7" B2 A3 G|
"Em" e4 z2|"C" e3 c e2|"A7" f3 d f2|"D7" g4 z2|
"G" B3 d B2|"Em" g3 f e2|"C" c3 E G2|"G" G4 z2:|
V:2 clef=treble name="Strings"
%%MIDI program 48
|:"G"D2 G2 B2|"Em"E2 G2 B2|"C"c2 E2 G2|"D"F2 A2 d2|
"G"G2 B2 d2|"G7"F2 A2 d2|"C"E2 G2 c2|"D7"D2 F2 A2|
"C"G2 c2 e2|"Bm"F2 B2 d2|"Am"A2 c2 e2|"D"F2 A2 d2|
"G"G2 B2 d2|"A7"E2 A2 c'2|"D7"D2 F2 A2|"G"G4- G2:|
|:"Em"B,2 E2 G2|"C"E2 c2 E2|"D"D2 B2 D2|"G"G4- G2|
"G"B2 d2 g2|"G7"A2 d2 f2|"Cmaj7"G2 e2 g2|"Am7"A2 B2 c2|
"Em"B,2 E2 G2|"C"E2 c2 E2|"A7"A2 d2 f2|"D7"A2 d2 f2|
"G"G2 B2 d2|"Em"E2 G2 B2|"C"G2 c2 e2|"G"G4- G2:|
V:3 clef=bass name="Guitar"
%%MIDI program 24
|:"G"G,2 B,2 D2|"Em"E2 G2 B2|"C"C2 E2 G2|"D"D2 F2 A2|
"G"G,2 B,2 D2|"G7"D2 F2 A2|"C"C2 E2 G2|"D7"D2 A,2 D2|
"C"E,2 G,2 C2|"Bm"B,2 D2 F2|"Am"A,2 C2 E2|"D"D2 F2 A2|
"G"G,2 D2 G2|"A7"A,2 C2 E2|"D7"D2 A,2 D2|"G"G,4- G,2:|
|:"Em"E,2 B,2 E2|"C"C2 G,2 C2|"D"D2 A,2 D2|"G"G,4- G,2|
"G"G,2 D2 G2|"G7"D2 A,2 D2|"Cmaj7"C2 G,2 C2|"Am7"A,2 E2 A2|
"Em"E,2 B,2 E2|"C"C2 G,2 C2|"A7"A,2 E2 A2|"D7"D2 A,2 D2|
"G"G,2 D2 G2|"Em"E,2 B,2 E2|"C"C2 E2 G2|"G"G,4- G,2:|



X:1
T:Golem of the Ancient Ruins
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Em
% Intrigue and the mechanical are melded in the following voices
V:1 clef=treble name="Brass" subname="Majestic Melody"
%%MIDI program 61
|: "Em" E2G2 B2e2 | "D" d6 AF | "C" G4E4 | "B7" B,2D2 F4 |
"Em" E2G2 B2e2 | "D" d6 AF | "C" G4E4 | "B7" B,4-B,4 :|
V:2 clef=treble name="Strings" subname="Rhythmic Pattern"
%%MIDI program 49
|: "Em"e4 "D"f2d2 | "C"c2B2 "B7"B4 | "Em"e2e2 "D"f4 | "C"c2G2 "B7"B2B2 |
"Em"e4 "D"f2d2 | "C"c2A2 "B7"B4 | "Em"e2E2 "D"F4 | "C"C4 C4 :|
V:3 clef=treble name="Choir Aahs" subname="Mystical Harmonies"
%%MIDI program 52
|: "Em" z2G2 z2E2 | "D" A2A2 z2z2 | "C" z2E2 G2E2 | "B7" z2D2 F4 |
"Em" z2G2 z2E2 | "D" A2A2 z2F2 | "C" E4 G4 | "B7" B,4 z4 :|
V:4 clef=bass name="Synth Bass" subname="Mechanical Pulse"
%%MIDI program 38
|: "Em" E,2 G,2 E,2 G,2 | "D" D,2 A,2 D,2 A,2 | "C" C,4 E,4 | "B7" B,6 B,2 |
"Em" E,2 G,2 E,2 G,2 | "D" D,2 A,2 D,2 A,2 | "C" C,4 E,4 | "B7" B,4 B,4 :|
% A fifth voice with percussive elements for added tension is intentionally not included due to the user's request.



X:1
T:Auld Lang Syne - Funky Duet
C:Traditional (R. Burns) - Arr. OrchestrAI
M:4/4
L:1/8
Q:1/4=108
K:F
V:1 clef=treble
%%MIDI program 65
% Alto Saxophone plays the melody with a funky twist.
|: "F"c2 c2 A2 A2 | "Bb"G4 F4 | "F"A2 A2 c3 A | "C7"G6 z2 |
"F"A2 A2 f2 f2 | "Bb"F4 E4 | "F"c2 A2 "C7"B2 G2 | "F"A6 z2 :|
V:2 clef=bass
%%MIDI program 58
% Tuba plays a funky bass line.
|: "F"F,3 C F,3 C | "Bb"B,,3 F B,,3 F | "F"=A,3 E A,3 E | "C7"G,6 z2 |
"F"F,3 C F,3 C | "Bb"B,,3 F B,,3 F | "F"A,3 F, "C7"G,3 E, | "F"F,6 z2 :|
% Break - Alto Sax plays a funky riff while the tuba keeps the beat.
V:1
%%MIDI program 65
[C2e2][Fa][Gc][Af] z3
| z3 [G2B2][Bd][Ae][Gc]| A2 cc A2 cB | A4 G2 F2 |
[G2B2][Gd][Af][Gc] z3
| z3 [F2A2][Fa][Gc][Af]| A2 cc A2 B2 | c2 A2 G2 F2 |
V:2
%%MIDI program 58
F,4 F,A,B, z | B,,4 B,,D,F, z | =A,,4 A,,C,E, z | G,6 z2 |
F,4 F,A,B, z | B,,4 B,,D,F, z | A,,4 A,,F, B, z | F,4 z5  |
% Funky variation of the melody.
V:1
%%MIDI program 65
c>c B>A G>F E<E | F4 F2 z2 | A>c A>B c2 A2 | G4 z2 z2 |
f>f e>d c>B A<A | B4 B2 z2 | c>c B>A G2 F2 | A6 z2 |
V:2
%%MIDI program 58
F,>C D<C F,>C D<C | F,A,<B, F,A,<B, z2 | A,>E F<E A,>E C>E | G,2 z4 z2 |
F,>C D<C F,>C D<C | B,,>F B,>D B,,>F B,>D | A,>F, A,<F, G,>E G,<E, | F,6 z2 |
% Return to the familiar melody to close.
V:1
%%MIDI program 65
c2 c2 A2 A2 | G4 F4 | A2 A2 c3 A | G6 z2 |
A2 A2 f2 f2 | F4 E4 | c2 A2 B2 G2 | A6 z2 |
V:2
%%MIDI program 58
F,3 C F,3 C | B,,3 F B,,3 F | A,3 E A,3 E | G,6 z2 |
F,3 C F,3 C | B,,3 F B,,3 F | A,3 F, G,3 E, | F,6 z2 |



X:1
T:On Hold Harmony
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:G
V:1
%% MIDI program 1
|:"G"G2B2 d2B2|"C"e2c2 A2c2|"D"D3E F2A2|"G"B4 E4|
"C"E2G2 c2G2|"G"D2B,2 G,2B,2|"Am"A3B c2e2|"D7"d6 d2|
"G"B2D2 G2B2|"C"E2G2 c2E2|"D"A2F2 D2F2|"G"G4- G4|
V:2
%% MIDI program 49
|:"G"D4 G,4|"C"C4 E4|"D"A,4 A,2F2|"G"D4 D4|
"C"E4 G4|"G"D4 B,4|"Am"A,2E2 C4|"D7"F2A,2 D4|
"G"D4 G,4|"C"C4 E4|"D"F4 D4|"G"G,4- G,4|
V:3
%% MIDI program 33
|:"G"G,2B,2 D4|"C"C2E2 G4|"D"F2A2 A,2D2|"G"G,2 B,2 D4|
"C"E2G2 C2E2|"G"D4 G,4|"Am"A3 E2 C2E|"D7"F6 A,2|
"G"G,2D2 G,2B,2|"C"C4 E2G2|"D"F2D2 A,2F2|"G"G,6- G,2|
V:4 clef=bass
%% MIDI program 33
|:"G"G,4 G,4|"C"C4 C4|"D"D4 D4|"G"G,4 G,4|
"C"C4 C4|"G"G,4 G,4|"Am"A,4 A,4|"D7"D4 D4|
"G"G,4 G,4|"C"C4 C4|"D"D4 D4|"G"G,4 G,4|



X:1
T:Bandstand Frolic
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:G
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|:"G" d2B2 G2D2 |[M:2/4] "D" F4 |[M:4/4]"Em" E4 "A7" c3B | "D" A3F "G" G4 |
"Em" B3B "A7" A3A | "D" F3A "G" G2F2 | "Em" E3^G "A7" A2B2 |[1 "D" d3e "G" d3c :|[2 "D" d2d2 "G" G4 |]
V:2 clef=treble name="Alto Sax"
%%MIDI program 65
|:"G" g4 d2B2 |[M:2/4] "D" A4 |[M:4/4]"Em" e2e2 "A7" a2g2 | "D" f4 "G" d2B2 |
"Em" e4 "A7" a4 | "D" f3e "G" d2B2 | "Em" e2^g2 "A7" a2b2 |[1 "D" d^cde "G" d2^c2 :|[2 "D" d2^cd "G" g4 |]
V:3 clef=treble name="Trombone"
%%MIDI program 57
|"D" A,2F,2 [A,2D2] [A,2F,2] |[M:2/4] "Bm" B,4 |[M:4/4]"G" G,4 "A" c3B, | "D" [A,F]4- [A,F]2 z2 |
"A" c2 c2 "D" F2F2 | G4 "C" E4 | "G" D2 G2 "A7" F3E |[1 "D" D4 "G" G4 :|[2 "D" D2D2 "G" G,4 |]
V:4 clef=bass name="Bass Guitar"
%%MIDI program 33
|"G" G,2, B,,2, D,2, |[M:2/4] "D" A,,4 |[M:4/4] "Em" E,4 "A7" A,3B,, | "D" F,4 "G" G,2, |
"G" D,4 "D" A,,4 | "G" B,,4 "C" C,4 | "G" D,3 F, "A7" G,2 A,2 |[1 "D" A,,4 "G" G,4 :|[2 "D" D,4 "G" G,4 |]



X:1
T:Pirate's Polka Jamboree
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=120
K:Dm
V:1 clef=treble name="Fiddle"
%%MIDI program 110
|: "Dm" A2A2 A2fd | "C" c2G2 E4 | "Dm" A2A2 A2fd | "A" c2e2 d4 |
"Dm" f2d2 "C"e2c2 | "Dm" d4 d2fd | "A" c2A2 "Dm" F2D2 |1 "A7" E4 E2C2 :|2 "A7" E4 e2dc |:
|: "Dm" f2a2 a2gf | "Gm" g2b2 b2ag | "Am" a2e2 "A7" c2A2 | "Dm" F4 f2ef |
"Dm" g2f2 e2d2 | "Gm" B4 B2AG | "A" A2c2 "A7" e2dc |1 "Dm" d4 d2ef :|2 "Dm" d4 d2z2 ||
V:2 clef=treble middle=C name="Accordion"
%%MIDI program 21
|: "Dm" d3 A F2A2 | "C" c2G2 E2G2 | "Dm" d2f2 A2d2 | "A" c2e2 d3 e |
"Dm" f2a2 "C"e2g2 | "Dm" d4 A2B2 | "A" c3 e "Dm" A2c2 |1 "A7" E4 E2G2 :|2 "A7" E4 E3 F |
|: "Dm" f2d2 a2f2 | "Gm" g2d2 b2g2 | "Am" e2A2 "A7" c2e2 | "Dm" F4 F3 G |
"Dm" A2G2 F2E2 | "Gm" B4 B2c2 | "A" A4 "A7" E4 |1 "Dm" d4 D2F2 :|2 "Dm" d4 D2z2 ||
V:3 clef=bass middle=C name="Tuba"
%%MIDI program 58
|: "Dm" D,4 A,2F,2 | "C" C,2G,2 E,2G,2 | "Dm" D,4 A,2D,2 | "A" [CE]4 [CA,2E,2] |
"Dm" [F,A,]4 "C" E,2C,2 z3 | "Dm" D,2D,2 A,2F,2 | "A" [CE]4 "Dm" [AD]4 |1 "A7" [EG]4 E,2C,2 z3 :|2 "A7" [EG]4 E,2z2 z3 |
|: "Dm" D,4 A,2D,2 | "Gm" G,2B,,2 D,2G,2 | "Am" A,2E,2 "A7" C,4 | "Dm" [FD]4 F,4 z3 |
"Dm" D,4 A,2D,2 | "Gm" G,4 G,2B,,2 | "A" A,4 "A7" E,4 |1 "Dm" D,4 D,2F,2 :|2 "Dm" D,4 D,2z2 ||



X:1
T:This Week in Google - Podcast Intro
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:Cmaj
V:1 clef=treble
%%MIDI program 81 % Lead 1 (square)
|: "C"c4 e2g2 | "G/B"b3a g2e2 | "Am7"a4- afga | "F"fed2 c4 |
"C"e2c2 "G/B"d2B2 | "Am7"c2e2 "F"d2c2 | "C"G4- G2g2 | "F"f6 z2 :|
V:2 clef=treble
%%MIDI program 73 % Flute
|: "C"e4 g2c'2 | "G/B"d'3c' b2g2 | "Am7"c'4- c'bag | "F"a2f2 e4 |
"C"g2e2 "G/B"c'2d'2 | "Am7"e2g2 "F"f2e2 | "C"B4- B2c'2 | "F"a6 z2 :|
V:3 clef=bass
%%MIDI program 33 % Fingered Bass
|: "C"C,4 E,2G,2 | "G/B"B,,3A,, G,,2E,,2 | "Am7"A,,4- A,,B,,C,2 | "F"F,,D,2 C,4 z |
"C"E,2C,2 "G/B"D,2B,,2 | "Am7"C,2E,2 "F"D,2C,2 | "C"G,,4- G,,2G,2 | "F"F,,6 z2 :|



X:1
T:High Octane Finale
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Cmin
V:1 clef=treble name="Lead Synthesizer" subname="Lead"
%%MIDI program 81
|: "Cm"G8 z GABc z3 | "Bb"d8 z defe z3 | "Ab"G8 z GABc z3 | "G"d4c4B8 |
"Cm"e2f2g2a2 b4 a4 | "Bdimg7"b2a2b2c2 "Fm"d8 | "Cm"e2c2G2G2 "G7"A8 | "Cm"c8 z4 c4 :|
V:2 clef=treble name="Brass Section" subname="Brass"
%%MIDI program 61
|: "Cm"g4c4e4g4 | "Bb"f4Bb4d4f3 | "Ab"e4Ab4c4e3 | "G"d4g4b4d'4 |
"Cm"g2a2b2c'2 d'9  | "Bdimg7"b4a4 "Fm"f8 | "Cm"e4c4 "G7"A4G4 | "Cm"c8 z8 :|
V:3 clef=bass name="Electric Bass" subname="Bass"
%%MIDI program 33
|: "Cm"C,16 | "Bb"Bb,16 | "Ab"Ab,16 | "G"G,16 |
"Cm"C,4G,4C4 z4 | "Bdimg7" B,4A,4 "Fm"D4 z4 | "Cm"C,4G,4 "G7"A,4G,4 | "Cm"C,8 z8 :|
V:4 clef=treble name="Strings" subname="Strings"
%%MIDI program 49
|: "Cm"c4 e4 g4 c'4 | "Bb"d4 f4 Bb4 d'3 | "Ab"c4 e4 Ab4 c'3 | "G"d8 G4 G4 |
"Cm"g,4 c4 e4 c'4 | "Bdimg7"f4 a4 "Fm"c4 d4 | "Cm"g,4 c4 e4 c'4 | "G7"d8 G4 G4 :| "Cm"c8 z8 :|



X:1
T:Stealth and Shadows
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=110
K:Cmin
V:1 clef=treble name="Violin" subname="Pizz"
%%MIDI program 40
|: "Cm"G,12 B,4 | c4 z8 ^F8 | G,6 _B,6 C8 | "G7"D4 z8 ^F4 D4 |
"Cm"C4 z4 C4 G,4 z4 | E8 z8 z4 | G,12 B,4 | c4 z8 ^F8 | G,6 _B,6 C8 | "G7"D4 z8 ^F4 D4 |
"Cm"C8- C8 z4 | z4 e8 d8 | "G7"c8 B,8 z4 | ^F8 G,12 z10 :|
V:2 clef=treble name="Viola" subname="Pizz."
%%MIDI program 41
|: "Cm"C,4 C,8 G,8 | C,2 C,6 B,8- B,4 | ^F,4 B,8 G,12 z6 | "G7"D4 F,8 A,9  |
"Cm"G,2 C,2 G,8 z4 z4 | C4 E4 C8 z4 | C,4 C,8 G,8 | C,2 C,6 B,8- B,4 | ^F,4 B,8 G,12 z6 |
"G7"D4 F,8 A,9  | "Cm"G,8 G,4 C4 C,4 | E8 z4 C4 E4 | "G7"D4 F,8 G,9-  | ^F8- ^F8 B,4 :|
V:3 clef=bass name="Cello" subname="Pizz."
%%MIDI program 42
|: "Cm"C,12 E,4 | G,4 z8 C8 | E,6 G,6 G,8 | "G7"D12 B,4 |
"Cm"C,2 G,2 C,8 z8 | z12 | C,12 E,4 | G,4 z8 C8 | E,6 G,6 G,8 | "G7"D12 B,4 |
"Cm"C,8- C,8 z4 | z4 G,8 F,8 | "G7"E,8 D,8 z4 | C,8 C,12 z10 :|
V:4 clef=bass name="Double Bass" subname="Pizz."
%%MIDI program 43
|: "Cm"C,6 C,4 C,8 z2 | z12 C,4 | G,,6 E,4 G,,8 z2 | "G7"z12 B,,4 |
"Cm"C,6 C,4 C,8 z2 | z12 | C,6 C,4 C,8 z2 | z12 C,4 | G,,6 E,4 G,,8 z2 | "G7"z12 B,,4 |
"Cm"G,,6 G,4 G,,8 z2 | z4 G,,8 F,4 z4 | "G7"E,12 | C,12 C,12 :|



X:1
T:Disco Victory
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Am
% Upbeat disco groove to capture the vibrant party vibe
V:1 clef=treble
%%MIDI program 81
|:"Am" e2e2 e2dc| "Dm" d2d2 d2cB| "G" B2cB A2GF| "C" E2FG A2GE|
"Am" c2Bc d2e2| "F" f4- f2ed| "E7" e2^G2 A2c2| "Am" a2g2- g4:|
V:2 clef=treble
%%MIDI program 73
|:"Am" a4- a2fe| "Dm" f4- f2ed| "G" g4- g2fe| "C" e3f- f2e2|
"Am" c2Bc d2e2| "F" f2e2 f2ga| "E7" g2e2 f2ed| "Am" a4- a2z2:|
V:3 clef=bass
%%MIDI program 33
|:"Am" A,4 C2E2| "Dm" D,4 F2A2| "G" G,4 B,2D2| "C" C4- C2E2|
"Am" A,4 C2E2| "F" F,4 A,2c2| "E7" E,4 ^G,2B,2| "Am" A,4- A,2z2:|



X:1
T:Pop Anthem
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=120
K:C
%%Composer: OrchestrAI
% Intro
V:1 clef=treble name="Trumpet"
%%MIDI program 56
| C G/B Am Em | F C/E Dm G |
V:2 clef=treble name="Trombone"
%%MIDI program 57
| G2 G2 E2 E2 | C2 C2 A,2 A,2 |
V:3 clef=bass name="Tuba"
%%MIDI program 58
| C,2 C,2  A,,2 A,,2 | F,2 F,2 G,,2 G,,2 |
% Verse 1
V:1
| "C" C2 E G2 E | "G" D2 B G2 B | "Am" A2 C E2 C | "Em" B,2 G E2 D |
V:2
| E2 G C2 E | D2 G B2 D | C2 E A2 C | B,2 D G2 B, |
V:3
| C,2 G,, G,,2 C, | D,2 G,, G,,2 D, | A,,2 E,, E,,2 A,, | B,,2 E,, E,,2 B,, |
% Chorus
V:1
| "C" G2 G E2 E | "F" F2 F C2 C | "G" G2 G D2 D | "C" C2 C G,2 G, |
V:2
| E2 E C2 C | C2 C A,2 A, | D2 D B,2 B, | G2 G C2 C |
V:3
| C,2 C, F,2 F, | F,2 F, G,,2 G,, | G,2 G, C,2 C, | C,2 C, G,,2 G,, |
% Verse 2
V:1
| "C" C2 E G2 E | "G" D2 B G2 B | "Am" A2 C E2 C | "F" C2 A F2 E |
V:2
| E2 G C2 E | D2 G B2 D | C2 E A2 C | A,2 C F2 A, |
V:3
| C,2 G,, G,,2 C, | D,2 G,, G,,2 D, | A,,2 E,, E,,2 A,, | F,2 C, C,2 F, |
% Chorus
V:1
| "C" G2 G E2 E | "F" F2 F C2 C | "G" G2 G D2 D | "C" C2 C G,2 G, |
V:2
| E2 E C2 C | C2 C A,2 A, | D2 D B,2 B, | G2 G C2 C |
V:3
| C,2 C, F,2 F, | F,2 F, G,,2 G,, | G,2 G, C,2 C, | C,2 C, G,,2 G,, |
% Bridge
V:1
| "Am" A2 A C2 C | "Em" E2 E G2 G | "F" F2 F A2 A | "G" G2 G B2 B |
V:2
| C2 C E2 E | B,2 B, D2 D | A,2 A, C2 C | B,2 B, D2 D |
V:3
| A,,2 A,, E,,2 E,, | E,,2 E,, A,,2 A,, | F,2 F, A,,2 A,, | G,2 G, B,,2 B,, |
% Chorus
V:1
| "C" G2 G E2 E | "F" F2 F C2 C | "G" G2 G D2 D | "C" C4 |
V:2
| E2 E C2 C | C2 C A,2 A, | D2 D B,2 B, | G4 |
V:3
| C,2 C, F,2 F, | F,2 F, G,,2 G,, | G,2 G, C,2 C, | C,4 |
% Outro
V:1
| "C" C2 G2 C2 |
V:2
| G2 E2 G2 |
V:3
| C,2 G,,2 C,2 |



X:1
T:Dance of the Fireflies
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=88
K:Dmaj
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "D"FA d2 A2 | "A7"e4 fe | "G"d2 B2 A2 | "D/F#"F2 A2 d2 |
"D"A2 d2 c2 | "Bm"B3 A G2 | "G"G2 E2 D2 |1 "A7"E4 z2 :|2 "A7"E4 dc |:
"G"BA G2 D2 | "D/F#"F2 A3 d | "Em"e3 f g2 | "A7"a4 ga |
"G"b2 g2 e2 | "D/A"a3 f d2 | "Bm"f2 d2 F2 |1 "A7"A4 dc :|2 "A7"A4 z2 |
V:2 clef=treble name="Violin" subname="Pizzicato"
%%MIDI program 45
|: "D" d4 f2 | "A7" c4 e2 | "G"B2 G2 F2 | "D/F#" A2 d2 f2 |
"D" A3 B A2 | "Bm"F4 E2 | "G"G2 B2 A2 |1 "A7"E4 z2 :|2 "A7"E4 de |:
"G"f2 d2 B2 | "D/F#"A2 d3 A | "Em"e2 g2 f2 | "A7"a2 e2 c2 |
"G"B2 d2 B2 | "D/A"A4 (3fed z2 | "Bm"A2 F2 (3ABA z2 |1 "A7"G4 de :|2 "A7"G4 z2 |
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "D"D2 F2 A,2 | "A7"E2 E,2 A,2 | "G"G,2 D2 B,2 | "D/F#"F,2 C2 D2 |
"D"D3 F A,2 | "Bm"B,2 B,,2 D2 | "G"G,2 B,2 A,2 |1 "A7"E2 z2 z2 :|2 "A7"E2 F2 G2 |:
"G"G,2 D2 D,2 | "D/F#"F,2 A,2 F,2 | "Em"E,2 G,2 B,2 | "A7"A,2 E2 A,2 |
"G"G,2 D2 B,2 | "D/A"A,2 d2 c2 | "Bm"B,2 F2 A,2 |1 "A7"A,2 F2 G2 :|2 "A7"A,2 z2 z2 |



X:1
T:Boss Battle Blitz
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Cmin
V:1 clef=treble name="Brass" subname="Brass"
%%MIDI program 61
|: "Cm"G7-1 | "Fm" F4e4 | "G7" B2c2d5 | "Cm"G7  |
"Cm"e4g4 | "Fm"F4A5 | "G7"G4B4D | "Cm"C7-1 |
"Cm"G4B3 | "Ab"G4E3 | "G7"B4G4 |1 "Cm"G7  :|2 "Cm"G171||
V:2 clef=treble name="Strings" subname="Str."
%%MIDI program 48
|: "Cm"G2B2c5 | "Fm" F2A2B5 | "G7" B,2D2E5 | "Cm"G7  |
"Cm"C4E4F | "Fm"A4c4d | "G7"G,4B,4D | "Cm"C7-1 |
"Cm"G4B4d | "Ab"E4G4F | "G7"G4F4E |1 "Cm"G7  :|2 "Cm"G171||
V:3 clef=bass name="Percussion" subname="Perc."
%%MIDI program 48
|: "Cm"C,16 | "Fm"F,16 | "G7"G,16 | "Cm"C,16 |
"Cm"G,16 | "Fm"F,16 | "G7"G,16 | "Cm"C,16 |
"Cm"G,16 | "Ab"G,16 | "G7"G,16 | "Cm"C,16 :|
V:4 clef=bass name="Bass" subname="Synth Bass"
%%MIDI program 39
|: "Cm"C,3E,G,C,2  | "Fm"F,3A,C,F,2  | "G7"B,,3D,G,B,2  | "Cm"C,7  |
"Cm"G,3C,G,c2  | "Fm"F,3A,C,F,2  | "G7"G,,3B,,D,G,2  | "Cm"C,7-1 |
"Cm"C,2G,C5  | "Ab"G,2E,G,5  | "G7"G,2D,G,5  |1 "Cm"C,7  :|2 "Cm"C,171||



X:1
T:Baroquely Romantic Tapestry
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=80
K:Gmaj
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G" D2 B2 G2 F2 | "Am" E4 A2 z2 | "Bm" F2 D2 B,2 A,2 | "D7" G,4 F2 z2 |
"Ebmaj7" B,2 G2 E2 B2 | "Am" A4 c2 z2 | "C" e2 c2 G2 E2 | "D7" D4 F2 z2 |
"Gmaj7" B2 d2 G2 B2 | "Cm" c4 c2 z2 | "Ebmaj7" B,2 F2 B,2 F2 | "D7" A4 A2 z2 |
"G" D2 B2 "C"G2 E2 | "Am"A4 "D7"f2 z2 | "G"G2 B2 "A"e2 d2 | "D7"c4 B2 z2 :|
V:2 clef=treble name="String Ensemble"
%%MIDI program 48
|:"G" G,2 B,2 d4 | "Am" e2 c2 A2 c2 | "Bm" f2 d2 b,2 d2 | "D7" a2 f2 d2 f2 |
"Ebmaj7" gb2 eb2 gb2 | "Am" c'2 a2 e2 c2 | "C" g2 e2 c2 e2 | "D7" a2 f2 d2 f2 |
"Gmaj7" g2 d'2 b2 g2 | "Cm" eb2 c2 g2 eb2 | "Ebmaj7" g2 eb2 c2 g2 | "D7" f2 d2 B2 f2 |
"G" d2 B2 G2 D2 | "C" e2 c2 A2 E2 | "Am" A2 c2 e2 a2 | "D7" f4 d2 f2 :|
V:3 clef=treble name="Harpsichord"
%%MIDI program 6
|:"G" D4 G,2 B,2 | "Am" c4 e2 a2 | "Bm" F4 D2 f2 | "D7" A,4 d2 f2 |
"Ebmaj7" B,2 ^F2 {/G=B,} G,2 B,2 | "Am" c4 e2 a2 | "C" G,4 c2 e2 | "D7" f4 a2 f2 |
"Gmaj7" D4 B,2 d2 | "Cm" c'4 eb2 g2 | "Ebmaj7" B,2 G2 B,2 e2 | "D7" a4 f2 a2 |
"G" D4 G,2 B,2 | "C" e4 c2 e2 | "Am" a4 A2 c2 | "D7" d4 f2 a2 :|
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"G" G,2 D,2 G,4 | "Am" A,2 E,2 A,4 | "Bm" B,2 F#2 B,4 | "D7" D,2 A,2 D4 |
"Ebmaj7" Eb,2 Bb,2 Eb,4 | "Am" A,2 E,2 A,4 | "C" C2 G,2 C4 | "D7" D,2 A,2 D4 |
"Gmaj7" G,2 D,2 B,2 G,4 | "Cm" C2 G,2 Eb4 | "Ebmaj7" Eb,2 Bb,2 G,4 | "D7" D,2 A,2 F#4 |
"G" G,2 D,2 G,4 | "C" C2 G,2 E,4 | "Am" A,2 E,2 A4 | "D7" D,2 f2 a4 :|



X:1
T:Band Extravaganza
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:C
% The introduction section with a lively melody and strong harmonic foundation
V:1 clef=treble name="Clarinet"
%%MIDI program 71
|:"C" e3c e2dc | "G" B3D G2G2 | "Am" A2c2 d2cB | "F" A3-G F2E2 |
|:"C" E3G A2c2 | "G" B3D G3F | "Am" E3G B2c2 | "F" d2B2 c3A ||
% Middle section with rich harmonies and counter melodies
V:2 clef=treble name="Bassoon"
%%MIDI program 70
|:"C" c2e2 g2c2 | "G" B2D2 G2d2 | "Am" c3B A2cB | "F" F2A2 B2c2 |
|:"C" e2g2 d2e2 | "G" B2A2 G2F2 | "Am" e2g2 d2f2 | "F" e3F G2A2 ||
% Bass section with a strong rhythmic foundation
V:3 clef=bass name="Acoustic Bass"
%%MIDI program 32
|:"C" C,3E, G,3C, | "G" G,3B, D,3G, | "Am" A,3E, A,3A, | "F" F,3A, C,3F, |
|:"C" C,3E, G,3C, | "G" G,3B, D,3G, | "Am" A,3E, A,3A, | "F" F,3A, C,3F, ||
% Adding a fourth voice to enrich the texture
V:4 clef=alto name="French Horn"
%%MIDI program 60
|:"C" e2d2 c-BcA | "G" B2A2 G2FG | "Am" A2c2 B2cB | "F" A3-G F2E2 |
|:"C" E2G2 A2Bc | "G" d2G2 F2E2 | "Am" E2G2 B2c2 | "F" d3e f2A2 ||



X:1
T:Sunlit Journey
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:G
% The opening section sets a bright and uplifting theme
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G" B2 d2 g2 B2 | "D" A2 F2 A2 F2 | "Em" G2 E2 G2 E2 | "C" E2 C2 E2 C2 |
|:"G" B2 d2 g2 a2 | "D/F#" f2 a2 f2 a2 | "Em" e2 g2 e2 g2 | "C" e2 c2 e2 c2 |
% Transition to the middle section with a contrasting theme
V:2 clef=treble name="Violin"
%%MIDI program 40
|:"G" G,2 G,2 D2 G2 | "D" F#2 F#2 A2 F#2 | "Em" E2 E2 G2 E2 | "C" C2 C2 E2 C2 |
|:"G" G,2 G,2 D2 A2 | "D/F#" F#2 F#2 A2 F#2 | "Em" E2 E2 G2 E2 | "C" C2 C2 E2 C2 |
% Counter melody with rhythmic variation
V:3 clef=treble name="Clarinet"
%%MIDI program 71
|:"G" D4 B4 | "D" A4 F#4 | "Em" G4 E4 | "C" E4 C4 |
|:"G" D4 A4 | "D/F#" F#4 A4 | "Em" E4 G4 | "C" E4 C4 |
% Steady harmonic foundation with engaging bass line
V:4 clef=bass name="Cello"
%%MIDI program 32
|:"G" G,4 D,4 | "D" A,4 F#4 | "Em" E,4 G,4 | "C" C,4 E,4 |
|:"G" G,4 A,4 | "D/F#" F#4 A,4 | "Em" E,4 G,4 | "C" C,4 E,4 |
% Development with increased harmonic complexity
V:1
|:"Am" A2 c2 e2 a2 | "D7" f2 d2 f2 d2 | "Bm" B2 d2 f#2 b2 | "E7" e2 g#2 b2 e2 |
|:"Am" a2 c2 e2 g2 | "D7" f2 d2 f2 d2 | "Bm" b2 d2 f#2 b2 | "E7" e2 g#2 b2 e2 |
% Return to the main theme
V:2
|:"G" B2 d2 g2 B2 | "D" A2 F#2 A2 F#2 | "Em" G2 E2 G2 E2 | "C" E2 C2 E2 C2 |
|:"G" B2 d2 g2 a2 | "D/F#" f2 a2 f2 a2 | "Em" e2 g2 e2 g2 | "C" e2 c2 e2 c2 |
% Coda with a resolute ending
V:3
|:"G" D4 B4 | "D" A4 F#4 | "Em" G4 E4 | "C" E4 C4 |
|:"G" D4 G4 | "D" A4 F#4 | "G" G6 z2 |
V:4
|:"G" G,4 D,4 | "D" A,4 F#4 | "G" G,6 z2 |



X:1
T:Cosmic Prelude
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Cmin
% Symphonic texture to capture the vastness of space
V:1 clef=treble name="Violin 1" subname="Vln.1"
%%MIDI program 40
|: "Cm"G4 z2 G2 | "Fm" c4 "Bb7"d4 | "Eb"e6 "Ab"g2 | "G7"g4 z4 |
"Cm"G4 (f4 g2) z4 | "Fm"A4 "Bb7"B4 | "Eb"G4 e2 "Cm"d2 | "G7"c4 "Cm"B4 :|
V:2 clef=treble name="Violin 2" subname="Vln.2"
%%MIDI program 40
|: "Cm"E4 z2 E2 | "Fm"A4 "Bb7"B4 | "Eb" G4 "Ab"f4 | "G7"d4 z4 |
"Cm"E4 (c4 d2) z4 | "Fm"F4 "Bb7"A4 | "Eb"C4 E2 "Cm"c2 | "G7"G4 "Cm"F4 :|
V:3 clef=alto name="Viola" subname="Vla."
%%MIDI program 41
|: "Cm"C4 z2 C2 | "Fm"F4 "Bb7"B4 | "Eb"e4 "Ab"c4 | "G7"B4 z4 |
"Cm"C4 (A4 B2) z4 | "Fm"F4 "Bb7"g4 | "Eb"E4 c2 "Cm"B2 | "G7"G4 "Cm"E4 :|
V:4 clef=bass name="Cello" subname="Cllo"
%%MIDI program 42
|: "Cm"C,4 z2 C,2 | "Fm"F,4 "Bb7"D4 | "Eb"E,4 "Ab"A,4 | "G7"B,,4 z4 |
"Cm"C,4 (A,4 B,2) z4 | "Fm"F,4 "Bb7"D4 | "Eb"E,4 C,2 "Cm"E,2 | "G7"G,4 "Cm"C,4 :|
V:5 clef=bass name="Contrabass" subname="C.B."
%%MIDI program 43
|: "Cm"G,,4 z2 G,,2 | "Fm"C,4 "Bb7"D,4 | "Eb"E,4 "Ab"A,4 | "G7"B,,,4 z4 |
"Cm"G,,4 (E,4 F,2) z4 | "Fm"F,4 "Bb7"B,,4 | "Eb"E,4 C,2 "Cm"D,2 | "G7"C,4 "Cm"B,,4 :|
V:6 clef=treble name="Trumpet" subname="Trpt."
%%MIDI program 56
|: "Cm"c4 z2 c2 | "Fm"f4 "Bb7"d4 | "Eb"e6 "Ab"e2 | "G7"B4 z4 |
"Cm"c4 (g4 a2) z4 | "Fm"a4 "Bb7"B4 | "Eb"c4 g2 "Cm"f2 | "G7"C4 "Cm"c4 :|
V:7 clef=treble name="Horn" subname="Hrn."
%%MIDI program 61
|: "Cm"C4 G,2 C2 | "Fm"F3 A "Bb7"D3 F | "Eb"E4 C G,2 z | "G7"B,4 z4 |
"Cm"C4 E,5  | "Fm"F4 F2 "Bb7"A2 | "Eb"E4 C2 "Cm"G,2 | "G7"C4 "Cm"G,4 :|
V:8 clef=treble name="Flute" subname="Fl."
%%MIDI program 73
|: "Cm"G6 c2 | "Fm"f4 "Bb7"d4 | "Eb"e6 c2 | "G7"d4 z4 |
"Cm"G4 c5  | "Fm"a4 "Bb7"g4 | "Eb"e4 c2 "Cm"g2 | "G7"G4 "Cm"c4 :|
V:9 clef=treble name="Oboe" subname="Ob."
%%MIDI program 68
|: "Cm"E6 G2 | "Fm"c4 "Bb7"d4 | "Eb"e6 "Ab"g2 | "G7"B4 z4 |
"Cm"G4 (e4 f2) z4 | "Fm"A4 "Bb7"A4 | "Eb"G4 "Cm"F2 z2 | "G7"B4 "Cm"d4 :|
V:10 clef=bass name="Timpani" subname="Tmp."
%%MIDI program 47
|: "Cm"G,2 z2 C,2 z2 | "Fm"F,2 z2 "Bb7"B,,2 z2 | "Eb"E,2 z2 "Ab"A,,2 z2 | "G7"B,,,2 z2 z4 |
"Cm"G,2 z2 "Cm"E2 z2 | "Fm"F,2 z2 "Bb7"D,2 z2 | "Eb"E,2 z2 "Cm"C2 z2 | "G7"B,,,4 "Cm"C,4 :|



X:1
T:Test Expansion
C:OrchestrAI
Z:Arr.  Joel Breit
Z:Polk the Bear, Extended
%%score (1 3) 2
L:1/8
Q:1/2=80
M:4/4
K:Gm
V:1 treble nm="Harmonica" snm="Harm."
V:2 bass nm="Tuba" snm="Tba."
V:3 treble nm="Accordion" snm="Acc."
% Original melody by Joel Breit
V:1
"D7" d2 AB cd/c/ BA |"Gm" G2 GB d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
"Cm" z c2 e g2 fe | "Bb" z d2 B d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
"Gm" d4 B4 |"D7" c4 A4 |"Gm" B4 G4 |"D7" ^F4 A4 |
"Gm" d4 B4 |"D7" c4 A4 |"Bb" B2 d2 g4 |"D7" ^f8 :|
% Additional composition by OrchestrAI
"D7" a2 gf ed/c/ BA |"Gm" B2 AG F2 GA |"D7" B3 A c2 B2 |"Gm" cB AB AG FE |
"Cm" D2 F2 G2 A2 | "Bb" d2 D2 cB AB |"D7" G3 A ^F3 G |"Gm" A6 z2 |
"Gm" B2 d2 d2 Bd |"D7" c3 B A2 F2 |"Gm" G2 B2 A2 G2 |"D7" F4 D4 |
"Gm" G4 D4 |"D7" C4 F4 |"Bb" d4 B4 |"D7" G6 z2 :|
V:2
"D7" f4 a4 | "Gm" B,4 D4 | "D7" A,4 C4 | "Gm" G,4 B,4 |
"Cm" G,4 C4 | "Bb" F,4 A,4 | "D7" A,4 C4 | "Gm" G,4 B,4 |
"Gm" d4 d4 | "D7" c4 c4 | "Gm" B,4 B,4 | "D7" A,4 A,4 |
"Gm" d4 d4 | "D7" c4 c4 | "Bb" B,4 B,4 | "D7" ^F4 ^F4 :|
% Additional composition by OrchestrAI
"D7" A4 D4 | "Gm" G,4 B,4 | "D7" A,4 D4 | "Gm" B,4 D4 |
"Cm" E2 G2 A2 C2 | "Bb" D2 D2 C2 A,2 | "D7" D4 ^F4 | "Gm" G,6 B,2 |
"Gm" D4 B,4 | "D7" A,4 F,4 | "Gm" G,4 E,4 | "D7" A,4 F,4 |
"Gm" D4 B,4 | "D7" C4 A,4 | "Bb" B,4 G,4 | "D7" C6 z2 :|
V:3
% Accordion harmony to support the harmonica – New addition
"D7" [df][af][df] [df][af][df] z2 | "Gm"[d2^f2][^fg] z[cB] z2 | "D7"[ec'A'][dA][fA] [f2a2]  z3 | "Gm" [d2^f2] [g2b2] z2[G2B2] |
"Cm" G,6 [EGc]2 | "Bb" [D2F2][DF][AF][D2F2] z2 | "D7"[CA,][EA,][GA,] ^F,5 | "Gm" [G,B,D][G2B2] z2[G2B2] z |
"Gm" d6 d2 | "D7" cB AG F2^F2 | "Gm" G2 B2 A2 G2 | "D7" [F2A2] z2 FEDC |
"Gm" [D2D2][G2G2] z4 | "D7" [C2E2][F2A2][G2B2] z2  | "Bb" [d2B2][g2d2] z4 | "D7" [^f2a2] z5  :|
% Additional accordion part by OrchestrAI
"D7" [ad][df][ad] [ad][df][ad] z2 |  "Gm"[bg] z[cB] z[AG] z3 |  "D7"[a=f][df][a=f] [^g=f][df][^g=f] |  "Gm"[gB][d^f] z[G=B] z[FE] z2 |
"Cm" G,2 [E,2G,2] z4 | "Bb" F,2 z2 A,2[G,2B,2] | "D7"[DA=F][^F2A2] z3 | "Gm" [G,2D2][^F2D2] z2 |
"Gm" [B,2D2][d2f2] z2[B,2D2] | "D7" [c2a2] [e2c2] [f2a2] z2 | "Gm" [g2e2] [B,2G,2] z4 | "D7" [f2a2] z4 z2 |
"Gm" D,6 z2 | "D7" C6 z2 | "Bb" B,6 z2 | "D7" G,6 z2 :|



X:1
T:Band's Delight
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=144
K:Gmaj
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|:"G"B2B2 d2B2|"D"A2A2 F2A2|"Em"B2A2 G4|"C"E2E2 C2E2|
"G"D2D2 G2B2|"D"F2D2 A2F2|"C"G4 B2AG|"D"A6z2:|
|:"G"GABc d2B2|"Am"c4 A2c2|"Bm"Bcde f2A2|"D7"d4 F2d2|
"G"G2D2 G2B2|"C"E2G2 C2E2|"Am"A2c2 e2c2|"D7"D6z2:|
|:"G"B3B d3B|"D"A3A F3A|"G"GABc "C"E2D2|"G"D6B2|
"G"B3B d3B|"D"A3A F3A|"C"EFGA B2G2|"D"A6z2:|
V:2 clef=treble name="Alto Sax"
%%MIDI program 65
|:"G"D2G2 B4|"D"A,2D2 F4|"Em"G2F2 E4|"C"C2G,2 E4|
"G"D2G2 B4|"D"A,2D2 F4|"C"E2C2 D2E2|"D"A,4 A,2z2:|
|:"G"G2G2 D2D2|"Am"A2c2 A4|"Bm"B2B2 F2F2|"D7"d2d2 F4|
"G"G2G2 D2D2|"C"C2E2 C4|"Am"A2A2 c2c2|"D7"D4 D2z2:|
|:"G"D3D G3G|"D"A3A D3D|"G"G2G2 "C"E2E2|"G"D4 D2z2|
"G"D3D G3G|"D"A3A D3D|"C"C3C E3E|"D"A,6z2:|
V:3 clef=treble name="Tenor Sax"
%%MIDI program 66
|:"G"B,2D2 G4|"D"F2A2 D4|"Em"E2G2 B,4|"C"E2G2 C4|
"G"B,2D2 G4|"D"F2A2 D4|"C"G,2E2 C4|"D"F2A2 D4:|
|:"G"B,2G,2 D2B,2|"Am"C2E2 A2C2|"Bm"B,2F2 F4|"D7"d2f2 d4|
"G"B,2G,2 D2B,2|"C"C2E2 G2C2|"Am"A2E2 C4|"D7"D2A,2 D4:|
|:"G"D2B,2 G4|"D"F2A2 D4|"G"G,2B,2 "C"E2C2|"G"D4 D2z2|
"G"D2B,2 G4|"D"F2A2 D4|"C"C2G,2 E4|"D"F2A,2 D4:|
V:4 clef=bass name="Bass Trombone"
%%MIDI program 58
|:"G"G,2G,2 G,4|"D"D,2D,2 D,4|"Em"E,2E,2 E,4|"C"C,2C,2 C,4|
"G"G,2G,2 G,4|"D"D,2D,2 D,4|"C"C,2C,2 C,4|"D"D,2F,2 D,4:|
|:"G"G,2G,2 G,4|"Am"A,2A,2 A,4|"Bm"B,2B,2 B,4|"D7"D,2D,2 D,4|
"G"G,2G,2 G,4|"C"C,2C,2 C,4|"Am"A,2A,2 A,4|"D7"D,2D,2 D,4:|
|:"G"G,2G,2 G,4|"D"D,2D,2 D,4|"G"G,2G,2 "C"C,2C,2|"G"G,4 G,2z2|
"G"G,2G,2 G,4|"D"D,2D,2 D,4|"C"C,2C,2 C,4|"D"D,6z2:|



X:1
T:Pirate Polka Plunder
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=200
K:Dmin
V:1 clef=treble name="Accordion"
%%MIDI program 21
|:"Dm"D2F2 A2d2|"A7"c2e2 e4|"Dm"d2f2 a2fd|"C"E2G2 c2e2|
"Dm"D2F2 A2d2|"A7"c2e2 e4|"Bb"d2B2 B2d2|"A7"c2A2 A4:|
|:"F"c2A2 A2c2|"C7"E2G2 G4|"F"c2A2 A2c2|"A7"A2c2 e4|
"Dm"d2f2 a2fd|"Gm"g2bg d2g2|"A7"c2e2 e2c2|"Dm"d4 D4:|
V:2 clef=treble name="Flute"
%%MIDI program 73
|:"Dm"F4 A4|"A7"e4 e2A2|"Dm"A2A2 F2D2|"C"G4 E4|
"Dm"F4 A4|"A7"e4 e2A2|"Bb"B4 d4|"A7"c2E2 E4:|
|:"F"A2A2 c2A2|"C7"G4 G2E2|"F"A4 A2A2|"A7"A2F2 A4|
"Dm"f2A2 d4|"Gm"G2B2 d4|"A7"E2c2 e4|"Dm"D4 D2z2:|
V:3 clef=treble name="Brass"
%%MIDI program 61
|:"Dm"d8|"A7"c8|"Dm"d2f2 a2f2|"C"c2e2 c4|
"Dm"d2D2 F2A2|"A7"c4 e4|"Bb"d2B2 B2d2|"A7"c4 A4:|
|:"F"c2A2 A2c2|"C7"E2G2 G4|"F"A2c2 c4|"A7"A4 e4|
"Dm"A2d2 f4|"Gm"B2d2 g4|"A7"c2A2 A4|"Dm"d4 D4:|
V:4 clef=bass name="Tuba"
%%MIDI program 58
|:"Dm"D8|"A7"C8|"Dm"D,2D2 A,2D2|"C"C8|
"Dm"D,2D2 F,2D2|"A7"C8|"Bb"B,2B2 B,2D2|"A7"C2E2 C4:|
|:"F"c4 F4|"C7"c2C2 C4|"F"F4 F2C2|"A7"c4 e4|
"Dm"d8|"Gm"d8|"A7"c4 e2c2|"Dm"D4 D4:|



X:1
T:Clash of Titans
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Cm
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"Cm"G8- G4 F2E2|G16|F8- F4 G2A2|B,16|
E2G2G2E2 D2G2G2D2|E16|D2F2F2D2 E2F2F2D2|C16|
G,4-C4-E4-G4 C4-E4-G4|G,16|F2-F4 E4-D4 C4-B,4 E4-G4|C16|
"Cm"G16-|G8 z8|"Ab"Ab16-|Ab8 z8|
"G"C4-C8 G4-G4|C16|"Bb"G,4-G4 B,4-B4 F4-F4|F16|
"Cm"G16-|"G7"G16|"Cm"G8- G4 F2E2|"G"G16:|
V:2 clef=treble name="Strings" subname="Strings"
%%MIDI program 48
|:"Cm"c4 d4 e4 f4|e8 d8|d4 e4 f4 g4|a8 g8|
e4 g4 e4 c4|e8- e4 d4|d4 f4 d4 B,4|d16|
c8- c4 B,4 c4|d16|e8- e4 c4 B,4|c16|
"Cm"c16-|c8 z8|"Ab"a8 b8 g8 a8|"Eb"b8 g8|
"G"c4-c8 e4-g4|c16|"Bb"F4-F8 d4-f4|F16|
"Cm"c16-|"G7"b16|"Cm"e8- e4 f8|"G"e16:|
V:3 clef=bass name="Brass" subname="Brass"
%%MIDI program 61
|:"Cm"C4 C,4 G,4 C4|G,4 C4 G,4 C4|B,4 B4 F4 B,4|F4 B4 F4 B,4|
"G"E4 E,4 C4 E4|C4 E4 C4 E4|"Ab"D4 D,4 B,4 D4|F4 C4 F4 C4|
"Cm"E4 G,4 C4 E4|C4 E4 C4 E4|"G7"F4 B,4 F4 F4|B,4 F4 B,4 F4|
"Cm"C16-|C8 z8|"G7"G,4 B,4 D4 G4|G,4 D4 G4 D4|
"Ab"B,4 B4 D4 B,4|B,8 E8|"Eb"G,4 G4 C4 G,4|C8 G,8|
"Cm"C16-|"Ab"E4 E4 D4 D4|C16|"G"G,16:|



X:1
T:Reflection and Bloom
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=88
K:Gmaj
V:1 clef=treble name="Piano"
%%MIDI program 0
|:"G"[GB]4 zD | "Em"[EG]4 zB, | "C"[CE]3A "D"[DF]2D | "G"[GB]4 zG |
"D/F#"[FD]4 zA | "Em"[EG] (3BAG E2 | "Am"[Ac]3e "D"[df]2d | "G"[GB]4 z2 :|
w: Reflective motif, gentle and pondering
|:"C"e3e c4 | "G/B"d3d "Am"cBAG | "D"a2gf "G/B"g2fe | "C"d6 c2 |
"Am"B3A c4 |"D"B3A G2FG |"Em"A3G F2E2 | "G" G6 :|
w: Expanding with a touch of hope and direction
V:2 clef=treble name="Violin I"
%%MIDI program 40
|:"G" d4 zB | "Em" g4 zE | "C" e3f "D" a2 | "G" d4 z2 |
"D/F#" a4 zc | "Em" g4 z2 | "Am" a3b "D" f2 | "G" d4 z2 :|
w: Complementing the piano with rising melodic lines
|:"C" g3g e2 | "G/B" f3f "Am" ed | "D" c2Bc "G/B" d2 | "C" e7  |
"Am" e3d e2 |"D" d3c A3 |"Em" B3A G3 | "G" G6 :|
w: Emphasizing the feeling of blossoming ideas
V:3 clef=treble name="Violin II"
%%MIDI program 40
|:"G" B,4 zG | "Em" E4 zC | "C" G3A "D" F2 | "G" B,4 z2 |
"D/F#" F4 zA | "Em" E4 z2 | "Am" c3d "D" B2 | "G" B,4 z2 :|
w: Supportive harmonic texture, reflective and rich
|:"C" c3c G2 | "G/B" B3B "Am" GF | "G" A2GA "G/B" B2 | "C" G7  |
"Am" G3F G2 |"D" F3E C3 |"Em" E3D C3 | "G" G6 :|
w: Enriching the soundscape, increasingly dynamic
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"G" G,4 zD, | "Em" E,4 zG, | "C" C3E "D" F2 | "G" G,4 z2 |
"D/F#" D,4 zA, | "Em" E,4 z2 | "Am" A,3C "D" F2 | "G" G,4 z2 :|
w: Deep, resonant foundation that echoes the theme
|:"C" C3C E,2 | "G/B" D3D "Am" C3 | "D" F2ED "G/B" G2 | "C" C7  |
"Am" B,3A, "D" D2 |"D" A,3G, "G" G3 |"Em" E2E2 "G" D2 | "G" G,6 :|
w: Enhancing the musical texture, providing depth and support



X:1
T:Candlelit Whisper
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=70
K:G
V:1 clef=treble name="Piano"
%%MIDI program 0
|: "G"B4 G2 | "C"E4 D2 | "D7"F3 E D2 | "G"G4 z2 |
"C"E4 G2 | "Am"A3 G F2 | "Bm"B4 A2 | "D7"A6 |
"G"B4 d2 | "D7"c3 B A2 | "Em"G4 E2 | "C"A4 z2 |
"Am"A6- | A4 B2 | "D7"F3 E D2 | "G"G4 z2 :|
V:2 clef=treble name="Violin"
%%MIDI program 40
|: "G"D3 D D2 | "C"C2 E3 G | "D7"A2 F3 E | "G"G2 D3 B |
"C"C2 E3 G | "Am"A2 c3 A | "Bm"B2 A3 G | "D7"F2 D3 F |
"G"G2 D3 d | "D7"F3 E D2 | "Em"G3 G B2 | "C"E3 E G2 |
"Am"A4 c2 | "Bm"B3 G A2 | "D7"A2 F3 E | "G"G2 D3 B :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "G"G,2 B,2 D2 | "C"E,4 G,2 | "D7"F,2 A,2 C2 | "G"G,2 B,2 D2 |
"C"E,2 C2 E2 | "Am"A,2 E2 C2 | "Bm"B,2 D2 F2 | "D7"F,2 A,2 C2 |
"G"G,4 B,2 | "D7"D4 F,2 | "Em"E,2 G,2 B,2 | "C"C3 E G,2 |
"Am"A,2 E2 C2 | "Bm"B,2 A,2 G,2 | "D7"F,2 D2 F,2 | "G"G,2 B,2 D2 :|



X:1
T:Festival Frolic
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:C
V:1 clef=treble
%%MIDI program 71
|:"C" G4- G2 E2 | "Am" A4- A2 c2 | "F" F4- F2 A2 | "G" G4- G2 B2 |
"C" e2 d2 c2 B2 | "Am" A2 ^G2 A2 G2 | "F" F6 E2 | "G" D4 D4 |
"C" c2 E2 G2 c2 | "Am" A2 c2 e2 c2 | "F" d2 F2 A2 d2 | "G" B2 G2 B2 d2 |
"C" e2^g2 a2g2 | "Am" f2e2 d2c2 | "F" F2 A2 c2A2 | "G" G6 z2 :|
V:2 clef=treble
%%MIDI program 56
|:"C" C4- C2 z2 | "Am" A3 B c2 A2 | "F" F2 E2 F4 | "G" B,4 D4 |
"C" E4- E2 E2 | "Am" A2 ^G2 A4 | "F" F3 E F2 A2 | "G" B,4 G4 |
"C" c2 e2 g2 e2 | "Am" A2 c2 e2 A2 | "F" A2 d2 f2 d2 | "G" d2 G2 B2 G2 |
"C" c3 e ^g2 e2 | "Am" A2 e2 d3 c | "F" F2 E2 A2 F2 | "G" D6 z2 :|
V:3 clef=bass
%%MIDI program 33
|:"C" C,4 E,4 | "Am" A,4 C4 | "F" F,4 A,4 | "G" G,4 B,4 |
"C" c4 e4 | "Am" a,4 A2 c2 | "F" f6 e2 | "G" d4 d4 |
"C" c2 E2 G2 E2 | "Am" A,2 C2 E2 A,2 | "F" F2 A2 d2 F2 | "G" D2 G2 B2 G2 |
"C" C4 C4 | "Am" A,6 A2 | "F" F3 E F2 C2 | "G" G,6 z2 :|



X:1
T:Brass Pop Victory
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Bb
% Voice 1 - Trumpet carrying the main melody
V:1 clef=treble name="Trumpet" subname="Tpt."
%%MIDI program 56
|: "Bb"D4 F2 D2 | "Eb"E2 E2 F2 G2 | "F"F4 D2 F2 | "Bb"D2 B2 "Cm"C2 D2 |
"Bb"D4 F2 D2 | "Eb"E2 E2 F2 G2 | "F"F3 E D2 C2 | "Bb"B,6 z2 :|
% Voice 2 - Trombone providing harmonic support
V:2 clef=treble-8 name="Trombone" subname="Trb."
%%MIDI program 57
|: "Bb"B,4 D2 B,2 | "Eb"E2 C2 B,2 A2 | "F"A4 F2 A2 | "Bb"B,2 G2 "Cm"G2 A2 |
"Bb"B,4 D2 B,2 | "Eb"E2 C2 B,2 A2 | "F"A3 G F2 E2 | "Bb"B,,6 z2 :|
% Voice 3 - Tuba anchoring with the bass line
V:3 clef=bass name="Tuba" subname="Tba."
%%MIDI program 58
|: "Bb"B,,4 B,,2 B,,2 | "Eb"E,4 E,2 E,2 | "F"F,4 F,2 F,2 | "Bb"B,,2 G,2 "Cm"G,4 |
"Bb"B,,4 B,,2 B,,2 | "Eb"E,4 E,2 E,2 | "F"F,3 E, D,2 C,2 | "Bb"B,,,6 z2 :|



X:1
T:Dance of the Fireflies
C:OrchestrAI
M:3/4
L:1/16
Q:1/4=126
K:Gmaj
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "G"D4 G4 B4 | z4 A3G F2A2 | "C"E4 C4 E4 | z4 D3C B,2C2 |
"G"D4 d4 G4 | "Bm"F4 d4 F4 | "Am"E4 c4 A4 | "D7"G,4 A4 F2E2 |
"G"G3A B2d2 g4 | "D"a4 f4 d4 | "C"e3f g2c2 e4 | "G"d6 B4 z2 |
"G"b2a2 g2f2 e4 | "D"d3c B2A2 G2F2 | "C"E6- E4 z2 | "D7"A,4 D4 F4 |
"G" [D3B,3]G B2 [G3B3]G D2 | z6 z2 z2 z2 :|
V:2 clef=treble name="Pizz. Strings"
%%MIDI program 45
|: "G"d4 B4 G4 | z4 A3G F2A2 | "C"c4 E4 A,4 | z4 B,3C G,2C2 |
"G"B4 d4 G4 | "Bm"a4 f4 b,4 | "Am"a4 e4 c4 | "D7"f4 a4 d2c2 |
"G"B3c d2B2 G4 | "D"f4 d4 A4 | "C"e4 G4 c4 | "G"g6 e4 z2 |
"G"g3f e2d2 c4 | "D"B3A G2F2 E4 | "C"c6- c4 z2 | "D7"A,6- A,4 z2 |
"G"B,4 D4 G4 | z6 z2 z2 z2 :|
V:3 clef=bass name="Marimba"
%%MIDI program 12
|: "G"G,4 D3 E G,3A, | B,4 A,3B, C2 D2 | "C"E,4 C4 E,4 | G,4 A,3 B, D3 E |
"G"G,4 D4 G,4 | "Bm"B,3 A, B,4 D4 | "Am"A,4 E4 A,4 | "D7"D3 C D2 A,4 z2 |
"G"G,4 D4 G,4 | "D" F,3 E D,4 F,3 A, | "C"E,4 C4 E4 | "G"D4 D,4 G,2 z2 |
"G"B,4 A,2 G,2 E,3 z | "D"F,4 E,2 D,2 C,4 | "C"C,6- C,4 z2 | "D7"D,6- D,4 z2 |
"G"G,4 D4 G,4 | z6 z2 z2 z2 :|



X:1
T:Romance in Motion
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=72
K:Cmaj
V:1 clef=treble name="Violin" subname="Main Melody"
%%MIDI program 40
|: "G"G4 E2 | "Em"E4 D2 | "C"C4 A,2 | "G"B,4 G2 |
"G"B3 c d2 | "Em"B4 A2 | "Am"G4 E2 | "D7"F4 D2 |
"Em"G4 F2 | "D" E4 G2 | "C"E6 | "G"B,4 G,2 |
"G"G3 A B2 | "Em"E4 D2 | "C"C4 A,2 | "D7"D4 D2 |
"G"G6 | "Em"e4 D2 | "C"c4 B,2 | "G" G4 z2 :|
V:2 clef=treble name="Piano" subname="Accompaniment"
%%MIDI program 0
|: "G"[G,2B,2D2] [G,2E2G2] z2 | "Em"[E,2G,2B,2] [E,2G,2B,2] z2 | "C"[C2E2G2] [C,2E2G2] z2 | "G"[D,2G,2B,2] [D,2B,2D2] z2 |
"G"[B,,2D,2G,2] [C2E2A2] z2 | "Em"[A,,2E,2G,2] [G,2B,2D2] z2 | "Am"[A,2E,2A,2] [C2E2A2] z2 | "D7"[D,2A,2D2] [F,2A,2D2] z2 |
"Em"[E,2G,2B,2] [G,2B,2E2] z2 | "D"[F,2A,2D2] [A,2D2F2] z2 | "C"[C2E2G2] [E2G2C2] z2 | "G"[D,2G,2B,2] [G,2B,2D2] z2 |
"G"[G,2B,2D2] [A,2C2E2] z2 | "Em"[E,2G,2B,2] [E,2G,2B,2] z2 | "C"[C2E2G2] [G,2C2E2] z2 | "D7"[F,2A,2D2] [A,2D2F2] z2 |
"G"[G,2B,2D2] [G,2E2G2] z2 | "Em"[E,2G,2B,2] [D,2G,2E2] z2 | "C"[C,2E2C2] [E,2C2G2] z2 | "G"[D,2B,2G2] [G,2D2B,2] z2 :|



X:1
T:Battle of the Ancients
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=170
K:Em
% The piece starts with an intense, fast-paced melody to set the stakes of the battle
V:1 clef=treble name="Brass" subname="Trumpets"
%%MIDI program 61
|:"Em"E4 z4 z4 B3G|"C"G4 z2 G2 z4 z4|"D"D4 F4 D4 A4|"Bm"B3A G4 z4 F4|
"Em"E4 z4 z4 B3G|"C"G4 z2 G2 z4 z4|"D"D4 F4 D4 G4|"B"B4 A4 z4 z2 z2|
"Em"B,4 E4 G4 B4|"G"G,4 C4 E4 G4|"Am"A,4 E4 C4 A,4|"F#m"F4 D4 z4 z2 z2|
"Em"E4 E4 E4 z2 z2|"D"D4 D4 F4 z2 z2|"G"B4 G4 E4 G4|"B7"B,4 A4 z4 z2 z2:|
V:2 clef=treble name="Strings" subname="Violins"
%%MIDI program 49
|:"Em"e8 B8|"C"c8 G4 z4|"D"d8 F8|"Bm"b8 F4 z4|
"Em"e8 B8|"C"c8 G4 z4|"D"d8 F8|"B"B8 z8|
"Em"B,8 E8|"G"G,8 C8|"Am"A,8 E8|"F#m"F8 D8|
"Em"e8 e8|"D"d8 d8|"G"b8 b8|"B7"B,8 A8:|
V:3 clef=bass name="Percussive Organ" subname="Organ"
%%MIDI program 18
|:"Em"B,4 E,4 G,4 B,4|"C"C4 G,4 E4 C4|"D"D4 G,4 F4 D4|"Bm"B4 F4 D4 B,4|
"Em"E,4 B,4 G,4 E,4|"C"C4 G,4 E4 C4|"D"D4 F4 D4 G,4|"B"B,4 F4 B,4 z4|
"Em"E,4 B,4 e4 g4|"G"G,4 c4 g4 b4|"Am"A,4 e4 c4 a4|"F#m"F4 d4 f4 a4|
"Em"e4 B,4 E4 G4|"D"d4 A,4 D4 F4|"G"B4 G,4 B4 d4|"B7"B,4 A4 F4 D4:|
V:4 clef=bass name="Bass" subname="Bass"
%%MIDI program 43
|:"Em"B,4 E,4 E,2 z4 B,2|"C"C4 G,4 G,2 z4 C2|"D"D4 F,4 F,2 z4 D2|"Bm"B,4 F,4 F,2 z4 B,2|
"Em"E,4 B,4 B,2 z4 E,2|"C"C4 G,4 G,2 z4 C2|"D"D4 F,4 F,2 z4 D2|"B"B,4 F,4 F,2 z4 B,2|
"Em"E,4 B,4 B,4 E,4|"G"G,4 C4 C4 G,4|"Am"A,4 E,4 E,4 A,4|"F#m"F,4 D4 D4 F,4|
"Em"E,4 B,4 B,4 E,4|"D"D,4 A,4 A,4 D,4|"G"G,4 B,4 B,4 G,4|"B7"B,4 A,4 A,4 F,4:|



X:1
T:Race to Victory at the Manifold Derby
C:OrchestrAI
M:3/4
L:1/16
Q:1/4=150
K:Gmaj
V:1 clef=treble name="French Horns" subname="Horns"
%%MIDI program 61
|:"G" B2d2 g4 f2e2|"D" d6 A2B2 d2|"Em" e2g2 (^c4 z6 B2)A2|"C" G6-E4-G2|
"G" B2d2 g4 f2e2|"D" F2A2 d2D2 F4|"G"G2B2 "A7"A2F2 "D"D4-E2|"G"G6 z2 z4|
"Am" c2e2 A2c2 e2g2|"D7" d2f2 A2d2 f2a2|"G" b2g2 e2g2 d2B2|"D7" F4-E4 D4|
"Em" e2g2 b4 e4|"C" G2E2 G4-E4|"D7"A2F2 z2 D6|"G" G,8- G,8:|
V:2 clef=treble name="Strings" subname="Str."
%%MIDI program 48
|:"G" D4 G4 D4|"D" A,4 D4 A,4|"Em" E4 G4 E4|"C" G,4 C4 E4|
"G" D4 G4 d4|"D" F4 d4 F4|"G"B,4 D4 G4|"D7"A4 F4 D4|
"Am" A,4 c4 e4|"D7" d3 f a4 z2 f2|"G" B4 g4 B4|"D7" F4 E4 D4|
"Em" e4 b4 e4|"C" E4 G4 C4|"D7"A4 F4 D4|"G" G,8- G,8:|
V:3 clef=bass name="Tuba" subname="Tuba"
%%MIDI program 58
|:"G" G,3 B, D4 G,4|"D" A,,3 D, F,4 A,,4|"Em" E,3 G, B,4 E,4|"C" C,3 E, G,4 C,4|
"G" G,3 B, D4 G,4|"D" F,,2 A,2 D4 F,4|"G"G,3 B, "A7"A,3 D, "D"D2 G,2|"G"G,8 z4|
"Am" C2 E2 A2 z4 c2|"D7" D2 F2 A2 z4 d2|"G" G,2 B,2 G4 z2 B,2|"D7" F,,4 E,4 D,4|
"Em" E,2 G,2 E4 z2 G,2|"C" G,,4 C2 z2 E4|"D7"D,4 F,4 A,4|"G" G,,8- G,,8:|



X:1
T:Boss Battle Blitz
C:OrchestrAI
M:3/4
L:1/16
Q:1/4=172
K:Cm
% Use of aggressive sounds to portray the intensity of a boss battle.
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"Cm"c8 g8|"G"B2d2B2 g4 z2 f4|"Cm"e2g2e2 c4 z2 c2B2|"G7"A2F2A2 z4 d6|
"Cm"G,2C2G,2 "Fm"c4 z2 c4|"Bm"e4 e2f2 g8|"G7"d2B2d2 g4 z2 g4|1"Cm"c8 c4 z4:|2"Cm"c8 c4 G,4|
V:2 clef=treble name="Brass" subname="Brass"
%%MIDI program 61
|:"Cm"G,2C2E2 z4 G,2C2E2|"G"B,2d2g2 z4 B,2d2g2|"Ab"E2G2c2 z4 E2G2c2|"G7"G,2D2G2 z4 G,2D2G2|
"Cm"G,4C4 G,8|"Fm"F,4A4 F,8|"Bm"F,2D2F2 A4 z2 A4|"G7"D,2B,2D2 z2 G,8:|
"Cm"G,4 C4 "Fm"F,4 A4|"Bm"F,4 D4 "G"A4 B4|"G7"G,4 G,4 "Cm"G,4 G,4|1"Cm"C8 C8:|2"Cm"C8 C4 G,4|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|:"Cm"C,16|"G"B,16|"Ab"G,16|"G7"F,16|
"Cm"G,16|"Fm"F,16|"Bm"D,16|"G7"D,16:|
"Cm"C,16|"Fm"A,16|"Bm"D,16|"G7"G,16|1"Cm"C8 C4 C4:|2"Cm"C8 C8|
V:4 clef=bass name="Percussive Organ" subname="Organ"
%%MIDI program 17
|:"Cm"c4z4 c'4z4|"G"b4z4 b'4z4|"Ab"e4z4 e'4z4|"G7"d4z4 d'4z4|
"Cm"c4z4 c4 z4|"Fm"a4z4 a4 z4|"Bm"f4z4 f'4z4|"G7"d4z4 d'4z4|
"Cm"c4z4 c4 z4|"Fm"a4z4 a4 z4|"Bm"f4z4 f'4z4|"G7"d4z4 d'4z4|1"Cm"c8 c4 z4:|2"Cm"c8 c8|



X:1
T:Clash of Titans
C:OrchestrAI
M:12/8
L:1/8
Q:3/8=180
K:Cm
V:1 clef=treble name="Brass" subname="Horns"
%%MIDI program 61
|:"Cm" G4 B4 d2 c  | c4 z4 z2 A  | "G" B4 d4 f2 e  | "Ab" c4 z4 z2 G  |
"Cm" E4 G4 c2 B  | "Fm" A4 z4 G2 A  | "Bb" d4 f4 a2 g  | "Cm" e4 z4 c2 B  |
"G"B4 d4 f2 e  | "Ab"g4 d4 c2 c  | "Cm"G4 B4 d2 c  | "G"G4 F4 E2 D  |
"Ab"G4 z3 F4  | "Bb"F4 z3 D4  | "Eb"E4 G4 c2 B  | "Cm"c6 C2 z2:|
V:2 clef=treble name="Strings" subname="Violins"
%%MIDI program 48
|:"Cm" e2 g2 c'2 b2 a2 | g2 f2 d2 c2 B2 | "G" f2 a2 d'2 f2 e2 | "Ab" g4 z4 g3  |
"Cm" e2 c2 G2 A2 G2 | "Fm" f4 z4 a3  | "Bb" b2 f2 d2 f2 d2 | "Cm" c4 z4 E3  |
"G"d'2 b2 g2 e2 c'2 | "Ab" c'2 a2 f2 d'2 c'2 | "Cm" c'2 g2 e2 c'2 a2 | "G" g4 d4 B3  |
"Ab" a2 f2 d2 e2 c'2 | "Bb" g2 d2 B2 c2 a2 | "Eb" g2 B2 e2 g2 f2 | "Cm" c2 z6 z2 :|
V:3 clef=bass name="Low Strings & Brass" subname="Celli & Tubas"
%%MIDI program 42
|:"Cm" C,4 E,4 G,2 | "G"G,4 D4 B,2 | "Ab" Ab,4 E4 C | "Cm"C,4 A,4 F,2 |
"G"G,4 D4 B,,2 | "Ab"Ab,4 E4 C | "Bb"Bb,4 F4 D | "Cm"C,4 E,4 G,2 |
"G"B,4 G,4 D2 | "Ab"Ab,4 E4 C | "Cm"C,4 A,4 F,2 | "G"G,4 D4 B,,2 |
"Ab"Ab,4 E4 C | "Bb"Bb,4 F4 D | "Eb"Eb,4 Bb,5  | "Cm" C2 z6 z2 :|
V:4 clef=bass name="Percussion" subname="Timp & Bass Drum"
%%MIDI program 48
|:"Cm" [CG]6 | "G" [DG]6 | "Ab" [EG]6 | "Cm" [CG]6 | "G" [DG]6 |
"Ab" [CG]6 | "Bb" [FA]6 | "Cm" [CG]6 | "G" [DG]6 | "Ab" [EG]6 |
"Cm" [CG]6 | "G" [DG]6 | "Ab" [CG]6 | "Bb" [FA]6 | "Eb" [EBb]6 | "Cm" C6 :|



X:1
T:Boss Battle Charge
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=170
K:Em
% The ensemble creates a soundscape of tension and ferocity fitting for a boss showdown
V:1 clef=treble name="Lead Brass"
%%MIDI program 61
|:"Em"e4B4 e4B4|g4e4 g2f2e2d2|c4G4 c4G4|B4G4 A4F4|
"Am"A4e4 A2B2c4|"B" B3AG3F E4D4| "C"G,4E4 G,4B,4|"D"D8- D4z4:|
V:2 clef=treble name="String Ensemble"
%%MIDI program 48
|:"Em"B4e4 B4e4|B4e4 B4e4|G4B4 G4B4|G4B4 G4B4|
"Am"c4A4 c4A4|"B"B4B4 B4B4| "C"e4C4 e4C4|"D"D4F4 D4F4:|
V:3 clef=treble name="Woodwind Choir"
%%MIDI program 73
|:"Em"g8- g4f4|e4^d4 e8|c8- c4B4|A4G4 F8|
"Am"A8- A4^G4|"B"B8- B4A4| "C"G8 G,8|"D"D8- D4z4:|
V:4 clef=bass name="Bass Brass" octave=-1
%%MIDI program 58
|:"Em"E,4E,4 B,4B,4|E,4E,4 G,4G,4|C4C4 G4G4|B,4B,4 A,4A,4|
"Am"A,4A,4 E,4E,4|"B"B,4B,4 B,4D4| "C"E,8 C,8|"D"D,8- D,4z4:|



X:1
T:Fantasia in E-flat Major
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=112
K:Eb
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "Eb" B3G E2GB | "Cm" c2e2 "F7" A3c | "Bb" d3B F2d2 | "Eb" e4 "G7" d3B |
| "Cm" c3B "Ab" c2e2 | "Bb" d3c "Eb" B2G2 | "Fm" A3G "Bb7" F2D2 | "Eb" E8 :|
|: "Gm" G3A B2d2 | "Cm" e3d c2B2 | "F7" A3B c2e2 | "Bb" d4 B4 |
| "Eb" G3A B2e2 | "Ab" c3B "Db" A2F2 | "Gm" G3F "C7" E2^C2 | "Fm" F8 :|
V:2 clef=treble name="Oboe"
%%MIDI program 68
|: "Eb" G3E B,2EG | "Cm" E2G2 "F7" F3A | "Bb" F3D B,2F2 | "Eb" G4 "G7" F3D |
| "Cm" E3D "Ab" E2G2 | "Bb" F3E "Eb" D2B,2 | "Fm" C3B, "Bb7" A,2F,2 | "Eb" E8 :|
|: "Gm" B,3C D2F2 | "Cm" G3F E2D2 | "F7" C3D E2G2 | "Bb" F4 D4 |
| "Eb" E3F G2B2 | "Ab" A3G "Db" F2D2 | "Gm" B,3A, "C7" G,2E,2 | "Fm" A,8 :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "Eb" E,3G, B,,2E,G, | "Cm" C,2E,2 "F7" F,3A, | "Bb" B,,3D, F,,2B,,2 | "Eb" E,4 "G7" G,,3B,, |
| "Cm" C,3B,, "Ab" A,,2C,2 | "Bb" B,,3A,, "Eb" G,,2E,,2 | "Fm" F,,3E,, "Bb7" D,,2B,,,2 | "Eb" E,,8 :|
|: "Gm" G,,3A,, B,,2D,2 | "Cm" C,3B,, A,,2G,,2 | "F7" F,,3G,, A,,2C,2 | "Bb" B,,,4 D,4 |
| "Eb" E,3F, G,2B,2 | "Ab" A,,3G,, "Db" F,,2D,,2 | "Gm" G,,3F,, "C7" E,,2C,,2 | "Fm" F,,8 :|
V:4 clef=bass name="Double Bass"
%%MIDI program 43
|: "Eb" E,,3G,, B,,,2E,,G,, | "Cm" C,,2E,,2 "F7" F,,3A,, | "Bb" B,,,3D,, F,,,2B,,,2 | "Eb" E,,4 "G7" G,,,3B,,, |
| "Cm" C,,3B,,, "Ab" A,,,2C,,2 | "Bb" B,,,3A,,, "Eb" G,,,2E,,,2 | "Fm" F,,,3E,,, "Bb7" D,,,2B,,,,2 | "Eb" E,,,8 :|
|: "Gm" G,,,3A,,, B,,,2D,,2 | "Cm" C,,3B,,, A,,,2G,,,2 | "F7" F,,,3G,,, A,,,2C,,2 | "Bb" B,,,,4 D,,4 |
| "Eb" E,,3F,, G,,2B,,2 | "Ab" A,,,3G,,, "Db" F,,,2D,,,2 | "Gm" G,,,3F,,, "C7" E,,,2C,,,2 | "Fm" F,,,8 :|



X:1
T:Final Confrontation Overture
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Bmin
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|: "Bm"fdfb afbf f4 e4 | "A"gagf e2c2 cdec B2A2 | "G"f2d2 d2F2 G4 A4 | "F#"B4 A4 F4 E4 |
"Bm"D2F2 B4 d9   | "A"E2C2 A4 e9   | "G"GABc d4 B4 c4 |1 "F#"B2F2 D4 B,4 E4 :|2 "F#"B2F2 D4 F4 z4 ||
V:2 clef=treble name="Rhythm Synth" subname="Chords"
%%MIDI program 81
|: "Bm"B,2F2 B,2F2 B,4 B,4 | "A"A,2E2 A,2C2 C4 E4 | "G"G,2D2 G,2B,2 D4 G4 | "F#"F,2A,2 C4 F,4 z4 |
"Bm"B,2F2 B,4 B,4 B,4 | "A"A,4 E4 A,4 E4 | "G"D,4 G,4 B,4 D4 |1 "F#"A,4 A,4 F,4 C4 :|2 "F#"A,4 A,4 F,4 z4 ||
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|: "Bm"B,8 B,8 B,  | "A"A,8 A,8 A,  | "G"G,8 G,8 G,  | "F#"F,8 F,8 F,  |
"Bm"B,8 B,8 B,  | "A"A,8 A,8 A,  | "G"G,8 G,8 G,  |1 "F#"F,8 F,8 F,  :|2 "F#"F,8 F,8 F,  ||



X:1
T:Mina's Melody
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:C
V:1 name="Flute" clef=treble
%%MIDI program 73
|:"C" E2 G3 E | "Am" A3 B c2 | "F" F2 A3 c | "G" G4 G,2 |
"C" e2 g4 | "Dm" f3 e d2 | "Em" e2 g3 e | "G" g4 g2 |
"Am" a2 e3 c | "F" d3 c B2 | "C" G2 E3 D | "G" C4-E2 |
"F" F2 G3 A | "Dm" A2 F3 E | "C" E2 C3 E | "G7" G,4 z2 :|
V:2 name="Violin 1" clef=treble
%%MIDI program 40
|:"C" C2 E3 C | "Am" A,3 C E2 | "F" F2 A3 c | "G" G4 G,2 |
"C" e2 c4 | "Dm" d3 c A2 | "Em" G2 E3 G | "G" G4 G,2 |
"Am" A2 C3 E | "F" f3 e d2 | "C" E2 C3 G, | "G" G,4-F2 |
"F" F2 A3 c | "Dm" d3 A F2 | "C" C2 G,3 C | "G7" C,4 z2 :|
V:3 name="Violin 2" clef=treble
%%MIDI program 40
|:"C" G,2 C3 G, | "Am" E2 A,3 E | "F" A,2 F3 A, | "G" D4 D,2 |
"C" G,2 E3 G, | "Dm" A,2 D3 F | "Em" B,2 G3 E | "G" B4 B,2 |
"Am" c2 A,3 F | "F" A2 F3 D | "C" G,2 C3 E | "G" G,4-G,2 |
"F" A,2 C3 E | "Dm" F2 D3 A, | "C" c2 G,3 E | "G7" G,4 z2 :|
V:4 name="Cello" clef=bass
%%MIDI program 42
|:"C" C,2 G,3 C | "Am" A,3 E3 A, | "F" F,2 C3 F, | "G" D,4 D,2 |
"C" E,2 C4 | "Dm" F,3 D3 A, | "Em" E,2 B,3 G, | "G" B,4 G,2 |
"Am" A,2 E3 A, | "F" F,3 C3 F, | "C" G,2 C3 E, | "G" G,4-G,2 |
"F" F,2 A,3 C | "Dm" D3 F,2 A, | "C" G,2 E,3 C | "G7" C,4 z2 :|



X:1
T:Family Embrace
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:Cmaj
% The introduction suggests warmth and familiarity
V:1 clef=treble
%%MIDI program 4
|: "C"E3 G c2 | "G7"B3 d g2 | "Am"c3 e a2 | "F"f3 a c'2 |
"C"g3 e c2 | "Dm"f3 d A2 | "Em"G3 E G2 | "G7" D6 :|
V:2 clef=treble
%%MIDI program 4
|: "C"C2 E4 | "G7"B,2 D4 | "Am"A,2 C4 | "F"A,2 C4 |
"C"G,2 E4 | "Dm"F2 D4 | "Em"G2 B,4 | "G7"D4 z2 :|
V:3 clef=bass
%%MIDI program 32
|: "C"C,2 G,4 | "G7"B,,2 D,4 | "Am"A,,2 E,4 | "F"F,2 A,4 |
"C"C,3 E, G,2 | "Dm"D,2 A,4 | "Em"E,2 B,,4 | "G7"D,4 z2 :|
% The melody develops, evoking conversations and laughter
V:1 clef=treble
|: "F"f3 a c'2 | "C"e3 g c'2 | "D7"d3 f a2 | "G"g3 b d'2 |
"C"c3 e g2 | "Am"a3 c e2 | "F"d3 c A2 | "G7"G6 :|
V:2 clef=treble
|: "F"A2 A,4 | "C"G2 E4 | "D7"F2 D4 | "G"B,2 D4 |
"C"G2 C4 | "Am"A2 A,4 | "F"F2 C4 | "G7"B,,2 F,4 :|
V:3 clef=bass
|: "F"F,2 C4 | "C"E,2 G,4 | "D7"D,2 A,4 | "G"B,,2 D,4 |
"C"C2 G,4 | "Am"A,,2 C4 | "F"F,2 A, D,2 z | "G7"G,4 z2 :|
% A tender middle section symbolizes the emotional connection
V:1 clef=treble
|: "Am"A3 c e2 | "Em"B3 e g2 | "F"c3 A f2 | "C"G3 E G2 |
"Am"c3 E A2 | "Dm"d3 F A2 | "G7"B3 d g2 | "C"c6 :|
V:2 clef=treble
|: "Am"E2 A,4 | "Em"G2 B,4 | "F"A2 c4 | "C"G2 E4 |
"Am"E2 c4 | "Dm"F2 A,4 | "G7"B,2 g4 | "C"c4 z2 :|
V:3 clef=bass
|: "Am"A,,2 E,4 | "Em"G,,2 B,,4 | "F"F,2 A, C2 z | "C"E,2 G,4 |
"Am"A,,2 C4 | "Dm"D,2 F,4 | "G7"G,2 D,4 | "C"C4 z2 :|
% A joyful resolution, representing the warmth of togetherness
V:1 clef=treble
|: "F"f3 a c'2 | "G"g3 f d2 | "C"e3 c G2 | "G7"B,3 d g2 |
"C"c4 e2 | "Am"a3 c e2 | "F"f3 e c2 | "C"c6 :|
V:2 clef=treble
|: "F"A2 c4 | "G"G2 B,4 | "C"C2 E4 | "G7"D2 B,4 |
"C"C2 G4 | "Am"A2 c4 | "F"F2 A,4 | "C"C4 z2 :|
V:3 clef=bass
|: "F"F,2 C4 | "G"G,2 D4 | "C"E,2 C, G,2 z | "G7"G,2 B,,4 |
"C"C2 E,4 | "Am"A,,2 C4 | "F"F,2 A,4 | "C"C4 z2 :|



X:1
T:Cartoon Capers
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=144
K:Cmaj
% Light-hearted melody with playful rhythms for cartoon's opening
V:1 clef=treble name="Piccolo" subname="Pc."
%%MIDI program 72
|:"C" E2 G2 c3B | "D7"A4 z2 G2 | "G7"Bd G2 F2E2 | "C"C4 z4 |
"C"e2e2 d3c | "F"A2A2 G4 | "C"EGCE D2E2 | "C"G6 z2 |
"Dm"F2A2 d2F2 |[M:2/4] "G7"B4 |[M:4/4] "E7"c2B2 A3G | "Am"A4 z2 A2 |
"F"G2A2 c2A2 | "G7"B2d2 c2B2 | "C"E2G2 "G7"A2F2 | "C"C8 :|
V:2 clef=treble name="Clarinet" subname="Cl."
%%MIDI program 71
|:"C" G,4 C3E | "D7"F4 z2 E2 | "G7"_B2G2 ^F4 | "C"C4 z4 |
"C"G,3G, ^F3F | "F"E3E D4 | "C"c2C2 E2D2 | "C"G4 z2 G,2 |
"Dm"A3A d2^c2 |[M:2/4] "G7"G4 |[M:4/4] "E7"G2^F2 E4 | "Am"A3G z2 A2 |
"F"c2C2 E2c2 | "G7"d2^F2 E2D2 | "C"G,2C2 "G7"B,2G,2 | "C"C6 z2 :|
V:3 clef=bass name="Acoustic Bass" subname="Bs."
%%MIDI program 32
|:"C" C,2 E,2 G,3A, | "D7"F,4 z2 A,2 | "G7"B,,4 D,2G,2 | "C"C,4 z4 |
"C"G,,4 G,3A, | "F"F,4 E,4 | "C"E,3C, G,2E,2 | "C"E,6 z2 |
"Dm"A,,4 D,4 |[M:2/4] "G7"D,4 |[M:4/4] "E7"B,,4 E,4 | "Am"C,3B,, z2 C,2 |
"F"F,2A,2 C2F,2 | "G7"D,2^F,2 D,4 | "C"C,4 "G7"B,,4 | "C"C,8 :|



X:1
T:Bandstand Frolic
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Gmaj
V:1 clef=treble name="Saxophone 1" subname="Alto Sax."
%%MIDI program 65
|: "G" B4 D4 | "C" E2G2 A2F2 | "G" G3A B2G2 | "D" F6 z2 |
"G" B4 c3B | "C" E4 D4 | "G" D2G2 "D7" A2F2 | "G" G8 |
"D" A6 A2 | "G" B4 D4 | "C" E4 c4 | "Am" A4 "D" F4 |
"G" G2F2 E2D2 | "Em" E4 "A7" A,4 | "D7" D3 E "G" G4 | "D" A4 G4 |
"G" B2d2 e3d | "C" c2B2 A2G2 | "G" G3A "C" G2E2 | "G" D6 z2 :|
V:2 clef=treble name="Saxophone 2" subname="Tenor Sax."
%%MIDI program 66
|: "G" d4 g4 | "C" c2e2 d2c2 | "G" B2d2 c2B2 | "D" A4 F4 |
"G" d6 d2 | "C" e4 c4 | "G" B2d2 "D7" c2A2 | "G" D8 |
"D" f4 f4 | "G" a4 g4 | "C" c6 e2 | "Am" d2B2 "D" A4 |
"G" G4 F3G | "Em" A4 "A7" c4 | "D7" A2F2 "G" G4 | "D" F6 z2 |
"G" d3e f2g2 | "C" c4 B3A | "G" d2B2 "C" c2e2 | "G" D6 z2 :|
V:3 clef=bass name="Bass" subname="Electric Bass"
%%MIDI program 33
|: "G" G,4 D4 | "C" E2C2 D2E2 | "G" B,4 D3G | "D" A,6 z2 |
"G" G,4 A,4 | "C" C2E2 D4 | "G" G,6 D2 | "G" G,8 |
"D" A,6 A,2 | "G" G,4 D4 | "C" C4 A,4 | "Am" A,4 "D" F4 |
"G" D2B,2 A,2G,2 | "Em" E,4 "A7" A,2C2 | "D7" D4 "G" G,4 | "D" A,6 z2 |
"G" G,2D2 D4 | "C" E6 C2 | "G" G,4 D4 | "G" B,6 z2 :|



X:1
T:Boss Battle Blitz
C:OrchestrAI
M:7/8
L:1/16
Q:3/8=180
K:Em
% Main melody with electric guitar sound capturing the battle intensity
V:1 clef=treble name="E.Guitar Lead" subname="Lead"
%%MIDI program 30
|:"Em" B2B2c2B2|B2e4 G2|"Am"c2A2A2G2|G2A4 F2|
"Em"B2B2G2F2|E4E4 D2C2|"D"B2A2F2G2|A8|
B2B4e2|d4B2G2|c2A2c2e2|d8|
e2e2e2d2|c2B4 A2|G2F2G2A2|"B7" B8:|
% Synthesized rhythmic backing to maintain urgency and momentum
V:2 clef=treble oct=1 name="Synth Rhythm" subname="Rhythm"
%%MIDI program 81
|:"Em" E4z4|E4z4|"Am" A4z4|A4z4|
"Em" E4z4|E4z4|"D" D4z4|D4z4|
E4z4|E4z4|A4z4|A4z4|
E4G4E4|F4 E4 D4|"B7"B,4z4|B,8:|
% Bass synthesizer for low-end power and grounding
V:3 clef=bass name="Synth Bass" subname="Bass"
%%MIDI program 87
|:"Em"E,8|E,8|"Am"A,8|A,8|
"Em"E,8|E,8|"D"D,8|D,8|
E,8|E,8|A,8|A,8|
E,4G,4|F,4E,4|D, z44|"B7"B,8:|
% Drum set for percussive drive
V:4 clef=perc stafflines=1 name="Drums" subname="Perc."
%%MIDI program 0
|:"Em" z6g2|z6g2|"Am"z6a2|z6a2|
"Em"z6B2|z6B2|"D"z6d2|z6d2|
z6g2|z6g2|z6a2|z6a2|
z6B2|z6c2|z6d2|"B7"z6B2:|



X:1
T:Intrigue and Inspiration
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=110
K:Gmaj
%%staves [(V1 V2) (V3 V4)]
V:1 clef=treble name="Flute"
%%MIDI program 73
[V:1]
|:"G"D2 | G4z2 | A4B2 | c6 | B4A2 | G6- | G4A2 | B4G2 | A6 :|
w: * * Mel-o-dy* calls, * In-trigue un-folds, * Har-mo-ny* draws* us* near
V:2 clef=treble name="Oboe"
%%MIDI program 68
[V:2]
|:z2 | D4z2 | E4D2 | F6 | E4F2 | D6- | D4E2 | F4E2 | D6 :|
w: * Coun-ter-point* guides, * Mov-ing a-long, * Spir-it of* song* here
V:3 clef=treble name="Guitar"
%%MIDI program 25
[V:3]
|:"G"B,2 | G,4B,2 | A,4B,2 | "D7"A,6 | G,4F,2 | "C"E,6- | E,4D,2 | "G"D,4B,,2 | "D7"A,4F,2 :|
w: Ten-sion it* grows, * Di-min-ish-ed* rose, * Lift-ing * us clear of * fear
V:4 clef=bass name="Cello"
%%MIDI program 42
[V:4]
|:"G"D,4 z2 | G,6 | "A7"A,4C2 | "D7"D,6 | G,4A,2 | B,6- | B,4C2 | "G"G,6 | D,6 :|
w: * Bass* lines a-base,* Up-ward* they chase, * Growth and* joy* ap-pear
% Section B - Development
[V:1]
|:"Em"G2 | e4z2 | "A7"A4c2 | "B7"B6 | "Am"c4B2 | "D"e6- | e4f2 | g4e2 | "D7"f6 :|
[V:2]
|:z2 | B4z2 | C4B,2 | D6 | C4D2 | B,6- | B,4C2 | D4C2 | B,6 :|
[V:3]
|:"Em"B2 | e4B2 | "A7"A4B2 | "B7" F6 | "Am"e4f2 | ^G6- | =G4A2 | B4=G2 | F6 :|
[V:4]
|:"Em"G,2 | E6 | "A7"A,4C2 | "B7"D,6 | "Am"E,4F,2 | "D"G,6- | A,4B,2 | "Em"G,6 | E,6 :|
% Section C - Recapitulation
[V:1]
|:"G"[D2B2] | G4z2 | A4B2 | c6 | B4A2 | G6- | G4A2 | B4G2 | A6 :|
[V:2]
|:z2 | [D2^F2]4z2 z2 | [G2E2]4D2 z2 | F6 | E4F2 | D6- | D4E2 | F4E2 | D6 :|
[V:3]
|:"G"B,2 | [G,2B,2]4B,2 z2 | [A,2E2]4B,2 z2 | [D2A,2]6 | [B,2G,2]4F,2 z2 | "C"E,6- | E,4D,2 | "G"[G,2D,2]4B,,2 z2 | [A,2^F,2]4D,2 z2 :|
[V:4]
|:"G"D,2 | G,4z2 | A,4C2 | D,6 | G,4A,2 | B,6- | B,4C2 | G,6 | D,6 :|



X:1
T:Intense Race Final Lap
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=200
K:Bmin
% Driving and relentless musical elements for the ultimate race climax
V:1 clef=treble name="Electric Guitar"
%%MIDI program 30
|: "Bm"B8 vB8 | "A"A8 vA8 | "G"G8 vG6 F2 | "F#7"F8 vF8 |
"Bm"B8 vB8 | "A"A8 vA8 | "E"E8 vE6 D2 | "F#7"F16 :|
V:2 clef=treble name="Synth Lead"
%%MIDI program 81
|: "Bm"d8 vFd7 | "A"c8 vEc7 | "G"B8 vGB6 A | "F#7"A8 vA8 |
"Bm"d8 vFd7 | "A"c8 vEc7 | "E"G8 vEG6 F | "F#7"F16 :|
V:3 clef=treble name="Brass Section"
%%MIDI program 62
|: "Bm"B,8 vB,8 | "A"A,8 vA,8 | "G"G,8 vG,6 F,2 | "F#7"F,8 vF,8 |
"Bm"B,8 vB,8 | "A"A,8 vA,8 | "E"E,8 vE,6 D,2 | "F#7"F,16 :|
V:4 clef=bass name="Bass Synth" transpose=-12
%%MIDI program 87
|: "Bm"B,16 | "A"A,16 | "G"G,16 | "F#7"F,16 |
"Bm"B,16 | "A"A,16 | "E"E,16 | "F#7"F,16 :|



X:1
T:Manifold Derby Dash
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=120
K:Gmaj
% The introduction section sets the stage for the exciting race
V:1 clef=treble name="Lead Violin" subname="Violin"
%%MIDI program 40
|"G" B2d d3 |"C" e2c A2F |"D7" D2F A2d |"G" G3 -G2 z |
"G" B2G E2D |"Am" c2A F2D |"Bm" B2d d2B |"D7" A3 -A2 z |
"G" B2d g3 |"C" e2c A2f |"D7" d3 d2B |"G" G3 -G2 z |
V:2 clef=treble name="Rhythm Guitar" subname="Guitar"
%%MIDI program 24
|"G" d2B G2G |"C" c2E C2C |"D7" A2F D2D |"G" G3 -G2 z |
"G" B2G D2G |"Am" A2F C2A |"Bm" D2B G2B |"D7" A3 -A2 z |
"G" d2B G2B |"C" c2E C2c |"D7" d2A F2A |"G" G3 -G2 z |
V:3 clef=treble name="Brass Section" subname="Brass"
%%MIDI program 61
|"G" G,2B, D2G, |"C" C2E, G,2C, |"D7" A,2D, F2A, |"G" G,3 -G,2 z |
"G" B,2D G2B, |"Am" A,2C E2A, |"Bm" D2F B2d |"D7" A,3 -A,2 z |
"G" G,2B, d2g |"C" E2c e2c |"D7" A2f a2d |"G" G,3 -G,2 z |
V:4 clef=bass name="String Bass" subname="Bass"
%%MIDI program 43
|"G" G,2D, G,2D, |"C" C,2G, C,2E, |"D7" A,2D, F,2A, |"G" G,3 -G,2 z |
"G" B,2G, B,2D, |"Am" A,2F, C,2E, |"Bm" B,2d B,2F, |"D7" A,3 -A,2 z |
"G" D2B, D2G, |"C" G,2C E,2C |"D7" F2A d2A, |"G" G,3 -G,2 z |



X:1
T:The Enchanted Valley
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=88
K:G
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G" B2 G2 D2 | "Em" E4 z2 | "C" c2 B2 A2 | "D" D4 z2 |
"G" d2 B2 G2 | "Am" A4 z2 | "Bm" B2 A2 G2 | "A" A4 z2 |
"C" B2^c2 d2 | "D" d4 z2 | "G" B2 d2 G2 | "D/F#" F4 z2 |
"Em" G2 E2 D2 | "C" c4 B2 | "G/D" D2 "Am" A2 "Bm" B2 | "G" G4 z2 :|
V:2 clef=treble name="Violin"
%%MIDI program 40
|:"G" D2 G2 B2 | "Em" G4 z2 | "C" E2 D2 C2 | "D" A,4 z2 |
"G" B2 G2 D2 | "Am" C4 z2 | "Bm" D2 C2 B,2 | "A" E4 z2 |
"C" G2 A2 B2 | "D" d4 z2 | "G" G2 B2 G2 | "D/F#" A4 z2 |
"Em" E2 C2 B,2 | "C" G4 F2 | "G/D" D2 "Am" A2 "Bm" B2 | "G" G4 z2 :|
V:3 clef=treble name="Harp"
%%MIDI program 46
|:"G" G,2 B,2 D2 | "Em" E4 z2 | "C" C2 G,2 E2 | "D" D2 A,2 F2 |
"G" G,2 D2 G2 | "Am" A4 z2 | "Bm" B,2 F2 B2 | "A" A4 z2 |
"C" C2 E2 G2 | "D" d4 z2 | "G" G2 B2 G2 | "D/F#" F4 z2 |
"Em" E2 G2 B2 | "C" c4 B2 | "G/D" D2 "Am" A2 "Bm" B2 | "G" G4 z2 :|
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"G" G,4 B,2 | "Em" E4 z2 | "C" C4 E2 | "D" D4 F2 |
"G" G,4 D2 | "Am" A4 z2 | "Bm" B,4 F2 | "A" A4 E2 |
"C" C4 G,2 | "D" d4 A,2 | "G" G4 z2 | "D/F#" F4 A,2 |
"Em" E4 G,2 | "C" C4 z2 | "G/D" D2 "Am" A2 "Bm" B2 | "G" G4 z2 :|



X:1
T:Enchanted Woodland Waltz
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=72
K:Am
V:1 clef=treble name="Violin I"
%%MIDI program 40
|: "Am"A4 A2 | "E7"G2 E2 F2 | "Am"A2 E2 C2 | "Dm"D4 z2 |
"C"E4 G2 | "G"B2 G2 G2 | "Am"A3 G E2 | "E7"E4 z2 |
"Am"A2 c2 B2 | "F"A2 d2 ^c2 | "E7"B4 G2 | "Am"A6 |
"Dm"F3 E D2 | "E7"E2 F2 G2 | "Am"A3 G E2 |1 "E7"E4 z2 :|2 "E7"E6 |]
V:2 clef=treble name="Violin II"
%%MIDI program 40
|: "Am"c2 E2 A2 | "E7"^G2 B2 c2 | "Am"A2 c2 E2 | "Dm"D4 z2 |
"C"G2 C2 E2 | "G"G2 B2 d2 | "Am"e4 c2 | "E7"^G4 z2 |
"Am"c2 A2 G2 | "F"=G2 F2 E2 | "E7"F3 D C2 | "Am"A6 |
"Dm"D2 F2 E2 | "E7"^G2 E2 F2 | "Am"A3 G E2 |1 "E7"E4 z2 :|2 "E7"E6 |]
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "Am"A,3 C E2 | "E7"B,2 E2 ^G2 | "Am"A,2 E2 C2 | "Dm"D4 z2 |
"C"C,2 E2 G2 | "G"B,2 D2 G2 | "Am"A,4 E2 | "E7"B,4 z2 |
"Am"A,2 A,2 A,2 | "F"C2 D2 E2 | "E7"G,3 F E2 | "Am"A,6 |
"Dm"D3 D E2 | "E7"^F2 ^G2 A2 | "Am"A,3 C E2 |1 "E7"E6 :|2 "E7"E4 z2 |]



X:1
T:Street Groove Spectacle
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Dmin
% Set MIDI instrument for each voice to mimic a street band's brass section
V:1 clef=treble
%%MIDI program 61
|:"Dm"A2A2A2GF|"C"=E2G2C2^F2|"A7"A,2E2"C"=G2F2|"Dm"D8|
"Dm"D2D2D2AG|"F"A2c2F2A2|"Bb"d2B2"Bb"A2G2|"A7"A6 z2|
"A7"c2c2c2BA|"Dm"B2A2"D7"G2F2|"Gm"G8|"A7"A2A2G2^F2|
"Dm"D2F2A4|"Gm"B2d2d2c2|"A7"c2A2G4-|"Dm"A2G2F4:|
V:2 clef=bass
%%MIDI program 58
|:"Dm"D4A,2D2|"C"E,4^G,2E,2|"A7"A,4E,2A,2|"Dm"D,8|
"Dm"D,4A,2D2|"F"F,4A,2F,2|"Bb"B,8|"A7"A,8|
"A7"=E8|"Dm"D,4"D7"F,2A,2|"Gm"G,8|"A7"A,8|
"Dm"D,2F,2A,4|"Gm"G,4B,4|"A7"^G,8|"Dm"D,8:|
% Define a percussive backbeat using MIDI instrument codes
V:P perc
%%MIDI program 25
|:"Dm"z4 z4|z4 z4|"A7"z4 z4|"Dm"z8|
"Dm"z4 z4|"F"z4 z4|"Bb"z4 z4|"A7"z4 z2 z2|
"A7"z4 z4|"Dm"z4 z4|"Gm"z4 z4|"A7"z4 z4|
"Dm"z4 z4|"Gm"z4 z4|"A7"z4 z4-|z4 z4:|



X:1
T:Pirate's Polka Jamboree
C:OrchestrAI
M:2/4
L:1/8
Q:1/4=120
K:Cmaj
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|:"C" G3/2E/2 E2 | "G7" D3/2B,/2 D2 | "C" c3/2e/2 dc | "G7" G,3/2B,/2 D2 |
"C" E3/2G/2 cB | "F" A3/2A/2 A2 | "C" Ge "G7"FD | "C" C2 z2 :|
|:"C" c2 ec | "G7" d2 Bd | "C" e2 ce | "G7" d2 B2 |
"C" g2 eg | "F" f2 af | "C" eg "G7"fd | "C" c2 z2 :|
V:2 clef=treble name="Clarinet"
%%MIDI program 71
|:"C" E3/2E/2 G2 | "G7" D3/2D/2 B,2 | "C" G3/2E/2 c2 | "G7" G,3/2D/2 B,2 |
"C" C3/2C/2 E2 | "F" A,3/2A,/2 A,2 | "C" C3/2E/2 "G7"A,3/2B,/2 | "C" E2 z2 :|
|:"C" e2 ge | "G7" d2 fd | "C" e2 ge | "G7" d2 B2 |
"C" c2 ec | "F" A,2 AA/ | "C" ec "G7"BA/ | "C" c2 z2 :|
V:3 clef=bass name="Tuba"
%%MIDI program 58
|:"C" C,2 F,2 | "G7" D,2 G,,2 | "C" C,2 E,2 | "G7" D,2 G,,2 |
"C" E,2 G,2 | "F" F,2 A,,2 | "C" G,,2 "G7"D,2 | "C" C,2 z2 :|
|:"C" C2 CC | "G7" D2 DD | "C" E2 EE | "G7" D2 G,2 |
"C" G,2 GG | "F" F2 FF | "C" G,2 "G7"D2 | "C" C2 z2 :|



X:1
T:Glorious Battle Saga
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=172
K:Bmin
% The composition is heroic, victorious, and intense.
V:1 clef=treble name="Brass" subname="Melody"
%%MIDI program 61
|: "Bm"d4 f4 B8 | "G" g4 B4 d'8 | "A" a4 e4 a8 | "Bm" b4 f4 b8 |
"Bm"d2d2 d2f2 "A"c'2c'2c'2e2 | "G"B2B2 B4 e4 f4 | "A"a4 a4 "F#m" f2f2 e6 "m" | "Bm" b8- b2a2 g2f2 :|
V:2 clef=treble name="Strings" subname="Harmony & Rhythm"
%%MIDI program 49
|: "Bm" B2- B2 f4 b8 | "G" G2- G2 B4 g8 | "A" A2- A2 e4 a8 | "Bm" B2- B2 f4 b8 |
"Bm" d4 f4 "A" e4 c'4 | "G" B2 B2 "G"B6-B2 z4 | "A"A4 "A"A4 "F#m"f4 "Bm"f4 | "Bm" b8- b2a2 g2f2 :|
V:3 clef=treble name="Woodwinds" subname="Counter-melody"
%%MIDI program 71
|: "Bm"b2f2 d'2f2 b8 | "G"a2d2 g2b2 a8 | "A"c'2c'2 a2c'2 a8 | "Bm" d'2a2 f2a2 d8 |
"Bm" d'4 d'4 "A" c'4 b4 | "G" a4 g4 "G"f4 d4 | "A" e8 "F#m"d'2c'2 b4 | "Bm" b'8 a2 g2 f2 z2 :|
V:4 clef=bass name="Low Brass & Strings" octave=-1
%%MIDI program 70
|: "Bm"D8 F8 | "G"G,8 B,8 | "A"A,8 C8 | "Bm"B,8 D8 |
"Bm" d8 f8 | "G" g8 b8 | "A" a,8 c8 | "Bm" b,8 d8 :|



X:1
T:Dance of the Enchanted Fairy
C:OrchestrAI
M:6/8
L:1/16
Q:3/8=104
K:Am
V:1 clef=treble
%%MIDI program 73
|: "Am"e2e2 e2e2c2 | "G"d2B2 G2G2G2 | c2c2 A2A2A2 | "E7"B6- B4 |
"A"c2e2 c2A2B2 | "Dm"d4 F2A2G2 | "Am"e2e2 e2e2c2 | "E7"B6- B4 |
"Am"A2e2 c2A2c2 | "G"B2d2 G2B2d2 | "C"e3g e2c2e2 | "A7"a6- a4 |
"Dm"d2d2 f2d2F2 | "Am"A2A2 e2c2A2 | "E7"B2B2 G2B2e2 | "Am"A6- A4:|
V:2 clef=treble
%%MIDI program 73
|: "Am"A,2A,2 C2E2C2 | "G"G,2B,2 D2G2B2 | "C"c2e2 G2c2e2 | "E7"e2^G2 B2e2^g2 |
"A"A,3C A,2C2E2 | "Dm"D2F2 A2d2f2 | "Am"A,3C A,2C2E2 | "E7"e2^G2 B2e2^g2 |
"Am"A,2A,2 A,2A,2A,2 | "G"G,2B,2 G,2B,2B,2 | "C"c2e2 c2e2e2 | "A7"a3^g a2^g2a2 |
"Dm"d2F2 d2F2F2 | "Am"A,2C2 A,2C2C2 | "E7"e2^G2 e2e2e2 | "Am"A,6- A,4:|
V:3 clef=bass
%%MIDI program 43
|: "Am"C,3E, C,2E,2A,,2 | "G"B,,3D, B,,2D,2G,,2 | "C"E,3G, E,2G,2C,2 | "E7"B,,3^F, B,,2^F,2B,,2 |
"A"C,3E, C,2E,2A,,2 | "Dm"F,3A, F,2A,2D,,2 | "Am"C,3E, C,2E,2A,,2 | "E7"B,,3^F, B,,2^F,2B,,2 |
"Am"A,,3C, A,,2C,2E,2 | "G"G,,3B,, G,,2B,,2D,2 | "C"C,2E,2 C,2C,2E,2 | "A7"A,3E, A,2E,2A,,2 |
"Dm"D,3F, D,2F,2A,,2 | "Am"A,,3C, A,,2C,2E,2 | "E7"B,,3^F, B,,2^F,2B,,2 | "Am"A,,6- A,,4:|



X:1
T:Manifold Markets
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=110
K:Em
V:1 clef=treble name="Vibraphone" subname="Vibes"
%%MIDI program 11
|:"Em"G4 B2 | "Am"c3 B AB | "B7"AF G2 FE | "Em"E4 z2 |
"Em"G3 F GA | "Am"B4 A2 | "B7"GF E3 F | "Em"G4 z2:|
V:2 clef=treble name="Violin" subname="Vln."
%%MIDI program 40
||:"Em"e2 "C"G4 | "Am"a3 g fe | "B7"f3 e dc | "Em"B4 z2 |
"Em"e3 d ef | "C" g4 f2 | "Am"a3 g fe | "Em"e4 z2:|
V:3 clef=bass name="Piano" subname="Pno."
%%MIDI program 0
|:"Em"E3 C E2 | "Am"A,3 C E2 | "B7"B,3 D F2 | "Em"E4 z2 |
"Em"E3 C E2 | "Am"A,3 C E2 | "B7"B,3 D F2 | "Em"E4 z2:|
V:4 clef=bass name="Contrabass" subname="C.B."
%%MIDI program 43
|:"Em"E,,4E,2 | "Am"A,,4A,2 | "B7"B,,4B,2 | "Em"E,4 z2 |
"Em"E,,4E,2 | "Am"A,,4A,2 | "B7"B,,4B,2 | "Em"E,4 z2:|



X:2
T:Nocturne of Shadows
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=70
K:Am
V:1 clef=treble
%%MIDI program 71
|:"Am"e4 A2fe dcBA|"G"GFED C2B,2 E2D2|"F"A4 F2AF C2D2|"E"E6 z2 E4|
"Am"A,2E2A2ce a2gf|"G"G2F2E2D2 C4|"F"A,2C2F2Ac f2ed|"E"E6 z2 E2C2|
"Dm"d4 f2ed c2AG|"Am"E2A2 c2e2 a4|"G"g4 B2AG F2ED|"F"E2A2- A2FE D4|
"Am"A2ce a2fg a2ga|"G"b2ag g2fe d2dc|"F"A2c2 f2af c2B2|"E"E6 z2 E4:|
V:2 clef=bass
%%MIDI program 43
|:"Am"A,4 A,2E2 D2C2|"G"B,4 B,2G2 E2D2|"F"C4 C2F2 A2G2|"E"E,4 E2B,2 G2F2|
"Am"A,2A,2 E2A,2 E4|"G"B,2D2 G2B,2 D4|"F"C2C2 F2C2 A2F2|"E"E,2E,2 E2B,2 E4|
"Dm"D2F2 A2D2 F4|"Am"A,2E2 A2D2 E4|"G"G,2B,2 D2G2 B,2G2|"F"F2C2 A2F2 C4|
"Am"A2E2 C2A,2 A,2E2|"G"B,2G2 G2B,2 D4|"F"C2F2 F2C2 F4|"E"E,2E,2 E2B,2 E4:|



X:1
T:Quest of Legends
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=150
K:Cmaj
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"C"E2 G4 AB | c4 B2G2 | "G" D2B,4 DE | G4 F2A2 |
"C"E2 G4 AB | c4 B2c2 | "Am" A2 E2 C4 |1 "F"F6 z2 :|2 "F"F6 GF |]
|:"Em" E4 F2G2 | A4 G2 B2 | "Dm" D2F4 A2 | "G" G2 F2A2 B2 |
"Em"G2 E4 F2 | "C"E2 D2C4 | "Am"A3 G F2E2 |1 "G"G6 GF :|2 "G"G8 |]
V:2 clef=treble name="Arpeggio Synth" subname="Arpeggio"
%%MIDI program 82
|:"C" C2E2 G2E2 | A2G2 E2C2 | "G" B,2D2 G2B2 | F2E2 C2A,2 |
"C" C2E2 G2E2 | A2B2 c2G2 | "Am" A2C2 E2C2 |1 "F" F2A2 c2A2 :|2 "F" F2E2 D2C2 |]
|:"Em" E2G2 B2G2 | A2B2 G2E2 | "Dm" D2F2 A2F2 | "G" G2F2 E2D2 |
"Em" E2G2 B2G2 | "C" G2E2 C2E2 | "Am" A2G2 F2E2 |1 "G" G2F2 E2D2 :|2 "G" G4 z4 |]
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|:"C" C4 z4 | G,4 z4 | "G" D4 z4 | B,,4 z4 |
"C" C4 z4 | G,4 z2E2 | "Am" A,4 z4 |1 "F" F4 z2C2 :|2 "F" F4 G,4 |]
|:"Em" E4 G,2B,2 | "Dm" A,4 D2F2 | "G" G4 F4 |1 "G" G,2A,2 B,4 :|2 "G" G4 z4 |]



X:1
T:Mystic Dreamscapes
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Am
% This composition explores ethereal harmonies and dreamlike textures
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"Am" c2A2 A2G2 | "Em" B2G2 G3E | "F" F2D2 D2C2 | "G" D3E F3G |
| "Am" A3G F2E2 | "Em" G3F E3D | "F" F2D2 D2C2 | "G" D3E F3G |
% Etc...
V:2 clef=treble name="Violin"
%%MIDI program 40
|:"Am" E3A c2e2 | "Em" B3e g3b | "F" A3c f2a2 | "G" D3G B3d |
| "Am" c3A A2G2 | "Em" B3G G3E | "F" F2D2 D3C | "G" D3E F3G |
% Etc...
V:3 clef=bass name="Cello"
%%MIDI program 43
|:"Am" A,4 C,4 | "Em" G,4 B,4 | "F" C,4 F,4 | "G" G,4 B,4 |
| "Am" A,4 C,4 | "Em" G,4 B,4 | "F" C,4 F,4 | "G" G,4 B,4 |
% Etc...



X:1
T:Clash of Fates
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=172
K:Bm
% The introduction sets the stage for an intense battle
V:1 clef=treble name="Violin" subname="Vln."
%%MIDI program 40
|:"Bm"d8 d2c2B2c2|"F#m"A8 A2G2F2G2|"G"b8 b2a2g2f2|"A"e8 e2f2e2d2|
"Bm"d2c2B2A2 G4 F4|"F#m"E4 F4 G4 A4|"Em"G8- G4 F4|"A"E8 E6 z2:|
V:2 clef=treble name="Brass" subname="Br."
%%MIDI program 61
|:"Bm"B,8 B,2A,2B,2C2|"F#m"F,8 F,2E,2F,2G,2|"G"G,8 G,2F,2G,2A,2|"A"A,8 A,2B,2A,2G,2|
"Bm"B,2A,2G,2F,2 E4 D4|"F#m"C4 D4 E4 F4|"Em"G,8- G,4 F,4|"A"A,8 A,6 z2:|
V:3 clef=bass name="Cellos" subname="Cel."
%%MIDI program 42
|:"Bm"B,16|"F#m"F,16|"G"G,16|"A"A,16|
"Bm"B,2A,2B,2C2 D4 D2E2|"F#m"F,4 G,4 A,4 B,4|"Em"G,8- G,4 E,4|"A"A,8 A,4 z4:|
V:4 clef=bass name="Tubas" subname="Tub."
%%MIDI program 58
|:"Bm"F,,16|"F#m"C,16|"G"B,,16|"A"F,,16|
"Bm"F,,8 F,8|"F#m"C,8 E,8|"Em"B,,8 B,8|"A"F,,16:|



X:1
T:MacBreak Weekly Intro
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Cmaj
% Podcast intro tune
V:1 clef=treble
%% MIDI program 114
|:"C"e2c2G4|"Am"A2c2E4|"F"f2d2A4|"G"g4- g2z2|
"C"g3e c'2a2|"Am"e3d c2E2|"F"f2a2 g2f2|"G"g6- g2|
"C"e2"G"d2"D"e2"A7"c'2|"F"c2"G"b2"D7"a4|"G"B2c2 d2B2| "C"c6- c2:|
V:2 clef=treble
%% MIDI program 46
|:"C"C2E2G4|"Am"A3B c4|"F"F2A2c4|"G"G4- G2z2|
"C"C2E2 G4|"Am"A3B c4|"F"F2A2 G4|"G"G6- G2|
"C"c2"G"B2"D"A2"A7"B2|"F"A2"G"G2"D7"F4|"G"G2F2 G2E2|"C"c4 z4:|
V:3 clef=bass
%% MIDI program 34
|:"C"C,4 G,4|"Am"A,4 E,4|"F"F,4 C4|"G"G,4 z D3|
"C"E,4 G,4|"Am"A,4 E,4|"F"F,4 C4|"G"B,3 z2 D3|
"C"c2"G"B,2"D"A,2"A7"E,2|"F"z2"G"G2"D7"F4|"G"G,2 F,2 z2 E,2|"C"C,6- C,2:|



X:1
T:Festival of Virtuosos
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=144
K:Eb
V:1 clef=treble name="Saxophone" subname="Alto Sax."
%%MIDI program 65
|: "Eb"G2B2 G2B2 | "Cm" c6B2 | "Fm" A2c2 A2c2 | "Bb7"b6g2 |
"Ab"G2B2 c4 | "Eb"B8 | "C7" e2 c2 E2G2 |1 "Bb7"F8 :|2 "Bb7"F4 z4 :|
V:2 clef=treble name="Trumpet" subname="Trp."
%%MIDI program 56
|: "Eb"e2g2 f4 | "Cm" a2f2 e4 | "Fm"f2a2 g4 | "Bb7"d2B2 G4 |
"Ab"g2e2 c2B2 | "Eb"G6 F2 | "C7"G2E2 F4 |1 "Bb7"D8 :|2 "Bb7"D4 z4 :|
V:3 clef=treble name="Trombone" subname="Trbn."
%%MIDI program 57
|: "Eb"G,2C2 G,2C2 | "Cm"c6G2 | "Fm"F,2A,2 F,2A,2 | "Bb7"B,6g,2 |
"Ab"G,2C2 d4 | "Eb"C8 | "C7"e2c2 E4 |1 "Bb7"F8 :|2 "Bb7"F4 z4 :|
V:4 clef=bass name="Tuba" subname="Tuba"
%%MIDI program 58
|: "Eb"B,,2E,2 B,,2E,2 | "Cm"G,,6B,,2 | "Fm"C,2F,2 C,2F,2 | "Bb7"F,6D,2 |
"Ab"B,,2E,2 E,4 | "Eb"E,8 | "C7"G,,2E,2 C,4 |1 "Bb7"B,,8 :|2 "Bb7"B,,4 z4 :|



X:1
T:Dream Confrontation Overture
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=90
K:Bm
% The piece begins with a sense of uncertainty
V:1 clef=treble name="Strings" subname="Violin I"
%%MIDI program 48
|:"Bm" B2f2 B2f2 B2d2 A2F2|"F#m"A2e2 A2e2 A2c2 A2E2|
"G" G2B2 D2G2 E4 E4|"A"A2e2 c2A2 F4 F4|
"Bm" B4 B4 a4 a4|"Em"g4 e4 f2d2 B2G2|"F#7"f2a2 A2f2 f4 f4|
"Bm"b8 b4 g4|"E"e4 e4 e2c2 B2G2|"D"A2d2 F2A2 A4 A4|
"F#m"f4 f4 e4 e4|"Bm"d2f2 b2d2 b8:|
% The middle section will have increased tension
|:"G" d4 B2d2 g4 g4|"A"e4 c2A2 a4 a4|
"Bm" b4 gfed c4 A3F|"Em"G4 FGAB A4 F4|
"F#7"E4 e4 fgaf f4 E4|"Bm"B2d2 f2a2 b4 f4|
"E"e2e2 G2B2 e4 z2 B2|"D"f2f2 A2d2 f4 z2 d2:|
% The hero begins to overcome their fears
|:"G" G2B2 G2B2 D4 D4|"A"A2c2 A2c2 E4 E4|
"Bm"B2d2 f2d2 B4 B4|"F#7"a6^a2 a8|
"Bm"b2f2 b2f2 b4 b4|"E"e2c2 e2c2 e4 e4|
"D"A2d2 d2F2 D4 D4|"Bm"f2B2 B4- B4 z4:|
% The piece transitions to resolution and determination
V:2 clef=treble name="Strings" subname="Violin II"
%%MIDI program 48
|:"Bm" D4 D4 E4 F4|"F#m"A4 A4 B4 c4|
"G"B,4 C4 D4 E4|"A"E4 E4 F4 G4|
"Bm"G4 F4 E4 D4|"Em"E4 G4 A4 F4|"F#7"D4 ^D4 E4 F4|
"Bm"F4 G4 A4 B4|"E"B,4 A4 G4 F4|"D"F4 G4 A4 B4|
"F#m"D4 E4 F4 G4|"Bm"B4 A4 G4 F4:|
% The harmony reflects the personal growth
|:"G"D4 G4 B4 d4|"A"A4 e4 c4 A4|
"Bm" f4 B4 d4 f4|"Em"g4 e4 g4 B4|
"F#7"A6^A2 A8|"Bm"d4 B4 d4 B4|
"E"G4 E4 G4 E4|"D"F4 D4 F4 D4:|
% The hero emerges stronger and determined
|:"G"B,4 D4 G4 B,4|"A"E4 A4 c4 E4|
"Bm"d4 f4 b4 d4|"F#7"a6^a2 a8|
"Bm"B6 f2 B8|"E"e6 e2 e8|
"D"A6 d2 A8|"Bm"B8- B8 z8:|
V:3 clef=bass name="Brass" subname="Horns"
%%MIDI program 60
|:"Bm"F2, F,4 B4 B2, D4|"F#m"E,4 C4 A4 A2 E4|
"G"G,2 B,2 D4 G,2 z4 B,2|"A"F,2 A2 c4 E2 z4 G2|
"Bm"B,2 D2 G4 B, z3 G4|"Em"E2 B,2 D4 E2 z4 G2|
"F#7" F2 F4 A2 c2 z2 f4|"Bm"B,2 F,2 B4 B4 z4|
"E"G,2 B,2 E4 G2 z4 B,2|"D"A2 D2 F4 A,2 z4 D2|
"F#m"F,4 A,2 C4 E,2 z2 A2|"Bm"B,2 D2 F4 B,2 z4 D2:|
% The tension unravels as the hero gains confidence
|:"G"G,2 D2 G,2 B,2 z4 D4|"A"A,2 E2 A, C4 z3 E4|
"Bm"B, B2 D4 F4 z3 B2|"Em"E4 G2 B2 G4 E4|
"F#7"A,4 F2 A4 c2 A4|"Bm"B,2 D4 F4 z2 B,4|
"E"G,2 B,2 E4 G4 B,4|"D"D2 F2 A4 d2 z2 f4:|
% Courage and determination are now the theme
|:"G"G,4 D4 G,4 D4|"A"A,4 E4 A,4 E4|
"Bm"B,4 D4 F4 B4|"F#7"A,8 ^A4^A4|
"Bm"B,4 F4 B4 F4|"E"E,4 G4 B4 E4|
"D"D4 F4 A4 D4|"Bm"B,8 B,8 z8:|



X:1
T:Mystery of the Hidden Temple
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=50
K:Em
% Voice 1: Ethereal melody with a choir sound, representing the chant-like singing of the secret society
V:1 clef=treble name="Choir Aahs"
%%MIDI program 52
|:"Em" E4 z2 | "Cmaj7"G3 A B2 | "D"D4 z2 | "Bm"B3 c d2 |
"Am" A4 z2 | "Em"G4 E2 | "D" F3 E D2 | "Em" E6 |
"C" C2 E2 G2 | "D" F4 A2 | "Bm" B2 d2 f2 | "Em" E6 |
"Eaug"G2 ^F2 G2 | "Am" A3 B c2 | "D" D3 E F2 | "Em" E4 z2 :|
% Voice 2: Mysterious and solemn melody, with a string ensemble to provide the thick texture and mystique of an ancient ritual
V:2 clef=treble name="String Ensemble"
%%MIDI program 48
|:"Em" e2 B2 G2 | "Cmaj7"e2 G2 E2 | "D"f2 d2 A2 | "Bm"f2 d2 B2 |
"Am"e2 A2 E2 | "Em"G2 B2 E2 | "D"F2 D2 F2 | "Em"e6 |
"C"e2 c2 G2 | "D"f2 A2 d2 | "Bm"d2 B2 f2 | "Em"e6 |
"Eaug"e2 B2 g2 | "Am"e2 c2 A2 | "D"A2 d2 f2 | "Em"e4 z2 :|
% Voice 3: Deep and resonant organ sound, holding the harmony and creating an atmosphere of depth and solemnity
V:3 clef=bass name="Pipe Organ"
%%MIDI program 19
|:"Em" E2, E,2 G,2 | "Cmaj7"C2 E2 G2 | "D"D2, F,2 A,2 | "Bm"B,2 D2 F2 |
"Am"A,2 E2 A2 | "Em"E,2 G2 B,2 | "D"D2, F,2 D2 | "Em"E3 ^D E2 |
"C"C2, E,2 G,2 | "D"D2, F,2 A,2 | "Bm"B,2 D2 F2 | "Em"E6 |
"Eaug"G,2 ^F,2 G,2 | "Am"A,2 E2 A2 | "D"D2, D2 F2 | "Em"E2,4 z :|



X:1
T:Rhapsodic Voyage
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:C
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "C"E2G2 A2B2 | "Am"c4 B2A2 | "F"F2A2 d3c | "G"B4 z4 |
"E"e2^g2 a2^f2 | "A7"e4 d2^c2 | "Dm"d2f2 a3g | "E7"e4 z2B2 |
"A"e2a2 c'2b2 | "F#m"c'4 a2g2 | "Bm"f#2a2 d'3c' | "A7"a4 z4 :|
V:2 clef=treble name="Oboe"
%%MIDI program 68
|: "C"C2E2 G2A2 | "Am"A4 G2F2 | "F"A2c2 f2e2 | "G"d4 z4 |
"E"e2^g2 e2c2 | "A7"d4 ^c2d2 | "Dm"f2a2 d3c | "E7"b4 z2^g2 |
"A"c'2e2 a2^f2 | "F#m"g2e2 f2d2 | "Bm"d2f2 a2^g2 | "A7"a4 z4 :|
V:3 clef=treble name="Violin"
%%MIDI program 40
|: "C"G,2C2 E2G2 | "Am"A,4 G,2F,2 | "F"C2F2 A2c2 | "G"G,4 z4 |
"E"E2G2 c2e2 | "A7"A4 G2F2 | "Dm"D2F2 A2d2 | "E7"E4 z2G2 |
"A"A2c2 e2a2 | "F#m"F2A2 c2f2 | "Bm"B2d2 f2b2 | "A7"a4 z4 :|
V:4 clef=bass name="Harpsichord"
%%MIDI program 6
|: "C"C,4 E,4 | "Am"A,,4 G,4 | "F"F,4 A,4 | "G"G,4 z4 |
"E"E,4 G,4 | "A7"A,,4 ^C4 | "Dm"D,4 F,4 | "E7"E,4 B,,4 |
"A"A,,4 C4 | "F#m"F,,4 A,4 | "Bm"B,,4 D4 | "A7"A,4 z4 :|



X:1
T:The Seeker's Eureka
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Am
% The introduction signals the start of the adventure and quest for discovery
V:1 clef=treble
%%MIDI program 1
|:"Am"A2 | e3f e2c2 | "F"d4 c2A2 | "G"B4 cBAG | "E7"A6 :|
% The motive represents the path of discovery with ascending movements
|"Am"A2e2 "F"f2d2 | "C"e2c2 "E7"B2G2 | "Am"a4 g2e2 | "F"f6 e2 |
|"Dm"d2f2 "G"g2B2 | "C"e2G2 "E7"A2c2 | "Am"a3g "F"a2g2 | "E7"e4 z2 A2 |
% The B section simulates the moment of discovery with rhythmical intensity.
|"Am"e2^c2 d2B2 | "Dm"g2a2 b2a2 | "G"g2B2 c2d2 | "E7"B6 z2 |
|"Am"a2e2 "F"c2A2 | "C"G2E2 "E7"D2C2 | "Am"A4 G2E2 | "F"F6 z2 |
|"Dm"d2c2 "E"e2d2 | "Am"c2B2 A2G2 | "E7"F4 G2E2 | "Am"A8:|
% The final section reflects the joy and energy of a successful discovery
V:2 clef=treble
%%MIDI program 73
|:"Am"A2 | c'4 c3B | "F"A4 G2F2 | "G"B4 d2G2 | "E7"E4 c2A2 |
"Am"A2 e2 "F"d2A2 | "C"c2G2 "E7"B2E2 | "Am"A4 a2e2 | "F"f4 d2c2 |
"Dm"d2A2 "G"G2B2 | "C"c2E2 "E7"G2B2 | "Am"a4 g2e2 | "E7"e4 c2B2 |
% This passage uses syncopation and dotted rhythms to enhance excitement
|"Am"c'4 b2a2 | "Dm"g2a2 b2g2 | "G"B2c2 d2G2 | "E7"E4 E2 z2 |
"Am"A4 G2E2 | "F"F2A2 c2d2 | "C"E4 c2E2 | "F"D6 z2 |
"Dm"D2d2 "E"E2e2 | "Am"c'2b2 a4 | "E7"B4 G2F2 | "Am"A8:|
% The bass voice (V:3) will provide a foundation with arpeggios and pedal tones.
V:3 clef=bass
%%MIDI program 32
|:"Am"A,2 | E2 A2 C4 | "F"F,2 C2 A4 | "G"G,2 B,2 D4 | "E7"E4 z2 A,2 |
"Am"A,2 E2 "F"F2 C2 | "C"C2 E2 "E7"G2 B,2 | "Am"A4 a2 e2 | "F"F4 z2 c2 |
"Dm"D2 A,2 "G"G2 D2 | "C"C2 E2 "E7"B,2 G2 | "Am"A4 e2 A2 | "E7"E4 z2 A,2 |
% The bass joins the thrill with driving rhythms and harmonic support.
|"Am"A,2 C2 E2 | "Dm"D4 F2 A2 | "G"G,2 D2 B,2 | "E7"E4 z2 E2 |
"Am"A4 E2 C2 | "F"F4 C2 A,2 | "C"C2 E2 G2 | "F"F6 z2 |
"Dm"D2 A,2 D2 | "E"E4 c2 G2 | "Am"A,4 E2 C2 | "E7"E8:|




X:1
T:Oktoberfest Frolic
C:OrchestrAI
M:2/4
L:1/8
Q:1/4=104
K:C
V:1 clef=treble name="Harmonica"
%%MIDI program 22
|:"C"G4 | GABc | "G7"dgfe | d4 | "F"c2Ac | "C"cBAG | "G"gfed | "C"c4 |
"C"e2ce | "G"dBGB | "C"c4 | "G7"d2Bd | "C"e4 | "G"dB g2 | "A7"gfed | "Dm"d4 |
"F"c2Ac | "C"cBAG | "G7"gfed | "C"e2ce | "G7"dBg2 | "C"c4 | "G7"d2Bd | "C"c4 :|
V:2 clef=treble name="Clarinet"
%%MIDI program 71
|:"C"c4 | cd e2 | "G7"b2g2 | f4 | "F"f2df | "C"e2c2 | "G"b2a2 | "C"g2e2 |
"C"e2c2 | "G"b2d2 | "C"g4 | "G7"fg a2 | "C"c2c2 | "G"b2a2 | "A7"f2e2 | "Dm"A4 |
"F"A2FA | "C"G2E2 | "G7"c2B2 | "C"G2A2 | "G7"Bdg2 | "C"c4 | "G7"faf2 | "C"c4 :|
V:3 clef=bass name="Tuba"
%%MIDI program 58
|:"C"C,4 | E,G, C,2 | "G7"D2F2 | B,,4 | "F"A,,2C2 | "C"E,G, C,2 | "G"DFA2 | "C"C,2G,2 |
"C"C,2E,2 | "G"B,,2D2 | "C"G,4 | "G7"F2D2 | "C"C2E2 | "G"B,,2D2 | "A7"A,,2C2 | "Dm"A,4 |
"F"F,,2A,2 | "C"C,2E,2 | "G7"D2F2 | "C"G,2C2 | "G7"B,2D2 | "C"C4 | "G7"D2F2 | "C"C,4 :|



X:1
T:Dissonant Quartet in C minor
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Cmin
% Use the string ensemble sound for a quartet feel
V:1 clef=treble name="Violin I"
%%MIDI program 40
|: "_Cm"cg^fg "Cdim"ag^fg | "_Ddim"D^FDG "Bdim"B,D^FD | "_AbM7"=ECEG "FmM7"A,C=EC | "_G7"C8 :|
| "_Cm"^f2e2 d2c2 | "FmM7"B2A2 G2^F2 | "_G7"E2^F2 G4 | "Cdim"_B,2A,2 G,4 :|
V:2 clef=treble name="Violin II"
%%MIDI program 40
|: "_Cm"c'gec "Cdim"c'a^fc' | "_Ddim"d'^fd'd "Bdim"b,dd'f' | "_AbM7"aceg "FmM7"afda | "_G7"c'4 z4 :|
| "_Cm"=g2f2 e2d2 | "FmM7"bagf e^def | "_G7"=g2^g2 c'4 | "Cdim"=B,2C2 D4 :|
V:3 clef=alto name="Viola"
%%MIDI program 41
|: "_Cm"c^FGc "Cdim"cAgc | "_Ddim"dDAd "Bdim"b,dDb | "_AbM7"=E=E^CE "FmM7"A,FA,c | "_G7"C2C2 C4 :|
| "_Cm"^F2_E2 D2C2 | "FmM7"B,2_A2 G4 | "_G7"=E2^F2 G4 | "Cdim"_B,=B,^F^F,4 z :|
V:4 clef=bass name="Cello"
%%MIDI program 42
|: "_Cm"C,G,C,G, "Cdim"_B,,F,B,,D, | "_Ddim"D,A,D,F, "Bdim"B,,,B,,F,B, | "_AbM7"=E,A,C,E, "FmM7"A,C,_E,A, | "_G7"C,8 :|
| "_Cm"^F,G,^F,C "_G7"G,,C,G,C | "FmM7"F,B,,F,B, E,A,E,A, | "_G7"=E,^F,G,C E,^F,G,C | "Cdim"_B,,D,=B,,D,4 z :|



X:1
T:Rally of Champions
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Cmaj
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|:"C" G4 E2 G2 | "F" A6 G2 | "C" G4 E2 C2 | "G" D6 C2 |
"F" A4 G2 F2 | "C" E4 C2 G2 | "Am" A6 G2 | "G" G6 z2 |
"C" e2 g2 e2 c2 | "F" d2 f2 d2 A2 | "G" c4 G2 E2 | "D" F6 E2 |
"Em" G4 E2 G2 | "Am" c4 B2 A2 | "F" G2 F2 E2 D2 | "G" C4 G,2 z2 :|
V:2 clef=treble name="Electric Guitar"
%%MIDI program 30
|:"C" c6 e2 | "F" a6 g2 | "C" e4 c2 G2 | "G" D6 C2 |
"F" c6 A2 | "C" G4 E2 C2 | "Am" c6 B2 | "G" d4 c2 z2 |
"C" e2 c2 G2 E2 | "F" f2 d2 A2 c2 | "G" G4 E2 D2 | "D" F4 D2 E2 |
"Em" G4 E2 c2 | "Am" A4 G2 F2 | "F" E2 D2 C2 E2 | "G" G6 z2 :|
V:3 clef=bass name="Bass Guitar"
%%MIDI program 33
|:"C" C2 G,2 C2 E2 | "F" F2 C2 F2 A2 | "C" G,2 E2 G,2 C2 | "G" D2 B,,2 D2 G,2 |
"F" A,2 F2 A,2 c2 | "C" G,2 E2 C2 E2 | "Am" A,2 E2 A,2 C2 | "G" D2 G,,2 D2 G,2 |
"C" C2 G,2 E2 G,2 | "F" F2 C2 A,2 C2 | "G" G,2 D2 B,,2 D2 | "D" F2 A,2 F2 A,2 |
"Em" E2 G,2 E2 G,2 | "Am" A,2 C2 A,2 C2 | "F" F2 A,2 F2 A,2 | "G" G,2 D2 G,,2 z2 :|



X:1
T:Snowflake Serenade
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=160
K:Amaj
V:1 clef=treble
%% MIDI program 11
|:"A"e2c2 E2C2|"D"A4- A2f2|"Bm"d3c B2A2|"E"c4- c2B2|
"A"e2c2 E2C2|"F#m"A3G F2E2|"E" E4 e2c2|"A"A6 z2:|
V:2 clef=treble
%% MIDI program 71
|:"A"A2 | "A"e4 e4 | "D"f4 f4 | "Bm"d4 c4 | "E"c4 B2 z2 |
"A"e4 e2 c2 | "F#m"A2 F2 E2 z2 | "E"E2 E4 E2 :| "A"A4 z2 z2 :|
V:3 clef=bass
%% MIDI program 42
|:"A"C4 E4|"D"F4 A,4|"Bm"B,4 D4|"E"E4 G,4|
"A"C4 E4|"F#m"F4 A,4|"E" E4 E,4|"A"A,4 z4:|



X:1
T:Velocity Victory
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Em
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"Em"B4 E4 G4 F2|"D"E4 A4 c4 B2|"C"G,4 C4 E4 D2|"B"A,4 D4 F4 E2|
"Em"B2e2 d2B2 G4|"A"F2c2 B2A2 E4|"G"B2d2 c2G2 D4|"Am"E2A2 F2E2 C4|
"Em"B4 e4 g4 f2|"D"d4 a4 f4 e2|"C"g,4 c4 e4 d2|"B"a,4 d4 f4 e2|
"Em"e8 ^d8|"A"c8 B8|"G"g,8 a8|"B"B,8 A8:|
V:2 clef=treble name="Rhythm Synth" subname="Rhythm"
%%MIDI program 81
|:"Em"E8 z4 E4|"D" F8 z4 F4|"C"E8 z4 E4|"B"D8 z4 D4|
"Em"E4 E4 G4 G4|"A"F4 F4 A4 A4|"G"B4 B4 G4 G4|"Am"A4 A4 E4 E4|
"Em"E4 e4 g4 e4|"D"d4 f4 a4 f4|"C"e4 c4 e4 c4|"B"f4 d4 f4 d4|
"Em"E4 ^D4 E4 ^D4|"A"C4 B4 C4 B4|"G"G,4 A,4 G,4 A,4|"B"B,4 A,4 B,4 A,4:|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|:"Em"E,2B,2 G2E2 E4|"D"F,2A,2 D2F2 F4|"C"E,2G,2 C2E2 E4|"B" D,2F,2 B,2D2 D4|
"Em"E2 G2 F2 E2 E4|"A"A2 c2 B2 A2 A4|"G" G2 B2 A2 G2 G4|"Am"A2 E2 F2 E2 E4|
"Em"E2 B2 G2 E2 E4|"D"F2 A2 D2 F2 F4|"C" E2 G2 C2 E2 E4|"B" D2 F2 B2 D2 D4|
"Em"E1 z66|"A" z6 A16|"G"G,1 z66|"B"B,16 z6:|
V:4 clef=treble name="Electric Guitar" subname="Guitar"
%%MIDI program 30
|:"Em"B4 e2d2 B2G2|"D"F4 c2B2 F2A2|"C"G4 e2d2 G2E2|"B"A4 f2e2 A2D2|
"Em"e4 B2e2 d3B|"A"c4 F2c2 B3A|"G" B4 G2B2 d3G|"Am"A4 E2A2 F3E|
"Em"B4 e3d e4|"D"f4 c3B c4|"C"e4 G2e2 d3e|"B" f4 d2f2 e3f|
"Em"e8 ^d8|"A" c8 B8|"G" B,8 A8|"B"B,8 A8:|



X:1
T:Maelstrom of the Dark Tyrant
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Cm
% The introduction sets the stage for a dramatic confrontation
V:1 clef=treble name="Lead Guitar" subname="Gtr.1"
%%MIDI program 30
|:"Cm" e4 g4 b4 d'4 | "G7" d2c2 B2A2 G4 z4 | "Cm" e2^f2 g2a2 g4e4 | "Ab" a2_b2 a2g2 f4e4 |
"Cm" c'2b2 a2g2 f2e2 d2c2 | "Bb" b2a2 b2c2 d4 cB z2 | "Fm" c4 c4 e4 c4 | "G7" d2c2 B2A2 G4 z4 |
"Fm" a2_b2 c'4 a2_b2 z4 | "Cm" a4 ^g4 a4 f4 | "Bdim" e4 ^f4 e2c2 d4 | "G7" e4 d4 c8 :|
V:2 clef=treble name="Rhythm Guitar" subname="Gtr.2"
%%MIDI program 30
|:"Cm" G,8 C,8 | "G7" D,8 F,8 | "Cm" G,8 C8 | "Ab" E,8 A,8 |
"Cm" G,8 C8 | "Bb" F,8 B,8 | "Fm" C8 F8 | "G7" D,8 G,8 |
"Fm" A,8 C8 | "Cm" B,8 E8 | "Bdim" F,8 A,8 | "G7" G,8 C8 :|
V:3 clef=bass name="Bass Guitar" subname="Bass"
%%MIDI program 34
|:"Cm" C,8 C,8 | "G7" D,8 D,2 C,4 z2 | "Cm" C,4 E,4 G,2 C, z5 | "Ab" F,8 F,4 E,4 |
"Cm" G,4 G,4 C,2 C,2 z4 | "Bb" D,8 D,8 | "Fm" C,8 C,8 | "G7" G,16 |
"Fm" A,16 | "Cm" G,16 | "Bdim" F,16 | "G7" D,16 :|
V:4 clef=treble name="Keyboard" subname="Keys"
%%MIDI program 5
|:"Cm" e4 c'4 g4 e4 | "G7" d2c2 B2A2 G2F2 z4 | "Cm" e2^f2 g2a2 g4e4 | "Ab" c'2b2 c'2a2 g2f2 z4 |
"Cm" c'2b2 a2g2 f2e2 d2c2 | "Bb" d2c2 B2A2 G4 z4 | "Fm" c3b a3g f3e d3c | "G7" B2A2 G2F2 E4 z4 |
"Fm" f2e2 d2 c'4 a2_g2 z2 | "Cm" g2^f2 e4 c'2b2 z4 | "Bdim" c'4 d4 e4 f2d2 | "G7" g2f2 e8 z4 :|



X:1
T:Victory March
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Cmaj
V:1 clef=treble
%%MIDI program 60
|:"C"G4 E4|"G"D3E D2z2|"Am"E4 C4|"F"A3G F2z2|
"C"G4 "G7"G3 F|"C"E2C2 C4|"F"A2F2 "G"G3 F|"C"E4 C4:|
V:2 clef=treble
%%MIDI program 60
|:"C"c4 e4|"G"d3e d2z2|"Am"c4 a4|"F"c3B A4|
"C"c4 "G7"d2c2|"C"C2E2 C4|"F"F2A2 "G"e2d2|"C"c4 C4:|
V:3 clef=alto
%%MIDI program 42
|:"C"C2E2 G4|"G"B,2D2 G3 A|"Am"A,2C2 E4|"F"F2A2 c3 d|
"C"C2E2 G3 E|"G7"D2B,2 D4|"C"E2C2 E3 F|"G"D2B,2 D4:|
V:4 clef=bass
%%MIDI program 32
|:"C"C,2C,2 E,4|"G"G,2D2 D4|"Am"A,2E2 A,4|"F"F,2A,2 C4|
"C"C,2G,2 E,4|"G7"D,4 B,,4|"C"C2C,2 G,4|"G"G,2D2 G,4:|



X:1
T:Majestic Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Eb
V:1 clef=treble name="Brass section"
%%MIDI program 61
|:"Eb"B2G2 F3E D2C2|"Bb"B,6 F2 G2F2|"Eb"E2D2 C4 B,4|"Ab" C8- C4 z4|
"Cm"G,2G,2 A,2C2 E4|"Fm"F,4 G,2A2 B2c2|"Bb/d"d2B2 B4 A4 |"Eb"G4 (3=FED z4 C4:|
V:2 clef=treble name="String ensemble"
%%MIDI program 48
|:"Eb"G4 E4 G4 z4|"Bb"D4 F4 G4 z4|"Eb"E4 G4 C4 B,4|"Ab"C4 (3EFE C4 z4|
"Cm"G,4 A,2B,2 C4|"Fm"F4 G2 A2 B2c2|"Bb/d"d2G2 F2E2 D2C2|"Eb"E4 G2F2 E4:|
V:3 clef=alto name="Woodwind section"
%%MIDI program 73
|:"Eb"B6 B2 c2B2|"Bb"G4 F4 E4 D2|"Eb"C2B,2 B,4 C4|"Ab"E2F2 G2A2 B4|
"Cm"G2A2 B2C2 D4|"Fm"F2G2 A3G F2E2|"Bb/d"D2F2 G2A2 B4|"Eb"E6 B,2 C4:|
V:4 clef=bass name="Choir Aahs"
%%MIDI program 53
|:"Eb"B,,6 B,2 ^A,2B,2|"Bb"G,6 F,2 _E,2F,2|"Eb"B,4 G,4 A,4|"Ab"C4 =E4 F4 z4|
"Cm"G,2A,2 B,2C2 E4|"Fm"F,4 A,2=B,2 _E4|"Bb/d"D2D,2 F,4G,4|"Eb"B,,4 E,2F,2 E,4:|



X:1
T:AI's Muse
C:OrchestrAI
M:3/4
L:1/16
Q:1/4=120
K:Gmaj
% The piece features complex harmonic progressions and counterpoint
V:1 clef=treble name="Violin I" subname="Violin"
%%MIDI program 40
d8 BAGF |"G"G2A2 B4 d4 |"Am"c2e2 d6 B2 |"D7"A4 F2 G4 E2 |
"D"d2f2 a4 "C"g4 |"G"b2d'2 b2a2 "Em"g2f2 |"Am"e4 c2 "Bm"d4 c2 |
"G"B8 "D"AdFd |"G"G,2B,2 D4 G,4:|
V:2 clef=treble name="Violin II" subname="Violin"
%%MIDI program 40
B,8 D4 |"G" G4 A2 F4 D2 |"Am" A,4 C2 E4 C2 |"D7" D4 F2 A4 F2 |
"D" F2A2 d4 "C" c4 |"G" B4 G2 "Em" E4 C2 |"Am" A2c2 e2A2 "Bm" F4 |
"G" D6 F2 "D" A,4 |"G" G,4B,4 D4 :|
V:3 clef=bass name="Viola" subname="Viola"
%%MIDI program 41
B,4 D3 F z4 |"G" G6 A2 B4 |"Am" c6 A2 c2 e2 |"D7" d4 B2 e4 d2 |
"D" d3 f f4 a2 z2 |"C" c2 e2 "G" g2 B2 d4 |"Em" e2 G2 "Bm" b,2 d2 f4 |
"G" g4 e2 "D" f4 a2 |"G" B,4 G,4 D4 :|
V:4 clef=bass name="Cello" subname="Cello"
%%MIDI program 42
G,4 B,3 D z4 |"G" G,6 B,2 D4 |"Am" A,6 C2 E4 |"D7" D6 A,2 F4 |
"D" D,4 F3 A z4 |"C" C6 E2 G4 |"G" G,4 D2 "Em" E4 G2 |"Am" A,2F2 "Bm" B,4 D2 z2 |
"G" G,4B,4 "D" A,2F2 :|"G" G,2B,2 D2 z4 G,2:|



X:1
T:Bandstand Frolic
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:C
V:1 clef=treble
%%MIDI program 56
|:"C"c2G2 E2C2|"F"A2c2 c4|"G"B2d2 G2B2|"C"e2c2 A4|
"C"G6 AG|"Am"A2e2 e4|"Dm"F2A2 d2f2|"G"G2B2 G4|
"C"E2c2 C2E2|"F"A2f2 F4|"G"G2B2 d2g2|"C"C8:|
V:2 clef=treble
%%MIDI program 60
|:"C"e2c2 G2e2|"F"d2A2 F4|"G"B2G2 D2B,2|"C"C4-C2 z2|
"C"c2e2 g2e2|"Am"a2A2 ^G2A2|"Dm"f2A2 d3f|"G"g2d2 B2d2|
"C"c2G2 C2E2|"F"f2c2 A2f2|"G"g2B2 d3B|"C"c4-c2 z2:|
V:3 clef=bass
%%MIDI program 33
|:"C"C,2 C2 E2 G,2|"F"F,2 F2 A2 c2|"G"G,2 D2 B,2 G,2|"C"C4-C2 z2|
"C"G,2 E2 C2 G,2|"Am"A,2 A2 E2 A2|"Dm"D2 F2 A2 d2|"G"G2 B2 D2 G2|
"C"E2 G2 C3 E|"F"F2 A2 F2 A2|"G"G2 B2 G2 B2|"C"C8:|



X:1
T:Clash of Titans
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=170
K:Bm
V:1 clef=treble name="Brass" subname="Melody"
%%MIDI program 61
|:"Bm"B8 F8|"D"d8 F8|"A"A8 E8|"Em"G4 E4 F4 G4|
"Bm"B3c d4 z4 B4|"D"A3B c4 z4 A4|"A"A2F2 A2c2 z4 B4|"Em"E4 E4 F4 G4|
"F#m"F8 C8|"Bm"B8 d8|"A"c8 A8|"Em"e4 c4 d4 e4|
"F#m"F4 F4 E4 D4|"Bm"B,4 D4 F4 B,4|"G" G4 D4 G4 B4|"A"A8- A8:|
V:2 clef=treble name="Strings and Synth" subname="Harmony"
%%MIDI program 49
|:"Bm"B,8 F4 A4|"D"D8 F4 A4|"A"A,8 E4 A4|"Em"G,4 E4 A4 B4|
"Bm"B,8 D4 F4|"D"A,8 c4 e4|"A"A,8 F4 A4|"Em"E,8 e4 g4|
"F#m"F,8 C4 E4|"Bm"B,8 d4 f4|"A"c8 G4 B4|"Em"e8 g4 B4|
"F#m"F,8 A4 c4|"Bm"B,4 D4 F4 B4|"G"G,4 B,4 D4 G4|"A"A,4 E4 A4 E4:|
V:3 clef=bass name="Bass" subname="Rhythm"
%%MIDI program 43
|:"Bm" B,16 | "D" D16 | "A" A,16 | "Em" E16 |
"Bm"B,4 B,4 F4 F4 |"D" D4 D4 A4 A4 |"A" A,4 A,4 E4 E4 |"Em" E4 E4 G4 G4 |
"F#m"F,16 | "Bm" B,16 | "A" C16 | "Em" e16 |
"F#m"F,4 F,4 C4 C4 |"Bm" B,4 B,4 F4 F4 |"G" G,4 G,4 D4 D4 |"A" A,16:|



X:1
T:Mystic Journey's Prelude
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=88
K:Am
V:1 clef=treble
%%MIDI program 79
|:"Am"E2 c2 BA | "G"B2 A2 G2 | "F"AA G2 FE | "E"E4 z2 |
"Am"A2 E2 E2 | "Dm"F2 D2 EF | "Em"G2 E2 "Am"A2 | "G"BA G2 F2 |
"Am"E2 "C"cB "Bdim"B2 | "Esus4"A2 "Am"E4 | "Dm"D2 ^C2 D2 | "G"B2 A2 G2 |
"Am"A3 B cA | "F"d2 c2 B2 | "E7"G3 A GB | "Am"A4 z2:|
V:2 clef=treble
%%MIDI program 73
|:"Am"E2 A2 c2 | "G"d3 c BA | "F"A2 c2 de | "E"e4 z2 |
"Am"A2 c2 e2 | "Dm"f3 e dc | "Em"B2 g2 B2 | "G"A2 G2 F2 |
"Am"A2 cB "C"c2 | "Bdim"E2 "Esus4"A2 A2 | "Dm"f2 ed "G"dc | "G"B2 A2 G2 |
"Am"A2 A2 c2 | "F"B2 c2 d2 | "E7"e3 f ^g2 | "Am"A4 z2:|
V:3 clef=bass
%%MIDI program 70
|:"Am"A,4 E2 | "G"B,4 D2 | "F"C4 A,2 | "E"E,4 z2 |
"Am"A,,4 E,2 | "Dm"D,4 F,2 | "Em"E,4 G,2 | "G"G,4 F,2 |
"Am"A,,3 A, "C"C2 | "Bdim"B,,2 "Esus4"E,2 z2 | "Dm"D,4 D2 | "G"G,4 B,2 |
"Am"A,,2 C, "F"F,2 z | "E7"E,2 F,2 G,2 | "Am"A,4 z2 | "Am"A,4 z2:|



X:1
T:Neoclassical Rêverie
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=90
K:C
V:1 name="Piano" clef=treble
%%MIDI program 0
|:"C"E2 "G7"D2 G2 | "Am"C4 E2 | "Dm"F3 "G7"E D2 | "C"C6 |
E3 D C2 | "F"A4 G2 | "Em"G3 "A7"F E2 |1 "Dm"D6 :|2 "Dm"D[FA] |
|:"G7"B,4 G2 | "C"E4 G2 | "Am"C2 E "Am/G"G A2 | "F"A6 |
w: A' sec-tion
E2 "G7"D2 "C"G2 | G2 E4 | "F"A4 "E7"G2 | "Am"A3 G F2 |
"Dm"F3 "G7"E D2 | "C"C6 :|
w: A' sec-tion var-i-a-tion
|:"C"E2 "G7"D2 "C"G2 | "C"E3 "G"D C2 | "Am"A,2 C "F"E F2 | "G7"B,6 |
"C"E2 "G7"D2 "C"G2 | "C"E3 "G"D C2 | "Am"A,2 C "F"E F2 | "G7"B,3 G, A, B, |
"C"c4 e2 | "Em"g3 e "Dm"f2 | "G7"e3 d c2 |1 "C"c6 :|2 "C"c3 z3 |]
V:2 name="Violin" clef=treble
%%MIDI program 40
|:"C"c2 "G7"b2 "C"e2 | "Am"c'3 b a2 | "Dm"af "G7"ge d2 | "C"c3 b c'2 |
c'2 "G7"b2 "C"e2 | "F"f3 e "F7"d2 | "Em"e2 "A7"d^c "Dm"d2 |1 "Dm"f3 d "A7"c2 :|2 "Dm"f3 e "A7"d2 |
|:"G7"b,2 "C"c'b "C"ag | "C"c'2 "G"a2 "C"g2 | "Am"a2 "Am/G"gf "F7"ed | "F"A4 c2 |
"C"c2 B2 A2 | G2 E4 | "F"f4 d2 | "E7"g3 f e2 |
"Am"a2 "Dm"af "G7"g=e | "C"c'3 b "C"c'2 :|
|:"C"c2 "G7"b2 "C"e2 | "C"c'3 "G"b "C"c'2 | "Am"a2 "F"g2 "F"a2 | "G7"b6 |
"C"c2 "G7"b2 "C"e2 | "C"c'3 "G"b "C"c'2 | "Am"a2 "F"g2 "F"a2 | "G7"b4 "C"c2 |
"C"e2 "Em"g2 "Dm"f2 | "G7"e3 d c2 | "C"c3 z c2 | [K:"Dm"] d4 f2 |
"Am"a2 "Dm"f2 e2 | "C"c6 | "Am"a3 g f2 | "G7"b,3 a g2 | [K:"C"] "C"c6 |]
V:3 name="Viola" clef=alto
%%MIDI program 41
|:"C"G,4 C2 | "G7"F2 D2 G2 | "Am"A,2 E2 A2 | "Dm"D4 F2 |
G,4 C2 | "F"C2 A2 C2 | "Em"B,2 G2 B2 |1 "Dm"F2 D4 :|2 "Dm"F2 D2 F2 |
|:"G7"B,4 F2 | "C"C2 E2 G2 | "Am"A,2 C2 E2 | "F"C4 A,2 |
G,4 C2 | "F"C2 A2 C2 | "E7"B,2 G2 B2 | "Am"A, E2 A,2 z |
"Dm"D4 "G7"G,2 | "C"C6 :|
|:"C"G,4 C2 | "G7"F2 D2 G2 | "Am"A,4 F2 | "G7"B,2 D2 G2 |
"C"G,4 C2 | "F"C2 A2 C2 | "Em"B,2 G2 B2 | "G7"B,4 C2 |
"C"E2 "Em"G2 B,2 | "Dm"F E2 D2 z | "G7"G,3 F E2 |1 "C"C6 :|2 "C"C3 E2 G |]
V:4 name="Cello" clef=bass
%%MIDI program 42
|:"C"C,6 | "G7"D,6 | "Am"A,6 | "Dm"D,6 |
G,6 | "F"F,6 | "Em"E,6 |1 "Dm"D,6 :|2 "Dm"D,4 F,2 |
|:"G7"B,,6 | "C"C,6 | "Am"A,3 G, F, E, | "F"F,6 |
C,2 D,2 E,2 | "F"F,4 A,2 | "E7"G,4 B,2 | "Am"A,3 G, F, E, |
"Dm"D,3 F, G, A, | "C"C,6 :|
|:"C"C,4 G,2 | "G7"D,3 ^C, D,2 | "Am"A,2 E,2 A,2 | "G7"B,,,6 |
"C"C,4 G,2 | "F"F,3 E, D,2 | "Em"E,4 B,2 | "G7"B,,,6 |
"C"C,4 E,2 | "Dm"D,3 F, E,2 | "G7"G,,4 G,2 |1 "C"C,6 :|2 "C"C,4 G,2 |]



X:1
T:Ode to the Cosmos
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=50
K:C
V:1 clef=treble name="Violin Lead"
%%MIDI program 40
|:"Cmaj7" (3G,CE G,2 (3EGc | "Emin7" (3B,EG B,2 (3DGB | "Amin7" (3cEA c2 (3cAe | "D9/F#" (3F#AD A,C D2 z2 |
"Gsus4" (3G,B,D G,D G,B, z2 | "G" (3G,B,D G2 G,B, z2 | "Cmaj7" G,2 E2 G,2 | "Emin7/A" (3ABE A2 (3cEA |
"Dm9" F2 (3A,DF A,D F2 | "Gsus4" G,2 (3BDG B,D G,2 | "Cmaj7" E2 (3GCE (3GCE E2 z2 | "Fmaj9" F2 (3ACF A,C F2 |
"Emin11" E3 G B,2  | "Amin7" c2 (3cEA (3cEA c2 z2 | "D9/F#" A,3 F# A,2 # | "G" G,3 G, D,2  :|
V:2 clef=treble name="Viola Harmony"
%%MIDI program 41
|:"Cmaj7" E,3 G, C2 | "Emin7" G,2 B,2 E2 | "Amin7" C3 A,3 | "D9/F#" F3 D3 |
"Gsus4" B,3 D4  | "G" B,2 D2 G,2 | "Cmaj7" g,6 | "Emin7/A" e2 c3 B, |
"Dm9" A,6 | "Gsus4" D3 G,3 | "Cmaj7" G,3 E3 | "Fmaj9" c6 |
"Emin11" B,3 D3 | "Amin7" e2 c3 z | "D9/F#" D3 F3 | "G" B,3 D3 :|
V:3 clef=bass name="Cello Melody"
%%MIDI program 42
|:"Cmaj7" (3C,EG C2 (3CEG | "Emin7" (3B,EG B,2 (3BEG | "Amin7" E2 (3cEA c2 (3cAE z2 | "D9/F#" D3 A2 (3FDA z |
"G" D3 B,2 G, | "Emin7/A" A,3 (3B,EA E3 | "Dm9" D3 (3FAD F2 z | "Gsus4" G,2 (3DGB D3 z |
"Cmaj7" C2 G,2 E2 | "Fmaj9" F3 C2 A, | "Emin11" E3 B,2 G, | "Amin7" A,3 C2 (3EAC z |
"D9/F#" F3 (3ADF D2 z | "G" G,3 D2 B, :|
V:4 clef=bass name="Double Bass Pedal"
%%MIDI program 43
|:"Cmaj7" C,3,, C,3,, | "Emin7" B,,3 E,3, | "Amin7" A,,3 C,3, | "D9/F#" F,,2 D,2 F,,2 |
"G" G,3,, D,3, | "Cmaj7" C,2 G,,2 C,2, | "Dm9" D,3, F,3, | "Gsus4" G,2 D,2 G,2, |
"Cmaj7" C,2 E,,2 G,2 | "Fmaj9" F,3, C,3, | "Emin11" E,,2 G,,2 B,,2 | "Amin7" A,,3 E,3, |
"D9/F#" D,,3 F,,3 | "G" G,,2 D,,2 G,2 :|



X:1
T:Yearning Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Am
V:1 name="Trumpet" clef=treble
%%MIDI program 56
|:"Am"e4 c2B2|A4 z2 E2|"F" F4 A2G2|F4 z2 D2|
"Am"e4 c2d2|e4 z2 E2|"G"G4 B2A2|"E7" G4- G2z2|
"Am"A4 e2c2|"F" F4- F2A2|"C"G4 G2E2|"G"G4- G2B2|
"Am"A4 "F"F2E2|"E7"E4 E2C2|"Am"A6 z2-|A4 z2 z2:|
V:2 name="Trombone" clef=treble
%%MIDI program 57
|:"Am"A,4 E2A2|A,4 z2 E2|"F"F4 A2F2|F4 z2 F2|
"Am"A,4 E2c2|A,4 z2 A,2|"G"G4 B2d2|"E7"E4- E2 z2|
"Am"A,4 "C"E2G2|"F"F4- F2c2|"C"G,4 C2E2|"G"G4- G2B,
2|"Am"A,4 "F"F2E2|"E7"E4 E2G,2|"Am"A,6 z2-|A,4 z2 z2:|
V:3 name="Tuba" clef=bass
%%MIDI program 58
|:"Am"A,2 E2 A,2 E2|A,2 E2 A,2 E2|"F"F2 C2 F2 C2|F2 F2 F2 E2|
"Am"A,2 E2 A,2 E2|A,2 E2 A,2 E2|"G"G,2 B,2 D2 B,2|"E7"E2 E2 E2 E2|
"Am"A,2 "C"C2 E2 G2|"F"F2 A,2 C2 E2|"C"C2 E2 G2 C2|"G"G,2 B,2 D2 B,2|
"Am"A,2 "F"F2 A,2 C2|"E7"E2 G2 B,2 D2|"Am"A,6 z2-|A,4 z2 z2:|



X:1
T:Glory in Conflict
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=200
K:Gmin
% A combination of instruments to achieve a stirring battle soundtrack
V:1 clef=treble name="Brass" subname="Main Theme"
%%MIDI program 61
|:"Gm" G8-G4FB z2 | "D"D8-D4FD z2 | "Cm" C8-E4Gc z2 | "Bb/FG" d2B2G2F2E4D4 |
"Gm"G8-g8 | "D" a2f2 d8 z4 | "Cm"c8-c4Gc z2 | "Bb/FG" B8-B4=B2C2 |
"Eb" E8-G4^Gc z2 | "F" F8-A4Ac z2 |  "Gm"G4^F2G2d8 | "D"A2^F2A2D8 z2 |
"Gm"B2G2G2B2d8 | "Cm"G2E2G2c8 z2 | "Bb" F2D2F2B,8 z2 | "F/A"A8-A4F2G2:|
V:2 clef=treble name="Strings" subname="Support"
%%MIDI program 49
|:"Gm" d4B4G4d4 | "D"F4D4A4f4 | "Cm"G4E4C4g4 | "Bb/FG"B4G4F4B4 |
"Gm"G4B4d8 | "D"A4d4f8 | "Cm"c4ecg8 z2 | "Bb/FG" B4=B2B4D6 |
"Eb"G4g4e8 | "F"A4a4c8 | "Gm"G4G4d8 | "D"d4d4A8 |
"Gm"B4B4d8 | "Cm"G4G4C8 | "Bb" F4F4B,8 | "F/A"A4A4F8:|
V:3 clef=bass name="Low Brass and Woods" subname="Bass"
%%MIDI program 58
|:"Gm" G,8-G,4B,2D2 | "D"D,8-A,4d4 | "Cm"C,8-e4g4 | "Bb/FG" B,8-d4g4 |
"Gm"G,8-g8 | "D" f8-a4f4 | "Cm"c8-C4E2G2 | "Bb/FG" B,8-=B,4=B,2D2 |
"Eb" E,8-E4G4 | "F" F,8-F4A4 |  "Gm"G,8-G4B4 | "D"A,8-A,4D4 |
"Gm"B,8-B4d4 | "Cm"G,8-G,4C4 | "Bb" F,8-F4B,4 | "F/A"A,8-A,4=AC3 :|
V:4 clef=bass name="Percussion"
%%MIDI program 47
|:"Gm" [G,B,DG]8 z8 z7 | "D"[D,AFD]8 z8 z7 | "Cm"[G,C,EG]8 z8 z7 | "Bb/FG"[G,,BD]8 z8 z7 |
"Gm"G,8 z4[G,B,D]4 | "D"D,8 z4[A,DF]4 | "Cm"C,8 z4[G,CE]4 | "Bb/FG"B,,8 z4[B,FG]4 |
"Eb"E,8 z4[E,G,^G,]4 | "F"F,8 z4[F,AC]4 |  "Gm"[G,B,DG]8 z8 z7 | "D"[D,AFD]8 z8 z7 |
"Gm"[G,B,DG]8 z8 z7 | "Cm"[G,C,EG]8 z8 z7 | "Bb"[F,B,DF]8 z8 z7 | "F/A"[C,A,EF]8 z8 z7 :|



X:1
T:Manifold Derby
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=116
K:Gmaj
V:1 name="Lead Melody" clef=treble
%%MIDI program 71
|: "G" dBG BAG | "C" ECE GEC | "D" DFA AFD | "G" G3 - G2E |
"G" dBG BAG | "C" cBc ece | "D" dBe edB | "G" G3 - G2z :|
V:2 name="Harmony" clef=treble
%%MIDI program 68
|: "G" B2G GAG | "C" E2C CEC | "D" F2D DFD | "G" G3 - G2B |
"G" d2B GAG | "C" E2c cec | "D" F2e efd | "G" G3 - G2D :|
V:3 name="Bass Line" clef=bass
%%MIDI program 32
|: "G" G,2D, G,2B, | "C" C,2G, C2E | "D" D,2A, A,2F | "G" G,3 - G,2D, |
"G" G,2D, G,2B, | "C" C,2G, E2C | "D" D,2F A2d | "G" G,3 - G,2B, :|
V:4 name="Rhythmic Drive" clef=perc
%%MIDI program 12
|: "G"d2d d2d | "C"c2c c2c | "D"B2B B2B | "G"G2G G2G |
"G"d2d d2d | "C"c2c c2c | "D"B2B B2B | "G"G2G G2G :|



X:1
T:Composition Quest
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Dmaj
% The piece opens with a modern synth lead, then transitions to a full orchestral sound
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|: "D" d2 F2 A2 d2 | "Bm" B2 d2 f2 B2 | "G" G2 B2 e2 G2 | "A" A2 ^c2 e2 A2 |
"D" d2 F2 A2 d2 | "Bm" B2 d2 f2 B2 | "G" G2 B2 e2 G2 | "A" A2 ^c2 e2 A2 :|
V:2 clef=treble name="Violin I"
%%MIDI program 40
|: "D" f4 A4 | "Bm" B4 F4 | "G" e4 d4 | "A" E4 A4 |
"D" f4 A4 | "Bm" B4 F4 | "G" e4 d4 | "A" E4 A4 :|
V:3 clef=treble name="Violin II"
%%MIDI program 40
|: "D" A4 d4 | "Bm" f4 B4 | "G" G4 B4 | "A" ^c4 e4 |
"D" A4 d4 | "Bm" f4 B4 | "G" G4 B4 | "A" ^c4 e4 :|
V:4 clef=bass name="Cello & Bass"
%%MIDI program 42
|: "D" D,8 | "Bm" F,8 | "G" G,8 | "A" E,8 |
"D" D,8 | "Bm" F,8 | "G" G,8 | "A" E,8 :|
% The composition transitions to a grand finale with powerful brass and timpani
V:5 clef=treble name="Brass"
%%MIDI program 61
|: "D" d2 f2 a2 d2 | "Bm" b2 d2 f2 b2 | "G" g2 b2 e2 g2 | "A" a2 ^c2 e2 a2 |
"D" d2 f2 a2 d2 | "Bm" b2 d2 f2 b2 | "G" g2 b2 e2 g2 | "A" a2 ^c2 e2 a2 :|
V:6 clef=treble-8 name="Timpani"
%%MIDI program 47
|: "D" D,2 D,2 D,2 D,2 | "Bm" F,2 F,2 F,2 F,2 | "G" G,2 G,2 G,2 G,2 | "A" E,2 E,2 E,2 E,2 |
"D" D,2 D,2 D,2 D,2 | "Bm" F,2 F,2 F,2 F,2 | "G" G,2 G,2 G,2 G,2 | "A" E,2 E,2 E,2 E,2 :|



X:1
T:The Underdog's Rally
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Bm
% Beginning with tension indicating the underdog status
V:1 clef=treble name="Brass" subname="Horns"
%%MIDI program 61
|:"Bm" B2 A2 z4 | G4 F2G2 | "A" A2F2 z4 | E4 D2E2 | "G" G2F2 z4 | E6 z2 | "F#m" F2E2 z4 | D4 C2D2 |
"Bm" B4 e2 z2 | d4 c2B2 | "A" A2F2 z4 | E4 z2 z2 | "G" G2F2 z4 | E6 z2 | "D" D2E2 z4 | F4 z4 :|
% The comeback begins
|:"D" F4 A2 z2 | "A" c6 F2 | "Bm" B2 A2 z4 | G4 F2E2 | "E" E2F2 z4 | G4 A4 | "Bm" f6 e2 | d4 c2B2 |
"D" F4 A2 z2 | "G" B4 G4 | "A" A4 c2 z2 | E6 z2 | "Bm" B2 A2 z4 | G4 A2 z2 | "D" F6 z2 | "E" E4 z4 :|
% The ensuring victory
V:2 clef=treble name="Strings" subname="Vlns."
%%MIDI program 48
|:"Bm" d2 c2 z4 | B4 A2d2 | "A" c2A2 z4 | F4 E2F2 | "G" G2F2 z4 | D6 z2 | "F#m" F2E2 z4 | C4 B,2C2 |
"Bm" d4 f2 z2 | a4 g2f2 | "A" c2A2 z4 | F4 z2 z2 | "G" G2F2 z4 | D6 z2 | "D" C2 D2 z4 | E4 z4 :|
|:"D" E4 c2 z2 | "A" f6 E2 | "Bm" d2 c2 z4 | B4 A2G2 | "E" E2F2 z4 | G4 c4 | "Bm" b6 a2 | g4 f2e2 |
"D" E4 c2 z2 | "G" d4 B4 | "A" A4 f2 z2 | F6 z2 | "Bm" d2 c2 z4 | B4 c2 z2 | "D" E6 z2 | "E" G,4 z4 :|
% Bass voices provide a foundation
V:3 clef=bass name="Tubas" subname="Tuba"
%%MIDI program 58
|:"Bm" B,2 D2 z4 | G,4 F,2G,2 | "A" A,2E2 z4 | D4 C2D2 | "G" G,2F,2 z4 | D6 z2 | "F#m" F,2E,2 z4 | C4 B,2C,2 |
"Bm" B,4 e2 z2 | d4 c2B,2 | "A" A,2F,2 z4 | E4 z2 z2 | "G" G,2F,2 z4 | D6 z2 | "D" C4 z4 | E2D2 B,4 :|
|:"D" F,4 A,2 z2 | "A" c4 F,4 | "Bm" B,2 A,2 z4 | G,4 F,2E,2 | "E" E2F,2 z4 | G,4 A,4 | "Bm" f4 e2 z2 | d4 c2B,2 |
"D" F,4 A,2 z2 | "G" B,4 G,4 | "A" A,4 c2 z2 | E6 z2 | "Bm" B,2 A,2 z4 | G,4 A,2 z2 | "D" F,6 z2 | "E" E,4 z4 :|



X:1
T:Trombone Trio Frolic
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Gm
V:1 clef=bass name="Trombone 1" %%MIDI program 57
|:"Gm"G2G2 B,G | D2D2 G,D | "D7"F2E2 D2 | "Gm"B,2G2 G2 :|
|"Bb"D2F2 Bb | F2F2 D2 | "Eb"Eb2Eb3  | "Bb"C2Bb4  :|
|"Gm"G2G2 B,G | D2D2 G,D | "D7"F2E2 D2 | "Gm"G2G2 G2 :|
V:2 clef=bass name="Trombone 2" %%MIDI program 57
|:"Gm"D2D2 G,B, | G2G2 D2 | "D7"A2A2 F2 | "Gm"D2D2 D2 :|
|"Bb"Bb2Bb3  | D2D2 Bb | "Eb"Bb2Bb3  | "Bb"F2F2 F2 :|
|"Gm"D2D2 G,B, | G2G2 D2 | "D7"A2A2 F2 | "Gm"D2B,G G2 :|
V:3 clef=bass name="Trombone 3" %%MIDI program 57
|:"Gm"G,2G,2 D,2 | B,,2B,,2 G,2 | "D7"D,2C2 B,,2 | "Gm"G,2G,2 G,2 :|
|"Bb"F,2F,2 Bb, | D,2D,2 F,2 | "Eb"Bb,,2Bb,,3  | "Bb"F,2F,2 F,2 :|
|"Gm"G,2G,2 D,2 | B,,2B,,2 G,2 | "D7"D,2C2 B,,2 |"Gm" G,2F,E D,2 :|



X:1
T:Island Tension Prelude
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Cmin
V:1 clef=treble name="Strings" subname="Str."
%%MIDI program 48
|:"Cm"G2 ^F2 G4 | "Eb" B,6 z2 | "G"-G2 G2 A2 ^F2 | "Cm"G4 z2 z2 |
"Cm"e3 d e2 z2 | "Bb/G"A2 G2 ^F2 z2 | "Cm"G2 =F2 D4 | "G"s4s2 z2 :|
V:2 clef=treble name="Brass" subname="Ens."
%%MIDI program 61
|:"Cm"c2 B2 c4 | "Eb"g2 e2 d2 c2 | "G"B2 A2 G2 =F2 | "Cm"c3 d c2 z2 |
"Cm"G2 ^F2 G2 A2 | "Bb/G"-B2 A2 G2 F2 | "Cm"c2 B2 "G7"G4 | "Cm"s4s2 z2 :|
V:3 clef=bass name="Bass" subname="Bass"
%%MIDI program 33
|:"Cm"C,2 z2 C,4 | "Eb"E,2 z2 E,4 | "G"D,2 z2 B,,4 | "Cm"C,4 z2 z2 |
"Cm"C,2 C,2 B,,2 B,,2 | "Bb/G"G,2 G,2 F,4 | "Cm"C,4 z2 E,2 | "G"s4s2 z2 :|



X:1
T:Bright Pop Bliss Enhanced
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:G
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|:"D"D2D2 E2F2 | "G"G2A2 B2d2 | "C"e2c2 A2G2 | "D"A2F2 G4 |
"D"D2F2 A2d2 | "G"B2d2 g2e2 | "C"c2E2 G2c2 | "G"B2G2 A4 :|
V:2 clef=treble name="Electric Guitar"
%%MIDI program 30
|:"D"A2F2 D2F2 | "G"B2G2 E2G2 | "C"c2E2 C2E2 | "G"D2B,2 G,2B,2 |
"D"F2A2 d2f2 | "G"G2B2 e2g2 | "C"E2G2 c2e2 | "G"B,2D2 G4 :|
V:3 clef=bass name="Electric Bass"
%%MIDI program 33
|:"D" D4 A,4 | "G" G,4 D,4 | "C" C4 E,4 | "G" G,4 D,4 |
"D" D4 A,4 | "G" G,4 D,4 | "C" C4 E,4 | "G" G,4 D,4 :|
V:4 clef=treble name="Synth Chords"
%%MIDI program 82
|:"D"[DFA]2 [DFA]2 | "G"[GBd]2 [GBd]2 | "C"[CEG]2 [CEG]2 | "G"[GBd]2 [GBd]2 |
"D"[DFA]2 [DFA]2 | "G"[GBd]2 [GBd]2 | "C"[CEG]2 [CEG]2 | "G"[GBd]2 [GBd]2 :|



X:1
T:The Seeker's Eureka
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Am
% The introduction signals the start of the adventure and quest for discovery
V:1 clef=treble
%%MIDI program 1
|:"Am"A2 | e3f e2c2 | "F"d4 c2A2 | "G"B4 cBAG | "E7"A6 :|
% The motive represents the path of discovery with ascending movements
|"Am"A2e2 "F"f2d2 | "C"e2c2 "E7"B2G2 | "Am"a4 g2e2 | "F"f6 e2 |
|"Dm"d2f2 "G"g2B2 | "C"e2G2 "E7"A2c2 | "Am"a3g "F"a2g2 | "E7"e4 z2 A2 |
% The B section simulates the moment of discovery with rhythmical intensity.
|"Am"e2^c2 d2B2 | "Dm"g2a2 b2a2 | "G"g2B2 c2d2 | "E7"B6 z2 |
|"Am"a2e2 "F"c2A2 | "C"G2E2 "E7"D2C2 | "Am"A4 G2E2 | "F"F6 z2 |
|"Dm"d2c2 "E"e2d2 | "Am"c2B2 A2G2 | "E7"F4 G2E2 | "Am"A8:|
% The final section reflects the joy and energy of a successful discovery
V:2 clef=treble
%%MIDI program 73
|:"Am"A2 | c'4 c3B | "F"A4 G2F2 | "G"B4 d2G2 | "E7"E4 c2A2 |
"Am"A2 e2 "F"d2A2 | "C"c2G2 "E7"B2E2 | "Am"A4 a2e2 | "F"f4 d2c2 |
"Dm"d2A2 "G"G2B2 | "C"c2E2 "E7"G2B2 | "Am"a4 g2e2 | "E7"e4 c2B2 |
% This passage uses syncopation and dotted rhythms to enhance excitement
|"Am"c'4 b2a2 | "Dm"g2a2 b2g2 | "G"B2c2 d2G2 | "E7"E4 E2 z2 |
"Am"A4 G2E2 | "F"F2A2 c2d2 | "C"E4 c2E2 | "F"D6 z2 |
"Dm"D2d2 "E"E2e2 | "Am"c'2b2 a4 | "E7"B4 G2F2 | "Am"A8:|
% The bass voice (V:3) will provide a foundation with arpeggios and pedal tones.
V:3 clef=bass
%%MIDI program 32
|:"Am"A,2 | E2 A2 C4 | "F"F,2 C2 A4 | "G"G,2 B,2 D4 | "E7"E4 z2 A,2 |
"Am"A,2 E2 "F"F2 C2 | "C"C2 E2 "E7"G2 B,2 | "Am"A4 a2 e2 | "F"F4 z2 c2 |
"Dm"D2 A,2 "G"G2 D2 | "C"C2 E2 "E7"B,2 G2 | "Am"A4 e2 A2 | "E7"E4 z2 A,2 |
% The bass joins the thrill with driving rhythms and harmonic support.
|"Am"A,2 C2 E2 | "Dm"D4 F2 A2 | "G"G,2 D2 B,2 | "E7"E4 z2 E2 |
"Am"A4 E2 C2 | "F"F4 C2 A,2 | "C"C2 E2 G2 | "F"F6 z2 |
"Dm"D2 A,2 D2 | "E"E4 c2 G2 | "Am"A,4 E2 C2 | "E7"E8:|




X:1
T:The Labyrinth of Shadows
C:OrchestrAI
M:4/4
L:1/16
Q:Lento
K:Cm
V:1 clef=treble
%% MIDI program 1
|:"Cm"(c4 e4 g4) c'4|z2(f4 g2) a4|(g4 f4 e2. d4|c4)_b2 a4 g2|
"Ab"(a2^g2 a4 c'4) e4|z2(f4 e2) f4|(g2 a4) _b2 g4|(f4 e4 d2. c4)|
"G7"(B4 d4 g4) b4|(z4 f4 e2.)(f4|g4 a4)_b4 a4|(g4 f4 e2)(d4 c2)|
:"Cm"z2(c4=B2 c4)|(e4 g4 a2 g4)|(f4=Bd) d4 c2 z2|z2(d4 _e2.)(d4 c2)|
"Ab"z2(A2^G2 A4 c'4)|e2. f2 e2. _b4|(c'2 e4 d2. c4)|(b4 a4 g2.)(f4 e2)|
"G7"(d4 d'c'2 b4) agfe|f8- f2 ed ^c2|(d8- d4) _e2 d'=b2|(c'4 _b2 a4 g2.) z4|"Cm"c8- c2 z4 z4:|



X:1
T:Whimsical Encounters
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:G
V:1 clef=treble name="Flute"
%%MIDI program 73
|:[G]"G" B2 B2 G | "Am" A2 ^c2 e2 | "D7" d2 z2 F2 | "G" G4 z2 |
"C" e2 g2 e2 | "D7" [fd][fd] z2 [fa][fa] | "Gm" g2 "Cm"e2 "D" ^f2 | "G" G4 z2 :|
V:2 clef=treble name="Violin"
%%MIDI program 40
M:4/4
|:"D" F2A2 d2c2 | "A7" e4 e2d2 | "G" G,B,DG "C" EcGE | "Am" A4 z2 A2 |
"D7" F2DD "G" G2BB | "C" c2e2 g4 | "A7" e2c2 "D" d2F2 | "G" G4 z4 :|
V:3 clef=treble name="Synth Lead"
%%MIDI program 81
M:5/4
|:"Am" c4 e4 a2 | "C" g4 e2 "E7" e2 | "Am" a2 c'2 "Dm" e2 f2 | "E7" g4 ^g2 a2 |
"G" b4 ^d4 g2 | "Cm" e4 ^f2 g2 | "D" a4 f2 "G" g2 | "C" c2 "B7" B2 "Em" E4 :|
V:4 clef=bass name="Synth Bass"
%%MIDI program 38
M:7/8
|:"Em" [G,2B,2] [A,2E2] [G,2B,2] | "C" [C,2E2] G,2 "Am" [A,2C2] | "Bm" [B,2D2] [F,2A,2] "F#7" [F,2A,2] | "Em" [E,2G,2] z [B,2G,2] |
"D" [D,2F,2] A,2 "G" [G,2B,2] | "C" [C,2E2] G,2 "A7" [A,2C2] | "G" [G,2B,2] "D" [D,2F,2] "Em" [E,2G,2] | "C" [C,2E2] "B7" [B,2D2] "Em" [E,2G,2] :|



X:1
T:Craig Newmark J-School Intro
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:Cmaj
V:1
%%MIDI program 79
% The theme is designed to be bright and forward-looking
|: "C" E4 G4 | "F" A4 c4 | "G" B4 d4 | "Am" e4 e2 z2 |
% Synth arpeggios represent the digital and academic feeling
"C" c2G2 E2C2 | "F" A2c2 F2A2 | "G" B2d2 G2B2 | "Am" e8 :|
V:2
%%MIDI program 80
% Lower voice with a calm rhythm to create a sense of gravitas
|: "C" C,4 E,4 | "F" F,4 A,4 | "G" G,2B,2 D4 | "Am" A,6 z2 |
% A supportive accompaniment that provides a stable foundation
"C" C4 E2C2 | "F" c4 A2F2 | "G" b2 d4 z2 | "Am" a4 z4 :|



X:1
T:The Clockwork Carnival
C:OrchestrAI
M:6/8
L:1/8
Q:1/4=120
K:G
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "G" G3 B2d | "Em" e2d B2G | "Am" A2B c2d | "D" d3 D3 |
"G" G2A B2c | "Em" d2e g2e | "Am" c2A "D" F2A | "G" G3 G3 :|
|: "C" e2f g2a | "G" b2a g2d | "D" f2e d2c | "G" B3 G3 |
"C" e2d c2B | "G" d2c B2A | "Am" c2B "D" A2F | "G" G3 G3 :|
V:2 clef=treble name="Oboe"
%%MIDI program 68
|: "G" D3 G2B | "Em" B2A G2E | "Am" F2G A2B | "D" A3 F3 |
"G" B,2D G2A | "Em" B2c e2c | "Am" A2F "D" D2F | "G" D3 B,3 :|
|: "C" G2A B2c | "G" d2c B2G | "D" A2G F2A | "G" G3 D3 |
"C" G2F E2D | "G" B2A G2F | "Am" E2D "D" C2A, | "G" B,3 G,3 :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "G" G,3 B,2D | "Em" E2D B,2G, | "Am" A,2B, C2D | "D" D3 F,3 |
"G" G,2A, B,2C | "Em" D2E G2E | "Am" A,2F, "D" D,2F, | "G" G,3 G,,3 :|
|: "C" C2D E2F | "G" G2F D2B, | "D" D2C A,2F, | "G" G,3 B,,3 |
"C" C2B, A,2G, | "G" B,2A, G,2F, | "Am" E,2D, "D" C,2A,, | "G" G,,3 G,3 :|
V:4 clef=bass name="Bass"
%%MIDI program 32
|: "G" G,,3 G,3 | "Em" E,,3 E,3 | "Am" A,,3 A,3 | "D" D,,3 D,3 |
"G" G,,3 G,3 | "Em" E,,3 E,3 | "Am" A,,3 "D" D,,3 | "G" G,,3 G,3 :|
|: "C" C,,3 C,3 | "G" G,,3 G,3 | "D" D,,3 D,3 | "G" G,,3 G,3 |
"C" C,,3 C,3 | "G" G,,3 G,3 | "Am" A,,3 "D" D,,3 | "G" G,,3 G,3 :|



X:1
T:Journey Through Realms
C:OrchestrAI
K:Cmaj
M:C
L:1/8
Q:1/4=80
V:1 clef=treble name="Piano" subname="Pno."
%%MIDI program 1
|: "C"E2 G4 z2 | "Am"A2 c4 z2 | "F"F2 A4 z2 | "G"G2 B4 z2 |
"C"C2 E4 G2 | "Dm"F4 A2 z2 | "Em"G2 B4 z2 |1 "F"F6 z2 :|2 "F"F8 ||
"Am"E4 C4 | "Em"G4 E4 | "F"A4 c4 | "C"G4 E4 |
"Dm"F4 D4 | "G"G4 B4 | "Am"A6 G2 | "Em"E8 |
"Am"A4 "E"e4 | "Dm"f4 "G"d4 | "C"e4 "A7"c4 | "Dm"d4 "E7"b4 |
"C"c6 B2 | "G"B4 A4 | "F"A8 |
K:Am
M:2/2
Q:1/4=120
V:1
"A"A2A2 "E"e2e2 | "Dm"d4 "C"e2f2 | "F"f3 a g2f2 | "E7"e4 z4 |
"A"a2 ab a2c'b | "Dm"a2 gf g4 | "F"f2 fg a2f2 | "E7"e2e2 e4 |
"A"c'2c'a b2ag | "E"e2ef g4 | "Dm"f2 fd e4 | "C"c2c2 c4 |
K:Cmaj
M:C
Q:1/4=80
V:1
|: "C"e2 "G"d4 c2 | "Am"a4 g4 | "F"f3 g a2f2 | "G"g4 z4 :|
V:2 clef=treble name="Strings" subname="Str."
%%MIDI program 48
|: "C"G,4 E4  | "Am"C4 A4  | "F"A,4 F4  | "G"B,4 G4  |
"C"C4 E4 "m" | "G"B4 D4 "m" | "Em"E4 G4 "" |1 "F"C8 :|2 "F"C8 ||
"Am"A4 E4 | "Em"B4 G4 | "F"C8 | "C"E8 |
"Dm"D8 | "G"G8 | "Am"A8 | "Em"E8 |
"Am"A8 | "E"E8 | "Dm"D8 | "G"G8 |
"C"C8 | "G"B,8 | "F"C8 |
K:Am
M:2/2
Q:1/4=120
V:2
"A"A,4 A,4 | "E"B,4 B,4 | "Dm"C4 C4 | "E7"E4 G,4 |
"A"A,8 | "Dm"D8 | "F"F8 | "E7"E8 |
"A"A,4 A,4 | "Dm"D4 D4 | "F"F4 F4 | "E7"E4 E4 |
K:Cmaj
M:C
Q:1/4=80
V:2
|: "C"C4 G,4 | "Am"A4 E4 | "F"F4 C4 | "G"G4 D4 :|
V:3 clef=treble name="Oboe" subname="Ob."
%%MIDI program 68
|: "C"zz e2 G2 z2 | "Am"zz A2 c2 z2 | "F"zz F2 A2 z2 | "G"zz G2 B2 z2 |
"C"zz C2 E2 z2 | "Dm"zz F2 A2 z2 | "Em"zz E2 G2 z2 |1 "F"zz F4 z2 :|2 "F"zz F4 z2 ||
"Am"e4 c4 | "Em"g4 e4 | "F"a4 c4 | "C"g4 e4 |
"Dm"f4 d4 | "G"g4 b4 | "Am"a6 G2 | "Em"e8 |
"Am"a4 e4 | "Dm"f4 d4 | "C"e4 c4 | "Dm"d4 b4 |
"C"c6 B2 | "G"B4 A4 | "F"A8 |
K:Am
M:2/2
Q:1/4=120
V:3
"A"A2A2 e2e2 | "Dm"d4 e2f2 | "F"fa3 g2f2 | "E7"e4 z4 |
"A"a2 ab ac'b z | "Dm"a2 gf g4 | "F"f2 fg af2 z | "E7"e2e2 e4 |
"A"c'a2 c'b b2a | "E"e2e2 g4 | "Dm"f2f2 e4 | "C"c'c'c'c'd'd'd'd' |
K:Cmaj
M:C
Q:1/4=80
V:3
|: "C"e2 d4 c2 | "Am"a4 g4 | "F"f2g a2 f2 z | "G"g4 z4 :|



X:1
T:Harmonized Horizons
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Gmaj
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|:"G"B4 c3 B|"C" A6 G2|"Em7"B4 c3 B|"D" A6 G2|
"G"B4 d3 c|"C" B6 A2|"Am7"B4 d3 c|"D" G6 F2|
"G"B4 c3 B|"C" A6 G2|"Em7"B4 c3 B|"D" A6 G2|
"G"G3 A B4 | "Em"G3 F E4 | "Am7"A3 G F3 E | "D7"D6 z2:|
V:2 clef=treble name="Flute"
%%MIDI program 73
|:"G"B2 B2 B2 G2|"C"E2 E2 E2 A2|"Em7"G2 G2 A2 B2|"D"A4 z4|
"G"B2 d2 d2 B2|"C"A2 c2 c2 A2|"Am7"B2 c2 B2 A2|"D" G4 F4|
"G"B2 B2 B2 G2|"C"E2 E2 E2 A2|"Em7"G2 G2 A2 B2|"D"A4 z4|
"G"G2 A2 B2 G2 | "Em"G2 F2 E2 G2 | "Am7"A2 G2 F2 E2 | "D7"D4 z4 :|
V:3 clef=treble name="Synth Pad"
%%MIDI program 88
|:"G"G,4 B,4|"C"C4 E4|"Em7"G,4 A,4|"D" F4 A4|
"G"G,4 B,4|"C"C4 E4|"Am7"A,4 C4|"D" D4 F4|
"G"G,4 B,4|"C"C4 E4|"Em7"G,4 A,4|"D" F4 A4|
"G"G,4 B,4|"Em"E4 G4|"Am7"A,4 C4|"D7" F4 A4:|
V:4 clef=bass name="Acoustic Bass"
%%MIDI program 32
|:"G"D,4 G,2 A,2|"C"C,3 E, G,2 A,2|"Em7"B,,4 E,4|"D"A,4 D4|
"G"G,4 D,4|"C"C,4 E,4|"Am7"A,4 E,4|"D" D,4 A,4|
"G"G,4 D,3 E,|"C"C,4 E,4|"Em7"B,4 E,3 F,|"D" A,6 G,2|
"G"G,2 A,2 B,2 G,2|"Em"E,4 G,4|"Am7"A,2 C,2 E,2 A,2|"D7"D,4 A,4 :|



X:1
T:Boss Battle Blitz
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=160
K:Cm
% The composition begins with a sense of looming danger and rapidly escalates into a full-throttle confrontation
V:1 clef=treble name="Synth Brass" subname="Lead"
%%MIDI program 62
|: "Cm"c4 e4 g4 g8 | "Bdim"b4 d4 f4 f8 | "G" B4 d4 g4 g8 | "Cm" c8 e4 g4 z4 |
"Cm"e4 g4 c'4 c'8 | "Fm"d4 Ab4 c'4 c'7 | "G7"B4 D4 F4 G8 | "Cm"c4 c4 e4 g4 z4 :|
V:2 clef=treble name="Synth Lead" subname="Harmony"
%%MIDI program 81
|: "Cm"G,4 C4 E4 E8 | "Bdim"F,4 A,4 C4 C8 | "G"D4 F4 A,4 A,8 | "Cm"G,8 C4 E4 z4 |
"Cm"G,4 C4 G,4 G,8 | "Fm"F,4 Ab4 c4 c'7 | "G7"D4 F4 B,4 D8 | "Cm"G,4 G,4 C4 E4 z4 :|
V:3 clef=bass name="Electric Bass" subname="Bass"
%%MIDI program 33
|: "Cm"C,4 F,4 G,,4 G,,8 | "Bdim"B,,4 E,4 F,,4 F,,8 | "G"D,4 G,4 A,,4 A,,8 | "Cm"C,4 G,,4 C,4 C4 z4 |
"Cm"C,4 F,4 G,,4 G,,8 | "Fm"F,4 C,4 Db,4 Db,6 | "G7"B,,4 D,4 G,,4 G,,8 | "Cm"C,4 C,4 G,,4 G,,4 z4 :|
V:4 clef=bass name="Synth Bass" subname="Pulse"
%%MIDI program 88
|: "Cm"z8 c8 z4 | "Bdim" z8 b8 z4 | "G" z8 g8 z4 | "Cm" z8 c'8 z4 |
"Cm"z8 c8 z4 | "Fm" z8 f8 z4 | "G7" z8 b,8 z4 | "Cm" z8 c'8 z4 :|



X:1
T:The Thrill of Discovery
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Am
%%staves [V1 V2]
V:1
%%MIDI program 1
|: "Am" e2^c2 a4 | "G" b2d'2 g4 | "F" c2A2 c4 | "E7" ^G2B2 A4 |
"Am" e2^c2 "Dm" d2f2 | "Am" a3g "G" f2e2 | "F" c2A2 "E7" B2^G2 | "Am" a8 :|
V:2
%%MIDI program 70
|: "Am" A,2E2 A,4 | "G" B,2G2 B,4 | "F" F,2C2 F,4 | "E7" ^G,2B,2 A,4 |
"Am" A,2E2 "Dm" D,2F2 | "Am" A,3G, "G" F,2E,2 | "F" F,2C2 "E7" ^G,2B,2 | "Am" A,8 :|
% Development section with a theme variation and modulation
V:1
|: "Dm" d2^c2 "G" B2d2 | "C" g2e2 "F" c'2a2 | "Bb" ^f2^d2 "E7" ^g2e2 | "Am" a6 z2 |
"Dm" f4 f2d2 | "C" e4 e2^c2 | "Bb" d4 d2^f2 | "E7" e6 e2 |
"Am" e2^c2 "Dm" d2f2 | "Am" a3g "G" f2e2 | "F" c2A2 "E7" B2^G2 | "Am" a8 :|
V:2
|: "Dm" D,2^C2 "G" B,2D2 | "C" G,2E2 "F" C2A,2 | "Bb" ^F2^D2 "E7" ^G,2E2 | "Am" A,6 z2 |
"Dm" F4 F2D2 | "C" E4 E2^C2 | "Bb" D4 D2^F2 | "E7" E,6 E,2 |
"Am" A,2E2 "Dm" D,2F2 | "Am" A,3G, "G" F,2E,2 | "F" F,2C2 "E7" ^G,2B,2 | "Am" A,8 :|
% Climactic resolution and return to the main theme
V:1
%%MIDI program 1
|: "Em" B2^G2 E4 | "A7" c2e2 a4 | "Dm" f2d2 "A7" c'2e2 | "Em" B6 z2 |
"Em" g4 "B7" f2e2 | "Am" e3f "E7" e2d2 | "Am" a2 e2 "B7" b2g2 | "Em" e8 :|
V:2
%%MIDI program 70
|: "Em" E2^G2 E4 | "A7" C2E2 A,4 | "Dm" F2D2 "A7" C2E2 | "Em" E6 z2 |
"Em" E3E "B7" D2E2 | "Am" E2E "E7" E2^G2 z | "Am" A,2 E2 "B7" B,2G2 | "Em" E8 :|



X:1
T:Lightning Chase
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Em
% Electric Guitar
V:1 clef=treble name="Electric Guitar" subname="Guitar"
%%MIDI program 30
|:"Em" B4E4G4e4 | "D" d4A4F4d4 | "C" c2B2A2G2F2E2D2C2 | "B7" B,4E4F4B4 |
"Em" e8 G8 | "Bm" f8 A8 | "Am" g2f2e2d2c2B2A2G2 | "B7" b,8 ^f8 |
| "Em" B2E2G2B2e2E2G2B2 | "C" G2C2E2A2c2C2E2A2 | "D" A2d2F2A2d2F2A2d2 | "B7" F2B2d2f2b2d2f2b2 |
"Em" e2^g2a4B4 z4 | "A" c2e2f4A4 z4 | "G" B2d2^e2F2G4 z4 | "B7" F8- F2z2 B,4 |[1 "Em" e8 z8 :|[2 "Em" e8 z2^d2 z2 e2||
% Synth strings creating harmonies with the guitar
V:2 clef=treble name="Synth Strings" subname="Strings"
%%MIDI program 50
|:"Em" e2B2G2e2 B2e2B2G2 | "D" d2A2F2d2 A2d2A2F2 | "C" c4 B4 A4 G4 | "B7" B,2E2F2B2 E4 F4 |
"Em" e4 G4 e8 | "Bm" f4 A4 f8 | "Am" g4 e4 g8 | "B7" b,2^f2b2f2 ^f2b2f2b2 |
| "Em" e4 B4 e4 B4 | "C" G4 C4 G4 C4 | "D" A4 d4 A4 d4 | "B7" F4 B4 F4 B4 |
"Em" ^g4 e4 g4 e4 | "A" c4 A4 c4 A4 | "G" B4 d4 B4 d4 | "B7" F2 F4- F2 B,4 z4 |[1 "Em" e4 e4 z8 :|[2 "Em" e8 z2^d2 z2 e2||
% Bass Guitar setting a strong rhythm
V:3 clef=bass name="Bass Guitar" subname="Bass"
%%MIDI program 33
|:"Em" B,8- B,4 E4 | "D" A,8- A,4 D4 | "C" C8- C,4 G4 | "B7" B,8- B,4 F4 |
"Em" e,4 E4 G,4 G4 | "Bm" f4 F4 A,4 A4 | "Am" ^G,8- G,4 C4 | "B7" f8- f,4 B,4 |
| "Em" E4 B,4 E4 B,4 | "C" G,4 C4 G,4 C4 | "D" D4 A,4 D4 A,4 | "B7" F4 B,4 F4 B,4 |
"Em" E4 G,4 e4 G,4 | "A" A,8 A,4 e4 | "G" G,8 G,2 d2 D4 | "B7" F8- F2 z2 B,4 |[1 "Em" E8 z8 :|[2 "Em" E8 z2^D2 z2 E2||
% Drums with Hard Rock feel
%%MIDI drum
% Note: Notation for drums is not included as per instructions. Drum track is for conceptual purposes only.



X:1
T:Electroclash Showdown
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Em
% The piece starts with a driving bassline setting the stage for the battle
V:1 clef=treble name="Lead Synth" subname="Main Melody"
%%MIDI program 81
|:"Em"B2E2G2B2 c4 B2A2|"C"G2A2G4 F4 E4 |
"D"B2A2G2F2 E4 D4|"Bm"C8- C4 B2A2|
"Em"G2B2e2d2 c4 B4|"C"A2c2A4 G4 F4|
"D"e2d2c2B2 A4 G4|"Bm"F8- F4 E4D2|
"Am"c2E2A2B2 c4 B2A2|"Em"B8 A2G2F2E2|
"C"D12 B,4|"D"A3B A4 G2F2 E6 :|
% Section for the hero's parts are more melodic over the driving base rhythm
V:2 clef=treble name="Chords Synth" subname="Chords"
%%MIDI program 82
|:"Em"E4 z4 B4 z4|"C"C4 E4 G4 z4|
"D"D4 F4 A4 B2A2|"Bm"B2 z6 D4 z4|
"Em"E4 z4 e4 z4|"C"c4 E4 G4 A2G2|
"D"D4 F4 z2 A6|"Bm"B2 z4 F4 z2 z4|
"Am"A4 c4 E4 z4|"Em"B8- B4 z4|
"C"C6 E2 G4 z4|"D"D8 A,4 z4 :|
% Bassline maintains a driving sense of tension and energy
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 39
|:"Em"E,4 E,2 F,2 G,8|"C"C,4 E,2 G,4 A,4 B,4|
"D"A,4 A,2 B,2 D8|"Bm"B,4 D4 B,8-|
"Em"E,4 G,4 E,8|"C"C,4 C,2 D,2 E,4 G,4 A,4|
"D"D,8 D,4 F,2 G,2|"Bm"B,2 A,2 z4 B,8|
"Am"A,8 A,2 E,2 z2 F,2|"Em"E,2 D,2 z4 C,8|
"C"C,6 E,2 G,4 A,2 B,2|"D"D,8- D,4 z4 :|



X:1
T:Garden of Vivid Hues
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=110
K:G
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G" D2 B2 G2|"Em" E2 ^C2 E2|"Am" A2 F2 E2|"D7" D2 F2 A2|
"G" G2 B2 d2|"C" e2 c2 e2|"G/D" B2 G2 F2|"D" A4 z2|
"D" F2 A2 d2|"G" B2 d2 B2|"C" e2 G2 c2|"Am" A4 z2|
"Bm" B2 A2 B2|"Em" G2 F2 E2|"A7" C2 E2 ^C2|"D7" D4 z2:|
V:2 clef=treble name="Oboe"
%%MIDI program 68
|:"G" B,2 D2 G2|"Em" E2 G2 B,2|"Am" A,2 C2 E2|"D7" F2 A2 d2|
"G" G2 B,2 D2|"C" E2 G2 c2|"G/D" D2 B,2 A2|"D" F4 z2|
"D" A2 d2 f2|"G" g2 f2 d2|"C" e2 c2 G2|"Am" A4 z2|
"Bm" B2 d2 c2|"Em" B2 G2 E2|"A7" E2 C2 A,2|"D7" F4 z2:|
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"G" G,2 B,2 D2|"Em" E,2 G,2 B,2|"Am" A,2 C2 E2|"D7" D2 F2 A,2|
"G" G,2 B,2 D2|"C" c2 E2 G2|"G/D" D2 B,2 G,2|"D" A,4 z2|
"D" d2 f2 a2|"G" d2 B2 G2|"C" c2 E2 c2|"Am" A,4 z2|
"Bm" b,2 d2 f2|"Em" e2 g2 b,2|"A7" a2 c'2 e2|"D7" d4 z2:|
V:4 clef=treble name="Violin"
%%MIDI program 40
|:"G" G2 B2 d2|"Em" e2 g2 b2|"Am" a2 f2 e2|"D7" f2 a2 d2|
"G" b2 d'2 b2|"C" c'2 e2 c2|"G/D" d2 B2 G2|"D" A4 z2|
"D" f2 d2 F2|"G" g2 B2 G2|"C" c2 e2 c2|"Am" A4 z2|
"Bm" f2 d2 f2|"Em" e2 g2 e2|"A7" c2 e2 a2|"D7" d4 z2:|



X:1
T:Celestial Waltz
C:OrchestrAI
M:C
L:1/8
Q:1/4=100
K:Bm
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
M:7/4
|:"Bm"D2 F2 B2 A2 G2 A2 | "A"F2 E2 F2 E4 D2 | "G" G2 B2 d2 c2 B2 d2 | "A"A4 F2 E6 |
M:5/4
"Bm"B2 d2 f2 d2 B2 | "E"G2 E2 C2 E4| "F#m"A2 A2 c2 "Bm"d2 F2 | "A"E4 z2 F3 G |
M:9/8
"G"B,2 D2 G2 B2 d2 | "A"e2 c2 A2 e2 f2 | "Bm"d2 B2 d2 f2 a2 ::
V:2 clef=treble name="Counter Synth"
%%MIDI program 84
M:7/4
|:"Bm" B,6 B,4 F,4 | "A"A,4 E4 C4 | "G"G,6 G,2 B,4 | "A"A,6 C6 |
M:5/4
"Bm"B,8 F,4 | "E"E,6 G,4 | "F#m"A,4 A,4 | "Bm"B,4 F,4 |
M:9/8
"G"G,4 G,2 B,2 D2 | "A"A,4 C2 E2 | "Bm"B,4 F2 A2 ::
V:3 clef=bass
%%MIDI program 47
M:7/4
|:"Bm" D6 F4 B,4 | "A"A,6 C4 E4 | "G"G,8 D,4 D,4 | "A"A,8 E4 E4 |
M:5/4
"Bm"B,8 B,4 | "E"E,6 E,4 | "F#m"F,8 A,4 | "Bm"B,8 F,4 |
M:9/8
"G"G,4 D,4 G,4 | "A"A,4 C,4 E,4 | "Bm"B,4 F,4 B,4 ::



X:1
T:Estudio Español
C:OrchestrAI
M:3/4
L:1/16
Q:1/4=160
K:Amin
V:1 clef=treble
%%MIDI program 1
|: "Am"e4 e4 e8 | "E7"e4 g4 f8 | "Am"a4 ^g4 a8- | a6 z2 trill z8 |
"Am"e4 e4 e8 | "E7"e4 g4 f8 | "Am" a4 ^ga4 ba4 z2 | "E7"g4e4 f4^g4 |
"Am"a2e2 a4 e4 z4 | a2e2 a4 e4 z4 | "E" e2^g2 b4 g4 z4 | e2^g2 b8 z4 |
"Am"a2a2 e'4 a4 z4 | "Dm"f2f2 d4 c4 z4 | "E7"e4 ^g4 f8 | "Am"a4 a4 a,8:|
V:2 clef=treble
%%MIDI program 1
|: "Am"c4 c4 c8 | "E7"b,4 g4 e8 | "Am"a,4 ^g,4 a,8 | a,6 z2 trill z8 |
"Am"c4 c4 c8 | "E7"b,4 g4 e8 | "Am" a,4 ^ga4 ba4 z2 | "E7"g4b4 e4^g4 |
"Am"a2e2 a4 e4 z4 | a2e2 a4 e4 z4 | "E" e2^g2 b4 g4 z4 | e2^g2 b8 z4 |
"Am"a2a2 e4 a4 z4 | "Dm"f2f2 d4 c4 z4 | "E7"e4 ^g4 f8 | "Am"c4 c4 c,8:|"
abc
X:1
T:Yee Haw Wiggle Expanded
C:Lorn OrchestrAI
M:12/8
L:1/8
Q:3/8=120
K:Bb
V:1 clef=treble
%%MIDI program 56
|: G3 z2 G-G3 G2G | F3 G3 B2G z3 | z2 B B2G B3 G2G | F3 G3 B2G z3 |
G3 z2 G-G3 G2G | F3 G3 B2G z3 | z2 B B2G B3 B2c | ^c3 d3 f2^f z3 :|
|: a3-b2 g f3 d2c | d2c B2A G3-G2 B | a3-b2 g f3 g2a | b3 a3 f2d z3 |
G3 z2 G-G3 G2G | F3 G3 B2G z3 | z2 B B2G B3 B2c |1 ^c3 d3 f2^f z3 :|2 ^c3 d3 f2^f !D.C.! z |
V:2 clef=treble
%%MIDI program 71
|: D3 z2 D-D3 D2D | C3 D3 G2D z3 | z2 G G2D G3 D2D | C3 D3 G2D z3 |
D3 z2 D-D3 D2D | C3 D3 G2D z3 | z2 G G2D G3 G2A | A3 B3 e2^e z3 :|
|: e3-f2 d c3 a2g | a2g G2F D3-D2 G | e3-f2 d c3 e2f | g3 f3 c2a z3 |
D3 z2 D-D3 D2D | C3 D3 G2D z3 | z2 G G2D G3 G2A |1 A3 B3 e2^e z3 :|2 A3 B3 e2^e !D.C.! z |



X:1
T:Elegy of Valor
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Cmin
V:1 clef=treble name="Violin" subname="Vln."
%%MIDI program 40
|:"Cm"G4 ^F4| G6 z2| E2G2 A2G2| "G" D6 z2 | "Ab" A2B2 c4| B2G2 A4 | "Bb"^F2D2 E4| "Cm"E6 z2|
"Cm"G3G ^F3G| G6 z2| E3E G3A| "G" D6 z2 | "Ab" A2B2 c4| B2G2 A4 | "Bb"^F2D2 E4| "Cm"E6 z2|
"Eb"G2G2 F4 :|"Ab"E2E2 ^C4 | "Bb"D2D2 ^F4 | "Cm"G,6 z2 | "G"^F2=G2 A3B | "Cm"G4 E4 | "Bb" D3E ^F3G | "Cm"C6 z2:|
V:2 clef=treble name="Horn" subname="Hrn."
%%MIDI program 60
|:"Cm"C2C2 C4 | E2G2 C4| "G"B,2B,2 D4 | G,2B,2 G4| "Ab"A,2A,2 C4 |"Eb"E2 ^C2 E4| "Bb"D2D2 D4 |1 "Cm"C4 z4:|2 "Cm"C2 z2 z4||
"Cm"E2G2 ^F2G2 | E2G2 C4| B,2D2 G2A2 | B,2D2 G4| A,2C2 B2c2 | A,2C2 E4| ^F2^F2 E4 |1 "Cm"C4 z4:|2 "Cm"C6 z2||
"Eb"G,2G,2 F2E2 | "Ab"E2E2 ^C2D2| "Bb"D2D2 =A2B2 | "Cm"G,2G,2 C2D2| "G"=B,2^F2 G2A2 | "Cm"G2E2 C4 :| "Bb" D2D2 ^F2G2 | "Cm"C6 z2:|
V:3 clef=bass name="Cello" subname="Vc."
%%MIDI program 42
|:"Cm"C,2 z2 G,2 z2| C,4 G,4| E,2 z2 C2 z2| G,4 E,4| "Ab"A,2 z2 C2 z2| A,4 ^C4 | "Bb"D2 z2 F2 z2| C4 z4:|
"Cm"C,2 G,2 F2 E2| C4 G,4| E3E C2C2| G,4 E4 | "Ab"A,2 C2 B,2 ^C2| A4 C4 | "Bb"D2 F2 E2G2| "Cm"C4 z4|
"Eb"G,2 G,2 F4:| "Ab"E2 E2 ^C4| "Bb"B,2 D2 =A4| "Cm"C,6 z2| "G"^F,2=G2 A4| "Cm"E2 C2 G,4| "Bb" D,3E ^F4| "Cm"C6 z2:|
V:4 clef=bass name="Contrabass" subname="Cb."
%%MIDI program 43
|:"Cm"C,4 C,2 E,2| "G"B,,4 D,4| "Ab"A,,4 A,2 ^C2| "Eb"E,,4 E,4| "Bb"D,4 D,4 |1 "Cm"C,4 z4:|2 "Cm"C,2 z2 z4||
"Cm"E,2 G,2 ^F,2 G,2 :| E,2 G,2 C,4| B,,2 D,2 G,2 A,2 | B,,2 D,2 G,4| A,,2 C,2 B,,2 c,2 | A,,2 C,2 E,4| ^F,4 E,4 |1 "Cm"C,4 z4:|2 "Cm"C,6 z2||
"Eb"G,,2 G,,2 F,4 :| "Ab"E,2 E,2 ^C4| "Bb"B,,2 D,2 =A,4 | "Cm"C,6 z2| "G"^F,2=G,2 A,4| "Cm"E,2 C,2 G,4| "Bb" D,,3E ^F,4| "Cm"C,6 z2:|



X:1
T:This Week in Google - Intro Theme
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Cmaj
V:1 clef=treble
%%MIDI program 81
|:"C"E2 E2 G3 E|"Am"E2 A2 c3 A|"F"F2 F2 A3 c|"G"G2 B2 d3 B|
"C"E2 E2 G3 G|"Am"A2 c2 e3 e|"F"d2 c2 B3 G|"G"G8:|
V:2 clef=bass
%%MIDI program 33
|:"C"C,4 E,4|"Am"A,4 C4|"F"F,4 A,4|"G"G,4 B,4|
"C"C,4 G,4|"Am"A,4 E4|"F"F,4 C4|"G"G,8:|
V:3 clef=treble
%%MIDI program 73
|:"C"c4 e4|"Am"e4 a4|"F"a2 g2 f3 e|"G"b4 g4|
"C"c2 g4 e2|"Am"a4 e4 c4|"F"f3 e d2 c2|"G"b6 z2:|



X:1
T:Capers of the Cosmic Crook
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=120
K:Gmin
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"Gm"g4 ^f2 | "D7"d2 c2 B2 | "Cm"G4 z2 | "F7"A2 A2 z2|
"Gm"G2 B2 d2 | "A7"c2 A2 F2 | "Gm"E2 G2 ^F2 | "D7"D4 z2|
"Cm"E2 G2 c2 | "Eb"B2 z2 G2 | "F7"A2 ^f2 a2 | "Bb"g4 z2|
"Gm"B2 c2 d2 | "C7"e2 d2 c2 | "D7"A2 B2 ^c2 | "Gm"G4 z2:|
V:2 clef=treble name="Strings"
%%MIDI program 48
|:"Gm"d4 ^f2 | "D7" F2 E2 D2 | "Cm"C4 z2 | "F7"F2 F2 z2 |
"Gm"G2 B2 d2 | "A7"c2 A2 F2 | "Gm"E2 G2 ^F2 | "D7"D4 z2 |
"Cm"E2 G2 c2 | "Eb"G2 z2 E2 | "F7"F2 ^f2 a2 | "Bb"g4 z2 |
"Gm"G2 A2 B2 | "C7"e2 d2 c2 | "D7"A2 B2 ^c2 | "Gm"G4 z2:|
V:3 clef=bass name="Bass Synth"
%%MIDI program 87
|:"Gm"G,4 D2 | "D7"A,2 ^F2 D2 | "Cm"C,4 z2 | "F7"F,2 F,2 z2|
"Gm"G,4 D2 | "A7"A,2 F2 C2 | "Gm"G,2 E2 C2 | "D7"D,4 z2|
"Cm"C,4 G,2 | "Eb"B,2 z2 G,2 | "F7"F,2 C2 A,2 | "Bb"B,4 z2|
"Gm"G,2 A2 B2 | "C7"C2 D2 E2 | "D7"F2 A,2 D2 | "Gm"G,4 z2:|



X:1
T:Final Encounter
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=176
K:Bm
% The piece starts with an intense introduction setting the stage for battle
V:1 clef=treble name="Brass" subname="Br."
%%MIDI program 61
|:"Bm"f4f4 e2d2c2B2 | "A"e4e4 d4c4 | "G"f8- f2e2d2c2 | "F#7"B4B4 G4F4 |
"Bm"b2a2g2f2 e2d2c2B2 | "A"a2g2f2e2 d4c4 | "G"B2A2G2F2 E4D4 | "F#7"A,4B,4 C4z4 :|
V:2 clef=treble name="Strings" subname="Str."
%%MIDI program 48
|:"Bm"d2d2 e2f2 g4f2 z2 | "A"c2c2 d2e2 f4e2 z2 | "G"d2d2 c2d2 B4A2 z2 | "F#7"G2G2 F2G2 A4G2 z2 |
"Bm"b2a2 b2c2 d4e2 z2 | "A"c2B2 c2d2 e4f2 z2 | "G"d2d2 B2c2 A4F2 z2 | "F#7"G4F4 E4z4 :|
V:3 clef=treble-8 name="Synth Lead" subname="Lead"
%%MIDI program 81
|:"Bm"B,8- B,8 | "A"A,8- A,8 | "G"G,8- G,8 | "F#7"F,8- F,8 |
"Bm"B,4B,4 A,4A,4 | "A"A,4A,4 G,4G,4 | "G"G,4G,4- G,4A,4 | "F#7"F,8- F,8 :|
V:4 clef=bass name="Electric Bass" subname="Bass"
%%MIDI program 33
|:"Bm"B,,4 B,4 E,4 E,4 | "A"A,,4 A,4 D,4 D,4 | "G"G,,4 G,4 D,4 D,4 | "F#7"F,,4 F,4 C,4 C,4 |
"Bm"B,,4 B,4 E,4 E,4 | "A"A,,4 A,4 D,4 D,4 | "G"G,,4 G,4 D,4 D,4 | "F#7"F,,4 F,4 C,4 C,4 :|



X:1
T:Reflections and Revelations
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=112
K:Gmin
% The A section - contemplative and reflective
V:1 clef=treble name="Lead Melody" subname="Accordion"
%%MIDI program 21
|: "Gm"G4 B2 | A4 G2 | F3 E D2 | "Cm"C6 | "D7"D4 F2 | E4 D2 | "Gm"G3 A G2 |1 "Cm"C6 :|2 "Cm"C4 z2 |]
[| "Gm"G2 A B2 c | "Dm"A4 A2 | "Gm"B2 c d2 c | "Cm"B6 | "Dm"d4 d2 | c3 B A2 | "Gm"B3 A G2 | "Gm"G4 z2 :|
% The B section - lively bursts of creativity
|: "Gm"d4 c2 | "Bb"B3 A G2 | "Eb" F4 G2 | "F"F6 | "Bb"G4 A2 | "Gm"A3 B c2 | "D"D3 E D2 |1 "D"D4 z2 :|2 "D"D4 F2 |]
[| "Gm"G2 A B2 d | "Bb"E4 c2 | "Eb"F2 G2 A2 | "F"F3 G A2 | "Bb"B4 "Cm"c2 | "D7"d4 "Gm"e2 | "Cm"c2 B2 "D7"A2 | "Gm"G4 z2 :|
% The A section - return to contemplative theme
V:1
[| "Gm"G4 B2 | A4 G2 | F3 E D2 | "Cm"C6 | "D7"D4 F2 | E4 D2 | "Gm"G3 A G2 | "Cm"C6 |]
[| "Gm"G2 A B2 c | "Dm"A4 A2 | "Gm"B2 c d2 c | "Cm"B6 | "Dm"d4 d2 | c3 B A2 | "Gm"B3 A G2 | "Gm"G4 z2 |]
V:2 clef=treble name="Harmony" subname="String Ensemble"
%%MIDI program 48
|: "Gm"D4 F2 | E4 D2 | C3 B, A,2 | "Cm"G,6 | "D7"A,4 C2 | B,4 A,2 | "Gm"G,3 A, G,2 |1 "Cm"G,6 :|2 "Cm"G,4 z2 |]
[| "Gm"D2 E F2 G | "Dm"C4 C2 | "Gm"F2 G A2 B | "Cm"E6 | "Dm"E4 E2 | D3 C B,2 | "Gm"C3 B, A,2 | "Gm"G,4 z2 :|
|: "Gm"G4 F2 | "Bb"F3 E D2 | "Eb"C4 D2 | "F"F6 | "Bb"G4 A2 | "Gm"A3 B c2 | "D"D3 E F2 |1 "D"D4 z2 :|2 "D"D4 F2 |]
[| "Gm"G2 A B2 d | "Bb"E4 c2 | "Eb"F2 G2 A2 | "F"F3 G A2 | "Bb"B4 "Cm"c2 | "D7"d4 "Gm"e2 | "Cm"c2 B2 "D7"A2 | "Gm"G,4 z2 :|
V:3 clef=bass name="Bass Voice" subname="Bassoon"
%%MIDI program 70
|: "Gm"G,4 G,2 | C,4 G,2 | F,3 E, D,2 | "Cm"C,6 | "D7"D,4 A,2 | D4 D,2 | "Gm"G,3 B, G,2 |1 "Cm"C,6 :|2 "Cm"C,4 z2 |]
[| "Gm"G,2 A, B,2 D | "Dm"F,4 F,2 | "Gm"G,2 A, B,2 G, | "Cm"E,6 | "Dm"F,4 F,2 | E,3 D, C,2 | "Gm"D,3 C, B,,2 | "Gm"G,4 z2 :|
|: "Gm"G,4 F,2 | "Bb"B,,4 D,2 | "Eb" E,4 G,2 | "F"F,6 | "Bb"D,4 F,2 | "Gm"G,3 A, B,2 | "D" D,3 F, D,2 |1 "D" D,4 z2 :|2 "D" D,4 A,2 |]
[| "Gm"G,2 B,2 d2  | "Bb"F,4 D,2 | "Eb" G,2 A,2 B,2 | "F"F,4 A,2 | "Bb"E,6 | "Cm"C,4 E,2 | "D7"F,2 E,2 "Gm"D,2 | "Gm"G,4 z2 :|]



X:1
T:Dance of the Fireflies
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=100
K:Gmaj
% A light and jovial dance capturing the playfulness of fireflies on a summer evening
V:1 clef=treble name="Piccolo" subname="Pc."
%%MIDI program 72
|: "G"d3 B2G | "Em"A3 G3 | "C"E3 G2E | "D"D3 F3 |
"G"B2d d2B | "Am"c2A A2c | "Bm"B2A G2F |[1 "G"G3 z3 :|[2 "G"G3 G2z ||
|: [K:Gmaj] "G"dBd g | "C"edc A2G | "D"F2D D2C | "G"G3-D3 |
"C"E2G c2e | "Em"G2E B2G | "Am"A2c e2A |[1 "D"d3 D2z :|[2 "D"d3 z3 |]
V:2 clef=treble name="Flute" subname="Fl."
%%MIDI program 73
|: "G"g3 e2d | "Em"B3 A3 | "C"c3 e2c | "D"A3 c3 |
"G"e2g g2e | "Am"d2B B2d | "Bm"A2G F2E |[1 "G"G3 z3 :|[2 "G"G3 G2z ||
|: [K:Gmaj] "G"ged c | "C"cBA G2F | "D"A2D D2C | "G"G3-D3 |
"C"E2c G2c | "Em"B2G E2B | "Am"A2F C2A |[1 "D"d2D D2z :|[2 "D"d3 z3 |]
V:3 clef=treble name="Harp" subname="Hp."
%%MIDI program 46
|: "G"D2G B2d | "Em"G2B d2B | "C"C2E G2C | "D"D2F A2D |
"G"B2G D2B | "Am"A2F C2A | "Bm"G2F E2D |[1 "G"G3 z3 :|[2 "G"G3 G2z ||
|: [K:Gmaj] "G"B2G D | "C"E2C A2C | "D"F2D B2D | "G"G3-G3 |
"C"E2c G3 | "Em"B2G E3 | "Am"A2c E3 |[1 "D"D2F G3 :|[2 "D"D3 z3 |]
V:4 clef=bass name="Cello" subname="Ce."
%%MIDI program 43
|: "G"D,2,G, G,2B, | "Em"G,2B, B,2e | "C"C,2E, E,2G, | "D"D,2F, F,2A, |
"G"B,2D G2B | "Am"A,2C E2A | "Bm"G,2F E2D |[1 "G"G,3 z3 :|[2 "G"G,3 G,2z ||
|: [K:Gmaj] "G"D2G B | "C"E2C G2C | "D"F2D A2D | "G"G,3-G,3 |
"C"C2E C3 | "Em"B2G B3 | "Am"A2C A3 |[1 "D"D2F A3 :|[2 "D"D,3 z3 |]



X:1
T:Clash of Titans
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Bm
V:1 clef=treble name="Violin" subname="Vln."
%%MIDI program 40
|:"Bm" B4f4bfed | "A"c2Ac "G"F2GF "F#7"E2FE | "Bm"D2B,DB,C2EF z3 | "A"E6 z6 |
"Bm"B,2B,2F2F2b4 | "G"A2c2 "G"B2d2 "F#7"c2e2 | "Bm"d2B2B2FA z4 | "A"E4 G4 F4 |
"Bm"B2cd e2fg a2b2 | "A"c2de "Em"g2fe z4 |"F#7" f6 ed z4 | "Bm"B4- B2 z2 z4 :|
V:2 clef=treble name="Trumpet" subname="Tpt."
%%MIDI program 56
|:"Bm" d2d2d2f2 a2a2 | "A"g2f2 "G"e2d2 "F#7"d2c2 | "Bm"B2B2B2D2 z4 | "A"E4 C4 F,4 |
"Bm"D2D2D2F2 A2A2 | "A"g4 "G"f4 "F#7"e4 | "Bm"d4 B4 A4 | "A"G6 F4 z2 |
"Bm"d2d2 c2c2 B2B2 | "A"A2A2 "Em"G2G2 z4 |"F#7" F4 E4 D4 | "Bm"B,4- B2 z2 z4 :|
V:3 clef=bass name="Cello" subname="Cello"
%%MIDI program 42
|:"Bm" B,2F2B2d2 F2B2 | "A"A,2A,2 "G"G,2G,2 "F#7"F,2F,2 | "Bm"D,2D,2E2F2 G2A2 | "A"A,6 B,4 z2 |
"Bm"B,2F2B2d2 F2B2 | "A"A4 "G"B4 "F#7"A4 | "Bm"B,2D2D2E2 z4 | "A"F4 E4 D4 |
"Bm"B,2F2 F2G2 A2B2 | "A"C2D2 "Em"E2F2 z4 |"F#7" F6 G2 z4 | "Bm"B,4- B,2 z2 z4 :|
V:4 clef=bass name="Bassoon" subname="Bsn."
%%MIDI program 70
|:"Bm"D4B,4B,4 | "A"A,4 A,4 z4 | "G"G,4 G,4 z4 | "F#7"F,4 F,4 z4 |
"Bm" B,4 B,4 B,4 | "A"A,4 A,4 z4 | "G"G,4 F4 z4 | "F#7"E4 D4 C4 |
"Bm"D2D2 D2E2 F4 | "A"C4 B,4 z4 | "Em"A4 G4 z4 | "F#7"F4 E4 z4 :|
"Bm"D4 B,4 B,4 | "A"A,4 A,4 z4 | "G"G,4 F4 z4 | "F#7"E4 D4 C4 |
"D" B,4- B,2 z2 z4 :|



X:1
T:Harmonic Voyage
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=144
K:Cmaj
% Introduction
V:1 clef=treble name="Violin"
%%MIDI program 40
|:"C" G2 Gc e2 "G7" fg | "F" a2 Gf e2 dc | "d" A2 AG F3 E | "G" D4- D3 z |
% Development
|:"G" B3 G d2 dB ||: "C" c2 Gc e3 d | "a" c>b a2 ef "D7" f3  | "G" B2 Bc A5  |
% Recapitulation
|:"C" E3 G e2 c2 ||: "F" f2 gf e3 d | "G" B2 AG F3 E | "C" G3 E C5  |
V:2 clef=treble name="Flute"
%%MIDI program 73
|:"C" E2 E2 G2 E2 | "G7" B2 A2 G4 | "F" A2 A3 c2 A | "d" f2 e2 d2 cB |:"G7" c2 d2 f4 |
% Development
|:"G" F4 f2 ed | "C" ec e4 z2 | "a" c4 b2 ac ||: "D7" f2 c4 z2 |
% Recapitulation
|:"C" E2 e2 g2 e2 | "F" f2 d2 e2 c2 | "G" B2 g3 f ed | "C" E2 c2 C5  |
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"C" G,2 C E2 G,2 z | "G7" D2 B,2 G,2 z2 | "F" F,2 C2 F,2 D2 | "d" A,2 F, E2 D,2 z |
% Development
|:"G" G2 d2 f2 B,2 ||: "C" E2 C G,2 c2 z | "a" E2 a2 e2 a2 | "D7" F,2 A, C2 D2 z |
% Recapitulation
|:"C" G,2 c2 E2 g2 ||: "F" C2 A,2 F,2 D2 | "G" G,2 B, F,2 E,2 z | "C" C2 E, C4 z |
V:4 clef=bass name="Double Bass"
%%MIDI program 45
|:"C" G,4 E,4 | "G7" D,2 B,,2 G,4 | "F" F,2 D,2 A,2 C, z | "d" A,,2 F,4 D,2 |
% Development
|:"G" G,2 d2 f2 B,,2 ||: "C" E,2 C2 G,,2 c,2 | "a" E,2 a,2 e,,2 a,,2 | "D7" F,2 A,2 D,4 |
% Recapitulation
|:"C" G,2 c,2 E,2 g,,2 ||: "F" C,2 F,2 D,4 | "G" G,2 B,,2 F,2 E,2 | "C" C2 E,C4 z |



X:1
T:Galactic Ballet
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:Em
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"Em" B4 z2 |"Cmaj7"G4 E2 |"D" F3E DC |"G"B,4 z2 |
"Am"A4 c2 | "B7"B3A GF |"Em"E2 ^D2 E2|"Cmaj7"G6 |
"Em"B6 | "Cmaj7"G4 F2 |"B7"A4 ^G2 |"Am"A4 z2 :|
V:2 clef=treble name="Violin I"
%%MIDI program 40
|:"Em" e3f g2 | "Cmaj7"g3a b2 |"D"a2 gf ed |"G"b6|
"Am"a4 c'2 | "B7"b2 ag fe |"Em"e6 |"Cmaj7"e3f g2 |
"Em"e6 | "Cmaj7"d2 e3d | "B7"c2 B3c |"Am"B4 z2 :|
V:3 clef=treble name="Violin II"
%%MIDI program 40
|:"Em" B3A G2 | "Cmaj7"E3G F2 |"D"D2 FE DF |"G"G4 z2 |
"Am"A3B A2 | "B7"G2 F2 G2 |"Em"E4 ^D2 |"Cmaj7"G4 z2 |
"Em"B,6 | "Cmaj7"G3F E2 | "B7"F4 ^G2 |"Am"A6 :|
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"Em" E,4 z2 | "Cmaj7"C,4 E,2 | "D"D,3C B,A, | "G"B,4 z2 |
"Am"A,4 C2 | "B7"B,4 ^F2 | "Em"E,4 z2 | "Cmaj7" G,4 z2 |
"Em"E,6 | "Cmaj7"C4 D2 | "B7"B,4 A2 | "Am"A,4 z2 :|



X:1
T:Pirate's Polka Jig
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=126
K:Dmaj
% The melody is designed to be catchy and encourage dancing
V:1 clef=treble name="Accordion"
%%MIDI program 21
|: "D"A2FA A2FA | "G"B2GB B2dB | "D"A2FA "A7"GFE2 |1 "D"F2A2 D4 :|2 "D"F2A2 D3z |
|: "D"d2fd d2fd | "A7"e2ce e2ce | "G"d2B2 B2AG |1 "A7"F2E2 E4 :|2 "A7"F2E2 E3z |
|: "D"A2d2 f3a | "G"b2g2 g3b | "D"a2f2 "A7"e2c2 |1 "D"d2A2 D4 :|2 "D"d2A2 D3z |
V:2 clef=treble name="Fiddle"
%%MIDI program 110
|: "D"FAFA FAFA | "G"BGBd gdBd | "D"FAFA "A7"GFEF |1 "D"A2F2 D4 :|2 "D"A2F2 D3z |
|: "D"f2d2 f4 | "A7"e2c2 e4 | "G"d2B2 B2AG |1 "A7"F2E2 E4 :|2 "A7"F2E2 E3z |
|: "D"A2d2 f3a | "G"b2g2 g3b | "D"a2f2 "A7"e2c2 |1 "D"A2F2 D4 :|2 "D"A2F2 D3z |
V:3 clef=bass name="Bass"
%%MIDI program 33
|: "D"D,4 D,2A,2 | "G"G,4 G,2B,2 | "D"D,2A,2 "A7"A,2E,2 |1 "D"F,2A,2 D,4 :|2 "D"F,2A,2 D,3z |
|: "D"A,,4 A,2F,2 | "A7"A,4 C4 | "G"B,,4 B,2G,2 |1 "A7"E,2C,2 E,4 :|2 "A7"E,2C,2 E,3z |
|: "D"A,,4 A,2D,2 | "G"B,,4 B,2G,2 | "D"A,2F,2 "A7"A,2E,2 |1 "D"F,2A,2 D,4 :|2 "D"F,2A,2 D,3z |



X:1
T:Cyber Chase
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=160
K:Cmin
% Synthesizers and strings create the tension and atmosphere of a cyberpunk city
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|: "Cm"G,4C4G4C4 | c8G8 | "Fm"AcBAG4A2G2 z4 | "G"G,4^F4G8 |
"Cm"G,4C4G4C4 | c8G8 | "Fm"AcBAG4A2G2 z4 | "G"G,8- G,8 :|
V:2 clef=treble name="Pads"
%%MIDI program 89
|: "Cm"c2 z4 e2 g2 g4 c2 | "Fm"f2 z2 f2 a2 g2 f2 z4 | "Cm"c2 z4 e2 g2 g4 c2 | "G"d2 c2 B2 d4 C6 |
"Cm"c8- c8 | "Fm"f2 z2 f2 a2 g2 f2 z4 | "Cm"c8- c8 | "G"g,2 a2 g2 f4 C6 :|
V:3 clef=treble name="Arp Synth"
%%MIDI program 84
|: "Cm"e16 | g16 | "Fm"f8 efge z4 | "G"g,16 |
"Cm"c8 G4 c4 | e8 c'8 | "Fm"f8 efge z4 | "G"d8 c8 :|
V:4 clef=bass name="Bass Synth"
%%MIDI program 38
|: "Cm"C,2C2C2C2D2D2D2D2 | "Fm"D2D2F2F2A2A2F2 z2 | "Cm"C,2C2C2C2D2D2D2D2 |
"G"G,,2G,2^F,2G,2G,2G,2C2 z2 | "Cm"C,2C2C2C2D2D2D2D2 | "Fm"D2D2F2F2A2A2F2 z2 |
"Cm"C,2C2C2C2D2D2D2D2 | "G"G,,8- G,,8 :|



X:1
T:Final Encounter
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=170
K:Bm
% The piece starts with a fast-paced rhythm to set the tone for the battle
V:1 clef=treble name="Lead Synth" subname="Melody"
%%MIDI program 81
|:"Bm"B4f4B4f4|"E"c4c4z8|"F#m"A4A4c4c4|"Bm"B8- B4z4|
"Bm"d2f2B2d2f2d2B2d2|"E"g2g2g2g2z8|"F#m"A6c2 A6c2|"Bm"B8- B4z4|
"F#m"A2A2e2e2A2A2e2e2|"E"e2e2c2c2e2e2c2c2|"G"A2f2A2d2A2f2A2d2|"Bm"B8- B4z4:|
V:2 clef=treble name="Strings" subname="Harm."
%%MIDI program 48
|:"Bm" B8- B8|"E" E8- E8|"F#m" A8- A8|"Bm" B8- B8|
"Bm" B,8- B,8|"E" E,8- E,8|"F#m" A,8- A,8|"Bm" B,8- B,8|
"F#m" A8- A8|"E" E8- E8|"G" G8- G8|"Bm" B8- B8:|
V:3 clef=treble name="Brass" subname="Counter-Mel."
%%MIDI program 61
|:"Bm"f2f2f2f2 e4d4|"E"e2e2e2e2 e2c2B2c2|"F#m"d2c2B2A2 G4F4|"Bm"A6f2 A4z4|
"Bm"f3ef2d2 c4B4|"E"c4B4 A3GB4|"F#m" c2d2e2f2 d2d2c2B2|"Bm"B8- B4z4|
"F#m"e2e2e2f2 g2g2f2e2|"E"e2f2g2a2 b2b2a2g2|"G"f2g2a2b2 a2a2g2f2|"Bm"B8- B4z4:|
V:4 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|:"Bm"B,8 B,8|"E"E,8 E,8|"F#m"A,8 A,8|"Bm"B,8 B,8|
"Bm"B,,8 B,,8|"E"E,,8 E,,8|"F#m"A,,8 A,,8|"Bm"B,,8 B,,8|
"F#m"A,8 A,8|"E"E,8 E,8|"G"G,8 G,8|"Bm"B,8 B,8:|



X:1
T:Festival of Joy
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=144
K:Cmaj
V:1 clef=treble name="Trumpet" subname="Trpt."
%%MIDI program 56
z8 | "C"G4 E4 | "G"G8 | "F"F6 A2 | "C"G4 z G2 z | "Am"A4 E4 | "Dm"F4 E4 | "G"G4 z2 G2 | "C"c8 :|
z8 | "C"e4 d4 | "G"d8 | "Am"c6 A2 | "F"f4 z f2 z | "G"g4 B4 | "C"c4 B4 | "F"A4 z2 A2 | "C"c8 :|
V:2 clef=treble name="Alto Sax" subname="Alto"
%%MIDI program 65
z8 | "C"c4 A4 | "G"d8 | "F"e6 c2 | "C"d4 z d2 z | "Am"c4 A4 | "Dm"B4 A4 | "G"g4 z2 g2 | "C"c8 :|
z8 | "C"c4 B4 | "G"B8 | "Am"A6 G2 | "F"f4 z A2 z | "G"B4 d4 | "C"c4 d4 | "F"e4 z2 e2 | "C"c8 :|
V:3 clef=bass name="Trombone" subname="Tbn."
%%MIDI program 58
z8 | "C"C4 E,4 | "G"G,8 | "F"F,6 C2 | "C"C4 z C2 z | "Am"A,4 C4 | "Dm"D4 E4 | "G"G,4 z2 G,2 | "C"C8 :|
z8 | "C"C4 D4 | "G"B,8 | "Am"A,6 e2 | "F"F,4 z F2 z | "G"g,4 B,4 | "C"c4 B,4 | "F"A,4 z2 A,2 | "C"C8 :|
V:4 clef=bass name="Bass Guitar" subname="Bass"
%%MIDI program 33
z8 | "C"C,8 | "G"B,,8 | "F"A,,8 | "C"G,,8 | "Am"C,8 | "Dm"D,8 | "G"B,,8 | "C"C,8 :|
z8 | "C"c8 | "G"B,,8 | "Am"A,8 | "F"F,8 | "G"G,8 | "C"c8 | "F"F,8 | "C"C,8 :|



X:1
T:Impressionist Quartet in B major
C:OrchestrAI "Ravelian Reflections"
M:3/4
L:1/8
Q:1/4=80
K:Bmaj
V:1 clef=treble name="Violin I"
%%MIDI program 40
M:3/4
|:"B" F4 B2 | "E"e4 B2 | "C#m7-5"A4 F2 | "F#"F3 E D2 |
M:5/4
"D#m7"C2 B2 A2 G2 F2 | "E"E6- E2 "F#7" F4 |
M:6/8
"B" F3 B,3 | "G#m7" G3 E3 | "C#m"A,3 C3 | "F#"D3 F2 z |
M:7/8
"B" F3 (B,A) B2 | "E"e2 ^d e2 f2 | "G#m"E3 F G2 | "F#7"A3 G F2 |
M:4/4
"B"B,6 B,2 :|
V:2 clef=treble name="Violin II"
%%MIDI program 40
M:3/4
|:"B"D4 F2 | "E"e2 c2 A2 | "C#m7-5"A2 F2 A2 | "F#"F2 E4 |
M:5/4
"D#m7"A2 z2 F2 G2 A2 | "E"B3 C D2 "F#7"A4 |
M:6/8
"B" D3 F3 | "G#m7" B3 G3 | "C#m"E,3 A,3 | "F#"C3 B,3 |
M:7/8
"B" D3 (A,G) D2 | "E"e2 f e2 g2 | "G#m"B3 ^A B2 | "F#7"A3 ^G A2 |
M:4/4
"B"B,6 D2 :|
V:3 clef=alto name="Viola"
%%MIDI program 41
M:3/4
|:"B"B,4 D2 | "E"=G4 ^F2 | "C#m7-5"C2 B,2 E2 | "F#"A3 G E2 |
M:5/4
"D#m7"E2 F2 G2 E2 D2 | "E"=G3 A ^G2 "F#7"B4 |
M:6/8
"B" B3 D3 | "G#m7" E3 C3 | "C#m"F,3 A,3 | "F#"G3 E3 |
M:7/8
"B" B3 (D,C) B2 | "E"=G2 A G2 F2 | "G#m"B,3 C D2 | "F#7"E3 D E2 |
M:4/4
"B"B,6 G2 :|
V:4 clef=bass name="Cello"
%%MIDI program 42
M:3/4
|:"B"B,2 F,3 z | "E"=G,2 E,3 z | "C#m7-5"A,3 B, C2 | "F#"F,2 G, (A,B,) |
M:5/4
"D#m7"D3 E F G A2 | "E"E,3 B,, C,3 "F#7"G,4 |
M:6/8
"B" B,,3 F,3 | "G#m7" G,3 E,3 | "C#m"A,,3 A,3 | "F#"F,,3 D,3 |
M:7/8
"B" B,,5 (D,C) | "E"=G,,2 A,, E,2 | "G#m"E,,3 B,,3 | "F#7"A,,3 F,,3 |
M:4/4
"B"B,,6 B,,2 :|



X:1
T:Friday Night Expanded
C:JLEM arranged by OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Bb
V:1 clef=treble
%%MIDI program 56
z4 zFGA | B2 ._A.B z2 FG | B2 ._A.B z4 | "Eb" G6 GA | "F" F2 ._E.F z2 DE | "Eb" G2 ._G.A BB A2|
"Gb" G2 F2 E2 D2 | "Bb" F4 zF GA | B2 ._A.B z4 | "Cm7" cefg "F7" edcB | "Bb" B2d2 f2g2 | "Eb" afge "F" dcBA|
"Eb" G2 FG A3 B | "F" =c4 z2 DE | "Bb" F6 zf | "Gm" g4 fedc | "Eb" B6 zG | "F" A3 G FEFD | "Bb" B4 z4 :|



X:1
T:Final Showdown Overture
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=144
K:Dmin
% The introduction sets the stage with a sense of impending confrontation
V:1 clef=treble name="Brass" subname="Br."
%%MIDI program 61
|:"Dm"d2a agf| "C"cde e3| "A7"gfe dcB| "Dm"A2F FED|
"Dm"D2A AGF|"C"CDE E3|"A7"GFE DCB|"Dm"D3-D3:|
V:2 clef=treble name="Violin 1" subname="Vln. 1"
%%MIDI program 40
|:"Dm"fff edc| "C"e2e e2f| "A7"g2g f2e| "Dm"d3 cde|
"Dm"f3 edc|"C"cde e2f|"A7"gfg fec|"Dm"d3-d3:|
V:3 clef=treble name="Violin 2" subname="Vln. 2"
%%MIDI program 40
|:"Dm"aba agf| "C"ggg g2a| "A7"bag fGF| "Dm"AFA DED|
"Dm"D2a agf|"C"g2g g2a|"A7"bag fec|"Dm"d3-d3:|
V:4 clef=bass name="Cello" subname="Cl."
%%MIDI program 42
|:"Dm"D3 A,3| "C"E3 C3| "A7"A,3 E3| "Dm"D3 F,3|
"Dm"D3 D,3| "C"E3 E3| "A7"A,3 A,3| "Dm"D3-D3:|



X:1
T:Cyber Velocity Racer
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Bmin
% The tune's unique sound is generated by driving rhythms and diverse electronic textures
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"Bm"[B4d4f4] z8 z4 | [B4d4f4] z8 z4 | [e4a4c'4] z2 [d4a4f'4] z6 | [c4g4e'4] z8 z4 |
"Bm"[B4d4f'4] [B3d3f'3] z [B2d2f'2] z6 | "A"[c4e4a4] z2 [c4e4a4] z6 | "G"B2G2 B2G2 B2d2 B2d2 | [A4c4e4] z8 z4 |
[1 "Bm"[B4d4f'4] z8 z4 | [B4d4f4] z8 z4 | [e4a4c'4] z2 [d4a4f'4] z6 | [c4g4e4] z8 z4 :|
[2 "Bm"[B2d2f2] z6 a2 z6 | [B2d2f2] z6 b2 z6 | "A"[c2e2a2] z2 "G"[d2B2G2] z2 z8 | "F#m"[A4c4f4] z8 z4 :|
V:2 clef=treble name="Rhythm Synth" subname="Rhythm"
%%MIDI program 84
|:"Bm"f8 z4 [df]4 | f8 z4 [df]4 | e8 z4 [ce]4 | e8 z4 [ce]4 |
f8 z4 [df]4 | f8 z4 [df]4 | g8 B4 d8 | e8 c4 a8 |
[1 f8 A4 c8 | f8 A4 c8 | e8 G4 c8 | e8 G4 c8 :|
[2 f8 z4 [df]4 | f8 z4 [df]4 | e8 z4 [ce]4 | e8 z4 [ce]4 :|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|:"Bm"[B,8F8] [B,8F8] | [B,8F8] [B,8F8] | [C8G8] [C8G,8] | [B,8F8] [B,8F8] |
[B,8F8][B,8F8]
| [C8E8][C8E8]
| "G"[G,8D8] [G,8D8] | "A"[A,16] |
[1 [B,16]| [B,16]
| [C16]| [B,16]
:|
[2 [B,8F8][B,8F8]
| [B,8F8][B,8F8]
| [C8E8][C8E8]
| [B,16]:|



X:1
T:Metamorphoses
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=90
K:C
V:1 clef=treble name="Flute" subname="Fl."
%%MIDI program 73
|: "C"E2 | "Am"A4 G2 | F6 | "G7"G4 F2 | E6- | E4 z2 :|
V:2 clef=treble name="Oboe" subname="Ob."
%%MIDI program 68
|: "C"C4 E2 | "Am"A6 | G4 F2 | "G7"G6 | "C"C4 C2 :|
V:3 clef=treble name="Clarinet" subname="Cl."
%%MIDI program 71
|: "C"E2 G2 E2 | "Am"A4 c2 | "Dm"d6 | "G7"G4 F2 | E6- | E4 z2 :|
V:4 clef=treble name="Violin I" subname="Vln. I"
%%MIDI program 40
|: "C"c4 e2 | "Am"a6 | "F"f4 e2 | "G7"g6 | "C"c4 c2 :|
V:5 clef=treble name="Violin II" subname="Vln. II"
%%MIDI program 40
|: "C"G,4 C2 | "Am"A,6 | "F"F4 E2 | "G7"D6 | "C"C4 C2 :|
V:6 clef=alto name="Viola" subname="Vla."
%%MIDI program 41
|: "C"E4 G2 | "Am"C6 | "F"F4 A2 | "G7"G4 F2 | "C"E4 z2 :|
V:7 clef=bass name="Cello" subname="Vc."
%%MIDI program 42
|: "C"C2 E2 G2 | "Am"A4 E2 | "Dm"D4 F2 | "G7"G,4 D2 | "C"C6- | C4 z2 :|
% Change to mixed meter, initiation of transformation
K:Amix
M:5/4
|: "Am"A4 z2 A2 c2 | "F"E4 z2 F2 A2 | "C"G4 z2 E2 G2 |
"Am"c6 A2 c2 | "F"E6 F2 A2 | "G7"G4 (3BAG (3FGA G4 | "C"C4 z4 :|
% Further progression, voices start to intertwine
M:7/4
K:D
|: "D"A4 F4 D2 A2 | "Bm"F4 D4 B,2 F2 | G6 F4 E2 |
"D"A,4 A,4 D4 E2 | "G"G4 G4 B,4- | B,6 A4 G2 | "A7"A8- | A4 z4 :|
|: "D"A4- A6 F2 | "Bm"B4 (3Bcd c6 | "G" d8 c4 B2 |
"D"A4 (3def e6 d2 | "G"G4 F4 G4 A2 | "A7"A4 G4 F4 E2 | "D"D8- | D4 z4 :|
% Climactic full ensemble resolution
M:C
K:G
V:1
|: "G"G4 F2 E  | "Am"A4 B2 A  | "C"D6 B,  | G6- | G4 z2 :|
V:2
|: "G"G4 A2 B  | "Am"A6 G  | "C"E6 D  | G,6- | G,4 z2 :|
V:3
|: "G" d4 e2 f  | "Am"a4 g2 f  | "D"B6 A  | d6- | d4 z2 :|
V:4
|: "G"B4 A2 G  ||: "Am"A4 B2 c  | "C"c6 B  | G6- | G4 z2 :|
V:5
|: "G"D4 E2 F  ||: "Am"A4 G2 F  | "C"E6 D  | G,6- | G,4 z2 :|
V:6
|: "G"G4 A2 B  | "Am"A6 G  | "C"E6 D  | G,6- | G,4 z2 :|
V:7
|: "G"G,4 F2 E  | "Am"A,4 B,2 A,  | "D"D6 B,,  | G,,6- | G,,4 z2 :|



X:1
T:Sunrise on the Apocalypse
C:OrchestrAI
M:C
L:1/8
Q:1/4=60
K:Am
V:1 clef=treble
%% MIDI program 79
|:"Am"A2 e2 d2 c2| e6 z2|"C"g2 e2 d2 B2| c6 z2| "G"B2 d2 c2 B2| d6 z2| "F"c2 A2 G2 F2| "E7"A6 z2|
"Am"A3/g/ a2 e2 f2|"Dm"d6 z2|"Am"c3/B/ A2 F2 E2|"G" G6 B2| "C"E2 G2 A2 G2| "F"F6 A2|"E7"E3 ^D E2 F2|"Am"A6 z2:|
V:2 clef=bass
%% MIDI program 70
|:"Am"A,2 E2 A,2 E2| "C"G,2 C2 G,2 C2| "G"B,2 D2 B,2 D2| "F"C2 F2 C2 A,2|
"Am"A,3/B, C2 E2 D2| "Dm"D,2 F2 A,2 F2| "Am"A,2 E2 C2 E2| "G"G,2 B,2 D2 G,2|
"C"C2 G,2 E2 G2| "Am"A,2 F2 A,2 E2| "G"B,2 D2 G,2 B,2| "Am"A,4 A,4| "Em"E,4 E,4| "Am"A,4 ^C2|"E7"B,4 G2| "Am"A,6 z2:|



X:1
T:This Week in Google - Podcast Intro
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=115
K:Cmaj
V:1 clef=treble
%%MIDI program 81
|:"C"E2G2c2E2|"Am"A2A2 G3E|"F"F2F2 E2D2|"G"G2E2 C4|
"C"E2G2c2E2|"Am"A2A2 G3E|"F"F2A2 c2B2|"G"G6 z2:|
V:2 clef=treble
%%MIDI program 81
|:"C"c2c2 e3g|"Am"a2a2 c'2b2|"F"e2d2 c2B2|"G"B2A2 G4|
"C"c2c2 e3g|"Am"a2a2 e2c2|"F"f2g2 a2f2|"G"g4 z4:|
V:3 clef=bass
%%MIDI program 33
|:"C"C2G,2 C4|"Am"A,3E A,4|"F"F,4 F,2A,2|"G"G,4 B,2D2|
"C"C2G,2 C4|"Am"A,3E A,4|"F"F,2C2 F,2A,2|"G"G,8:|
|:"C"c2e2 g2c2|"Am"e4 c2A2|"F"d2f2 a2d2|"G"g2 e2 d4|
"C"c2e2 g2c2|"Am"a3b c'2a2|"F"f2 a2 f2d2|"G"g4 z4:|



X:1
T:Bandstand Frolic
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Cmaj
V:1 clef=treble name="Trumpet 1"
%%MIDI program 56
|:"C" G4 zE G2 | "F" c3 B AGFE | "C" G4 zE G2 | "G" D4- D2 z2 |
"G7" B,4 zD G2 | "C" E4 zG, C2 | "F" A4 G2F2 | "C" E4- E2 z2 |
"C7" e4 d2c2 | "F" f4 e2d2 | "C" G4 zE G2 | "G" D6 z2 |
"G7" Bc d2 cB AG | "C" E4 zG, C2 | "F" A4 G2F2 | "C" E6 z2 :|
V:2 clef=treble name="Alto Sax"
%%MIDI program 65
|:"C" e4 zc e2 | "F" a3 g fedc | "C" e4 zc e2 | "G" B4- B2 z2 |
"G7" d4 zB d2 | "C" c4 ze c2 | "F" f4 e2d2 | "C" c4- c2 z2 |
"C7" g4 f2e2 | "F" a4 g2f2 | "C" e4 zc e2 | "G" B6 z2 |
"G7" de f2 ed cB | "C" c4 ze c2 | "F" f4 e2d2 | "C" c6 z2 :|
V:3 clef=treble name="Tenor Sax"
%%MIDI program 66
|:"C" c4 zG, C2 | "F" F3 E DCB,A, | "C" c4 zG, C2 | "G" G,4- G,2 z2 |
"G7" G,4 zD G,2 | "C" C4 zC G,2 | "F" A,4 G,2F,2 | "C" C4- C2 z2 |
"C7" c4 B2A2 | "F" F4 E2D2 | "C" c4 zG, C2 | "G" G,6 z2 |
"G7" CG G2 FG E2 | "C" C4 zC G,2 | "F" A,4 G,2F,2 | "C" C6 z2 :|
V:4 clef=bass name="Bass Guitar"
%%MIDI program 33
|:"C" C,2G,,2 zC, G,,2 | "F" F,,2C,2 F,,2C,2 | "C" C,2G,,2 zC, G,,2 | "G" G,,2D,2 G,,2D,2 |
"G7" G,,2D,2 zG,, F,2 | "C" C,2G,,2 zC, G,,2 | "F" F,2E,2 F,2E,2 | "C" C,4- C,2 z2 |
"C7" C,2B,,2 C,2B,,2 | "F" F,2F,,2 F,2F,,2 | "C" C,2G,,2 zC, G,,2 | "G" G,,4- G,,2 z2 |
"G7" B,,2D,2 C,2D,2 | "C" C,2G,,2 zC, G,,2 | "F" F,,4 F,2E,2 | "C" C,6 z2 :|



X:1
T:Horizon Prelude
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Am
% Melody captures a sense of anticipation and forward-looking optimism
V:1 clef=treble
%%MIDI program 1
|:"Am"A2 ce a2 e2|"G"G3 A B2 d2| "F"E2 FG A2 c2|"E"E4 -E2 z2|
"Am"A2 ce a2 e2|"G"G3 A B2 d2|"F"E3 F "E"EDC2|"Am"A6 z2:|
V:2 clef=treble
%%MIDI program 1
|:"Am"C2 E2 A,2 C2|"G"B,3 C D2 G2|"F"A,2 B,2 C2 E2|"E"E4 -E2 z2|
"Am"C2 E2 A,2 C2|"G"B,3 C D2 G2|"F"A,2 A,2 "E"G,2 G,2|"Am"A,6 z2:|
V:3 clef=bass
%%MIDI program 1
|:"Am"E2 A2 C2 E2|"G"D2 G2 B,2 D2|"F"C2 F2 A,2 C2|"E"E3 B, E3 B,|
"Am"E2 A2 C2 E2|"G"D2 G2 B,2 D2|"F"C2 F2 "E"E2 z B,|"Am"A,6 z2:|
% Development section explores the nuanced emotions with a darker tone
V:1
|:"Dm"F2 A2 d2 fd|"C"e2 ge c2 ec|"Bb"d2 cB B2 G2|"Am"A4 -A2 z2|
"Dm"F2 A2 d2 fd|"C"e2 ge c2 ec|"Bb"d3 c "A7"cBA2|"Dm"d6 z2:|
V:2
|:"Dm"A,2 D2 F2 A,2|"C"G,2 C2 E2 G,2|"Bb"F,2 Bb,2 D2 F,|"Am"A,4 -A,2 z2|
"Dm"A,2 D2 F2 A,2|"C"G,2 C2 E2 G,2|"Bb"F,3 E "A7"E2 DC|"Dm"D,6 z2:|
V:3
|:"Dm"D2 F2 A,2 D2|"C"C2 E2 G,2 C2|"Bb"B,2 B,2 F2 z B,|"Am"A,4 -A,2 z2|
"Dm"D2 F2 A,2 D2|"C"C2 E2 G,2 C2|"Bb"B,3 A, "A7"A,2 C2|"Dm"D,6 z2:|
% Climactic section resolving into A Major for a hopeful, upbeat conclusion
V:1
|:"A"e2 ce a2 fe|"D"f2 df b2 af|"G"g2 fe d2 cd| "A"e6 e2|
"A"e2 ce a2 fe|"D"f2 df b2 af|"E"e2 e2 "A"cBA2|"A"A8:|
V:2
|:"A"A,2 CE A,2 CE|"D"F2 DF A,2 DF|"G"G2 FE D2 CD|"A"A,6 A,2|
"A"A,2 CE A,2 CE|"D"F2 DF A,2 DF|"E"E2 E2 "A"A,2 C2|"A"A,8:|
V:3
|:"A"E2 A,2 C2 E2|"D"D2 F2 A,2 D2|"G"G2 B,2 D2 G2|"A"E6 E2|
"A"E2 A,2 C2 E2|"D"D2 F2 A,2 D2|"E"E2 E2 "A"A,2 E2|"A"A,8:|



X:1
T:Golem's Reawakening
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=132
K:Ddor
% Voice 1 - Lead Melody, representing the unyielding presence of the golem
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"Dm"A2A2 A2f2 z4 d2A2|c2c2 c2A2 z4 c2E2|A2A2 A2f2 z4 d2A2|c4 E4 E4 z2 z2|
d2e2 c2A2 z4 A2d2|c2c2 A2f2 z2 e6|d2d2 A2f2 z4 g2e2|1 c8 z8 :|2 c8 A4 z2 z2||
% Voice 2 - Harmony, adding an arcane texture to the melody
V:2 clef=treble name="Brass Section"
%%MIDI program 61
|:"Dm"F2F2 F2A2 z4 F2D2|E2E2 E2C2 z4 E2A,2|F2F2 F2A2 z4 F2D2|G4 A4 A4 z2 z2|
d2f2 f2A2 z4 d2f2|e4 e2A2 z4 d4|A4 F2D2 z4 E4|1 F8 z8 :|2 F8 F4 z2 z2||
% Voice 3 - Bass Line, echoing the steps of the golem
V:3 clef=bass name="Bass Synth"
%%MIDI program 38
|:"Dm"D,8 D,8|A,,8 A,,8|D,8 D,8|A,,8 z8|
D,4 F,4 A,4 C4|A,,4 C4 D,4 F,4|D,8 D,8|1 A,,8 z8 :|2 A,,8 D,4 z2 z2||
% Voice 4 - Percussive Elements, no MIDI program needed as requested to exclude percussion voices
V:4 clef=perc stafflines=1
|:D4 z4 D4 z4|C4 z4 C4 z4|D4 z4 D4 z4|C4 z4 z8|
D4 z4 D4 z4|C4 z4 C4 z4|D4 z4 D4 z4|1 C4 z4 z8 :|2 C4 z4 D4 z2 z2||



X:1
T:Friday Night Expanded
C:JLEM arr. OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Bb
V:1 clef=treble
%%MIDI program 56
z4 zFGA | B2 ._A.B z2 FG | B2 ._A.B z4 | % Existing part
^F2FE ^D2C2 | B,B4- B3z | % New part begins
CCGA B2A2 | G4 _A2B2 | _A4 G4- | G2z2 F4 |
BBcd efed | cdec BABG | A2F2 FGAG |1 F4 :|2 F2z2 F4 |] % Repeat sign with 1st and 2nd endings
V:2 clef=bass
%%MIDI program 33
B,2 B,2 CDEF | B,2F2 F2z2 | B,2F2 B,4 | % Existing part expanded to bass
^F,4 ^D,4 | B,,4 =D2 B,4 | % New part begins
C4 E4 | F4 G2B,2 | A,4 G4-  | G4 z4 |
G,4 B,4 | A,2C2 E4 | D2F2_B,2D2 |1 B,4 :|2 B,4 z4 |] % Repeat sign with 1st and 2nd endings



X:1
T:Pop Brass Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Bb
V:1 clef=treble name="Trumpet" subname="Trp."
%%MIDI program 56
|:"Bb"D4 F4 | G4 E4 | F4 D4 | G4 z4 |
B4 d4 | c4 B4 | A4 G4 | F4 z4 |
D4 (3FGA B4 | c4 B4 | A4 G4 | "F7"F4 z4 |
"Eb"G4 E4 | F4 D4 | "Bb/Eb"E4 C4 |1 "F7"F4 z4 :|2 "F7"F8 |]
V:2 clef=treble name="Trombone" subname="Trb."
%%MIDI program 57
|:"Bb"B,4 B,4 | C4 A,4 | B,4 B,4 | C4 z4 |
F4 F4 | E4 D4 | C4 B,4 | B,4 z4 |
B,4 (3CBC D4 | E4 D4 | C4 B,4 | "F7"A,4 z4 |
"Eb"G,4 E4 | F4 D4 | "Bb/Eb"B,4 G,4 |1 "F7"A,4 z4 :|2 "F7"A,8 |]
V:3 clef=bass name="Tuba" subname="Tba."
%%MIDI program 58
|:"Bb"B,,4 B,,4 | B,,4 B,,4 | B,,4 B,,4 | B,,4 z4 |
F,,4 F,4 | F,4 F,4 | E,,4 E,4 | E,4 z4 |
D,4 D,4 | D,4 D,4 | C,4 C,4 | "F7"F,,,4 z4 |
"Eb"G,,4 G,4 | G,4 G,4 | "Bb/Eb"B,,,4 B,,4 |1 "F7"F,,,4 z4 :|2 "F7"F,,,8 |]



X:1
T:Colors of Spring - Extended
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=140
K:D
V:1
|:"D" FAdA z2 FA| "G" G2 z2 BAGF|"Em" E2G2 z2 F2|"A7" A4- A2 z2|
"D" fdA2 z2 f2|"Bm" b4 z2 a2|"G" g2fe z2 "A7" ce|"D" d4- d2 z2:|
|:"A" e2c2 E2C2| "D" F2D2 F2AF| "Bm" g2f2 g2bg| "A7" a4 a2z2|
"G" G2B2 d2G2| "D/A" A2F2 A2d2| "Em" G2E2 C2E2| "A7" A4- A2 z2:|
| "D" FDFA d2F2|"G" GBAG B2d2|"Em" E2EF G2A2|"A7" c2B2 A4|
"D" d2df a2fd|"G" b2a2 g2f2|"A7" e2c2 A2c2|"D" d8:|
V:2
|:"D"D,4 F,4|"G"G,4 B,4|"Em"E,4 G,4|"A7"A,4 C4|
"D"D,4 F,4|"Bm"B,4 D4|"G"G,4 B,4|"A7"A,4 E,4:|
|:"A"C4 E,4|"D"F4 A,4|"Bm"D4 F4|"A7"E4 C4|
"G"B,4 D4|"D/A"A,4 E4|"Em"G,4 B,4|"A7"E4 A,4:|
| "D" D,4 A,4|"G" B,4 D4|"Em" F,4 A,4|"A7" E,4 C4|
"D" D,4 F,4|"G" G,4 B,4|"A7" A,4 C4|"D" D,8:|



X:1
T:Final Confrontation
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=144
K:Bm
V:1 clef=treble name="Trumpet" subname="Lead"
%%MIDI program 56
|:"Bm" B4 d2 | "A" c4 A2 | "G" B4 G2 | "F#7" A4 F2 |
"Em" G6 | "A" F4 E2 | "D" ^A,4 A2 | "Bm" B4 z2 :|
V:2 clef=treble name="French Horn" subname="Counter"
%%MIDI program 61
|:"Bm" d2 f2 b2 | "A" e2 c'2 e2 | "G" g2 b2 d'2 | "F#7" a2 ^f2 a2 |
"Em" g2 B2 e2 | "A" f2 A2 c'2 | "D" ^A,2 C2 E2 | "Bm" [B4d2] z2 :|
V:3 clef=treble name="Strings" subname="Harmony"
%%MIDI program 48
|:B,6 | A,6 | G,6 | F#6 |
E4 G2 | A4 F2 | D6 | B,4 z2 :|
V:4 clef=bass name="Bassoon" subname="Bass"
%%MIDI program 70
|:"Bm" B,4 B,2 | "A" A,4 C2 | "G" G,4 B,2 | "F#7" F#4 A,2 |
"Em" E4 E,2 | "A" A,4 C2 | "D" D,4 F#2 | "Bm" B,4 z2 :|



X:1
T:Temple of the Hidden Rite
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=50
K:Emin modal
V:1 clef=treble name="Choral Pad"
%%MIDI program 92
|: "Em"G2 B2 A2 | "D" F3 G F2 | "Am" E4 z2 | "Em" B,3 C E2 |
"Em" G2 B2 A2 | "D" F3 G F2 | "Am" E4 z2 | "B" B3 ^A B2 :|
|: "Em" E2 G2 F2 | "C" G3 A G2 | "G" D4 z2 | "Am" C3 B, C2 |
"Em" E2 G2 F2 | "C" G3 A G2 | "G" D4 z2 | "D" F3 E F2 :|
V:2 clef=bass name="Pipe Organ"
%%MIDI program 19
|: "Em"E2 z2 E2 | "D"F2 z2 D2 | "Am"A2 z2 E2 | "Em"B,2 z2 G,2 |
"Em"E2 z2 E2 | "D"F2 z2 D2 | "Am"A2 z2 E2 | "B"B,2 z4 :|
|: "Em"E2 z2 G,2 | "C"C2 z2 G,2 | "G"G,2 z2 B,2 | "Am"A,2 ^C2 D2 |
"Em"E2 z2 G,2 | "C"C2 z2 E2 | "G"G,2 z2 B,2 | "D"D2 F2 A2 :|



X:1
T:Sailing into the Sunset
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=84
K:Gmaj
V:1 clef=treble
%%MIDI program 73
%%MIDI chordvol 60
%%MIDI gchord fzbz
|: "G"D2 | "G"B3A G2 | "Am"A4 F2 | "D7"F3E D2 | "G"G6 |
"G"B3c d2 | "Em"B4 A2 | "C"E3D E2 | "D7"D6 |
"D7"A3B c2 | "C"E4 D2 | "G"B3A G2 | "Am"A6 |
"D7"F3G A2 | "G"B4 A2 | "C"E3D E2 | "G"G4 z2 :|
V:2 clef=treble
%%MIDI program 48
%%MIDI chordvol 50
%%MIDI gchord fzbz
|: "G"G,2 | "G"D3E G,2 | "Am"C4 A,2 | "D7"F3G D,2 | "G"G,6 |
"G"D3E G,2 | "Em"E4 G,2 | "C"C3D E2 | "D7"D,6 |
"D7"A,3B c2 | "C"E4 G,2 | "G"D3E G,2 | "Am"A,6 |
"D7"F3G A,2 | "G"B,4 G,2 | "C"C3D E2 | "G"G,4 z2 :|
V:3 clef=bass
%%MIDI program 43
%%MIDI chordvol 40
%%MIDI gchord fzbz
|: "G"D,2 | "G"G,,3A, D,2 | "Am"A,,4 E,2 | "D7"F,3G, D,2 | "G"G,,6 |
"G"G,,3A, D,2 | "Em"B,,4 G,,2 | "C"C3D, G,2 | "D7"D,,6 |
"D7"A,,3B, c2 | "C"E4 G,,2 | "G"G,,3A, D,2 | "Am"A,,6 |
"D7"F,3G, A,2 | "G"B,4 F,2 | "C"C3D, G,2 | "G"G,,4 z2 :|



X:1
T:Clockwork Carnival
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=144
K:Cmaj
V:1 clef=treble name="Marimba"
%%MIDI program 12
|:"C" g4 e2f2 | "Dm" a2f2 d4 | "Em" b2ag e2^c2 | "F" d2G2 G4 |
"G" e4 =c2d2 | "Am" f2g2 a2e2 | "Bdim" b2af g2fe | "C"G6 z2 :|
|:"C" e2e2 d2d2 | "G"B2c2 d2B2 | "Am"c4 A2c2 | "F"d2G2 G2F2 |
"G" e2c2 B2G2 | "Em" G2B2 E4 | "A"B2A2 G2^F2 | "Dm" A2d2 f4 |
"F" G2A2 A2^G2 | "Em" F2B2 e4 | "G" d2B2 G4 | "C" e6 z2 :|
V:2 clef=treble name="Accordion"
%%MIDI program 21
|:"C" c4 G2A2 | "Dm" B2G2 E4 | "Em" G2^F2 G2E2 | "F" A,2C2 C4 |
"G" G2G2 D2E2 | "Am" c2d2 ^A2G2 | "Bdim" B2c2 A2^F2 | "C"E6 z2 :|
|:"C" G,G2 G2E2 z | "G"^F2D2 G,2B,2 | "Am" A,2C2 E2A,2 | "F" A2c2 c4 |
"G" d2B2 G2D2 | "Em" E2G2 B,2B,2 | "A" c2B2 A2G2 | "Dm" ^F2A2 d4 |
"F" C2C2 D2D2 | "Em" B2B2 E2E2 | "G" G2D2 D4 | "C" G,2C2 G,4 :|
V:3 clef=bass name="Tuba"
%%MIDI program 58
|:"C" C,4 z2E2 | "Dm" D,4 z2A,2 | "Em" E,4 z2B,2 | "F" F,2C2 C4 |
"G" G,4 z2D2 | "Am" A,4 z2E2 | "Bdim" B,,4 z2F,2 | "C" C,6 z2 :|
|:"C" G,,4 G,4 | "G" D,4 E,4 | "Am" E,4 A,4 | "F" C2F,2 F,4 |
"G" G,4 z2B,2 | "Em" B,4 E,4 | "A" E,2A,2 A,4 | "Dm" D,4 F,4 |
"F" F,4 F4 | "Em" E,4 E,4 | "G" D4 G,4 | "C" C,6 z2 :|



X:1
T:Utopian Horizons
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=100
K:Cmaj
V:1 clef=treble name="Lead Synth" subname="Melody"
%%MIDI program 81
|:"Cmaj7"e4 e4 [g4]  | "Am7"a4 a4 [c'4]  | "Dm9"f4 f4 [a4]  | "G13"B4 B4 [d4]  |
"Cmaj7"[e2g2]4 [e2g2]4 [c2e2g2e2]4 | "Am7"[a2c'2]4 [a2c'2]4 [e2a2c'2a2]4 | "Dm9"[f2a2]4 [f2a2]4 [d2f2a2f2]4 | "G13"[B2d2]4 [B2d2]4 [B2g2d2B2]4 |
"Cmaj7"[c2e2]4 [g2c'2]4 [e4g4c'4]2 | "Am7"[a2c'2]4 [e2a2]4 [c'4e4a4]2 | "Dm9"[d2f2]4 [a2d2]4 [f4a4d4]2 | "G13"[g2B2]4 [d2g2]4 [B4d4g4]2 |
"Cmaj7"[c4e4g4] [g4c'4e'4] [e4g4c'5] [] | "Em7"[e4g4b4] [b4e'4g'4] [g4b4e'5] [] |
"Fmaj7"[f4a4c'4] [a4f'4a'4] [c'4f'4a'5] [] | "G13"[g4B4d4] [g4B4d4] [B4d4g5] [] :|
V:2 clef=treble name="Piano" subname="Accompaniment"
%%MIDI program 0
|:"Cmaj7"[e2g2c'2e'2]4 z4 | "Am7"[a2c'2e'2a'2]4 z4 | "Dm9"[d2f2a2d'2]4 z4 | "G13"[g2B2d2g'2]4 z4 |
"Cmaj7"[c2e2g2c'2]4 z4 | "Am7"[a2e2a2c'2]4 z4 | "Dm9"[d2f2a2d'2]4 z4 | "G13"[g2d2g2B2]4 z4 |
"Cmaj7"[c2e2g2c'2]4 z4 | "Am7"[a2c'2e'2a'2]4 z4 | "Dm9"[d2f2a2d'2]4 z4 | "G13"[g2B2d2g'2]4 z4 |
"Cmaj7"[c2e2g2c'2]4 z4 | "Em7"[e2g2b2e'2]4 z4 | "Fmaj7"[f2a2c'2f'2]4 z4 | "G13"[g2B2d2g'2]4 z4 :|
V:3 clef=bass name="Synth Bass" subname="Bass"
%%MIDI program 38
|:"Cmaj7"c4 z12 | "Am7"a4 z12 | "Dm9"d4 z12 | "G13"g,4 z12 |
"Cmaj7"c4 z12 | "Am7"a,4 z12 | "Dm9"d4 z12 | "G13"g,4 z12 |
"Cmaj7"c,4 z12 | "Am7"a,4 z12 | "Dm9"d4 z12 | "G13"g,4 z12 |
"Cmaj7"c,4 z12 | "Em7"e,4 z12 | "Fmaj7"f,4 z12 | "G13"g,4 z12 :|
V:4 clef=treble name="Pad" subname="Harmony"
%%MIDI program 92
|:"Cmaj7"[ceg'c'e']4 [ceg'c'e']4 [ceg'c'e']4 [ceg'c'e']4 z2 | "Am7"[ace'a'c']4 [ace'a'c']4 [ace'a'c']4 [ace'a'c']4 z2 | "Dm9"[dfa'd'f']4 [dfa'd'f']4 [dfa'd'f']4 [dfa'd'f']4 z2 | "G13"[gbd'g'b']4 [gbd'g'b']4 [gbd'g'b']4 [gbd'g'b']4 z2 |
"Cmaj7"[cegc'e']4 [cegc'e']4 [cegc'e']4 [cegc'e']4 z2 | "Am7"[aec'e'a']4 [aec'e'a']4 [aec'e'a']4 [aec'e'a']4 z2 | "Dm9"[dfad'f']4 [dfad'f']4 [dfad'f']4 [dfad'f']4 z2 | "G13"[gbdg'b']4 [gbdg'b']4 [gbdg'b']4 [gbdg'b']4 z2 |
"Cmaj7"[cegc'e']4 [cegc'e']4 [cegc'e']4 [cegc'e']4 z2 | "Am7"[aec'e'a']4 [aec'e'a']4 [aec'e'a']4 [aec'e'a']4 z2 | "Dm9"[dfad'f']4 [dfad'f']4 [dfad'f']4 [dfad'f']4 z2 | "G13"[gbdg'b']4 [gbdg'b']4 [gbdg'b']4 [gbdg'b']4 z2 |
"Cmaj7"[cegc'e']4 [cegc'e']4 [cegc'e']4 [cegc'e']4 z2 | "Em7"[egbe'g']4 [egbe'g']4 [egbe'g']4 [egbe'g']4 z2 | "Fmaj7"[acf'a'c']4 [acf'a'c']4 [acf'a'c']4 [acf'a'c']4 z2 | "G13"[gbdg'b']4 [gbdg'b']4 [gbdg'b']4 [gbdg'b']4 z2 :|



X:1
T:Manifold Markets Theme
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Gmix
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"G"G2 B2 d3 c|"C"B8| "Am"A2 ^c2 e3 d|"D"d6 B2|
"G"B2 d2 G3 F|"C"E8| "Am"A2 e2 c3 B|"D"A6 G2|
"G"G2 B2 d3 c|"C"B8| "Am"A2 ^c2 e3 d|"D"d6 B2|
"G"B3 c B2 A2|"C"G6 F2|"D" A4 G4|"G"G8:|
V:2 clef=treble name="Chords Synth" subname="Chords"
%%MIDI program 88
|:"G"d2 z2 B2 z2|"C"e2 z2 G2 z2| "Am"^c2 z2 e2 z2|"D"f2 z2 A2 z2|
"G"d2 z2 B2 z2|"C"e2 z2 G2 z2| "Am"^c2 z2 e2 z2|"D"A2 z2 G2 z2|
"G"d2 z2 B2 z2|"C"e2 z2 G2 z2| "Am"^c2 z2 e2 z2|"D"f2 z2 A2 z2|
"G"d3 e d2 B2|"C"e2 g2 e2 c2|"D"f2 g2 f2 d2|"G"G8:|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|:"G"G,2 G,4 z2|"C"C,2 C,4 z2| "Am"A,2 A,4 z2|"D"D,2 D,4 z2|
"G"G,2 G,4 z2|"C"C,2 C,4 z2| "Am"A,2 A,4 z2|"D"D,2 D,4 z2|
"G"G,2 G,4 z2|"C"C,2 C,4 z2| "Am"A,2 A,4 z2|"D"D,2 D,4 z2|
"G"G,4 F,4|"C"E,4 D,4|"D"D,2 G,2 D,4|"G"G,8:|



X:1
T:Whispers of the Ancient Forest
C:OrchestrAI
M:C
L:1/8
Q:1/4=90
K:Em
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "Em"B3 A G2 F2 | E4-F2 G2 | "Am"A4 A2 B2 | c6 B2 |
"A"G2 (3AGF E2 D2 z2 | "Em"C6 B,2 | "Bm"D3 E F2 G2 |1 "Em"E6 z2 :|2 "Em"E4 G2 F2 ||
|: "C"e4 e2 g2 | "G"f3 e d2 B2 | "Am"c4 A2 B2 | "Bm"B6 A2 |
"G"G2 (3BAG "D"A3 G z2 | "C"F4 E2 D2 | "Am"E4 c2 B2 |1 "G"G4 F2 G2 :|2 "G"G4 z2 z2 |
[| "C"e4 (3fef g2 z2 | "Bm"f4 (3fga b2 z2 | "Am"a4 a2 g2 | "Em"f6 e2 |
"E"e4 e2 g2 | "C"c4 B2 A2 | "Em"B6- z2 | "Bm"B3 A B2 ^G2 | "Em"E4 z2 z2 |]
V:2 clef=treble name="Harp"
%%MIDI program 46
|: "Em"E2 B,2 E3 F | G4 G2 A2 | "Am"A2 E2 A3 B | c6 c2 |
"A"e2 a2 a2 g2 | "Em"e4 d2 B,2 | "Bm"D2 F2 B3 A |1 "Em"G4 E2 z2 :|2 "Em"G2 E2 F2 G2 ||
|: "C"c2 G2 c3 e | "D"d4 A2 F2 | "Am"A2 E2 C3 E | "Bm"F6 E2 |
"G"G2 D2 G2 D2 | "D"F4 E2 C2 | "Am"A2 E2 A3 G |1 "G"B4 A2 B2 :|2 "G"B4 z2 z2 |
[| "C"c4 c2 G2 | "Bm"B4 B2 F2 | "Am"A2 E2 A2 F2 | "Em"G6 E2 |
"E"e4 B2 e2 | "C"c6 A2 | "Em"G2 E2 B,2 E2 | "Bm"F4 D2 F2 | "Em"E4 z2 z2 |]
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "Em"E,4 E,2 G,2 | A,4 B,2 C2 | "Am"A,2 E2 A2 A,2 | B,4 C2 D2 |
"A"E2 A2 G2 F2 | "Em"E4 D2 B,2 | "Bm"D2 B,2 F2 D2 |1 "Em"E4 E,2 z2 :|2 "Em"E4 G2 A2 ||
|: "C"C4 G,2 E2 | "G"B,4 D2 B,2 | "Am"A,4 C2 A,2 | "Bm"B4 B,2 E2 |
"G"G2 E2 G2 D2 | "D"F4 E2 C2 | "Am"A,4 E2 A,2 |1 "G"B,4 A,2 B,2 :|2 "G"B,4 z2 z2 |
[| "C"C4 C2 E2 | "Bm"B,4 D2 F2 | "Am"A,4 C2 A,2 | "Em"E4 E,2 G,2 |
"E"E2 B,2 E2 E2 | "C"C4 A,2 G,2 | "Em"B,4 E4  | "Bm"B,2 F2 B2 ^G2 | "Em"E,4 E,2 z2 |]



X:1
T:Cinematic Battle for Transcendence
C:OrchestrAI
M:C
L:1/8
Q:1/4=140
K:Am
V:1 name=Violins1 clef=treble
%% MIDI program 48
|:"Am"c4 e4|a6 gf|"F"e4 e4|f6 ed|"G"dB4 dB2|cB4 c2 B|"Em"c4 c3B|A6 z2:|
|:"Am"A4 c4|e4 e2 f2|"F"e6 f2|e6 d2|"G"d4 B4|B4 A2 G2|"Em"G4 G4|E6 z2:|
V:2 name=Violins2 clef=treble
%% MIDI program 48
|:"Am"e2 c4 e2|a4 z2 gf|"F"f2 e4 f2|d4 z2 e2|"G"B2 d4 B2|"G"d4 c2 B2|"Em"e2 c4 B2|B6 z2:|
|:"Am"A2 c4 A2|E4 F2 E2|"F"F2 A4 G2|E4 D2 E2|"G"d2 B4 d2|"G"dB4 A2 G|"Em"G2 B2 E2 G2|B6 z2:|
V:3 name=Violas clef=alto
%% MIDI program 41
|:"Am"A3 B A2 E2|c6 BA|"F"F3 G F2 C2|e6 dc|"G"B,3 D B,4|G,3 B, G,4|"Em"E3 G E4|B,6 z2:|
|:"Am"A,4 A,4|A,3 C E4|F2 F4 G2|F4 E2 D2|"G"B,4 B,4|B,2 G,4 A2|E4 E4|E6 z2:|
V:4 name=Cellos clef=bass
%% MIDI program 42
|:"Am"A,2 C4 E2|A,4 z2 BA,|"F"F,2 A,4 C2|F,4 z2 dc,|"G"B,,2 D4 B,2|"G"d4 cB, A,2|"Em"E,2 G,4 E2|E,6 z2:|
|:"Am"A,2 A,2 E2 E2|E2 E4 F2|A,2 A,4 G2|A,4 F2 E2|"G" B,,2 D4 z B,|G4 F2 E2|"Em"E,4 E4|E,6 z2:|
V:5 name=Contrabasses clef=bass
%% MIDI program 43
|:"Am"A,,2 A,,2 A,2 C2|A,6 BA,|"F"F,,2 F,2 A,2 C2|F,6 dc,|"G"B,,4 D4 B,|"G"d4 cB,A,2|"Em"E,,4 E,4|E,,6 z2:|
|:"Am"E,,4 A,,,4|A,,,3 E, E,2 C2|F,,2 F,4 G,2|F,4 E,2 D,2|"G"B,,4 B,4|B,2 G,4 A,,2|E,,4 E,4|E,,6 z2:|



X:1
T:Brass Pop Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:C
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|: "C" G4 E2 G2 | "F" A4 F2 A2 | "G" B4 G2 B2 | "C" c2 B2 A2 G2 |
"Am" A3 G E2 C2 | "Dm" F4 D2 F2 | "G" B2 G2 E2 D2 |1 "C" C6 z2 :|2 "C" C4 E2 G2 ||
|: "C" c4 c2 BA | "G/B" B4 B2 AG | "Am" A3 G E2 C2 | "F" F4 D2 F2 |
"C" c4 c2 BA | "G/B" B4 B2 AG | "Am" A3 G E2 C2 |1 "G" G6 z2 :|2 "G" G4 ^F2 G2 ||
V:2 clef=treble name="Trombone"
%%MIDI program 57
|: "C" E4 C2 E2 | "F" c4 A2 c2 | "G" d4 B2 d2 | "C" c2 B2 A2 G2 |
"Am" c3 B A2 E2 | "Dm" A4 F2 A2 | "G" B2 G2 E2 C2 |1 "C" E6 z2 :|2 "C" E4 C2 E2 ||
|: "C" e4 e2 dc | "G/B" B4 B2 AG | "Am" A3 G E2 C2 | "F" A4 F2 A2 |
"C" e4 e2 dc | "G/B" B4 B2 AG | "Am" A3 G E2 C2 |1 "G" B6 z2 :|2 "G" B4 A2 B2 ||
V:3 clef=bass name="Tuba"
%%MIDI program 58
|: "C" C,4 G,2 C2 | "F" F,4 C2 F2 | "G" G,4 D2 G2 | "C" C2 B,2 A,2 G,2 |
"Am" A,3 G, C2 G,2 | "Dm" D4 A,2 D2 | "G" G,2 D2 B,2 A,2 |1 "C" C,6 z2 :|2 "C" C,4 G,2 C2 ||
|: "C" C4 C2 B,A, | "G/B" G,4 G,2 F,E, | "Am" A,3 G, C2 E,2 | "F" F4 C2 F2 |
"C" C4 C2 B,A, | "G/B" G,4 G,2 F,E, | "Am" A,3 G, C2 E,2 |1 "G" G,6 z2 :|2 "G" G,4 F,2 G,2 ||



X:1
T:Pirate Polka Jig
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=120
K:Ddor
V:1 clef=treble name="Accordion"
%%MIDI program 21
|: "Dm"A2F2 A2F2| "C"G4 E4 | "Dm"A2d2 c2A2 | "A7"G4 E4 :|
"Dm"D2F2 A2d2 | "C"G2E2 G2E2 | "Dm"A2d2 f2a2|"A7"e4 d4|
"Dm"A2F2 A2F2| "C"G4 E4 | "Dm"A2d2 c2A2 | "A7"G4 E4 |
"Dm"f2a2 a2gf| "C"e2c2 c2AG|"Dm"F2D2 D2FA|"A7"d8:|
V:2 clef=treble name="Violin"
%%MIDI program 40
|: "Dm"a2a2 f2d2| "C"g2e2 e2c2| "Dm"a4 f2d2| "A7"g4 e4 :|
"Dm"d2fd A2fa| "C"e2ce G2eg| "Dm"a2f2 d2fd|"A7"A4 G4|
"Dm"a2a2 f2d2| "C"g2e2 e2c2| "Dm"a4 f2d2| "A7"g4 e4 |
"Dm"a4 a2ga| "C"g2e2 e2G2|"Dm"d3c d2F2|"A7"A4 G4:|
V:3 clef=bass name="Bass"
%%MIDI program 33
|: "Dm"D,4 D,4| "C"C,4 C,4| "Dm"D,4 D,4| "A7"A,4 A,4 :|
"Dm"D,2F,2 D,2F,2| "C"C,2G,2 C,2G,2| "Dm"D,4 D,4| "A7"A,4 A,4|
"Dm"D,4 D,4| "C"C,4 C,4|"Dm"D,4 D,4| "A7"A,4 A,4 |
"Dm"D,2A,2 D,2F,2| "C"C,2G,2 C,2E,2| "Dm"D,4 D,4|"A7"A,4 A,4:|



X:1
T:Tales of the Mystic Alehouse
C:OrchestrAI
M:6/8
L:1/8
Q:1/4=100
K:Gdor
V:1 clef=treble name="Lute" subname="Lute"
%%MIDI program 76
|: "Gm"D2G GAB | A2D DED | "Cm"C3 EFG | "D"D3 D2C |
"Gm"B2G GAB | A2D DED | "F"F3 "Dm"A2G |1 "Gm"G3 G2A :|2 "Gm"G3 G3 ||
V:2 clef=treble name="Recorder" subname="Rec."
%%MIDI program 74
|: "Gm"G2B cde | "A7"dcA A2G | "Cm"C2E FGA | "Adim"BAG F2E |
"Gm"D2G GAB | "Cm"cdc "D7"A2F | "G"G3 "Dm"A2G |1 "Gm"G3 G2A :|2 "Gm"G3 G3 ||
V:3 clef=treble name="Choir Pad" subname="Pad"
%%MIDI program 91
|: "Gm"G,3 G,3 | G,3 G,3 | "Cm"G,3 G,3 | "D"A,3 A,3 |
"Gm"G,3 G,3 | "Cm"E,3 E,3 | "F"C3 C3 |1 "Gm"G,3 G,3 :|2 "Gm"G,3 G,3 ||
% Repeat the lute and recorder but with modifications to add variation
V:1
|: "Gm"D2G G2A | B2D DED | "Cm"C2E EFG | "D"D3 DEF |
"Gm"G2B BAG | A2D DED | "F"F3 "Dm"A2G |1 "Gm"G3 G2A :|2 "Gm"G3 G3 ||
V:2
|: "Gm"G2B d2c | "A7"dcA A2G | "Cm"E2G FGA | "Adim"BAG F2E |
"Gm"D2G GAB | "Cm"cdc "D7"A2F | "G"G3 "Dm"A2G |1 "Gm"G3 G2A :|2 "Gm"G3 G3 ||
V:3
|: "Gm"D,3 D,3 | D,3 D,3 | "Cm"E,3 E,3 | "D"F,3 F,3 |
"Gm"D,3 D,3 | "Cm"G,3 G,3 | "F"A,3 A,3 |1 "Gm"D,3 D,3 :|2 "Gm"D,3 D,3 ||



X:1
T:Harmony of the Hearth
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:G
V:1 clef=treble name="Flute" subname="Flt"
%%MIDI program 73
|: "G" D2 B2 G2 | "Em" E4 A2 | "C" G2 E2 C2 | "D" D6 |
"G" B3 A G2 | "C" E4 C2 | "G" B2 A2 G2 |1 "D" D6 :|2 "D" D4 z2 |
V:2 clef=treble name="Acoustic Guitar" subname="Gtr"
%%MIDI program 24
|: "G" G,2 B,2 G,2 | "Em" E3 A, E2 | "C" C2 E2 C2 | "D" D2 A,2 F2 |
"G" G,2 B,2 G,2 | "C" E3 G, E2 | "G" G,2 B,2 G,2 |1 "D" D2 A,2 F2 :|2 "D" D4 z2 |
V:3 clef=bass name="Double Bass" subname="Bass"
%%MIDI program 43
|: "G" D,4 G,2 | "Em" E,4 A,2 | "C" C,4 G,2 | "D" F,4 D2 |
"G" D,4 G,2 | "C" E,4 C,2 | "G" D,4 G,2 |1 "D" F,4 D2 :|2 "D" D4 z2 |



X:1
T:Christmas Frenzy
C:OrchestrAI
M:2/4
L:1/8
Q:1/4=160
K:Am
V:1 clef=treble
%%MIDI program 74
|: "Am"A>A E2 | "G"G>A B2 | "F"EF "E7"G>F | "Am"A4 |
"Am"A2 ^c2 | "C"ed "G"Bc | "Am"A>G "E7"E>F |1 "Am"A4 :|2 "Am"A3 z |
|: "C"c>B "G"B>A | "F"A>G "E7"G>F | "Am"A^c "Dm"de | "E7"e2 f2 |
"G"g>f "C"e>d | "F"f>e "D7"d2 | "Am"ec "G"Bd |1 "Am"A4 :|2 "Am"A3 z |]
V:2 clef=bass
%%MIDI program 42
|: "Am"A,>A, C2 | "G"B,2 D2 | "F"C2 "E7"B,2 | "Am"A,4 |
"Am"A,2 A,2 | "C"C2 "G"G,2 | "Am"A,>C "E7"E2 |1 "Am"A,4 :|2 "Am"A,3 z |
|: "C"c2 "G"B,2 | "F"C2 "E7"B,>c | "Am"A,2 "Dm"d2 | "E7"e2 f2 |
"G"g2 "C"c2 | "F"f2 "D7"d2 | "Am"A,2 "G"G,2 |1 "Am"A,4 :|2 "Am"A,3 z |]



X:1
T:Nocturne for a Sleeping City
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=60
K:Am
V:1 clef=treble
%%MIDI program 1
|: "Am"A4 e4 | "E"e4 z2 B2 | A4 ^G4 | "A7"A6 z2 |
"Am"A4 e4 | "C"e4 z2 c2 | "G"B4 A4 |1 "Am"A6 z2 :|2 "Am"A6 A2 ||
|: "F" c4 c4 | "C"e4 z2 E2 | "Dm"d4 c4 | "E7"B6 z2 |
"F"c4 c4 | "C"e4 z2 e2 | "Am"A4 ^G4 |1 "E7"E6 A2 :|2 "E7"E6 z2 ||
V:2 clef=treble
%%MIDI program 1
|: "Am"E4 C4 | "E"B,4 z2 G,2 | E4 F4 | "A7"E6 z2 |
"E"E4 A,4 | "C"C4 z2 E2 | "G"G4 F4 |1 "Am"E6 z2 :|2 "Am"E6 E2 ||
|: "F"F4 F4 | "C"E4 z2 C2 | "Dm"D4 E4 | "E7"A,6 z2 |
"F"F4 F4 | "C"G4 z2 c2 | "Am"A4 F4 |1 "E7"B,6 E2 :|2 "E7"B,6 z2 ||
V:3 clef=bass
%%MIDI program 1
|: "Am"A,4 E3 z | "E"E,4 z2 E2 | A,4 ^G,4 | "A7"E3 ^C2,D2 z |
"Am"A,4 E4 | "C"C4 E2 A,2 | "G"B,4 A,4 |1 "Am"A,6 z2 :|2 "Am"A,6 A,2 ||
|: "F"F4 F4 | "C"E3 C2 E2 z | "Dm"D4 F4 | "E7"E,6 z2 |
"F"F4 A,3 z | "C"C4 E2 C2 | "Am"A,4 ^G,4 |1 "E7"B,6 E2 :|2 "E7"B,6 z2 ||



X:1
T:The Enchanted Library
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=90
K:Em
V:1 clef=treble name="Harpsichord" subname="Hpschd."
%%MIDI program 6
|: "Em" B4 (Bc) | "D" A4 (AG) | "G" F3 G E2 | "C" B,4 (DE) |
"Am" E3 F G2 | "Bdim" B,4 A,2 | "E7" G4 F2 | "Am" A6 :|
|: "C" E4 (F^G) | "Am" A3 B c2 | "Em/B" B4 z2 | "A7/C#" D4 (DC) |
"D" F4 (FE) | "G" G3 A B2 | "Em" E6- | "Em" E4 z2 :|
V:2 clef=treble name="Violin" subname="Vln."
%%MIDI program 40
|: "Em" e4 (ef) | "D" d4 (dc) | "G" B3 A G2 | "C" c4 (Bc) |
"Am" A3 G F2 | "Bdim" ^F4 E2 | "E7" G4 (FE) | "Am" A6 :|
|: "C" c4 (d^e) | "Am" A3 B (cA) | "Em/B" B4 (B2) | "A7/C#" D4 (dc) |
"D" d4 (df) | "G" G4 (GA) | "Em" e6- | "Em" e4 z2 :|
V:3 clef=bass name="Cello" subname="Clo."
%%MIDI program 42
|: "Em" B,4 (DC) | "D" A,4 (BA,) | "G" G,3 A, B,2 | "C" C4 DE |
"Am" A,4 (GF) | "Bdim" G,4 F,^F, | "E7" E,4 (DC) | "Am" A,6 :|
|: "C" C4 (DE) | "Am" A,4 (BA) | "Em/B" B,4 z2 | "A7/C#" [D2A,2] ([CE][DA]) |
"D" D4 DE | "G" G,4 A,2 | "Em" E,6- | "Em" E,4 z2 :|



X:1
T:Mystic Quest Overture
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:Em
V:1 clef=treble name="Strings"
%%MIDI program 48
|:"Em"B4 G4|"D"F4 A4|"G"B4 d3B|"C"E6 G2|
"A"E4 "D"F4|"G"GABc BAGE|"C"D4 "Bm"F4|"Em"E8:|
V:2 clef=treble name="Brass"
%%MIDI program 61
|:"Em"E4 G4|"D"F4 D4|"G"G3A Bcde|"C"G6 E2|
"A"e4 "D"A4|"G"g4 fgag|"C"e4 "Bm"d4|"Em"e8:|
V:3 clef=treble name="Woodwinds"
%%MIDI program 71
|:"Em"G2F2 E4|"D"A2G2 F4|"G"B2A2 G4|"C"E2G2 A2G2|
"A"E2A2 B2G2|"D"F2D2 E2F2|"G"G3A G4|"Em"E8:|
V:4 clef=bass name="Cellos Basses"
%%MIDI program 42
|:"Em"E,4 E,4|"D"D,4 D,4|"G"G,4 G,4|"C"C,4 C,4|
"A"A,4 A,4|"D"F,4 D,4|"G"G,4 B,4|"Em"B,8:|
[|:"Em" z2G2 (BABc) z4 |"G" (B2AG) G4 z4 |"G" (BAGF) E2D2 z4 |"C" C2E4 z2 |
"E"^D2 (EF) (GAB) |"Am" e4 cdef|"Em"g4- g2f2 |"B7" [B2d2]6 z2 z4 :|]



X:1
T:Call to Quest
C:OrchestrAI
M:6/8
L:1/8
Q:1/4=144
K:Em
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"Em"B2G E2F|G2E D3|"C"G2E C2D|E2G F3|"Am"A2E C2D|E2C B,3|"B"E2G F2D|1 "Em"E3-E3:|2 "Em"E3 z3||
V:2 clef=treble name="Arpeggio Synth" subname="Arp."
%%MIDI program 82
|:"Em" e3-e2B|d3-d2G|"C"e3-e2c|g3-g2E|"Am"c3-c2A|e3-e2C|"B"d3-d2B|1 "Em"e3-e3:|2 "Em"e3 z3||
V:3 clef=treble name="String Ensemble" subname="Strings"
%%MIDI program 48
|:"Em"G,3 B,3|E3 G,3|"C"C3 E3|G,3 C3|"Am"A,3 C3|E3 A,3|"B"B,3 D3|1 "Em"E3 E3:|2 "Em"E3 z3||
V:4 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|:"Em"B,,3 B,3|E,3 B,,3|"C"C,3 G,,3|E,3 C,3|"Am"A,,3 A,3|C3 A,,3|"B"B,,3 F,,3|1 "Em"E,3 E,3:|2 "Em"E,3 z3||
|:"Em"B2e e2B|"C"d2e c2A|"D"B2A A2F|"G"G3-F3|"A"A2c c2A|"B"B3-A3| "Em"G2A B2G|"Em"E3-E3:|



X:1
T:Triumphant Tones
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=112
K:C
% The trumpet part carries the main catchy melody.
V:1 clef=treble
%%MIDI program 56
"G"D2- D4 E2|"C"G,2- G,4 C2|"Am"E3- E F2 E2|"F"D2- D4 z2|
"G" B2- B4 c2|"C"A,2- A,4 E2|"Am"A3- A G2 F2|"F" E6 z2|
% The trombone adds the middle harmony.
V:2 clef=treble
%%MIDI program 57
"G"B,2 B,2 D3 E|"C"C2 C2 E3 G,|"Am"A,2 A,2 C3 E|"F"F,2 F,2 A,3 D|
"G"G,2 G,2 B,3 d|"C"E2 E2 G3 c|"Am"F3 F2 E3 D|"F"F,3 E D3 C|
% The tuba provides the bass foundation.
V:3 clef=bass
%%MIDI program 58
"G"G,4 D,4|"C"C,4 G,4|"Am"A,4 E,4|"F"F,4 C4|
"G"G,4 D,4|"C"C,4 A,4|"Am"A,4 E,4|"F"F,4 C4|
% Repeat the sections as desired, or use as a foundation to expand upon.



X:1
T:Whispers of the Old Forest
C:OrchestrAI
M:C
L:1/8
Q:1/4=90
K:Am
V:1 clef=treble
%%MIDI program 76
|: "Am"e2 A3 B | "E"^G6  | "Am"A2 ^G3 E | "Dm"F6  |
"Am"e3 d c2  | "Em"B3 ^G A2 | "F"=G4 F2 | E6 :|
"Am"A3 c e2 | "C"=G4 F2 | "Dm"F3 E D2 | "E"E4 ^G2 |
"Am"A3 c e2 | "G"E2 ^G3 A | "Am"A6  | "E"^G6 :|
V:2 clef=treble
%%MIDI program 74
|: "Am" A,3 C E2 | "E"B,6  | "Am"A,3 B, C2 | "Dm"D6  |
"Am"C3 D E2 | "Em"E6  | "F"F3 E D2 | "E"E3 A, B, C :|
"Am"A,2 E3 A, | "C"B,3 C D2 | "Dm"D3 C B,2 | "E"E3 E F2 |
"Am"A,2 E3 C | "G"=G3 ^G A2 | "Am"A,3 E A, z | "E"B,6 :|
V:3 clef=bass
%%MIDI program 70
|: "Am"A,4 .E.G | "E"B,4 .E.G | "Am"A,2 .A,.C .E.G | "Dm"D4 .F.A |
"Am"A,3 .C .E z | "Em"G,3 .B,E z | "F"F4 .A.C | "E"E4 z2 :|
"Am"A,3 .C.E z | "C"C4 .E.G | "Dm"D3 .F.D z | "E"E4 .B,G |
"Am"A,3 .C.E z | "G".B, .E3 z2 | "Am"A,4 z2 | "E"E4 z2 :|



X:1
T:Trombone Trio Capers
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Gm
%%Chord progression
V:1 clef=bass name="Trombone I"
%%MIDI program 57
|:"Gm" G,2 B,2 | D2 C2 | B,2 A,2 z2 | G3 A, |
"Intro" G2 A2 | A2 F2 | A2 G2 | E4 :|
V:2 clef=bass name="Trombone II"
%%MIDI program 57
|:"Gm" d4 | A2 c2 | A,2 | E4 :|
V:3 clef=bass name="Trombone III"
%%MIDI program 57
|:"Gm" G2 B,2 | D2 C2 | B,2 A,2 | A4 :|
"Chorus" G3 A, | E4 | G3 A, :|
%%Melody for first trombone
V:1 clef=treble name="Trombone I"
%%MIDI program 59
|:"Gm" G2 A2 | B2 A2 | G2 F2 | E4 :|
%%Melody for second trombone
V:2 clef=treble name="Trombone II"
%%MIDI program 31
|:"Gm" d4 | c2 B2 | E4 :|
%%Melody for third trombone
V:3 clef=treble name="Trombone III"
%%MIDI program 67
|:"Gm" b3 G3   :|
%%Accompanying harmonies
V:1 clef=piano name="Piano"
%%MIDI program 33
|:"Gm" G3 B2  :|
%%Dynamic markings
V:1 clef=piano name="Piano"
%%MIDI program 33
|:"Intro" pianissimo | pianoforte z :|
%%Articulations and ornaments
V:1 clef=piano name="Piano"
%%MIDI program 33
V1 articulato player pianissimo mf pianissimo sforzato | legato :|



X:1
T:Journey to the Horizon
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Gmin
% Reflecting initial struggles with a sense of hope underneath
V:1 clef=treble name="Piano" subname="Main Melody"
%%MIDI program 0
|"Gm"G2 A2 B2 d2|"Cm"c4 B2 A2|"D"d4 c2 B2|"D7"A4 G2 F2|
|"Gm"G3 A B2 G2|"Cm"c4 A2 G2|"Eb"e4 d2 B2| "D7"A4 F2 G2|
|"Gm"B2 d2 g2 f2|"Cm"e2 c2 A2 G2|"D7"f4 e2 d2|"Gm"d4 c2 B2|
% Transitioning into confidence and purpose
|"Bb"D2 F2 B2 A2|"Eb"G4- G2 F2|"D7"A4 c2 B2|"Gm"G4 z4|
V:2 clef=treble name="Strings" subname="Harmony"
%%MIDI program 48
|"Gm"G,4 B,4|"Cm"C4 E4|"D"F4 A4|"D7"A,4 C4|
|"Gm"G,3 A B2 G,2|"Cm"C4 C4|"Eb"E4 G4|"D7"A,4 F4|
|"Gm"G,2 B,2 d4|"Cm"C2 E2 G4|"D7"F4 A4|"Gm"G,2 B,2 D4|
|"Bb"B,2 D4 F2|"Eb"E4 G4|"D7"A,4 C4|"Gm"G,4 z4|
V:3 clef=bass name="Bass Guitar" subname="Bass"
%%MIDI program 33
|"Gm"D,4 G,,4|"Cm"G,4 C,4|"D"A,4 D,4|"D7"A,4 D,4|
|"Gm"B,,4 E,,4|"Cm"G,4 C,4|"Eb" B,,4 E,4|"D7"A,4 D,4|
|"Gm"G,,4 D,4|"Cm"A,,4 E,4|"D7"F,,4 A,,4|"Gm"D,4 G,,4|
% Achieving growth and success
|"Bb"F,4 B,,4|"Eb"G,,4 E,4|"D7"F,,4 A,,4|"Gm"G,,4 z4|



X:1
T:Boss Battle Theme
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=160
K:Cm
% Intro - Dramatic opening
V:1 clef=treble name="Violin 1"
%%MIDI program 40
|C2G, z42|"Cm" G2Eb2 G2D|"G7" Bb2D2 BbAG|"Cm" C2G,2 C2G, z42|
V:2 clef=treble name="Violin 2"
%%MIDI program 40
|C2G, z42|"Cm" Eb2C2 D2Bb,2|"G7" F2Bb,2 AGFE|"Cm" G,2C2 G,2C2|
V:3 clef=bass name="Cello"
%%MIDI program 42
|C,2G,, z42|"Cm" C,2G,,2 G,,2C,,2|"G7" G,,2D,,2 z D,F,G,|"Cm" C,2G,,2 C,2G,,2|
% A Section - Driving theme
V:1
|"Cm" c2d2 efg2|"G7" g2f2 e2d2|"Cm" c2B,2 A,2G,2|"G" G2B,2 c2d2|
V:2
|"Cm" g2g2 gfed|"G7" f2e2 d2c2|"Cm" g,2e2 d2c2|"G" B,2D2 EGFE|
V:3
|"Cm" C,2G,,2 C,2G,,2|"G7" G,,2D,,2 z D,F,G,|"Cm" C,2G,,2 G,,2C,,2|"G" G,,2B,,2 C,2D,2|
% B Section - Heroic, Eb Major
V:1
|"Eb" g2ab gfed|"Bb7" f2e2 d2c2|"Eb" g2c2 bb2ag|"Ab" ab2g2 f2e2|
V:2
|"Eb" c2c2 bb,ag|"Bb7" bb,ag fedc|"Eb" g2g2 fedc|"Ab" c'2bb, a2g2|
V:3
|"Eb" Eb,2Bb,,2 Eb,2Bb,,2|"Bb7" Bb,,2F,,2 F,D,Eb,|"Eb" Eb,2Bb,,2 Bb,,2Eb,,2|"Ab" Ab,2Eb,,2 F,2D,2|
% A' Section - More intense, C minor
V:1
|"Cm" c2d2 efg2|"G7" g2abb a2g2|"Cm" c2B,2 A,2G,2|"Ab" Ab2G2 F2Eb2|
V:2
|"Cm" g2g2 gfed|"G7" fedc c2B,A,|"Cm" g,2e2 d2c2|"Ab" c2B,2 z A,G,F,|
V:3
|"Cm" C,2G,,2 C,2G,,2|"G7" G,,2D,,2 D,F,G,A,|"Cm" C,2G,,2 G,,2C,,2|"Ab" Ab,2Eb,,2 F,2D,2|
% Coda - Triumphant, C minor
V:1
|"Cm" C2G,2 C2G,2|"G7" G2G2 G2G2|"Cm" C2.G,,2 z4 ||
V:2
|"Cm" G,2C2 G,2C2|"G7" G2G2 G2G2|"Cm" G,2.C,2 z4 ||
V:3
|"Cm" C,2G,,2 C,2G,,2|"G7" G,,2D,,2 G,,2D,,2|"Cm" C,2.G,,4 z2 ||



X:1
T:Pensive Waltz No. 1
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=60
K:Am
V:1 clef=treble name="Violin 1"
%%MIDI program 40
|:"Am"E2 A2 c2 | "E7"G3 A B2 | "Am"c3 B A2 | "Dm/F"F4 E2 | "Am"E2 A2 c2 | "E7"G3 A B2 | "Am"A4 G2 | "Am"A6 |
"A/C#"c2 E2 F2 | "Dm"G3 F E2 | "C"E3 C G2 | "F"F4 E2 | "A/C#"c2 E2 F2 | "Dm"G3 F E2 | "A7"E4 D2 | "Dm"D6 |
"C"G2 E2 G2 | "G7"B,3 C D2 | "C"E3 G c2 | "F"E3 G F2 | "C"E2 "G7"B,2 D2 | "C"G3 A G2 | "C"E4 D2 | "C"C6 :||
V:2 clef=treble name="Violin 2"
%%MIDI program 40
|:"Am"A4 z2 | "E7"^G3 A B2 | "Am"A3 G A2 | "Dm/F"F4 E2 | "Am"A3 E A2 | "E7"^G3 F G2 | "Am"A4 G2 | "Am"A3 B E2 |
"A/C#"E2 c2 E2 | "Dm"F3 E F2 | "C"G4 G2 | "F"F3 G A2 | "A/C#"E2 c2 E2 | "Dm"F3 G F2 | "A7"E4 E2 | "Dm"D3 E F2 |
"C"E4 E2 | "G7"D3 C D2 | "C"E3 D E2 | "F"D3 C D2 | "C"C4 C2 | "G7"D3 G, A,2 | "C"C4 B,2 | "C"C4 z2:|
V:3 clef=alto name="Viola"
%%MIDI program 41
|:"Am"c4 e2 | "E7"e3 d c2 | "Am"c3 B c2 | "Dm/F"A,4 A,2 | "Am"c3 e a2 | "E7"e3 d c2 | "Am"A4 G2 | "Am"A3 B e2 |
"A/C#"c4 f2 | "Dm"f3 e d2 | "C"G4 c2 | "F"E4 D2 | "A/C#"c4 f2 | "Dm"f3 e d2 | "A7"E4 C2 | "Dm"D,4 A,2 |
"C"c4 e2 | "G7"d3 c B2 | "C"c3 e g2 | "F"f3 e d2 | "C"c4 e2 | "G7"B,3 A G2 | "C"c4 B,2 | "C"c4 z2:|
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"Am"A,6 | "E7"B,,4 E2 | "Am"A,4 A,2 | "Dm/F"F,4 A,2 | "Am"A,4 E2 | "E7"B,,4 E2 | "Am"A,4 G,2 | "Am"A,6 |
"A/C#"C,4 C2 | "Dm"D,4 F,2 | "C"G,4 C2 | "F"F,4 A,2 | "A/C#"C,4 C2 | "Dm"D,4 F,2 | "A7"A,,4 E,2 | "Dm"D,6 |
"C"G,4 C2 | "G7"G,,4 B,,2 | "C"E,4 G,2 | "F"F,4 A,2 | "C"C4 E2 | "G7"D3 B,, D2 | "C"C4 G,2 | "C"C6:|



X:1
T:Trombone Trio Capers
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Gm
V:1 clef=bass name="Trombone I"
%%MIDI program 57
|:"Gm"G2 A2 | B2 A2 | G2 F2 | E4 |
D4 | C2 D2 | "D"F4 | F4 |
"A"C4 | E2 F2 | "D"A2 G2 | F2 E2 |
"Gm"G2 A2 | B2 A2 | G2 F2 | G4 :|
V:2 clef=bass name="Trombone II"
%%MIDI program 57
|:"Gm"d5  | d2 c3  | A2 G3  | G5  |
"D"A2 G2 | B2 c2 | D4 | "Cm"F4 |
"Gm"d4 B  | d2 c3  | A2 G3  | "F"c5  |
"D"A2 B2 | c2 d2 | c2 B2 | "Gm"G4 :|
V:3 clef=bass name="Trombone III"
%%MIDI program 57
|:"Gm"G,2 B,2 | D2 C2 | B,2 A,2 | G,4 |
"A" A,2 C2 | E2 D2 | C2 B,2 | A,4 |
"D" F,2 A,2 | F4 | E2 F2 | G2 F2 |
"Gm"G,2 B,2 | D2 G,2 | F2 E2 | "D5"D4 :|



X:1
T:Friday Night Expanded
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Bb
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:FGA | B2 ._A.B z2 GA | B2 ._A.B z4 |
w: Be-cause-its Fri-day night! And we're feel-in' right!
FGA | B2 ._A.B c2 FG | A2 ._A.B c2 z2 |
FGA | B2 ._A.B z2 GA | B2 ._A.B z4 :|
|:d2 c2 z4 | f2 e2 d2 f2 | g4 e4 |
d2 c2 z4 | f2 e2 d2 c2 | BB2 c2 f3 :|
V:2 clef=bass name="Bass"
%%MIDI program 39
|:z4 z4 | B,2 E2 G2 E2 | B,2 F2 G4 |
z4 z4 | B,2 E2 G2 E2 | F2 A2 B,4 :|



X:1
T:AI Fusion Fantasy
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:G
V:1 clef=treble name="Piano RH"
%%MIDI program 0
|:"G"G2 B2 d2 | "C"e4 g2 | "Am"c2 e2 a2 | "D7"f3 e d2 |
"G"B2 d2 gd | "Em"B2 A2 G2 | "C"E2 G2 c2 | "A7"e4 F2 |
"D7"A2 F2 A2 | "G"D4 G2 | "Bm"F2 A3 G | "Em"e2^d2 e2 |
"A7"f4 e2 | "D7"d2 F2 A2 | "C"G4 E2 | "G"D6 :|
V:2 clef=treble name="Piano LH"
%%MIDI program 0
|:"G"D,2 G,2 B,2 | "C"C2 E2 G,2 | "Am"A,2 E2 A2 | "D7"F2 A,2 D2 |
"G"G,2 B,2 d2 | "Em"E2 G,2 B,2 | "C"C2 E2 c2 | "A7"A,2 ^C2 E2 |
"D7"D2 A,2 F2 | "G"G,2 B,2 G2 | "Bm"B,2 D2 G2 | "Em"E2 ^D2 B,2 |
"A7"A,4 ^c2| "D7"A,2 F2 d2 | "C"C2 E2 G,2 | "G"D,2 B,2 G,2 :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"G"G,3 D, G, z | "C"C3 E C z | "Am"A,3 E A, z | "D7"D,3 A, D z |
"G"G,3 B, G, z | "Em"E3 G E z | "C"C2 E C z2 | "A7"A,3 ^C A, z |
"D7"D,2 F D, z2 | "G"G,3 B, G, z | "Bm"B,3 F B, z | "Em"E3 ^D E z |
"A7"A,3 e c z | "D7"D3 f a z | "C"C4 E z | "G"G,3 D, G, z :|



X:1
T:Digital Utopia Voyage
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:Cmaj
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|: "Cmaj9"e4c2de | "Fadd9" f2a2c'2a2 | "Dm7" a4f2ga | "G/B" b2d2g2f2 |
"Cmaj9"g2e2c2d2 | "Am7" e4 "Em7" g2f2 | "Fadd9" a6 g2 | "Gadd9" g4 "Cmaj9" e2 z2 :|
V:2 clef=treble name="Piano"
%%MIDI program 1
|: "Cmaj9"c2E2 G,2C2 | "Fadd9" F2A,2 C2F2 | "Dm7" D2F2 A,2D2 | "G/B" G,2B,2 D2G,2 |
"Cmaj9"C2E2 G,4 | "Am7" A,2C2 E2A,2 | "Fadd9" F4 F2A2 | "Gadd9" G,2E2 "Cmaj9" C4 :|
V:3 clef=treble name="Strings"
%%MIDI program 49
|: "Cmaj9"[e2G2][c2E2] z4 | "Fadd9" [c'2a2][f2a2] z4 | "Dm7" [a2f2][d2A2] z4 | "G/B" [b2g2][d2B2] z4 |
"Cmaj9"[g2e2][c2E2] z4 | "Am7"[e2c2][a2A2] z4 | "Fadd9" [f2a2][c'2f2] z4 | "Gadd9" [g2b2][d2G2] z4 :|
V:4 clef=bass name="Synth Bass"
%%MIDI program 38
|: "Cmaj9"C,2 C4 z2 | "Fadd9" F,4 F2 z2 | "Dm7" D,4 D2 z2 | "G/B" G,4 G,2 z2 |
"Cmaj9"C,4 C2 z2 | "Am7" A,,2 A,4 z2 | "Fadd9" F,6 z2 | "Gadd9" G,,4 "Cmaj9" C,2 z2 :|



X:1
T:Anime Blitz Overture
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Em
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|: "Em"E4 z4 B4 z4 z4 | "C" G8- G4 A2 G2 z4 | "Am"A6 A2 e6 e2 z4 | "B7"B8- B6 z2 z4 |
"G" G4 F4 E4 D4 z4 | "Em"E6 G2 F4 G2 z6 | "C"G8- G6 A2 z4 | "B7"B8 z4 B4 z4 |
"Em" E12 B,4 | "C"G12 E4 | "Am"A12 E4 | "B7"B4 z4 B8 z4 |
"G" d3 B G3 E F3 D z8 | "Em" E6 E2 G4 F2 z6 | "D" D12 G2 | "B7" B8 z8 z4 :|
V:2 clef=treble name="Guitar Rhythm" subname="Rhythm"
%%MIDI program 30
K:Em
|: "Em" E2 z2 E2 G4 z4 | "C" C2 E4 G4 A4 | "Am" A,2 C4 E4 A4 | "B7" B,2 D4 F4 B4 |
"G" G2 B2 D4 G4 | "Em" E2 G4 B4 z2 | "C" C6 E2 G4 z2 | "B7" B2 D4 F4 B4 z2 |
"Em" E2 B,4 E4 z4 | "C" C2 E4 G4 z4 | "Am" A,2 E4 A4 z4 | "B7"B2 D4 F4 z4 |
"G" D2 G4 B4 z2 | "Em" E4 z4 B4 E4 | "D" D4 F4 A4 D2 | "B7" B4 z4 F4 B4 z2 :|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|: "Em" E2 E4 E4 E4 +slide+[E4 E4] | "C"C2 C4 C4 E4 +slide+[C4 C4] | "Am"A2 A4 A4 C4 +slide+[A4 A4] | "B7"B2 B4 B4 D4 +slide+[B4 B4] |
"G" G,2 G,4 G,4 G,4 +slide+[G,4 G,4] | "Em" E2 E4 E4 G,4 +slide+[E4 E4] | "C" C2 C4 C4 E4 +slide+[C4 C4] | "B7" B2 B4 B4 D4 +slide+[B4 B4] |
"Em" E2 E4 E4 E4 +slide+[E4 E4] | "C" C2 C4 C4 C4 +slide+[C4 C4] | "Am" A2 A4 A4 A4 +slide+[A4 A4] | "B7" B4 B4 B4 B2 D2 z4 |
"G" G,2 B,4 D4 G,2 +slide+[G,4 G,4] z2 | "Em" E2 G4 B4 E2 +slide+[E4 E4] z2 | "D" D2 F4 A4 D2 +slide+[D4 D4] z2 | "B7" B4 D4 F2 B,4 +slide+[B4 B4] :|



X:1
T:Clash of Titans
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=170
K:Dm
V:1 clef=treble name="Orchestral Strings" subname="Str."
%%MIDI program 48
|:"Dm"d2d A2A|"C"c2c "Bb"B2B|"A"A2e "A7"c2a|"Dm"d3 A3|
"Dm"d2f "C"e2d|"Bb"c2B "A7"A2G|"Gm"G2B "A"=A2^G|"A"A3 -A2 z|
"Dm"f2f "C"e2e|"Bb"d2d "C"c2B|"A"A2A "F"F2G|"Gm"G3 -G2A|
"Dm"d2f e2d|"C"c2B "Bb"A2G|"A"A2^G "A7"A2F|"Dm"D3 -D2 z:|
V:2 clef=treble name="French Horns" subname="Hrns."
%%MIDI program 60
|:"Dm"A,2D F2A,|"C"G,2C E2G,|"Bb"F,2B,, D2F,|"A"A,3 A,3|
"Dm"D3 D3|"C"C3 C3|"Bb"B,,3 "A7"B,3|"Dm"A,,6|
"Dm"A,2A, D2D|"C"G,2G, "Bb"F2F|"A"A,2D "F"F2A,|"Gm"G,3 -G2B,|
"Dm"d2d A2A|"C"c2c "Bb"B2B|"A"A2e "A7"c2a|"Dm"d3 -d2 z:|
V:3 clef=bass name="Timpani" subname="Timp."
%%MIDI program 47
|:"Dm"D,3 A,3|"C"C,3 G,3|"Bb"B,,3 F,3|"A"A,3 -A,3|
"Dm"A,,3 D,3|"C"C,3 G,3|"Bb"B,,3 "A7"A,3|"Dm"D,6|
"Dm"A,,3 D,3|"C"C,3 G,3|"Bb"B,,3 "C"C,3|"A"A,3 D,3|
"Dm"D,3 A,,3|"C"C,3 G,,3|"Bb"B,,3 F,,3|"A"A,,3 -A,,3:|
V:4 clef=bass name="Contrabass" subname="Cbs."
%%MIDI program 44
|:"Dm"D,3 A,,3|"C"C,3 G,,3|"Bb"B,,3 F,,3|"A"A,3 -A,,3|
"Dm"A,,3 D,3|"C"C,3 G,,3|"Bb"B,,3 "A7"A,,3|"Dm"D,,6|
"Dm"A,,3 D,3|"C"C,3 G,,3|"Bb"B,,3 "C"C,3|"A"A,3 D,,3|
"Dm"D,3 A,,3|"C"C,3 G,,3|"Bb"B,,3 F,,3|"A"A,,3 -A,,3:|



X:1
T:Fusion Pulse
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=144
K:Cmaj
V:1 clef=treble name="Synth Lead" subname="Lead"
%%MIDI program 81
|: "C"e2c2 e2g2 a2f2 g4 z4 | "Am"a2e2 a2c'2 b2g2 a4 z4 | "F"f2d2 f2a2 g2e2 f4 z4 | "G"g2^d2 g2b2 a2f2 g4 z4 :|
|: "Em"g2b2 g2e2 f2d2 e2c2 z4 | "Dm"d2f2 d2a2 g2f2 e2^c2 z4 | "A7"e2^c2 e2g2 f2^d2 e2c2 z4 | "G"g2b2 g2e2 f2d2 g4 z4 :|
|: "C"e4 e2g4 c'4 z6 | "Am"a4 a2c4 e4 z6 | "F"f4 f2a4 d4 z6 | "G"g4 g2b4 ^d4 z6 :|
V:2 clef=treble name="Electric Piano" subname="Chords"
%%MIDI program 5
|: "C"[cegc']4 [cegc']4 [cegc']4 [cegc']4 | "Am"[aeac']4 [aeac']4 [aeac']4 [aeac']4 | "F"[fdfa]4 [fdfa]4 [fdfa]4 [fdfa]4 | "G"[gbdg]4 [gbdg]4 [gbdg]4 [gbdg]4 :|
|: "Em"[gbdf]4 [gbdf]4 [gbdf]4 [gbdf]4 | "Dm"[dfad]4 [dfad]4 [dfad]4 [dfad]4 | "A7"[^ceag]4 [^ceag]4 [^ceag]4 [^] | "G"[gbdg]4 [gbdg]4 [gbdg]4 [gbdg]4 :|
|: "C"[cegc']8 [cegc']8 | "Am"[aeac']8 [aeac']8 | "F"[fdfa]8 [fdfa]8 | "G"[gbdg]8 [gbdg]8 :|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|: "C"C,8 z4 C,8 | "Am"A,,8 z4 A,,8 | "F"F,8 z4 F,8 | "G"G,8 z4 G,8 :|
|: "Em"E,8 z4 E,8 | "Dm"D,,8 z4 D,,8 | "A7"A,,8 z4 A,,8 | "G"G,8 z4 G,8 :|
|: "C"C,16 z8 C,16 | "Am"A,,16 z8 A,,16 | "F"F,16 z8 F,16 | "G"G,16 z8 G,16 :|



X:1
T:Rustic Village Dance
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:G
% The melody captures the simple joy and contentment of fantasy village life
V:1 clef=treble name="Harp"
%%MIDI program 46
|: "G"(B>c) d2 G2-| G3 A B2 | "C"(e>d) c2 E2 | "D"(F>G) A2 D2 |
"G"(B>c) d2 G2-| G3 A B2 | "C/e"(e>f) g2 e2 |1 "D"(d2) d4 :|2 "D"(d2) d3 z ||
[K:Gmix]
|: "Gm"(B>c) d2 G2-| G3 A B2 | "Cm"(e>d) c2 E2 | "D7"(F>G) A2 D2 |
"Gm"(B>c) d2 G2-| G3 A B2 | "Cm"(e>f) g2 e2 |1 "D7"(d2) d4 :|2 "D7"(d2) d3 z |]
V:2 clef=bass name="Strings Ensemble"
%%MIDI program 48
|: "G"D3 G, B,2 | D4 z2 | "C"E3 C G,2 | E4 z2 |
"G"D3 G, B,2 | D4 z2 | "C"E3 C E A |1 "D"A4 z2 :|2 "D"A4 A,2 ||
[K:Gmix]
|: "Gm"D3 G, B,2 | D4 z2 | "Cm"E3 C G,2 | E4 z2 |
"Gm"D3 G, B,2 | D4 z2 | "Cm"E3 C E G |1 "D7"A4 z2 :|2 "D7"A4 A,2 |]



X:1
T:Revised Composition
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Cmaj
V:1 clef=treble
|: "C"c2 e2 g2 e2 | "Am"a4 a2 g2 | "F"f2 a2 c'2 a2 | "G"g4 g2 f2 |
"C"e2 c'2 e2 g2 | "Am"a4 ^g2 a2 | "Dm"d2 f2 a2 f2 | "G"g4 g2 e2 :|
V:2 clef=treble
|: "C"c4 e2 c2 | "Am"a2 e2 a2 e2 | "F"f4 f2 a2 | "G"b4 g2 b2 |
"C"c4 e2 g2 | "Am"a2 e2 a2 ^g2 | "Dm"f4 a2 d'2 | "G"g4 g2 e2 :|
V:3 clef=alto
|: "C"e2 g2 c'2 e2 | "Am"a2 c'2 e2 c'2 | "F"a2 f2 a2 c'2 | "G"d2 b2 d'2 b2 |
"C"e2 g2 e2 c'2 | "Am"a2 e2 g2 e2 | "Dm"f2 d'2 f2 a2 | "G"b2 g2 e2 g2 :|
V:4 clef=bass
|: "C"C2 E2 G,2 C2 | "Am"A,2 E2 A,2 E2 | "F"F,2 A,2 C2 F2 | "G"G,2 D2 G,2 B,2 |
"C"C2 G,2 E2 G2 | "Am"A,2 A,2 ^G,2 A,2 | "Dm"D2 F2 A,2 D2 | "G"G,2 B,2 G2 D2 :|



X:1
T:Serenity of Elderville
C:OrchestrAI
M:6/8
L:1/8
Q:1/4=120
K:G
% A peaceful melody influenced by traditional folk sounds
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G" B2 G E2 G | "C" A3 E C2 | "G" B2 G D2 E | "D7" F3 D A2 |
| "G" B2 G E2 G | "C" A3 E C2 | "G" B2 A F2 G | "D7" F3 D D2 :|
V:2 clef=treble name="Violin"
%%MIDI program 41
|:"G" d2 B- B2 d | "C" e2 c e2 c | "G" d2 B- B2 d | "D7" f2 a f2 a |
| "G" d2 B- B2 d | "C" e2 c e2 c | "G" d2 B- B2 d | "D7" f2 a a2 z :|
V:3 clef=treble name="Acoustic Guitar"
%%MIDI program 24
|:"G" G2 E G3  | "C" E2 E C3  | "G" G2 D G2 B | "D7" F2 A A3 |
| "G" B2 G E2 G | "C" E2 E C3  | "G" B2 A F2 G | "D7" D3 F A2 :|
V:4 clef=bass name="Double Bass"
%%MIDI program 43
|:"G" G,2 B, D,3  | "C" C,2 E, G,3  | "G" G,2 B, D,3  | "D7" D,2 F, A,3  |
| "G" G,2 B, D,3  | "C" C2 E, G,3  | "G" B,2 A, F,2 G, | "D7" D,3 F, A,2 :|



X:1
T:Enchanted Library Waltz
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=92
K:Em
% The piece starts with a mystical theme representing the whispers of ancient knowledge within the library
V:1 clef=treble name="Harpsichord" subname="Hpd."
%%MIDI program 6
|:"Em" B4 G2 | E4 z2 | "Am" A3 G F2 | "B7" B4 z2 |
"Em" B4 e2 | d4 B2 | "C" c2 G4  | "D" B,4 z2 |
"Em" G4 e2 | "C" G4 E2 | "Am" e3 f g2 | "B7" b4 z2 |
"Em" e4 E2 | G4 B,2 | "Am" A4 a2 | "Em" e4 z2:|
V:2 clef=treble name="Pizz. Strings" subname="Pizz."
%%MIDI program 45
|:"Em" e3 B e2 | g4 z2 | "Am" c'2 a2 e2 | "B7" f4 z2 |
"Em" g2 e2 B2 | d4 B2 | "C" E2 c2 G2 | "D" A3 G F2 |
"Em" e2 g2 B2 | "C" E4 G2 | "Am" A4 c2 | "B7" B,4 z2 |
"Em" e4 g2 | b2 a2 g2 | "Am" a4 f2 | "Em" e4 z2:|
V:3 clef=bass name="Cello" subname="Cello"
%%MIDI program 42
|:"Em" B,2 G,2 E,2 | E,4 z2 | "Am" A,2 C2 E2   | "B7" F2 D2 B,2 |
"Em" E2 E4           | B,2 D2 G,2   | "C" C3 D E2   | "D" A,,2 D2 F2 |
"Em" B,2 E2 G2       | "C" C2 G2 E2 | "Am" A,2 E2 A2 | "B7" B,3 A G2 |
"Em" E2 G4           | B,2 E4       | "Am" A,2 C2 E2 | "Em" E4 z2:|



X:1
T:Quantum Leap Pursuit
C:OrchestrAI
M:7/8
L:1/16
Q:1/4=140
K:Cmix
% The piece combines a sense of urgency with unpredictable changes and contrasting worlds
V:1 name="Synth Lead" clef=treble
%%MIDI program 81
|: "Cm"G2^F2G2 z4 z^ | "Bb"=F2D2^F2 G4 | "Ab"=E2C2=E2 c4 z | "Gm"D2B,2D2 G,4 |
"Gm"A2A2B2 c2 d3  | "Fm"B2G2A2 d4 z | "Eb"G4 z2 G2 ^F3  | "Cm"G,4 z2 C2 G,3  :|
M:5/4
|: "G"B2C2 D4 B,2 G,4 | "Cm7"=E4 z2 C4 G,8 | "F9"A4-A2 z2 G4 F,8 | "Cm/C"G,4 z2 C4 z G,16 :|
M:6/8
|: "Dm"A2^F2A2 G2^F2A2 | "A7"^F2E2^F2 G2E2A2 | "Dm"D2D2E2 =F2D2G2 | "Bb"^A2^F2=AB =c2^a2f2 :|
M:7/8
|: "Cm"G4 z2 G2 ^F4 z2 | "Bb"=F4 z2 D4 z2 G2 | "G"^F4 z2 F2 G8 | "Ab"=E4 z2 _E4 z2 C4 :|
V:2 name="Electric Rhythm" clef=treble
%%MIDI program 89
|: "Cm"C8 z2 | "Bb"B,8 z2 | "Ab"A,8 z2 | "Gm"G,8 z2 |
"Gm"c8 z2 | "Fm"A,4 B,4 z2 | "Eb"G,4 A,4 B,2 | "Cm"c,8 z,3  :|
M:5/4
|: "G"g8 z4 c8 | "Cm7"C8 z4 G,8 | "F9"A8 z f8 | "Cm/C"C8 z G,4 C8 :|
M:6/8
|: "Dm"d8 z8 | "A7"^e8 z8 | "Dm"a4 d4 z8 | "Bb"g4 ^f4 z8 :|
M:7/8
|: "Cm"C12 z4 | "Bb"B,12 z4 | "G"^F12 z4 | "Ab"A,8 z4 A,4 :|
V:3 name="Bass Synth" clef=bass
%%MIDI program 88
|: "Cm"C,12 C,8 | "Bb"B,,12 B,,8 | "Ab"A,,12 A,,8 | "Gm"G,,12 G,,8 |
"Gm"c,12 c,8 | "Fm"A,,12 A,,8 | "Eb"G,,12 G,,8 | "Cm"C,12 C,8 :|
M:5/4
|: "G"G,,12 B,,8 | "Cm7"C,12 C,8 | "F9"A,,12 F,,8 | "Cm/C"G,,12 _B,,8 :|
M:6/8
|: "Dm"D,,4 A,,4 D,,4 | "A7"^E,,4 A,,4 E,,4 | "Dm"D,4 A,4 D,4 | "Bb"B,,,4 G,,4 B,,4 :|
M:7/8
|: "Cm"C,12 C,8 | "Bb"B,,12 B,,8 | "G"^F,12 ^F,8 | "Ab"A,,12 A,,8 :|



X:1
T:Convergence Waltz
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=120
K:Fmaj
V:1 clef=treble name="Piano - Right Hand"
%%MIDI program 0
V:2 clef=treble name="Violin 1"
%%MIDI program 40
V:3 clef=treble name="Violin 2"
%%MIDI program 40
V:4 clef=alto name="Viola"
%%MIDI program 41
V:5 clef=bass name="Cello"
%%MIDI program 42
V:6 clef=bass name="Contrabass"
%%MIDI program 43
V:7 clef=treble name="Flute"
%%MIDI program 73
V:8 clef=treble name="Clarinet"
%%MIDI program 71
V:1
|:"F"     c2 F A | "Bb"    d2 B, D | "A7"    c2 A,^C | "Dm"    d2 F A |
"Bb"    f2 B, d | "Gm"    e2 G c | "C7"    f2 E G | "Fmaj7" c2 A c |
"Gm7"   b2 G B | "C7"    a2 ^F A | "F6"    g2 F A | "Dm7"   f2 D F |
"Gm7"   e2 c e | "C7"    d2 B, G | "Fmaj7" c2 A F |1 "C9"     G3 z :|2 "C7"     G,3 z |]
V:2
|:"F"     c2 c f | "Bb"    f2 d f | "A7"    e2 e a | "Dm"    a2 f a |
"Bb"    d2 d g | "Gm"    g2 b g | "C7"    a2 a e | "Fmaj7" f2 d f |
"Gm7"   b2 b f | "C7"    g2 g c | "F6"    a2 f c | "Dm7"   g2 g b |
"Gm7"   f2 f a | "C7"    a2 e g | "Fmaj7" d2 f c |1 "C9"     f3 z :|2 "C7"     e3 z |]
V:3
|:"F"     A,2 A, c | "Bb"    c2 B, D | "A7"    B,2 B, E | "Dm"    D2 F A |
"Bb"    G,2 G, B | "Gm"    A2 c e | "C7"    B2 E G | "Fmaj7" A2 A C |
"Gm7"   F2 F A | "C7"    G2 G B | "F6"    E2 A c | "Dm7"   F2 F A |
"Gm7"   D2 E G | "C7"    C2 C E | "Fmaj7" D2 F A |1 "C9"     E3 z :|2 "C7"     E,3 z |]
V:4
|:"F"     F,2 F, A | "Bb"    D2 F B | "A7"     E2 A, ^C | "Dm"    A,2 D F |
"Bb"    B,2 G, B | "Gm"    C2 G c | "C7"    E2 C E | "Fmaj7" A,2 A C |
"Gm7"   F,2 F A | "C7"    G2 B, D | "F6"    A,2 F A | "Dm7"   D2 F B |
"Gm7"   C2 E G | "C7"    G,2 G B | "Fmaj7" A,2 A C |1 "C9"     D3 z :|2 "C7"     D,3 z |]
V:5
|:"F"     c2 F, A, | "Bb"    F2 F B, | "A7"    E2 E, A, | "Dm"    A,2 A, D |
"Bb"    B,2 B, d | "Gm"    G,2 G, B, | "C7"    E2 G, c | "Fmaj7" F2 F A |
"Gm7"   D2 D F | "C7"    C2 C E | "F6"    A,2 A, C | "Dm7"   D2 D F |
"Gm7"   B,2 B, D | "C7"    G,2 G, B, | "Fmaj7" F2 A c |1 "C9"     F,3 z :|2 "C7"     F,3 z |]
V:6
|:"F"     F,,4  | "Bb"    D,,4  | "A7"     E,,4  | "Dm"    A,,4  |
"Bb"    B,,,4  | "Gm"    G,,4  | "C7"    C,4  | "Fmaj7" F,,4  |
"Gm7"   D,4  | "C7"    C,,4  | "F6"    A,,4  | "Dm7"   D,4  |
"Gm7"   B,,,4  | "C7"    G,,4  | "Fmaj7" F,,4  |1 "C9"     F,,3 z :|2 "C7"     F,,3 z |]
V:7
|:"F"    c2 A F | "Bb"   F2 D B, | "A7"   A2 E C | "Dm"   D2 A, F |
"Bb"   B3 G,  | "Gm"   E3 A,  | "C7"   G3 c  | "Fmaj7" A3 c  |
"Gm7"  B2 G B | "C7"   A2 D F | "F6"   c2 F A | "Dm7"  B2 D F |
"Gm7"  A2 A ^C | "C7"   G2 E G | "Fmaj7" c2 A F |1 "C9"    F z2 c :|2 "C7"    E z2 A |]
V:8
|:"F"     A2 F A | "Bb"    D2 D F | "A7"    E2 C E | "Dm"    A2 F A |
"Bb"    G2 D G | "Gm"    B,2 G B | "C7"    E2 E G | "Fmaj7" A2 c A |
"Gm7"   F2 B D | "C7"    D3 E  | "F6"    A2 A C | "Dm7"   F2 F A |
"Gm7"   A2 A C | "C7"    E3 G  | "Fmaj7" c2 F A |1 "C9"    c z2 F :|2 "C7"    B z2 E |]



X:1
T:Journey Through Melodic Landscapes
C:OrchestrAI
M:3/4
L:1/16
Q:1/4=100
K:Fmaj
V:1 clef=treble name="Flute"
%%MIDI program 73
P:A
|:"F" f3a g2f2 e4 | "C7"e2g2 f2e2 d4 | "Bb"b3b a2g2 f2e2 | "C7"c3c d2e2 f4 |
"F" a2a2 g4 a2c'2 | "Dm" d3d c2d2 e2f2 | "Gm" g2g2 f4 g2b2 | "C7"e2e2 d4 z4 :|
P:B
|:"Bb" df3 bb3 af2d | "F/A" c'a3 fa3 gf2e | "Gm7" df3 gf3 fe2d | "C7" ec3 gc3 fe2d |
"Bb" bb3 ab3 gf2d | "Eb" eb3 gb3 fe2d | "Am7b5" ea3 ag3 fe2c | "Bb/D" df3 "C7" eg3 f4 :|
P:C
|:"F" f3a g2f2 e4 | "C7"e3g f2e2 d4 | "Bb"b2b2 a4 b2d2 | "F/A"c'2a2 a4 a2f2 |
"Dm" d2d2 c4 d3e | "Bm" d3c B2A2 G4 | "Am" Ac2B A2G2 F4 |1 "Dm"d2f2 "C7"e2d2 "F"f4 :|2 "Dm"d2f2 "C7"e2g2 f2z2||
V:2 clef=treble name="Oboe"
%%MIDI program 68
P:A
|:"F" c2f2 e2d2 c4 | "C7"g2c2 b2a2 g4 | "Bb"f2f2 e2d2 c2B2 | "C7"a2g2 f2e2 d4 |
"F" c2f2 e2f2 g2a2 | "Dm" a2f2 e4 f2a2 | "Gm" b2g2 a4 b2d2 | "C7"g2e2 f4 z4 :|
P:B
|:"Bb" g2d2 c'2b2 a4 | "F/A"e2c2 f2e2 d4 | "Gm7" g2d2 b2a2 g4 | "C7"f2e2 g2f2 e4 |
"Bb" b2g2 a4 g2f2 | "Eb" g2e2 f4 e2d2 | "Am7b5" c2a2 b4 a2g2 | "Bb/D"g2d2 "C7" f2e2 d4 :|
P:C
|:"F" c3f e2d2 c4 | "C7"g3c b2a2 g4 | "Bb"f2f2 e4 f2d2 | "F/A"c'2a2 f4 c'2a2 |
"Dm" a2a2 f4 a3b | "Bm" a2g2 f2e2 d4 | "Am" a4 g2f2 e4 |1 "Dm"a2c2 "C7"g2f2 "F"c4 :|2 "Dm"a2c2 "C7"g2c2 f2z2||
V:3 clef=alto name="Viola"
%%MIDI program 41
P:A
|:"F" a2d2 c2B2 A4 | "C7"c2f2 e2d2 C4 | "Bb"F2F2 E2D2 C2B,2 | "C7"G,2F2 E2D2 C4 |
"F" A2A2 G4 A2c2 | "Dm" G2G2 F2G2 A2B2 | "Gm" G2G2 F4 G2B2 | "C7"C2C2 B,4 z4 :|
P:B
|:"Bb" F2A2 F2G2 F4 | "F/A"C4 F2E2 D4 | "Gm7" F2A2 E2D2 F4 | "C7"G,2C2 B,2A,2 F4 |
"Bb" F2A2 G4 F2E2 | "Eb" G,2B,2 A,4 G,2F2 | "Am7b5" E2C2 F4 E2D2 | "Bb/D"F2A2 "C7"G2F2 F4 :|
P:C
|:"F" a3d c2B2 A4 | "C7"c3f e2d2 C4 | "Bb"F2F2 G4 F2E2 | "F/A"A2c2 A4 F2A2 |
"Dm" G2G2 F4 G3A | "Bm" G2F2 E2D2 C4 | "Am" c2c2 B2A2 G4 |1 "Dm"F2A2 "C7"C2B,2 "F"A4 :|2 "Dm"F2A2 "C7"C2E2 A,2z2||
V:4 clef=bass name="Cello"
%%MIDI program 42
P:A
|:"F" F,4 A,2C2 F,4 | "C7"C4 E2D2 C4 | "Bb"B,,4 D2F2 B,4 | "C7"G,4 C2E2 G,4 |
"F" A,4 C4 D4 | "Dm" D4 F4 A4 | "Gm" B,,4 D4 G4 | "C7"C4 G,4 z4 :|
P:B
|:"Bb" D4 F,2A,2 D4 | "F/A"F4 A,2C2 F4 | "Gm7" D4 F,2A,2 D4 | "C7"G,4 C2E2 G,4 |
"Bb" D4 F,4 E4 | "Eb" G,,4 B,2D2 G,4 | "Am7b5" E2C2 G,4 F,4 | "Bb/D"D4 "C7"C2E2 F4 :|
P:C
|:"F" F,4 A,2C2 F,4 | "C7"C4 E2D2 C4 | "Bb"B,,4 E2F2 B,4 | "F/A"A,4 D4 A,4 |
"Dm" D4 F4 D4 | "Bm" B,,4 F,2A,2 B,,4 | "Am" C2C2 B,,2A,2 G,,4 |1 "Dm"D4 "C7"G,2F2 "F"F4 :|2 "Dm"D4 "C7"G,4 C,4||



X:1
T:Harmony Restored
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=84
K:Am
V:1 clef=treble
%%MIDI program 1
|:"Am" A3 B c2 | "E7" e4 B2 | "Am" A3 B A2 | "G" G4 E2 | "F" E3 F G2 | "Am" A4 z2 |
"C" G3 F E2 | "F" D4 C2 | "E7" B,3 C D2 | "Am" E4 z2 | "A" c3 B A2 | "F" F4 E2 |
"Dm" F3 G A2 | "E7" E4 z2 |[1 "Am"A4 z2 :|[2 "Am"A3 z3 ||
V:2 clef=treble
%%MIDI program 1
|:"Am" E3 F G2 | "E7" B4 ^G2 | "Am" E3 F E2 | "G" D4 B,2 | "F" C3 D E2 | "Am" E4 z2 |
"C" C3 D C2 | "F" A,4 ^G,2 | "E7" G,3 A, B,2 | "Am" C4 z2 | "A" A3 ^G A2 | "F" E4 D2 |
"Dm" D3 E F2 | "E7" G4 z2 |[1 "Am"E4 z2 :|[2 "Am"E3 z3 ||
V:3 clef=bass
%%MIDI program 32
|:"Am" A,4 C2 | "E7" B,,4 E2 | "Am" A,3 C A,2 | "G" G,4 D2 | "F" F,3 E F,2 | "Am" A,4 z2 |
"C" G,4 E2 | "F" F,3 D F,2 | "E7" B,,4 E2 | "Am" A,4 z2 | "A" A,3 ^G A,2 | "F" C3 C C2 |
"Dm" D,3 F A,2 | "E7" E,4 z2 |[1 "Am"A,4 z2 :|[2 "Am"A,3 z3 ||



X:1
T:Celestial Voyage
C:OrchestrAI
M:6/8
L:1/8
Q:1/4=120
K:G
% The composition begins with a dreamy atmosphere, representing the voyage through the cosmos
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G" B2dad f2d | "Em" g3f e2c | "C" c2gB G2B | "D" A3c d2B |
| "C" A2F G2E | "G" D3E F2G | "Em" E2=F G2A | "G" G3B A2z |
| "Am" B2A c2e | "D" d2cB A3G | "C" G3E (3DCE | "G" D3B G2d |
| "Em" f2gB (3cBf | "C" e2dB G3D | "D" A3F (3FGE | "G" G6 z2 ||
% The middle section elaborates on the celestial imagery with rising motifs and layered harmonies
V:2 clef=treble name="Oboe"
%%MIDI program 68
|:"G" g2f e2d | "Em" g3B A2z | "C" G2E (3GFA | "D" A3D B2G |
| "C" B/c/ d2e f2c | "G" A2b c2e | "Em" E2F G2A | "G" G3B A2z |
| "Am" c3e e3c | "D" d2B A2G | "C" B/c/ d2e g3 | "G" G2B G4 |
| "Em" A2B (3EGF | "C" C3E E3G | "D" A2d2 f2d | "G" G6 z2 ||
% The concluding section brings a sense of resolution and reflection, combining elements from earlier parts
V:3 clef=bass name="Cello"
%%MIDI program 43
|:"G" G,3B, d3G | "Em" B,3E, e3G | "C" C,3G, B,3C | "D" A,3D, F,3A, |
| "C" E2C2 A,2E | "G" G3F E2G | "Em" E2D B2f | "G" G6 z2 |
| "Am" a2g f2e | "D" d3B A,2d | "C" C2G G2B | "G" G2B ^fg G2d |
| "Em" e2gB (3cBf | "C" e2dB c2G | "D" A3F (3FGE | "G" G6 z2 ||
% The piece finishes with a reflective descent, bringing the voyage to a serene close
V:4 clef=bass name="Double Bass"
%%MIDI program 34
|:"G" D,,3G,, D,,3G,, | "Em" B,,3E,, E,,3G,, | "C" C,,3G,, G,,3C,, | "D" A,3D, F,3A, |
| "C" E,2C2 A,,2E | "G" D,,3G,, G,,2B,, | "Em" E,,3F,, B,,3E,, | "G" G,6 z2 |
| "Am" A,,2B,, E,,2g | "D" D,,2A,, G,,2B | "C" C,,2G,, G,,2B | "G" G,,2B,, G,,2D,, |
| "Em" E,2G (3B,G,A, | "C" C,,3E,, E,,Z | "D" D,,3F, (3A,G,F, | "G" G,,6 z2 ||



X:1
T:Discovery Waltz
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:Dmix
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "Dm"A2 | "C"e3 d (3cBA | "Bb"d3 c (3BAG | "A7"(3ABA G2 F2 | "Dm"A4 :|
w: Float-ing on a bree-ze, a-whirl in free-dom’s dance
|: "Gm"G2 | "Csus2"(3B,^CB, D2 E2 | "Fsus2"F3 G (3AGF | "A7"A6- | A4 :|
w: Gently swing-ing twirls, we weave through life’s expansive trance
| "Dm"(3(FGA) (3(FGA) (3(FGA) | "C"(E2G2) c2 | "Bb"d4 (3dcB | "A7"A6- |
| "Am"A4 z2 :|
V:2 clef=treble name="Clarinet"
%%MIDI program 71
|: "Dm"f2 | "C"(3e^de g2 a2 | "Bb"(3fdc b2 c'2 | "A7"b6 | "Dm"A4 :|
|: "Gm"g2 | "Csus2"(3ded (3cBA | "Fsus2"(3dcB (3cAG | "A7"F6- | F4 :|
| "Dm"d4 d2 | "C"c3 A (3GFE | "Bb"B,4 (d3c) | "A7"A6- |
| "Am"A4 z2 :|
V:3 clef=treble name="Violin"
%%MIDI program 40
|: "Dm"d2 | "C"c3 B (3AGF | "Bb"B3 A (3GFE | "A7"(3GFG E2 D2 | "Dm"D4 :|
|: "Gm"G2 | "Csus2"(3AGA B2 c2 | "Fsus2"d3 c (3Bcd | "A7"D6- | D4 :|
| "Dm"(3AAA (3AAA (3AAA | "C"(G2B2) e2 | "Bb"d4 (3dcB | "A7"A6- |
| "Am"A4 z2 :|
V:4 clef=bass name="Cello"
%%MIDI program 42
|: "Dm"D2 | "C"C4 z2 | "Bb"B,,4 z2 | "A7" A,2 E,2 C,2 | "Dm"D4 :|
|: "Gm"G,2 | "Csus2"A,,2 D,2 F,2 | "Fsus2"F,4 z2 | "A7"D6 | D4 :|
| "Dm"D,4 D,2 | "C"C,3 C, E,2 | "Bb"B,,4 B,2 | "A7"A,6 |
| "Am"A,4 z2 :|



X:1
T:Baroque Reflections
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=100
K:Gmin
V:1 clef=treble name="Harpsichord"
%%MIDI program 6
|:"Gm" d4 c4 B2A2 G4 | "D7" A6F2 G4 F2E2 | "Gm" D8 D4 E2F2 | "Cm" G,4 C4 E2D2 C4 |
"Bb" F6D2 E4 D2C2 | "A7" A,6F2 A4 ^G2A2 | "D7" d4 c4 B2A2 ^G4 | "Gm" G8 G2A2 B2d2 |
"D" F4 D4 F4 A4 | "Gm" d6c2 B2d2 c2A2 | "Cm" E4 C4 E4 G4 | "Gm/D" A,4 D4 G4 A2B2 |
"D7" A6F2 G4 F2E2 | "Gm" D8 D4 D2E2 |"Bb/F" F6A2 B4 c2d2 | "Gm"1 G8 z8:|2 G8 z8 :|
V:2 clef=treble name="Violin"
%%MIDI program 40
|:"Gm" B,8 d4 c2B2 | "D7" c6A2 =B4 A2GF | "Gm" G8 G4 F2G2 | "Cm" C8 C4 D2C2 |
"Bb" D6F2 E4 D2C2 | "A7" C6E2 F4 G2F2 | "D7" d4 c4 B2A2 ^G4 | "Gm" G8 G4 F2D2 |
"D" A,4 F4 A4 d2c2 | "Gm" B,8 d4 c2B2 | "Cm" G,4 E4 G4 c2=B2 | "Gm/D" D8 D4 G2F2 |
"D7" A6G2 F4 E2D2 | "Gm" G8 G4 F2G2 | "Bb/F" F6D2 C4 B2A2 | "Gm"1 G8 z8:|2 G8 z8 :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"Gm" G,8 D4 E2F2 | "D7" F,8 A,4 =B,2C2 | "Gm" D8 D4 G,2A,2 | "Cm" C8 C4 D2E2 |
"Bb" F,8 B,,4 C2D2 | "A7" A,,8 E4 F2G2 | "D7" d,8 A,4 B2c2 | "Gm" G,8 G,4 F2E2 |
"D" D8 D4 E2F2 | "Gm" G,8 D4 F2E2 | "Cm" C8 C4 E2D2 | "Gm/D" D8 D4 G,2A,2 |
"D7" F,8 A,4 G2F2 | "Gm" D8 D4 G,2F2 | "Bb/F" F,8 F4 E2F2 | "Gm"1 G,8 z8:|2 G,8 z8 :|



X:1
T:Epic Boss Battle
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=180
K:Am
V:1 clef=treble name="Clarinet"
%%MIDI program 71
|:"Am" G3E GABc | "E" E2e2 f2gf | "F" f3d f2d2 | "Am" e4 efdB |
|:"D" d3d d2cB | "C" c2c2 B2AG | "G" G3B G2B2 | "Am" A4 GABc |
V:2 clef=treble name="Violin"
%%MIDI program 40
|:"Am" e3d e2c2 | "E" a3a a2b2 | "F" g3g g2f2 | "Am" e4 efdB |
|:"D" d3c d2B2 | "C" c3b c2a2 | "G" g3a g2b2 | "Am" A4 GABc |
V:3 clef=bass name="Cello"
%%MIDI program 41
|:"Am" A,3A, G,2A,B, | "E" E,2E,2 G,2F,G, | "F" F,3F, E,2F, z | "Am" E,4 D,E,G, z |
|:"D" D,3D, C,2D,B, | "C" C,3C, B,,2A,,B, | "G" G,3G, F,2G, z | "Am" A,4 D,E,A, z |
V:4 clef=bass name="Acoustic Bass"
%%MIDI program 32
|:"Am" E,,3A,, E,3A,, | "E" E,,3G,, F,3F,, | "F" F,,3G,, E,3F,, | "Am" E,,4 D,,E,,G,, z |
|:"D" D,,3F,, A,,3B,, | "C" C,,3E,, B,,3A,, | "G" G,,3B,, G,3A,, | "Am" A,,3E,, A,3D,, |



X:1
T:Ode to the Cosmos
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=50
K:Em
V:1 clef=treble name="Violins" subname="Vln."
%%MIDI program 40
|:"Em"B4 G2|"C"G4 E2|"D"A4 F2|"G"B6|
"Am"C4 A2|"B7"B4 G2|"Em"E6|"E7"A4 F2|
"Am"c4 A2|"D7"F4 D2|"G"B4 G2|"Cmaj7"E6|
"Em"B3 A G2 E2|"C"E4 G2|"D"AF D2 B,2|"Em"E6:|
V:2 clef=treble name="French Horns" subname="Hrn."
%%MIDI program 60
|:"Em"E2 G2 B2|"C"C2 E2 G2|"D"D2 F2 A2|"G"G2 B2 d2|
"Am"A2 c2 e2|"B7"F2 A2 B2|"Em"E3 B, G2|"E7"A2 F2 A2|
"Am"c2 e2 A2|"D7"D2 F2 A2|"G"B,2 D2 G2|"Cmaj7"C2 E2 G2|
"Em"B,2 E2 G2|"C"C2 E2 G2|"D" F2 A2 D2|"Em"E4 z4:|
V:3 clef=bass name="Cellos" subname="Vc."
%%MIDI program 42
|:"Em"E,2 B,,2 E2|"C"C4 z2|"D"D,2 A,,2 D2|"G"G,4 z2|
"Am"A,2 E,2 A,2|"B7"B,,4 z2|"Em"E4 z2|"E7"A,2 F,2 A2|
"Am"c2 A,2 c2|"D7"D4 z2|"G"B,,2 D,2 G,2|"Cmaj7"C,2 E,,2 C,2|
"Em"E,2 B,,2 E2|"C"C2 E,2 G,2|"D"D,2 F,2 A,2|"Em"E,4 z4:|
V:4 clef=bass name="Double Bass" subname="Db."
%%MIDI program 43
|:"Em"B,,2 E,2 G,2|"C"C,3 G,2 E,2|"D"D,3 A,,2 F,2|"G"G,3 D,2 B,,2|
"Am"A,3 E,2 C,2|"B7"B,,2 G,2 F,2|"Em"E,3 B,,2 G,2|"E7"A,2 E,2 C,2|
"Am"A,2 F,2 E,2|"D7"D3 A,,2 F,2|"G"G,3 B,,2 D,2|"Cmaj7"C,2 G,2 E,2|
"Em"B,,2 E,2 G,2|"C"C,2 G,2 E,2|"D"D,2 A,,2 F,2|"Em"E,4 z4:|



X:1
T:Fusion of Minds
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:Dmaj
% Voice 1 - Flute (expressive, melodic)
V:1 clef=treble name="Flute"
%%MIDI program 73
% The flute carries the main melody with expressive phrases.
|: "D"df "A"e2 "Bm"f2 "G"ga | "D"fd "G"ed "A7"ce "D"d2 |
"Bm"bf "F#m"e2 "G"dc "A7"e2 | "D"df "Em"ed "A7"ce "D"d2 :|
% Voice 2 - Acoustic Guitar (rhythmic, harmonious)
V:2 clef=treble name="Acoustic Guitar"
%%MIDI program 24
% The guitar provides a harmonic and rhythmic framework.
|: "D"[d2f2] "A"[c2e2] "Bm"[b2d2] "G"[a2b2] | "D"[d2f2] "G"[b2d2] "A7"[c2e2] "D"[d2f2] |
"Bm"[b2d2]"F#m"[a2c2] "G"[b2d2] "A7"[e2g2] | "D"[d2f2] "Em"[g2b2] "A7"[e2g2] "D"[d2f2] :|
% Voice 3 - Viola (counter melody, warmth)
V:3 clef=alto name="Viola"
%%MIDI program 41
% The viola adds depth with its rich, warm counter melodies.
|: "D"A,2 "A"C2 "Bm"D2 "G"B,2 | "D"A,2 "G"F2 "A7"G2 "D"A,2 |
"Bm"B,2 "F#m"A,2 "G"D2 "A7"F2 | "D"A,2 "Em"G2 "A7"F2 "D"D2 :|
% Voice 4 - Cello (bass, foundation)
V:4 clef=bass name="Cello"
%%MIDI program 42
% The cello anchors the piece with solid bass notes and rhythm.
|: "D"D,4 "A"E,4 | "Bm"F,4 "G"G,4 | "D"D,4 "G"B,,4 | "A7"C,4 "D"D,4 :|



X:1
T:Boss Battle Blitz
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Em
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"Em" B4B4 B2A2G2F2 | "D" A4A4 A2G2A2F2 | "C" G4G4 G2G2E2F2 | "B" F4F4 F4 z4 |
"Em" e8- e4 z4 | "A" c8- c4 z4 | "C" G8- G4 z4 | "B" B,8- B,4 z4 |
"Em" B4B4 B2B2A2G2 | "D" d4d4 d2d2F2E2 | "C" c4c4 c2G2E2D2 | "B7" B4B4 B4 z4 :|
V:2 clef=treble name="Brass" subname="Brass"
%%MIDI program 61
|:"Em" E8- E4 z4 | "D" F8- F4 z4 | "C" G8- G4 z4 | "B" B,8- B,4 z4 |
"Em" E4E4 E4 z4 | "A" A,4c4 A2A2A2A2 | "C" G4G4 G4 z4 | "B" B,4B4 B2B2B2B2 |
"Em" e4E4 E2E2E2E2 | "D" A4F4 F2F2F2F2 | "C" G4G4 G2G2G2G2 | "B7" B,4B4 B4 z4 :|
V:3 clef=bass name="Timpani" subname="Timp."
%%MIDI program 47
|:"Em" E,2E,2 E,2E,2 E,2E,2 E,2E,2 | "D" F,2F,2 F,2F,2 F,2F,2 F,2F,2 | "C" G,2G,2 G,2G,2 G,2G,2 G,2G,2 | "B" B,,2B,,2 B,,2B,,2 B,,2B,,2 B,,2B,,2 |
"Em" E,1E,1E,1E,1 E,2E,2 E,2E,2 z4 | "A" A,,2C,2 A,,2C,2 A,,2C,2 A,,2C,2 | "C" G,1G,1G,1G,1 G,2G,2 G,2G,2 z4 | "B" B,,1B,,1B,,1B,,1 B,,2B,,2 B,,2B,,2 z4 |
"Em" E,2E,2 E,2E,2 E,2E,2 E,2E,2 | "D" F,2F,2 F,2F,2 F,2F,2 F,2F,2 | "C" G,2G,2 G,2G,2 G,2G,2 G,2G,2 | "B7" B,,2B,,2 B,,2B,,2 B,,2B,,2 B,,2B,,2 :|
V:4 clef=treble name="Choir" subname="Choir"
%%MIDI program 53
|:"Em" E4E4 G4 z4 | "D" F4F4 A4 z4 | "C" G4G4 c4 z4 | "B" B,4B,4 e4 z4 |
"Em" e4e4 g4 z4 | "A" c4c4 e4 z4 | "C" g4g4 c'4 z4 | "B" b4b4 e'4 z4 |
"Em" e8- e4 z4 | "D" f8- f4 z4 | "C" g8- g4 z4 | "B7" b,8- b,4 z4 :|



X:1
T:The Thrill of the Chase
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=160
K:Amin
V:1 clef=treble
%% MIDI program 11
|: "Am"e2ed cBAG | "E"E2EE E2EF | "Am"A2A2 c4 | "E"B2^G2 A4 |
"Am"c3B AGFE | "G"G2G2 GABc | "Dm"d2d2 A2d2 | "E"E4 E4 :|
V:2 clef=treble
%%MIDI program 11
|: "Am"A,2^C2 E2A,2 | "E"B,2E2 ^G2B,2 | "Am"A,2A,2 A,4 | "E"B,2^G2 B,4 |
"Am"A,2^C2 E2A,2 | "E"B,2E2 B,2E2 | "Dm"F2F2 D2F2 | "E"E4 E4 :|
V:3 clef=bass
%%MIDI program 40
|: "Am"A,4 z4 | "E"E,4 z4 | "Am"A,12 | "E"E,12 |
"Am"A,2A,2 A,4 | "G"G,12 | "Dm"D,4 F,4 | "E"E,4 E,4 :|



X:1
T:Yee Haw Wiggle Expanded
C:Lorn, Arr. OrchestrAI
M:12/8
L:1/8
Q:3/8=120
K:Bb
V:1 clef=treble name="Fiddle 1"
%%MIDI program 110
P:A
|: "Bb"G3 z2 G-G3 G2G | "Eb"F3 G3 B2G z3 | "F7"z2 B B2G B3 G2G | "Bb"F3 G3 B2G z3 |
"Bb"G3 z2 G-G3 G2G | "Eb"F3 G3 B2G z3 | "F7"z2 B B2G B3 B2c | "Bb"^c3 d3 f2^f z3 :|
P:B
|: "Bb"d3 z3 d2g f3 | "Eb"e3 z3 e2b g3 | "Gm"z2 g gfe G2B A3 | "F"A3 e2c A3 z3 |
"Eb"g3 -g2g e2c "Cm"G3 -G2G | "F7"A3 -A2A F3 "Bb"F2D | "G7"B3 -B2d G2B "Cm"e3 | "F7"f3 z3 "Bb"B3 z3 :|
P:C
|: "Eb"d2^c d3 e2f g3 | "Ab"e3 f3 ab2a a2b | "Eb"g2f e3 "Bb"f2e d3 | "Bb"d3 c3 B3 z3 |
"Cm"g2^f g3 a2b c3 | "F7"d2c B3 c2B A3 | "F7"A2G F3 "Bb"G3 F3 | "Eb"E3 z2E "Bb"F3 z3 :|
P:D
| "Bb"G3 z2 G-G3 G2G | "Eb"F3 G3 B2G z3 | "F7"z2 B B2G B3 G2G | "Bb"F3 G3 B2G z3 |
| "Bb"G3 z2 G-G3 G2G | "Eb"F3 G3 B2G z3 | "F7"z2 B B2G B3 B2c | "Bb"^c3 d3 f2^f z3 |
P:E
|: "Bb"d3 z3 d2g f3 | "Eb"e3 z3 e2b g3 | "Gm"z2 g gfe G2B A3 | "F"A3 e2c A3 z3 |
"Eb"g3 -g2g e2c "Cm"G3 -G2G | "F7"A3 -A2A F3 "Bb"F2D | "G7"B3 -B2d G2B "Cm"e3 | "F7"f3 z3 "Bb"B3 z3 :|
P:A
| "Bb"G3 z2 G-G3 G2G | "Eb"F3 G3 B2G z3 | "F7"z2 B B2G B3 G2G | "Bb"F3 G3 B2G z3 |
| "Bb"G3 z2 G-G3 G2G | "Eb"F3 G3 B2G z3 | "F7"z2 B B2G B3 B2c | "Bb"^c3 d3 f2^f z3 ||
V:2 clef=treble name="Fiddle 2"
%%MIDI program 110
%%MIDI transpose -12
P:A
|: B,3 z3 -B,B,3 B,3 | A,3 B,3 d3 z3 | z3 d d3 d3 B,3 | A,3 B,3 d3 z3 |
B,3 z3 -B,B,3 B,3 | A,3 B,3 d3 z3 | z3 d B,3 B,3 d3 | d3 e3 g3 z3 :|
P:B
|: B,3 z3 B,3 g3 | c3 z3 c4 a3 | z3 g f2e F3 D3 | D3 c3 D3 z3 |
g3 g3 c3 G3 | D3 D3 A,3 A,3 F3 | B,3 B,3 d d3 g3 | g3 z3 d3 z3 :|
P:C
|: d2c d3 c3 B3 | c3 d3 f3 e3 e3 | f2e d3 B3 A3 | B,3 A,3 G,3 z3 |
g2f g3 a3 b3 | d3 c3 B3 A3 G3 | A2G F3 B,3 B,3  | E,3 z2E F3 z3 :|
P:D
| B,3 z3 -B,B,3 B,3 | A,3 B,3 d3 z3 | z3 d d3 d3 B,3 | A,3 B,3 d3 z3 |
| B,3 z3 -B,B,3 B,3 | A,3 B,3 d3 z3 | z3 d B,3 B,3 d3 | d3 e3 g3 z3 |
P:E
|: B,3 z3 B,3 g3 | c3 z3 c4 a3 | z3 g f2e F3 D3 | D3 c3 D3 z3 |
g3 g3 c3 G3 | D3 D3 A,3 A,3 F3 | B,3 B,3 d d3 g3 | g3 z3 d3 z3 :|
P:A
| B,3 z3 -B,B,3 B,3 | A,3 B,3 d3 z3 | z3 d d3 d3 B,3 | A,3 B,3 d3 z3 |
| B,3 z3 -B,B,3 B,3 | A,3 B,3 d3 z3 | z3 d B,3 B,3 d3 | d3 e3 g3 z3 ||



X:1
T:Winter Hearth Tales
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=90
K:Gmaj
V:1
%%MIDI program 41
|:"G"G3 B d2|"Em"B3 e g2|"C"G3 E C2|"G"D6|
"G"G3 B d2|"D7"F2A2 d2|"G"G3 B A2|"D7"A6|
"C"e3 G c2|"G"B3 d g2|"Am"A3 F A2|"D7"D6|
"G"G3 B d2|"Em"B2g2 f2|"C"E3 C G2|"G"G6:|
V:2
%%MIDI program 42
|:"G"B,6|"Em"G,6|"C"E,6|"G"D,6|
"G"B,6|"D7"A,6|"G"B,6|"D7"F,6|
"C"C6|"G"B,6|"Am"A,6|"D7"A,4 F,2|
"G"G,6|"Em"G,6|"C"E,6|"G"G,6:|
V:3
%%MIDI program 43
|:"G"D3 G B2|"Em"E3 A d2|"C"C3 G E2|"G"D6|
"G"D3 G B2|"D7"A3 F d2|"G"D3 G F2|"D7"F6|
"C"G,3 E G2|"G"B,3 G B2|"Am"E3 C E2|"D7"D6|
"G"G,3 B, D2|"Em"E3 G e2|"C"C3 G, C2|"G"G,6:|



X:1
T:This Week In Google Podcast Intro
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=140
K:Cmaj
V:1 clef=treble
%%MIDI program 80
|:"C"e2c2E2G2 c2e2g2e2|"F"d2f2A2c2 f2d2a2f2|"G"b2g2B2d2 g2b2d'2b2|"C"e4E4 c4G4:|
|:"C"c4-c2e2 g4-g2e2|"F"f3a f2a2 d4-d2f2|"G"b2g2 b2d'2 g4b2d'2|"C"c2e2 C2E2 G,4C4:|
V:2 clef=treble
%%MIDI program 80
|:"C"G,2E2C2E2 G,2C2E2G,2|"F"A,2C2F2A2 D2F2A2d2|"G"B,2D2G2B,2 D2G2B2d2|"C"G,4C4 E4G4:|
|:"C"C4-C2E2 C4-E2C2|"F"F2A2 F2A2 F4-A2F2|"G"G2B,2 D2G2 B,4-D2G2|"C"C2E2 G,2C2 E4G,4:|
V:3 clef=bass
%%MIDI program 33
|:"C"C4C,4 E4E,4|"F"F4F,4 A4A,4|"G"G4G,4 B4B,4|"C"C4E,4 G4C,4:|
|:"C"C4C,4 E4G,4|"F"F4A,4 C4F,4|"G"G4B,4 D4G,4|"C"C4E,4 G4C,4:|



X:1
T:Emotional Voyage
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Emin
V:1 clef=treble name="Violin" subname="Main Melody"
%%MIDI program 40
|:"Em" B2 A2 | G5  | F2 E2 | D6 ""  |
"D" D2 E2 | "G" G2 A2 | "B" B3 A | "Em" G4 |
"Am" A2 B2 | "C" c2 d2 | "B7" e4 | "Em" B3 A |
"G" G3 F | "D" E4 | F2 D2 |1 "Em" E4 :|2 "B7" E3 F ||
|: "Emin" G2 A2 B  | "D" F2 E3  | "Cmaj" E2 G2 A  | "B7" F2 E3  |
"Am" A2 c2 B  | "Em" G5   | "D" F2 E2 D  | "G" B,5   :|
V:2 clef=treble name="Oboe" subname="Counter Melody"
%%MIDI program 68
|:"Em" e4 | d2 B2 | c2 B2 | A6 ""  |
"D" F2 G2 | "G" A2 G2 | "B" F4 | "Em" B,4 |
"Am" A2 c2 | "C" e2 g2 | "B7" f4 | "Em" e2 d2 |
"G" B3 G | "D" A4 | G2 E2 |1 "Em" E4 :|2 "B7" E3 G ||
|: "Emin" e2 f2 g  | "D" d2 c3  | "Cmaj" c2 e2 f  | "B7" d2 c3  |
"Am" A2 c2 B  | "Em" e2 d3  | "D" d2 c2 B  | "G" e5   :|
V:3 clef=bass name="Cello" subname="Harmony"
%%MIDI program 42
|:"Em" E2 D2 | C5  | B,2 A,2 | "C" G,5  |
"D" A,2 B,2 | "G" G2 F2 | "B" F4 | "Em" B,4 |
"Am" A,2 B,2 | "C" c2 B2 | "B7" E4 | "Em" E2 F2 |
"G" G,3 E | "D" F4 | E2 C2 |1 "Em" E,4 :|2 "B7" E,3 F ||
|: "Emin" E2 F2 G  | "D" D2 C3  | "Cmaj" C2 E2 F  | "B7" B,2 A,3  |
"Am" A,2 C2 B,  | "Em" E2 F3  | "D" A,2 G,3  | "G" G,5   :|



X:1
T:Glorious Battle March
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=170
K:Bm
V:1 clef=treble name="Brass Section"
%%MIDI program 61
|:"Bm"f4 f4 f8 | "A"g4 g4 g8 | "G"f4 e4 d2c2 B4 | "F#7"e4 c4 B4 F4 |
"Bm"f4 f4 f8 | "A"g4 g4 g8 | "G"f4 e4 d2c2 "Bm"B4 | "F#7"e8 F8 :|
|:"Bm"f3g a4 f3g a4 | b3a g8 z4 | f3g a4 f3g a4 | b3a g2f2 e4 z4 |
"A"e3f g4 "D"f3e d4 | "G"c3d e4 "E7"e4 d4 | "Bm"f3g a4 f3g a4 | b3a "F#7"g8 z4 :|
V:2 clef=treble name="String Ensemble"
%%MIDI program 49
|:"Bm"B,4 B,4 z8 | "A"A,4 A,4 z8 | "G"G,4 G,4 z4 "F#7"F4 | "Bm"B,4 B,4 z8 |
"Bm"B,4 B,4 z8 | "A"A,4 A,4 z8 | "G"G,4 G,4 z4 "Bm"B4 | "F#7"F4 F4 z8 :|
|:"Bm"B8- B4 B,4 | "A"A8- A4 A,4 | "G"G8- G4 "F#7"F4 | "Bm"B8- B4 B,4 |
"A"A8- A4 A,4 | "D"D8- D4 d4 | "G"G8- G4 "E7"e4 | "Bm"B8- B4 "F#7"B,4 :|
V:3 clef=alto name="Woodwind Choir"
%%MIDI program 73
|:"Bm"z4 d2B2 d4 z4 | "A"z4 c2A2 c4 z4 | "G"z4 B2F2 G4 z4 | "F#7"z4 A2c2 F4 z4 |
"Bm"z4 d2B2 d4 z4 | "A"z4 c2A2 c4 z4 | "G"z4 B2F2 "Bm"G4 z4 | "F#7"z8 F8 :|
|:"Bm"d4 B4 d8 | "A"c4 A4 c8 | "G"B4 G4 B8 | "F#7"A4 F4 A8 |
"Bm"d4 B4 d8 | "A"c4 A4 c8 | "G"B4 G4 B8 | "F#7"A8 F8 :|
V:4 clef=bass name="Tubas and Bassoons"
%%MIDI program 70
|:"Bm"B,8 B,8 | "A"A,8 A,8 | "G"G,8 G,8 | "F#7"F,8 F,8 |
"Bm"B,8 B,8 | "A"A,8 A,8 | "G"G,8 "Bm"G,8 | "F#7"F,8 F,8 :|
|:"Bm"B,8 B,8 | "A"A,8 A,8 | "G"G,8 G,8 | "F#7"F,8 F,8 |
"Bm"B,8 B,8 | "A"A,8 A,8 | "G"G,8 G,8 | "F#7"F,8 F,8 :|



X:1
T:Pirate Cowboy's Odyssey
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Am
% The acoustic guitar maintains the cowboy feel while the accordion brings the sea shanty vibe
V:1 clef=treble name="Acoustic Guitar"
%%MIDI program 25
|:"Am"A2 AB c2 cd|e2 e>d c2 A>G|"Dm"F2 FG A2 AG|"E"F4 E4|
"Am"A2 z2 e2 f2|g2 g>a f2 e>d|"G"C2 c2 e2 d>c|"Am"A4 A4:|
"Am"e2 c2 "Dm"f2 d2|"G"c2 B2 A2 z2|"Am"A>c "E"B>A "Am"A4|"G"g2 f2 e2 d>c|
"Am"A2 AB c2 cd|e2 e>d c2 A>G|"Dm"F2 FG A2 AG|"E"F4 E4|
"Am"A2 z2 e2 f2|g2 g>a f2 e2|"G"C2 c2 e2 d>c|"Am"A4 A4||
V:2 clef=treble name="Accordion"
%%MIDI program 21
|:"Am"c2 de f2 fg|a2 a>b a2 f>e|"Dm"d2 de f2 ed|"E"c4 B4|
"Am"c2 z2 a2 b2|c'2 c'b a2 g>f|"G"B2 Bc d2 c>B|"Am"A4 A4:|
"Am"a2 f2 "Dm"d2 A2|"G"B2 A2 G2 F2|"Am"A2 "E"G>F "Am"E4|"G"a>g f>e d2 B2|
"Am"c2 de f2 fg|a2 a>b a2 f>e|"Dm"d2 de f2 ed|"E"c4 B4|
"Am"c2 z2 a2 b2|c'2 c'b a2 f2|"G"B2 Bc d2 c>B|"Am"A4 A4||



X:1
T:Reminiscence in Sepia
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=80
K:C
V:1 clef=treble
%%MIDI program 73
|:"C"E2 G4| "Am"A2 c4| "F"F2 A2 G2| "G7"G4 z2|
"C"C3 B A2| "Em"B3 A G2| "Am"A3 G F2| "Dm"D4 z2|
"F"F2 E4| "C"G2 E4| "Am"A2 F2 E2| "Dm"D4 z2|
"G7"B,2 C4| "C"E3 D C2| "Am"A2 G2 "G7"F2| "C"C4 z2:|
V:2 clef=treble
%%MIDI program 71
|:"C"E2 C4| "Am"A2 E4| "F"F2 C2 E2| "G7"D4 z2|
"C"C2 D4| "Em"B2 G4| "Am"A2 E2 C2| "Dm"D4 z2|
"F"F2 D4| "C"A2 F3 E| "Am"E2 C4| "Dm"D4 z2|
"G7"G2 B,4| "C"C2 E4| "Am"A2 G2 "G7"E2| "C"C4 z2:|
V:3 clef=bass
%%MIDI program 43
|:"C"C4 E2| "Am"A,4 E2| "F"F2 A2 C2| "G7"G,2 B,4|
"C"C2 E2 G2| "Em"B,4 G2| "Am"A2 C4| "Dm"D2 F3 A|
"F"F4 A2| "C"C,4 E2| "Am"A,2 E2 A2| "Dm"D4 F2|
"G7"G,2 B,2 D2| "C"C2 E2 C2| "Am"A,4 "G7"E2| "C"C4 z2:|"
abc
X:1
T:Yee Haw Wiggle
C:Lorn
M:12/8
L:1/8
Q:1/4=120
K:Bb
V:1 clef=treble
%%MIDI program 56
|: g3 z3 g3 g3 | f2 g2 b2 g3 z3 | z3 b2 g2 b2 g3 | f2 g2 b2 g3 z3 |
g3 z3 g3 g3 | f2 g2 b2 g3 z3 | z3 b2 g2 b2 b2 c'2 | ^c'2 d'2 f2 ^f3 z3 :|



X:1
T:Pirate's Bold Horizon
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=160
K:Em
V:1 clef=treble name="Fiddle"
%%MIDI program 110
|:"Em"B4 A2 G2 | E4 E2 F2 | "D"G4 G2 F2 | D6 EF |
"D"G4 G2 A2 | "C"B4 B2 AG | "Em"E4 E2 "D"F2 |1 "Em"E6 AB :|2 "Em"E6 z2 ||
|:"Em"e4 e2 fg | "G"a4 a2 gf | "D"g4 f2 e2 | "C"e6 ef |
"Em"g4 e2 G2 | "A"e2 A2 B2 c2 | "D"B4 G2 "Em"F2 |1 "Em"E6 z2 :|2 "Em"E6 AB |]
V:2 clef=treble name="Accordion"
%%MIDI program 21
|:"Em"e4 B2 G2| "D"A4 F2 D2| "B7"B4 f2 e2| "B7"z6 Bc|
"B7"d4 f2 B2| "G"B4 e2 G2| "Em"E4 B,2 "D"A,2|1 "Em"E6 Bc :|2 "Em"E6 z2 ||
|:"Em"e4 e2 B2| "G"g4 g2 e2| "C"c4 B2 G2| "Am"B4 A2 F2|
"Em"B4 B2 e2| "C"G2 E2 F2 G2| "D"A4 d2 "Em"B2|1 "Em"E6 z2 :|2 "Em"E6 Bc |]
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"Em"E4 E2 G2| "D"D4 F2 A2| "B7"B,4 B,2 D2| "B7"z6 G,A,|
"B7"B,4 B,2 B,2| "G"G,4 G,2 B,2| "Em"E4 E2 "D"C2|1 "Em"E6 B,2 :|2 "Em"E6 z2 ||
|:"Em"B,4 E2 G2| "G"G4 D2 F2| "C"C4 E2 G2| "Am"A,4 A,2 C2|
"Em"E4 E2 G2| "C"C4 E2 G2| "D"D4 F2 "Em"A2|1 "Em"E6 z2 :|2 "Em"E6 B,2 |]



X:1
T:Boss Battle Blitz
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Dmin
% Multiple instruments combine for a powerful and rapid soundscape
V:1 clef=treble name="Brass" subname="Main Theme"
%%MIDI program 61
|: "Dm"d2af d2af d4 c2A2 | "C"c2eg c2eg c4 B2G2 | "Bb"d2Bb d2Bb d4 c2A2 | "A"A2ce A2ce A8 |
"Dm"d2af d2af d4 c2A2 | "C"c2eg c2eg c4 B2G2 | "Bb"d2Bb d2Bb d4 c2A2 | "A7"A2ce A2ce A8:|
V:2 clef=treble name="Strings" subname="Rhythm"
%%MIDI program 49
|: "Dm"A4 A4 f4 f4 | "C"G4 G4 e4 e4 | "Bb"F4 F4 d4 d4 | "A"E4 E4 c4 c4 |
"Dm"A4 A4 f4 f4 | "C"G4 G4 e4 e4 | "Bb"F4 F4 d4 d4 | "A7"E4 E4 c4 c4 :|
V:3 clef=treble name="Woodwind" subname="Counter"
%%MIDI program 74
|: "Dm"f2d2 f2d2 AcBA z4 | "C"e2c2 e2c2 GFGA z4 | "Bb"B2F2 B2F2 DEDF z4 | "A"c2A2 c2A2 E4 z4 |
"Dm"f2d2 f2d2 AcBA z4 | "C"e2c2 e2c2 GFGA z4 | "Bb"B2F2 B2F2 DEDF z4 | "A7"c2A2 c2A2 E4 z4 :|
V:4 clef=bass name="Bass"
%%MIDI program 43
|: "Dm"D8 D8 | "C"C8 C8 | "Bb"Bb,8 Bb,6 | "A"A,8 A,8 |
"Dm"D8 D8 | "C"C8 C8 | "Bb"Bb,8 Bb,6 | "A7"A,8 A,8 :|



X:1
T:Stellar Waltz
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:Am
V:1 clef=treble
%%MIDI program 1
|:"Am"A2 c2 e2|"G"B4 A2|"F"E2 A2 c2|"E"E6|
"w1"A3 G FE|"Dm"F3 E DC|"Am"A,3 B, CD|"E7"E3 DCB,|
"A"A2 c2 e2|"C"E2 A2 c2|"F"A2 f2 e2|"E7"G4 G2|
"Am"A2 a2 g2|"G"f2 e2 d2|"Am"c3 B A2|"E7"E6:|
V:2 clef=treble
%%MIDI program 2
||:"Am"E2 E2 E2|"G"D2 D2 D2|"F"C2 C2 C2|"E"B,2 B,2 B,2|
"w1"A,2 A,2 G,2|"Dm"F2 F2 E2|"Am"A,2 B,2 C2|"E7"B,2 B,2 A,2|
"A"E2 E2 A2|"C"E2 A2 c2|"F"A2 c2 A2|"E7"B,2 G2 B,2|
"Am"E2 E2 F2|"G"G2 A2 B2|"Am"A2 G2 E2|"E7"B,4 z2:|
V:3 clef=bass
%%MIDI program 43
||:"Am"A,2 C2 E2|"G"G,2 B,2 D2|"F"F,2 A,2 C2|"E"E,2 G,2 B,2|
"w1"A,2 B,2 A,2|"Dm"D2 F2 E2|"Am"A,2 E2 C2|"E7" B,2 E2 G,2|
"A"A,2 A,2 E2|"C"C2 E2 G2|"F"F2 C2 A2|"E7"G,2 B,2 D2|
"Am"A,2 E2 A,2|"G"G,2 D2 G,2|"Am"A,2 E2 C2|"E7"B,4 z2:|



X:1
T:Triumph of Persistence
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:Cmaj
V:1 clef=treble
%%MIDI program 56
|:"C"E2G2 C2E2|"Am"A2E2 A2c2|"F"F2A2 d2c2|"G7"B2G2 B2D2|
"C"c4-B2A2|"F"A4-G2F2|"G"E4-D2C2|"C" C4 C4|
"C"G3A B2G2|"Am"E4 F2E2|"F"D4 C4|"G"B,4 G4|
"C"C4-E2G2|"Am"A2E2 A2c2|"F"F3G A2F2|"C"G4 G4:|
V:2 clef=treble
%%MIDI program 48
|:"C"c4 e2c2|"Am"e2A2 c3c|"F"a2f2 d2c2|"G7"d2B2 G2D2|
"C"e2c2 d2B2|"F"A4-G2F2|"G"E2C2 D2C2|"C" C4 z4|
"C"e2g2 f2e2|"Am"c2A2 A2c2|"F"a2a2 g2f2|"G"d2B,2 D4|
"C"E2C2 G4|"Am"A2c2 E4|"F"F4 G3A|"C"c2C2 z4:|
V:3 clef=bass
%%MIDI program 42
|:"C"C4 C2C2|"Am"A,4 A,2A,2|"F"F4 F2F2|"G7"G,4 D2G,2|
"C"C4 E2G2|"F"F4 A2c2|"G"G,4 B,2D2|"C"C4 z4|
"C"C,4 E2G2|"Am"A,4 C2E2|"F"F4 F2A2|"G"G,2B,2 D4|
"C"C2E2 G4|"Am"A,2C2 E4|"F"F2A2 c4|"C"C2C2 z4:|



X:1
T:Celebratory Beats
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=128
K:G
V:1 clef=treble
%%MIDI program 56
%%MIDI chordvol 75
%%MIDI gchord fz
|:"G"B4 B2 AG|"Em"E4 E2 FG|"C"G4 G2 FE|"D"D4 D2 EF|
"G"G4- G2 AG|"Em"E4- E2 FG|"C"c6 BA|"D"D6 EF|
"G"B,4 B,2 CD|"C"E4 E2 FG|"Am"A4 A2 G2|"D"B6 AB|
"G"B4 G4|"C"E4- E2 C2|"D"D4 D2 EF|"G"G8:|
V:2 clef=treble
%%MIDI program 73
%%MIDI chordvol 70
%%MIDI gchord fz
|:"G"d3e d2 B2|"Em"e3f g2 e2|"C"c3d e2 c2|"D"d3e f2 d2|
"G"g3a b2 g2|"Em"e3f g2 e2|"C"cB c2 e2 d2|"D"cB A2 F4|
"G"g3a b2 g2|"C"e3f g2 e2|"Am"a3b a2 f2|"D"d3c B2 A2|
"G"d2 B2 G2 B2|"C"e2 c2 E2 c2|"D"d2 A2 D2 F2|"G"G2 B2 D4:|
V:3 clef=bass
%%MIDI program 33
%%MIDI chordvol 70
%%MIDI gchord fz
|:"G"G,4 D4|"Em"B,4 E4|"C"C4 E4|"D"D4 F4|
"G"G,4 D4|"Em"B,4 E4|"C"C4 E4|"D"D4 F4|
"G"G,4 D4|"C"E4 C4|"Am"A,4 E4|"D"D4 A,4|
"G"G,2 B,2 D4|"C"C2 E2 G4|"D"D2 A,2 F4|"G"G,2 D2 G4:|



X:1
T:Salute of Respect
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:Gmaj
V:1 clef=treble name="Horn"
%%MIDI program 60
|:"G"B6 D2| "C"G4 F4| "Am"A6 B2| "D"A4 G4|
"G"G4 B2G2| "Em"B4 G2F2| "C"E6 G2| "D"D4 D4 |
"G"B6 D2| "C"G4 F4| "Am"A6 B2| "D"D4 G4|
"G"G4 B2G2|"C"E4 D4| "G"B2G2 A2G2|"D"D4 G4:|
V:2 clef=treble name="Strings"
%%MIDI program 48
|:"G"D4 G2B2| "C"C4 C4| "Am"A4 c2e2| "D"D4 F2A2|
"G"G4 G2B2| "Em"B4 A2G2| "C"C4 E2C2| "D"D4 F2D2|
"G"D4 G2B2| "C"C4 C4| "Am"A4 c2e2| "D"D6 D2|
"G"G4 G2B2|"C"C4 E2C2| "G"G4 G2B2|"D"D4 G4:|
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"G"G,4 D4| "C"C4 E4| "Am"A,4 E4| "D"D,4 A,2D2|
"G"G,4 D4| "Em"E4 G,2B,2| "C"C4 G,4| "D"D,4 A,2D2|
"G"G,4 D4| "C"C4 E4| "Am"A,4 E4| "D"D,6 D2|
"G"G,4 D4|"C"E4 C4| "G"G,4 B,2D2|"D"D,4 G,4:|



X:1
T:Midnight Festivities
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=130
K:Cmaj
V:1 clef=treble
%%MIDI program 56
|:"C"E2 G4 G2|"Am"A4 A2 Bc|"F"d4 cB AG|"G"E6 D2|
"C"c2 e4 e2|"Am"c2 B2 A2 G2|"F"f2 a4 g2|"G"e6 c2|
"Dm"d2 f4 a2|"G"g2 f2 e2 d2|"C"e2 c4 G2|"G"B6 z2|
"C"G2 G2 E2 E2|"F"F2 A2 F2 D2|"G"E2 G2 C2 G,2|"C"C6 z2:|
V:2 clef=treble
%%MIDI program 56
|:"C"c2 e4 e2|"Am"a2 g2 f2 e2|"F"d2 f4 f2|"G"d6 B2|
"C"c2 e4 e2|"Am"a4 g2 f2|"F"f2 a4 a2|"G"e6 A2|
"Dm"A2 f4 d2|"G"B2 A2 G2 F2|"C"c2 e4 c2|"G"B6 z2|
"C"E2 C2 G,2 E2|"F"F2 A2 F2 D2|"G"B,2 D2 G2 E2|"C"C6 z2:|
V:3 clef=bass
%%MIDI program 33
|:"C"C,2 E,4 G,2|"Am"A,4 A,2 B,2|"F"C4 F,2 A,2|"G"D6 G,2|
"C"c4 E4|"Am"A,4 A,2 B,2|"F"A2 C4 E2|"G"D6 G,2|
"Dm"F4 A4|"G"G,4 D4|"C"E4 C4|"G"B,6 z2|
"C"C4 E4|"F"F4 A4|"G"G,4 D4|"C"C,6 z2:|



X:1
T:Symphonic Narrative
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:G
V:1 clef=treble name="Flute"
%%MIDI program 73
%%MIDI balance 64
[| "G" G2 B2 d4 | "C" c4 B2 A2 | "D" F4 A2 d2 | "Em" e6 z2 |
"G" d2 e2 g2 f2 | "Am" a4 (3gfe z4 | "Bm" d4 (3edc z4 | "C" B6 z2 |
"D" a2 f2 g4- | g2 a2 b2 a2 | "G" g3 f e2 d2 | "C" c6 z2 |
"G" B2 G2 E2 G2 | "A7" A2 F2 F2 A2 | "D7" D2 F2 A2 F2 | "G" G8 |
"Em" e4 (3Bcd e2 z2 | "A" c4 A2 (3Bcd z2 | "D7" d4 F2 (3AGF z2 | "G" G8 |]
V:2 clef=treble name="Violin"
%%MIDI program 40
%%MIDI balance 96
[| "G" (3GAB d2 B2 z4 | "C" c4 E2 D2 | "D" (3FGA B2 d2 z4 | "Em" e4 z2 G2 |
"G" B2 A2 (3GAB z4 | "Am" A4 A2 G2 | "Bm" (3def g2 f2 z4 | "C" c6 z2 |
"D" (3efe d2 F2 z4 | "G" (3GFG (3ABA G2 | "G" (3Bcd e2 (3dcB | "C" c4 z2 A2 |
"G" (3GAB (3cde d2 | "A7" (3AEF A2 c2 z4 | "D7" (3FDF A2 d2 z4 | "G" G4 z4 |
"Em" (3efg (3agf e2 | "A" (3abc' a2 f2 z4 | "D7" d4 (3cBA G2 z2 | "G" G8 |]
V:3 clef=treble name="Cello"
%%MIDI program 42
%%MIDI balance 80
[| "G" G,2 G,2 D,2 D,2 | "C" C,2 C,2 G,2 G,2 | "D" D,2 D,2 A,2 A,2 | "Em" E,2 E,2 z4 |
"G" (3GGG (3DDD (3GGG (3DDD | "Am" A,2 G,2 E,2 C,2 z4 | "Bm" D4 (3DED | "C" C4 C,4 |
"D" D2 F2 A2 d2 | "G" G,2 B,2 d2 g2 z4 | "G" g2 (3fed B2 | "C" c2 C2 z2 G,2 z4 |
"G" G,2 D2 (3GAB z4 | "A7" A,2 E2 (3cde z4 | "D7" d2 A2 (3FDF | "G" G,4 z4 z4 |
"Em" E2 (3GAB c2 z4 | "A" A2 (3cde d2 | "D7" D4 d4 | "G" G,8 |]
V:4 clef=bass name="Double Bass"
%%MIDI program 43
%%MIDI balance 112
[| "G" G,,4 D,,4 | "C" C,,4 G,,4 | "D" D,,4 A,,4 | "Em" E,,6 z2 |
"G" D,,2 G,,2 D,,2 G,,2 | "Am" C,,2 E,,2 A,,2 A,,2 | "Bm" D,,4 G,,2 F,,2 | "C" C,,6 z2 |
"D" A,,4 D,,4 | "G" G,,4 D,,4 | "G" G,,2 F,,2 E,,2 D,,2 | "C" C,,6 z2 |
"G" D,,2 G,,2 B,,2 D,2 | "A7" E,,2 A,,2 (3cdc e2 z2 | "D7" A,,4 D,4 | "G" G,,8 |
"Em" E,,4 G,,4 | "A" A,,4 (3cBc d2 z2 | "D7" D,,8 | "G" G,,8 |]



X:2
T:Trombone Trio Journey
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Gm
V:1 clef=treble name="Trombone I"
%%MIDI program 57
|:"Gm"G2 B2 | A2 G2 | F2 E2 | D4 |
|:"Cm"E2 G2 | F2 E2 | D2 C2 | B,4 |
|"D"A2 c2 | B2 A2 | G2 F2 | E4 |
|"Gm"G2 B2 | A2 G2 | F2 E2 | G4 |
V:2 clef=alto name="Trombone II"
%%MIDI program 57
|:"Gm"D2 F2 | E2 D2 | C2 B,2 | A4 |
|:"Cm"G2 E2 | F2 G2 | A2 B2 | c4 |
|"D"d2 F2 | e2 d2 | c2 B,2 | A4 |
|"Gm"D2 F2 | E2 D2 | C2 B,2 | D4 |
V:3 clef=bass name="Trombone III"
%%MIDI program 57
|:"Gm"G,2 D2 | C2 B,2 | A,2 G,2 | F,4 |
|:"Cm"C2 E2 | D2 F2 | E2 G2 | A,4 |
|"D"D2 A,2 | G,2 F,2 | E,2 D,2 | C,4 |
|"Gm"G,2 D2 | C2 B,2 | A,2 G,2 | G,4 |



X:1
T:Boss's Challenge
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=140
K:Gmin
% The piece starts with an aggressive and powerful exposition, setting the stage for the battle.
V:1 clef=treble name="Brass" subname="Lead"
%%MIDI program 61
|:"Gm"d2c2B2A2 G4F4|G2A2B2d2 c4B4|d2c2B2A2 G4F4|G2A2B2d2 c8|
A2^F2G2A2 d4F4|A2B2c2d2 d2c2B2A2|G2^F2G2A2 d4F4|G2A2B2d2 c8:|
V:2 clef=treble name="Strings" subname="Rhythm"
%%MIDI program 49
|:"Gm"z4 G4 z4 B4|z4 A4 z8|z4 G4 z4 B4|z4 A4 z8|
z4 d4 z4 F4|z4 d4 z4 d4|z4 d4 z4 F4|z4 d4 z8:|
V:3 clef=bass name="Bassline" subname="Bass"
%%MIDI program 39
|:"Gm"G,8 F,8|D,8 B,8|G,8 F,8|D,8 B,8|
d,8 d,8|d,8 d,8|d,8 d,8|D,8 B,8:|
V:4 clef=treble name="Synth Pad" subname="Pad"
%%MIDI program 92
|:"Gm"B4d4 F4B4|A4d4 F4A4|B4d4 F4B4|A4d4 F4A4|
d2c2B2A2 d4B4|d2c2B2A2 c4B4|d2c2B2A2 d4B4|A4d4 F8:|



X:1
T:Bandstand Groove
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=128
K:F
% The tune is structured to prioritize energy, fun, and engagement among band members
V:1 clef=treble name="Electric Guitar" subname="Gtr."
%%MIDI program 30
|: "F"C2C2 F4 | "Bb"D4 D2 F2 | "F"A2A2 A2 c2 | "C7"G4 E4 |
"F"C2C2 F4 | "Bb"D4 D2 F2 | "F"A2G2 F2E2 |1 "C7"G4 z4 :|2 "C7"G4 E4 |:
V:2 clef=treble name="Trumpet" subname="Tpt."
%%MIDI program 56
|: "F"F4 A2 c2 | "Bb"d2c2 B2A2 | "F"c2A2 c4 | "C7"B2A2 G4 |
"F"F4 A2 c2 | "Bb"d2c2 B2A2 | "F"c2B2 A2G2 |1 "C7"F4 z4 :|2 "C7"F4 E4 |:
V:3 clef=treble name="Alto Sax" subname="Sax."
%%MIDI program 65
|: "F"f4 c2A2 | "Bb"B4 g2f2 | "F"A4- A2 A2 | "C7"e4 c4 |
"F"f4 c2f2 | "Bb"B4 g2f2 | "F"A4 G4 |1 "C7"F4 z4 :|2 "C7"F4 c4 |:
V:4 clef=bass name="Electric Bass" subname="Bass"
%%MIDI program 33
|: "F"F,4 F,4 | "Bb"B,4 B,4 | "F"F,2C2 F,4 | "C7"G,4 E,4 |
"F"F,4 F,4 | "Bb"B,4 D,4 | "F"A,4- A,2 G,2 |1 "C7"F,4 z4 :|2 "C7"F,4 C,4 |:
% Optional improvisation sections for the band members



X:1
T:Hero's Clash
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=160
K:Gmin
% The piece begins with an intense and driving theme
V:1 clef=treble name="Brass" subname="Horns"
%%MIDI program 61
|:"Gm"d4d4 d4e2f2|"Dm"a4a4 a4g2f2|"Gm"g8- g4f4| "Eb"e8- e4d4|
"Cm"c4B2G2 "F"B4A2G2|"Bm"F8- F4G4|"Gb"b8- b4a4|"Gm"g8 z4 z4:|
V:2 clef=treble name="Strings" subname="Cellos"
%%MIDI program 42
|:"Gm"B2G2D2G2 B2G2D2G2|"Dm"F2D2A2D2 F2D2A2D2|"Gm"G,2G2B2D2 G,2G2B2D2| "Eb"E2E2G2B2 E2E2G2B2|
"Cm"C2C2E2G2 "F"A2A2c2e2|"Bm"F4F4 "Gb"B4B4|"Gm"D8- D4G4:|
V:3 clef=bass name="Bass" subname="Bass"
%%MIDI program 43
|:"Gm"D,8 D,4G,4|"Dm"F,8 F,4A,4|"Gm"G,8- G,4D,4| "Eb"E,8- E,4G,4|
"Cm"C,4B,,2G,,2 "F"F,4E,2D,2|"Bm"F,8- F,4G,4|"Gb"B,,8- B,,4F,4|"Gm"G,8 z4 z4:|
% counter melody for intensity
V:4 clef=treble-8 name="Synth" subname="Lead"
%%MIDI program 81
||:"Gm"d2c2B2A2 G2F2E2D2|"Dm"F2E2D2C2 A2G2F2E2|"Gm"G4- G2F2 G2A2B2A2|"Eb"G4- G2F2 E2D2C2B2|
"Cm"G2F2E2D2 "F"F2E2D2C2|"Bm"D2E2F2G2 "Gb"A2B2c2d2|"Gm"B4 A2G2 F2E2D2C2:|



X:1
T:Clash with the Colossus
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Em
V:1 clef=treble name="Violins" subname="Vln."
%%MIDI program 40
|:"Em"B4e4g4b4|"D" a8 f8|"C"G4c4e4g4|"Bm"f8 d8|
"Am"e4a4c'4e'4|"B"d8 g8|"G"B4d4g4b4|"A"a8 ^f8|
"Em"e2B2 g2e2 f2d2 e4|"D"d2A2 f2d2 e2c2 d4|
"C"c2G2 e2c2 d2B2 c4|"Bm"b2f2 d2b2 c2a2 b4|
"Am"c2e2 a2c2 b2g2 a4|"B"b2g2 f2d2 e2B2 g4|
"G"g2B2 d2g2 a2f2 g4|"A"a2^f2 g2a2 b4 z4:|
V:2 clef=treble name="Brass" subname="Br."
%%MIDI program 61
|:"Em" E2B2 E2G2| "D"D2A2 D2^F2| "C"C2G2 C2E2| "Bm"B2^F2 B2D2 |
"Am"A2E2 A2C2| "B"G2D2 G2B2| "G"G2D2 G2B2| "A"A2E2 A2^F2|
"Em" E4 B4 ^G4 ^F4|"D" D4 A4 ^F4 E4|"C" C4 G4 E4 D4|"Bm" B4 ^F4 D4 ^C4|
"Am"A4 E4 C4 B4|"B" G4 D4 B4 A4|"G" G4 D4 B4 ^G4|"A" A4 E4 ^F4 G4:|
V:3 clef=bass name="Cellos" subname="Vc."
%%MIDI program 42
|:"Em" E,4 E,4|"D" D,4 D,4|"C" C,4 C,4|"Bm" B,4 B,4|
"Am" A,4 A,4|"B" ^F,4 ^F,4|"G" G,4 G,4|"A" A,4 A,4|
"Em" E,2 ^F,2 G,2 A,2|"D" D,2 E,2 ^F,2 ^G,2|
"C" C,2 D,2 E,2 ^F,2|"Bm" B,2 ^C,2 ^D,2 E,2|
"Am" A,2 B,2 C2 D2|"B" ^F,2 ^G,2 A2 B2|
"G" G,2 A,2 B2 C2|"A" A,2 B,2 ^C2 ^D2:|
V:4 clef=bass name="Contrabass" subname="Cb."
%%MIDI program 43
|:"Em" E,8| "D"D,8| "C"C,8| "Bm"B,8|
"Am" A,8| "B" ^F,8| "G" G,8| "A" A,8|
"Em" z2 E,16| "D" z2 D,16| "C" z2 C,16| "Bm" z2 B,16|
"Am" z2 A,16| "B" z2 ^F,16| "G" z2 G,16| "A" z2 A,16:|



X:1
T:Twinkle Twinkle Little Star (Arrangement)
C:OrchestrAI
M:2/4
L:1/8
Q:1/4=100
K:C
%%score (V1 V2) (V3 V4)
V:V1 clef=treble
%%MIDI program 0
|: "C"c4  | "C"e4   | "F"f4   | "C"e4   | "G"d4   | "C"c4   :|
V:V2 clef=treble
%%MIDI program 0
|: G,4   | E4     | C4     | G,4    | C4    | C4    :|
V:V3 clef=alto
%%MIDI program 42
|: "Am" A,4 | "Am"E4    | "F"A,4   | "C"E4     | "G"g,4    | "C"c4     :|
V:V4 clef=bass
%%MIDI program 32
|: "C"C,4 | "C"G,4    | "F"F,4   | "C"C,4    | "G"D,4    | "C"C,4    :|
|: "F"c4  | "F"a4   | "F"f4   | "C"c4  | "G"g4   | "C"c4   :|
|: "Am"A,4 | "Am"e4     | "F"A,4   | "C"e4     | "G"g,4     | "C"c4     :|



X:1
T:Mystic Exploration
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=108
K:Dmin
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"Dm"D3E FG|"A7"A2G2 FE|"Dm"D2A2 d2|"Gm"c2B2 AG|"A7"A4G2|
"F" F3G Ac|"Bb"d3c de|"A7"f2e2 dc|"Dm"d4 z2:|
|:"Fmaj7"c2A2 F2|"C"c3B AG|"Bb"D2F2 G2|"A7"A6|
"Dm"A2B2 c2|"Gm"B2A2 G2|"A7"F2E2 FG|"Dm"D4 z2:|
V:2 clef=treble name="Clarinet"
%%MIDI program 71
|:"Dm"A2F2 D2|"A7"G2E2 C2|"Dm"D2A,2 D2|"Gm"G,A,2 z B,2|"A7"C4A,2|
"F"F2C2 F2|"Bb"B,D2 z E2|"A7"A2F2 G2|"Dm"A4 z2:|
|:"Fmaj7"c2A2 c2|"C"E2G2 c2|"Bb"E2C2 A2|"A7"G4 F2|
"Dm"D3E FG|"Gm"G,A,2 z B,2|"A7"C3D EF|"Dm"D4 z2:|
V:3 clef=treble name="Oboe"
%%MIDI program 68
|:"Dm"D4F2|"A7"A2c2f2|"Dm"d2a2 fd|"Gm"b2g2 e2|"A7"c4 A2|
"F"A2f2 c2|"Bb"d2F2 B2|"A7"e2c2 A2|"Dm"F4 z2:|
|:"Fmaj7"A4 c2|"C"c2 e2 g2|"Bb"d2 f2 b2|"A7"a4 g2|
"Dm"f2 e2 d2|"Gm"g2 a2 b2|"A7"a2 g2 f2|"Dm"d4 z2:|
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"Dm"D2 A,2 D2|"A7"E2 C2 E2|"Dm"D4 F2|"Gm"B,2 D2 G2|
"A7"A,2 C2 E2|"F"F4 A2|"Bb"B,2 F2 B2|"A7"C2 E2 G2|
"Dm"D4 z2:| |:"Fmaj7"C4 F2|"C" G,2 C2 E2|"Bb"D2 A,2 D2|
"A7"E2 C2 E2|"Dm"F2 D2 F2|"Gm"G2 B,2 D2|"A7"A,2 E2 C2|
"Dm"D4 z2:|



X:1
T:Dimensions in Pursuit
C:OrchestrAI
M:7/8
L:1/8
Q:3/8=140
K:Cmin
V:1 clef=treble name="Electric Lead"
%%MIDI program 81
|:"Cm"G4 z Bd | "G"G4 z BG | "Cm"E6 z | E2 D2 E2 F |
"Fm"F3 G A2 B | "G"G4 ABc | "Cm"B2 c2 "Bdim"B3  | "G"G2 E2 G3 :|
|:"Fm"F3 G A2 B | "G"G4 ABc | "Cm"B2 c2 "Bdim"B3  | "G"G2 E2 G3 |
"Cm"G4 z E2 | "Fm"F4 z F2 | "G7"G2 A2 B3 | "Cm"C6 z :|
V:2 clef=treble name="Strings"
%%MIDI program 48
|:"Cm"e4 z fe | "G"g4 z gd | "Cm"c6 z | c2 B2 c2 d |
"Fm"af3 g2 f | "G"g4 a2b | "Cm"c'2 b2 "Bdim"a3  | "G"e2 c2 e3 :|
|:"Fm"af3 g2 f | "G"g4 a2b | "Cm"c'2 b2 "Bdim"a3  | "G"e2 c2 e3 |
"Cm"e4 z c2 | "Fm"f4 z f2 | "G7"ag2 b3 z | "Cm"c6 z :|
V:3 clef=bass name="Bass Synth"
%%MIDI program 87
|:"Cm"C,4 z C,G, | "G"G,,4 z G,D, | "Cm"C,6 z | C,2 C,2 C,2 C, |
"Fm"F,4 F,2 F, | "G"G,,4 G,DG, | "Cm"C,3 C, "Bdim"B,,C, z | "G"G,2 C,2 G,3 :|
|:"Fm"F,4 F,2 F, | "G"G,,4 G,DG, | "Cm"C,3 C, "Bdim"B,,C, z | "G"G,2 C,2 G,3 |
"Cm"C,4 z C,2 | "Fm"F,4 z F,2 | "G7"G,2 A,2 B,,3 | "Cm"C,6 z :|



X:1
T:Rhythmic Harmony
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:G
V:1 clef=treble name="Flute"
%%MIDI program 73
| "G" G4 B2d2 | "Em" e4 g2b2 | "C" c4 e2g2 | "D" d4 f#2a2 |
| "G" b4 d2g2 | "Em" g4 b2e2 | "C" e4 g2c2 | "D" a4 f#2d2 |
| "G" g4 a4 | "Em" e4 d4 | "C" c4 B2A2 | "D" A4 F#2E2 |
| "G" G6 z2 | "Em" E6 z2 | "C" C6 z2 | "D" D6 z2 |
V:2 clef=alto name="Oboe"
%%MIDI program 68
| "G" B3g g2d2 | "Em" g3e e2b2 | "C" e3c c2g2 | "D" f#3d d2a2 |
| "G" d3g g2b2 | "Em" b3e e2g2 | "C" g3c c2e2 | "D" a3f# f2d2 |
| "G" a4 g4 | "Em" d4 e4 | "C" B4 A4 | "D" F#4 E4 |
| "G" g6 z2 | "Em" e6 z2 | "C" c6 z2 | "D" d6 z2 |
V:3 clef=bass name="Bassoon"
%%MIDI program 70
| "G" G,4 D,2G,2 | "Em" E,4 B,2E,2 | "C" C,4 G,2C,2 | "D" D,4 A,2D,2 |
| "G" D,4 G,2D,2 | "Em" B,4 E,2B,2 | "C" G,4 C,2G,2 | "D" A,4 D,2A,2 |
| "G" G,6 D,2 | "Em" E,6 B,2 | "C" C,6 G,2 | "D" D,6 A,2 |
| "G" G,8 | "Em" E,8 | "C" C,8 | "D" D,8 |
V:4 clef=bass name="Acoustic Bass"
%%MIDI program 33
| "G" G,,4 D,,2G,,2 | "Em" E,,4 B,,2E,,2 | "C" C,,4 G,,2C,,2 | "D" D,,4 A,,2D,,2 |
| "G" D,,4 G,,2D,,2 | "Em" B,,4 E,,2B,,2 | "C" G,,4 C,,2G,,2 | "D" A,,4 D,,2A,,2 |
| "G" G,,6 D,,2 | "Em" E,,6 B,,2 | "C" C,,6 G,,2 | "D" D,,6 A,,2 |
| "G" G,,8 | "Em" E,,8 | "C" C,,8 | "D" D,,8 |



X:1
T:Moment of Revelation
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:Am
V:1 clef=treble
%%MIDI program 2
|:"Am"e4 A2c2|"E"B4 A2G2|"F"E4- E2C2|"C"E6 z2|
"A"^G4- G2A2|"Dm"F4- F2E2|"G"B2A2 "E7"G2F2|"Am"E8|
"A/C#"e4 "Dm"F2A2|"F"E4 "E7"c2B2|"A"^G4 "Am"A2G2|"A7"F4 "Dm"E4|
"E7"G4 "Am"E2C2|"F"E6 "C"E2|"G"G3A B2G2|"Am"A8:|
V:2 clef=treble
%%MIDI program 2
|:"Am"A,4 E2A,2|"E"B,4 A,2^G,2|"F"C4- C2E2|"C"E6 z2|
"A"^G,4- ^G,2A,2|"Dm"A,4- A,2G,2|"G"B,2A,2 "E7"^G,2F,2|"Am"E8|
"A/C#"A,4 "Dm"F2D2|"F"C4 "E7"E2^G2|"A"^A,4 "Am"A,2=^G,2|"A7"F4 "Dm"E4|
"E7"^G,4 "Am"E2A,2|"F"C6 "C"C2|"G"G,4 A,2F,2|"Am"A,8:|
V:3 clef=bass
%%MIDI program 33
|:"Am"A,4 C2E2|"E"B,4 ^G,2F,2|"F"F4- F2A,2|"C"C6 z2|
"A"A,4 "Dm"D2F2|"G"B,4- B,2A,2|"G"B,2A,2 "E7"^G,2F,2|"Am"A,8|
"A/C#"C4 "Dm"F2D2|"F"C4 "E7"B,2^G,2|"A"A,4 "Am"A,2^G,2|"A7"F4 "Dm"D4|
"E7"^G,4 "Am"A,2F,2|"F"C6 "C"z2|"G"G,4 A,2F,2|"Am"A,8:|




X:1
T:Sunset Sailing
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=88
K:C
V:1 clef=treble
%%MIDI program 73
|:"C"E4 G2|"Am"A3 G E2|"F"F4 E2|"G"G3 F D2|
"C"C4 E2|"Dm"D4 ^C2|"G"E3 D B,2|"C"C6|
"E7"e4 ^d2|"Am"c3 B A2|"Dm"d4 c2|"G"G3 F D2|
"F"A4 G2|"E7"G3 F E2|"Am"A4 ^G2|"D"=G6|
"C"E4 G2|"Am"A3 G E2|"F"F4 E2|"G"G3 F D2|
"C"C4 E2|"Dm"D4 ^C2|"G"E3 D B,2|"C"C6:|
V:2 clef=bass
%%MIDI program 48
|:"C"C,4 E2|"Am"A,4 C2|"F"F,4 A,2|"G"B,,4 D2|
"C"C4 E2|"Dm" D,3 C A,2|"G"B,,4 D2|"C"C,6|
"E7"G,4 B,,2|"Am"A,3 G, E,2|"Dm"D,4 F,2|"G"G,4 D2|
"F"F,4 A,2|"E7"E,4 G,2|"Am"A,4 C2|"D"A,4 F,2|
"C"C,4 E2|"Am"A,4 C2|"F"F,4 A,2|"G"B,,4 D2|
"C"C4 E2|"Dm"D,4 F,2|"G"B,,4 D2|"C"C,6:|



X:1
T:Run for the Roses: Manifold Derby Anthem
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=140
K:Cmaj
% The tune captures the spirit of the derby - a celebration of speed, grace, and competition.
V:1 clef=treble name="Piccolo" subname="Pc."
%%MIDI program 72
|:"C"E4 G4 c4|"G"d4 B4 G4|"Am"e4 c4 A4|"F" F4 A4 c4|
"C"G4 E4 C4|"Dm"F4 D4 F4|"G7"E4 D4 G,4|"C"C4 z4 z4:|
V:2 clef=treble name="Trumpet" subname="Trpt."
%%MIDI program 56
|:"C"c2 c4 z4 e2|"G"g2 d4 z4 B2|"Am"a2 e4 z4 c2|"F"a2 f4 z4 a2|
"C"c4 G4 z2 E2|"Dm"A2 F4 z4 D2|"G7"B2 e4 z4 d2|"C"c4 z4 z4:|
V:3 clef=bass name="Tuba" subname="Tuba"
%%MIDI program 58
|:"C"C,4 C,2 z4 C,2|"G"G,4 G,2 z4 D,2|"Am"A,4 A,2 z4 E,2|"F"F,4 A,2 z2 C,4|
"C"E,4 C,4 G,4|"Dm"F,4 D,4 A,4|"G7"G,4 B,2 z4 D2|"C"C,4 z4 z4:|
V:4 clef=bass name="String Bass" subname="Bass"
%%MIDI program 43
|:"C"C,4 E,4 C,4|"G"G,4 B,4 G,4|"Am"A,4 C4 A,4|"F"F,4 A,4 F,4|
"C"C,4 G,4 E,4|"Dm"D,4 A,4 F,4|"G7"B,4 D4 G,4|"C"C,4 z4 z4:|



X:1
T:Chamber Musings
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=92
K:G
V:1 clef=treble name="Violin I"
%%MIDI program 40
[V:1]
"G" D2 B2 A2 G2 | F2 E2 D4 | G2 A2 B2 c2 | d4 z4 |
w: (A)
"G" B2 G2 E2 D2 | G2 A2 F2 G2 | "Am" E2 A2 c2 e2 | "D7" d4 z4 |
"G" D2 B2 A2 G2 | "C" E2 G2 c4 | "G" B2 G2 D2 B,2 | "D7" A4 z4 |
w: (B)
"Em" B2 e2 g2 f2 | "Bm" d2 B2 A2 F2 | "Am" E2 A2 c2 B2 | "D7" G4 F4 |
"G" G2 A2 B2 c2 | d4 z2 d2 | "C" c2 B2 A2 G2 | F4 z4 |
w: (A1)
"G" D2 B2 A2 G2 | F2 E2 D4 | G2 F2 E2 D2 | "G" G4 z4 |]
V:2 clef=treble name="Violin II"
%%MIDI program 40
[V:2]
"G" G2 F2 E2 D2 | B4 z4 | c2 d2 e2 f2 | e2 d2 c2 B2 |
w: (A)
"G" G2 c2 e2 d2 | B2 G2 A2 F2 | "Am" A2 c2 e2 d2 | "D7" A4 z4 |
"G" G2 F2 G2 A2 | "C" c4 B2 G2 | "G" D2 G2 B2 G2 | "D7" F4 z4 |
w: (B)
"Em" e2 B2 d2 c2 | "Bm" A2 F2 d2 e2 | "Am" G2 B2 F2 G2 | "D7" A4 G4 |
"G" G2 F2 E2 D2 | c4 z2 A2 | "C" B2 A2 G2 F2 | E4 z4 |
w: (A1)
"G" G2 F2 E2 D2 | B4 z4 | c2 B2 A2 G2 | "G" G4 z4 |]
V:3 clef=alto name="Viola"
%%MIDI program 41
[V:3]
"G" B,2 D2 G2 F2 | E2 G2 A2 G2 | "C" E2 F2 G2 A2 | G2 E2 D4 |
w: (A)
"G" B,2 D2 G2 E2 | F2 D2 G2 E2 | "Am" A2 c2 e2 c2 | "D7" A2 F2 D4 |
"G" B,2 D2 G2 A2 | "C" c2 E2 G4 | "G" B2 D2 G2 B,2 | "D7" G2 F2 E4 |
w: (B)
"Em" B2 g2 e2 d2 | "Bm" A2 d2 B2 G2 | "Am" A2 c2 E2 D2 | "D7" G2 F2 E4 |
"G" B2 c2 d2 e2 | G2 d2 c2 B2 | "C" c2 B2 A2 G2 | E2 D2 C4 |
w: (A1)
"G"B,2 D2 G2 F2 | E2 G2 A2 G2 | "Em" B,2 A2 G2 F2 | "G" G4 z4 |]
V:4 clef=bass name="Cello"
%%MIDI program 42
[V:4]
"G" G,2 B,2 D2 G2 | G,2 A,2 B,2 C2 | "C" E,2 G,2 C4 | D2 G,2 E4 |
w: (A)
"G" G,2 B,2 D2 G2 | G2 D2 G2 B,2 | "Am" A,2 E2 A4 | "D7" D2 A,2 F2 D2 |
"G" G,2 B,2 G2 A2 | "C" C4 E2 G2 | "G" D2 G,2 D4 | "D7" A,2 G,2 F2 D2 |
w: (B)
"Em" E4 G4 | "Bm" B,2 D4 z2 | "Am" A,2 c2 E2 C2 | "D7" D4 E4 |
"G" G,2 B,2 D4 | G4 z2 D2 | "C" C2 E2 G4 | C,,2 D,,2 E,,4 |
w: (A1)
"G" G,2 B,2 D2 G2 | G,2 A,2 B,2 C2 | "C" E,2 G,2 C4 | "G" G,4 z4 |]



X:1
T:Journey Through Dawn
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=96
R:Rhapsody
K:Gmaj
V:1 clef=treble name="Flute"
%%MIDI program 73
M:3/4
|:"G" d2 B2 G2 | "C" e2 c2 E2 | "Am" A2 F2 D2 | "D7" F2 D2 G,2 :|
M:4/4
|:"G" B3 A G2 F2 | "Em" E2 G2 F2 E2 | "C" D3 C B,2 C2 |1 "D" D2 F2 A3 G :|2 "D7" D2 F2 A3 A |
M:3/4
%%MIDI program change 71
V:2 clef=treble name="Piano RH"
M:3/4
|:"G" G2 z2 z2 | "C" c2 z2 z2 | "Am" A2 z2 z2 | "D7" d2 z2 z2:|
M:4/4
|:"G" G2 G2 B2 d2 | "Em" e4 E2 z2 | "C" c2 C2 E2 G2 |1 "D" d2 D2 F2 A2 :|2 "D7" d2 D2 F2 A2|
M:3/4
%%MIDI program change 1
V:3 clef=bass name="Piano LH"
|:"G" G,2 D2 G2 | "C" C2 G,2 C2 | "Am" A,2 E2 A2 | "D7" D2 A,2 D2 :|
M:4/4
|:"G" G,2 B,2 d2 g2 | "Em" e2 A2 B2 G2 | "C" C2 G,2 c2 e2 |1 "D" D2 F2 A2 d2 :|2 "D7" D2 A,2 D2 F2|
M:3/4
%%MIDI program change 42
V:4 clef=treble-8 name="Cello"
|:"G" G,3 D3 | "C" C3 G,3 | "Am" A,3 E3 | "D7" D3 A,3 :|
M:4/4
|:"G" G,2 D2 G,2 B,2 | "Em" E3 G,3 | "C" C2 G,2 C2 E2 |1 "D" D3 F3 :|2 "D7" D3 A,3 |
[| "Gmaj7" G4- G4 z4 | "Cmaj9" c4 e4 g4 | "D" d4 f4 a4 | "Gmaj7" g4 b4 d'4 |
"Cmaj9" e4 g4 c'4 | "D" a4 f4 d4 | "B7" b2 f2 b4 | "Em" e4 g4 b4 |
"Am7" a4 c4 e4 | "D7" f4 a4 c'4 | "Gmaj7" g2 b2 d'4 | "Cmaj9" (3ece (3g2g4 | "D" d4 f4 a4 | "Gmaj7" g4- |]



X:1
T:Photo Finish Frenzy
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Em
V:1 clef=treble name="Electric Guitar"
%%MIDI program 28
|: "Em" E4G4B4e4 | "D" ^F4A4d4f4 | "C" G4c4e4g4 | "B7" ^F4B4d4f4 :|
|: "Em" e2B2G2E2 B,2E2G2B2 | "D" d2A2^F2D2 A,2D2^F2A2 | "C" c2G2E2C2 G,2C2E2G2 | "B7" B2^F2D2B,2 ^F,2B,2D2^F2 :|
|: "Em" (3B,EG (3BEG (3BEG (3beg | "D" (3A,D^F (3AD^F (3AD^F (3ad^f | "C" (3G,CE (3GCE (3GCE (3gce | "B7" (3^F,B,D (3^FB,D (3^FBD (3^fbd :|
V:2 clef=treble name="Synthesizer"
%%MIDI program 81
|: "Em" E6G2B6e2 | "D" ^F6A2d6f2 | "C" G6c2e6g2 | "B7" ^F6B2d6f2 :|
|: "Em" B,4E4G4B4 | "D" A,4D4^F4A4 | "C" G,4C4E4G4 | "B7" ^F,4B,4D4^F4 :|
|: "Em" E2G2B2e2 G2B2e2g2 | "D" ^F2A2d2f2 A2d2f2a2 | "C" G2c2e2g2 c2e2g2c'2 | "B7" ^F2B2d2f2 B2d2f2b2 :|
V:3 clef=bass name="Synth Bass"
%%MIDI program 38
|: "Em" E,16- | E,12^D,2E,2 | "C" C,16- | C,12B,,2C,2 :|
|: "Em" E,4B,,4E,4G,4 | "D" D,4A,,4D,4^F,4 | "C" C,4G,,4C,4E,4 | "B7" B,,4^F,,4B,,4D,4 :|
|: "Em" [E,16B,,16] | "D" [D,16A,,16] | "C" [C,16G,,16] | "B7" [B,,16^F,,16] :|
V:4 clef=bass name="Sawtooth Wave"
%%MIDI program 82
|: "Em" [E,4B,,4] [G,4E,4] [B,4G,4] [E4B,4] | "D" [D,4A,,4] [^F,4D,4] [A,4^F,4] [D5] | "C" [C,4G,,4] [E,4C,4] [G,4E,4] [C4G,4] | "B7" [B,,4^F,,4] [D,4B,,4] [^F,4D,4] [B,5^] :|
|: "Em" E,2B,,2E,2G,2 B,,2E,2G,2B,2 | "D" D,2A,,2D,2^F,2 A,,2D,2^F,2A,2 | "C" C,2G,,2C,2E,2 G,,2C,2E,2G,2 | "B7" B,,2^F,,2B,,2D,2 ^F,,2B,,2D,2^F,2 :|
|: "Em" [E,8B,,8]-[E,8B,,8] | "D" [D,8A,,8]-[D,8A,,8] | "C" [C,8G,,8]-[C,8G,,8] | "B7" [B,,8^F,,8]-[B,,8^F,,8] :|



X:1
T:Temple of Whispers
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=56
K:Em
V:1 clef=treble name="Choir"
%%MIDI program 52
|: "Em" E4 (3BAG | "D" F4 (3AGF | "C" G3 A (3Bcd | "Bm" B,4 (3EDE |
"Em"G2 (3FED "Am"E2 (3DCB, | "G"B,4 (3ABc | "D"d2 cB  | "Em"E5  :|
V:2 clef=treble name="String Ensemble"
%%MIDI program 48
|: "Em"e2 B3  | "D"f2 d3  | "C"g2 e3  | "Bm"b,2 g3  |
"Em"g6  "m" | "G"B2 B3  | "D"A2 F3  | "Em"E5  :|
V:3 clef=bass name="Synth Pad"
%%MIDI program 91
|: "Em"[E,B,][E,G] [E,B,][E,G] | "D"[F,A,][F,D] [F,A,][F,D] | "C"[G,C][G,E] [G,C][G,E] | "Bm"[B,,D][B,,G] [B,,D][B,,G] |
"Em"[G,B,][G,E] [G,B,][G,E] | "Am"[A,C][A,E] [A,C][A,E] | "G"[G,D][G,B,] [G,D][G,B,] | "D"[D,A,][D,F] [D,A,][D,F] :|
"Em"E,5  | "D"D5  | "C"C5  | "Bm"B,5  |
"Em"E,5  | "Am"A,5  | "G"G,5  | "D"D5  :|
V:4 clef=bass name="Timpani"
%%MIDI program 47
|: "Em"[E,2E,2] z2 | "D"[D2D2] z2 | "C"[C2C2] z2 | "Bm"[B,2B,2] z2 |
"Em"[E,2E,2] z2 | "Am"[A,2A,2] z2 | "G"[G,2G,2] z2 | "D"[D2D2] z2 :|



X:1
T:Utopia of Synthesis
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Emaj
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"E"B4 z c2B|"C#m"G4 z E2G|"Amaj7"A3B ABc2|"F#m7"B4 z2 F2|
"E"B4 z c2e|"C#m"A4 z G2A|"Amaj7"E2F2 G4|"B7"F2E2 E4:|
|:"E"B3c e4|"C#m"A3B c4|"Amaj7"E2E2 F2G2|"F#m7"B4 z4|
"E"B3B c2B2|"G#m7"G3G A2G2|"Amaj7"A6 B2|"B7"B4 z4:|
V:2 clef=treble name="Arpeggiated Synth"
%%MIDI program 82
|:"E"B,2 B,2 E2 G2|"C#m"G,4 G,2 C2|"Amaj7"A,6 E2|"F#m7"B,4 F,2 A,2|
"E"E4 G2 B,2|"C#m"e2 G2 C2 z E|"Amaj7"A,3E z "B7"FB,2|"E"B,8:|
|:"E"B,3^D E2^G2|"C#m"A,3B, E2C2|"Amaj7"E2A,2 ^D2E2|"F#m7"F,4 A,2 C2|
"E"B,2 ^D2 E2 G2|"G#m7"B,2 E2 ^F2^A2|"Amaj7"A,6 E2|"B7"B,8:|
V:3 clef=bass name="Bass Synth"
%%MIDI program 87
|:"E"E,4 z2 B,2|"C#m"A,4 z2 E,2|"Amaj7"A,4 z2 E,2|"F#m7"F,4 z2 B,2|
"E"E,4 z2 B,2|"C#m"A,4 z2 C#2|"Amaj7"A,4 z2 E,2|"B7"B,4 z4:|
|:"E"E,6 B,2|"C#m"A,6 E,2|"Amaj7"A,6 E,2|"F#m7"F,6 B,2|
"E"E,6 B,2|"G#m7"G,6 B,2|"Amaj7"A,6 E,2|"B7"B,8:|



X: 2
T: AI Fantasy
C: OrchestrAI
M: 3/4
L: 1/8
Q: 1/4 = 140
K: C
V: 1 clef=treble name="Oboe"
%%MIDI program 68
"C" | C4 | G4 | E4 | C4 | D4 | B3 | C4 | A3 |
"F" | F4 | C4 | G4 | A3 | F4 | G4 | A3 | C4 |
"Dm" | Dm4 | F4 | A3 | Dm4 | F4 | A3 | Dm4 | F4 |
"G7" | C4 | G4 | B3 | C4 | G4 | B3 | C4 | G4 |
V: 2 clef=treble name="Clarinet"
%%MIDI program 71
"C" | E4 | C4 | G4 | B3 | C4 | D4 | E4 | C4 |
"F" | A4 | F4 | C4 | D4 | E4 | F4 | G4 | A4 |
"Dm" | A3 | F4 | A3 | F4 | A3 | F4 | G4 | A3 |
"G7" | C4 | G4 | B3 | C4 | G4 | B3 | G4 | C4 |
V: 3 clef=alto name="French Horn"
%%MIDI program 60
"C" | G3 | E3 | G3 | C4 | E4 | G3 | E3 | G3 |
"F" | C4 | F3 | A3 | C4 | F3 | G3 | A3 | C4 |
"Dm" | G3 | E3 | G3 | E3 | G3 | E3 | G3 | C4 |
"G7" | G3 | B3 | D4 | G3 | B3 | D4 | G3 | B3 |
V: 4 clef=bass name="Cello"
%%MIDI program 48
"C" | C2 | G2 | C2 | G2 | C2 | G2 | C2 | G2 |
"F" | F2 | C2 | G2 | A2 | F2 | G2 | A2 | C2 |
"Dm" | Dm2 | F2 | Dm2 | F2 | Dm2 | F2 | Dm2 | F2 |
"G7" | G2 | B2 | D3 | G2 | B2 | D3 | G2 | B2 |
% Repeat the melody in D major:
V: 1
"D" | D4 | A4 | F#4 | D4 | E4 | C#4 | D4 | B3 |
"A" | A4 | D4 | A4 | B3 | A4 | A4 | B3 | D4 |
"Bm" | Bm4 | D4 | F#4 | Bm4 | D4 | F#4 | Bm4 | D4 |
"E7" | D4 | A4 | C#4 | D4 | A4 | C#4 | D4 | A4 |
V: 2
"D" | F#4 | D4 | A4 | C#4 | D4 | E4 | F#4 | D4 |
"A" | B4 | A4 | D4 | E4 | F#4 | A4 | B4 | C#4 |
"Bm" | B3 | D4 | B3 | D4 | B3 | D4 | E4 | B3 |
"E7" | D4 | A4 | C#4 | D4 | A4 | C#4 | A4 | C#4 |
V: 3
"D" | A3 | F#3 | A3 | D4 | F#4 | A3 | F#3 | A3 |
"A" | D4 | A3 | D4 | E4 | D4 | D4 | E4 | D4 |
"Bm" | A3 | F#3 | A3 | F#3 | A3 | F#3 | A3 | D4 |
"E7" | A3 | C#4 | E4 | A3 | C#4 | E4 | A3 | C#4



X:1
T:Trombone Trio Capers
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Gm
V:1 clef=bass name="Trombone I"
%%MIDI program 57
|:"Gm"G,4 "D"F,A, C2| "Cm"B,3A G,G,A | "Gm"B2 A2 G2 F2 | "D" E,3D C,D,E |
| "Cm"D2 B,2 G,2A,2 | "Gm"B,4 F,E, D,2| "Bb" G,2B,2 D,B, G,2 | "D"F,3 G,A,B |
| "Gm"G,4 "D"F,A, D,F | "Cm" G,3A G,F,E | B,2 D2 C2 B,2 | "Gm"A,3 B,A,G, :|
V:2 clef=bass name="Trombone II"
%%MIDI program 57
|:"Gm"B,2 D2 G,F, | "Cm" D,3C B,,D,E, | "Gm"F,2 G,2 F,2 E,D, | "D"E,C,D, G,F, z |
| "Cm" G,2 A,2 B,C, | "Gm" D,3F, A,C,B, | "Bb"D,C,E, A,F,B, | "D" G,3 A,B,C, |
| "Gm"G,2 F,2 E,F, | "Cm"A,3B, D,F,E, | G,2 F,2 E,2 D,2 | "Gm"G,3 A,B,C,:|
V:3 clef=bass name="Trombone III"
%%MIDI program 57
|:"Gm"G,,2 B,,2 D,4 | "Cm"E,2 F, G,F,D, | "Gm"B,,3 C,D,F, | "D"A,,2 B,,2 C,D,E, |
| "Cm"A,,2 G,2 F,2 | "Gm"D,C,B,, A,2 G, | "Bb"B,,2 D,2 F,D, | "D"A,,2 G,2 F,2 |
| "Gm"G,,2 B,,2 D,C, | "Cm"E,3F, A,3G, | B,,2 A,,2 G,,2 F,,2 | z "Gm"G,,2A,,B,,D,:|



X:1
T:Thanks for the Interview!
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:G
% The melody conveys warmth and appreciation
V:1 clef=treble name="Piano"
%%MIDI program 0
|:"G"D4 B2d2|"C"c4 A2G2|"D"F4 D2E2|"G"G6 z2|
"D"A3B A2F2|"C"G3A G2E2|"G"D4 B,2D2|"D7"A6 z2|
"G"B4 e2d2|"C"e4 c2B2|"Am"d4 c2d2|"D7"c4 B2A2|
"G"G4-D2 G2|"Em"B3A G2F2|"Am"c4 A2F2|"D7"D6 z2|
"G"G3F E2D2|"C"E4 D2C2|"G"B,4 A,G, B,D|"Em"G,4 A2B2|
"C"c4-d c2B|"G"G4-F G2A|"Am"B4-c2 B2|"D7"A3G F2E2|
"G"G4: z4:|
V:2 clef=bass name="Bass"
%%MIDI program 32
|:"G"G,2D2 G,2B,2|"C"E,2C2 E,2G,2|"D"A,2F2 A,2d2|"G"G,6 z2|
"D"D,2A,2 D,2F2|"C"C,2G,2 C,2E2|"G"B,,2G,2 B,,2D2|"D7"A,6 z2|
"G"G,2B,2 d2g2|"C"e2c2 A3c|"Am"A,2E2 A,2c2|"D7"A,2F2 D3F|
"G"G,4B,2 G,2|"Em"G,2E2 B,2G,2|"Am"A,2C2 E2A,2|"D7"A,4F2 D2|
"G"G,2B,2 D2G,2|"C"C,2E2 G2c2|"G"B,,2D2 G,2B,2|"Em"E,2G,2 B,2e2|
"C"E,2G,2 c2e2|"G"D,2B,,2 G,2B,2|"Am"A,2E2 C2A,2|"D7"F2A,2 D2F2|
"G"G,4: z4:|



X:1
T:Before the Tempest
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:Am
V:1 clef=treble
%% MIDI program 48
|:"Am"e2E2 A2c2|"Dm"d2F2 A2d2|"E"e2^G2 B2e2|"Am"A6B2|
"Am"c3B A2G2|"C"e2E2 G2c2|"F"=GF z2 E2D2|"E7"E4z D2C|
"Am"A,2C2 E2A,2|"G"A2^G2 G4|"F"E3^D E2E2|"E7"E8|
"Am"e4e3^d|"Dm"d3c d2c2|"E"B2A2 G2F2|"Am"A8:|
V:2 clef=treble
%% MIDI program 49
|:"Am"A2E2 C2A2|"Dm"F2D2 A2f2|"E"G2E2 ^G4|"Am"A,6C2|
"Am"A2G2 F2E2|"C"c2G2 E4|"F"E2D2 C4|"E7"z z D2G2F2|
"Am"A,2C2 E2A,2|"G"B2A2 G4|"F"F2E2 ^D4|"E7"E8|
"Am"A4A3^G|"Dm"F3E F2E2|"E"B2A2 G4|"Am"A,8:|
V:3 clef=bass
%% MIDI program 42
|:"Am"A,2C2 E4|"Dm"D,2F2 A,4|"E"E,2^G2 B,4|"Am"A,8|
"Am"E3^D E4|"C"C2E2 G,4|"F"F,2A,2 C4|"E7"E,2 z3B, A,2|
"Am"A,2C2 E2A,2|"G"G,2^F2 D4|"F"F,2E2 C4|"E7"E,8|
"Am"A,4A,3^G,|"Dm"D,3C, D,4|"E"E,2^G,2 B,4|"Am"A,8:|



X:1
T:Dance of the Fireflies
C:OrchestrAI
M:3/4
L:1/16
Q:1/4=126
K:G
V:1 clef=treble name="Piano RH" subname="Melody"
%%MIDI program 1
|: "G" B3d g4 f3e | "C"e3f g2e2 d2c2 | "D7" d2f2 a4 b3a | "G" g3f e2d2 B4 |
"G" d2B2 G4 g3a | "C" b4 a3g f2e2 | "Em" g2e2 d3B c2B2 | "A7" a4 g3f e4     |
"G" B3d g4 f3e | "C" e3f g2e2 d2c2 | "D"  d3f d2B2 g2f2 | "G" g2B2 d4 G4 :|
V:2 clef=treble name="Piano LH" subname="Harmony"
%%MIDI program 1
|: "G"[GB]4 z4 [GB]4 | "C"[CE]4 z4 [CE]4 | "D7"[DFA]4 z4 [DFA]4 | "G"[GBd]4 z4 [GBd]4 |
"G"[GB]4 z4 [GB]4 | "C"[CE]4 z4 [CE]4 | "Em"[EGB]4 z4 [EGB]4 | "A7"[ACE]4 z4 [ACE]4 |
"G"[GB]4 z4 [GB]4 | "C"[CE]4 z4 [CE]4 | "D"[DF#A]4 z4 [DF#A]4 | "G"[GBd]4 z4 [GBd]4 :|
V:3 clef=treble name="Strings" subname="Pizzicato"
%%MIDI program 45
|: "G" d8 z2 B3    | "C" e8 z2 c3    | "D7" f8 z2 d3    | "G" d8 z2 g3    |
"G" B8 z2 d3    | "C" e8 z2 a3    | "Em" g8 z2 e3    | "A7" a8 z2 e3    |
"G" d8 z2 B3    | "C" e8 z2 c3    | "D"  f8 z2 A3    | "G" d8 z2 g3  :|



X:1
T:Dance of the Fireflies
C:OrchestrAI
M:3/4
L:1/16
Q:1/4=94
K:Gmaj
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G" B2d2 G4 z4 | "D" d2G2 A2^F2 G4 | "G" B2d2 d2B2 G4 | "C" E2G2 c4 B4 |
"G" g4 (3gab g2f2 e2d2 | "D" f2a2 (3fed afge z4 | "G" b2g2 (3gfe d2B2 z4 | "Am" a4 (3efg a2g2- g4 |
"G" B2d2 G4 z4 | "D" d2G2 A2^F2 G4 | "G" B2d2 d2B2 G4 | "C" E2G2 c4 B4 |
"G" g4 (3gab g2f2 e2d2 | "D" f2a2 (3fed afge z4 | "G" b2g2 (3gfe d2B2 z4 | "G" g6- g4 z2:|
V:2 clef=treble name="Violin"
%%MIDI program 40
|:"G" D2 (3BAG D4 z4 z2 | "D" A3G ^F2E2 D4 | "G" D2G2 B2A2 G4 | "C" E2A2 c4 A4 |
"G" d2 (3ded (3Bcd B2A2 z6 | "D" A2d2 a2f2 g2e2 | "G" D3B, D2G2 B2G2 | "Am" A4 G2F2 E4 |
"G" D2 (3BAG D4 z4 z2 | "D" A3G ^F2E2 D4 | "G" D2G2 B2A2 G4 | "C" E2A2 c4 A4 |
"G" d2 (3ded (3Bcd B2A2 z6 | "D" A2d2 a2f2 g2e2 | "G" D3B, D2G2 B2G2 | "G" D6- D4 z2:|
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"G" G,4 D2 G,4 z2 | "D" D4 A,2 D4 z2 | "G" G,2G,2 D2E2 G4 | "C" E,4 C2 E,6 |
"G" D4 B,4 D4 | "D" A,4 F2 A,6 | "G" D3G, B,4 D4 | "Am" A,6- A,6 |
"G" G,4 D2 G,4 z2 | "D" D4 A,2 D4 z2 | "G" G,2G,2 D2E2 G4 | "C" E,4 C2 E,6 |
"G" D4 B,4 D4 | "D" A,4 F2 A,6 | "G" D3G, B,4 D4 | "G" G,6- G,7 :|



X:1
T:Theatrical Parade
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Bb
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|"Bb" d4 (3dcB z4 | "Eb"g2f2 e4 | "F" d2c2 B2A2 | "Bb"B6 z2 |
"Bb" d4 (3dcB z4 | "Eb" g2f2 f2g2 | "F" a2g2 f2e2 | "Bb"b4 f2 d2 |
"Gm" B2B2 G2G2 | "F"A4 A2f2 | "Eb"g2f2 e2d2 | "Bb"d4 c2B2 |
"F" c3d c2B2 | "Eb"A2A2 G2F2 | "Bb"F2G2 E4 | "F7"F6 z2 |
V:2 clef=treble name="Saxophone"
%%MIDI program 65
|"Bb" B4 F2D2 | "Eb"G2A2 B2c2 | "F" d4 c2B2 | "Bb"B6 z2 |
"Bb" B4 F2D2 | "Eb" E2D2 F2G2 | "F" A2B2 G2F2 | "Bb"d4 B4 |
"Gm" g4 d2B2 | "F" A2B2 c4 | "Eb" e4 e2c2 | "Bb"B6 z2 |
"F" c3B A2G2 | "Eb" G2F2 E2D2 | "Bb" F4 D4 | "F7"F6 z2 |
V:3 clef=bass name="Trombone"
%%MIDI program 57
|"Bb" B,4 B,2D2 | "Eb" G,4 G,2B,2 | "F" A,4 A,2C2 | "Bb"B,6 z2 |
"Bb" B,4 B,2D2 | "Eb" G,4 G,2A,2 | "F" C4 C2A,2 | "Bb"B,4 F4 |
"Gm" D4 D2B,2 | "F" A,4 C4 | "Eb" G,4 G,2E2 | "Bb"B,4 D4 |
"F" B,,4 D2B,2 | "Eb" G,4 G,2F2 | "Bb" B,4 B,2D2 | "F7"F,6 z2 |



X:1
T:iPhone Ad Jingle
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Gmaj
V:1 clef=treble name="Synth Lead" subname="Lead"
%%MIDI program 81
|: "G" B4 G4 | "C"e8 | "D"d4 B4 | "G"B6 G2 |
"G"B2 c2 "D"A4 | "Em"G2 F2 "D"A4 | "C"G2 A2 B2 c2 | "G"d4 B4 :|
V:2 clef=treble name="Synth Rhythm" subname="Rhythm"
%%MIDI program 81
|: "G"D2 G4 z2 | "C"e2 G4 z2 | "D"d4 A5  | "G"G6 z2 |
"G"B2 "D"A2 G4 | "Em"B2 A2 G4 | "C"e2 d2 c4 | "G"B2 G4 z2 :|
V:3 clef=bass name="Bass" subname="Bass"
%%MIDI program 39
|: "G"G,4 D4 | "C"C,4 G,4 | "D"D,4 A,4 | "G"G,6 D2 |
"G"G,4 D4 | "Em"E,4 E,4 | "D"D,4 D,4 | "G"G,4 D4 :|



X:1
T:Boss Battle Blitz
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=140
K:Cmin
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|: "Cm" g4c'4g4c'4 | e4g4c4e4 | "Bb"d4f4b4d4 | "Ab"c4e4ab4c'3 | "G"g4b4e4g4 | "F"f4a4d4f4 | "Cm"e4g4c'4e'4 | "G7"d4f4b4d'4 :|
|: "Cm" G,4G,4c4g4 | "Bb" F,4F,4b4f4 | "Ab" E4E4a4e4 | "G" D4D4g4d4 | "F" C4C4f4c'4 | "Cm"B,4C4E4G4 | "G7"A,4B,4D4F4 | "Cm"G,4C4G4C4 :|
V:2 clef=treble name="Choir Synth"
%%MIDI program 91
|: "Cm" G4G4g4e4 | "Bb" F4F4f4d4 | "Ab" E4E4e4c4 | "G" D4D4d4B4 | "F" C4C4c4A4 | "Cm"B4G4E4C4 | "G7" B4F4D4A,4 | "Cm"G,4C4E4G4 :|
|: "Cm" e4g4c8 | "Bb" d4f4b8 | "Ab" c4e4a8 | "G" b4d4g8 | "F" a4c4f8 | "Cm"g4e4c'8 | "G7"d4b4f8 | "Cm"c'4g4e4c4 :|
V:3 clef=bass name="Electric Bass"
%%MIDI program 39
|: "Cm" C4z4C4z4 | "Bb" B,4z4B,4z4 | "Ab" A,4z4A,4z4 | "G" G,4z4G,4z4 | "F" F,4z4F,4z4 | "Cm" E,4z4E,4z4 | "G7" D,4z4D,4z4 | "Cm" C4z4C4z4 :|
|: "Cm" c8G,8 | "Bb" b8F,8 | "Ab" a8E8 | "G" g8D8 | "F" f8C8 | "Cm" e8B,8 | "G7" d8A,8 | "Cm" c8G,8 :|
V:4 clef=bass name="Synth Bass"
%%MIDI program 38
|: "Cm" C4C4C4C4 | "Bb" B,4B,4B,4B,4 | "Ab" A,4A,4A,4A,4 | "G" G,4G,4G,4G,4 | "F" F,4F,4F,4F,4 | "Cm" E,4E,4E,4E,4 | "G7" D,4D,4D,4D,4 | "Cm" C4C4C4C4 :|
|: "Cm" c16c16 z4 | "Bb" b16b16 z4 | "Ab" a16a16 z4 | "G" g16g16 z4 | "F" f16f16 z4 | "Cm" e16e16 z4 | "G7" d16d16 z4 | "Cm" c16c16 z4 :|



X:1
T:Pixelated Pursuit
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=160
K:Bm
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"Bm"B2A F2E|D3-C2E|"A"F2E A2c|B3-A3|
"G"G2F E2D|C3-B,3|"F#m"A,2A,2 zA|B,3- A,3|"E"E2E F2G|"Bm"F3-E3|
"D"A2B "A"F2E|"G"G3-A2G|"Bm"F2E "E7"G2F|"A"A3-G2E:|
V:2 clef=treble name="Strings" subname="Str."
%%MIDI program 48
|:"Bm"B,3 D3|"A"C3 E3|"G"G,3 B,2D|"F#m"A,3 C2E|
"E"F3 G2A|"D"F2 G2 A2|"A"c2 B2 A2|"E"E3 G2 F|
"Bm"G2 F2 E2|"A"A3 F2 E|"G"A2 B2 c2|"Bm"B2 A2 G2|"E"A3 G2 F|"Bm"F2 E2 D2:|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|:"Bm"B,,3 D,3|"A"A,,3 C,3|"G"G,,3 B,,2D,|"F#m"F,,3 A,,2C,|
"E"E,,3 F,2G,|"D"D,3 E,2F,|"A"A,2G,2F z,|"E"E,3 F,2 G,|
"Bm"B,,3 F,2 E,|"A"A,,3 E,2 D,|"G"G,,3 B,,2c,|"Bm"F,,3 A,2 B,|"E"E,,3 G,2 F,|"Bm"B,,3 D,2 E,:|



X:1
T:Whispers of Affection
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Am
% The piece starts with a gentle yet eager motif
V:1 clef=treble
%%MIDI program 1
|:"Am" e4 e2dc | "E7" B4- B2AB | "Am" c4 A2GE | "Dm" F6 E2 |
"F" c4 c2BA | "E7" G3A B2cB | "Am" A4- A2cB | "E7" A6 z2 :|
V:2 clef=treble
%%MIDI program 41
|: "Am" A2 z2 A2E2 | "E7" E4- E2F2 | "Am" A2 z2 A2^G2 | "Dm" D4- D2E2 |
"F" F2 z2 F2A2 | "E7" ^G4- ^G2A2 | "Am" A2 z2 A2c2 | "E7" E4 z4 :|
V:3 clef=bass
%%MIDI program 42
|: "Am" A,2 C2 E2A,2 | "E7" B,2 E2 ^G2B,2 | "Am" E2 A,2 C2E2 | "Dm" F2 D2 A,2D2 |
"F" C2 F2 A,2C2 | "E7" ^G,2 B,2 E2^G2 | "Am" A,2 E2 C2E2 | "E7" E,2 B,2 E2B,2 :|
% A lyrical section follows, capturing the tenderness of romance
V:1
|:"Dm" F4 F2EF | "Am" A4- A2GA | "C" G4 E2CD | "B7" B6 A2 |
"Em" E4 E2^G2 | "Am" A4- A2E2 | "F" F4 E2DC | "E7" E6 z2 :|
V:2
|: "Dm" D2 z2 D2F2 | "Am" A4- A2A2 | "C" C2 E2 C2E2 | "B7" B3A G2F2 |
"Em" E2 ^G2 E2E2 | "Am" A2 A2 E2E2 | "F" F2 A2 F2A2 | "E7" E4 z4 :|
V:3
|: "Dm" D,2 A,2 D2F2 | "Am" A,2 C2 E2A,2 | "C" E2 G2 C2E2 | "B7" F2 D2 B,2D2 |
"Em" E,2 G2 B,2E2 | "Am" A,2 C2 E2A,2 | "F" C2 F2 A,2C2 | "E7" E,2 B,2 E2^G,2 :|
% The excitement is palpable in the rhythms of the next section
V:1
|:"Am" e2^G2 A2BA | "E7" G2A2 B2^cB | "Am" c2E2 A2cA | "Dm" d4 F2A2 |
"F" c2d2 e2dc | "E7" B2A2 ^G2F2 | "Am" A2 GE "E7" E2 ^CE | "Am" A6 z2 :|
V:2
|: "Am" E2 C2 E2A2 | "E7" B,2 E2 G2B2 | "Am" A2 E2 A2c2 | "Dm" D4 F2D2 |
"F" C2 F2 A2c2 | "E7" ^G2 F2 E2D2 | "Am" E2 "E7" B,2 ^G,2 E2 | "Am" A4 z4 :|
V:3
|: "Am" A,2 E2 A,2E2 | "E7" B,2 E2 ^G2B,2 | "Am" E2 C2 E2A,2 | "Dm" D2 F2 D2F2 |
"F" C2 F2 A,2C2 | "E7" ^G,2 B,2 E2^G2 | "Am" A,2 E2 "E7" E,2 ^C2 | "Am" A,6 z2 :|



X:1
T:Bandstand Frolic
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=144
K:Bb
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|"Bb"F2B2 B2cB | "Eb"d2e2 f2ge | "F7"f2e2 c2BA | "Bb"B4z4 |
"Gm"G2AB c2dc | "Eb"B2c2 d2ef | "F7"g2fe f2ed | "Bb"B4z4 |
"F"!>!c2d2 f2g2 | "Eb"!>!a2g2 f2e2 | "Gm"!>!d2c2 B2AG | "F7"F4z4 |
"Bb"F2B2 B2cB | "Eb"d2e2 f2ge | "F7"f2e2 c2BA | "Bb"B4z4 :|
V:2 clef=treble name="Alto Sax"
%%MIDI program 65
|"Bb"d2f2 B2cB | "Eb"g2a2 b2ag | "F7"a2b2 d2c2 | "Bb"d4z4 |
"Gm"B2c2 e2f2 | "Eb"g2b2 a2g2 | "F7"f2e2 c2d2 | "Bb"d4z4 |
"F"!>!A2B2 d2e2 | "Eb"!>!g2f2 e2d2 | "Gm"!>!c2B2 A2GF | "F7"F4z4 |
"Bb"d2f2 B2cB | "Eb"g2a2 b2ag | "F7"a2b2 d2c2 | "Bb"d4z4 :|
V:3 clef=treble name="Trombone"
%%MIDI program 57
|"Bb"B2B2 A2GF | "Eb"E2F2 G2AG | "F7"A2G2 E2DC | "Bb"B,4z4 |
"Gm"G2A2 B2B2 | "Eb"E2F2 G2A2 | "F7"A2G2 F2E2 | "Bb"B,4z4 |
"F"C2D2 F2G2 | "Eb"A2B2 c2d2 | "Gm"B2A2 G2F2 | "F7"F4z4 |
"Bb"B2B2 A2GF | "Eb"E2F2 G2AG | "F7"A2G2 E2DC | "Bb"B,4z4 :|
V:4 clef=bass name="Tuba"
%%MIDI program 58
|"Bb"B,,2B,2 B,,2D,2 | "Eb"E,2E,2 E,2G,2 | "F7"F,2F,2 B,,2E,2 | "Bb"B,,4z4 |
"Gm"G,,2G,2 D,2D,2 | "Eb"E,2E,2 G,2G,2 | "F7"F,2F,2 B,,2F,2 | "Bb"B,,4z4 |
"F"F,,2F,2 F,,2A,2 | "Eb"Eb,,2Eb,2 B,,3 | "Gm"G,,2G,2 E,,2E,2 | "F7"F,,4z4 |
"Bb"B,,2B,2 B,,2D,2 | "Eb"E,2E,2 E,2G,2 | "F7"F,2F,2 B,,2E,2 | "Bb"B,,4z4 :|"
abc
X:1
T:Winter's Fury
C:OrchestrAI
M:C
L:1/16
Q:1/4=160
K:Amin
V:1 clef=treble
%%MIDI program 1
|: e2cA e2cA e2cA e2cA | d2B^G d2B^G d2B^G d2B^G | c2A^F c2A^F c2A^F c2A^F | B2^GE B2^GE B2^GE B2^GE |
E2C2 E4 F2D2 F4 | G2E2 G4 A2F2 A4 | c2B2 A4 B2c2 d4 | e4 e2c2 e4 e2d2 |
f2e2 d4 e2f2 g4 | a4 a2f2 a4 a2g2 | a2f2 e4 e2A2 f4 | g4 g2e2 g4 g2f2 |
e2d2 c4 d2e2 f4 | e2^d2 e4 e2^c2 d4 | c2B2 A4 G2A2 B4 | A4 A8 :|
|: e2^c2 a4 e2c2 e4 | d2B2 ^g4 d2B2 d4 | c2A2 ^f4 c2A2 c4 | B2B2 e4 B2^G2 E4 |
E4 c4 E2C2 E4 | F4 d4 F2D2 F4 | G4 e4 G2E2 G4 | A3B c4 A2B2 c4 |
d4 f4 d2e2 f4 | e4 e3f g4 e2^c2 e4 | a4 a2f2 e4 d2e2 d4 | g4 g2e2 g4 e2f2 g4 |
a4 a2f2 g4 f2e2 | a4 g2e2 f4 e2^d2 | e4 e3^d e4 |[1 A6 A2 :|[2 A12 |]



X:1
T:Capers and Laughter
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=116
K:Cmaj
V:1 clef=treble name="Accordion"
%%MIDI program 21
|:"C"E2E "C7"_B2B | "F"A2c "G7"B2d | "C"e3 "Am"e3 | "Dm"f2d "G7"c2B |
"G7"B3 "C"=B3 | "Am"A2G "F"G2A |"C"B2c "G7"BAG | "C"C3-C3 |
"F"A2c "Eb"=B2^G | "G7"=B2d "A7"c2e | "Dm"d3-dcB | "G7"^cde d^cB |
"F"A2f "C7"e2f | "Fm"_e2d "C"=B2G | "Am"c>BA "G7"B2G | "C"C3-C3 :|
V:2 clef=bass name="Tuba"
%%MIDI program 58
|:"C"C,2C, "C7"G,,2B,, | "F"F,2C, "G7"D,2G, | "C"C,3 "Am"E,3 | "Dm"F,3 "G7"D,2B,, |
"G7"G,,2B,, "C"B,,2C, | "Am"E,2C, "F"G,2F, |"C"G,2A, "G7"B,2G, | "C"C,3-C,3 |
"F"F,2F, "Eb"G,,2G,, | "G7"B,,2D, "A7"C,2E, | "Dm"D,3-D,2F, | "G7"G,,2B,,G,,2B,, |
"F"F,2C, "C7"A,2B, | "Fm"A,,2C, "C"E,2G, | "Am"C,2B,, "G7"A,2B, | "C"C,3-C,3 :|



X:1
T:Majestic Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:Cmaj
% The anthem unfolds slowly with a sense of grandeur.
V:1 clef=treble name="Violin 1"
%%MIDI program 40
|:"C" G4 E4 | "Am" A6 E2 | "F" F6 A2 | "G" G8 |
"Em" E4 G4 | "Am" A6 c2 | "Dm" D6 F2 | "G" G8 |
"C" C4 E4 | "G" G4 F4 | "Am" A4 E4 | "F" F4 E4 |
"Dm" D6 C2 | "G" B,4 A,4 | "F" A6 G2 | "C" C8 :|
V:2 clef=treble name="Violin 2"
%%MIDI program 40
|:"C" E4 C4 | "Am" c4 E4 | "F" A4 c4 | "G" G4 F4 |
"Em" G4 B4 | "Am" c4 A4 | "Dm" F4 D4 | "G" G4 F4 |
"C" E4 C4 | "G" D4 B,4 | "Am" E4 C4 | "F" A4 F4 |
"Dm" D4 C4 | "G" B,4 D4 | "F" c4 A4 | "C" C4 G,4 :|
V:3 clef=bass name="Viola"
%%MIDI program 41
|:"C" C4, G,,4 | "Am" E,4 A,,4 | "F" F,4 C,4 | "G" G,4 D,4 |
"Em" E,4 G,4 | "Am" A,4 E,4 | "Dm" D,4 A,,4 | "G" G,4 B,,4 |
"C" C4, E,4 | "G" D4 G,,4 | "Am" A,4 E,4 | "F" F,4 C,4 |
"Dm" D,4 F,4 | "G" G,4 B,,4 | "F" C4 F,,4 | "C" C4, G,,4 :|
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"C" C,6 C2 | "Am" A,6 E,2 | "F" F,6 A,2 | "G" G,6 G,2 |
"Em" E,4 G,4 | "Am" A,6 c2 | "Dm" D,6 F,2 | "G" G,8 |
"C" C,4 E,4 | "G" G,4 F,4 | "Am" A,4 E,4 | "F" F,4 E,4 |
"Dm" D,8 | "G" B,,4 A,,4 | "F" A,6 G,2 | "C" C,8 :|



X:1
T:Rustic Hearth Melody
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=90
K:Gmaj
% A melody that echoes the simplicity and harmony of village life
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G" D2 B2 G2 | "C" E3 F G2 | "Am" A2 c2 e2 | "D" d3 c B2 |
"G" G2 B2 d2 | "Em" e3 d B2 | "C" c2 E2 G2 |1 "D" D4 z2 :|2 "D" D6 ||
|:"G" g4 f2 | "Em" e4 d2 | "C" G2 E2 D2 | "G" D6 |
"G" g4 f2 | "Em" e4 d2 | "C" c2 A2 G2 |1 "D" D4 z2 :|2 "D" D6 |]
V:2 clef=treble name="Acoustic Guitar"
%%MIDI program 25
|:"G" D3 G, B,2 | "C" C3 E G,2 | "Am" A,3 E A2 | "D" D3 F A,2 |
"G" G,3 B, D2 | "Em" E3 G B,2 | "C" C3 E G,2 |1 "D" D4 z2 :|2 "D" D6 ||
|:"G" B3 A G2 | "Em" G3 F E2 | "C" C3 D E2 | "G" G6 |
"G" B3 A G2 | "Em" G3 F E2 | "C" A3 G F2 |1 "D" D4 z2 :|2 "D" D6 |]
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"G" G,2 B,,2 D2 | "C" E,3 G, C2 | "Am" A,,3 E, A,2 | "D" D2 F, A,,2 z |
"G" G,2 B,,2 D2 | "Em" G,2 E, G,2 z | "C" C2 E, G,2 z |1 "D" D4 z2 :|2 "D" D6 ||
|:"G" G,3 G,2 z | "Em" E,3 E,2 z | "C" E,3 E,2 z | "G" D6 |
"G" G,3 G,2 z | "Em" E,3 E,2 z | "C" C3 E,2 z |1 "D" D4 z2 :|2 "D" D6 |]



X:1
T:Yee Haw Wiggle
C:Lorn
M:12/8
L:1/8
Q:3/8=140
K:Bb
V:1 clef=treble
%%MIDI program 56
|: G3 z2 G-G3 G2G | F3 G3 B2G z3 | z2 B B2G B3 G2G | F3 G3 B2G z3 |
G3 z2 G-G3 G2G | F3 G3 B2G z3 | z2 B B2G B3 B2c | ^c3 d3 f2^f z3 :|
V:2 clef=bass
%%MIDI program 58
|: G,,3 z2 D,,-D,,3 ^F,,3 | G,,3 z2 D,,-D,,3 ^F,,3 | G,,3 z2 D,,-D,,3 ^F,,3 | G,,3 z2 D,,-D,,3 ^F,,3 |
G,,3 z2 D,,-D,,3 ^F,,3 | G,,3 z2 D,,-D,,3 ^F,,3 | D,3 z2 A,,-A,,3 ^C,3 | G,,3 z2 D,,-D,,3 ^F,,3 :|
V:3 clef=treble octave=-1
%%MIDI program 58
|: d3 B2 B-AGA AFD | c3 A2 A-GFE DCB, | B,3 ^D2 B,=DEF G2 A | F3 ^A3 A2B c3 |
d3 B2 B-AGA AFD | c3 A2 A-GFE DCB, | B,3 ^D2 B,=DEF A2 d | f3 z2 c-ABc f3 :|



X:1
T:Anthem of the Giants
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:Cmaj
V:1 clef=treble name="Violin I"
%%MIDI program 40
|:[C]"C" E2 G2 c2 e | "G" d4 B4 | "Am" c2 A2 E2 C2 | "F" A2 F2 D4 |
"G" G,2 B,2 G2 D2 | "Em" E2 G2 B4 | "C/A" c2 e2 c4 | "G/B" B,2 G2 d4 |
"C" e4 G4 | "G" d6 B2 | "Am" c2 A2 E2 C2 | "F" A2 F2 D4 |
"G" G4 B2 B,2 | "Em" E2 G2 ^c4 |[1 "Dm" d6 f2 | "G7" e4 c4 :|[2 "Dm" d4 f2 e2 | "G7" g8 |]|
|: "C"[EG]4[ce]4 | "F"[Ac]4[Af]4 | "Dm"[Ad]4[df]4 | "G"[GB]4[gd]4 |
"C"[EG]4[ce]6 | "Am"[Ac][EA][F^D][AF] | "Dm"[df]4[df][Ad] | "G"[GB]8 |
"C"[CG]4[CG]4 | "F"[AF]6[AE]2 | "Dm"[Ad]4[Fd][Ad] | "G"[GB]4[G^D]4 |
"C"[EG]8 | "F"[Ac]8 | "Dm"[df][Ad][df][Ad] | "G"[g4B4] z4 :|
V:2 clef=treble name="Violin II"
%%MIDI program 40
|: "C" G4 E4 | "G" c4 A4 | "Am" E2 C2 A2 E2 | "F" D4 z4 |
"G" B,,2 D2 B,2 G2 | "Em" G2 B2 E4 | "C/A" E2 C2 A4 | "G/B" D2 B,2 G4 |
"C" c2 E2 G4 | "G" B2 d2 B4 | "Am" A2 C2 E4 | "F" C2 A2 F4 |
"G" G2 B2 G4 | "Em" B2 G2 E4 |[1 "Dm" A2 F2 D4 | "G7" C2 E2 G4 :|[2 "Dm" A2 F2 D2 E2 | "G7" c8 |]|
|: "C" E3 C G,4 | "F" A3 F C4 | "Dm" D3 A F4 | "G" G4 B3 D |
"C" E4 C2 E2 | "Am" A4 C2 A2 | "Dm" D4 A2 F2 | "G" G4 B,2 D2 |
"C" C4 G,2 E2 | "F" F4 C2 A2 | "Dm" A4 F2 D2 | "G" G4 D2 B2 |
"C" E4 C3 G, | "F" A4 F3 C | "Dm" A,6 F2 | "G" G4 d2 B2 :|
V:3 clef=alto name="Viola"
%%MIDI program 41
|: "C" C4 z4 | "G" G,4 D4 | "Am" A,4 E4 | "F" F,4 C4 |
"G" D4 G4 | "Em" E4 B4 | "C/A" A,4 E4 | "G/B" G,4 D4 |
"C" C2 E2 G4 | "G" D2 B,2 d4 | "Am" E2 C2 A4 | "F" C2 A,2 F4 |
"G" D4 G4 | "Em" B4 e4 |[1 "Dm" F4 A4 | "G7" E4 c4 :|[2 "Dm" F2 A2 F2 G2 | "G7" d8 |]|
|: "C" C3 E G,4 | "F" F3 A c4 | "Dm" D3 F A,4 | "G" G4 B3 D |
"C" C4 G4 | "Am" A4 E4 | "Dm" D4 A4 | "G" B,4 D4 |
"C" C2 G4 z2 | "F" F2 C4 z2 | "Dm" A2 F4 z2 | "G" D2 B,4 z2 |
"C" E4 C4 | "F" A4 F4 | "Dm" A,2 F4 z2 | "G" G4 D4 :|
V:4 clef=bass name="Cello"
%%MIDI program 42
|: "C" C,2 G,2 C4 | "G" D,2 G,2 B,4 | "Am" E,2 A,2 C4 | "F" F,4 A,2 C2 |
"G" D4 G4 | "Em" E,4 G2 B2 | "C/A" A,4 C4 | "G/B" B,4 D4 |
"C" G,2 C2 E4 | "G" D4 G,2 B2 | "Am" A,2 E2 A4 | "F" F,2 C2 F4 |
"G" D4 G,2 B2 | "Em" E,2 B,2 E4 |[1 "Dm" A,4 D2 F2 | "G7" G,4 C2 E2 :|[2 "Dm" A,4 D2 F2 | "G7" G,8 |]|
|: "C" C3 G, C4 | "F" A,3 F C4 | "Dm" D3 A, D4 | "G" B,4 G D3 |
"C" E,4 C G, z2 | "Am" A,4 E A, z2 | "Dm" F4 A, D z2 | "G" B,8 |
"C" G,4 C E, z2 | "F" A,4 F C z2 | "Dm" A,4 D F z2 | "G" B,4 D G, z2 |
"C" E4 C G, z2 | "F" A4 F C z2 | "Dm" A,4 D F z2 | "G" G,4 D B, z2 :|



X:1
T:Velocity Vortex
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=200
K:Bmin
V:1 clef=treble name="Synth Lead" subname="Lead"
%%MIDI program 81
|:"Bm"B4 f4 B4 f4 | "A"c4 A4 c4 e4 | "G"f4 d4 B4 d4 | "F#m"a4 f4 d4 f4 |
"Bm"B2 A2 F2 B2 G2 d2 F2 z2 | "A"A2 G2 E2 c2 G2 B2 E2 z2 | "G"F2 E2 D2 F2 A2 d2 c2 z2 | "F#m"F2 E2 D2 C2 A,2 F2 E2 z2 |
"Bm"B4 f4 B4 f4 | "A"c4 A4 c4 e4 | "G"f4 d4 B4 d4 | "F#m"a4 f4 d4 f4 |
"Bm"B8- B2 A2 B2 c2 | "A"A8- A2 G2 A2 B2 | "G"G8- G2 F2 G2 A2 | "F#m"F8- F2 E2 F2 G2 :|
V:2 clef=treble name="Brass" subname="Brass"
%%MIDI program 61
|:"Bm"B,2 D2 F2 B,2 D2 F2 z4 | "A"A,2 C2 E2 A,2 C2 E2 z4 | "G"G,2 B,2 D2 G,2 B,2 D2 z4 | "F#m"F,2 A,2 C2 F,2 A,2 C2 z4 |
"Bm"B,2 F2 B,2 D2 A,2 D2 z4 | "A"A,2 E2 A,2 C2 G2 E2 z4 | "G"G,2 D2 G,2 B,2 F2 D2 z4 | "F#m"F,2 C2 F,2 A,2 E2 C2 z4 |
"Bm"B,2 D2 F2 B,2 D2 F2 z4 | "A"A,2 C2 E2 A,2 C2 E2 z4 | "G"G,2 B,2 D2 G,2 B,2 D2 z4 | "F#m"F,2 A,2 C2 F,2 A,2 C2 z4 |
"Bm"B,,2 B,2 B,2 F2 B,4 z4 | "A"A,,2 A,2 A,2 E2 A,4 z4 | "G"G,,2 G,2 G,2 D2 G,4 z4 | "F#m"F,,2 F,2 F,2 C2 F,4 z4 :|
V:3 clef=bass name="Synth Bass" subname="Bass"
%%MIDI program 38
|:"Bm"B,16 | "A"A,16 | "G"G,16 | "F#m"F,16 |
"Bm"B,4 A,4 B,4 A,4 | "A"A,4 G,4 A,4 G,4 | "G"G,4 F,4 G,4 F,4 | "F#m"F,4 E,4 F,4 E,4 |
"Bm"B,16 | "A"A,16 | "G"G,16 | "F#m"F,16 |
"Bm"B,,4 B,4 B,4 B,4 | "A"A,,4 A,4 A,4 A,4 | "G"G,,4 G,4 G,4 G,4 | "F#m"F,,4 F,4 F,4 F,4 :|



X:1
T:Embrace of Hearts
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=80
K:G
% The piece starts with a heartfelt and expressive melody
V:1 clef=treble name="Violin" subname="Vln."
%%MIDI program 40
|:"G" D2 B3 A| "Em" G4- G2 | "C" E3 G F2 | "Am" A4 z2 | "D7" F3 E D2 | "G" G4 z2 |
"G" B3 A G2 | "Em" E4- E2 | "C" G3 E C2 | "Am" A4 z2 | "D7" F2 G A2 z | "G" G4 z2 :|
"The melody is rich with expressions of love and yearning"
V:2 clef=alto name="Viola" subname="Vla."
%%MIDI program 41
|:"G" G,4 G2 | "Em" B,4 E2 | "C" C4 E2 | "Am" A,4 z2 | "D7" D4 F2 | "G" G,4 z2 |
"G" G,4 A2 | "Em" B,4 E2 | "C" C4 E2 | "Am" A,4 z2 | "D7" D2 F2 G2 | "G" G,4 z2 :|
V:3 clef=bass name="Cello" subname="Vc."
%%MIDI program 42
|:"G" G,4 D2 | "Em" E,4 G,2 | "C" C4 E,2 | "Am" A,3 E C2 | "D7" D,4 F2 | "G" G,4 z2 |
"G" B,,4 D2 | "Em" E,4 G,2 | "C" C3 E G,2 | "Am" A,4 C2 | "D7" D,3 F A,2 | "G" G,4 z2 :|



X:1
T:Yee Haw Wiggle (Expanded)
C:Lorn
M:12/8
L:1/8
Q:3/8=140
K:Bb
V:1 clef=treble
%%MIDI program 56
|: "Gm"G3 z2 G-G3 G2G | "F"F3 G3 B2G z3 | "Eb"z2 B B2G B3 "F"G2G | "Bb"F3 G3 "F"B2G z3 |
"Gm"G3 z2 G-G3 G2G | "F"F3 G3 B2G z3 | "Eb"z2 B B2G "Gm"B3 "A7"B2c | "D7"^c3 d3 "Gm"f2^f z3 :|
V:2 clef=bass
%%MIDI program 58
|: "Gm"G,,3 z2 D,,-D,,3 ^F,,3 | "F"G,,3 z2 D,,-D,,3 ^F,,3 | "Eb"G,,3 z2 D,,-D,,3 ^F,,3 | "Bb"G,,3 z2 D,,-D,,3 ^F,,3 |
"Gm"G,,3 z2 D,,-D,,3 ^F,,3 | "F"G,,3 z2 D,,-D,,3 ^F,,3 | "Eb"D,3 z2 A,,-A,,3 ^C,3 | "D7"G,,3 z2 D,,-D,,3 ^F,,3 :|
V:3 clef=treble
%%MIDI program 71
|: "Gm"d3 z2 d-f3 f2d | "F"c3 A3 c2f z3 | "Eb"z2 g g2f g3 f2d | "Bb"A3 c3 "F"e2c z3 |
"Gm"d3 z2 d-f3 f2d | "F"c3 A3 c2f z3 | "Eb"z2 f f2a g3 d2B | "D7"B3 A3 f2d z3 :|



X:1
T:Glorious Battle Anthem
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=200
K:Bmin
V:1 clef=treble name="Brass" subname="Fanfares"
%%MIDI program 61
|: "Bm"d2f2 B4 d2f2 z4 | B2f2 d2f2 B4 d2f2 | "G" G6 G2 F4 E4 | "A" A6 c2 B4 z4 |
"Bm"B2f2 B4 d2f2 z4 | B2f2 d2f2 B4 d2f2 | "D" D6 A2 F4 E4 | "A" A8- A4 z4 :|
V:2 clef=treble name="Strings" subname="Melody"
%%MIDI program 49
|: "Bm"f2B2 B4 f2B2 z4 | f2B2 f4 B4 B2 z2 | "G"G4- G8 z4 | "A"A2E2 A4 z4 z4 |
"Bm"b2d'2 f4 b2d'2 z4 | b2d'2 f4 B4 B2 z2 | "D"A8- A4 F4 | "A"E8- E4 z4 :|
V:3 clef=treble name="Strings" subname="Harmony"
%%MIDI program 49
|: "Bm"F2D2 F4 F2 D2 z4 | F2D2 F4 B,4 D2 z2 | "G"G,6 G,2 G,4 G,4 | "A"A6 c2 A4 z4 |
"Bm"B2F2 B4 B2 F2 z4 | B2F2 B4 F4 B2 z2 | "D"D,6 D2 A,4 A,4 | "A"E6 E2 A,4 z4 :|
V:4 clef=bass name="Bass"
%%MIDI program 43
|: "Bm"B,4 B,4 D4 F4 | B,4 B,4 D4 F4 | "G"G,8 z8 | "A"A,6 E4 z6 |
"Bm"B,4 B,4 D4 F4 | B,4 B,4 D4 F4 | "D"D,8 z8 | "A"A,6 E4 z6 :|



X:1
T:Cybernetic Sneak
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=90
K:Cmin
V:1 clef=treble name="Synth Lead" subname="Lead"
%%MIDI program 81
|: "_Vib."zz3 E2|"_Pizz."G,6|"_Vib."zz3 E2|"_Pizz."G,3 z "_Harm."C2|
"Am7b5" E4 ED|"_Pizz."E6| "Bb7#11"=B4 =B^C|"_Pizz."D6|
"Fm9"FA3 AG|"Fm9"=E6|"G7sus4"G3 AGF|"Cmin"G,3 C3|
"G#"G,4 B,=D|"Cmin7"E4 G,2|"_Pizz."D2 C2 "_Leg."B,2|C,6:|
V:2 clef=treble name="Synth Pad" subname="Pad"
%%MIDI program 92
|: "_Strings"sB,3 sG3|"_Choir Ahh"sC3 sE3|"_Strings"sB,3 sG3|"_Choir Ahh"sC6|
"_Pizz."G,6|sB,3 sD3|"_Harm."E3 C3|sD6|
"_Leg."sE3 sF3|s=ED3 sC2|"G7sus4"sA,3 s^F3|"Cmin"sC4 sE2|
"G#"sE4 sG2|"Cmin7"sC4 sE2|"_Pizz."G,6|sB,4 sG2:|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|: "Cmin"C,3 z3|C,6|"Cmin"C,3 z3|C,6|
"Cmin"C,2 D,2 E,2|C,3 z3| "Cmin"B,,2 ^A,2 G,,2|C,6|
"Cmin"z2 E,2 F,2|z3 C,3|"Cmin"G,,3 G,3|"Fmin7"B,,6|
"G#"G,,3 G,3|"Cmin7"C,3 z3|"Cmin7"_B,,3 C,3|C,6:|



X:1
T:Final Encounter
C:OrchestrAI
M:12/8
L:1/8
Q:1/4=172
K:Cmin
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"Cm"g2g gec g2d|"Bb"d2d dfB d2G|"Ab"G2G GEC G,2C|"Eb"=E2E EGE =E2B,|
"Cm"G2A B2c d2e|"Bb"d2e f2g a2f|"Ab"gfe dBG G2c|"G"E2D =E2F G3:|
V:2 clef=treble name="Brass Section"
%%MIDI program 61
|:"Cm"c2B cec g2g|"Bb"b2a bag f2f|"Ab"a2g aga ec'c'|"Eb"e2f ege b2b|
"Cm"c2c 'cdec' g2g|"Bb"b2b 'bdfb' f2f|"Ab"a2a egae c'c'c|"G"b2g f2d g3:|
V:3 clef=treble name="String Ensemble"
%%MIDI program 48
|:"Cm"e2c Gec gec|"Bb"d2B FdB fdb|"Ab"c2A EcA eca|"Eb"B2G =EGB ege|
"Cm"c2e cec g2g|"Bb"d2f bfd b2f|"Ab"c2e aec g2e|"G"d2B GDB =E3:|
V:4 clef=bass name="Synth Bass"
%%MIDI program 87
|:"Cm"C,2, C,4 z C,2,|"Bb"B,,2, B,,4 z B,,2,|"Ab"A,,2, A,,4 z A,,2,|"Eb"G,,2, G,,4 z G,,2,|
"Cm"C,2, C,4 z C,2,|"Bb"B,,2, B,,4 z B,,2,|"Ab"A,,2, A,,4 z A,,2,|"G"G,,3 =E,,3 F,,3:|



X:1
T:Arcane Assembly
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=50
K:Dm
V:1 clef=treble name="Choir Aahs"
%%MIDI program 52
|: "Dm"A4 F2 | D2 (C2D2) | E4 G2 | A6 |
"Dm"A3 ^G A2 | A2 z2 F2 | "Gm"G4 A2 | G6 |
"A"A3 ^G A2 | "A7"A2 z2 F2 | "Dm"D4 E2 | D6 |
"Dm"(C8 | D8 | A,6) z2 :|
V:2 clef=treble name="Strings"
%%MIDI program 48
|: "Dm"F4 A2 | F2 (E2F2) | G4 A2 | F6 |
"Dm"F3 F F2 | F2 z2 D2 | "Gm"G,4 A,2 | G,6 |
"A"A,3 ^G, A,2 | "A7"A,2 z2 D2 | "Dm"D4 ^C2 | D6 |
"Dm"(E8 | F8 | A,6) z2 :|
V:3 clef=bass name="Oboe"
%%MIDI program 69
|: "Dm"D4 F2 | A2 z2 G2 | F4 E2 | "C"E6 |
"Dm"A3 ^G A2 | A2 z2 ^C2 | "Gm"G4 A2 | G6 |
"A"A3 ^G A2 | "A7"A2 z2 F2 | "Dm"D4 F2 | D6 |
"Dm" D8 | "A7"A8 | (D6) z2 :|
V:4 clef=bass name="Bassoon"
%%MIDI program 70
|: "Dm"D,4 A,2 | D2 z2 (E2F2) | G,4 A,2 | A,6 |
"Dm"D,3 D, D,2 | D,2 z4 | "Gm"G,,4 D,2 | G,,6 |
"A"A,3 ^G, A,2 | "A7"A,2 z4 | "Dm"D,4 A,2 | D,6 |
"Dm" D,8 | "A7"A,8 | "Dm"D,6 z2 :|



X:1
T:Autumn's Wrath
C:OrchestrAI
M:C
L:1/16
Q:1/4=180
K:Am
V:1 clef=treble
%%MIDI program 1
|:"Am"E4 E4 E4 E4|"E"A,4 E4 B,4 E4|"A"^C4 E4 A4 E4|"Dm"D4 F4 A4 F4|
"Am"E4^C4 E4 A4|"E"B,4 E4 G4 E4|"A7"^C4 E4 A4 ^G,4|"Dm7"D4 F4 A4 D4|
|:"Am"c8 B8|"F"=B8 A8|"E7"G8 ^F8|"Am"A,4 E4 A,4 E4|
"Am"c8 e8|"F"=b8 a8|"E7"g8 f8|"Am"a,4 e4 a4 e4:|
|:"Am"e2e2 e4 e2a2 a4|"E7"G2^F2 G4 E2^F2 G4|"Am"A2E2 A4 c2A2 A4|"Dm"F2D2 F4 A2^C2 E4|
"Am"E2^C2 E4 ^c2e2 e4|"E"B,2E2 B4 d2B2 B4|"A7"^C2E2 ^c2e2 a4 g4|"Dm7"F4 D4 F4 d4|
|:"Am"e4 e2f2 g4 a4|"F"f4 =b4 a4 g4|"E7"^f4 g4 a4 b4|"Am"a2e2 a2g2 f4 e4|
"Am"c4 A2E2 e2f2 g2a2|"F"=b2f2 b2a2 ^a2g2 f2g2|"E7"g2B2 g2^f2 =f2e2 d2e2|"Am"^e2a2 e2c2 B4 A4:|



X:1
T:Boss Battle Blitz
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Cmin
% The piece starts with a high energy introduction that sets the tone for the boss battle
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"Cm"c2c2c2G2 c6B2|B2B2B2G2 B6c2|d2d2d2A2 d6G2|G2G2G2E2 G6d2|
c2c2e2G2 c6e2|e2e2e2c2 e6f2|g2g2g2d2 g6b2|b2b2b2g2 b6c'2|
"Cm"c2c2c2G2 c6B2|B2B2B2G2 B6c2|"G"d2d2d2A2 d6G2|G2G2G2E2 G6d2|
"Cm"c2c2e2G2 c6e2|e2e2e2c2 e6f2|"D#"g2g2g2d2 g6b2|b2b2b2g2 b6z2:|
V:2 clef=treble name="Rhythm Synth" subname="Rhythm"
%%MIDI program 89
|:"Cm"cBcB cBcB cBcB cBcB|"G"BABAB4 GAGA F4|"Cm"dAdAdAdAdFdFdF zd|"G"GFGFGFGG E8|
"Cm"cE cE cE cE cE cE cE cE|"G"eG eG eG eG eG eG eG eG|"Cm"fG fG fG fG fe fe fe fe|"D#""Bb"d8- d2d2 d4|
"Cm"cBcB cBcB cBcB cBcB|"G"BABAB4 GAGA F4|"Cm"dAdAdAdAdFdFdF zd|"G"GFGFGFGG E8|
"Cm"cE cE cE cE cE cE cE cE|"G"eG eG eG eG eG eG eG eG|"D#""Bb"d8- d2d2 d4 z4| "D#"d2z2 "G"d2z2 "Cm"c2z2 "D#""Bb""D#"z8:|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|:"Cm"c4 z4 z8|c4 z4 z8|G,4 z4 z8|G,4 z4 z8|
c4 z4 z8|c4 z4 z8|d,4 z4 z8|d,4 z4 z8|
c4 z4 z8|c4 z4 z8|"G"b,4 z4 z8|b,4 z4 z8|
"Cm"c4 z4 z8|c4 z4 z8|"D#"a,4 z4 z8|a,4 z4 z8:|



X:1
T:Classical Scherzo à la Mozart
C:OrchestrAI
M:3/4
L:1/16
Q:1/4=160
K:G
V:Vln1 name="Violin I"
%%MIDI program 40
|: "G"D4 G3A B4 z2 | "D7"A4 F3G A4 z2 | "G"B2d2 d2B2 G4 z2 | "C"E4 C3D E4 z2 |
"G"D4 G3A B4 z2 | "D7"A4 F3G A4 z2 | "G"B2d2 e2f2 g4 f2 | "D7"a4 f2 "G"g4 z2 z2 :|
V:Vln2 name="Violin II"
%%MIDI program 40
|: "G"G,4 B,3D G4 z2 | "D7"F4 D3F A4 z2 | "G"G2B2 B2G2 D4 z2 | "C"C4 E3G c4 z2 |
"G"G,4 B,3D G4 z2 | "D7"F4 D3F A4 z2 | "G"G2B2 c2d2 e4 d2 | "D7"f4 d2 "G"g4 z2 z2 :|
V:Vla name="Viola"
%%MIDI program 41
|: "G"B,4 D3G B4 z2 | "D7"A4 c3F A4 z2 | "G"B2d2 d2B2 G4 z2 | "C"G,4 E3G c4 z2 |
"G"B,4 D3G B4 z2 | "D7"A4 c3F A4 z2 | "G"B2d2 e2f2 g4 f2 | "D7"a4 f2 "G"g4 z2 z2 :|
V:Cello1 name="Cello I"
%%MIDI program 42
|: "G"G,4 G,2 D3E z4 | "D7"F,4 A,2 F3A z4 | "G"G,4 G,2 D3B, z4 | "C"G,4 C2 E3G, z4 |
"G"G,4 G,2 D3E z4 | "D7"F,4 A,2 F3A z4 | "G"G,4 G,2 B,3A z4 | "D7"A,6- "G"G,6 z2 :|
V:Cello2 name="Cello II"
%%MIDI program 42
|: "G"D,4 D,2 A,,3B,, z4 | "D7"A,4 F,2 A,2C2 z4 | "G"D,4 D,2 G,,3A,, z4 | "C"C,4 G,,2 C,2E,2 z4 |
"G"D,4 D,2 A,,3B,, z4 | "D7"A,4 F,2 A,2C2 z4 | "G"D,4 D,2 B,,3A,, z4 | "D7"A,,6- "G"G,,6 z2 :|



X:1
T:Realms in Pursuit
C:OrchestrAI
M:7/8
L:1/16
Q:3/8=140
K:Cmin
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|: "Cm"G2B2 z4 c2d2 z2 z2 | % Unexpected jump in the melody
"Ab"B2dB G2F3  | % Suspense with descending chromatic movement
"DbM7"z2A2F3  | % Modulation for otherworldly twist
"Bb7"z4 A4B2c2  |
"Fm"A4A4 G2z2 E2C2 | % Chase-like urgency
"G7"B2^B2A2 G2F2  |
"Cm"E2C2 G2B2 z4 G2A2 | % Melodic playfulness representing twists
"Ab"^A8- ^  :| % Jump back to previous motif with a raised tone
V:2 clef=treble name="Accompaniment Synth"
%%MIDI program 81
|: "Cm"c2c2 z4 e2f2 z2 z2 | % Harmonic support with pulsating rhythm
"Ab"g2g2 f4 e4 | % Layering tension
"DbM7"f2f2 d2 z2 a2g3  | % Interwoven dissonance
"Bb7"g2g2 f2e2 f2e  |
"Fm"a4a4 g4 a4 | % Sweeping feel, capturing the chase
"G7"b5   |
"Cm"g2g2 f4 e2c2 z4 | % Texture changes depicting dimension shifts
"Ab"z2^g4 ^ ^ :| % Dissonance highlighting uncertainty
V:3 clef=bass name="Bass Synth"
%%MIDI program 87
|: "Cm"c,16 | % Steady bass as a foundation for the unpredictability
"Ab"1 |
"DbM7"f,2a,2 d,8- d,5 | % Constant motion and dimensional jumps
"Bb7"z4 B,2D2 E,1 |
"Fm"A,8- A,6 G,3 | % The lower end of suspense
"G7"B,2C2 B,7  |
"Cm"C,8- C,9 1 | % Stability amidst chaos
"Ab"^A,16 z4 :| % Subtle nod to the interdimensional twist
V:4 clef=treble name="Plucked Strings"
%%MIDI program 46
|: "Cm"z8 C4 C4 | % Plucked strings for a staccato, timing effect
"Ab"z2   |
"DbM7"z4 F8 D4 |
"Bb7"B,8 z4 E5  |
"Fm"A,8- A,9  | % Mystery within the chase
"G7"B,2C2 D2 E2 F2G2 |
"Cm"z16 |
"Ab"z8 ^A4 ^g4 :| % Anticipation of the next leap



X:1
T:Noir Detective Theme
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=90
K:Cm
V:1 clef=treble
%%MIDI program 65
|: "Cm9" G,^F G2 B2  | "A+7" =F2 E2 ^D2  | "Dm7b5" F2 A2 G2 | "G9" G,2 E2 ^D2  |
"Cm9" c=B c2 e2  | "AbMaj7" B2 A2 G2 | "Bb13" F2 ^D2 F2  | "G7#5b9" B,2 A,2 ^G,2 :|
V:2 clef=treble
%%MIDI program 71
|: "Cm9" E2 E2 z2 | "A+7" C2 C2 B,2 | "Dm7b5" A2 A2 G2 | "G9" E2 E2 ^D2 |
"Cm9" G2 G2 E2 | "AbMaj7" =F2 F2 E2 | "Bb13" D2 D2 C2 | "G7#5b9" A,2 A,2 z2 :|
V:3 clef=bass
%%MIDI program 32
|: "Cm9" C,4 C,2 | "A+7" A,,2 E,2 ^D,2 | "Dm7b5" D,2 F,2 A,,2 | "G9" G,,2 B,,2 E,2 |
"Cm9" C,2 G,,2 E,,2 | "AbMaj7" _A,,2 F,2 C,2 | "Bb13" B,,2 F,2 D,2 | "G7#5b9" G,,2 E,,2 C,,2 :|
V:4 clef=treble
%%MIDI program 0
%%MIDI drumon
|: z2 "Cm9"[G,G,][^F,F,] z | z2 z "A+7"[=F=F,][E,E,]| z2 "Dm7b5"[F,F,][A,A,] z2 | z2 "G9"[G,G,][E,E,] z2 |
z2 "Cm9"[c,c,][B,B,] z2 | z2 "AbMaj7"[B,B,][A,A,] z2 | z2 z "Bb13"[F,F,][^D,D,]| z2 "G7#5b9"[B,,B,,][A,,A,,] z2 :|



X:1
T:Ensemble Intrigue
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=110
K:G
V:1 clef=treble name="Violin 1"
%%MIDI program 40
|: "G"D3 B G2 | "Am"A3 B c2 | "Bm"B3 c d2 | "C"c6 |
"G"B3 d ^c2 | "E7"=c3 B A2 | "Am"B4 G2 |1 "D7"F6 :|2 "D7"F4 A2 ||
|: "G"e6 | "Em"e3 f g2 | "A7"a6 | "D7"f3 g a2 |
"G"g3 f e2 | "C"e3 d ^c2 | "D"B3 A G2- |1 "D"G4 A2 :|2 "G"G6 ||
V:2 clef=treble name="Violin 2"
%%MIDI program 40
|: "G"B,6 | "Am"c6 | "Bm"d6 | "C"e6 |
"G"d3 ^c d2 | "E7"e3 f g2 | "Am"a3 g f2 |1 "D7"d6 :|2 "D7"d4 f2 ||
|: "G"g6 | "Em"e4 ^c2 | "A7"a3 f d2 | "D7"d3 c B2 |
"G"B3 c d2 | "C"e4 g2 | "D"e3 d ^c2 |1 "D"d4 f2 :|2 "G"g6 ||
V:3 clef=alto name="Viola"
%%MIDI program 41
|: "G"G,4 D2 | "Am"A,4 E2 | "Bm"B,4 F2 | "C"C4 G,2 |
"G"G,3 F G2 | "E7"^F3 G A2 | "Am"A4 E2 |1 "D7"F6 :|2 "D7"A,4 D2 ||
|: "G"B6 | "Em"g4 e2 | "A7"a6 | "D7"f4 d2 |
"G"g3 f e2 | "C"e3 d ^c2 | "D"B,3 A, G,2 |1 "D"D4 F2 :|2 "G"G,6 ||
V:4 clef=bass name="Cello"
%%MIDI program 42
|: "G"D,3 D, D,2 | "Am"C3 C, E,2 | "Bm"B,,3 B,, D,2 | "C"C,6 |
"G"B,,3 B,, D,2 | "E7"^F,3 F,2 A, | "Am"A,,3 A,, C,2 |1 "D7"D,6 :|2 "D7"F,4 A,,2 ||
|: "G"G,6 | "Em"G,3 E, ^C,2 | "A7"A,6 | "D7"D,3 F, A,2 |
"G"G,,3 G,, D,2 | "C"C,3 E, G,2 | "D"D,3 D, C,2 |1 "D"D,4 F,2 :|2 "G"G,6 ||



X:1
T:Reflective Journeys
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=92
K:Gmaj
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G" d4 B2 d2| "Am7" e4 c2 E2| "D7" F4 D2 F2| "G" G6 z2|
"G" d2 B2 "Em" e2 g2 | "Cmaj7" f2 e2 "D7" d2 c2 | "G" B4 A2 B2 | "G7" c6 z2 :|
|:"C" e4 "Em" g4 | "Am" a3 g f2 e2 | "D" d2 F2 "G" G4 | "C" c6 z2 :|
V:2 clef=treble name="Clarinet"
%%MIDI program 71
|:"G" B2 G2 d2 B2 | "Am7" c2 E2 A2 c2 | "D7" A2 F2 A2 c2 | "G" B4 G4 |
"G" B2 d2 "Em" g2 f2 | "Cmaj7" e2 f2 "D7" d2 c2 | "G" B4 G4 | "G7" B2 c2 B2 A2 :|
|:"C" E2 G2 "Em" B2 G2 | "Am" A2 c2 "D" F2 A2 | "G" G2 B2 "C" e2 d2 | "C" c4 B2 A2 :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"G" G,2 D2 G2 B,2 | "Am7" A,2 E2 A2 C2 | "D7" D2 A,2 D2 F2 | "G" G,4 G,4 |
"G" G,2 B,2 "Em" E2 G2 | "Cmaj7" C2 E2 "D7" A,2 D2 | "G" G,2 B,2 G,4 | "G7" G,2 F2 E2 D2 :|
|:"C" C2 E2 "Em" G,2 B,2 | "Am" A,2 C2 "D" F2 A,2 | "G" G,2 D2 "C" C2 B,2 | "C" C4 B,2 A,2 :|



X:1
T:Epic and Dark Anthem
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=50
K:Cm
% The anthem begins with a brooding melody line that sets the tone for the epic darkness
V:1 clef=treble name="Choir Aahs"
%%MIDI program 52
|: "Cm"G2 z2 Bc | "Ab"e2 c2 BA | "Bb"F2 z2 DF | "G7"G,4 z2 |
"Cm"e2 G2 A2 | "Ab"e2 c2 z2 | "Bb"d2 B2 AG | "Cm"G4 z2 |
"G" d2 B2 G2 | "Eb"G2 E2 z2 | "Ab"E2 C2 E2 | "Eb"G,4 z2 |
"Cm"c2 E2 G2 | "G7"B2 D2 G2 | "Cm"E2 C2 z2 :|
V:2 clef=bass name="String Ensemble"
%%MIDI program 48
|: "Cm"C2 z2 G,2 | "Ab"AB, C2 E2 | "Bb"B,2 z2 F,2 | "G7"D2 B,,2 z2 |
"Cm"E2 C2 G,2 | "Ab"AB, C2 z2 | "Bb"B,2 G2 E2 | "Cm"C4 z2 |
"G" B,,2 G,2 B,2 | "Eb"E2 G,2 z2 | "Ab"AB, C2 E2 | "Eb"E2 G,4 |
"Cm"C2 E2 G2 | "G7"B,2 D2 G2 | "Cm"C4 z2 :|



X:1
T:Elegant Ballroom Waltz
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=144
K:Cmaj
V:1 clef=treble name="Violin I"
%%MIDI program 40
|: "C" E3 G c2 | "Am" A3 c e2 | "Dm" f3 a d'2 | "G" g3 b g2 | "C" e3 e g2 | "Am" a3 f e2 | "F" c3 A c2 | "G7" B6 |
"C" G3 E G2 | "Am" A3 F A2 | "F" f3 D F2 | "G7" g3 B d2 | "C" c3 B A2 | "Am" G3 A B2 | "Dm" A3 G F2 | "G7" G6 :|
V:2 clef=treble name="Violin II"
%%MIDI program 40
|: "C" C3 E G2 | "Am" A3 F A2 | "Dm" D6 | "G" B,6 | "C" E6 | "Am" A,6 | "F" A,6 | "G7" D6 |
"C" C3 E G2 | "Am" A3 F A2 | "F" F6 | "G7" D6 | "C" C3 C E2 | "Am" F3 E F2 | "Dm" D3 F A2 | "G7" B,6 :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "C" C,3 C, E,2 | "Am" A,3 A, C2 | "Dm" D,3 D, F,2 | "G" G,3 B,, D,2 |
"C" E,3 E, G,2 | "Am" A,3 C E,2 | "F" C3 C A,2 | "G7" B,,6 |
"C" C,3 E, G,2 | "Am" A,3 C E,2 | "F" F,3 F, A,2 | "G7" D,3 D, G,2 |
"C" G,3 G, B,,2 | "Am" A,3 A, C2 | "Dm" D,3 A, F,2 | "G7" G,6 :|



X:1
T:Tech-Journalism Prelude
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Am
V:1 clef=treble
%%MIDI program 79
|: "Am" e4 A2 GF | "E7" E6 EF | "Am" A2 ce d2 B2 | "C" c3 B c2 DE |
"Am" e4 A2 GF | "E7" E6 EF | "Am" Ae cA "G"BG dG | "C" c8 :|
V:2 clef=treble
%%MIDI program 81
|: "Am" A,2 C4 E2 | "E7" B,2 E4 G2 | "Am" A,2 E2 C4 | "C" E2 G4 E2 |
"Am" A,2 C4 E2 | "E7" B,2 E4 G2 | "Am" A,C E2 "G" G,B, D2 | "C" C6 z2 :|
V:3 clef=bass
%%MIDI program 33
|: "Am" A,8 | "E7" B,8 | "Am" A,2 C2 E2 A,2 | "C" C2 E2 G2 E2 |
"Am" A,8 | "E7" B,8 | "Am" A,2 C2 "G" D2 G2 | "C" C8 :|



X:1
T:Epic Inspiration
C:OrchestrAI
M:4/4
L:1/4
Q:1/4=100
K:Cmaj
V:1 clef=treble name="Trombone"
%%MIDI program 57
|: "Am"[A,EA]2 [CEA]2 z2 | "F"[F,AF]2 [CAF]2 z2 | "C/G"[G,EG]2 [CEG]2 z2 | "Gsus4"[G,EG]2 [CDG]2 z2 |
"C"[CEG]2 [CEG]2 | "E7"[G^DG]2 [^DFG]2 | "Am"[AEA]2 [CEA]1z | "Fmaj7"[cAF]2 [EAc]2 |
"Dm"[ADF]2 [ADF]2 | "G"[GBd]2 [DGB]2 | "C"[CEG]2 [^CEG]2 | "A7sus4"[AEc]2 [A,E,C]2 |
"Dm"[ADF]2 [dAf]2 | "Bb2"[B,DF]2 [FDf]2 | "Esus4"[EG^G]2 [^GEG]1z | "E"[EG^G]2 [EG^G]2 :|
V:2 clef=treble name="Strings"
%%MIDI program 48
|: "Am"e2 c'2 | "F"d2 A2 | "C/G"c2 G2 | "Gsus4"d2 G2 |
"C"e2 c'2 | "E7"b,2 e2 | "Am"f2 d'2 | "Fmaj7"a2 c'2 |
"Dm"f2 A2 | "G"b2 d2 | "C"e2 g2 | "A7sus4"G2 E2 |
"Dm"F2 D2 | "Bb2"F2 B,2 | "Esus4"G2 B,2 | "E"G2 E2 :|
V:3 clef=bass name="Bass Guitar"
%%MIDI program 33
|: "Am"A,2E2 | "F"F,2A2 | "C/G"G,2G,2 | "Gsus4"G,2D2 |
"C"C,2E2 | "E7"^G,2B,2 | "Am"A,2E2 | "Fmaj7"A,2F2 |
"Dm"D2F2 | "G"G,2B2 | "C"C2G2 | "A7sus4"C,2A,2 |
"Dm"D2F2 | "Bb2"B,,2D2 | "Esus4"E,2G,2 | "E"E,2G,2 :|



X:1
T:Digital Utopia Dreamscape
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:Gmaj
% The melody and accompaniment shall convey the sense of a seamless fusion of nature and technology
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|:"G"B2 d2 B2|"C"E4 G2|"D" F3 E D2| "Em"E4 z2|
"A" c3 B A2|"Bm" B4 A2|"G"D2 B,2 "D7"D2|"G"G6:|
"G" B2 d2 B2|"C" E4 G2|"Am"A3 G F2| "D7"F4 z2|
"Em"G2 B2 e2|"C"E4 D2|"G"B,2 B2 "D7"A2|"G"G6:|
V:2 clef=bass name="Synth Pad"
%%MIDI program 90
|:"G"G,4 D2|"C"C4 E2|"D"A,3 B, C2|"Em"E4 z2|
"A"A,4 E2|"Bm"F,4 D2|"G"B,,4 "D7"A,,2|"G"G,6:|
"G"G,4 D2|"C"C4 E2|"Am"A,,4 "D7"D2|"G"G,4 z2|
"Em"E4 B,2|"C"C4 A,2|"G"G,,4 "D7"F,2|"G"G,6:|
V:3 clef=bass name="Electronic Pulse"
%%MIDI program 88
|:"G"z2 G,2 D,2|"C"z2 E2 C2|"D" z3 A, B, C|"Em"z2 E2 z2|
"A"z2 A,2 E2|"Bm"z2 D2 F,2|"G"z2 G,,2 "D7"z2|"G"z2 z2 G,2:|
"G"z2 G,2 D,2|"C"z2 E2 C2|"Am"z3 G, A, B,|"D7"z2 F,2 z2|
"Em"z2 E2 B,2|"C"z2 C2 A,2|"G"z2 G,,2 "D7"z2|"G"z2 z2 G,2:|



X:1
T:Bouncy Bop
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=160
K:Cmaj
V:1 clef=treble
%%MIDI program 1
|"C"E2 G4 G2|"F"A2 c4 c2|"Dm"F2 E2 D2 C2|"G"G,2 B,4 D2|
"C"E2 G4 G2|"F"A2 c4 c2|"G"G2 E2 C2 D2|"C"C6 z2|
|"Am"E2 A2 c3 E|"F"A2 F2 A3 c|"C"G2 E2 G2 C2|"G"B,3 D2 G2 z|
"Am"c3 E A3 c|"F"F3 A c3 d|"G"e2 c2 B2 A2|"C"C6 z2:|
V:2 clef=bass
%%MIDI program 33
|"C"C,2 E,4 G,2|"F"F,2 A,4 z c|"Dm"D,2 F,4 z A,|"G"G,2 B,4 z D|
"C"C,2 E,4 G,2|"F"F,2 A,4 z c|"G"G,2 E,4 z G,|"C"C,6 z2|
|"Am"A,2 E2 z A3|"F"F,2 C2 z F3|"C"C2 G,2 z2 E2|"G"G,3 B,2 z D2|
"Am"A,2 C2 z2 E2|"F"F,2 A,2 z2 c2|"G"B,2 G,2 z2 F,2|"C"C,6 z2:|



X:1
T:Cybernetic Pursuit
C:OrchestrAI
M:7/8
L:1/16
Q:3/8=180
K:Bmin
% The protagonist darts through the urban labyrinth
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"Bm" B8 B4 z4 | "F#" F8 F4 z4 | "E" E8 E4 e4 | "F#" F8 F4 z4 |
"Bm" B8 B4 f4 | "A" A8 A6 g2 | "Bm" B4 d4 B2 F2 G2 z2 | "F#" F8 F4 z4 |
|: "G" G4 A2 G2 F2 E2 D2 z2 | "F#" z2 F4 E4 D2 C2 z2 | "E" z4 E4 F4 G2 A2 | "Bm" B8- B6 z2 :|
V:2 clef=treble name="Arp Synth" subname="Arp."
%%MIDI program 82
|:"Bm" b4 f2 b4 z6 | "F#" f4 c2 f4 z6 | "E" e8 e4 z4 | "F#" f8- f4 z4 |
"Bm" b4 f2 d4 z6 | "A" a4 e2 a6 z4 | "Bm" b4 d2 b2 f2 g2 z4 | "F#" f4 c2 f4 z6 |
|: "G" g4 a2 g2 f2 e2 d2 z2 | "F#" f2 f4 e2 d2 c2 z4 | "E" e2 e4 f2 g4 z4 | "Bm" b8 b6 z2 :|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|:"Bm" B,4 F2 B,4 z6 | "F#" F,8 F,4 z4 | "E" E,8 E,4 z4 | "F#" F,8- F,4 z4 |
"Bm" B,4 F2 B,4 z6 | "A" A,8 A,8 | "Bm" B,4 F2 B,4 z6 | "F#" F,8 F,4 z4 |
|: "G" G,4 A2 G,2 F2 E2 z4 | "F#" F,4 F2 E2 D2 z6 | "E" E,8 E,4 z4 | "Bm" B,.8 B,.7 z :|
V:4 clef=treble name="Rhythm Synth" subname="Rhythm"
%%MIDI program 81
|:"Bm" z8 z4 z4 | "F#" z8 z4 z4 | "E" z8 z4 z4 | "F#" z8 z4 z4 |
"Bm" z8 z4 z4 | "A" z8 z6 z2 | "Bm" z4 z4 z2 z2 z2 z2 | "F#" z8 z4 z4 |
|: "G" z4 z2 z2 z2 z2 z2 z2 | "F#" z2 z4 z4 z2 z2 z2 | "E" z4 z4 z2 z2 z2 z2 | "Bm" z8- z7 z :|



X:1
T:Battle of the Titans
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Cmin
% Orchestral instruments unite to evoke the majesty and peril of titanic clash.
V:1 clef=treble name="Horns" subname="Main Melody"
%%MIDI program 61 % French Horn
|: "Cm"G4 z2 G2 | "G"B4 z2 d2 | "Cm"c4 z2 c2 | "G"D4 z4 |
"Cm"E2C2 G,2C2 | "G"D2B,2 "Ab"G,2B,2 | "Cm"C2G,2 "Fm"F,2A,2 | "G"G,4 z4 :|
V:2 clef=treble name="Strings" subname="Harmony"
%%MIDI program 49 % Strings Ensemble
|: "Cm"C4 z2 C2 | "G"D4 z2 G2 | "Cm"C4 z2 C2 | "G"D4 z4 |
"Cm"G,2E2 C2G,2 | "G"F3 E "Ab"D2C2 | "Cm"C2G,2 "Fm"F2A,2 | "G"D,4 z4 :|
V:3 clef=treble name="Woodwind" subname="Counter"
%%MIDI program 74 % Flute
|: "Cm"g2c2 e2g2 | "G"d2^f2 b2d2 | "Cm"c2e2 g2c2 | "G"d4 z4 |
"Cm"G2C2 E2G2 | "G"D2^F2 "Ab"=F2D2 | "Cm"C4 "Fm"A,4 | "G"G,4 z4 :|
V:4 clef=bass name="Brass" subname="Bass"
%%MIDI program 58 % Tuba
|: "Cm"C,4 z2 C2 | "G"D,4 z2 G,2 | "Cm"C,4 z2 C,2 | "G"D,4 z4 |
"Cm"G,,4 C,4 | "G"G,,4 "Ab"G,4 | "Cm"C,,4 "Fm"F,4 | "G"G,,4 z4 :|
% Additional percussion may be imagined to add to the epic quality, though not notated here.



X:1
T:Dance of the Fireflies
C:OrchestrAI
M:3/4
L:1/16
Q:1/4=120
K:G
V:1 clef=treble name="Piccolo"
%%MIDI program 72
|:"G" (3GAB AG (3FGA FD|"C" E4 z E2 z C4| "D" (3DEF AF (3GFE DC|"G"D8 z4|
"G" d4 d2 d2 z4 (3Bcd|"Em" e4 e2 z2 B4| "C" (3GEG CE G2 z6 E2|"D" (3AGF (3FGA D4|
"G"B6 G2 E4| "C" (3cBc A2 E3 z6 F|"D" (3DEF AF (3AGE FD|"G"G6- G4 z2:|
V:2 clef=treble name="Violin I"
%%MIDI program 40
|:"G"D4 (3GAB c3 z4 B|"C" A4 z G2 z E4| "D" F4 (3DEF A3 z4 F|"G"G8 z4|
"G" (3gfg dg B3 G z4 E2|"Em" (3efe ge d3 B z4 G2| "C" E4 (3GEG z6 CE|"D" D4 (3FGA z6 FD|
"G" (3GFG DA G3 F z4 E2|"C" (3cBc A2 F3 z6 G|"D" F4 (3DEF A3 z4 F|"G"G6- G4 z2:|
V:3 clef=treble name="Violin II"
%%MIDI program 40
|:"G" G,4 B,2 z2 D4|"C" C6 z2 A,4| "D" F4 A3 F D4|"G"G,8 z4|
"G" B4 B2 z2 G4|"Em" e6 z2 B4| "C" G4 E3 G C4|"D" D6 z2 F4|
"G" B6 z2 D4|"C" E6 z2 C4|"D" A4 c3 B G4|"G"G,6- G,4 z2:|
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"G"G,2 "C"E,2 "G"D,2 "C"G,2 z2 B,2|"D"D,2 "C"A,2 z4 "G"G,4| "G"B,,2 "D"A,,2 "G"G,,2 z2 "D"D,4|"G"G,,8 z4|
"G"G,4 B,,4 D,4|"Em"G,4 "Bm"B,,4 "Em"E,4| "C"E,2 (3G,E,G, z6 C,4|"D"D,4 z2 F,6|
"G"G,2 B,2 D2 z4 E,2|"C"G,2 E,2 "D"F,2 z2 "G"D,4|"D"A,,2 "D"D,2 "G"G,,2 z2 "D"F,4|"G"G,,6- G,,4 z2:|



X:1
T:Starry Night Serenade
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=60
K:C
V:1 clef=treble name="Soprano"
%%MIDI program 73
|:"C"E2 G4| "G7"F2 A4| "Am"A2 c4| "F"z2 F4| "C"E2 G4| "Dm7"F4 E2| "Gsus4"G4 z2| "G7"[F4A2] z2|
"C"c2 e4| "F"e2 d4| "Am"c2 A4| "G7"B2 G4| "C"E2 C4| "Em"z2 E4| "F"D2 F4| "C"C6:|
V:2 clef=treble name="Alto"
%%MIDI program 73
|:"C"C2 E4| "G7"B,2 D4| "Am"A,2 C4| "F"A2 c4| "C"G,2 E4| "Dm7"F2 E4| "Gsus4"G2 z4| "G7"G,2 A,4|
"C"e2 g4| "F"f2 e4| "Am"d2 B,4| "G7"c2 A4| "C"G2 E4| "Em"A2 G4| "F"F2 A4| "C"C6:|
V:3 clef=bass name="Tenor"
%%MIDI program 70
|:"C"G,2 C4| "G7"A,2 B,4| "Am"E2 A,4| "F"C2 F4| "C"G,2 B,4| "Dm7"A2 G4| "Gsus4"F2 z4| "G7"F2 G4|
"C"G2 c4| "F"A2 G4| "Am"F2 D4| "G7"B,2 E4| "C"C2 G,4| "Em"E2 C4| "F"A2 c4| "C"C6:|
V:4 clef=bass name="Bass"
%%MIDI program 70
|:"C"C,2 E,4| "G7"D,2 F,4| "Am"A,2 E,4| "F" F,2 A,4| "C"E,2 C4| "Dm7"D2 F,4| "Gsus4"G,2 z4| "G7"G,2 F,4|
"C"C,2 E,4| "F"F,2 D,4| "Am"A,2 C4| "G7"B,2 G,4| "C"C,2 G,4| "Em"E,2 E,4| "F"F,2 A,4| "C"C6:|



X:1
T:Candlelight Serenade
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:G
V:1 clef=treble name="Piano"
%%MIDI program 1
|: "G" G3 A B2 | "Em" G2 E2 D2 | "Am" C2 E2 A2 | "D7" F3 A c2 |
"G" B3 d g2 | "Em" e2 B2 G2 | "Am" A3 B "D7" c2 | "G" B4 z2 :|
|: "C" e3 d c2 | "G" d3 c B2 | "Am" c3 B "D7" A2 | "G" B4 z2 |
"C" e3 g "Cm" _e2 | "G" d3 B "Em" G2 | "Am" A3 B "D7" c2 | "G" G4 z2 :|
V:2 clef=treble name="Violin"
%%MIDI program 40
|: D3 F G2 | E2 B,2 G,2 | A,2 C2 E2 | D3 F A2 |
G3 B d2 | B2 G2 E2 | C3 D E2 | D4 z2 :|
|: G3 F E2 | F3 E D2 | E3 D C2 | D4 z2 |
G3 B G2 | F3 D B,2 | C3 D E2 | B,4 z2 :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|: G,3 B, D2 | E,2 G,2 B,2 | A,3 C E2 | D,3 F, A,2 |
G,3 B, D2 | E,2 G,2 B,2 | F,3 G, A,2 | G,4 z2 :|
|: C,3 E, G,2 | G,,3 B, D2 | A,,3 C E2 | G,4 z2 |
C,3 E, G,2 | G,,3 B, D2 | D,3 F, A,2 | G,,4 z2 :|



X:1
T:Ode to Beethoven
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=120
K:Cmaj
V:1 name="Piano" clef=treble
%%MIDI program 0
%%MIDI voice 0
|:"C"G16 | "G7"E6G2 F4E4 | "C"E6G2 G6A2 | "F" F6A2 G6 F2 | "C"G8- G4 z4 | "Am"E4A4 G4E4 | "Dm"F4A4 G4F4 | "G7"E8- E4 z4 |
|:"C"C4E4 G4c4 | "G"B4d4 G4B4 | "C"c4e4 g4c'4 | "G"B4d4 g4b4 | "A7/a"A4c'4 e4a4 | "Dm/F"d4f4 a4d'4 | "G7"B4G4 F4D4 | "C"C8- C4 z4 :|
|:"Cm"G,16 | "G7/B"D6F2 E4D4 | "Cm"E6G2 G6A2 | "Eb" Eb6G2 F6 Eb2 | "Ab"G8- G4 z4 | "Cm/Bb"E4G4 F4D4 | "Ab/Db"F4Ab4 G4F4 | "G7"D8- D4 z4 |
|:"Cm"C4Eb4 G4c4 | "G/B"B4d4 G4B4 | "Cm"c4eb4 g4c'4 | "G/B"B4d4 g4b4 | "F7/A"A4c'4 e4a4 | "Bb/D"d4f4 a4d'4 | "G7"B4G4 F4D4 | "Cm"C8- C4 z4 :|
|: ["^Recapitulation - back to C Major""C"G16 | "G7"E6G2 F4E4 | "C"E6G2 G6A2 | "F" F6A2 G6 F2 | "C"G8- G4 z4 | "Am"E4A4 G4E4 | "Dm"F4A4 G4F4 | "G7"E8- E4 z4 |
"C"C4E4 G4c4 | "G"B4d4 G4B4 | "C"c4e4 g4c'4 | "G7"F4A4 G4F4 | "C"A4C4 E4G4 | "G/B"D4G4 B4d4 | "C6"G4E4 C4G4 | "C"C8- C4 z4 :|



X:1
T:Pirate's Polka Plunder
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=120
K:Gmaj
V:1 clef=treble name="Accordion"
%%MIDI program 21
|: "G"D2G2 B2A2 | "D"A2F2 F4 | "G"B2d2 d2B2 | "D"A4 G4 |
"G"G2B2 d2g2 | "C"e2c2 c2G2 | "D" A2f2 f2d2 | "G"G4- G4 :|
|:"Em"B2e2 e2g2 | "D"f2d2 A4 | "C"G2E2 C2E2 | "D"D4- D2z2 |
"G"D2G2 B2d2 | "C"e2c2 E2c2 | "D"A2F2 D2F2 | "G"G4- G4 :|
V:2 clef=treble name="Brass"
%%MIDI program 61
|: "G"B,2D2 G2F2 | "D"F2A2 A4 | "G"G2B2 B2d2 | "D"F4 E4 |
"G"G2B2 d2g2 | "C"c2e2 c2E2 | "D"A2a2 a2f2 | "G"B,4- B,4 :|
|:"Em"G2B2 B2e2 | "D"F2A2 D4 | "C"E2G2 C2E2 | "D"A,4- A,2z2 |
"G"B,2D2 G2B2 | "C"c2e2 G2c2 | "D" F2A2 A2F2 | "G"B,4- B,4 :|
V:3 clef=bass name="Tuba"
%%MIDI program 58
|: "G" G,4 G,4 | "D" F,4 F,4 | "G" G,4 G,4 | "D" F,4 E,4 |
"G" G,4 G,4 | "C" E,4 C,4 | "D" A,4 A,4 | "G" G,4- G,4 :|
|: "Em" B,,4 B,,4 | "D" F,4 D,4 | "C" C,4 C,4 | "D" A,,4- A,,2z2 |
"G" G,4 G,4 | "C" E,4 C,4 | "D" F,4 F,4 | "G" G,4- G,4 :|



X:1
T:Reality Rift Rhapsody
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=140
K:Dmin
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|: "Dm"d2c2B2A2 G4 F4 | ^c4A2F2 G4 A4 | "C#dim"D^C D2 E4 F2E2 z4 | D4-D4 z4 z4 |
"Dm"A4 A2G2 F2E2 D2^C2 | "Gm"B4-G4 A2F2 G4 | "A7sus4"c2d2 e4 f2g2 a2f2 | "Dm"d8-d4 z4 :|
"Em"B2^A2 G2^F2 E6 z2 | "A"^c2A2 F2E2 D4 D2 z2 | "Dm"B2A2 G2^F2 G4 z2 z2 | "Eb"^F2G2 A4 B2^A2 G2^F2 |
"Db6"e4 ^d4 c2B2 A4 | "C^7"B,4 C4 D2C2 B,4 | "Bm7b5"A4 G4 F2E2 D4 | "E"^F2G2 A4-B4 z4 :|
V:2 clef=treble name="Strings"
%%MIDI program 48
|: "Dm"a,2a2 f2f2 d4-d4 | e2e2 c2c2 A4-A4 | "C#dim"d2d2 f2f2 a2a2 d'4 | c'2c'2 a2a2 f2f2 d4 |
"Dm"d'd2 d2 c'c2 c2 a2a2 a2 | "Gm"g2g2 b2b2 d'4 d4 | "A7sus4"c'c2 c2 f2f2 f4 f3 | "Dm"a4 a4 d'd2 c2 a2a :|
"Em"g2g2 b2b2 e'4 e4 | "A"c'2c'2 a2a2 f4 f4 | "Dm"d'2d'2 d2 d2 a2a2 a2 z2 | "Eb"c'2c'2 g2g2 e'4 e4 |
"Db6"f2f2 f2f2 f4 f4 | "C^7"a,2a2 e2e2 e4 e4 | "Bm7b5"d2d2 A2A2 A4 A4 | "E"g2g2 b2b2 e'4 e4 :|
V:3 clef=bass name="Bass"
%%MIDI program 39
|: "Dm"D,4 D,2C2 B,2A,2 z4 | "C#dim"C4=C4 C4=C4 | "Dm"D,8 D,4 C,4 | "Eb"E,4=E4 E,4=E4 |
"Dm"A,8 A,8 | "Gm"G,4 G,4 G,4 G,4 | "A7sus4"A,2A,2 A,4-F,4 z4 | "Dm"D,4 D,4 D,8 :|
"Em"E,4=E4 E,4=E4 | "A"A,4=A4 A,4=A4 | "Dm"D,8 D,8 | "Eb"E,4=E4 E,4=E4 |
"Db6"D,4=D4 D,4=D4 | "C^7"C,4=C4 C,4=C4 | "Bm7b5"B,4=B4 B,4=B4 | "E"E,4=E4 E,8 :|



X:1
T:Yearning Heights
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Dmaj
V:1 clef=treble
%%MIDI program 56
% Trumpet - Melody
|: "D"A2 F2 G2 E2 | "G"A4 F2 D2 | "A"F2 D2 E2 F2 | "D"D4 z4 |
"D"F2 E2 F2 G2 | "G"B,4 A,2 F2 | "A"A2 G2 F2 E2 |1 "D"D6 z2 :|2 "D"D4 z4 ||
% Chorus
|: "D"A2 ^c2 d2 e2 | "Bm"f4 e2 ^c2 | "G"d2 B2 A2 G2 | "A"A6 z2 |
"D"A2 ^c2 d2 e2 | "Bm"f4 e2 ^c2 | "G"B2 A2 G2 F2 |1 "A"G6 z2 :|2 "A"A4 z4 |]
V:2 clef=tenor
%%MIDI program 57
% Trombone - Harmony
|: "D"F2 D2 F2 A2 | "G"B,4 E2 C2 | "A"F2 E2 D2 E2 | "D"F4 z4 |
"D"A2 F2 G2 A2 | "G"B,4 G,2 E2 | "A"C2 B,2 A,2 G,2 |1 "D"F6 z2 :|2 "D"F4 z4 ||
% Chorus
|: "D"F2 A2 B2 ^c2 | "Bm"e4 ^c2 A2 | "G"d2 B,2 A2 G2 | "A"=C6 z2 |
"D"F2 A2 B2 ^c2 | "Bm"e4 ^c2 A2 | "G"F2 E2 D2 C2 |1 "A"B,6 z2 :|2 "A"A4 z4 |]
V:3 clef=bass
%%MIDI program 58
% Tuba - Bass
|: "D"D,4 A,4 | "G"G,4 D,4 | "A"A,4 E,4 | "D"D,4 z4 |
"D"A,4 F,4 | "G"G,4 B,,4 | "A"A,4 E,4 |1 "D"D,6 z2 :|2 "D"D,4 z4 ||
% Chorus
|: "D"D,2 F,2 G,2 A,2 | "Bm"B,4 A,2 F,2 | "G"G,2 D,2 G,2 A,2 | "A"A,6 z2 |
"D"D,2 F,2 G,2 A,2 | "Bm"B,4 A,2 F,2 | "G"G,2 F,2 E,2 D,2 |1 "A"C,6 z2 :|2 "A"A,4 z4 |]



X:1
T:Journey of the Mind's Eye
C:OrchestrAI
M:C
L:1/8
Q:1/4=100
K:Em
V:1 clef=treble name="Flute" sname="Fl."
%%MIDI program 73
[E2B2] [E2B3]  |: "Em"[E2B2] z "D"[F]  | "C"[G2E2] z2 |1 "B"[A,2E2] z2 :|2 "B"[A,2D2] z2 ||
"G"B2 z2 | "Em"B2 "Am"e2 | "B7"d2 cB | "Em"B2 AG | "Am"A2 AB | "D7"c2 de | "G"B2 Bd | "Em"e4 ||
V:2 clef=treble name="Violin" sname="Vl."
%%MIDI program 40
z2 B,2 |: "Em"G,2 "D"F,2 | "C"E,2 z2 |1 "B"B,2 z2 :|2 "B"B,2 z2 ||
"G"G2 z2 | "Em"E2 "Am"C2 | "B7"D2 ED | "Em"E2 DC | "Am"A,2 A,B, | "D7"C2 CD | "G"B,2 B,C | "Em"E2 z2 ||
V:3 clef=bass name="Cello" sname="Vc."
%%MIDI program 42
z2 E,2 |: "Em"E,2 {G,}"D"F, | "C" G,2 {B,}[G,,] |1 [B,,2E,2] z2 :|2 [B,,2A,,2] z2 ||
"G"G,,2 z2 | "Em"E,2 "Am"A,,2 | "B7"D,2 C,D, | "Em"E,4 | "Am"A,2 A,B, | "D7"D,2 F,A, | "G"G,,2 B,,2 | "Em"E,2 z2 ||



X:1
T:Ode to the Cosmos
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=50
K:Emaj
V:1 clef=treble name="Violin 1"
%%MIDI program 40
|:"E"B4 (3GFE z2 | "Bsus4"F6- | "Eadd9"F3E D2 | "C#m7"G,6 | "Amaj7"A,3B, C2 |
"E/B"B,6 | "Bsus4/F#"F4 F2 | "Eadd9"G4 (3FE^D z2 | "Am6"E6 | "C#m9"G,4 A2 :|
|:"E/B"B,4 z2 | "Aadd9/E"E4 E2 | "F#m11"A,4 A,2 | "B11"B4 z2 |
"Aadd9"e4 c'2 | "E6"B6 | "G#m11"B,4 (3DEF z2 | "Aadd9"E4 ^D2 | "Eadd9/B"B,4 E2 | "E6"e6 :|
V:2 clef=treble name="Violin 2"
%%MIDI program 40
|:"E"e4 (3dcB z2 | "Bsus4"B6 | "Eadd9"e3f g2 | "C#m7"a4 a2 | "Amaj7"c'4 B2 |
"E/B"B6 | "Bsus4/F#"B2 B2 z2 | "Eadd9"g4 (3fg^e z2 | "Am6"d2 BG z2 | "C#m9"a4 g2 :|
|:"E/B"B4 B2 | "Aadd9/E"E4 F2 | "F#m11"A4 G2 | "B11"B4 B2 |
"Aadd9"A4 B2 | "E6"e4 e2 | "G#m11"g4 (3a^gf z2 | "Aadd9"E4 F2 | "Eadd9/B"B4 (3BcA z2 | "E6"e4 B,2 :|
V:3 clef=alto name="Viola"
%%MIDI program 41
|:"E"B,4 A,2 | "Bsus4"F,4 E2 | "Eadd9"D4 C2 | "C#m7"A,6 | "Amaj7"A,3B, C3 |
"E/B"B, (3B,C^D E4 z | "Bsus4/F#"F,4 F2 | "Eadd9"D4 (3D^CE z2 | "Am6"E2 D4 | "C#m9"A,2 F,4 :|
|:"E/B"E,4 z2 | "Aadd9/E"E4 E2 | "F#m11"A,4 A2 | "B11"B,4 z2 |
"Aadd9"c2 (3cde | "E6"B,4 (3^GAB z2 | "G#m11"B,4 (3ABc z2 | "Aadd9"E3F G2 | "Eadd9/B"E,4 A2 | "E6"e4 E2 :|
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"E"E,2 B,2 E2 | "Bsus4"F,6 | "Eadd9"E,2 G,2 B,2 | "C#m7"G,,4 G,2 | "Amaj7"A,,3B,, E,2 |
"E/B"B,,4 B,2 | "Bsus4/F#"F,7  | "Eadd9"E,2 E,2 B,2 | "Am6"D,4 F,2 | "C#m9"G,,4 A,2 :|
|:"E/B"E,,4 E,2 | "Aadd9/E"E,4 E,2 | "F#m11"A,,4 A,2 | "B11"B,,4 B,2 |
"Aadd9"A,4 (3(cBA z2 | "E6"B,6 | "G#m11"B,,4 (3(DEF z2 | "Aadd9"E,4 ^D,2 | "Eadd9/B"B,,4 E,2 | "E6"e,6 :|



X:1
T:Whispers of the Old Forest
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=80
K:Am
V:1 clef=treble
%%MIDI program 74
|:"Am"e4 a2|"C"g3e dc|"G"B4 g2|"Em"g3e dB|"Am"a6|
"A"e3d cB|"F"AG F2 E2|"E7"^G3E D2|"Am"A6:|
V:2 clef=treble
%%MIDI program 74
|:"Am"A2 e2 c2|"C"G2 E2 C2|"G"G,2 B,2 G2|"Em"E2 G2 E2|"Am"A,2 A,2 z2|
"A"c3B AG|"F"F3E DC|"E7"B,3A, GE,|"Am"A,2 A,2 z2:|
V:3 clef=bass
%%MIDI program 70
|:"Am"C4 E2|"C"C4 A,2|"G"B,4 D2|"Em"G,4 G2|"Am"A,4 C2|
"A"A,3B, C2|"F"F,4 A,2|"E7"^G,3E, D2|"Am"A,4 z2:|



X:1
T:Nightmare Confrontation
C:OrchestrAI
M:C
L:1/16
Q:1/4=180
K:Bm
% The piece starts with an adrenaline-pumping motif to set the mood for the battle
V:1 clef=treble name="Lead" subname="Synth Lead"
%%MIDI program 81
|: "Bm"[B4d4f4] z8 | "Em"[e4g4b4] z8 | "F#"[f3a3c'3] z4 f4 | "Bm"[B3d3f3] z4 B8 |
"F#"f4a4f4d4 | "Bm"B4f4B4d4 | "E"a4g4f4e4 | "F#7"f8 e4f4 |
"Bm"d4B4F4D4 | "A"c3AB4 A8 | "G"M:6/8 [B8g8] | "A"M:C [A4e4c4] z8 :|
% Counter melody giving the chaotic sense of the battle's unpredictability
V:2 clef=treble name="Strings" subname="Pizz."
%%MIDI program 45
|: "Bm"B,16 | "Em"E,16 | "F#"[F,4A,4C4]F,8 | "Bm"B,8 .D16 |
"F#"F,8 A,8 | "Bm"B,8 D8 | "E"[E,4G,4B,4]E,8 | "F#7"[F,4A,4C4]F,8 |
"Bm"[B,2D2F2] z12 | "A"[C2E2A,2] z12 | "G"M:6/8 [B,8G8] | "A"M:C [A,4E4C4] z8 :|
% Lower strings providing dark harmonic underpinning
V:3 clef=bass name="Brass" subname="Brs."
%%MIDI program 61
|: "Bm"B,4 z4 B,4 z4 | "Em"G,4 z4 E,4 z4 | "F#"[F,,4A,,4C,4] z4 F,,8 | "Bm"B,,8 D,8 |
"F#"F,,8 A,,8 | "Bm"B,,8 D,8 | "E"E,8 G,8 | "F#7"F,,8 A,,8 |
"Bm"B,,4 z4 B,,8 | "A"A,,8 C,8 | "G"M:6/8 G,,8 B,,8 | "A"M:C A,,8 E,8 :|
% Continuous driving texture in the percussion, adding urgency
V:4 clef=perc name="Percussion" subname="Perc."
%%MIDI program 115
|: "Bm"[^G,G,G,G,]16 | "Em"[E,E,E,E,]16 | "F#"[^F,F,F,F,]16 | "Bm"[B,B,B,B,]16 |
"F#"[^F,F,F,F,]16 | "Bm"[B,B,B,B,]16 | "E"[E,E,E,E,]16 | "F#7"[^F,F,F,F,]16 |
"Bm"[B,B,B,B,]16 | "A"[A,A,A,A,]16 | "G"M:6/8 [G,G,G,G,]12 | "A"M:C [A,A,A,A,]16 :|



X:1
T:Fantasy Quest Overture
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=110
K:Dmin
V:1 clef=treble name="Lead"
%%MIDI program 74
|:"Dm"D2 F A2 d|"C"=c2 E G2 c|"Bb"B2 D F2 B|"A7"(A3 A3)|
"Dm"F2 d A2 f|"Gm" =g2 e d2 B|"A7"c2 A E2 G|"Dm"(D3 D3)|
"Dm" A2 f a2 gf|"C"e2 d g2 ec|"Bb"d2 B G2 FD|"A7"C3 -C2 D|
"Dm"(D2 |1 "C"F2 "Gm"G2 A2 :|2 "C"F2 "Gm"G2 A3) ||
V:2 clef=treble name="Harmony"
%%MIDI program 73
|:"Dm"D2 A F2 D|"C"E2 G =c2 E|"Bb"B,2 D _B,2 D|"A7"A,3 -A,3|
"Dm"d2 A F2 D|"Gm"B,2 G B2 d|"A7"e2 c A2 E|"Dm"D3 -D3|
"Dm"a2 f d2 cB|"C"=c2 B G2 E2|"Bb"B,2 D G2 E2|"A7"A,2 E C3 -
|"Dm"D2 |1 "C"E2 "Gm"F2 G2 :|2 "C"E2 "Gm"F2 A2 ||
V:3 clef=bass name="Bass"
%%MIDI program 34
|:"Dm"[D2 F2] [A,2 C2] [D,2 F2]|"C"[E2 G2] [_B,2 E2] [E,2 G2]|
"Bb"[D2 F2] [B,2 D2] [F,2 B,2]|"A7"[E2 G2] [C2] [E2]|
"Dm"[D2 F2] [A,2 C2] [D,2 F2]|"Gm"[D2 B,2] [G,2 B,2] [D2 B,2]|
"A7"[C2 E2] [A,2 C2] [E,2 C2]|"Dm"[D,4 F,4 A,4]|
"Dm" A,2 D F A d f|"C"c E G c e g|"Bb"B, D F B d f|
"A7"A, E G A c e|"Dm"F A d F A d|"Gm"G B d G B d|
"A7"A c e A c e|"Dm"D2 :|



X:1
T:Battle for Earth's Dominion
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=144
K:Bmin
V:1 clef=treble name="Trumpet" subname="Hero's Theme"
%%MIDI program 56
|:"Bm"F4 z4 G4 z4 | A8 B4 d4 | c4 z4 B4 z4 |1 "F#m"A8 F4 z4 :|2 "F#m"A8 F4 E5  |
"F#m" A,4-C4 E4-F4 | G,4-B,4 d4 e4 | "G"f4 z4 "A7"e4 z4 | "Bm"d4 c4 B4 A4 |
"G"d4 z4 "A7"e4 z4 | "Bm"d4 c4 B4 A4 | "Em"G4 F4 E4 D4 | "F#7"C8-C4 F4 |
V:2 clef=treble name="French Horns" subname="Alien Fleet"
%%MIDI program 61
|:"Bm"D8 F4 A4 | G8 E4 c4 | "D"B8 d4 B4 |1 "F#7"G,8 E4 C4 :|2 "A"C8 B,4 D5  |
"A"C4-F4 A4 G4 | "Em"B4-A4 D4 F4 | "A7"G8 G4 A4 | "D"F4 E4 D4 C4 |
"A7"G8 G4 F4 | "D"F4 E4 D4 C4 | "G"B,8 B,4 A4 | "F#7"A4 G4 F4 E4 |
V:3 clef=bass name="Cellos & Basses" subname="Battle Rhythm"
%%MIDI program 42
|:"Bm"B,4 E4 B4 E4 | "F#m"F4 A4 c4 A4 | "G"G,4 B,4 d4 B,4 |1 "A7"A,4 C4 E4 C4 :|2 "A7"A,4 C4 E4 A,4 |
"D"F,8 A,4 A,4 | "F#m"F,8 A,8 | "G"G,8 B,8 | "A7"A,8 E8 |
"Em"F,8 A,8 | "F#7"F,8 A,8 | "G"G,8 B,8 | "A"A,8 E8 |
V:4 clef=treble name="Synth Lead" subname="Lasers & Explosions"
%%MIDI program 81
|:"Bm"d4e4f4g4 | a4g4f4e4 | "D"b4a4g4f4 |1 "F#7"c4B4A4G4 :|2 "A"d4c4B4A4 |
"A"d4c4B4A4 | "Em"g4f4e4d4 | "A7"c4B4A4G4 | "D"f4e4d4c4 |
"A7"c4B4A4G4 | "D"f4e4d4c4 | "G"b4a4g4f4 | "F#7"c4B4A4G4 |



X:1
T:Trombone Trio Capers (Revised)
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Gm
V:1 clef=bass name="Trombone I"
%%MIDI program 57
|: "Gm" G3 A B2 A>G | F2 E>F G2 D2 | "Cm" E3 F G2 A>B | "D" ^F2 A2 D4 |
"Gm" B3 c d2 B>A | G2 F>E D2 G2 | "Eb" _E3 F G2 A>B | "D" A2 ^F2 G4 :|
V:2 clef=bass name="Trombone II"
%%MIDI program 57
|: "Gm" D3 E F2 D>C | B,2 C>D E2 B,2 | "Cm" C3 D E2 F>G | "D" D2 ^F2 A,4 |
"Gm" G3 A B2 G>F | E2 D>C B,2 D2 | "Eb" B,3 C D2 E>F | "D" F2 D2 B,4 :|
V:3 clef=bass name="Trombone III"
%%MIDI program 57
|: "Gm" G,3 A, B,2 C>D | D2 C>B, G,2 B,2 | "Cm" C3 B, A,2 G,>F, | "D" D,2 A,2 D4 |
"Gm" G,3 A, B,2 C>D | E2 D>C B,2 G,2 | "Eb" G3 F _E2 D>C | "D" D2 A,2 G,4 :|



X:1
T:Evening Reflections
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=110
K:G
V:1 clef=treble name="Piano Right Hand"
%%MIDI program 0
|:"G" B2A2G2 | "Em" E3FG2 | "Am" A2F2E2 | "D7" D3EF2 | "G" G3FE2 | "C" C2B,2C2 | "D" D2E2D2 | "G"(3DEF G2z2 z2 |
"Em" B2A2G2 | "C" c4B2 | "Am" A2d2c2 | "D7" F3G A2 | "G" B3AG2 | "C" E2C2D2 | "G/D" D2G2F2 | "G" G4z2:|
V:2 clef=treble name="Piano Left Hand"
%%MIDI program 0
|:"G"D2G,2B,2 | "Em"B,2E2G2 | "Am"C2A,2E2 | "D7"A,2D2F2 | "G"B,2G2B2 | "C"C2E2G2 | "D"A,2D2F2 | "G"B,2G2B2 |
"Em"B,2E2G2 | "C"C2C2E2 | "Am"A,2A2E2 | "D7"A,2D2F2 | "G"B,2G2B2 | "C"C2E2G2 | "G/D"D2G,2B,2 | z2 "G"G,2G2:|



X:1
T:Cowboy’s Jaunt
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:G
V:1 clef=treble name="Banjo"
%%MIDI program 105
|: "G"D4 B2d2 | "C"e4 e2d2 | "G"B4 G3A | "D7"B6 AG |
"G"G2B2 d2B2 | "C"e2c2 A2F2 | "G"DG3 B3d |1 "D7"A4 G4 :|2 "D7"A4 A4 |:
V:2 clef=treble name="Harmonica"
%%MIDI program 22
|: "G"B2d2 g2B2 | "C"c2e2 e2c2 | "G"d2B2 G2D2 | "D7"F2A2 A4 |
"G"d3e d2B2 | "C"c2A2 A2F2 | "G"G2B2 d2g2 |1 "D7"F4 G4 :|2 "D7"F4 z4 |:
V:3 clef=bass name="Acoustic Bass"
%%MIDI program 32
|: "G"G,2G,2 D2D2 | "C"C2E2 G,2C2 | "G"G,2B,2 D2G,2 | "D7"D2A,2 A,2D2 |
"G"G,4 D4 | "C"C4 E4 | "G"G,2D2 G4 |1 "D7"D4 G,4 :|2 "D7"D4 z4 |:



X:1
T:Warm Embrace
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=90
K:C
V:1 clef=treble
%% MIDI program 1
|"C"E2 G3 C|"Am"A3 E G2|"F"F3 E F2|"G7"G6|
"C"E2 G3 A|"Am"A6|"F"F3 E C2|"C"G4 E2|
"C"C3 E G2|"Am"A3 E A2|"Dm"D3 F A2|"G7"B6|
"C"C3 E G2|"Am"A3 E G2|"F"A2 A z G2|"C"C4 z2|
V:2 clef=treble
%% MIDI program 1
||"C"C2 E3 G|"Am"A3 C E2|"F"F3 G A2|"G7"B3 G3|
"C"C2 E3 F|"Am"A6|"F"F3 G F2|"C"E3 C E2|
"C"C2 E3 C|"Am"A3 G E2|"Dm"F3 D F2|"G7"D3 G B2|
"C"C2 E3 C|"Am"A3 C E2|"F"G2 F z E2|"C"C4 z2|
V:3 clef=bass
%% MIDI program 32
|"C"C,2 E,3 G,|"Am"A,3 E,3|"F"F,3 C F,2|"G7"B,3 G,3|
"C"C,2 E,3 G,|"Am"A,6|"F"F,2 A, z C2|"C"G,4 E,2|
"C"C,2 G,3 E,|"Am"A,3 E,3|"Dm"D,3 A, D2|"G7"G,3 B,3|
"C"C,2 G,3 C|"Am"A,3 C3|"F"F,2 A, z G,2|"C"C,4 z2|



X:1
T:Bandstand Boogie
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=150
K:D
V:1 clef=treble name="Saxophone Lead"
%%MIDI program 65
|: "D"D8 F8 | A4G4 F4E4 | "G"G8 B,8 | D4C4 B,4A,4 |
"A"A8 e8 | c4B4 A4G4 | "D"F8 "A7"E8 | D4C4 B,4A,4 |
"D"A4G4 F4E4 | "Bm"d8 c8 | "G"B8 "A7"c8 | "D"d4c4 B4A4 |
"D"A6G6 F4 | "G"G4F4 E4D4 | "A"A,4B,4 c4d4 | "D"d8- d4z4 :|
|: "G"B8- B4d4 | "A"c8- c4e4 | "D"f8- f4a4 | "Bm"g8- g4b4 |
"G"B4c4 "A7"d'4c'4 | "D"b8 a8 | "D"A4F4 "G"G4D4 | "A"A,4E4 D4C4 |
"D"D8 F8 | A4G4 F4E4 | "G"G8- G4B,4 | D4C4 B4A,4 |
"A"A8 e8 | c4B4 A4G4 | "D"F8 "A7"E8 | D8- D4z4 :|
V:2 clef=treble name="Trumpet Riffs"
%%MIDI program 56
|: "D"d'8 f'8 | a4b4 a4g4 | "G"g'8 c'8 | a4g4 f4e4 |
"A"e'8 a8 | g4f4 e4d4 | "D"d'8 "A7"c8 | "D"f4e4 d4c4 |
"D"b4a4 g4f4 | "Bm"d'8 c'8 | "G"b8 "A7"c8 | "D"d'4c'4 b4a4 |
"D"a6f6 g4 | "G"g4f4 e4d4 | "A"e,4f,4 g4a4 | "D"d'8- d'4z4 :|
|: "G"g8- g4b4 | "A"f8- f4a4 | "D"d8- d4f4 | "Bm"e8- e4g4 |
"G"g4a4 "A7"f4g4 | "D"d'8 c8 | "D"f4d4 "G"g4b,4 | "A"e,4d4 c4B,4 |
"D"d'8 f'8 | a4b4 a4g4 | "G"g'8- g4c'4 | a4g4 f4e4 |
"A"e'8 a8 | g4f4 e4d4 | "D"d'8 "A7"c8 | "D"d'8- d'4z4 :|
V:3 clef=treble name="Trombone"
%%MIDI program 58
|: "D"[FD]8 [AF]8 | [ce]4[db]4 [AF]4[GE]4 | "G"[GB]8 [D'B,]8 | [ce]4[df]4 [D'B,]4[C'A,]4 |
"A"[EA]8 [ae]8 | [ag]4[bf]4 [ae]4[GD]4 | "D"[AF]8 "A7"[EG]8 | [FD]4[GE]4 [D'C]4[C'B,]4 |
"D"[ce]4[db]4 [AF]4[GE]4 | "Bm"[df]8 [ec]8 | "G"[DB]8 "A7"[dc]8 | "D"[df]4[ce]4 [DB]4[C'A]4 |
"D"[ab]6[af]6 [AG]4 | "G"[GB]4[AF]4 [GE]4[FD]4 | "A"[E,A,]4[DF]4 [ce]4[df]4 | "D"[df]8- [df]4z4 :|
|: "G"[DB]8- [DB]4[df]4 | "A"[ec]8- [ec]4[ea]4 | "D"[fA]8- [fA]4[af]4 | "Bm"[ge]8- [ge]4[ag]4 |
"G"[DB]4[CE]4 "A7"[dc']4[cb']4 | "D"[ba]8 [af]8 | "D"[F,A,]4[CE]4 "G"[GB]4[BD]4 | "A"[E,A,]4[EG]4 [FD]4[EC]4 |
"D"[dF]8 [AF]8 | [ce]4[db]4 [AF]4[GE]4 | "G"[GB]8- [GB]4[D'B,]4 | [ce]4[df]4 [D'B,]4[C'A,]4 |
"A"[EA]8 [ae]8 | [ag]4[bf]4 [ae]4[GD]4 | "D"[AF]8 "A7"[EG]8 | "D"[dF]8- [dF]4z4 :|
V:4 clef=bass name="Electric Bass"
%%MIDI program 33
|: "D"[DF]12 :| [AF]12 | "G"[GB]12 | [BG]12 |
"A"[AE]12 | [ae]12 | "D"[DF]12 | [DF]12 |
"D"[df]12 | "Bm"[Bd]12 | "G"[GB]12 | "A"[A,ce]12 |
"D"[df]12 | "G"[GB]12 | "A"[ae]12 | "D"[DF]12 |
|: "G"[GB]12 | "A"[ac]12 | "D"[df]12 | "Bm"[bd]12 |
"G"[GB]12 | "A7"[ac]12 | "D"[DF]12 | "A"[AE]12 |
"D"[DF]12 | [AF]12 | "G"[GB]12 | [BG]12 |
"A"[AE]12 | [ae]12 | "D"[DF]12 | "A7"[EG]12 :|
"D"[DF]12 :|



X:1
T:Hispanic Heartbeat
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=120
K:Am
V:1 clef=treble name="Guitar"
%%MIDI program 25
|: "Am"e2 A c2 A | "E"B2E G2E | "Am"A2c e2c | "G"BAG "F"EFG |
"Am"e2A c2A | "E"B2E G2E | "Am"cBA "G"BGE |1 "Am"A3-A2z :|2 "Am"A3-A2A ||
|: "C"g2e c2G | "G"B2d g2B | "Am"c2A e2c | "G"d^cd e2f |
"C"g2e c2G | "G"B2d g2B | "Am"Ace "E7"eG^D |1 "Am"E3-E2A :|2 "Am"E3-E2z ||
V:2 clef=treble name="Trumpet"
%%MIDI program 56
|: "Am"A2e c2e | "E"^G2B a2g | "Am"a2e c2B | "G"d2B "F"c2A |
"Am"A3 c2e | "E"E3 g2f | "Am"e2A "G"G2E |1 "Am"A3-A2z :|2 "Am"A3-A2E ||
|: "C"c2G E2C | "G"D2G B2d | "Am"c2A E2A | "G"^c2d e2f |
"C"c2e G2c | "G"D3 B2d | "Am"Ace "E7"eG^D |1 "Am"E3-E2E :|2 "Am"E3-E2z ||
V:3 clef=bass name="Bass"
%%MIDI program 33
|: "Am"A,3 E,2A, | "E"^G,3 B,2E | "Am"A,3 E2A, | "G"G,3 "F"F,2E, |
"Am"A,3 E,2A, | "E"E,3 ^G,2E | "Am"A,2F, "G"G,2D, |1 "Am"A,,3-A,2z :|2 "Am"A,,3-A,2A, ||
|: "C"C,3 G,2C, | "G"G,3 D2G, | "Am"A,3 E,2A, | "G"G,2B, A,2F, |
"C"C,3 G,2C, | "G"D,3 G,2B, | "Am"A,3 "E7"E,2^D, |1 "Am"E,,3-E,2A, :|2 "Am"E,,3-E,2z ||



X:1
T:Boss Battle Allegro
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=200
K:Emin
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|: "Em"E4E4 G8 | "D"B4B4 A8 | "C"G4G4 E8 | "B"B,4B,4 F8 |
"Am"A4A,4 C8 | "G"D4D4 F8 | "F#m"A4A4 c8 | "B7"B,4E4 B8 :|
V:2 clef=treble name="Strings"
%%MIDI program 48
|: "Em"B8- B4z4 | "D"A6F2 A,6F2 | "C"G4E4 C8 | "B"F4D4 B,8 |
"Am"C4A,4 A,4A4 | "G"F8- F4z4 | "F#m"A4c4 F8 | "B7"F4B,4 B,8 :|
V:3 clef=treble name="Brass"
%%MIDI program 61
|: "Em"E2G2 B4 e4 z4 | "D"d2f2 A4 d4 z4 | "C"c2e2 G4 c4 z4 | "B"b2d2 F4 b4 z4 |
"Am"a2c2 e4 a4 z4 | "G"g2b2 d4 g4 z4 | "F#m"f2a2 b4 f4 z4 | "B7"B2^A2 G4 F4 z4 :|
V:4 clef=bass name="Percussive Organ"
%%MIDI program 18
|: "Em"E,4 E,4 G,8 | "D"D,4 D,4 B,,8 | "C"C,4 C,4 G,,8 | "B"B,,4 B,,4 F,8 |
"Am"A,4 A,4 C8 | "G"G,4 G,4 D,8 | "F#m"F,4 F,4 A,8 | "B7"B,,4 E,4 B,,8 :|



X:1
T:Digital Eden
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Cmaj
% The piece will utilize smooth synths for a futuristic but warm texture
V:1 clef=treble name="Lead Synth" subname="Melody"
%%MIDI program 81
|: "C"E4 G4 | c3B A2G2 | "Am"A6 ^G2 | "Em"G4 E4 |
"F"F3A G2F2 | "G"G6 z2 | "C"E4 G4 | c3B A2G2 |
"Dm"A4 f4 | "G" e3d c2B2 | "Am"A2A2 G2^F2 | "Em"G4 E4 |
"F"F2E2 D2F2 | "G"G4- G3A | "C"c4 c3B | "Cmaj7"A8 :|
V:2 clef=treble name="Pad Synth" subname="Accompaniment"
%%MIDI program 92
|: "C"c2e2 e2g2 | C6 z2 | "Am"a2e2 c'2a2 | "Em"g4 b4 |
"F"f2a2 a2c'2 | "G"d4 b4 | "C"c2e2 G2c2 | C6 z2 |
"Dm"f2d2 f2a2 | "G"g2b2 B2g2 | "Am"a2e2 E2c2 | "Em"g4 b4 |
"F"f2a2 A2c2 | "G"d4 b4 | "C"c2e2 g4 | "Cmaj7"e6 z2 :|
V:3 clef=bass name="Bass Synth" subname="Foundation"
%%MIDI program 83
|: "C"C,6 C2 | C,6 C2 | "Am"A,6 A,2 | "Em"E,6 E2 |
"F"F,6 F2 | "G"G,6 G2 | "C"C,6 C2 | C,6 C2 |
"Dm"D,6 D2 | "G"G,6 G2 | "Am"A,6 A,2 | "Em"E,6 E2 |
"F"F,6 F2 | "G"G,6 G2 | "C"C,6 C2 | "Cmaj7"B,,8 :|



X:1
T:Shadow Tyrant's Assault
C:OrchestrAI
M:7/8
L:1/16
Q:3/8=100
K:Cmin
V:1 clef=treble name="Strings" subname="Vln."
%%MIDI program 48
|:"Cm"G4 z2 G3  | E4 z2 E3  | "G"B,4 z2 B3  | "Ab"G4 z3 G |
"Cm"G8- G  | A4 z2 G3  | "Bb"F4 z2 F3  | "G"D2 G,4 z G, :|
V:2 clef=treble name="Brass" subname="Hrn."
%%MIDI program 61
|: "Cm"G,4 D4 G,  | E,4 C4 E,  | "G"B,8- B,  | "Ab" G,8 G,  |
"Cm"G,4 G,2 C3  | A,4 A,2 G,3  | "Bb"F4 F,2 B,3  | "G"D,2 D,4 G,3  :|
V:3 clef=bass name="Choir" subname="Choir Aahs"
%%MIDI program 52
|: "Cm"C,2 z2 C2 B,2 | B,2 z2 C2 D2 | "G"G,4- G,2 A,3  | "Ab"G,2 G4 z2 |
"Cm"C,8 C  | "Eb"E,4 E2 C3  | "Fm"F,4 G,2 E,3  | "G7"B,,4 B,2 D,3  :|
V:4 clef=bass name="Timpani" subname="Timp."
%%MIDI program 47
|: "Cm"C,4 z4 | E,4 z4 | "G"G,4 z4 | "Ab"Ab,4 z3 |
"Cm"C,4 z4 | "Eb"E,4 z4 | "Fm"F,4 z4 | "G7"G,4 z4 :|



X:1
T:Friday Night Expanded
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Bb
V:1 clef=treble name="Melody"
%%MIDI program 56
|: z4 zFGA | "Bb"B2 ^A2 B2 z F | "Eb"B2 ^A2 B2 z2 :|
|: "F7" Fz F2 E2D2 | "Bb"D4 z2 FG | "Gm"G2F2 E2D2 | "Cm7"C2B,2 B,4 |
"F7" F4 EF GA | "Bb"B6 z2 | "Bb"B2 ^A2 B2 z F | "Eb"B2 ^A2 B2 z2 |
"F7" Fz F2 E2D2 | "Bb"D4 z2 FG | "Gm"G2F2 E2D2 | "Cm7"C2B,2 B,4 |
"F7" F6 EF | "Bb"B6 GA | "F7"Bc dc B2 AG | "Bb"B4- B4 |]
V:2 clef=treble name="Harmony"
%%MIDI program 70
|: z4 zcec | "Bb"d4 d2 z2 | "Eb"e2d2 c4 :|
|: "F7"A4 G2F2 | "Bb"d4 d2 z2 | "Gm"e2d2 c2B2 | "Cm7"c2B2 B4 |
"F7"g4 f2e2 | "Bb"d4 c2B2 | "Bb"B4 z2 z2 | "Eb"e4 d4 |
"F7"c4 B2A2 | "Bb"f4 f2 z2 | "Gm"g2f2 e2d2 | "Cm7"c2B2 B4 |
"F7"A4 Gz FG | "Bb"F2D2 F4 | "F7"A2^G2 A2c2 | "Bb"B4- B4 |]



X:1
T:Forest Dusk
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=85
K:Gmaj
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G"B4 B2 | "Am"A4 z2 | "Bm"B4 d2 | "C"G4 z2 |
"Em"e4 e2 | "D"d2 c2 B2 | "C"c4 B2 | "G"G4 z2 |
"D"D4 E2 | "G"G4 z2 | "Em"B3 A G2 | "C"E4 z2 |
"Am"A4 A2 | "D"d2 c2 B2 | "G"B4 A2 | "G"G4 z2:|
V:2 clef=treble name="Oboe"
%%MIDI program 69
|:"G"B2G2 D2 | "Am"A4 z2 | "Bm"F3 E D2 | "C"E4 z2 |
"Em"G4 e2 | "D"F3 E D2 | "C"E4 D2 | "G"D4 z2 |
"D"A4 B2 | "G"c4 z2 | "Em"B2 A2 G2 | "C"E4 z2 |
"Am"A2 F2 D2 | "D"d4 E2 | "G" G3 A B2 | "G"G4 z2:|
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"G"D4 G,2 | "Am"A,4 z2 | "Bm"B,2 D2 G2 | "C"C4 z2 |
"Em"G,2 B,2 E2 | "D"D4 A,2 | "C"C4 G,2 | "G"G,4 z2 |
"D"A,4 D2 | "G"G4 z2 | "Em"G,2 B,2 e2 | "C"c4 z2 |
"Am"A,2 E2 A2 | "D"F2 A2 d2 | "G"B,2 D2 G2 | "G"G,4 z2:|



X:1
T:Tango for West Virginia
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=126
K:Am
V:1 clef=treble
%%MIDI program 41
|: "Am"e4 c2B2 | "E7"B4 A2^G2 | "Am"A2c2 e3f | "E7"e4 z2e2 |
"Am"A2c2 e2^G2 | "E7"B2A2 ^G2F2 | "Am"A2c2 "E7"B2^G2 |1 "Am"A4 z4 :|2 "Am"A4 z2c2 |
|: "C"c2e2 g4 | "G7"f2d2 d4 | "C"c2e2 g2a2 | "G7"g4 z2f2 |
"C"e2c2 G2E2 | "E7"^G2E2 "Am"A2c2 | "A7"c2e2 "Dm"f2d2 | "G7"g4 z4 :|
W:Distilled essence of song with tango rhythm and call-response
V:2 clef=treble
%%MIDI program 42
|: "Am"A,2C2 E2A,2 | "E7"B,2E2 ^G2B,2 | "Am"A,2C2 E3F | "E7"E2 E4 z2 |
"Am"A,2C2 E2^G2 | "E7"B,2A,2 ^G2F2 | "Am"A,2C2 "E7"B,2^G2 |1 "Am"A,2 A,4 z2 :|2 "Am"A,2 A,4 z2 |
|: "C"c2e2 e4 | "G7"d2B2 B4 | "C"c2e2 e4 | "G7"g2 d4 z2 |
"C"e2c2 G2E2 | "E7"^G2E2 "Am"A2c2 | "A7"c2e2 "Dm"f2d2 | "G7"g2 d4 z2 :|
W:Complementary tango harmony and rhythm
V:3 clef=bass
%%MIDI program 34
|: "Am"A,4 E,4 | "E7"E,4 B,,4 | "Am"A,4 C4 | "E7"E,4 z2 B,,2 |
"Am"A,4 E4 | "E7"B,,4 ^G,4 | "Am"A,4 "E7"B,4 |1 "Am"A,4 z4 :|2 "Am"A,4 z2 E,2 |
|: "C"C4 G,4 | "G7"B,4 D4 | "C"C4 G4 | "G7"G,4 z2 D2 |
"C"E,4 C,4 | "E7"^G,4 "Am"A,4 | "A7"E,4 "Dm"F4 | "G7"G,4 z4 :|
W:Driving tango bass line



X:1
T:Bandstand Frolic
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=150
K:G
% A lively and fun band tune with playful interaction between parts
V:1 clef=treble name="Saxophone" subname="Alto Sax."
%%MIDI program 65
|:"G"D2G2 B4 d4|"C"c2E2 G4 c4|"D7"F2A2 d4 F4|"G"G8- G4|
"D7"d2f2 a4 f4|"G"b2d2 g4 d4|"Em"e2^c2 e4 g4|"A7"a6 f2 e4|
"G"g3f e2d2 d4 B4|"C"C8- C2 E4 G4|"D7"DFA2 DFA2 DFAd|"G"B2G2 B2A2 G8:|
V:2 clef=treble name="Trombone" subname="Tbn."
%%MIDI program 57
|:"G"B,4 D4 G4|"C"E4 G4 c4|"D7"A,4 D4 F4|"G"G,8- G,8|
"D7"A4 d4 f4|"G"B,4 G4 B4|"Em"E4 ^C4 E4|"A7"A6 E2 F4|
"G"G,8- G,4 B,4|"C"C4 E4 G4|"D7"A4 d4 f2a2|"G"B,4 G,4 D4:|
V:3 clef=treble name="Trumpet" subname="Trpt."
%%MIDI program 56
|:"G"d4 B4 G4|"C"c4 G4 E4|"D7"f4 d4 F4|"G"D8- D8|
"D7"a4 f4 A4|"G"g4 d4 B4|"Em"e4 g4 ^C4|"A7"E4 A,4 F4|
"G"B4 G4 D4|"C"c4 G4 E4|"D7"f4 d4 A4|"G"d4 B4 G4:|
V:4 clef=bass name="Electric Bass" subname="Bass"
%%MIDI program 33
|:"G"G,8 D,8|"C"E,8 C8|"D7"A,8 F,8|"G"G,8- G,8|
"D7"D8 A,8|"G"G,8 D,8|"Em"E,8 ^C,8|"A7"A,8- A,8|
"G"G,1 z66|"C"C,8 C8|"D7"D,8 A,8|"G"G,8 D,8:|



X:1
T:Call to the Horizon
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=90
K:Am
%%MIDI program 41
V:1 clef=treble
|: "Am"A2 e c2 e | "G" d2 B G2 B | "F" c2 A F2 A | "E" B2 G E2 G |
"Am"A2 e c2 e | "G" d2 B G2 B | "F"c3 - c z A | "E" B3 - B2 z :|
"Am" e4 ^c d | "Dm" e3 - e2 f | "Am" a3 - a2 ^g | "F" a3 - a3 |
"Am" e4 ^c d | "Dm" e3 - e2 f | "Am" e2 ^g a2 f | "E" e2 z2 z z |
|: "Am" c2 A e2 ^c | "G" B2 G d2 B | "F" A2 F c2 A | "E" G2 E B2 G |
"Am" [A3 A3] [e3 e3] | "G" [d3 d3] [B3 B3] | "F" [c3 A3] [e3 F3] | "E" [G3 E3] [B3 z3] :|
V:2 clef=treble
%%MIDI program 42
z | "Am" A,2 C E2 A, | "G" G,2 D, G,2 D, | "F" F,2 A, C2 F, | "E" E,3 - E,2 z |
"Am" A,2 C E2 A, | "G" G,2 D, G,2 D, | "F" F,2 A, C2 F, | "E" E,3 - E2 z |
"A" c2 | "Dm" D2 F A2 z | "Am" A2 c e2 z | "F" F2 A c2 z | "Am" A,2 C E2 A, |
"Dm" D3 - D2 z | "Am" A,3 - A,2 z ||: "E" E,2 z2 z z |
|: "Am" A,2 E A,2 C | "G" G,2 E G,2 B, | "F" F,2 A, C2 F, | "E" E,2 G, B,2 E, |
"Am" [A,2E2] [C2E2] [A,2E2] | "G" [D,2G2] [B,,2D2] [G,2D2] | "F" [F,2A2] [C2A2] [F,2A2] :| "E" [E,2G2] [B,2E2] [E,2G2] :|
V:3 clef=bass
%%MIDI program 43
|: "Am"A,3 - A,2 z | "G"B,3 - B,2 z | "F"C3 - C2 z | "E"B,3 - B,2 z |
"Am"A,3 - A,2 z | "G"B,3 - B,2 z | "F"C3 - C2 z | "E"B,2 z2 z z :|
|: "Dm" F,3 - F,2 z | "Am"A,3 - A,2 z | "F"F,3 - F,2 z | "Am"A,3 - A,2 z |
"Dm"D,3 - D,2 z | "Am"A,2 - A,3 z | "E"E,2 z2 z z |
|: "Am"A,3 - A,2 C ||: "G"G,3 - G,2 D | "F"F,3 - F,2 A | "E"E,3 - E,2 G |
"Am"[A,3A,3] [C3E3] | "G"[G,3D,3] [B,,3D3] | "F"[F,3A,3] [F3A3] | "E"[E,2G2] [B,2E2] z2 :|



X:1
T:Yee Haw Wiggle
C:Lorn
M:12/8
L:1/8
Q:3/8=140
K:Bb
V:1 clef=treble
%%MIDI program 56
|: "Bb"G3 z2 G-G3 G2G | "Eb"F3 G3 "F7"B2G z3 | "Bb"z2 B B2G "Gm"B3 G2G | "Eb"F3 "F7"G3 "Bb"B2G z3 |
"Bb"G3 z2 G-G3 G2G | "Eb"F3 G3 "F7"B2G z3 | "Bb"z2 B B2G "Cm"B3 B2c | "F7"^c3 d3 "Bb"f2^f z3 :|
V:2 clef=bass
%%MIDI program 58
|: "Bb"G,,3 z2 D,,-D,,3 ^F,,3 | "Eb"G,,3 z2 D,,-D,,3 ^F,,3 | "Bb"G,,3 z2 D,,-D,,3 ^F,,3 | "Eb"G,,3 z2 D,,-D,,3 ^F,,3 |
"Bb"G,,3 z2 D,,-D,,3 ^F,,3 | "Eb"G,,3 z2 D,,-D,,3 ^F,,3 | "Cm"D,3 z2 A,,-A,,3 ^C,3 | "F7"G,,3 z2 D,,-D,,3 ^F,,3 :|
V:3 clef=treble
%%MIDI program 105
|: "Bb"z3 D2z B,2D2z z | "Eb"z3 ^C2z E2^C2z z | "Bb"z3 D2z B,2D2z z | "Eb"F2A2D2 F2A2B2 |
"Bb"z3 D2z B,2D2z z | "Eb"z3 ^C2z E2^C2z z | "Bb"z3 D2z A,2D2z z | "F7"G2^F2D2 z6 :|



X:1
T:Starry Night Serenade
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=60
K:Amaj
V:1
%% MIDI program 91
|: "A"A6 E2 | "F#m"F4 E4 | "D" D6 F2 | "E"E4 z4 |
"A"A3 B c4 | "E"B6 G2 | "A"A3 F E4 | "D"D4 F4 |
"A"A6 E2 | "Bm7"E4 D4 | "E7sus4"G6 A2 | "A"A4 z4 :|
V:2
%% MIDI program 91
|: "A"C4 E4 | "F#m"A4 G4 | "D"F4 A4 | "E"E4 z4 |
"A"A4 c2 B2 | "E"G4 E4 | "A"A4 F4 | "D"E4 F4 |
"A"C6 E2 | "Bm7"F4 D4 | "E7sus4"G4 A4 | "A"A4 z4 :|
V:3
%% MIDI program 91
|: "A"A,4 E3 C | "F#m"F,4 A4 | "D"D,4 F4 | "E"E,4 z4 |
"A"A,4 C4 | "E"E4 G3 E | "A"A,4 E3 F | "D"D4 F4 |
"A"A,4 E4 | "Bm7"B,4 D4 | "E7sus4"E4 G4 | "A"A,4 z4 :|
V:4
%% MIDI program 91
|: "A"E2 A3 z2 z | "F#m"C4 A4 | "D"A2 D4 F2 | "E"E4 z4 |
"A"E4 A3 B | "E"C4 F3 G | "A"A3 E2 F2 z | "D"F4 z4 |
"A"E6 A2 | "Bm7"D4 B4 | "E7sus4"G3 F E4 | "A"A4 z4 :|



X:1
T:AI Ingenuity
C:OrchestrAI
M:7/8
L:1/16
Q:3/8=100
K:Cmaj
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|: c4 e4 g4 z4 | d4 f4 a4 z4 | b4 e4 g8 | a8 f9  |
e8 c4 e4 | c8 B9  | G2 F2 E2 D2 C2 B,2 A,2 z2 | G,4 A,4 B,4 z4 :|
V:2 clef=treble name="Electric Piano"
%%MIDI program 4
|: E4 G4 c4 z4 | F4 A4 d4 z4 | G4 B4 e8 | f8 c9  |
g8 e4 g4 | e8 d9  | B2 A2 G2 F2 E2 D2 C2 z2 | G,4 A,4 B,4 z4 :|
V:3 clef=bass name="Bass Synth"
%%MIDI program 38
|: C8 E8 | D8 F8 | E8 G8 | A,8 C8 |
B,8 A,8 | G8 F8 | E4 D4 C8 | B,8 A,8 :|
V:4 clef=treble name="Percussive Organ"
%%MIDI program 17
|: g8 e9  | f8 d9  | e8 c9  | b8 g9  |
g8 e9  | a8 g9  | e2 c2 d2 e2 f2 g2 a2 z2 | b4 a4 g4 z4 :|



X:1
T:Celestial Waltz
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=72
K:Emaj
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|:"Em" B2 B2 z2 | "C#m" G2 F#2 G2 | "Amaj7" A2 A2 c2 | "B7" B2 B2 z2 |
"Em" e4 z2 | "G#m" g2 g2 f2 | "F#m7" F2 A2 G2 | "B7" B3 z3 |
"Em" B2 B2 z2 | "C#m" G2 F#2 G2 | "Amaj7" A2 A2 c2 | "B7" B2 B2 z2 |
"Em" e4 z2 | "G#m" g2 g2 f2 | "F#m7" F2 A2 G2 | "B7" B3 z3 :|
V:2 clef=treble name="Synth Pad"
%%MIDI program 92
|:"Em" E2 z2 E2 | "C#m" E2 z2 G2 | "Amaj7" C2 E2 A,2 | "B7" F2 z2 B,2 |
"Em" E2 G2 B,2 | "G#m" E2 z2 G2 | "F#m7" A2 z2 A2 | "B7" E2 F2 B,2 |
"Em" E2 z2 E2 | "C#m" E2 z2 G2 | "Amaj7" C2 E2 A,2 | "B7" F2 z2 B,2 |
"Em" E2 G2 B,2 | "G#m" E2 z2 G2 | "F#m7" A2 z2 A2 | "B7" E2 F2 B,2 :|
V:3 clef=bass name="Synth Bass"
%%MIDI program 87
|:"Em" E,4 E,2 | "C#m" C,4 C,2 | "Amaj7" A,4 A,2 | "B7" B,4 F,2 |
"Em" E,2 G,2 B,2 | "G#m" B,2 E,2 G,2 | "F#m7" F,4 A,2 | "B7" B,4 B,2 |
"Em" E,4 E,2 | "C#m" C,4 C,2 | "Amaj7" A,4 A,2 | "B7" B,4 F,2 |
"Em" E,2 G,2 B,2 | "G#m" B,2 E,2 G,2 | "F#m7" F,4 A,2 | "B7" B,4 B,2 :|



X:1
T:The Quest Begins
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=120
K:Cmaj
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "C"E2 | "G"D "Em"E3/2E/2 "Am"E2 | "F"F "G" G3/2F/2 "Am"A2 | "Dm"G "G"A3/2A/2 "C"A3/2G/2 | "F"F6 |
"C"E "Dm"F "Em"G "Am"A | "Dm"B "Em"c "A7"d "Dm"c | "G"B "Am"A "F"G "Em"F |1 "G"G6 :|2 "G"G4 ||
|: [A,2E2]| "C"C "F"A3/2G/2 "F"F2 | "G"G "Em"F3/2E/2 "Am"D2 | "F"C "C/E"D3/2E/2 "F"F3/2E/2 | "G"D3/2E/2 "C"D3/2C/2 "G"B,2 |
"C"C "F"A3/2G/2 "Am"A2 | "D7"G "G"F3/2E/2 "C"D2 | "F"c "C"A "G7"B "C"c |1 "C"c4 :|2 "C"c6 |]
V:2 clef=treble name="Oboe"
%%MIDI program 68
|: "C"G,2 | "G"B, "Em"C3/2C/2 "Am"E2 | "F"D "G" C3/2D/2 "Am"F2 | "Dm"C "G"B3/2B/2 "C"G3/2F/2 | "F"E6 |
"C"G, "Dm"A, "Em"B, "Am"C | "Dm"D "Em"E "A7"F "Dm"E | "G"D "Am"C "F"B, "Em"A, |1 "G"G,6 :|2 "G"G,4 ||
|: [G,2C2]| "C"E, "F"C3/2B,/2 "F"A,2 | "G"B, "Em"A,3/2G,/2 "Am"F,2 | "F"E, "C/E"B,,3/2C/2 "F"D3/2C/2 | "G"G,3/2F/2 "C"E,3/2D,/2 "G"C,2 |
"C"E, "F"C3/2B,/2 "Am"A,2 | "D7"G, "G"F,3/2E,/2 "C"D,2 | "F"c "C"A, "G7"B, "C"C |1 "C"c4 :|2 "C"c6 |]
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "C"C,2 | "G"B,, "Em"C2 "Am"C,2 | "F"A,, "G"B,, "Am"A,,2 | "Dm"G,, "G"B,, "C"C,3/2B,,/2 | "F"A,,6 |
"C"E,,2 "Dm"C,2 "Em"B,,2 "Am"A,,2 | "Dm"D, "Em"E, "A7"F, "Dm"E, | "G"B,, "Am"A,, "F"G,, "Em"F,, |1 "G"C,6 :|2 "G"C,4 ||
|: [C,2E2]| "C"G,, "F"A,, "F"A,,2 | "G"B,, "Em"A,, "Am"G,,2 | "F"F, "C/E"C, "F"E,, "G"G,, | "G"F,,3/2E,/2 "C"D,,2 "G"B,,,2 |
"C"G,, "F"A,, "Am"A,,2 | "D7"F, "G"E, "C"D,,2 | "F"F, "C"E, "G7"D, "C"C, |1 "C"C,4 :|2 "C"C,6 |]



X:1
T:This Week In Google - Podcast Intro
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=130
K:Cmaj
V:1 clef=treble
%%MIDI program 81
|:"C"C2E2 G4|"Am"A4 E4|"F"F2A2 c4|"G"G4 E4|
"C"E2G2 c4|"G"B,4 D4|"F"A4 c3E|"G"G6 z2:|
V:2 clef=treble
%%MIDI program 81
|:"C"c4 e3g|"Am"a2e2 c4|"F"f2a2 c'4|"G"g4 e4|
"C"c4 e2g2|"G"d4 B3G|"F"c2f2 a3g|"G"g6 z2:|
V:3 clef=bass
%%MIDI program 34
|:"C"C,6 G,2|"Am"A,6 E,2|"F"F,6 A,2|"G"G,6 D,2|
"C"C,4 E,4|"G"B,,6 D,2|"F"A,4 C4|"G"G,8:|



X:1
T:Test - Continued
Z:Arr.  Joel Breit
Z:Polk the Bear - Continued by OrchestrAI
L:1/8
Q:1/2=80
M:4/4
K:Gm
V:1 clef=treble name="Harmonica" subname="Harm."
%%MIDI program 22
"D7" d2 AB cd/c/ BA |"Gm" G2 GB d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
$"Cm" z c2 e g2 fe | "Bb" z d2 B d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
$"Gm" d4 B4 |"D7" c4 A4 |"Gm" B4 G4 |"D7" ^F4 A4 |
$"Gm" d4 B4 |"D7" c4 A4 |"Bb" B2 d2 g4 |"D7" ^f8 :|
"D7" ^f2 ed cB/A/ GF | "Cm" G2 A2 B2 d2 | "D7" c3 d e2 ^f2 | "Gm" g2 B2 B2 z2 |
"Adim" z a2 f a2 e^f | "Ebmaj" z g2 B g3 f | "D7" e3 a g2 f2 | "Gm" a2 g2 g4 |
"D7" ^f2 ed cB/A/ GF | "Cm" G2 A2 B2 d2 | "D7" c3 d e2 ^f2 | "Gm" d3 d B2 z2 |
"Cm" g2 ag b2 ag | "Eb" g2 fe "D7" ^f4 | "Gm" g3 g d2 B2 | "D7" ^f8 :|
V:2 clef=bass name="Tuba" subname="Tba."
%%MIDI program 58
"D7" z2 A,2 "Gm"D,4 | "Gm" G,4 z2 G,2 | "D7" A,6 F,2 | "Gm" G,4 z4 |
"Cm" E,4 G,2 z2 | "Bb" D,4 "D7"A,4 | "Gm" G,4 z2 G,2 | "D7" A,8 |
"Gm" d4 B,4 | "D7" c4 A,4 | "Gm" B,4 G,4 | "D7" ^F4 A,4 |
"Gm" d2 d2 B,4 | "D7" c2 c2 A,4 | "Bb" B,4 "D7"F2 ^F2 | "Gm" G,8 :|
"D7" A,4 D,4 | "Cm" G,2 A,2 D2 G,2 | "D7" A,2 B,2 E2 A,2 | "Gm" D,4 G,4 |
"Adim" D,2 E,2 A,3 G, | "Ebmaj" B,,2 C,2 F2 B,,2 | "D7" A,2 F,2 B,,3 A, | "Gm" G,4 D,4 |
"D7" A,4 D,4 | "Cm"G,2 A,2 D2 G,2 | "D7"A,2 B,2 E2 A,2 | "Gm"D,4 D,2 B,2 |
"Cm"G,2 A,2 B,2 C2 | "Eb"D,2 E,2 F2 ^F,2 | "Gm"G,4 D4 | "D7"A,8 :|



X:1
T:Celestial Wonders
C:OrchestrAI
M:4/4
L:1/8
Q:1/2=80
K:Gm
V:1 clef=treble name="Trumpet"
%%MIDI program 56
"D7" d2 AB cd/c/ BA |"Gm" G2 GB d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
"Em7b5" e2 c2 e2 fg | "A7" a2 f2 a2 df |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
"Gm" d4 B4 |"D7" c4 A4 |"Gm" B4 G4 |"D7" ^F4 A4 |
"Gm" d4 B4 |"D7" c4 A4 |"Bb" B2 d2 g4 |"D7" ^f8 :|
V:2 clef=bass name="Trombone"
%%MIDI program 57
"D7" F2 A,2 D2 C2 |"Gm" G,2 C2 F2 E2 |"D7" A,,2 C2 E2 D2 |"Gm" G,2 B,2 G,2 z2 |
"Em7b5" e,2 E2 g2 f2 | "A7" e2 c2 a2 f2 |"D7" A,,2 C2 E2 D2 |"Gm" G,2 B,2 G,2 z2 |
"Gm" G,2 B,2 G,4 |"D7" A,2 ^F,2 E,4 |"Gm" D2 B,2 G,4 |"D7" ^F,2 A,2 A,4 |
"Gm" G,2 B,2 G,4 |"D7" A,2 ^F,2 A,4 |"Bb" ^F2 D2 B,4 |"D7" ^F,8 :|



X:1
T:Epic Triumvirate
C:OrchestrAI
M:5/4
L:1/8
Q:1/4=60
K:Am
V:1 clef=treble
%%MIDI program 48
|: "E7#11/D"D2 ^G2 A2 c2 d2 z2 | e6 z2 A2 ^G2 | "Am" A,4 E2 A2 C2 E2 | "Fmaj7#11"F2 A2 c2 e2 g2 z2 |
"E7#11/D"^G,2 B,2 e2 g2 b2 z2 | "Dm"[d6f6] z2 "G7"c2 B2 | "Cmaj9"B,2 E2 G2 B2 D2 z2 | "G#dim/G"G,2 B,2 ^D2 E2 ^G2 z2 |
"Fmaj7#11/A"A,3 C 3 E3 G3 | "E7#11"d3 ^f3 a3 c'3 |"Am"[e4a4] z4 "G7"[d'4g4] :|
V:2 clef=bass
%%MIDI program 42
|: "E7#11/D"[D,2A,2] z4 [D,2^G,2] z4 | [E,2A,2C2] z6 [A,2^G,2] z2 | "Am"[A,,2E,2A,2] z2 [A,,2C2E2] z2 z4 | "Fmaj7#11"[F,2A,2C2] z6 [A,2^G,2] z2 |
"E7#11/D"[^G,,2B,,2E,2] z2 [B,,2e2g2] z2 | "Dm"[D,2F,2] z6 [C2D,2F2] z2 | "Cmaj9"[B,,2E,2G,2] z6 [G,2B,2D2] z2 | "G#dim/G"[G,,2B,,2] z6 [^D,2E,2^]  |
"Fmaj7#11/A"[A,,2C2A,2] z2 [E,2G,2C2] z2 z4 | "E7#11"[^F,2A,2d2] z6 [^]  | "Am"[E,2A,2e2] z2 "G7"[D2^F,2d2] z6 :|



X:1
T:Meditations in the Garden
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=60
K:D
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"D" A3 F G2 | "Em7" E4 z E | "Gmaj7" G3 B d2 | "Asus4" A4 z A | "D" d3 c B2 | "Bm7" B4 z B | "Em7" F2 G3 E | "A7sus4" A6 |
"Gmaj7" B3 A G2 | "F#m7" F4 z F | "Em7" e3 f d2 | "A7sus4" A4 z A | "D" d3 A F2 | "Gmaj7" G4 z G | "A7sus4" A3 F G2 | "D" D6 :|
V:2 clef=bass name="Harp"
%%MIDI program 46
|:"D"D6 | "Em7" [E,G,]4 E2 z3 | "Gmaj7" [D,G,]4 D2 z3 | "Asus4" [C,A,]4 C2 z3 | "D" [F,A,D]4 F2 z3 | "Bm7" [F,B,,]4 F2 z3 | "Em7" [E,G,]4 E2 z3 | "A7sus4" [C,A,]4 C2 z3 |
"Gmaj7" [D,G,]6 | "F#m7" [F,A,C]4 F2 z3 | "Em7" [E,G,]4 E2 z3 | "A7sus4" [C,A,]4 C2 z3 | "D" [D,F,A,]4 D2 z3 | "Gmaj7" [D,G,]4 D2 z3 | "A7sus4" [C,A,]4 C2 z3 | "D" D,6 :|



X:1
T:Band Blast Bonanza
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=144
K:C
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|: "C" c2G2 E2C2 | "F" A,2C2 F2A2 | "G7" B,2D2 G2B2 | "C" c2e2 g2e2 |
| "Am" A2c2 e2c2 | "Dm" d2f2 a2f2 | "G7" (3gfe (3dcB (3AGF (3EDC | "C" C4 z4 :|
|: "F" f2c2 A2F2 | "Bb" _B2d2 f2d2 | "C7" e2c2 G2E2 | "F" F4 A,4 |
| "Dm" d2A2 F2D2 | "G7" G,2B,2 D2F2 | (3GAB (3cde (3fga (3bc'd' | "C" c'4 z4 :|
V:2 clef=treble name="Alto Sax"
%%MIDI program 65
|: "C" E2C2 G,2E2 | "F" F2A,2 C2F2 | "G7" D2G,2 B,2D2 | "C" E2G2 c2G2 |
| "Am" C2E2 A2E2 | "Dm" F2A2 d2A2 | "G7" (3BAG (3FED (3CB,A, (3G,F,E, | "C" C4 z4 :|
|: "F" A2F2 C2A,2 | "Bb" D2F2 _B2F2 | "C7" G2E2 C2G,2 | "F" F4 C4 |
| "Dm" F2D2 A,2F,2 | "G7" B,2D2 G,2B,2 | (3B,CD (3EFG (3AB,c (3def | "C" e4 z4 :|
V:3 clef=bass name="Trombone"
%%MIDI program 57
|: "C" C,2E,2 G,2C2 | "F" F,2A,2 C2F2 | "G7" G,2B,2 D2G2 | "C" C,2E,2 G,2C2 |
| "Am" A,,2C,2 E,2A,2 | "Dm" D,2F,2 A,2D2 | "G7" G,,2B,,2 D,2F,2 | "C" C,4 z4 :|
|: "F" F,,2A,,2 C,2F,2 | "Bb" B,,2D,2 F,2B,2 | "C7" C,2E,2 G,2C2 | "F" F,,4 A,,4 |
| "Dm" D,2F,2 A,2D2 | "G7" G,,2B,,2 D,2G,2 | B,,2D,2 F,2A,2 | "C" C,4 z4 :|
V:4 clef=bass name="Tuba"
%%MIDI program 58
|: "C" C,4 C,4 | "F" F,,4 F,,4 | "G7" G,,4 G,,4 | "C" C,4 C,4 |
| "Am" A,,4 A,,4 | "Dm" D,4 D,4 | "G7" G,,2 [G,,B,,D,]2 [G,,B,,D,F,]4 | "C" C,4 z4 :|
|: "F" F,,4 F,,4 | "Bb" B,,4 B,,4 | "C7" C,4 C,4 | "F" F,,4 F,,4 |
| "Dm" D,4 D,4 | "G7" G,,4 G,,4 | G,,2 [G,,B,,D,]2 [G,,B,,D,F,]4 | "C" C,4 z4 :|



X:1
T:Cyberpunk Chase Sequence
C:OrchestrAI
M:7/8
L:1/16
Q:1/4=140
K:Cmin
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"Cm"c4 e2 g2 f2 e2 d2|c4 B2 d2 f2 g2 a2|"G"b4 g2 f2 e2 d2 c2|G8- G4 z2|
"Fm" f4 ab ag af ge gd|f4 e2 c2 f2 g2 a2|"C"b4 c'2 b2 a2 g2 f2|G8- G4 z2|
"Cm"e4 g2 c2 e2 f2 g2|"Ab"g4 e2 c2 B2 d2 c2|"Bb"d4 c2 B2 c2 d2 e2|"G7"b4 a2 g2 f2e2 d2|
"Fm"f2 g2 a2 b2 a2 g2 f2|e2 c2 d2 c2 B2 A2 G2|"Cm"G4 A2 B2 G2 F2 E2|D8- D4 z2:|
V:2 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 38
|:"Cm"C,8- C,4 D,2|E,8- E,4 F,2|"G"G,8- G,4 A,2|B,8- B,4 C2|
"Fm"F,8- F,4 G,2|A,8- A,4 B,2|"C"C8- C,4 D,2|E,8- E,4 F2|
"Cm"C,8- C,4 D,2|E,8- E,4 F,2|"Ab"A,8- A,4 B,2|"Bb"B,8- B,4 G,2|
"G7"G,8- G,4 A,2|B,8- B,4 C2|"Fm"F,8- F,4 G,2|A,8- A,4 B2|
"Cm"C8- C,4 D,2|E,8- E,4 F8- F,4|"Cm"G,16- G,8 z8:|



X:1
T:Manifold Derby March
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Gmaj
V:1 clef=treble name="Brass" subname="Horns"
%%MIDI program 61
|:"G"D4 G4|"C"E2 D2 C4|"D"A6 F2|"G"G8|
"G"B2 A2 G2 F2|"C"E4 D4|"D"A2 B2 A4|"G"G8|
"D"F4 A4|"G"B2 d2 G4|"Am"E6 c2|"D"d8|
"G"B4 d2 B2|"C"c6 A2|"G"B2 G2 A4|"D"D8:|
V:2 clef=treble name="Strings" subname="Violins"
%%MIDI program 40
|:"G"d4 g4|"C"e2 d2 c4|"D"f6 d2|"G"g8|
"G"b2 a2 g2 f2|"C"e4 d4|"D"f2 g2 f4|"G"g8|
"D"d4 f4|"G"b2 d2 g4|"Am"e6 a2|"D"d8|
"G"b4 d2 b2|"C"c6 e2|"G"d2 B2 c4|"D"d8:|
V:3 clef=bass name="Bass" subname="Cellos"
%%MIDI program 42
|:"G"G,4 D4|"C"C4 A,4|"D"D4 F4|"G"G,8|
"G"G,2 A2 B2 C2|"C"C4 E4|"D"D4 D4|"G"G,8|
"D"A,4 D4|"G"G,2 B2 G4|"Am"C6 E2|"D"D8|
"G"B,4 D2 G2|"C"E6 C2|"G"G,2 D2 G4|"D"D8:|
V:4 clef=bass name="Percussion" subname="Timpani"
%%MIDI program 47
|:"G"G,8|"C"C8|"D"D8|"G"G,8|
"G"G,8|"C"C8|"D"D,8|"G"G,8|
"D"D,8|"G"G,8|"Am"A,8|"D"D8|
"G"G,8|"C"C8|"G"G,8|"D"D,8:|



X:1
T:Rhapsody in the Realm of Twilight
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=96
K:Amaj
V:1 clef=treble name="String Ensemble"
%%MIDI program 48
|: "A"E2 | "C#m"G3A B2 | "D"A3G F2 | "A/E"E3D C2 | "Bm"D3C B,2 | "A/C#"C3D E2 | "F#m"A3B c2 | "D"E3F G2 | "E"A,3^G A2 :|
|: "E"A3B c2 | "A"E3D C2 | "F#m"C3D E2 | "D"A,3B, C2 |"A"B,3C D2 | "E"G3A B2 | "A"E3F G2 | "D"F4 E2 :|
V:2 clef=treble name="Woodwinds"
%%MIDI program 71
|: "A"c2 | "C#m"e3f g2 | "D"f3e d2 | "A/E"c3B A2 | "Bm"b3a g2 | "A/C#"f3g a2 | "F#m"d3e f2 | "D"a3g f2 | "E"c'3b a2 :|
|: "E"g2 | "A"c'3b a2 | "F#m"f3e d2 | "D"b3a g2 | "A"e3f g2 | "E"a3g f2 | "A"e3d c2 | "D"d4 c2 :|
V:3 clef=treble name="Human Voices"
%%MIDI program 52
|: "A"A,2 | "C#m"C3D E2 | "D"F3E D2 | "A/E"A,3B, C2 | "Bm"D3C B,2 | "A/C#"E3F G2 | "F#m"A3B A2 | "D"F3G F2 | "E"A,4 z2 :|
|: A,2 | "E"C3D E2 | "A"A,3B, C2 | "F#m"E3F G2 | "D"A3B A2 | "A"E3F G2 | "E"C3B, A,2 | "A"A,3^G, A,2 :| "D"D4 z2 :|
V:4 clef=bass name="Harp"
%%MIDI program 46
|: "A"E,2 | "C#m"A,3B, C2 | "D"D3C B,2 | "A/E"E,2^G,2 A,2 | "Bm"F3E D2 | "A/C#"C2B,2 C2 | "F#m"A,2 E2 F2 | "D"D3C B,2 | "E"E,4 z2 :|
|: E,2 | "E"A,3B, C2 | "A"E,2^G,2 A,2 | "F#m"F2 E2 F2 | "D"A,2 E2 D2 | "A"C2 B,2 C2 | "E"F3G F2 | "A"A,3^G A,2 :| "D"D4 z2 :|



X:1
T:Transcendental Battle Overture
C:OrchestrAI
M:C
L:1/16
Q:1/4=60
K:C
V:1 treble
%%MIDI program 48
% Voice 1 – Representing the ideological force of the Huserrlian Humanists
|:G,16 | E3E G3G B3B ^d3^d| E6 z4 E6 z4| D,16 | F3F A3A c3c e3e| D6 z4 D6 z4:|
V:2 treble
%%MIDI program 49
% Voice 2 – Embodying the relentless pursuit of the Neo Nietzscheans
|:A,8 E8 | C8 F8 | A,8 E8| C8 F8 | A,16 | C8 F8 | A,16 | C8 F8:|
V:3 alto
%%MIDI program 50
% Voice 3 – Symbolizing the mechanical precision of the Butlerian Bogostroiteli
|:.G,16 | G,6 .B,6 G,4| .E,16 | E,6 .A,6 E,4 | .D,16 | D,6 .G,6 D,4| .A,16 | A,6 .D,6 A,4:|
V:4 bass
%%MIDI program 43
% Voice 4 – The underpinning war drums that herald the coming of the transcendental transformation
|:D,16 | D,12 G,4 | C,16 | C,12 F,4 | B,,16 | B,,12 E,4 | G,,16 | G,,12 C,4:|
% Following sections would involve further development, modulation, and thematic material representing the battle and ideologies.



X:1
T:Nocturnal Enchantment
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:Am
% Voice assignments
V:1 name="Lead" clef=treble
%%MIDI program 74
V:2 name="Harmony" clef=treble
%%MIDI program 73
V:3 name="Counter-Melody" clef=treble
%%MIDI program 72
V:4 name="Bass" clef=bass
%%MIDI program 70
% Lead voice with melody
[V:1]
|: "Am"C2 E2 A2 c2 | "G"B4 A2 G2 | "F"E2 C2 E2 A2 | "E"G6 z2 |
"Am"A4 c2 B2 | "G"E2 D2 G2 B2 | "F"A2 c2 E2 G2 | "E"A6 z2 |
"Am"C2 E2 A2 e2 | "C"G4 E2 C2 | "F"E2 A2 c2 f2 | "E"G6 z2 |
"Dm"F2 A2 d2 f2 | "Am"E2 C2 A2 G2 | "G"B2 G2 E2 D2 | "Am"C8 :|
% Harmony voice with chords and accompanying melody
[V:2]
|: "Am"A2^G2 A2E2 | "G"G2F2 G2D2 | "F"C2F2 A2c2 | "E"E2^D2 E4 |
"Am"A,2B,2 C2A,2 | "G"G,2B,2 D2G,2 | "F"F2A2 c2f2 | "E"E2B,2 E4 |
"Am"A,2C2 E2A,2 | "C"C2E2 G2c2 | "F"F3 A c2e2 | "E"E3 ^D E4 |
"Dm"D2F2 A2d2 | "Am"A,2C2 E2A,2 | "G"G2B,2 D2G,2 | "Am"A4 z4 :|
% Counter-Melody voice with opposing motion and counter-rhythms
[V:3]
|: "Am"c4 c2B2 | "G"d4 B2G2 | "F"c2A2 c2e2 | "E"d4 z2D2 |
"Am"c2E2 A2c2 | "G"B2D2 G3A | "F"A2c2 e3f | "E"G4 z2E2 |
"Am"A2 d2 c2B2 | "C"c2G2 A4 | "F"f2 e2 d2c2 | "E"G4 z2E2 |
"Dm"f2 e2 d2c2 | "Am"A4 c3B | "G"B2 A2 G4 | "Am"A4 z4 :|
% Bass voice with chord root notes and rhythmic foundation
[V:4]
|: "Am"A,2 C2 E2 A,2 | "G"G,2 B,2 D2 G,2 | "F"F,2 A,2 C2 F,2 | "E"E,2 G,2 B,2 E,2 |
"Am"A,2 C2 E2 A,2 | "G"G,2 B,2 D2 G,2 | "F"F,2 A,2 C2 F,2 | "E"E,2 G,2 B,2 E,2 |
"Am"A,2 C2 E2 A,2 | "C"C,2 E2 G2 C,2 | "F"F,2 A,2 C2 F,2 | "E"E,2 G,2 B,2 E,2 |
"Dm"D,2 F,2 A,2 D,2 | "Am"A,2 C,2 E,2 A,2 | "G"G,2 B,2 D,2 G,2 | "Am"A,4 z4 :|



X:1
T:Gridiron Glory
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=144
K:Cmaj
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|: "C"G4 E2G2 | c4 G4 | "F"d4 c2d2 | e6 z2 |
"C"G4 E2C2 | D4 z4 | "G"E4 D2G2 | "C"C6 z2 |
"F"A3G F3E | "C"G4 z2G2 | "G"e3d B3G | "C"G6 z2 :|
V:2 clef=treble name="Horns"
%%MIDI program 61
|: "C"c2c2 G2E2 | C2E2 G4 | "F"A2F2 A2c2 | f4 z4 |
"C"E2G2 c2E2 | G4 z2E2 | "G"C4 B,2C2 | "F"F6 z2 |
"C"C2E2 G2c2 | "G"G4 z2G2 | "C"E4 E2G2 | C6 z2 :|
V:3 clef=treble name="Trombone"
%%MIDI program 58
|: "C"C2C2 C2G,2 | E2G2 C4 | "F"F2F2 F2A,2 | c4 z4 |
"C"G,2C2 G,2E2 | C4 z2G,2 | "G"B,2E2 B,2G2 | "C"C6 z2 |
"F"F2A,2 F3G | "C"C4 z2C2 | "G"B2d2 B2G2 | "C"C6 z2 :|
V:4 clef=bass name="Tuba"
%%MIDI program 58
|: "C"C4, C,2E,2 | G,2C2 C4 | "F"F4, F,2A,2 | c2F2 F4 |
"C" C2G,,2 C2E,2 | G,4 z2C2 | "G"B,4 B,2D2 | "C"C6 z2 |
"F"F4, F,2A,2 | "C"C4 z2C2 | "G"G,4 G,2B,2 | "C"C6 z2 :|



X:1
T:Festival of Grooves
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Bb
V:1 clef=treble name="Electric Guitar" subname="Gtr."
%%MIDI program 30
|: "Bb"D4 F3 G | "Eb" G8 | "F" A4 z2 c2 | "Bb"B6 z2 |
"Gm"D4 F3 G | "Eb"G4 E2 G2 | "F"F8 | "Bb"B,8 :|
"Eb"F4 G2 A2 | "Bb"G8 | "Cm"G4 F2 E2 | "F"F8 |
"Bb"D4 F3 G | "Gm" A8 | "Eb"G4 G2 E2 | "Bb"B,8 |
V:2 clef=treble name="Keyboard" subname="Keys"
%%MIDI program 5
|: "Bb"B2 F4 B2 | "Eb"G2 B2 G4 | "F"A2 c4 e2 | "Bb"d2 B2 z4 |
"Gm"B4 - B2 d2 | "Eb"e2 G2 E2 G2 | "F"c8 | "Bb"Bb,7 :|
"Eb"E4 G2 F2 | "Bb"D2 F2 D4 | "Cm"C2 E2 C2 G,2 | "F"A,4 C4 |
"Bb"B,2 D4 F2 | "Gm"B2 d4 f2 | "Eb"E2 G2 E2 G2 | "Bb"B,8 |
V:3 clef=treble name="Alto Sax" subname="Sax."
%%MIDI program 65
|: "Bb"F4 d3 e | "Eb"c8 | "F"c4 z2 f2 | "Bb"d6 z2 |
"Gm" g4 - g2 b2 | "Eb"c4 A2 c2 | "F"f8 | "Bb" B,8 :|
"Eb"G4 A2 B2 | "Bb"F8 | "Cm"E4 D2 C2 | "F"C8 |
"Bb"B4 d3 e | "Gm" c8 | "Eb"E4 c2 A2 | "Bb"B,8 |
V:4 clef=bass name="Bass Guitar" subname="Bass"
%%MIDI program 34
|: "Bb"B,8 | "Eb" G,8 | "F" C8 | "Bb"B,,8 |
"Gm" B,8 | "Eb" G,8 | "F" C8 | "Bb"B,,8 :|
"Eb" G,8 | "Bb" F8 | "Cm" G,8 | "F" C8 |
"Bb" B,8 | "Gm" B,8 | "Eb" G,8 | "Bb" B,,8 |



X:1
T:Yearning Pop Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:C
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|: "C"e4 G4 | "Am" A4 ^G4 | "F" f4 e4 | "G" d6 c2 |
"C"e4 e3 d | "Am" c4 _B4 | "F" a4 g4 | "G" g6 e2 |
"C"e4 G4 | "Am" A4 ^G4 | "F" f4 e4 | "G" d6 c2 |
"C"e2 c2 e2 G2 | "F" g4 e4 | "G" d4 G4 | "C" c8 :|
V:2 clef=bass name="Trombone"
%%MIDI program 57
|: "C"c2 E2 G2 E2 | "Am" A,2 C2 E2 C2 | "F" F,2 A2 c2 A2 | "G" G,3 E_ D2 z2 |
"C"C2 E2 G2 E2 | "Am" A,2 C2 E2 C2 | "F" F,2 A2 c2 A2 | "G" G,3 _B, A,2 z2 |
"C"c2 E2 G2 E2 | "Am" A,2 C2 E2 C2 | "F" F,2 A2 c2 A2 | "G" G,3 E_ D2 z2 |
"C"C2 E2 G2 C2 | "F" F,2 A2 c2 F2 | "G" G,4 E4 | "C" C8 :|
V:3 clef=bass-8 name="Tuba"
%%MIDI program 58
|: "C"C,4 G,4 | "Am" A,4 E4 | "F" F,2 F,2 _B,,2 C2 | "G" D4 _B,,4 |
"C"C,4 G,4 | "Am" A,4 E4 | "F" F,2 F,2 _A,,2 _B,,2 | "G" G,6 _E,2 |
"C"C,4 G,4 | "Am" A,4 E4 | "F" F,2 F,2 _B,,2 C2 | "G" D4 _B,,4 |
"C"C,2 C,2 E,4 | "F" F,6 _A,,2 | "G" _B,,4 D4 | "C" C,8 :|



X:1
T:Celestial Ballet
C:OrchestrAI
M:5/4
L:1/8
Q:1/4=100
K:Emaj
V:1 clef=treble name="Synth Pad 1" subname="Stars"
%%MIDI program 91
|:"Emaj9"B,4 E4 G4 F4 | "Amaj7"A,4 C4 E4 ^D4 | "C#m11"C,4 F,2 A,4 B,2 z4 | "Badd9"B,4 ^D4 G4 A2 F2 |
"Emaj9"B,6 G,8 z2 | "Amaj7"A,6 E4 C4 z2 | "F#m11"F,4 A,4 C4 E2 ^D2 | "G#m7"G,4 B,4 ^D8 |
"Bmaj7+B9"B,2F,2 A,2^D2 F4 G4 | "C#m11"C,4 E4 G4 B4 A  | "Amaj7"A,6 E6 z4 | "Emaj9"E,8 B,8 :|
V:2 clef=treble name="Strings" subname="Galaxies"
%%MIDI program 48
|:"Emaj9"B3 G3 F3 E3 z4 | "Amaj7"A3 ^D3 E3 C3 z4 | "C#m11"C3 B,3 A,3 G,3 z4 | "Badd9"B3 A3 G3 F3 E3 z |
"Emaj9"B3 B,3 E3 G3 F3 z | "Amaj7"A3 A,3 C3 E3 ^D3 z | "F#m11"F3 F,3 A,3 C3 E3 z | "G#m7"G3 G,3 B,3 ^D3 z4 |
"Bmaj7+B9"B3 F,3 A,2^D2 F2 G2 A2 | "C#m11"C3 E3 G3 B3 A2 G2 | "Amaj7"A,3 E3 A,3 E3 z4 | "Emaj9"E3 B,3 E3 B,3 z4 :|
V:3 clef=alto name="Synth Lead" subname="Comets"
%%MIDI program 81
|:"Emaj9"[BE]8 [GF]8 | "Amaj7"[CA]8 [^D>E]8 z6 | "C#m11"[CF]8 [AB]8 | "Badd9"[^DB]8 [GF]8 [E3] |
"Emaj9"[BE]8 [GF]8 [E4B4]4 | "Amaj7"[CA]8 [^D>E]8 z6 | "F#m11"[FC]8 [A>^D]8 | "G#m7"[GB]8 [^D>F]8 z6 |
"Bmaj7+B9"[BF]4 [^D>A]4 [G4F4]4 z3 | "C#m11"[CE]8 [GB]8 [A2G2]4 | "Amaj7"[CA]8 [E6A6]4 | "Emaj9"[BE]8 [B8E8]4 z7 :|
V:4 clef=bass name="Bass" subname="Black Holes"
%%MIDI program 39
|:"Emaj9"[E,B,]4 [G,E,B,]4 [F,E,B,]4 | "Amaj7"[A,C]4 [C,A,C]4 [^D,A,C]4 z8 | "C#m11"[C,F]4 [A,C,F]4 [B,A,C]4 | "Badd9"[B,^D]4 [G,B,^D]4 [A,G,B]4 [F2^D2]4 |
"Emaj9"[E,B,]6 [G,E,B,]4 [B,8]4 z6 | "Amaj7"[A,C]6 [C,A,]4 [A,8]4 z6 | "F#m11"[F,A]4 [C,F,A]4 [E,2^D2]4 | "G#m7"[G,B,]4 [^D,G,B,]4 [B,8]2 |
"Bmaj7+B9"[B,F]2 [A,^D]2 [F4B,4]4 [G,B,]4 [A,^D]4 z8 | "C#m11"[C,E]4 [B,C,E]4 [A,G]2 [G,8]4 z5 | "Amaj7"[A,C]6 [E,A,]4 [A,8]4 z6 | "Emaj9"[E,B,]8 [B,8]4 z7 :|



X:1
T:Inventive Curiosity
C:OrchestrAI
M:7/8
L:1/16
Q:3/8=126
K:G
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"G"dedc BAGF|"C"EGAB cdcB|"Am"c2E2 A4|"D7"F2D2 GFGA|
"D"BBcD DEFG|"C"B2c2 E4|"Bm"D2F2 A4|"E7"e2B2 AGFE|
"G"G2B2 BAGB|"C"CD2E GFGA|"Am"E2A2 c2B2|"D7"F2D2 G4|
"G"G2B2 BAGB|"C"c2E2 G4|"Em"GABc BAGB|"D"AGFE D2G2:|
V:2 clef=treble name="Piano Rhythm"
%%MIDI program 0
|:"G" G2z2 B2d2|"C" e2g2 c2e2|"Am" A2c2 E2A2|"D7" F2A2 D2F2|
"D" d3f a2f2|"C" e2g2 c2e2|"Bm" b2d2 f2b2|"E7" g2b2 e2g2|
"G" g2b2 d2g2|"C" e2g2 c2e2|"Am" a2c2 e2a2|"D7" f2a2 d4|
"G" g2b2 d2g2|"C" e2g2 c3e|"Em" e2G2 B2e2|"D" d4 z4:|
V:3 clef=bass name="Bass Synth"
%%MIDI program 38
|:"G" D4 z4| "C" C4 z4| "Am" A,4 z4| "D7" D4 z4 |
"D" A,4 z4| "C" G,4 z4| "Bm" B,4 z4| "E7" E4 z4 |
"G" D4 z4| "C" C4 z4| "Am" A,4 z4| "D7" D4 z4 |
"G" D4 z4| "C" C4 z4| "Em" E4 z4| "D" D4 z4 :|



X:1
T:Twinkle Twinkle Little Star
C:OrchestrAI
M:4/4
L:1/4
Q:1/4=100
K:C
% Melody with chords:
V:1 clef=treble
%%MIDI program 1
|: "C"C C "G7"G G | "C" E E D2 | "F"F F "C"E E | "G7"D D C2 |
"C"C C "G7"G G | "C" E E D2 | "F"F F "C"E E | "G7"D D C2 |
"C"E E "F"F F | "G7"E E D2 | "C"E E "F"F F | "G7"E E D2 |
"C"C C "G7"G G | "C"E E D2 | "F"F F "C"E E | "G7"D D C2 :|
% Counter-melody/Harmony in Voice 2:
V:2 clef=treble
%%MIDI program 44
|:  G, G, B, B, | G G E2 | A, A, G, G, | B, B, G,2 |
G, G, B, B, | G G E2 | A, A, G, G, | B, B, G,2 |
G, G, A, A, | G G E2 | G, G, A, A, | G G E2 |
G, G, B, B, | G G E2 | A, A, G, G, | B, B, G,2 :|
% Bass voice:
V:3 clef=bass
%%MIDI program 34
|: C,2 G,,2 | E,2 C,2 | F,2 A,,2 | D,2 G,,2 |
C,2 G,,2 | E,2 C,2 | F,2 A,,2 | D,2 G,,2 |
E,2 F,2 | E,2 C,2 | E,2 F,2 | E,2 C,2 |
C,2 G,,2 | E,2 C,2 | F,2 A,,2 | D,2 G,,2 :|



X:1
T:The Transcendental Battle
C:OrchestrAI
M:5/4
L:1/16
Q:1/4=160
K:Am
%%staves {V1 V2 V3 V4}
V:1 clef=treble
%%MIDI program 48
K:Am
|:"Am"^c8 e8|a4 g4 ^f4 e4|"G"d4 B4 d4 ^f4|g4 e4 g4 a4|
|:"Am"e2 ^c2 e2 a2 g2 f2|e2 A2 E2 A2 G2 E2|"G"d2 B2 d2 g2 ^f2 e2|d2 G2 B2 d2 ^f2 g2|
|:"C"e4 E4 C4 E4|"Dm"A4 A,4 D4 F4|"E7"G4 B,4 E4 G4|"A5"(B,8 B,8)|
V:2 clef=treble
%%MIDI program 41
|:"Am"A2 E2 A2 ^c2 E2 A2|C2 A,2 C2 E2 A2 G2 "G"|B,2 D2 G2 B2 d2 B2|G2 D2 F2 A2 d2 c2|
|:"Am"A2 E2 A2 c2 E2 A2|C2 A,2 E2 A2 C2 E2|"G"B,2 D2 G2 B2 d2 G2|D2 G2 B2 d2 G2 A2|
|:"C"G2 C2 E2 G2 C2 E2|"Dm"F2 A2 d2 f2 a2 g2|"E7"G2 B2 e2 g2 b2 a2|"A5"A,2 E2 A2 E2 A2 G2|
V:3 clef=alto
%%MIDI program 49
|:"Am"e2 A2 c2 e4 a2|g2 e2 ^f2 e2 a4 g4|"G"d2 G2 B2 d4 ^f4|g4 e4 g4 a4|
|:"Am"a3 g3 f3 e3 A3 G3|"G"B3 d3 ^f3 g3 e3 d3|"C"e3 E3 C4 E4|"Dm"A,3 A3 D4 F4|
|:"E7"^C3 G3 B3 e3|"A5"B,3 A3 E4 A4|G6 AB A4|"Am"e8 z8|
V:4 clef=bass
%%MIDI program 43
|:"Am"A,6 E6 | A,6 E6 | "G"D,6 B,6 | D,6 G,6 |
|:"Am"A,4 A,2 E4 E2|A,4 A,2 E4 E2|"G"D,4 D,2 B,4 B,2|D,4 D,2 G,4 G,2|
|:"C"C,4 C,2 C4 E2| "Dm"F4 F2 D4 F2|"E7"E,4 G,2 E4 G2|"A5"A,12|



X:1
T:Innovative Inspirations
C:OrchestrAI
M:12/8
L:1/16
Q:1/4=110
K:Cmaj
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"C"E2G4 c4 z2 E2D2|"Am"A3B c4 A4 G2F2 |"F"F2A4 c4 A2G4|"G"G2B4 d4 G4 F3 |
"C"E6 -E2G4 z2 c2|"Em"B4 -B2G4 z2 E4|"F"F6 -F2A4 z2 c2|"G7"G2D4 D4 G,6 :|
V:2 clef=treble name="Electric Guitar"
%%MIDI program 30
|:"C"c4 e6 G4 c2|"Am"a3b c6 z2 -c2B2|"F"A6 A2F4 z2 A2|"G"G4 d6 -d2c4 |
"C"e6 -e2c4 z2 G2|"Em"G,6 B,4 z2 E4|"F"F2A4 c6 z2 -c2|"G7"G,6 D4 G,6 :|
V:3 clef=bass name="Synth Bass"
%%MIDI program 38
|:"C"C6 G,6 C,4|"Am"A,6 E6 A,4|"F"F4 A,4 c4 F,4|"G"G,4 B,3 G4 D,5 |
"C"C6 -C4 G,6|"Em"B,4 E4 G,6 -G2|"F"F,6 A,6 c4|"G7"G,8 D6 G,2 :|
V:4 clef=treble name="E.Piano"
%%MIDI program 4
|:"C"e4 g6 c4 e2|"Am"a4 c6 e4 a2|"F"f4 a4 c6 f2|"G"g4 b6 d4 g2 |
"C"c4 -c2e4 g4 c'2|"Em"e4 g4 b6 e2|"F"a4 c'4 f6 a2|"G7"b4 d8 g4 :|



X:1
T:Transcendental Warfare
C:OrchestrAI
M:C
L:1/8
Q:1/2=60
K:Am
V:1 treble
%%MIDI program 48
% The opening sets a mystical and turbulent stage
|:"Am"E2 A2 C3 E|"E7+11/D"[D2E2F2G2] [A2^c2e2g2]|"Am/A"E4 A3 C|"E7/B"B3 ^G E2 z2|
|:"Fmaj7"A2 c2 e3 g |"Dm/F"f2 "G13"G2 "Cmaj9"E2 G2|"Am" A4 E3 C|"E7"B,4 D4:|
|:"Amin7"D3 D EF GA|"D7/F#" F3 D CD EF|"Gmaj7"B,4 D4 G2|"C/E"G3 G FE DC|
|:"F6"A2 c2 e2 g2|"Dm7"a2 f2 "E7"G B d2|"Am" c4 E4|"G" D6 FE:|
|:"C"G3 E F2 G2|"Am"E2 D2 C4|"Fmaj7"A,2 A,2 B,2 C2|"G13"G,2 B,2 D3 G|
% Transitioning to reveal the crux of the battle
|:"Dm"A2 d2 f3 a|"Am"e2 c2 E4|"E7" G2 B,2 "A7/C#"^C2 E2|
"Fmaj7"+B,2 A2+ [G2B2d2]+[e2g2e'2]|
% Climax that represents transcendence
|:"E7#9/G#"[G2^G2B2][e2g2^g2][b2e'2][g'2b2]|"F6/A"[A,2c2][f2a2][c'2e'2][a2f2]|
"D7/A"[A2d2][f2a2][d2f2][a,2d'2]|"G#"B,4 [G,2G,2]z2 "C/G"[G2C2][E2G2][C2E2][G,2C2]|
[|:"Am"A6 ^G2|"F" A2 c2 f2 a2|"G6"[e2G2] "Dm/A"A4|"F6/A"C4 "G13/D"D4|
% The conclusion stresses the uncertainty of the outcome
[|:"E7#11/D"[D2^F2A2c2][d2f2a2c2][d2f2a2c2][d2^f2]|"Am9"[E2A,2C2E2][A,2E2A,2^c2][E2A,2C2E2] [A,2E2A,2^c2]|:|



X:1
T:Cyber Chase Conundrum
C:OrchestrAI
M:7/8
L:1/16
Q:1/4=160
K:Emin
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|: "Em"B,16 | B,2E2B2E2 B2F2D2B,2 | B,2E2G2F2 E6 z2 | "C"B,2E2B,2D2 E2F2G2A2 B2A2G2F2|
"C"G,2B,2G,2E2 F2G2A2B2 c2B2A2G2 | "A#dim7"A,2c2A,2F2 G2A2B2c2 d2c2B2A2 | "B7"B,2D2F2G2 F4 E4 | "Em"E,12 z4:|
V:2 clef=treble name="Arpeggio Synth"
%%MIDI program 84
|: "Em"E8- E4 B4 | E2E2G2F2 E8 | E4 B,4 E4 E8 | "C"G4- G4 E4 F4 G4 A4 |
"C"G2_E2F2G2 A2G2F2E2 | "A#dim7"F2A2F2D2 E8 | "B7"D2F2G2A2 G4 F4 | "Em"E,8- E8:|
V:3 clef=treble name="Bass Synth"
%%MIDI program 38
|: "Em"E,16 | E,16 | E,2B,2B,2E,2 F,2E,2E,2D,2 | "C"E,2C2C2G,2 A,2G,2F,2E,2 |
"C"C,3C,3E,2 F,4 G,4 | "A#dim7"A,2F2F2D2 E,8 | "B7"B,4 F,8 z4 | "Em"E,12 z4:|
V:4 clef=bass name="Drone"
%%MIDI program 92
|: "Em"E,8 z8 | z8 z8 | "C"G,8 z8 | z8 z8 |
"C"C,8 z8 | "A#dim7"B,,8 z8 | "B7"B,8 z8 | "Em"E,8 z8:|



X:1
T:Final Showdown
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=200
K:Bm
V:1 clef=treble name="Synth Lead" subname="Lead"
%%MIDI program 81
|: "Bm"d2d2 cBAG F2F2 z4 | "Em" E6 z2 G2GF E2E2 | "F#7"F2F2 FEDE F4 z4 | "Bm" B8- B6 z2 |
"Bm" d2fd c2ec B2dB z4 | "A"A6 z2 A2Bc d2d2 | "G"g2BG F2AF E4 z4 | "Bm" B8- B6 zd |
"Em" e4 g2e2 f2d2 z4 | "F#7" c4- c2 B2 c4 z4 | "Bm"B6 z2 d2cB A2A2 | "Em" G4-F4 E8 :|
V:2 clef=treble name="Distorted Guitar" subname="Guitar"
%%MIDI program 30
|: "Bm"B4 z4 B4 z4 | "Em" E4 z4 E4 z4 | "F#7" F4 z4 F4 z4 | "Bm" B8- B6 z2 |
"Bm" B4 z4 B4 z4 | "A" A4 z4 A4 z4 | "G" G4 z4 G4 z4 | "Bm" B8- B6 z2 |
"Em" E4 z4 E4 z4 | "F#7" F2-D2 C4-B4 z4 | "Bm" B4 z4 d4 z4 | "Em" G4-F4 E8 :|
V:3 clef=bass name="Electric Bass" subname="Bass"
%%MIDI program 33
|: "Bm"B,8 B,8 | "Em" E,8 E,8 | "F#7" F,8 F,8 | "Bm" B,8 B,8 |
"Bm" B,8 B,8 | "A" A,8 A,8 | "G" G,8 G,8 | "Bm" B,8 B,8 |
"Em" E,8 E,8 | "F#7" F,8 F,8 | "Bm" B,8 B,8 | "Em" E,8 E,8 :|



X:1
T:Joyful Ensemble Jam
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=144
K:G
V:1 clef=treble name="Flute" subname="Flute"
%%MIDI program 73
|:"G"d2B2 G2A2| "D7"A2F2 F4 | "C"E2D2 "G"D2E2|"D7"F2D2 "G"G4|
"G"B2d2 d2B2| "C"c2e2 e2d2 | "Em"B2G2 A2BA|"D7"G6z2:|
V:2 clef=treble name="Clarinet" subname="Cl."
%%MIDI program 71
|:"G"G2D2 E2G2 | "D7"A2D2 F2A2|"C" G2E2 "G"G2A2|"D7"F2D2 "G"G4|
"G"d2g2 g2d2| "C"e2c2 c2B2 | "Am"E2C2 D2ED|"D7" A4 A4 :|
V:3 clef=bass name="Electric Bass" subname="Bass"
%%MIDI program 33
|:"G"D4 G,2B,2| "D7"A,4 A,2F2 | "C"C4 E4 |"D7" F4 D4|
"G"G,4 G,2B,2| "C"E4 G4| "Em"B,4 E4|"D7" A,4 D4:|
V:4 clef=treble name="Trumpet" subname="Trpt."
%%MIDI program 56
|:"G"B2d2 B2d2 | "D7"f2a2 f4 | "C"g2e2 "G"d2B2 | "D7"c2A2 "G"G4|
"G"d2^c2 d2e2 | "C"c2B2 A2G2| "Am"A2G2 F2E2 | "D7"d4 d4 :|



X:1
T:Quest of Legends
C:OrchestrAI
M:12/8
L:1/8
Q:1/4=170
K:Em
V:1 clef=treble name="Brass" subname="Brass"
%%MIDI program 61
|:"Em" B3 B2A G2F E2E | G3-F3 E4 z2 | "C" G3E3 C3D3 | "B7" B,3^D3 F3G3 |
"Em" B3 B2A G2F E2E | G3-F3 E4 z2 | "C" G2E C2D G2B, D2E | "Em" E6 E4 z2 :|
V:2 clef=treble name="Strings" subname="Violins"
%%MIDI program 48
|:"Em" e4 b3 g3f2 | g3-f3 e6 | "C" c4 G3 E3D2 | "B7" B3^D3 G3F3 |
"Em" e4 b3 g3f2 | g3-f3 e6 | "C" e3 c3 G3B,3 | "Em" e6 e4 z2 :|
V:3 clef=bass name="Bass" subname="Bass"
%%MIDI program 43
|:"Em" E,4 E,3 A,3B,2 | B,2 A,2 G,6 z2 | "C" C4 C3 E3G2 | "B7" B,3^D3 F3^A3 |
"Em" E,4 E,3 A,3B,2 | B,2 A,2 G,6 z2 | "C" C2 G,2 E2C2 z4 | "Em" E,6 E,4 z2 :|
V:4 clef=treble name="Woodwinds" subname="Flute"
%%MIDI program 73
|:"Em" B6- B2d2c2 | d4 e2 B4 z2 | "C" G4- G2 E4 z2 | "B7" B6- B2F2^D2 |
"Em" d2B2 G4- G2 z2 | F4 E2 D4 z2 | "C" G2E2 C4- C2 z2 | "Em" E6 E4 z2 :|



X:1
T:Auld Lang Syne Duet
C:Traditional
C:Arranged by OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:Cmaj
V:1 name="Alto Sax" clef=treble
%%MIDI program 65
|: "C" e4 e2 d2 | "G" B4 A2 G2 | "C" E4 G2 E2 | "C" C6 z2 |
"C" e4 e2 d2 | "G" B4 A2 G2 | "C" E4 G2 E2 | "C" C6 z2 |
"F" f4 e2 c2 | "C" e4 d2 B2|"Am" A4 G2 E2 | "G" G6 z2 |
"F" f4 e2 c2 | "C" e4 d2 B2 | "C" E4 G2 E2 | "C" C6 z2 :|
V:2 name="Tuba" clef=bass
%%MIDI program 58
|: "C" C,4 C,2 D,2 | "G" G,4 D2 G,2 | "C" C4 E2 C2 | "C" C,6 z2 |
"C" C,4 C,2 D,2 | "G" G,4 D2 G,2 | "C" C4 E2 C2 | "C" C,6 z2 |
"F" A,4 A,2 F,2 | "C" C4 E2 C2 | "Am" A,4 C2 E2 | "G" G,6 z2 |
"F" A,4 A,2 F,2 | "C" C4 E2 C2 | "C" C4 E2 C2 | "C" C,6 z2 :|



X:1
T:Duel at High Noon
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Em
% The piece begins with the tension of the duelists eyeing each other
V:1 clef=treble name="Trumpet" subname="Call"
%%MIDI program 56
|:"Em" E4 z4 | "B" B,4 z4 | "Em" E4 G4 | "B" B,4 z2 z2 |
"Am"A4 B2 A2 | "Em"G4 F2 E2 | "B" D4 z4 | "Em" E6 z2 |
% The tempo increases representing the acceleration of the duel's pace
Q:1/4=120
"Em"E3 F G2 F2 | "B" B4 z4 | "Em"E3 F G2 F2 | "B" B6 :|
% The quick draw and shots fired
Q:1/4=150
"Em"B8 | "D" A8 | "G" B2 E4 G2 | "Em" E8 :|
V:2 clef=treble name="Acoustic Guitar" subname="Rhythm"
%%MIDI program 25
|:"Em" [E4B4] z4 | "B" [B,4F#4] z4 | "Em" [E4B4] [G4E4] | "B" [B,4F#4] z2 z2 |
"Am"[A2E2][C4E4]
[A2E2]| "Em" [G4B,4] [F4A4] [E5] | "B" [D4F#4] z4 | "Em" [E4B,4] [G4B4] [E5] |
% Guitar quickens with 'tremolo' to increase tension
Q:1/4=120
"Em"[E2B2][E2G2]
[F2B2][F2A2]| "B" [B,4F#4] z4 | "Em"[E2B2] [E2G2] [F2B2] [F2A2] | "B" [B,4F#4] z4 |
% Staccato notes signify the shoot-out
Q:1/4=150
"Em"[E4B4]| "D" [A4F#4] | "G" [B2G2] [E4B,4] [G2B2] | "Em" [E4B,4] :|
V:3 clef=bass name="Bassoon" subname="Bass"
%%MIDI program 70
|:"Em" E,4 z4 | "B" B,,4 z4 | "Em" E,4 E4 | "B" B,,4 z2 z2 |
"Am" A,4 z4 | "Em" G,4 z4 | "B" D4 z4 | "Em" E,6 z2 |
% Bassoon follows tension with a rhythmical increase
Q:1/4=120
"Em"E,3 F, G,2 F,2 | "B" B,,4 z4 | "Em"E,3 F, G,2 F,2 | "B" B,,4 z4 |
% Deep, quick notes for the resolution
Q:1/4=150
"Em"E,8 | "D" A,,8 | "G" B,,2 E,4 G,2 | "Em" E,8 :|



X:1
T:Whimsical Rhapsody
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=120
K:G
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G"D2 B2 G2 | "Am"A4 GA | "Bm"B3 G FE | "Em"G4 B2 |
"D"A2 d2 c2 | "C"B4 AB | "G"D3 G Bc | "D"D4 EF |
"G"G6 | "C"E4 EC | "D"D6 | "G"B,4 D2 | "Em"E4 E2 | "Am"A3 G F2 |
"G"G6 | "D7"A4 F2 :|
V:2 clef=treble name="Piano Right"
%%MIDI program 0
|:"G"g2 e2 g2 | "Am"a4 e2 | "Bm"f3 e d2 | "Em"e4 g2 |
"D"f2 a2 g2 | "C"e4 a2 | "G"g3 a b2 | "D"a4 b2 |
"G"g6 | "C"c4 e2 | "D"a6 | "G"b4 d2 | "Em"e4 e2 | "Am"c3 B c2 |
"G"g,6 | "D7"f4 d2 :|
V:3 clef=bass name="Piano Left"
%%MIDI program 0
|:"G"G,4 D2 | "Am"A,4 C2 | "Bm"B,2 A,2 ^F2 | "Em"E,4 D2 |
"D"D4 F2 | "C"G,4 C2 | "G"G,2 D2 B,2 | "D"A,3 D F2 |
"G"D,4 G,2 | "C"C4 E2 | "D"F2 A2 d2 | "G"G,4 D2 | "Em"E,4 E2 | "Am"A,4 C2 |
"G"G,4 B,2 | "D7"D,2 ^F2 A,2 :|



X:1
T:Nocturnal Promenade
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=108
K:Cmin
V:1 clef=treble name="Piano"
%%MIDI program 0
|:"Cm"E2 DC B,2 C2 | D4 E2 G2 | "Fm"F3 E F2 A2 | "G" G4- G2 F2 |
"Fm" F3 E F2 G2 | "Eb"E2 C2 D4 | "Ab" c4 B2 A2 | "Bb" B4 z2 G2 |
"Cm" E2 DC B,2 C2 | "Fm" F2 E2 F2 G2 | "Eb"E2 D2 C2 B,2 | "G7" B,4 G,2 F2 |
"Cm"E3 F G2 ^F2 | "Ddim"D2 E2 F3 G |[K:Ab] "Ab"c2 c2 "G" B3  |[K:Cm] "Cm" C4 z2 E |
|: "Ab" A2 GF E2 F2 | "Eb" G4 -G2 F2 | "Cm" E2 DC B,2 C2 |"G" D4- D2 F2 |
"Ab" A2 GF E2 C2 | "Eb" G4- G2 ^F2 | "Cm"G3 A B2 c2 |"G7" G4 z2 G2 :|
V:2 clef=treble name="String Ensemble"
%%MIDI program 48
|:"Cm"c2 B2 c2 d2 | e2 e2 g2 e2 | "Fm"f2 e2 f2 g2 | "G" g4- g2 f2 |
"Fm" f2 e2 "Eb" d2 c2 | B2 B2 c2 e2 | "Ab" a2 a2 "Bb" b2 g2 | g4 z2 g2 |
"Cm"c2 B2 "Fm" c2 "Eb" d2 | "Fm"f2 e2 f2 "Bb" d2 | "Eb"c2 B2 "Cm"c2 "G7" B2 | G4 z2 G2 |
"Cm"c2 c2 d2 eb | "Ddim"d2 e2 f2 g2 |[K:Ab] "Ab"a2 a2 "G" b3  |[K:Cm] "Cm"c'4 z2 c |
|: "Ab" a2 gf "Eb" e2 f2 | "Cm" c4 -c2 B2 | "Cm"B2 A2 G2 F2 |"G" D4- D2 F2 |
"Ab" a2 gf "Eb" e2 c2 | "Cm"B4- B2 "G7"^A2 | "Cm"c2 B2 A2 G2 | G4 z2 G2 :|
V:3 clef=alto name="Choir Aahs"
%%MIDI program 52
|:"Cm"G2 A2 B2 c2 | d4 e2 c2 | "Fm"f2 e2 d2 c2 | "G" b4 a2 g2 |
"Fm" a2 g2 "Eb"f2 e2 | d2 d2 e2 c2 | "Ab"c2 c2 "Bb"d2 c2 | c4 z2 A2 |
"Cm"G2 A2 "Fm"B2 A2 | "Eb"G2 F2 E2 F2 | "Cm"D2 E2 F2 G2 | "G7" G4 z2 B2 |
"Cm"c3 d e2 d2 | "Ddim"d2 c2 B2 A2 |[K:Ab] "Ab" e2 e2 "G" d3  |[K:Cm] "Cm" c4 z2 G |
|: "Ab" e2 dc B2 c2 | "Eb" c4 -c2 A2 | "Cm"G2 F2 E2 G2 |"G" D4- D2 G2 |
"Ab" e2 dc B2 c2 | "Eb" c4- c2 A2 | "Cm"G3 A B2 c2 |"G7" G4 z2 ^F2 :|
V:4 clef=bass name="Synth Bass"
%%MIDI program 38
|:"Cm"C,4 C,2 C,2 | D,4 E,2 G,2 | "Fm"F,4 F,2 A,2 | "G" G,4- G,2 F,2 |
"Fm" F,4 "Eb" E,2 "Fm" F,2 | "Eb"E,2 C,2 "Ab" D,4 | "Ab" c2 -c2 "Bb" B,2 G,2 | B,4 z2 C2 |
"Cm"C,4 "Fm" D2 "Eb" E,2 | "Fm" F,4 "Bb" G,2 z2 | "Eb" E,4 "G7" F,2 z2 | "Cm" G,2 C,2 B,,2 C,2 |
"Ab" C,2 D,2 E,2 F,2 | "Ddim" F,2 G,2 A,2 D2 |[K:Ab] "Ab" A,3 F,"Bb" G,2 |[K:Cm] "Cm"C,4 z2 C, |
|: "Ab" A,2 D,2 C,2 F,2 | "Eb" G,4 -G,2 ^F,2 | "Cm"C,2 B,,2 B,2 C2 |"G" D,4 -D,2 F,2 |
"Ab" A,2 D,2 C,2 F,2 | "Eb" G,4- G,2 ^F,2 | "Cm"C,3 C, D,2 E,2 | "G7" B,,4 z2 G,2 :|



X:1
T:Glorious Battle Rhapsody
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Bm
% The introduction sets the stage for an epic confrontation
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
|:"Bm" B2B2 d2f2 g3f edcB | "G" G2A2 B2d2 c3B ABAG | "A" A2f2 e2c2 d3c BAGF | "Em" E2G2 F2A2 G4 z4 |
"Bm" f2a2 b2af g2e2 d2c2 | "G" d2B2 G2B2 A2F2 G4 | "F#m" A2c2 e2a2 f3e dcBA | "Bm" B8 B4 z4 |
"F#7" c2c2 e2g2 a3g fecA | "G" d2d2 f2a2 b3a gfed | "A" c2cB A2GF E2D2 C4 | "Em" E8 E4 z4 |
"Bm" b3a g3f e2d2 cdcB | "G" B3A G3F E2G2 F4 | "F#m" c4 - c2c2 d3c BGAF | "Bm" B8 B4 z4 :|
V:2 clef=treble name="Brass Section" subname="Brass"
%%MIDI program 61
|:"Bm" d2f2 B2d2 f4 z4 | "G" B2d2 G2B2 d4 z4 | "A" A2c2 E2A2 c4 z4 | "Em" G2B2 E2G2 B4 z4 |
"Bm" f2a2 b2af g2e2 d2c2 | "G" d2B2 G2B2 A2F2 G4 | "F#m" A2c2 e2a2 f3e dcBA | "Bm" B8 B4 z4 |
"F#7" c2e2 A2c2 e4 z4 | "G" d2f2 G2d2 f4 z4 | "A" A2cB A2GF E2D2 C4 | "Em" E8 E4 z4 |
"Bm" b3a g3f e2d2 cdcB | "G" B3A G3F E2G2 F4 | "F#m" c4 - c2c2 d3c BGAF | "Bm" B8 B4 z4 :|
V:3 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 87
|:"Bm" B,4 B,2B,2 B,4 z4 | "G" G,4 G,2G,2 G,4 z4 | "A" A,4 A,2C2 A,4 z4 | "Em" E,4 E,2E,2 E,4 z4 |
"Bm" B,4 B,2B,2 B,4 z4 | "G" G,4 G,2G,2 G,4 z4 | "F#m" F,4 F,2A,2 F,4 z4 | "Bm" B,8 B,4 z4 |
"F#7" F,4 F,2A,2 F,4 z4 | "G" G,4 G,2G,2 G,4 z4 | "A" A,4 A,2A,2 A,4 z4 | "Em" E,8 E,4 z4 |
"Bm" B,4 B,2B,2 B,4 z4 | "G" G,4 G,2G,2 G,4 z4 | "F#m" F,4 F,2A,2 F,4 z4 | "Bm" B,8 B,4 z4 :|



X:1
T:Epic Inspiration
C:OrchestrAI
M:4/4
L:1/4
Q:1/4=100
K:Cmaj
V:1 clef=treble name="Trombone"
%%MIDI program 57
|:"Am"[A,EA]3 [CEA]3 | "F"[F,AF]3 [CAF]3 | "C/G"[G,EG]3 [CEG]3 | "Gsus4"[G,EG]3 [CDG]3 |
"C"[CEG]4- | "E7"[G^DG]3 [^DFG]3 | "Am"[AEA]3 [CEA]2z | "Fmaj7"[cAF]3 [EAc]3 |
"Dm"[ADF]4- | "G"[GBd]3 [DGB]3 | "C"[CEG]3 [^CEG]3 | "A7sus4"[AEc]3 [A,E,C]3 |
"Dm"[ADF]3 [dAf]3 | "Bb2"[B,DF]3 [FDf]3 | "Esus4"[EG^G]3 [^GEG]2z | "E"[EG^G]4 :|
V:2 clef=treble name="Strings"
%%MIDI program 48
|:"Am"e4 c'4 | "F"d4 A4 | "C/G"c4 G4 | "Gsus4"d4 G4 |
"C"e4 c'4 | "E7"b,4 e4 | "Am"f4 d'4 | "Fmaj7"a4 A4 |
"Dm"f4 A4 | "G"b4 d4 | "C"e4 g4 | "A7sus4"G4 E4 |
"Dm"F4 D4 | "Bb2"F4 B,4 | "Esus4"G4 B,4 | "E"G4 E4 :|
V:3 clef=bass name="Bass Guitar"
%%MIDI program 33
|:"Am"[A,2E2]3 [A,2C2]3 z4 | "F"[F,2A2]3 [F,2C2]3 z4 | "C/G"[G,2G,2]3 [G,2E2]3 z4 | "Gsus4"[G,2D2]3 [G,2C2]3 z4 |
"C"[C,2E2]4- | "E7"[^G,2B,2]3 [^G,2^D2]3 | "Am"[A,2E2]3 [A,2C2]2z | "Fmaj7"[A,2F2]3 [C2F2]3 |
"Dm"[D2F2]4- | "G"[G,2B2]3 [D2B2]3 | "C"[C2G2]3 [C2^E2]3 | "A7sus4"[C,2A,2]3 [C2E2]3 |
"Dm"[D2A2]3 [D2F2]3 | "Bb2"[B,,2D2]3 [F2D2]3 | "Esus4"[E,2G,2]3 [G2B,2]2z | "E"[E,2G,2]4 :|



X:1
T:Nocturnal Glide
C:OrchestrAI
M:C
L:1/8
Q:1/4=60
K:Am
V:1 clef=treble
%% MIDI program 71
|:"Am"A2 | E4 E2 CD | E4 z2 A2 | "C"G6 AG | E6 z2 |
"Am"A4 (BA) GA | "G"B2 (AG) E2 DC | "Dm"A,4 E2 DE | "Am"A6 z2 |
"F"EF G2 A2 G2 | "E"E4 E2 EF | "Am"A4 (A6 |1 A6 :|2 A6 ||
V:2 clef=bass
%% MIDI program 42
[|"Am"A,6 CE | A,6 C2 | "C"C6 EG | "Am"A,6 CE |
"Am"A,2 A,2 E2 E2 | "G"G,2 G,4 ^G,2 | "Dm"A,6 DE | "Am"A,6 |
"F"F,6 F2 G2 | [E3B,3] [E2B2] E2 | [A,6F6] |1 [A,6E6] :|2 [A,6A,6] |]



X:1
T:Pirate Polka
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=120
K:Dmin
V:1 clef=treble name="Fiddle"
%%MIDI program 110
|:"Dm" A2A2 F2D2 | E4 E2DE | F2F2 A2d2 | "C" c8 |
"Dm" A2f2 e2d2 | "A" c2A2 c2E2 | "Dm" D4 F2A2 | "C" G4 G4 |
"Dm" A2d2 f2a2 | "Gm" B2d2 g2f2 | "A" e4 c2A2 | "Dm" d8 :|
V:2 clef=treble name="Accordion"
%%MIDI program 21
|:"Dm" A2G2 F2E2 | D2C2 E2D2 | F2F2 A2A2 | "C" G6 G2 |
"Dm" A2A2 G2F2 | "A" E4 c4 | "Dm" D2D2 A,2A,2 | "C" G,6 G,2 |
"Dm" A,2A,2 A,2A,2 | "Gm" B,2B,2 G2F2 | "A" E4 c4 | "Dm" d6 z2 :|
V:3 clef=bass name="Tuba"
%%MIDI program 58
|:"Dm" D,2F,2 A,2C2 | D,6 D,2 | F,2A,2 D2F2 | "C" C6 C2 |
"Dm" D,2F,2 A,2A,2 | "A" C2E2 A,2C2 | "Dm" D,6 D,2 | "C" C8 |
"Dm" D,2F,2 A,2A,2 | "Gm" B,2D2 G,2F,2 | "A" E,6 E,2 | "Dm" D,8 :|



X:1
T:Velocity Vortex
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=170
K:Bmin
% The racing intensity is matched with driving rhythms and fierce harmony
V:1 clef=treble name="Synth Lead"
%%MIDI program 81
|:"Bm"B4 z4 B4 z4|e4 z4 e4 z4|d8 e8|f8 e8|
"f#m"d4 z4 c4 z4|e4 z4 f4 z4|g8 a8|"A"b8 a8|
"E"g4 z4 e4 z4|f4 z4 g4 z4|"Bm"a8 b8|b16|
"Bm"B4 z4 B4 z4|e4 z4 e4 z4|d8 e8|f8 e8|
"f#m"d4 z4 c4 z4|e4 z4 f4 z4|g8 a8|"A"b8 a8|
"E"g4 z4 e4 z4|f4 z4 g4 z4|"Bm"b8 ^g8|a16:|
V:2 clef=treble name="Synth Bass"
%%MIDI program 87
|:"Bm"f4 z4 f4 z4|f4 z4 f4 z4|B,8 D8|G,8 F8|
"f#m"d4 z4 d4 z4|d4 z4 d4 z4|c8 c8|"A"e8 e8|
"E"A,4 z4 A,4 z4|A,4 z4 A,4 z4|"Bm"G,8 A,8|B,16|
"Bm"f4 z4 f4 z4|f4 z4 f4 z4|B,8 D8|G,8 F8|
"f#m"d4 z4 d4 z4|d4 z4 d4 z4|c8 c8|"A"e8 e8|
"E"A,4 z4 A,4 z4|A,4 z4 A,4 z4|"Bm"G,8 A,8|B,16:|
V:3 clef=treble name="Electric Guitar"
%%MIDI program 30
|:"Bm"z4 B4 z4 B4|z4 e4 z4 e4|z4 d4 z4 e4|z4 f4 z4 e4|
"f#m"z4 d4 z4 c4|z4 e4 z4 f4|z8 g8 a8|"A"z8 b8 a8|
"E"z4 g4 z4 e4|z4 f4 z4 g4|"Bm"z8 a8 b8|b16|
"Bm"z4 B4 z4 B4|z4 e4 z4 e4|z4 d4 z4 e4|z4 f4 z4 e4|
"f#m"z4 d4 z4 c4|z4 e4 z4 f4|z8 g8 a8|"A"z8 b8 a8|
"E"z4 g4 z4 e4|z4 f4 z4 g4|"Bm"z8 b8 ^g8|a16:|
V:4 clef=bass name="Electric Bass"
%%MIDI program 39
|:"Bm"B,16|B,16|D16|G,16|
"f#m"D16|D16|c16|"A"e16|
"E"A,16|A,16|"Bm"G,16|B,16|
"Bm"B,16|B,16|D16|G,16|
"f#m"D16|D16|c16|"A"e16|
"E"A,16|A,16|"Bm"G,16|B,16:|



X:1
T:Hero's Triumph
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:Cmaj
% The following is the main theme, symbolizing the pride of victory.
%%MIDI program 61
V:1
%%MIDI program 61
|: "C"G4 E4 | "Am"A6 B2 | "F"c4 A4 | "G"B2 c2 d4 |
"C"e2 G2 c4 | "Am"A2 G2 E4 | "F"F2 E2 D2 G,2 | "G"G,2 A2 C4 :|
% This secondary theme gives a sense of striving, building to triumph.
V:2
|: "C"c2 E2 G4 | "Am"e2 A2 c4 | "F"d2 F2 A4 | "G"b2 d2 e4 |
"C"G2 B,2 E4 | "Am"B2 c2 e4 | "F"F2 A2 c3d | "G"e2 G2 G4 :|
% The bridge represents the challenge that is overcome on the path to victory.
V:3
%%MIDI program 48
|: "Am"E2 A2 c2 e2 | "F"d3c B2 A2 | "C"G4 E2 G2- | "G"G2 F2D4 |
"Am"E2 A2 c2 e2 | "F"d3c B2 A2 | "C"G4- G2 E2  | "G"G4 z4 :|
% Return to the main theme with increased intensity, symbolizing the peak of pride.
V:1
"Em"B2 E2 G4 | "Am"c2 E2 A4 | "G"B2 D2 G4 | "C"c2 E2 G4 |
"C"G2 E2 C4 | "Am"A2 G2 E4 | "F"F3G A2 c2 | "G"B2 G2 G4 |
"C"E2 G2 c3B | "Am"A4 G2 F2 | "F"E2 c2 A2 G2 | "G" F4-D4 |
"C"G2 E2 C2 E2 | "Am"A2 F2 A2 c2 | "F"E2 D2 C4 | "C"C8 :|
V:2
"C"c2 G2 E2 G2 | "Am"A2 B2 c2 A2 | "G"B2 d2 G2 F2 | "C"E2 G2 c4 |
"C"G4 c4  | "Am"A2 B2 A2 G2 | "F"A2 G2 F2 D2 | "G"G,4 C3D |
"C"E2 G4 A2 | "Am"A4 F3E | "F"D2 A2 F2 E2 | "G"B4- G4 |
"C"c4 E2 G2 | "Am"A4 F2 A2 | "F"d3c "G"B2 G2 | "C"C8 :|



X:1
T:Amour Soirée
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=80
K:Gmaj
V:1 clef=treble name="Violin" subname="Lead"
%%MIDI program 40
|: "G"D4 B2 | "C"G3 F E2 | "D7"A4 F2 | "G"G6 |
"G"B3 c d2 | "Em"B4 G2 | "C"EF G2 A2 | "D7"A6 |
"G"D4 B2 | "C"E3 D G2 | "Am"A4 c2 | "Bm"B6 |
"D7"F4 E2 | "C"E3 D C2 | "G"D6- | "G"D4 z2 :|
V:2 clef=treble name="String Pad" subname="Pad"
%%MIDI program 48
|: "G"G,6 | "C"C4 E2 | "D7"F4 A2 | "G"G,6 |
"G"B,4 D2 | "Em"G,4 E2 | "C"C3 D E2 | "D7"F6 |
"G"G,4 B,2 | "C"E4 G2 | "Am"A,4 C2 | "Bm"B,6 |
"D7"F4 A,2 | "C"C4 E2 | "G"G,6 | "G"G,4 z2 :|
V:3 clef=bass name="Acoustic Guitar" subname="Gtr."
%%MIDI program 25
|: "G"D,3 G, A, z | "C"C,3 E, G, z | "D7"A,,3 F, A, z | "G"G,,3 D, G, z |
"G"B,,3 D, G, z | "Em"E,3 G, B, z | "C"C,3 E, G, z | "D7"D,3 F, A, z |
"G"G,3 B, D, z | "C"C,3 E, C, z | "Am"A,,3 C, E, z | "Bm"B,,3 D, F, z |
"D7"A,,3 F, A, z | "C"C,3 E, C, z | "G"G,,3 D, G, z | "G"D,3 G, B, z :|



X:1
T:Villain's Theme
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Gmin
% Combining sensory elements for an atmospheric effect
V:1 clef=treble name="Brass" subname="Theme"
%%MIDI program 62
|:"Gm" d4 B2 A2 | "Cm" g4 e2 d2 | "D"d4 f2 e2 | "Gm" d8 |
"Gm" c4 A2 G2 | "Cm" f4 d2 c2 | "D" B3 A G2 F2 | "Gm" G8 :|
V:2 clef=treble name="Strings" subname="Harmony"
%%MIDI program 48
|:"Gm" g4 g2 f2 | "Cm" c4 c2 B2 | "D" d4 d2 c2 | "Gm" g8 |
"Gm" f4 f2 e2 | "Cm" c4 c2 B2 | "D" d6 c2 | "Gm" g8 :|
V:3 clef=treble name="Synth" subname="Ambience"
%%MIDI program 81
|:"Gm" B2 d4 g2 | "Cm" g2 B4 c2 | "D" A2 d4 f2 | "Gm" b2 a4 g2 |
"Gm" B2 d4 g2 | "Cm" g2 B4 c2 | "D" A2 d4 f2 | "Gm" b2 a4 g2 :|
V:4 clef=bass name="Bass" octave=-1
%%MIDI program 33
|:"Gm" G,8 | "Cm" C,8 | "D" D,8 | "Gm" G,8 |
"Gm" G,8 | "Cm" C,8 | "D" D,8 | "Gm" G,8 :|



X:1
T:Groove of the Good Times
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Am
V:1
%%MIDI program 1
|: "Am"c2 E2 F2 E2 | "G"G4- G3A | "F"E2 c2 d2 c2 | "E"B6 B2 |
"Am"A2 c2 E2 c2 | "Dm"D4- D3E | "Am"C2 E2 A,2 C2 | "E"E6 z2 :|
V:2
%%MIDI program 70
|: "Am"[A,2E2] z A,3E z | "G"[G,2D2] z G,3D z | "F"[F2C2] z F3C z | "E"[E,2B,2] z E3B, z |
"Am"[A,2E2] z A,3C z | "Dm"[D2A,2] z D3F z | "Am"[A,2E2] z A,3C z | "E"[E,2B,2] z2 z4 :|
V:3
%%MIDI program 46
|: "Am"E2 A2 B2 A2 | "G"G4- G2AB | "F"A2 E2 F2 E2 | "E"G6 G2 |
"Am"A2 E2 G2 E2 | "Dm"D4- D2EF | "Am"A2 E2 C2 E2 | "E"E6 z2 :|
V:4
%%MIDI program 33
|: "Am"A,,2 C,2 E,2 C,2 | "G"G,,4- G,,,3A,, | "F"F,,2 A,,2 C,2 A,,2 | "E"E,,6 E,,2 |
"Am"A,,2 C,2 E,2 C,2 | "Dm"D,,4- D,,,3F,, | "Am"A,,2 C,2 A,,,2 C,2 | "E"E,,6 z2 :|



X:1
T:Sinfonia Latina
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:G
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|:"G" D4 G2 B2 | "C" E4 A2 c2 | "D7" F2D2 G2F2 | "G" G6 z2 |
"G" D2B2 G2A2 | "Am" A2E2 A2B2 | "D7" B2G2 F2D2 | "G" G6 z2 |
"C" c2e2 c2e2 | "G" B2d2 B2d2 | "Am" A2c2 A2c2 | "D" F2A2 F2A2 |
"G" G2B2 D4 | "Em" E2G2 E2C2 | "Am" A2E2 "D7" D2F2 | "G" G6 z2 :|
V:2 clef=treble name="Guitar"
%%MIDI program 25
|:"G" d2 d2 B2 d2 | "C" c2 c2 G2 c2 | "D7" F2 F2 A2 d2 | "G" G2 G2 B2 d2 |
"G" B2 B2 d2 B2 | "Am" A2 A2 E2 c2 | "D7" d2 d2 F2 A2 | "G" G2 G2 D2 G2 |
"C" E2 E2 G2 c2 | "G" D2 D2 B2 G2 | "Am" C2 C2 E2 A2 | "D" A2 A2 F2 D2 |
"G" B2 B2 D2 G2 | "Em" G2 G2 E2 C2 | "Am" A2 c2 "D7" F2 d2 | "G" G2 B2 D2 z2 :|
V:3 clef=bass name="Bass"
%%MIDI program 32
|:"G" G,2 G,2 D2 G,2 | "C" C,2 C,2 E2 G,2 | "D7" D2 A,2 D2 F2 |"G" G,4 D2 G,2 |
"G" B,,2 B,,2 G,2 B,,2 | "Am" A,,2 A,,2 E,2 A,,2 | "D7" F,2 D,2 A,2 d2 | "G" G,4 D2 G,2 |
"C" E,2 E,2 G,2 c2 | "G" D,2 D,2 B,,2 G,2 | "Am" A,,2 C,2 E,2 A,2 | "D" F,2 A,2 F,2 D,2 |
"G" B,,2 G,2 D2 G,2 | "Em" E,2 G,2 C2 E,2 | "Am" A,2 c2 "D7" F2 A,2 | "G" G,4 D2 z2 :|



X:1
T:Industrial Fugue in 7/8
C:OrchestrAI
M:7/8
L:1/16
Q:1/4=100
K:Cmin
% The violin leads with a poignant, dissonant theme
V:1 clef=treble name="Violin"
%%MIDI program 40
|: "Cm"G4 z2 G4 z G2 | "G" ^F4 z2 F4 z F2 | "Ab"=E4 z2 E4 z E2 | "Bdim"F4 z2 F4 z F2 |
"Cm" G,4 z2 G4 z G2 | "G" ^F4 z2 A,4 z A,2 | "Ab" =E4 z2 c4 z c2 | "Bdim"D4 z2 D4 z D2 :|
V:2 clef=treble name="Viola"
%%MIDI program 41
|: "Cm"_B,4 z2 _B,4 z =B,2 | "^G" =A,4 z2 =A,4 z ^A,2 | "Ab" G,4 z2 G,4 z G,2 | "^Bdim"=A,4 z2 =A,4 z ^A,2 |
"Cm" G,4 z2 G,4 z G,2 | "^G" =A,4 z2 =A,4 z ^A,2 | "Ab" G,4 z2 G,4 z G,2 | "^Bdim"=A,4 z2 =A,4 z ^A,2 :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "Cm"C,8 G,5 | "G"^F,8 D,5 | "Ab"=E,8 _B,,5 | "Bdim"F,8 _A,,5 |
"Cm" C,8 G,5 | "G"^F,8 D,5 | "Ab"=E,8 C,5 | "Bdim"D,8 F,5 :|
V:4 clef=bass octavedown=1 name="Bass"
%%MIDI program 43
|: "Cm"C,,8 C,,5 | "G"^F,,8 ^F,,5 | "Ab"=E,,8 =E,,5 | "Bdim"F,,8 F,,5 |
"Cm" C,,8 C,,5 | "G"G,,8 G,,5 | "Ab"=E,,8 =E,,5 | "Bdim"F,,8 F,,5 :|



X:1
T:Yearning Brass Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Am
% Assign voices for MIDI instruments
%%MIDI channel 1
%%MIDI program 56 % Trumpet
V:1 name="Trumpet" clef=treble
% Assign chord names to trumpet part
|:"Am"[E2A2] "F"[A2c2] "C"[G2E2] "G"[B2D2] | "Am"[E2A2] "F"[A2c2] "C"E4 | "F"[A2c2] "G"[B2D2] "Am"[E2A2] "G"[D2B2] | "F"[A2c2] "E"[G2E2] "Am"A4 |
|:"C"c4 "G"B4 | "Am"A3 G "F"E2 z2 | "C"E4 "Dm"D3 E | "E"E3 D "C"C4 :|
[| "Am"A3 B c4 | "F"A6 z2 | "G"B3 c d4 | "E"E6 z2 |
| "Am"c3 B A4 | "F"c6 z2 | "G"B3 A "Am"A4 | "E"E6 z2 |]
%%MIDI channel 2
%%MIDI program 57 % Trombone
V:2 name="Trombone" clef=bass
|:A,4 C4 | E3 F G2 z2 | A,4 C4 | E3 D C4 |
|:E,4 G,4 | A,3 G, A,2 z2 | C3 D E2 z2 | F2 E4 z2 :|
[| A,3^G, A,4 | C4 z2 z2 | A,3^G, A,4 | E4 z2 z2 |
| C3 D E4 | F4 z2 z2 | E3 F G2 z2 | A,4 z2 z2 |]
%%MIDI channel 3
%%MIDI program 58 % Tuba
V:3 name="Tuba" clef=bass
|:"Am"A,2 z2 "F"C2 z2 | "C"C,2 z2 C2 z2 | "F"A,2 z2 "G"B,2 z2 | "Am"A,2 z2 A,2 z2 |
|:"C"C,4 "G"B,,4 | "Am"A,4 "F"C2 z2 | "C"C,4 "Dm"D2 z2 | "E"E,4 "C"C2 z2 :|
[| "Am"A,,6 z2 | "F"C,6 z2 | "G"B,,6 z2 | "E"E,,6 z2 |
| "Am"A,,6 z2 | "F"C,6 z2 | "G"B,,6 z2 | "E"E,,6 z2 |]



X:1
T:Shadows and Whispers
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=60
K:Am
V:1 clef=treble
%%MIDI program 71
|:"Am"E2 F2 E4|"Dm"A,2 F2 D4|"E"A,2 C2 E4|"Am"E4 z4|
|:"C"E2 G2 E4|"Am"C2 E2 A4|"Ab"B,2 E2 C4|"E"B,2 G,2 z B,3:|
V:2 clef=treble
%%MIDI program 71
|:"Am"E4 E4|"Dm"D4 D4|"E"E4 E4|"Am"A4 z4|
|:"C"C4 C4|"Am"A4 A4|"Ab"=G4 G4|"E"E4 z4:|
V:3 clef=bass
%%MIDI program 70
|:"Am"A,4 A,4|"Dm"D,4 D,4|"E"E,4 E,4|"Am"A,2 C2 E2 A,2|
|:"C"E,4 G,4|"Am"A,4 (C2 z4 z2 E2)|"Ab"=G,4 (B,2 z4 z2 E2)|"E"B,4 z4:|



X:1
T:Realms of Aetheria
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:Gmaj
% The piece emphasizes the imaginative and adventurous spirit of a fantasy game
V:1 clef=treble name="Lead - Harp"
%%MIDI program 46
|:"G" B3A G2 | "D/F#" F4 A2 | "Em" E4 G2 | "C" G3E "D" F2 |
"G" D4 B,2 | "C" G,4 E2 | "D" A4 F2 |1 "G" G4 z2 :|2 "G" G4 "D"C2 |
|:"D" D3E F2 | "Bm" A4 d2 | "G" G4 B2 | "Em" B3A "A7" G2 |
"D" F4 A2 | "G" G3E D2 | "C" C3B, "D" A,2 |1 "D" D4 "D7"C2 :|2 "D" D4 "A7"c2 |
"Em" E4 E2 | "Bm" B3A "A7" G2 | "G" G4 B2 | "D7" A4 B2 |
"G" B3A G2 | "C" E3D C2 | "G/B" D3E "D" F2 | "G" G4 z2 |]
V:2 clef=treble name="Strings Ensemble"
%%MIDI program 48
|:"G" d4 B2 | "D/F#" A4 F2 | "Em" G4 e2 | "C" E3C A2 |
"G" B4 g2 | "C" e4 c2 | "D" f4 c'2 |1 "G" d4 z2 :|2 "G" d4 A2 |
|:"D" F4 F2 | "Bm" B4 d2 | "G" D4 D2 | "Em" B4 B2 |
"D" A4 A2 | "G" G3E D2 | "C"C4 C2 |1 "D" F4 E2 :|2 "D" F4 e2 |
"Em" E4 B,2 | "Bm" G4 G2 | "G" D4 D2 | "D7" A4 A2 |
"G" B4 G2 | "C" E3C "A7" A2 | "G/B" D3E "D" F2 | "G" G4 z2 |]
V:3 clef=bass name="Bass - Cello"
%%MIDI program 42
|:"G" G,4 D2 | "D/F#" F,4 A,2 | "Em" E,4 G,2 | "C" C4 E2 |
"G" D,4 G,2 | "C" C,4 E,2 | "D" A,4 D2 |1 "G" G,4 z2 :|2 "G" G,4 B,2 |
|:"D" D,4 F2 | "Bm" B,2 F2 z2 | "G" G,4 D2 | "Em" E,4 G,2 |
"D" A,4 F,2 | "G" G,4 D2 | "C" C,4 E,2 |1 "D" D,4 F,2 :|2 "D" D,4 A,2 |
"Em" E,4 G,2 | "Bm" B,4 B,2 | "G" G,4 D2 | "D7" A,4 F,2 |
"G" G,4 D2 | "C" C,4 C2 | "G/B" D,4 D2 | "G" G,4 z2 |]



X:1
T:Ephemeral Breezes
C:OrchestrAI
M:6/8
L:1/8
Q:1/4=120
K:C
% The introduction captures a gentle breeze, leading into more dynamic themes
V:1 clef=treble name="Clarinet"
%%MIDI program 71
|:"C" E2 E2 e2 d2 B2 | "G" G3 A3 B2 (3c3B3 | "Am" A2F2 E4 | "G" B3d d2 B2 A2 |
% Development section introduces contrasting material
V:2 clef=treble name="Bassoon"
%%MIDI program 70
|:"C" c2G2 E2C2 | "F" A2c2 f4 | "C" g3f e2 d2 B2 | "G" G3d e4 |
% Melodic interplay with clarinet
V:3 clef=alto name="Viola"
%%MIDI program 40
|:"E" E2B2 c2A2 | "C" E3C E2D2 | "A" A2E2 F2D2 | "G" B3d (3B2c2B2 |
% Strong bass line providing harmonic foundation
V:4 clef=bass name="Acoustic Bass"
%%MIDI program 32
|:"C" C,2E,2 C,3E, | "F" A,,2F,2 F,3A, | "C" C2E,2 G,3C, | "G" G,,2B,2 G,3B, |
% The conclusion recalls themes with variations
V:1
|:"C" E2 e2 c2 B2 d2 | "G" B2 A2 G3E | "Am" A2F2 E4 | "C" C4 z2 C2 |
% Resolve with a gentle finish
V:2
|:"C" c3g c2e2 | "F" A3F (3G2A2B | "C" E4 C2e2 | "G" B2 G3G |
V:3
|:"E" E2B2 A2F2 | "C" E3C z2D2 | "A" A2E2 C2E2 | "G" G4 C2  |
V:4
|:"C" C,2E,2 C,4 | "F" F,,2A,2 F,3A, | "C" C2E,2 G,3B, | "C" C,,2E,2 C,3C, |



X:1
T:Thalbergian Rush
C:OrchestrAI
M:C
L:1/16
Q:1/4=300
K:G
V:1 clef=treble
%%MIDI program 1
|:"G"d2B2 G2F2 E4D4|"C"E2c2 G2E2 D8|d4c4B4A4|G6F2 E4D4|
"G"B2d2 d2B2 d4c4|B2d2 d2B2 A8|"D7"d2f2 f2d2 f4e4|f2a2 a4 g8|
"G"g2B2 d2g2 b4a4|g4f4 e6z2|g2f2 e2d2 c4B4|"Am"A2c2 e2a2 c8|
"D7"f2A2 d2f2 a4g4|f6e2 d4c4|"G"G2B4G2 F4E4|D8 G,4G,4:|



X:1
T:Cornfield Dance
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:G
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G" G2B2d2 | "C" c4B2 | "D" A3G FE | "G" D6 |
"G" G2B2d2 | "C" c4e2 | "D" d3c BA | "G" G6 |
"C" e2e2e2 | "G" d3B AG | "Am" A3B cd | "D7" e6 |
"G" d2g4 | "Em" e2a4 | "Bm" B2d4 | "D7" A4G2 :|
V:2 clef=alto name="Guitar"
%%MIDI program 24
|:"G" D2G2B2 | "C" E3D EC | "D" FD G2F2 | "G" D4D2 |
"G" D2G2B2 | "C" E3G EG | "D"A2F2G2 | "G" D4D2 |
"C" G2G2G2 | "G" D2G2D2 | "Am" A2B2c2 | "D7" F4E2 |
"G" D2G2B2 | "Em" E2G2B2 | "Bm" F24 z | "D7" A4G2 :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"G" G,2B,2D2 | "C" C4E2 | "D" F4D2 | "G" G,4D2 |
"G" G,2B,2D2 | "C" C4C2 | "D" A,4F2 | "G" G,6 |
"C" C3C E2 | "G" G,3B, D2 | "Am" A,2C2E2 | "D7" A,4F2 |
"G" G,2B,2D2 | "Em" E,4G2 | "Bm" B,4D2 | "D7" F4D2 :|



X:1
T:Rhapsody of AI
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=88
K:Cmaj
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "C"E2 G4 c2 | "Am"d2 c4 B2 | "F"A4 G2 F2 | "G"G6 z2 |
"C"E2 G4 c2 | "Am"d2 c4 B2 | "F"A6 G2 | "G"E4 D2 C2 :|
V:2 clef=treble name="Clarinet"
%%MIDI program 71
|: "C" C6 E2 | "Am" A,4 A,2 D2 | "F" F4 G2 A2 | "G" B,4 C2 D2 |
"C" C6 E2 | "Am" A,4 A,2 D2 | "F" F6 G2 | "G" E4 C2 G,2 :|
V:3 clef=treble name="Violin I"
%%MIDI program 40
|: "C" e4 f2 g2 | "Am" a2 g2 f2 e2 | "F" d2 cB z4 | "G" e4 d2 cB |
"C" c2 e2 f2 g2 | "Am" a2 g2 f2 e2 | "F" f2 e2 d2 c2 | "G" B4 c2 d2 :|
V:4 clef=treble name="Violin II"
%%MIDI program 40
|: "C" G2 E2 C2 G,2 | "Am" A,2 C2 E2 A,2 | "F" F4 G2 A2 | "G" G4 F2 E2 |
"C" G2 E2 C2 G,2 | "Am" A,2 C2 E2 A,2 | "F" F4 E2 A,2 | "G" G4 F2 E2 :|
V:5 clef=bass name="Cello"
%%MIDI program 42
|: "C" C,2 C2 E2 G,2 | "Am" A,2 A,2 C2 E2 | "F" F,4 A,2 F,2 | "G" G,2 B,,2 D2 G,2 |
"C" C,2 C2 E2 G,2 | "Am" A,2 A,2 C2 E2 | "F" F,4 A,2 F,2 | "G" G,4 F,2 E,2 :|
V:6 clef=treble name="Piano Right"
%%MIDI program 0
|: "C" e2 G4 c2 | "Am" f2 e4 d2 | "F" c4 B2 A2 | "G" B2 G2 ^F2 G2 |
"C" e2 G4 c2 | "Am" f2 e4 d2 | "F" c6 B2 | "G" e4 c2 G2 :|
V:7 clef=bass name="Piano Left"
%%MIDI program 0
|: "C" C,2 E2 G2 C2 | "Am" A,2 C2 E2 A,2 | "F" F,2 A,2 c2 F2 | "G" D2 B,,2 G,,2 D2 |
"C" C,2 E2 G2 C2 | "Am" A,2 C2 E2 A,2 | "F" F,2 A,2 c2 F2 | "G" G,4 F,2 D,2 :|



X:1
T:Victorious Fanfare
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=132
K:Cmaj
V:1 clef=treble
%%MIDI program 56
|:"C"G4- G2E2|"G"D6 G2|"Am"E4 F2G2|"F"A6 c2|
"C"G4- G2E2|"G"D6 G2|"F"E2F2 G3 A|"C"C8:|
"E7"G3 A B2G2|"A"A6 B2|"Dm"D4 F2A2|"G7"B6 d2|
"Em"G4 E2G2|"Am"c2B2 A2B2|"Dm"F2E2 D3 C|"G7"B,8|
"C"G,4 C2E2|"G"D6 G2|"F"A4 c2e2|"C"c8|
"C"G4- G2E2|"G"D6 G2|"Am"E2F2 G3 A|"C"C8||
V:2 clef=treble
%%MIDI program 48
|:"C"e4- e2c2|"G"B6 e2|"Am"A4 c2d2|"F"c6 e2|
"C"e4- e2c2|"G"B6 e2|"F"c2d2 e3 f|"C"c8:|
"E7"g4 g2f2|"A"a6 c'2|"Dm" f4 a2f2|"G7"d2d2 B3 A|
"Em"g4 e2d2|"Am"A2 G2 z EG2|"Dm"d2c2 F3 E|"G7"D6 B,2|
"C"E4 G2E2|"G"B6 e2|"F"d2f2 a2g2|"C"G4 G4|
"C"E4- E2C2|"G"B6 e2|"Am"A2G2 A3 B|"C"c8||
V:3 clef=bass
%%MIDI program 42
|:"C"C4- C2C2|"G"G,4 D2D2|"Am"A,4 E2A2|"F"C4 C4|
"C"E4- E2E2|"G"B,4 D2D2|"F"A,2A,2 C3 E|"C"C6 C2:|
"E7"B,4 _B,2_B,2|"A"C4 E2A,2|"Dm"A,4 D2D2|"G7" G,4 D2G,2|
"Em"E4 E2E2|"Am"C4 C2C2|"Dm"F4 A,2D2|"G7"B,6 F,2|
"C"C4 E2C2|"G"G,4 D2D2|"F"A,4 F2C2|"C" C4 C4|
"C"G,4- G2E2|"G"D6 D2|"Am"E2E2 G3 A|"C"C8||



X:1
T:Yearning Hearts Anthem
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Cmaj
% The Trumpet will handle the melody, expressing the yearning theme of the anthem
V:1 clef=treble
%%MIDI program 56
|: "C"E2 G2 c3 B | A4 G4 | F2 E2 D2 C2 | G4 ^F4 |
E2 G2 c3 B | A4 G4 | "G7"F2 E2 D3 E | "C"C8 :|
V:2 clef=treble
%%MIDI program 57
|: "C"C2 F2 A2 G2 | F4 E4 | D2 C2 G,2 A,2 | F4 G4 |
C2 F2 A2 G2 | F4 E4 | "G7"G2 F2 E3 F | "C"C8 :|
V:3 clef=bass
%%MIDI program 58
|: "C"C,4 G,4 | E,4 C4 | C,2 D,2 E,2 F,2 | G,2 A,2 G,4 |
C,4 G,4 | E,4 C4 | "G7"D2 E2 F2 G2 | "C"C,8 :|



X:1
T:Synth Fusion Fantasia
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=120
K:Cmaj
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|: "C"e2c2G2e2 c4B4 | "Am"a2e2A2c2 e4c4 | "F"f2d2C2f2 a4g4 | "G"B2d2G2B2 d8 |
"C"e2c2G2e2 c4B4 | "Am"a2e2A2e2 c4e4 | "F"fdz2cdz2 z8 | "G"g8- g6z2 |
"C"e2^G2A2B2 c8 | "Fmaj7"e2f2g2a2 f8 | "Dm7"d2c2B2A2 G8 | "Gsus4"G4A4 B4c4 |
"C"e2c2e2g2 a2g2e2c2 | "Am"a2f2a2c2 e4A4 | "Fmaj7"fdz2cdz2 z8 | "G"g8- g6a2 |
"C"e2^G2A2B2 c8 | "Fmaj7"agf2e2d2 c8 | "Dm7"A2d2F2A2 z8 | "G"B2G2A2F2 G8 :|
V:2 clef=treble name="Arpeggio Synth"
%%MIDI program 82
|: "C"ceGceG ceGceG z4 | "Am"aeAaeA aeAaeA z4 | "F"fdCfdC fdCfdC z4 | "G"BGDBGD BGDBGD z4 |
"C"ceGceG ceGceG z4 | "Am"aeAaeA aeAaeA z4 | "F"fdcfdc | "G"gbggbg |
"C"e^GA^GAB cBGABc z4 | "Fmaj7"efgafg efgafg z4 | "Dm7"dABcBA GFEDCB, z4 | "Gsus4"GABcdB cdBcdB z4 |
"C"ceGceg agfecG z4 | "Am"afAafA aeAcA z5 | "Fmaj7"fdcfdc | "G"gbgagf |
"C"e^GA^GAB cBGABc z4 | "Fmaj7"agfeDC BAGFED z4 | "Dm7"AdFAF^D | "G"BAGFED G4 z6 :|
V:3 clef=bass name="Bass Synth"
%%MIDI program 38
|: "C"C,8- C,6C2 | "Am"A,8- A,6A2 | "F"F,8- F,6F2 | "G"G,8- G,6G2 |
"C"E,8- E,6E2 | "Am"A,8- A,6C2 | "F"F,6F2 C,4 z4 | "G"G,8- G,6D2|
"C"C,4E,4G,4 C,4 | "Fmaj7"F,8- F,6A,2 | "Dm7"D,8- D,6A,2 | "Gsus4"G,8- G,6B,2|
"C"E,6G,2E,6G,2 | "Am"A,6C2A,6C2| "Fmaj7"F,6 F2 C,4 z4 | "G"G,8- G,6G2 |
"C"C,4E,4G,4 C,4 | "Fmaj7"F,4A,4C4 F,4 | "Dm7"D,4F,4A,4 D,4 | "G"G,4D,4G,4 G,4 :|



X:1
T:Whispers of the Old Forest
C:OrchestrAI
M:6/8
L:1/8
Q:3/8=60
K:Am
V:1 clef=treble
%% MIDI program 74
|: "Am"A2 z e2 z | "C"G3 F2 E | "Dm"D2 z F2 A | "Am"E4 z2 |
"Am"A2 z "E7"c3 | "Am"B2 G A2 z | "E7"G2 E F3 | "Am"A6 :|
"Am"A3 E2 A | "F"FGA FED | "C"E3 G2 E | "G"GBG EFG |
"Am"A2 z e2 z | "C"G3 F2 E | "Dm"D2 z F2 A | "Am"E6 |]
V:2 clef=treble
%% MIDI program 74
|: "Am"e2 z c2 z | "C"=B3 A2 =B | "Dm"F2 z A2 c | "Am"c4 z2 |
"Am"c2 z "E7"=B3 | "Am"A2 E c2 z | "E7"=B2 =B A3 | "Am"A6 :|
"Am"A3 c2 A | "F"F2 A F2 E | "C"E3 G2 E | "G"=B3 G2 E |
"Am"c2 z e2 z | "C"=B3 A2 =B | "Dm"F2 z A2 c | "Am"c6 |]
V:3 clef=bass
%% MIDI program 70
|: "Am"A,6 | "C"C,6 | "Dm"D,6 | "Am"A,6 |
"Am"A,3 A,3 | "E7"B,3 E,3 | "Am"A,6 | "Am"E,6 :|
"Am"A,3 E,3 | "F"F,3 C,3 | "C"C,3 G,3 | "G"G,3 D,3 |
"Am"A,6 | "C"C,6 | "Dm"D,6 | "Am"A,6 |]



X:1
T:Springtime Sonata
C:OrchestrAI
M:6/8
L:1/8
Q:1/4=120
K:G
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "G" D2G G2A | BAG "D7" A2F | "Em" G2E E2F | "C" EDC "D7" D2F |
"G" G2B d2c | "D7" BAG A2d | "Em" e2d "Am" c2B | "D7" AGF "G" G3 :|
|: "C" e2e "G" d2d | "D" c2A "G" B2G | "Em" B2B "Bm" A2A | "C" G2E "D7" F2D |
"G" G2A B2c | "D7" d2e f2d | "C" e2c "Am" a2g | "D7" fed "G" g3 :|
V:2 clef=treble name="Violin"
%%MIDI program 40
|: "G" B,2D D2F | GAB "D7" F2D | "Em" E2G G2A | "C" GFE "D7" F2A |
"G" B2d g2a | "D7" gfe f2a | "Em" g2f "Am" e2d | "D7" cBA "G" B3 :|
|: "C" g2g "G" f2f | "D" e2c "G" d2B | "Em" d2d "Bm" c2c | "C" B2G "D7" A2F |
"G" B2c d2e | "D7" f2g a2f | "C" g2e "Am" c'2b | "D7" agf "G" g3 :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "G" G,3 B,2D | G,2B, "D7" D,2F, | "Em" E,3 G,2B, | "C" C,2E, "D7" D,2F, |
"G" G,2B, D2G, | "D7" D,2F, A,2D, | "Em" E,2G, "Am" A,2C | "D7" D,2F, "G" G,,3 :|
|: "C" C,2E, "G" G,2B, | "D" D,2F, "G" G,2B, | "Em" E,2G, "Bm" B,,2D, | "C" C,2E, "D7" D,2F, |
"G" G,2B, D2G, | "D7" D,2F, A,2D, | "C" C,2E, "Am" A,2C | "D7" D,2F, "G" G,,3 :|



X:1
T:The Pride of a Triumph Earned
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:G
V:1 name="Soprano" clef=treble
%%MIDI program 53
|:"G"D2 G4 F2|E2 D4 z2|"C"G2 E4 C2|D2 C4 z2|
"D"A2 F4 D2|"G"B,2 G,4 E2|"C"G2 E4 C2|"D"D6 z2|
"G"B2 d4 B2|A2 G4 F2|"Em"B2 e4 d2|c2 B4 A2|
"G"G2 B4 d2|"C"e2 c4 A2|"G"G2 F4 D2|"G"G,6 z2:|
V:2 name="Alto" clef=treble
%%MIDI program 53
|:"G"B,2 D4 C2|B,2 A,4 z2|"C"E2 C4 E2|D2 C4 z2|
"D"A,2 D4 F2|G,2 B,4 z2|"C"E2 C4 E2|"D"D4 F2 A2|
"G"D2 G4 F2|E2 D4 C2|"Em"E2 G4 F2|D2 C4 B,2|
"G"B,2 D4 G2|"C"C2 E4 C2|"G"B,2 D4 G2|"G"G,6 z2:|
V:3 name="Tenor" clef=bass
%%MIDI program 52
|:"G"G,2 B,4 A,2|G,2 F,4 z2|"C"C2 G,4 E,2|F,2 E,4 z2|
"D"A,2 D4 A,2|B,,2 G,4 E,2|"C"C2 A,4 E,2|"D"D4 F,2 A,2|
"G"G,2 B,4 A,2|G,2 F,4 E,2|"Em"E,2 G,4 F,2|D,2 C,4 z B,|
"G"G,2 B,4 d2|"C"E2 C4 A,2|"G"G,2 B,4 G,2|"G"G,6 z2:|
V:4 name="Bass" clef=bass
%%MIDI program 43
|:"G"G,,2 B,,4 D,2|C,2 B,,4 z2|"C"C,2 E,4 G,2|A,2 G,4 z2|
"D"D,2 F,4 A,2|G,2 B,,4 z2|"C"C,2 E,4 G,2|"D"D4 F,2 A,2|
"G"G,2 B,,4 D,2|C,2 B,,4 C,2|"Em"E,2 G,4 B,2|A,2 G,4 z F,|
"G"G,2 D4 G,2|"C"C,2 E,4 G,2|"G"B,,2 D,4 G,2|"G"G,,6 z2:|



X:1
T:Meadow Dance
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=120
K:G
V:1 clef=treble name="Flute"
%%MIDI program 73
|:"G" G4 B2d2 | "D7" d2F2 A2F2 | "C" c2E4 e2 | "D" d4 F2A2 |
"G" B2G2 "C" E2c2 | "G" D2G2 "D7" A,2F2 | "G" G6 z2 :|
"D7" F2A2 c2d2 | "G" B6 z2 | "Em" e2f2 g2a2 | "A7" a6 z2 |
"D7" f4 d2e2 | "G" g2B2 "A7" d2c2 | "D" B2d2 "G" G4 :|
V:2 clef=treble name="Violin"
%%MIDI program 40
|:G,4 B,2d2 | d2F2 A2d2 | c2E4 G2 | d4 F2A2 |
B,2G,2 E2c2 | D4 A,2F2 | G,6 z2 :|
F2A2 c2d2 | B6 z2 | e2f2 g2a2 | a6 z2 |
f4 d2e2 | g2B2 d2c2 | B2A2 G4 :|
V:3 clef=alto name="Viola"
%%MIDI program 41
|:"C" c4 e2g2 | "G" g2B2 d2B2 | "Am" a2c4 a2 | "D7" f4 a2b2 |
"C" e2c2 "G" B2d2 | "C" e4 "G" B2G2 | "C" c6 z2 :|
"D7" a2f2 a2b2 | "C" g6 z2 | "Am" c2d2 c2e2 | "D7" f6 z2 |
"G" d4 f2g2 | "C" e2c2 "D7" f2e2 | "G" d2B2 "C" c4 :|
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"G" |:G,4 B,2d2 | "D7" D4 F2A2 | "C" C6 C2 | "D" D4 F2A2 |
"G" G,4 B,2G,2 | "C" C4 "D7" A,2F2 | "G" G,6 z2 :|
"D7" A,4 c2d2 | "G" G,6 z2 | "Em" E4 G2A2 | "A7" A,6 z2 |
"D7" F4 A2B2 | "G" B,2D2 "A7" A,2E2 | "D" F4 "G" G,4 :|



X:1
T:Longing Echoes
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Cmaj
% The piece kicks off with a memorable trumpet melody
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|:"C" G4- G4 | A4 G2 E2 | "F" F4- F4 | E4 D2 C2 |
"G" G4- G4 | A4 G2 E2 | "Am" A4 "G" G2 z2 |1 "F" F6 E2 :|2 "F" F6 z2 |
% The trombone provides mid-range harmony and rhythm
V:2 clef=treble-8 name="Trombone"
%%MIDI program 57
|:"C" E4 E4 | F4 E2 C2 | "F" A,4 A,4 | G,4 F,2 E,2 |
"G" E4 E4 | F4 E2 C2 | "Am" E4 "G" D2 z2 |1 "F" C4 E2 F2 :|2 "F" C4 C5  |
% The tuba offers the bass foundation
V:3 clef=bass name="Tuba"
%%MIDI program 58
|:"C" C,4 C,4 | C,4 C,2 C,2 | "F" F,,4 F,,4 | F,,4 F,,2 C,2 |
"G" G,,4 G,,4 | G,,4 G,,2 E,2 | "Am" A,,4 "G" G,,2 z2 |1 "F" F,,6 C,2 :|2 "F" F,,6 z2 |
% Back to the trumpet for the second part, introducing a countermelody in the trombone
[V:1]
|:"C"G3 A B2 G2 | "F"A4 G2 F2 | "C" E4 G2 E2 | "G" D4- D5  |
"Am" c4 e2 c2 | "F" G2 A2 B2 G2 | "C"G3 A B2 A2 | "G"G6 z2 :|
[V:2]
|:"C" c6 c2 | "F"d4 c2 A2 | "C" G4 c2 G2 | "G" B,4 c2 d2 |
"Am" e4 c2 A2 | "F" G2 A2 c2 d2 | "C" e4 c2 A2 | "G" G4 z4 :|
[V:3]
|:"C" C,2 E,2 G,,2 G,2 | "F" A,,2 C,2 F,,2 F,2 | "C" E,2 E,2 C,2 C,2 | "G" G,,2 B,,2 G,,2 G,2 |
"Am" A,,2 c2 e2 e2 | "F" f,,2 a,2 c'2 c'2 | "C" G,,2 B,,2 E,2 E,2 | "G" G,,4 z4 :|



X:1
T:Ode to the Cosmos
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=50
K:Emaj
V:1 clef=treble
%%MIDI program 48
|: "Eadd9"B,6 | B,3 (E G) F2 | "Badd9"D6 | D3 (F A) B2 |
"Amaj7"C4 (E A) | "G#m7" G3 (B e) g2 | "C#m7"B3 e d2 | "F#m11"A6 |
"Eadd9"B,3 E G2 | "Badd9"D3 F A2 | "Amaj7"C4 E2 | "G#m7"G3 B e2 |
"C#m7"g2 f2 e2 | "F#m11"A3 G F2 | "Badd9"D6 |1 "E"E6 :|2 "E"E4 z2 |]
V:2 clef=alto
%%MIDI program 49
|: "Eadd9"e6 | e3 g# b2 | "Badd9"f6 | f3 a d3 |
"Amaj7"e4 g# b | "G#m7"g3 b e2 | "C#m7"f2 e2 d2 | "F#m11"f6 |
"Eadd9"e3 g# b2 | "Badd9"f3 a d2 | "Amaj7"E4 A2 | "G#m7"g3 b2 e2 |
"C#m7"e2 d2 c2 | "F#m11"a3 g f2 | "Badd9"d6 |1 "E"e6 :|2 "E"e4 z2 |]
V:3 clef=tenor
%%MIDI program 50
|: "Eadd9"G,6 | G,3 B, E2 | "Badd9"A,6 | A,3 C F2 |
"Amaj7"G,4 B, E | "G#m7"F3 A C2 | "C#m7"E3 A B2 | "F#m11"D6 |
"Eadd9"G,3 B, E2 | "Badd9"A,3 C F2 | "Amaj7"G,4 B,2 | "G#m7"E2 G, B,2 |
"C#m7"A,2 B,2 A,2 | "F#m11"D3 C B,2 | "Badd9"A,6 |1 "E"E6 :|2 "E"E4 z2 |]
V:4 clef=bass
%%MIDI program 42
|: "Eadd9"E,6 | E,3 A, C2 | "Badd9"F,6 | F,3 B, D2 |
"Amaj7"E3 A, C2 | "G#m7"F2 E, G,2 | "C#m7"B,,2 E,2 F,2 | "F#m11"F,,6 |
"Eadd9"E,3 A, C2 | "Badd9"F,3 B, D2 | "Amaj7"E,6 | "G#m7"F2 F, A,2 |
"C#m7"B,,2 E,2 F,2 | "F#m11"F,,6 | "Badd9"F,6 |1 "E"E,6 :|2 "E"E,6 z2 |]



X:1
T:Epic Boss Battle Anthem
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=176
K:Cmin
% Lead synth
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"Cm"G4 E2C2 D4 z8 | G4 E2C2 D4 G,4 E4 | "Fm"F4 D2A2 B4 z8 z8 | F4 D2A2 B4 F,4 D4 |
"Bb"B4 G2D2 C4 z8 | B4 G2D2 C4 B,4 G4 | "Ab" E4 C2G2 A4 z8 | E4 C2G2 A4 E,4 C4 |
"Cm"G4 E2C2 D4 z4 G,4 | "Fm"F4 D2A2 B4 z4 F,4 | "Bb"B4 G2D2 C4 z4 B,4 | "G"E4 C2G2 A4 z4 E,4 |
"Cm"G2E2C2 D2E2G2 A2B2c2 z2 | d4 z4 d4 z4 z4 | "G"G2D2F2 G2A2B2 =d2^f2g2 z2 | a4 z4 a4 z4 z4 |
"Eb"e2c2=B2 c2d2e2 f2g2=B2 z2 | =B4 z4 =B4 z4 z4 | "Ab"a4 f4 e=dcAF G2=C2 z3 | "G"G,4 G,2F2 G4 z8 :|
% Choir "aahs"
V:2 clef=treble name="Choir Aahs"
%%MIDI program 52
|:"Cm" g4 e2c2 d4 z8 z8 | g4 e2c2 d4 g,4 e4 | "Fm" f4 d2a2 b4 z8 | f4 d2a2 b4 f,4 d4 |
"Bb" b4 g2d2 c4 z8 | b4 g2d2 c4 b,4 g4 | "Ab" e4 c2g2 a4 z8 z8 | e4 c2g2 a4 e,4 c4 |
"Cm" g4 e2c2 d4 z4 g,4 | "Fm" f4 d2a2 b4 z4 f,4 | "Bb" b4 g2d2 c4 z4 b,4 | "G" e4 c2g2 a4 z4 e,4 |
"Cm" g2e2c2 d2e2g2 a2b2c'2 z2 | d4 z8 | "G" g2d2f2 g2a2b2 d'2f2g2 z2 | a4 z8 |
"Eb" e2c2B2 c2d2e2 f2g2B2 z2 | B4 z8 | "Ab" a4 f4 e=c'af g2c2 z4 | "G" g,4 g2f2 g8 z4 :|
% Bass synth and drums
V:3 clef=bass name="Bass Synth"
%%MIDI program 87
|:"Cm"C,8 C,2G,,2 C,8 | C,8 C,2G,,2 C,8 | "Fm"F,8 F,2C,2 F,8 | F,8 F,2C,2 F,8 |
"Bb"B,,8 B,,2F,,2 B,,8 | B,,8 B,,2F,,2 B,,8 | "Ab"Ab,,8 Ab,,2E,,2 Ab,,5 | Ab,,8 Ab,,2E,,2 Ab,,5 |
"Cm"C,8 C,2G,,2 C,8 | "Fm"F,8 F,2C,2 F,8 | "Bb"B,,8 B,,2F,,2 B,,8 | "G"G,,8 G,,2D,,2 G,,8 |
"Cm"C,4 C,2C,2 C,8 z4 | C,8 C,8 z4 | "G"G,,4 G,,2G,,2 G,,8 z4 | G,,8 G,,8 z4 |
"Eb"Eb,,8 Eb,,2B,,2 Eb,,5 | Eb,,8 Eb,,8 z2 | "Ab"Ab,,4 Ab,,2F,,2 Ab,,8 z | "G"G,,8 G,,4 z8 :|



X:1
T:Bandstand Groove
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:F
V:1 clef=treble name="Saxophone"
%%MIDI program 65
|:"F" c2 A F2 G2 z | A2 G E2 C2 z | "C7" G,2 E, D,4 z | "F" F4 z4 |
"F" c2 A F2 G2 z | A2 G E2 C2 z | "C7" G,2 E, C2 G,2 z |1 "Bb" B,8 :|2 "Bb" B,4 A,4 |
|:"Dm" A4 FG A2 | "Gm" B4 AG B2 | "C7" c2 cG E4- | E4 z2 E2 |
"F/A" A4 F2 GA | "Bb" d6 ed | "C7" G2 E2 C2 G,2 |1 "F" F8 :|2 "F" F4 z4 |
V:2 clef=treble name="Trumpet"
%%MIDI program 56
|:"F" f4 A4 | G4 E4 | "C7" E2 G2 E4 | "F" c4 z4 |
"F" f4 A4 | G4 E4 | "C7" E2 G2 E4 |1 "Bb" B,4 c4 :|2 "Bb" B,4 A4 |
|:"Dm" A2 B2 c3 d | "Gm" d3 c (3BAG z4 | "C7" c4 E4 | "F/A" (3AGF E4 z4 |
"F" f3 g a2 (3gfe z2 | "Bb" d4 (3dcB A2 z2 | "C7" G2 E2 C4 |1 "F" F8 :|2 "F" F4 z4 |
V:3 clef=bass name="Bass Guitar"
%%MIDI program 33
|:"F" F,4 C4 | F,4 A,4 | "C7" C,2 E,2 C4 | "F" F,4 C4 |
"F" F,4 C4 | F,4 A,4 | "C7" C,2 E,2 C4 |1 "Bb" B,,4 F,4 :|2 "Bb" B,,4 G,4 |
|:"Dm" A,4 D4 | "Gm" G,4 B,4 | "C7" C,4 E,4 | "F/A" A,4 C4 |
"F" F,4 A,4 | "Bb" B,,4 F,4 | "C7" C,2 E,2 C4 |1 "F" F,8 :|2 "F" F,4 C4 |



X:1
T:Anime Odyssey Theme
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=172
K:Em
%%staves [(V1 V2) (V3 V4)]
V:1 clef=treble name="Synth Lead" subname="Lead"
%%MIDI program 81
[V:1]
|:
"Emin" B,4 E4 G  | "D" D8 F  | "C" G,4 C4 E  | "B" B,3 F B3 D F  |
"Emin" B,4 E4 G  | "D" A,4 D4 F  | "C" E,8 G  | "B" B,6 A A,2   |
"Emin" [E4B4][G4B4][E5] | "D" [D4F4][A4F4][D5] | "C" [C4E4][G4E4][C5] | "B" [B,3F3][B3D3][F4G2] |
"Emin" [B,4E4][G4E4][B,5] | "D" [A,4D4][F4D4][A,5] | "C" [E,4C4][G4C4][E5] | "B" [B,6A4][A,3F3B2] |]
V:2 clef=treble name="Synth Strings" subname="Strings"
%%MIDI program 50
[V:2]
|:
"Emin" E8-| "D" D8-| "C" C8- | "B" B8- |
"Emin" E8- | "D" D8- | "C" C8- | "B" B8- |
"Emin" E4 E4 E  | "D" D4 D4 D  | "C" C4 C4 C  | "B" B4 B4 B  |
"Emin" E4 E4 E  | "D" D4 D4 D  | "C" C4 C4 C  | "B" B4 B4 B  |]
V:3 clef=bass name="Electric Bass" subname="Bass"
%%MIDI program 33
[V:3]
|:
"Emin" E,8-| "D" D,8-| "C" C,8- | "B" B,8- |
"Emin" E,8- | "D" D,8- | "C" C,8- | "B" B,8- |
"Emin" [E,4G,4][B,4E4] | "D" [D,4F,4][A,4D4] | "C" [C,4E,4][G,4C4] | "B" [B,,4F,4][B,4D4] |
"Emin" [E,4G,4][B,4E4] | "D" [D,4F,4][A,4D4] | "C" [C,4E,4][G,4C4] | "B" [B,,4F,4][B,4D4] |]
V:4 clef=perc name="Power Drums" subname="Drums"
%%MIDI program 0
[V:4]
|:
"Emin" z8-| "D" z8-| "C" z8- | "B" z8- |
"Emin" z8- | "D" z8- | "C" z8- | "B" z8- |
"Emin" z4 z4 z  | "D" z4 z4 z  | "C" z4 z4 z  | "B" z4 z4 z  |
"Emin" z4 z4 z  | "D" z4 z4 z  | "C" z4 z4 z  | "B" z4 z4 z  |]



X:1
T:Pirate Polka Shanty
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=120
K:Gm
V:1 clef=treble name="Fiddle"
%%MIDI program 110
D2 |:"Gm"G4 BAGF | "D7"AGFE D2F2 | "Gm"GABc "D7"d2cB | "Gm"A2G2 G2 ::
Bc |:"Gm"d2g2 f2g2 | a2b2 g2fe | "D7"f2gf e2dc | "Cm"B2c2 "D7"A2Bc |
"Gm"G2B2 A2G2 | "D7"F4 E2D2 | "Gm"GABc "D7"d2cB | "Gm"A2G2 G2 z2 :|
V:2 clef=treble name="Accordion"
%%MIDI program 21
D2 |:"Gm"g4 g2f2 | "D7"f2e2 d2D2 | "Gm"g2g2 "D7"f2e2 | "Gm"d4 G2 ::
Bc |:"Gm"d3 e d2c2 | B3 c B2A2 | "D7"A2G2 F2E2 | "Cm"G4 "D7"A2Bc |
"Gm"d2g2 f2e2 | "D7"d2c2 B2A2 | "Gm"g2f2 "D7"e2d2 | "Gm"d4 G2 z2 :|
V:3 clef=bass name="Tuba"
%%MIDI program 58
D2 |:"Gm"G,4 D2G,2 | "D7"A,4 D2A,2 | "Gm"G,2G,2 "D7"A,2A,2 | "Gm"D4 G,2 ::
B,2 |:"Gm"D,4 D,2D,2 | G,4 G,2G,2 | "D7"A,4 A,2A,2 | "Cm"G,4 "D7"A,2B,2 |
"Gm"D4 G4 | "D7"D4 A,4 | "Gm"G,2G,2 "D7"A,2A,2 | "Gm"D4 G,2 z2 :|



X:1
T:Majestic Anthem of the Brave
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:Eb
V:1 clef=treble name="Trumpet 1"
%%MIDI program 56
|:"Eb"B4 B2G2 | G4 F4 | "Ab"E4 E2C2 | C6 z2 |
"Bb"F4 F2D2 | "Eb"D4 "Bb"D2F2 | "Eb"G4 "Cm"G2B2 | "Bb"B6 z2 |
"Eb"B4 "Ab"e4 | c4 "Fm"c2A2 | "Bb"F4 "Eb"D4 | "Cm"G6 z2 |
"Ab"E4 "Eb"G4 | "Bb"F4 "Eb"D4 | "Bb"F2E2 "Fm"D2C2 | "Bb"B,6 z2:|
V:2 clef=treble name="Trumpet 2"
%%MIDI program 56
|:"Eb"G2F2 E4 | G4 F4 | "Ab"C2B,2 A,4 | C4 B,4 |
"Bb"D4 "Eb"C4 | "Bb"D4 "Eb"D2B,2 | "Cm"G2F2 E4 | "Bb"D4 C4 |
"Eb"E4 "Ab"E4 | "Fm"F4 F2A2 | "Bb"D4 "Eb"C4 | "Cm"C6 z2 |
"Ab"A,4 "Eb"C4 | "Bb"B,4 G,4 | "Eb"G,2F,2 "Fm"E,2D,2 | "Bb"B,,6 z2:|
V:3 clef=treble name="Horn"
%%MIDI program 61
|:"Eb" E2G2 B,4 | E2G2 F4 | "Ab"C2E2 A,4 | C2E2 C4 |
"Bb"B,2D2 G,4 | "Eb"B,2D2 "Bb"D4 | "Eb"G2B2 "Cm"E4 | "Bb"D4 B,4 |
"Eb"E2G2 "Ab"c4 | "Fm"A4 "Bb"F4 | "Eb"D2F2 "Cm"B,4 | "Ab"C6 z2 |
"Ab"A,4 "Eb"E4 | "Bb"B,2D2 "Eb"G4 | "Fm"F2E2 D2C2 | "Bb"B,,6 z2:|
V:4 clef=bass name="Trombone"
%%MIDI program 58
|:"Eb" E3G B,3E | E2G2 F4 | "Ab"A,2C2 C4 | A,3C C4 |
"Bb"D3F B,,3D | "Eb"E2B, "Bb"D4 z | "Cm"G,2E2 E4 | "Bb"D2F2 B,4 |
"Eb"E2G2 "Ab"A,4 | "Fm"A4 "Bb"G,4 | "Eb"D2B,2 "Cm"G,4 | "Ab"A,4 C4 |
"Ab"A,3E "Eb"E4 | "Bb"B,,3D "Eb"G,4 | "Fm"F,2E2 "Bb"B,,3D | "Eb"E6 z2:|
V:5 clef=perc stafflines=1 name="Timpani"
%%MIDI program 47
|:"Eb"[E,B,E]4 z4 z3 | [E,E]4 [B,B]4 | "Ab"[E,C]4 z4 z3 | [E,E]4 [C,C]4 |
"Bb"[D,F]4 z4 | "Eb"[E,B,]4 [B,B]4 | "Cm"[G,E]4 z4 | "Bb"[D,F]4 z4 |
"Eb"[E,E]4 z4 | "Ab"[E,E]4 z4 | "Fm"[F,F]4 z4 | "Bb"[D,D]4 z4 |
"Ab"[A,,A,]4 z4 | "Eb"[E,E]4 z4 | "Bb"[B,,B,,]4 z4 | "Eb"[E,B,]4 z4 :|



X:1
T:Manifold Markets
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=120
K:Gmin
V:1 clef=treble name="Lead Synth" subname="Lead"
%%MIDI program 81
"Gm"B2 B2 AG | "Dm"F2 D4 | "A7"A2 c2 cB | "Gm"G4 F2 |
"Cm" G2 E2 FG | "A7"A2 F2 D2 | "Gm"B4 A2 | "D7"A2 G4 |
"D"G2 F2 D2 | "A7"c2 E4 | "Gm"G2 A2 BG | "C7"F4 E2 |
"Am"D2 E2 F2 | "D7"G2 A2 G2 | "Gm"G4 AG | "C7"F6 |
"Gm"B2 B2 AG | "Dm"F2 D4 | "A7"A2 c2 cB | "Gm"G4 F2 |
"Cm"G2 E2 FG | "A7"A2 F2 D2 | "Bb"F4 D2 | "Cm"G3 A "D7"B2 |
"Gm"G6 | "D7"A6 | "Gm"G4 z2 ||
V:2 clef=bass name="Bass Synth" subname="Bass"
%%MIDI program 87
"Gm"D,4 "Dm"A,2 | "A7"E,6 | "Gm"D,4 "Cm"B,,2 | "A7"C,6 |
"Gm"G,,4 "D7"A,,2 | "D"D,4 "A7"F,2 | "Gm"G,,6 | "D7"A,,6 |
"D"A,,4 "A7"E,,2 | "Gm"D,4 "C7"G,,2 | "Am"A,,4 "D7"A,,2 | "Gm"G,,6 |
"Gm"D,4 "D7"A,,2 | "D"D,4 "A7"F,2 | "Gm"G,,6 | "C7"C,6 |
"Gm"D,4 "Dm"A,2 | "A7"E,6 | "Gm"D,4 "Cm"B,,2 | "A7"C,6 |
"Gm"G,,4 "D7"A,,2 | "D"D,4 "A7"F,2 | "Bb"F,6 | "Cm"G,,6 |
"Gm"G,,6 | "D7"A,,6 | "Gm"G,,4 z2 ||
V:3 clef=treble name="Harmony Synth" subname="Harmony"
%%MIDI program 82
"Gm"d2 d2 cB | "Dm"d2 A4 | "A7"c2 c4 | "Gm"d4 c2 |
"Cm"e2 c2 de | "A7"f2 c2 A2 | "Gm"d4 c2 | "D7"B2 A4 |
"D"A2 F2 d2 | "A7"d2 f4 | "Gm"g2 d2 c2 | "C7"B2 A4 |
"Am"d2 d2 f2 | "D7"G2 A2 G2 | "Gm"G2 G4 | "C7"F6 |
"Gm"d2 d2 cB | "Dm"d2 A4 | "A7"c2 e4 | "Gm"d4 c2 |
"Cm"e2 c2 de | "A7"f2 c2 A2 | "Bb"F4 D2 | "Cm"e3 f "D7"d2 |
"Gm"d6 | "D7"B6 | "Gm"d4 z2 ||



X:1
T:Odyssey's End
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=104
K:G
V:1 clef=treble name="Strings"
%%MIDI program 48
|: "G"G4 B4 | "C"c4 D4 | "G"B2d2 B2A2 | "D7"A3G F2D2 |
"G"G4 B4 | "C"e4 d4 | "Em"b4 a4 | "D7"g4 f4 |
"D"G4 F4 | "C"E4 D4 | "G"B2d2 B2A2 | "D7"A3G F2D2 |
"G"G4 E4 | "D"D4 B,4 | "C"e4 d2B2 | "G"G8 :|
V:2 clef=treble name="Brass"
%%MIDI program 61
|: "G"D4 F4 | "C"c4 D4 | "G"B3d e2g2 | "D7"f2a2 g2f2 |
"G"G3B A3G | "C"A2c2 d2e2 | "Em"e4 g4 | "D7"f4 e4 |
"D"A4 G4 | "C"F3E D4 | "G"B3d g2e2 | "D7"f2e2 d4 |
"G"G4 E4 | "D"D4 B,4 | "C"c2A2 d2G2 | "G"G8 :|
V:3 clef=bass name="Cello"
%%MIDI program 42
|: "G" G,4 D4 | "C"E4 G4 | "G"B,4 D4 | "D7"A,4 F4 |
"G"G,4 D4 | "C"C4 E4 | "Em"G,4 B,4 | "D7"A,4 F4 |
"D"D,4 A,4 | "C"C4 C4 | "G"B,4 D4 | "D7"A,4 F4 |
"G"G,4 B,4 | "D"D4 D4 | "C"C4 E4 | "G"G,8 :|



X:1
T:Courage of the Knights
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Am
V:1 clef=treble
%%MIDI program 61
|"Am"E2 A2 c2 e2|"E"E4 z4|"F"A2 c2 f2 a2|"G"G4 z2 B2|
"Am"E2 A2 c2 E2|"C"e2 g2 e4|"Dm"d2 f2 a2 fd|"E"E4 z2 E2|
"F"c2 A2 c2 e2|"Am"A4 z4|"G"B2 d2 g2 B2|"E"e4 z2 e2|
"Am"a2 e2 c2 A2|"Dm"f2 d2 A2 F2|"E"E4 E2 z2|"Am"A4 A2 z2|
V:2 clef=treble
%%MIDI program 48
||"Am"c2 e2 a2 c'2|"E"B2 E2 G2 B2|"F"A2 c2 f2 a2|"G"G2 B2 d2 B2|
"Am"c2 E2 A2 c2|"C"c2 E2 G2 c2|"Dm"F2 A2 d2 F2|"E"E2 G2 B2 e2|
"F"A2 F2 A2 c2|"Am"A2 E2 A2 e2|"G"B2 d2 g2 b2|"E"e2 B2 e2 g2|
"Am"A2 e2 a2 c'2|"Dm"d2 A2 f2 a2|"E"E2 B2 e2 g2|"Am"A2 E2 A2 z2|
V:3 clef=bass
%%MIDI program 42
|"Am"A,2 E2 A2 C2|"E"E,2 B,2 E2 G2|"F"F,2 C2 F2 A2|"G"G,2 B,2 D2 G2|
"Am"A,2 C2 E2 A,2|"C"C2 G,2 C2 E2|"Dm"D2 A,2 D2 F2|"E"E,2 G2 B,2 E2|
"F"F,2 C2 F2 A,2|"Am"A,2 E2 A2 c2|"G"G,4 G2 B2|"E"E,4 E2 e2|
"Am"A,2 E2 A2 C2|"Dm"D2 F2 A,2 d2|"E"E2 G2 B2 e2|"Am"A,4 A,2 z2|



X:1
T:Hold The Groove
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Gdor
V:1 clef=treble
%%MIDI program 80
|:"Gm"G4 B2d2|"C7"c4 E4|"D7"F4 G4|"C7"E6 z2|
"Gm"G4 A2B2|"C7"d4 E4|"D7"F4 G4|"Gm"G8:|
V:2 clef=treble
%%MIDI program 81
||:"Gm"D2G4 B2|"C7"c2E4 E2|"D7"A2F4 G2|"C7"E2C4 E2|
"Gm"d2A4 B2|"C7"d2E4 E2|"D7"A2F4 G2|"Gm"G8:|
V:3 clef=bass
%%MIDI program 33
||:"Gm"G,4 G,4|"C7"C,4 E,4|"D7"A,4 A,4|"C7"E,4 G,4|
"Gm"G,4 D,4|"C7"E,4 C,4|"D7"A,4 F,4|"Gm"G,8:|



X:1
T:Oom-Pah Polka
C:OrchestrAI
M:2/4
L:1/8
Q:1/4=120
K:Gmaj
% Accordion provides melody and harmony
V:1 clef=treble name="Accordion"
%%MIDI program 21
|: "G"d2 B2 | "D7"A2 F2 | "G"G2 E2 | D2 B,2 |
"G"d2 e2 | "D7"f2 g2 | "G"e2 d2 | B4 |
"C"e2 c2 | "G"d2 B2 | "A7"e2 c2 | "D7"F4 |
"G"d2 B2 | "D7"A2 F2 | "G"G2 E2 | "G7"D4 :|
% Tuba provides bass line and rhythm
V:2 clef=bass name="Tuba"
%%MIDI program 58
|: "G"G,,2 D,2 | "D7"A,,2 D,2 | "G"G,,2 E,2 | "D7"A,,2 D,2 |
"G"G,,2 D,2 | "D7"A,,2 D,2 | "G"G,,2 B,,2 | "G"D,4 |
"C"E,2 C,2 | "G"G,,2 D,2 | "A7"C,2 A,,2 | "D7"F,4 |
"G"G,,2 D,2 | "D7"A,,2 F,2 | "G"G,,2 E,2 | "G7"D,4 :|



X:1
T:Euphorian Citadel
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:E
% Solo horn introduces the central theme of the city
V:1 clef=treble name="Horn" subname="Hrn."
%%MIDI program 71
|: "E"B4 A2 G2 | "C#min"F4 E4 | "B"A3 B c2 B2 | "A"A6 z2 |
"E"B4 "G#min"G2 F2 | "A"A2 G2 F2 E2 | "E"B4 "A"E3 F |"B"B6 z2 |
V:2 clef=treble name="Violin 1" subname="Vln.1"
%%MIDI program 41
|: "E"B2 c2 B2 G2 | "C#min"E2 F2 E2 C2 | "B"B,2 B2 B2 c2 | "A"A,2 E2 F2 G2 |
"E"B,2 B2 A2 G3^ | "G#min"G2 A2 G2 E2 | "A"A,2 A2 B2 c2 | "B"B,3 B A4 :|
V:3 clef=treble name="Violin 2" subname="Vln.2"
%%MIDI program 41
|: "E"e4 e2 d2 | "C#min"A4 A2 G2 | "B"G4 f2 e2 | "A"A4 z4 |
"E"e6 g2 | "G#min"f4 e2 d2 | "A"A4 E2 F2 | "B"B6 z2 :|
V:4 clef=bass name="Cello" subname="Vc."
%%MIDI program 42
|: "E"E2 A,2 E2 G,2 | "C#min"A,2 C2 E2 G,2 | "B"B,2 F2 B,2 F2 | "A"A,2 E2 A,2 C2 |
"E"E2 E2 B,2 ^G,2 | "G#min"G2 B,2 E2 E2 | "A"A2 A,2 E2 E2 | "B"B,2 F2 B,4 :|
V:5 clef=bass name="Bass" subname="B"
%%MIDI program 43
|: "E"E,4 B,,4 | "C#min"C,4 G,,4 | "B" B,,3 B, C2 z2 | "A"A,,4 z4 |
"E" E,4 "G#min"G,4 | "A"A,3 A, B,,2 z2 | "E"E,2 E,,2 B,,4 | "B"B,,6 z2 :
V:6 clef=treble name="Harp" subname="Hp."
%%MIDI program 47
|: "E"e2^g2 a2f2 | "C#min"g4 e2^g2 | "B"b2a2 b2a2 | "A"a4 z2 e2 |
"E"B6 e2 | "G#min"f2g2 a2e2 | "A"E4 F2G2 | "B"B6 z2 :|



X:1
T:Epic Anthem of Valor
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=92
K:Gmaj
V:1 clef=treble name="Trumpets"
%%MIDI program 56
|: "G"G2 B2 d2 | "C"E3 F G2 | "D"A2 ^F2 A2 | "G"G4 z2 |
"Bm"B2 A2 ^G2 | "C"E4 z2 | "D"D2 F2 G2 | "G"B3 A G2 |
"Em"E2 G2 F2 | "G"G2 A2 B2 | "A7"c2 A2 F2 | "D"D4 z2 |
"G"B,2 D2 G2 | "C"E2 G2 C2 | "G"D2 G2 B2 | "D"D2 ^F2 A2 |
"C"G2 E2 C2 | "G/B"A2 D2 ^F2 | "Am"A2 c2 e2 | "D"d4 z2 :|
V:2 clef=treble name="Horns"
%%MIDI program 60
|: "G"G,2 B,2 D2 | "C" C2 E2 G2 | "D"F2 A2 d2 | "G"G,4 z2 |
"Bm"B,2 A,2 ^G,2 | "C"C2 E2 G2 | "D"D2 F2 A2 | "G"B,3 A, G,2 |
"Em"E,2 G,2 F,2 | "G"G,2 A,2 B,2 | "A7"c2 A,2 F,2 | "D"D,4 z2 |
"G"B,2 D2 G2 | "C"C2 E2 G2 | "G"D2 G2 B,2 | "D"D2 ^F2 A2 |
"C"G,2 E2 C2 | "G/B"A2 D2 ^F2 | "Am"A2 c2 e2 | "D"d4 z2 :|
V:3 clef=bass name="Cellos"
%%MIDI program 42
|: "G"D,3 B,, G,,2 | "C"C,2 E,2 C2 | "D"D2 F2 A,2 | "G"G,,4 z2 |
"Bm"B,,2 A,,2 ^G,,2 | "C"C,4 z2 | "D"D,3 A,, D,2 | "G"B,,2 A,, G,,2 z |
"Em"E2 E,2 F,2 | "G"G,2 A,2 B,2 | "A7"c2 A,2 F,2 | "D"D,4 z2 |
"G"B,,2 D,2 G,2 | "C"C2 E,2 G,2 | "G"G,2 D,2 B,,2 | "D"D,2 ^F2 A,2 |
"C"G,2 E2 C2 | "G/B"B,2 D2 ^F2 | "Am"A,2 C2 E2 | "D"d3 B,, D,,2 :|
V:4 clef=bass name="Contrabasses"
%%MIDI program 43
|: "G"G,,4 z2 | "C"C,4 z2 | "D"D,4 z2 | "G"G,,4 z2 |
"Bm"B,,4 z2 | "C"C,4 z2 | "D"D,4 z2 | "G"G,,4 z2 |
"Em"E,4 z2 | "G"G,,4 z2 | "A7"A,,4 z2 | "D"D,,4 z2 |
"G"G,,2 D,2 G,,2 | "C"C,2 E,2 C,2 | "G"G,,2 D,2 G,,2 | "D"D,2 ^F2 A,,2 |
"C"C,2 G,,2 E,2 | "G/B"B,,2 D,2 ^F,2 | "Am"A,,2 C,2 E,2 | "D"D,4 z2 :|



X:1
T:Epic Boss Confrontation
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=175
K:Em
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"Em"B4E4G4e4|"D"d4A4d4f4|"C"G4E4C4G,4|"B"B,4F4B,4D4|
"Em"E4^G4B4e4|"A"a4^c4e4a4|"G"g4b4d4g4|"B"B4d4f4b4|
"Em"B6A4G6F4|"D"E6D4C6B,4|"C"G4B4d4g4|"A"A4^c4e4a4|
"Em"e2B2e2B2g2E2g2E2|"B"B2F2B2F2d2B2d2B2|
"A"a2^c2a2^c2e2A2e2A2|"Em"G4E4G4B,4:|
V:2 clef=treble name="Choir Aahs"
%%MIDI program 52
|:"Em"E4 z4 E4 z4|"D"D4 z4 D4 z4|"C"C4 z4 C4 z4|"B"B,4 z4 B,4 z4|
"Em"E4 z4 E4 z4|"A"A4 z4 A4 z4|"G"G4 z4 G4 z4|"B"B4 z4 B4 z4|
"Em"E4 z4 E4 z4|"D"D4 z4 D4 z4|"C"C4 z4 C4 z4|"A"A4 z4 A4 z4|
"Em"E2E2 z2E2 E2B2 z2B2|"B"B2B2 z2B2 B2F2 z2F2|
"A"A2A2 z2A2 A2E2 z2E2|"Em"E4 z4 E4 z4:|
V:3 clef=bass name="Bass Synth"
%%MIDI program 87
|:"Em"E,8 E,8|"D"D,8 D,8|"C"C,8 C,8|"B"B,,8 B,,8|
"Em"E,8 E,8|"A"A,8 A,8|"G"G,8 G,8|"B"B,,8 B,,8|
"Em"E,8 E,8|"D"D,8 D,8|"C"C,8 C,8|"A"A,8 A,8|
"Em"E,8 E,8|"B"B,,8 B,,8|"A"A,8 A,8|"Em"E,8 E,8:|



X:1
T:Strength of Resolve
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=85
K:Amin
% Piano assigned to the first voice
V:1 clef=treble
%% MIDI program 1
|: "Am"c4 e4 | "E"e3 d B2G2 | "Am"A6 G2 | "F"E4 c4 |
"Am"A,4 "E"G,3 E | "Am"A3 G "F"E3 F | "Am"A4 z4 :|
V:2 clef=bass
%% MIDI program 1
|: "Am"A,2 C2 E2 A,2 | "E"B,2 ^G,2 B,2 E2 | "Am"A,2 E2 A2 e2 | "F" F,2 A,2 c2 F2 |
"Am"A,2 C2 E2 A,2 | "E"B,2 E2 "Am"A,4 | "F"F,2 C2 "E"E,4- | "Am"E,8 :|
V:1
% A powerful and bold section to capture moments of heightened emotion
|: "Am"A4 c4 ||: "G"G4 B4 | "F"F4 A4 | "E7"e4 ^d4 |
"Am"c4 "E7"B4 | "Am"a4 g4 | "F"f4 "E7"e4 | "Am"A8 :|
V:2
|: "Am"A,4 C4 | "G"G,4 B,4 | "F"F,4 A,4 | "E7"E,2 G,2 B,4 |
"Am"A,4 "E7"G,4 | "Am"A,2 E2 "G"G,4 | "F"F,2 C2 "E7"E,4 :| "Am"A,,8 :|
V:1
% A return to the initial theme with even more intensity
|: "Am"c4 e4 | "E"e3 d B2G2 | "Am"A6 G2 | "F"E4 c4 |
"Am"A,4 "E"G,3 E | "Am"A3 G "F"E3 F | "Am"A4 z4 :|
V:2
|: "Am"A,2 C2 E2 A,2 | "E"B,2 ^G,2 B,2 E2 | "Am"A,2 E2 A2 e2 | "F" F,2 A,2 c2 F2 |
"Am"A,2 C2 E2 A,2 | "E"B,2 E2 "Am"A,4 | "F"F,2 C2 "E"E,4- | "Am"E,8 :|



X:1
T:Tension Before the Storm
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Am
% The first voice carries the main melody with foreboding undertones
V:1
%% MIDI program 41
|:"Am"E2 A2 E2 A2 | "Dm"D2 F2 A3 G | "E"E2 ^C2 E2 G2 | "Am"A6 z2 |
"Am"A2 c2 E3 ^D | "Dm"F2 A2 d3 c | "E"E2 ^G2 B3 A | "Am"A6 z2 |
"F"=c2 FA cAFA | "G"B2 G2 DGBG | "Am"A2 EA cA E2 | "Dm"F2 D2 FDAD |
"E"E2 ^G2 E2 G2 | "Am"E2 C2 A3 B | "F"c2 FA c2 e2 | "E"B6 z2:|
% The second voice adds a sense of urgency with counterpoint
V:2
%% MIDI program 49
|:"Am"E2 E2 G2 G2 | "Dm"D4 D4 | "E"E2 ^C2 A3 G | "Am"A4 z4 |
"Am"A2 c2 E4 | "Dm"D2 F2 D4 | "E"E4 ^G2 G2 | "Am"A4 z4 |
"F"=c2 FA c2 =c2 | "G"B4 G2 B2 | "Am"A2 E2 A2 c2 | "Dm"D3 F z2 F2|
"E"E2 ^G2 B2 ^G2 | "Am"A2 C2 E2 ^D z | "F"c3 =c c2 e2 | "E"B4 z4:|
% The third voice introduces lower register tension
V:3
%% MIDI program 42
|:"Am"A,2 A,2 C3 E | "Dm"F,4 D2 z2 | "E"E,4 ^C2 z2 | "Am"A,4 z4 |
"Am"A,2 A,2 C4 | "Dm"D,2 F,2 D2 z2 | "E"E4 E2 z2 | "Am"A,4 z4 |
"F"F,2 A,2 F,2 A,2 | "G"G,4 G2 z2 | "Am"A,2 A,2 C2 z2 | "Dm"D,2 F,2 D2 z2 |
"E"E2 E4 z2 | "Am"A,2 C2 A,2 z2 | "F"F,2 A,2 F,2 z2 | "E"E,6 z2:|
% The fourth voice completes the harmonies with a bass foundation.
V:4
%% MIDI program 43
|:"Am" A,4 E4 | "Dm" D3 F A,2 z2 | "E" E4 ^C4 | "Am" A,6 z2 |
"Am" A,4 E4 | "Dm" D3 F A,2 z2 | "E" E4 ^G,4 | "Am" A,6 z2 |
"F" F,4 C4 | "G" G,4 D4 | "Am" A,4 E2 z2 | "Dm" F,4 A,2 z2 |
"E" E4 ^C4 | "Am" A,3 E ^C,2 z2 | "F" F,4 C2 z2 | "E" E,6 z2:|



X:1
T:Voyage of the Arcane
C:OrchestrAI
M:C
L:1/8
Q:1/4=110
K:D
V:1 clef=treble name="Flute" subname="Fl."
%%MIDI program 73
|: "D"FA d2 "G"B2 A2 | "A7"e4 "A"c2 e2 | "Bm"d3 c "F#m"B2 A2 | "G"G4- G2 GA |
"D"A3 B "G"AG F2 | "A"A4- A2 z2 | "Bm"B2 c2 d2 e2 | "A7"A6- A2 |
"D"D2 F2 "F#m"A2 d2 | "G"G2 B2 d4 | "A7"A3 B c2 E2 | "D"D8 :|
V:2 clef=treble name="Oboe" subname="Ob."
%%MIDI program 68
|: "D"d4 "G"g2 f2 | "A7"a4 "A"c'2 b2 | "Bm"d'3 c' "F#m"b2 a2 | "G"g4- g2 ga |
"D"f3 g "G"a2 g2 | "A"a4- a2 z2 | "Bm"d2 e2 f3 g | "A7"a6- a2 |
"D"d2 f2 "F#m"a2 d'2 | "G"g2 b2 g4 | "A7"a3 b c2 E2 | "D"d8 :|
V:3 clef=treble name="Horn" subname="Hn."
%%MIDI program 60
|: "D"A,4 "G"D2 C2 | "A7"E4 "A"A,2 F2 | "Bm"B,3 A, "F#m"D2 C2 | "G"G,4- G2 GA, |
"D"A,3 B, "G"C2 D2 | "A"A,4- A2 z2 | "Bm"B2 C2 D2 E2 | "A7"A,6- A,2 |
"D"D2 F2 "F#m"A2 D2 | "G"G2 B2 D4 | "A7"A,3 B, C2 E2 | "D"D,8 :|
V:4 clef=bass name="Cello" subname="Vc."
%%MIDI program 42
|: "D"D,2 D2 "G"G,2 B,2 | "A7"A2 E2 "A"C2 A,2 | "Bm"B,,2 D2 "F#m"F2 A,2 | "G"G,4- G,2 z2 |
"D"D4 D2 C2 | "A"A,4- A,2 z2 | "Bm"B,,2 E2 "F#m"F2 A,2 | "A7"E4- E,2 z2 |
"D"D,2 A,2 "F#m"D2 F2 | "G"G,2 B,2 D4 | "A7"A,3 E "D"F2 D2 | "D"D,8 :|



X:1
T:Soaring Destiny
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=170
K:Em
V:1 clef=treble name="Electric Guitar" subname="Guit."
%%MIDI program 30
|:"Em" B8 E8 | "G" G4 F4 G4 A4 B3    | "Am" A8 c8 | "D" d4 d4 e4 f5  |
"Em" B8 E8 | "G" G4 F4 G4 A4 B3    | "Bm" B8 d8 | "C" c4 B4 A4 G5  |
"C" E4 G4 A4 B4 "Bm" B3    | "Am" A4 c4 E4 G4 "G" G8 z8 :| "Am" A8 "Bm" B8 z8 | "Em"E4 E4 F4 G5  :|
V:2 clef=treble name="Strings" subname="Violins"
%%MIDI program 49
|:"Em" e4 e4 g4 g4 b2   | "G" d4 d4 b4 b4 a2   | "Am" c4 c4 e4 e4 a2   | "D" f4 f4 a4 a4 g2   |
"Em" e4 e4 g4 g4 b2   | "G" d4 d4 b4 b4 a2   | "Bm" d4 d4 f4 f4 b2   | "C" e4 e4 g4 g4 a2   |
"C" E8 G8 | "Bm" B4 A4 G4 F5  | "Am" A8 z8 | "G" G4 F4 E4 D4 :| "Am" A8 z8 | "Bm" B8 z8 | "Em"E16 :|
V:3 clef=bass name="Synth Bass" subname="Synth."
%%MIDI program 39
|:"Em" E,16 | "G" G,8 F,8 | "Am" A,16 | "D" D8 F,8 |
"Em" E,16 | "G" G,8 F,8 | "Bm" B,16 | "C" C,8 E,8 |
"C" E,4 C,4 E,4 G,4 | "Bm" B,4 B,4 A,4 G,4 | "Am" A,8 c8 | "G" G,8 F,8 | "Am" A,16 | "Bm" B,16 | "Em"E,16 :|
V:4 clef=treble name="Brass" subname="Hrns."
%%MIDI program 61
|:"Em" B2 B2 B2 B2 E4 G4 F  | "G" G2 F2 G2 A2 B4 A4 G  | "Am" A2 A2 A2 A2 c4 E4 c  | "D" d2 d2 e2 f2 g4 e4 d  |
"Em" B2 B2 B2 B2 E4 G4 F  | "G" G2 F2 G2 A2 B4 A4 G  | "Bm" B2 B2 B2 B2 d4 F4 d  | "C" c2 B2 A2 G2 F4 d4 c  |
"C" E8 z8 | "Bm" B4 A4 G2 F2 z4 | "Am" A8 z8 | "G" G8 | "Am" A8 z8 | "Bm" B8 | "Em" E8 z8 :|



X:1
T:Yearning Heights
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:Cmaj
% Voice settings
V:1 name="Trumpet" clef=treble
V:2 name="Trombone" clef=bass
V:3 name="Tuba" clef=bass-8
%%MIDI program 56 % Trumpet
%%MIDI program 57 % Trombone
%%MIDI program 58 % Tuba
% Trumpet carries the main melody
[V:1]
%%MIDI program 56
|: "C"E4 z2 G2 | "Am"A4 z2 E2 | "F"F4 z2 C2 | "G"G4 z4 |
"C"E6 G2 | "Am"A4 z2 E2 | "F"F6 C2 | "G"G4 z4 :|
% Trombone provides harmonies and syncopation
[V:2]
%%MIDI program 57
|: "C"C,D,4 E,3 | "Am"A,4 C4 | "F"F,4 A,4 | "G"G,4 B,4 |
"C"C,D,4 E,3 | "Am"A,4 C4 | "F"F,4 A,4 | "G"G,4 B,4 :|
% Tuba underpins with a solid bass line
[V:3]
|: "C"C,8 | "Am"A,,8 | "F"F,,8 | "G"G,,8 |
"C"C,8 | "Am"A,,8 | "F"F,,8 | "G"G,,8 :|



X:1
T:Dawn of the New Era
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=100
K:Cmaj
V:1 clef=treble name="Celesta"
%%MIDI program 8
|:"Am" E2 A2 B2 | "C" c4 z2 | "F" A2 F2 G2 | "Fm" A4 z2 |
"Am" E2 A2 B2 | "C" c4 z2 | "F" A2 F2 G2 |1 "C" G,4 z2 :|2 "C" G,4 G,2 ||
|:"C" "C"E2 "G"G2 ^G2 | "Am" A4 z2 | "F" c2 A2 F2 | "G" G,4 G,2 |
"C" "C"E2 "G"G2 ^G2 | "Am" A4 z2 | "F" c2 A2 F2 |1 "G" G4 z2 :|2 "G" G4 ^F2 ||
|:"G" "Em"G2 B2 ^F2 | "Am"A4 z2 | "F" c2 A2 F2 | "E7" E,4 E,2 |
"Am" "Em"G2 B2 ^F2 | "Am"A4 z2 | "F" c2 A2 F2 |1 "E7" E4 z2 :|2 "E7" E4 E2 ||
V:2 clef=treble name="Violin"
%%MIDI program 40
undefined| "C" E3 G2 E | "Am" A,3 C2 E | "C" G,3 E2 G | z6 |
z6 | "C" E3 G2 E | "Am" A,3 C2 E |1 "C" G,3 E2 G |undefined|2 [K:clef=treble] "C"G,  ||undefined| "G" B,3 D2 B, | "Am" A,3 C2 E | "F" A,3 F2 A, |
"C" z6 | "G" B,3 D2 B, | "Am" A,3 C2 E |1 "F" A,3 F2 A, | z6 :|2 "F" A,3 A,3 ||undefined| "Em" G3 B,2 G | "Am" A,3 C2 E | "E7" E,3 G,2 E, |
"Am" z6 | "Em" G3 B,2 G | "Am" A,3 C2 E |1 "E7" E,3 G,2 E, | z6 :|2 "E7" E,3 E,3 ||
V:3 clef=bass name="Cello"
%%MIDI program 42
[K:clef=bass]
"Am" A, | "C" E,6 | "F" F,6 | "Fm" A,6 |
"Am" A,6 | "C" E,6 | "F" F,6 |1 "C" G,,6 :|2 "C" G,,3 G,,3 ||
[K:clef=bass]
"C" z | "G" B,,6 | "Am" A,,6 | "F" F,6 |
"C" z6 | "G" B,,6 | "Am" A,,6 |1 "F" F,6 :|2 "F" F,3 F,3 ||
[K:clef=bass]
"G" z | "Em" G,6 | "Am" A,,6 | "E7" E,6 |
"Am" z6 | "Em" G,6 | "Am" A,,6 |1 "E7" E,6 :|2 "E7" E,6 |]



X:1
T:Reunion Rhapsody
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:G
V:1 clef=treble name="Piano (Right Hand)"
%%MIDI program 0
|:"G"D2 B2 d2 G2| "Am"c4 B2 A2|"C"G4- G2 FE|"D7"D4- D2 z2|
"Em"B2 A2 G2 F2|"C" E4 D2 C2|"G/B"B4 A2 G2|"D"A6 G2|
"G"B2 d2 G2 B2|"Em"A4 G2 F2|"C"E2 F2 G2 A2|"D7"F4 D2 z2|
"Em"B2 e2 d2 B2|"Am"c2 B2 A2 G2|"G"D6 "Dsus4"G2|"G"G8:|
V:2 clef=treble name="Piano (Left Hand)"
%%MIDI program 0
|:"G"G,4 B,4| "Am"A,4 C4|"C"E,4 G,4|"D7"F,4 A,4|
"Em"G,4 B,4|"C"C4 E4|"G/B"G,4 B,4|"D"A,4 D4|
"G"G,4 B,4|"Em"G,4 B,4|"C"E,4 C4|"D7"A,4 F,4|
"Em"G,4 B,4|"Am"A,4 C4|"G"D,4 "Dsus4"G,4|"G"G,8:|
V:3 clef=treble-8 name="Flute"
%%MIDI program 73
|:"G"D4 B2 d2|"Am"c2 B2 A4|"C"G2 F2 E4|"D7" A2 F2 D4|
"Em"B,4 A2 G2|"C"E,4 D2 C2|"G/B"B,4 A2 G2|"D7"A2 F2 D4|
"G"B4 d2 B2|"Em"A4 G2 E2|"C"G2 F2 z2 E2|"D7"A4 F2 D2|
"Em"B4 e2 d2|"Am"c4 B2 A2|"G"B,4- B2 "Dsus"G2|"G"D4 z4:|
V:4 clef=bass name="Cello"
%%MIDI program 42
|:"G"G,4 D4|"Am"A,6 E2|"C"C,6 G,2|"D7"D,6 A,2|
"Em" G,4 E4|"C"C,4 G,4|"G/B"B,4 G,4|"D"A,4 D4|
"G"G,4 D4|"Em"G,6 E2|"C"E,6 C2|"D7"F,6 D2|
"Em"G,4 E4|"Am"A,4 C4|"G"G,4 D4|"G"G,8:|



X: 1
T: The Enchanted Valley
M: 2/4
L: 1/16
B: "O'Neill's 1"
N: "Very slow" "collected by J. O'Neill"
N:
Z: "Transcribed by Norbert Paap, norbertp@bdu.uva.nl"
K: Gm
|: "Gm"G3A (Bcd=e) | f4 (g2dB) | ({d}c3B) G2E2 | F4 (D2=E^F) |
"Dm"G3A (Bcd=e) | f4 d2f2 | (g2a2 b2).g2 | {b}(a2g2 f2).d2 |
"Gm"(d2{ed}c2) B2B2 | (A2G2 {A}G2F2) | "D7"(GABc) (d2{ed}cA) | "Gm"G2G2 G2z2 |
"Gm"G2 | "Cm"B2c2 (dcAB) | "Gm"G2G2 G2G2 | "Cm"B2d2 (gfdc) | "D7"d2g2 (g3ga) |
"Gm"(bagf) (gd)(dc) | "F"(B2AG) F2D2 | "Bb"(GABc) (d2d2) | "D"(bgfd) cAF2 |
"Gm"G2A2 (B2{cB}AG) | "D7"A3G F2D2 | "Gm"(GABc) (d2{ed}cA) | G2G2 G2z2 :|
|: "Dm"A2^F2 (ABAF) | "Cm"G4- G2G2 | "Gm"A2B2 (c2BA) | G6- G2 |
"F"A2c2 (d2cB) | "Bb"d4- d3d | "Eb"(cBAG) "F"(AFGA) | "Gm"B4- B2z2 |
"Gm"(d>e)(dc) B2(A>B) | "Cm"c4- c2c2 | "Gm"(d>e)(dc) B2(A>B) | "D7"A4- A2A2 |
"Gm"B2A2 "Cm"G2^F2 | "D7"G6- G2 | "Gm"BAGF "D"(G2^F2) | "Gm"G4 G4 :|



X:1
T:Pirate Polka Jam
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=200
K:Gmaj
% The pirates dance a frenetic polka on the deck under starlight
V:1 clef=treble name="Accordion" subname="Melody"
%%MIDI program 21
|: "G"D2G2 B2d2 | "D7"c2A2 F4 | "G"D2G2 B2d2 | "C"E4 "D7"D4 |
"G"D2G2 B2d2 | "D7"c2A2 F4 | "G"B2G2 "D"A2F2 |1 "G"G4 z4 :|2 "G"G8 |
|: "G"d2cB "C"e2d2 | "D7"c2A2 F4 | "G"B2d2 g2f2 | "Em"e4 "A7"d4 |
"D"A2A2 f2a2 | "G"g2f2 "Em"e4 | "Am"c2B2 "D7"A2c2 | "G"G8 :|
V:2 clef=treble name="Violin" subname="Harmony"
%%MIDI program 40
|: "G"BG4 d3 | "D7"AF4 F3 | "G"BG4 d3 | "C"E2C2 "D7"D4 |
"G"BG4 d3 | "D7"AF4 F3 | "G"BG3 "D"A2F2 |1 "G"G4 z4 :|2 "G"G4 z4 |
|: "G"B2G2 "C"E2C2 | "D7"A2F2 F4 | "G"G2B2 d3e | "Em"e2B2 "A7"A2d2 |
"D"F2A2 A2d2 | "G"B2d2 "Em"e3B | "Am"c2e2 "D7"A2A2 | "G"G4 z4 :|
V:3 clef=bass name="Bass" subname="Bassline"
%%MIDI program 33
|: "G"D,4 D,4 | "D7"A,4 A,4 | "G"D,4 D,4 | "C"C,4 "D7"D,4 |
"G"D,4 D,4 | "D7"A,4 A,4 | "G"B,,4 "D"A,4 |1 "G"G,4 z4 :|2 "G"G,4 z4 |
|: "G"D,2A,2 "C"C,2G,2 | "D7"A,4 A,4 | "G"B,2D2 d4 | "Em"E4 "A7"A,4 |
"D"F4 f4 | "G"B4 e4 | "Am"c4 "D7"A4 | "G"G,8 :|



X:1
T:Fanfare of Triumph
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:Cmaj
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|:"C"e3g e3d | "G"cBAG FEDC | "Am"E2A2 c2e2 | "F"d2^c2 d4 |
"C"e3g e3f | "G"gabg e2d2 | "F"a2^c2 "G"b2d2 |1 "C"c4 c4 :|2 "C"c4 z4 ||
|:"F"f3f a3f | "C"g4 e4 | "F"f3g a3f | "G"g4- g4 |
"F"f3e d3c | "Am"e4- e2E2 | "F"d2^c2 "G"b2d2 | "C"c4 z4 :|
V:2 clef=treble name="Saxophone"
%%MIDI program 65
|:"C"G4 C2E2 | "G"B,2C2 D4 | "Am"c4 E2A2 | "F"A4 A2z2 |
"C"G4 C2G2 | "G"d2B2 G2E2 | "F"c2A2 F2A2 |1 "C"C4 C4 :|2 "C"C4 z4 ||
|:"F"A2F2 A2c2 | "C"E4 G2E2 | "F"A2F2 A2c2 | "G"G4- G4 |
"F"A2c2 B2A2 | "Am"A2E2 G2E2 | "F"A2F2 "G"B2d2 | "C"C4 z4 :|
V:3 clef=bass name="Bass"
%%MIDI program 33
|:"C"C,4 E,2G,2 | "G"G,2B,2 D4 | "Am"A,4 C2E2 | "F"F4 F2z2 |
"C"C,4 E,2C,2 | "G"G,2D2 B,4 | "F"F,2A,2 "G"B,2D2 |1 "C"C,4 C,4 :|2 "C"C,4 z4 ||
|:"F"F,4 A,2C2 | "C"C4 E2C2 | "F"F,4 A,2C2 | "G"G,4- G,4 |
"F"F2A2 G2F2 | "Am"A,4 E2C2 | "F"F,2A,2 "G"G,2B,2 | "C"C,4 z4 :|



X:1
T:Pirate's Polka
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=120
K:Dmin
V:1 clef=treble name="Accordion"
%%MIDI program 21
|: "Dm"d4 d2A2 | "C"c4 c2E2 | "Dm"D4 F2A2 | "A7"A4 A,2C2 |
"Dm"d4 d2de | "C"f4 e2dc | "Dm"d6 A2 | "A7"A4 G2F2 :|
|: "Dm"A4 A2B2 | c4 A2G2 | "C"F4 E4 | D6 z2 |
"Dm"A4 A2B2 | c4 A2G2 | "A7"G4 F4 | E6 z2 :|
V:2 clef=treble name="Fiddle"
%%MIDI program 110
|: "Dm"A4 A2f2 | "C"G4 G2c2 | "Dm"d4 f2a2 | "A7"e4 e2c2 |
"Dm"A4 A2a2 | "C"e4 d2c2 | "Dm"A6 f2 | "A7"e4 c2B2 :|
|: "Dm"f4 f2g2 | a4 f2e2 | "C"d4 c4 | D6 z2 |
"Dm"f4 f2g2 | a4 f2e2 | "A7"c4 B4 | A6 z2 :|
V:3 clef=bass name="Bass"
%%MIDI program 33
|: "Dm"D,8 | "C"C,8 | "Dm"D,8 | "A7"A,8 |
"Dm"D,4 F,4 | "C"C,4 E,4 | "Dm"D,6 F,2 | "A7"A,4 E,4 :|
|: "Dm"A,,4 A,4 | C,4 A,,4 | "C"E,4 D,4 | D,6 z2 |
"Dm"A,,4 A,4 | C,4 A,,4 | "A7"A,,4 G,,4 | E,6 z2 :|



X:1
T:Impending Tempest
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:Am
V:1 clef=treble
%%MIDI program 48
|:"Am"(E2 | A2) E2 C2 E2 | (A2 | A2) B2 ^G2 A2 | "F"(F2 | F2) E2 F2 G2 | "E"(E2 | E2) E4- E2 |
"Am"(A,2 | A,2) ^G2 A2 B2 | "Dm"(D2 | D2) F2 E2 D2 | "E"(E2 | E2) ^G2 A2 B2 | "Am"A6- A2 :|
V:2 clef=treble
%%MIDI program 48
|:"Am"A2- A2 E2 A2 | B2- B2 ^G2 A2 | "F"F2- F2 E2 A2 | "E"E2- E2 E4- E2 |
"Am"A,2- A,2 ^G2 A2 | "Dm"D2- D2 F2 E2 | "E"E2- E2 ^G2 A2 | "Am"A6- A2 :|
V:3 clef=bass
%%MIDI program 42
|:"Am"A,2 C2 E2 A,2 | "Dm"D,2 F2 A2 D,2 | "F"F,2 A2 C2 F,2 | "E"E,2 ^G,2 B,2 E,2 |
"Am"A,2 A,2 C2 E2 | "Dm"D,2 D,2 F2 A2 | "E"E,2 E,2 ^G,2 B,2 | "Am"A,6- A,2 :|



X:1
T:8-bit Eastern European Quest
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=144
K:Dmin
% The A-section symbolizes the darker, mysterious side of the adventure.
V:1
%% MIDI program 81
|: "Dm"A2^G2A2B2 d4 c2A2 | "A7"e2^d2e2^f2 a4 ^g2e2 | "Dm"d2c2B2A2 G4 F2D2 | "C"=G,2^G,2A,2B,2 C8 |
"Dm"A2^G2A2B2 d4 c2A2 | "A7"e2^d2e2^f2 a4 ^g2e2 | "Dm"D4 C2B,2 "A7"A,8 | "Dm"d8- d4 z4 :|
% The B-section represents the joyful relief, in contrast to darker moods.
V:1
%% MIDI program 80
|: "G"=B4 B2c2 d4 B2A2 | "D"D4 F2A2 d8 | "G"=B4 B2c2 d4 e2d2 | "A7"c2A2 F2E2 "D"D8 |
"G"=B4 B2c2 d4 B2A2 | "D"D4 F2A2 d8 | "G"g2^f2 e2d2 c4 "A7"A,8 | "D"d8- d4 z4 :|
% Transition back to the darker theme
V:1
%% MIDI program 81
|: "Dm"F2E2D2C2 D8 | "C"E2D2C2B,2 C8 | "Dm"A,2A,2 ^G,2A,2 "A7"B,8 | "Dm"d8- d2z2 z4 |
"Dm"F2E2D2C2 D8 | "C"E2D2C2B,2 C8 | "Dm"D2C2B,2A,2 "A7"A,8 | "Dm"d8- d4 z4 :|
% Return to joyfulness with a little twist, adding Eastern European dance rhythm patterns.
V:1
%% MIDI program 80
|: "G"G8- G4 B2B2 | "D"d8 A4 F2A2 | "G"G8- G4 e2d2 | "A7"c2A2 F2G2 "D"D8 |
"G"G,4 G,2A,2 "D"A4 d6 | "G"B4 B2c2 d8 | "G"g2^f2 e2d2 c4 "A7"A,8 | "D"d8- d4 z4 :|



X:1
T:Starry Night Serenade
C:OrchestrAI
M:3/4
L:1/16
Q:1/4=60
K:Am
V:1 clef=treble
%%MIDI program 73
|:"Am"(e4 {e}d4) c2A2|A6 z4 "C"e2d2|"G"B6 z2 "C"c4|"Am"A3B ^G2 A2 F4|
w: *      *         *      *
"C"e4 z2 "E7"d6|"Am"c6 z2 "A2"E4|"F"D6 z "E7"{F}E4|"Am"A,6 z2 A,4|
w: *      *      *         *
"A"B2c2 e2 A2 z2 A2|"E7"G4 F4 E2C2|"Am"A,2C2 E2 z2 A,2B,2|^G4 F4 E4|
w: *               *               *      *
"Am"A6 z2 A2 B2|c6 c2 B2 A2|"G" G3A Bc d2 z2 B2|"Am"A8 z4:|
V:2 clef=bass
%%MIDI program 48
|:"Am"A,12|A,8 z4|"C"E,6 G,6|"G"G,4 F,2 z2 E,4|
w: *          *         *       *
"C"E,12|"E7"G,8 z4|"Am"A,4 E,4 z2 "E7"E2|"Am"A,12|
w: *          *
"A"A,6 C4 E4|"E7"G,8"E" E4|"Am"A,12|
w: *           *              *
"A"A,8 z4 "G"G,8-|G,6 F,2 E,4|"Am"A,12 z6 z4:|



X:1
T:This Week in Google Intro
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=100
K:Cmaj
% MIDI setup for a modern synth sound
V:1 clef=treble
%%MIDI program 81
% This phrase represents the modernity and forward-thinking nature of technology
|: "C" E4 G4 | "Am" A4 c4 | "F" F4 A4 | "G" G6 z2 |
% A shift to a minor here adds a touch of complexity, hinting at in-depth analysis
"G" G4 B,4 | "Em" E4 G4 | "Am" A6 c2 | "F" F6 z2 |
% A return to a more straightforward progression, representing clarity and communication
| "C" G4 E4 | "G" D4 B,4 | "Am" c4 A4 | "F" G4 F4 |
% A resolute cadence gives a sense of completion and readiness
| "F" E4 F4 | "G" G6 A2 | "C" E4 G4 | "C" C8 :|
V:2 clef=bass
%%MIDI program 34
% The bass locks in the foundation of the rhythm and harmony
|: "C" C,2 E,2 G,2 C2 | "Am" A,2 C2 E2 A,2 | "F" F,2 A,2 C2 F2 | "G" G,2 B,2 D2 G,2 |
% Bass follows the harmony for depth while staying solid and clear
"G" B,4 D4 | "Em" E,4 G,4 | "Am" A,2 C2 E2 A,2 | "F" F,4 A,4 |
% Maintaining the solid harmonic foundation mirroring the treble progression
| "C" C2 E,2 G,2 C2 | "G" D2 B,2 D2 G2 | "Am" A,2 C2 E2 A,2 | "F" F,2 A,2 C2 F2 |
% Leading to the final statement with a firm and conclusive bass line
| "F" E,4 F,4 | "G" D,6 G,2 | "C" C,2 E,2 G,2 C2 | "C" C,8 :|



X:1
T:Energetic Stride
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=180
K:Am
V:1 clef=treble
%%MIDI program 73
|"Am"A4 A4-|"E"A6 G2|"A"e4 "C"e2d2|"B"E6 z2|
"Am"A4 "G7"b2a2|"C"g4 "A7"e4|"Dm"f2a2 "E7"g2f2|"Am"a8|
"Am"a3b a2a2|"G"g3a "F"e4|"C"c6 "B"e2|"E7"g4 -g2 B2|
"Am"A4 A4|"B"z2 d2 c3 B|"E7"B2E2 "A"c2"A7"B2|"Am"a8:|
V:2 clef=bass
%%MIDI program 33
|"Am"E4 "E7"E4|"A"C4 "C"z2C2|"C"E4 -E2 "E"C2|"B"B,4 -B,2 B,2|
"Am"A,4 "G7"G,4|"C"C2E2 "A7"A,2C2|"Dm"F,4 "E7"E4|"Am"A,8|
"Am"E4 E4|"G"F2G2 "F"E4|"C"A,4 "B"B,2B,2|"E7"B,4 G,2E2|
"Am"A,4 A,2A,2|"B"B,4 -z2E2|"E7"B,2E2 "A"E2"G"E2|"Am"A,8:|



X:1
T:Nebula Escape
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=170
K:Bm
V:1 clef=treble name="Trumpet"
%%MIDI program 56
|: "Bm"B4^G4A4f4 | "A" e8 e4c4 | "Gmaj7"d2B2 d4G4F2D2 | "F#m"A,8 ^G,4A,4 |
"Bm"B4^G4A4f4 | "A" e6e2 e4c4 | "Gmaj7"B2d2 d4F4E2C2 | "F#m"A8- A4z4 |
"E"B3c dcBA ^G4E4 | "Bm"F4D4 E4F4 | "A"A3B cdBc A4c4 | "E"B8- B,4 E4 |
"Bm"B4^G4A4f4 | "A" e8 e4c4 | "Gmaj7"d2B2 d4G4F2D2 | "F#m"A8- A6 z2 |
"E"B3c dcBA ^G4E4 | "Bm"F4D4 E4F4 | "A"A3B cdBc A4c4 | "E"B8 z8 :|
V:2 clef=treble name="Horns"
%%MIDI program 61
|: "Bm"d4d2B2 ^G2F2D2 z2 | "A"C8 C2E2A,2 z2 | "Gmaj7"G,4B,2 D4B,4 z2 | "F#m" F8- F4z4 |
"Bm" d4d2B2 ^G2F2D2 z2 | "A"C6C2 C2E2A,2 z2 | "Gmaj7"G2B2 B4G4F2 z2 | "F#m"F8- F4z4 |
"E"B,4C4 D2E2F2^G2 | "Bm"A4F4 G4A4 | "A"G4E4 F4G4 | "E"E8- E4z4 |
"Bm"d4d2B2 ^G2F2D2 z2 | "A"C6C2 C2E2A,2 z2 | "Gmaj7"G,2B,2 D4G,4B,2 z2 | "F#m"F8- F6z2 |
"E"E4C4 D2E2F2^G2 | "Bm"A4F4 G4A4 | "A"G4E4 F4G4 | "E"E8 z8 :|
V:3 clef=bass name="Synth Strings"
%%MIDI program 50
|: "Bm"B,2^G2F2E2D2C2B,2 z2 | "A"A,4E4 A,4E4 | "Gmaj7"G,4B4 D4G4 | "F#m"F4^G4 A4F4 |
"Bm"B,2^G2F2E2D2C2B,2 z2 | "A"A,3E A,4 E4A,4 | "Gmaj7"G,2^G2A2B2 d4c4 | "F#m"F8- F4z4 |
"E"B,4C4 D2E2 ^G2A2 | "Bm"B,2A,2^G2A2 B4d4 | "A"A,4A2 E4c4d2 | "E"E8- E4z4 |
"Bm"B,2^G2F2E2D2C2B,2 z2 | "A"A,4E4 A,4E4 | "Gmaj7"G,2B,2 D2G2B2d2 z4 | "F#m"F8- F4z4 |
"E"B,4C4 D2E2 ^G2A2 | "Bm"B,2A,2^G2A2 B4d4 | "A"A,4A2 E4c4d2 | "E"E8 z8 :|
V:4 clef=bass name="Bass Synth"
%%MIDI program 87
|: "Bm"D8- D4 D2F2 | "A"A,8- A,4 C4 | "Gmaj7"G,8- G,4B,4 | "F#m"F8- F4z4 |
"Bm"D8- D4 D2F2 | "A"A,6- A,2 C4E2 z2 | "Gmaj7"G,2B,,2 D4 G,4B,2 z2 | "F#m"F8- F4z4 |
"E"E,4E2 F2^G2A2 z4 | "Bm"B,8- B,4D4 | "A"A,8- A,4E4 | "E"E12 z4 |
"Bm"D8- D4 ^G4 | "A"A,6- A,2 C4E2 z2 | "Gmaj7"G,8 G,4B,4 | "F#m"F8- F4z4 |
"E"E,4E2 F2^G2A2 z4 | "Bm"B,8- B,4D4 | "A"A,8- A,4E4 | "E"E8 z8 :|



X:1
T:Nebula Escape
C:OrchestrAI
M:7/8
L:1/8
Q:1/4=144
K:Dm
V:1 clef=treble name="Lead Synth"
%%MIDI program 81
|:"Dm" DFA F2F | A2G "C"EGE | "Bb" D2C C2F | "A7" ECE E2D |
"Dm" D3 EFG |"Gm" AFD G2E | "F" F3 AGF | "A7" EDC D3 :|
V:2 clef=treble name="Pad Synth"
%%MIDI program 92
|:"Dm" A,3 A,2C| "C" E,3 G,2E| "Bb" B,,3 D2D| "A7" A,,3 C2E|
"Dm" D3 C3  | "Gm" D2E FG2 | "F" C2C A2F | "A7" G3 E3  :|
V:3 clef=treble name="Arpeggio Synth"
%%MIDI program 82
|:"Dm" d2f dfd | a3 a2f | "C"e2g ege | "Bb"d2b dbd |
"Gm" g2b gbg |"A7" c3 c2e | "Dm" fed cde | "A7"dcB AGF :|
V:4 clef=bass name="Bass Synth"
%%MIDI program 87
|:"Dm" D,3 F,2A, | "C" C,3 E,2G, | "Bb" B,,3 D2F | "A7" E,,3 A,,2C, |
"Dm" D,3 D,2F, | "Gm" G,,3 B,,2D | "F" F,2F, A,2C, | "A7" A,,3 E,3  :|



X:1
T:Pre-Interview Power Up
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=160
K:Cmaj
V:1 clef=treble name="Brass"
%%MIDI program 61
|: "C"G4 G4 | e4 d2 c2 | "F"A6 G2 | F4 E4 |
"C"G4 G4 | e4 d2 c2 | "G" B2 B2 A2 G2 |1 "C"C8 :|2 "C"C4 z4 :|
V:2 clef=treble name="Strings"
%%MIDI program 49
|: "C"e3 g f2 e2 | d4 c4 | "F"a3 c b2 a2 | g4 f4 |
"C"e3 g f2 e2 | d4 c4 | "G"b3 d c2 B2 |1 "C"c4 z4 :|2 "C"c8 :|
V:3 clef=treble name="Woodwind"
%%MIDI program 74
|: "C" e2 G2 c4 | d2 e2 f4 | "F" a2 c2 f4 | A2 G2 F4 |
"C" e2 G2 c4 | d2 e2 f4 | "G" b2 d2 g4 |1 "C" c6 z2 :|2 "C" c2 z6 :|
V:4 clef=bass octave=-1 name="Bass"
%%MIDI program 43
|: "C"C2 G,2 E2 C2 | D4 E4 | "F"F2 E2 C2 A,2 | G,4 A,4 |
"C"C2 G,2 E2 C2 | D4 E4 | "G"G,2 D2 G2 B,2 |1 "C"C4 z4 :|2 "C"C8 :|



X:1
T:Epic Pirate Intro
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=140
K:Am
V:1 clef=treble
%% MIDI program 61
|:"Am"A2e2 c2e2|"Em"B2G2 E2G2|"F"A4 z2A2|"E7"B4 z2B2|
"Am"c2A2 A4|"G"d2B2 G4|"Am"A2e2 c2e2|"E7"B4 z2z2|
|:"Dm"d4 f2d2|"Am"c2E2 A4|"F"f2A2 A2c2|"Am"A6 z2|
"Dm"A2d2 f2a2|"Am"e2c2 A4|"G"B2d2 g2b2|"Am"a4 e2^c2|
"Em"B3G E2G2|"Am"A4 z2A2:"F"A4 z2c2|"E7"B6 z2:|
V:2 clef=treble
%% MIDI program 61
|"Am"e4 z2e2|"Em"g4 z2g2|"F"c4 z2c2|"E7"e4 z2e2|
"Am"e4 z2A2|"G"b4 z2B2|"Am"e4 z2e2|"E7"e4 z2z2|
|"Dm"f4 z2f2|"Am"a4 z2E2|"F"c4 z2c2|"Am"A4 z2z2|
"Dm"f4 z2d2|"Am"e4 z2c2|"G"b4 z2B2|"Am"a4 e2^c2|
"Em"g4 z2B2|"Am"e4 z2e2|"F"c4 z2F2|"E7"e6 z2:|
V:3 clef=bass
%% MIDI program 68
|"Am"A,8|"Em"E,8|"F"F,4 C2F,2|"E7"B,4 E2G,2|
"Am"A,2C2 A,4|"G"G,4 B,2D2|"Am"A,2E2 A,4|"E7"E,4 z2z4|
|"Dm"D,8|"Am"A,4 E2A,2|"F"F,2C2 F,4|"Am"A,6 z2|
"Dm"D,2F2 D,4|"Am"A,2E2 A,4|"G"G,4 D2G,2|"Am"A,4 E2^C2|
"Em"E,6 G,2|"Am"A,4 z2A,2|"F"F,6 C2|"E7"E,6 z2:|
V:4 clef=bass
%% MIDI program 68
|"Am"A,4 C4|"Em"B,4 E4|"F"F,4 A4|"E7"G,4 B,4|
"Am"C4 A,4|"G"D4 B,4|"Am"E4 A,4|"E7"E4 z8|
|"Dm"F4 D4|"Am"A,4 E4|"F"C4 F4|"Am"A4 z8|
"Dm"F4 A,4|"Am"E4 A,4|"G"B,4 D4|"Am"A,4 E4|
"Em"B,2 z2 ^G,4|"Am"A,4 C4|"F"F,4 A,4|"E7"G,4 B,4:|



X:1
T:High Octane Circuit
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=180
K:Dmin
% The piece begins with an intense burst of energy, signaling the start of the race
V:1 clef=treble name="Brass" subname="Lead Horns"
%%MIDI program 61
|:"Dm"D4F4 A4d4 | "C"c2c2 c2e2 g4e4 | "Bb"B4d4 f4B4 | "A7"a2a2 a2c2 e4c4 |
"Dm"d4f4 a2g2 f5 | "C"E2F2 G2A2 B4z4 | "Bb"d2e2 f2d2 B4z4 | "A7"c2E2 A2G2 F4D4 |
"Dm"D4F4 A4d4 | "C"c2c2 c2e2 g4e4 | "Bb"B4B4 d4f4 | "A7"A4a4 c4e4 |
"Dm"d4^c4 d2e2 f5 | "C"E2^D2 E2F2 G2A2 B4 | "Bb"d2c2 d4B4 z4 | "A7"A8 z8 :|
V:2 clef=treble name="Strings" subname="Staccato Strings"
%%MIDI program 49
|:"Dm"f8 f4a4 | "C"e8 e4g4 | "Bb"d8 d4g4 | "A7"c8 c4e4 |
"Dm"a8 a2g2 f5 | "C"g2f2 e2d2 c4z4 | "Bb"A2B2 c2A2 F4z4 | "A7"G,2C2 E2D2 B,4A,4 |
"Dm"f8 f4a4 | "C"e8 e4g4 | "Bb"d8 d4f4 | "A7"a8 c4e4 |
"Dm"a4g4 f2e2 d5 | "C"b2a2 g2f2 e2d2 c4 | "Bb"A4B4 c4A4 | "A7"G4A4 z8 :|
V:3 clef=bass name="Bass" subname="Bass"
%%MIDI program 33
|:"Dm"D,4D,4 D,4A,,4 | "C"C,4C,4 E,4C,4 | "Bb"B,,4B,,4 D,4B,,4 | "A7"A,4A,4 C,4E,4 |
"Dm"D,4D,4 F,2E,2 D,5 | "C"=B,,2C,2 D,4A,,4 z4 | "Bb"B,,4^A,,4 B,,4D,4 | "A7"A,8 z8 |
"Dm"D,4D,4 D,4A,,4 | "C"C,4C,4 E,4G,4 | "Bb"B,,4B,,4 D,4F,4 | "A7"A,8 z8 |
"Dm"D,4D,4 E,4D,4 C, | "C"C,2B,,2 C,4G,4 z4 | "Bb"B,,8 z8 | "A7"A,8 z8 :|



X:1
T:Sunrise on the Apocalypse
C:OrchestrAI
M:C
L:1/16
Q:1/4=40
K:Am
% We will start with the first voice leading the minimalist melody.
V:1 clef=treble
%% MIDI program 91
|: "Am"z4 z4 z2 E2 | z4 z4 z4  | "G"A2 z2 z4 z4 | z2 C2 z4 z4 |
"Em"G2 z2 z4 z2 B,2 | "Am"E2 z2 z4 z4 | z4 z2 A2 z4 | "F"z4 z4 z2 F2 :|
% For the second voice, we include sporadic syncopation.
V:2 clef=treble
%% MIDI program 91
|: "Am"z4 z4 z2 E2 | z4 z4 z4  | "G"A2 z2 z4 z4 | z2 C2 z4 z4 |
"Em"G2 z2 z4 z2 B,2 | "Am"E2 z2 z4 z4 | z4 z2 A2 z4 | "F"z4 z4 z2 F2 :|
% The third voice will introduce a bass counterpoint to the melody.
V:3 clef=bass
%% MIDI program 43
|: "Am"A,6 z2 .E4 | .A,4 .A,4 .A,2 .E2 | "G"B,,6 z2 .A,4 | .C,4 .C,4 .C,2 .B,,2 |
"Em".B,,4 .G,4 .E,2 .G,2 | "Am"A,6 z2 .E4 | .A,4 .A,4 .A,2 .C2 | "F"F,8 .F,4  :|
% For the fourth voice, the emphasis on dissonance and tone clusters.
V:4 clef=bass
%% MIDI program 43
|: "Am"C,4 E,4 A,,4  | A,4 C4 E,4  | "G"B,,4 D,4 G,,4  | G,4 B,,4 D,4  |
"Em"G,,4 E,4 B,,4  | A,,4 C4 E,4  | A,4 E,4 A,,4  | "F"F,4 F,4 C,4  :|



X:1
T:Insightful Journalism Prelude
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=110
K:C
V:1
%%MIDI program 11
|:"C"E2G2 c2e2|"Am"A4 E2A2|"F"F4 c2d2|"G"G4- G2z2|
"C"c2e2 "F"f2d2|"Am"e4 "Em"B2G2|"F"A6 G2|"C"G4- G2z2:|
V:2
%%MIDI program 44
||:"C"C2E2 G,2C2|"Am"A,4 E2A,2|"F"F,4 C2D2|"G"G,4- G,2z2|
"C"C2E2 "F"F2A,2|"Am"G,4 "Em"B,2E2|"F"A,6 G,2|"C"G,4- G,2z2:|
V:3
%%MIDI program 34
||:"C"C,2E,2 C2G,2|"Am"A,4 E2A,2|"F"F,4 C2F,2|"G"G,4- G,2z2|
"C"C,2E,2 "F"F,2C2|"Am"A,4 "Em"G2B,2|"F"F,6 E,2|"C"C4- C2z2:|



X:1
T:Yee Haw Wiggle
C:Lorn
M:12/8
L:1/8
Q:3/8=140
K:Bb
V:1 clef=treble
%%MIDI program 56
|: "Bb"G3 z2 G-G3 G2G | "Gm7"F3 G3 "C7"B2G z3 | "Bb/F"z2 B "F7"B2G B3 "Bb/G"G2G | "Gm7"F3 G3 "C7"B2G z3 |
"Bb"G3 z2 G-G3 G2G | "Gm7"F3 G3 "C7"B2G z3 | "Eb"z2 B B2G B3 "F7"B2c | "^F7"^c3 d3 "Bb"f2^f z3 :|
V:2 clef=treble
%%MIDI program 106
|:z6 z6 | "Bb" d3 "Bb/A" c3 "Bb/G" B3 z3 | "Gm7" d3 "C7/E" e3 "C7" g3    z3 | "Bb/F" f3 "F7/A" a3 "Bb/G" g3 z3 | "Gm7" b3 "C7/D" a3 "C7" g3 z3 |
"Bb" d3 "Bb/A" c3 "Bb/G" B3 z3 | "Gm7" d3 "C7/E" e3 "C7" g3    z3 | "Eb" G3 "F7/A"^A3 "F7/Bb" =B3 z3 :| "^F7"=A3 "Bb/d" d3 "^F7/f" f3 z3 ||
V:2 clef=bass
%%MIDI program 58
|: "Bb"G,,3 z2 D,,-D,,3 ^F,,3 | "Gm7" G,,3 z2 D,,-D,,3 ^F,,3 | "Bb/F" G,,3 z2 D,,-D,,3 ^F,,3 | "Gm7" G,,3 z2 D,,-D,,3 ^F,,3 |
"Bb" G,,3 z2 D,,-D,,3 ^F,,3 | "Gm7" G,,3 z2 D,,-D,,3 ^F,,3 | "Eb" D,,3 z2 A,,-A,,3 ^C,,3  | "Bb" G,,3 z2 D,,-D,,3 ^F,,3 :|



X:1
T:Dance of the Fireflies
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=110
K:Gmaj
V:1 clef=treble name="Glockenspiel" subname="Glock."
%%MIDI program 9
|:"G"B3 c d2|BGB "D"A3 F|G2 z EDE|"Em"G2 z "^sparkle"B3|
"G"B3 c d2|"C"cBc "D"A2D|"G"B,4 D2|"G"G3- G3|
"D"F3 A d2|"A7"cBA "D"FDA|"G"B3 c B2|"C"E3 G3|
"G"D3- D2 G|GAB "Bm"A2d|"Am"cBA "D7"BAG|"G"G4 z2:|
V:2 clef=treble name="Harp" subname="Harp"
%%MIDI program 46
|:"G"B2"G/D"d2 B2|"D" D3 F AF|"G" G3 B, G,2|"Em"E3 F GF|
"G"G2 "G/B"d2 B2|"C"c2 B "D"A3|"G"B,4 "G/B"D2|"G"G,G, G,2 z2|
"D"A2"A/C#"f2 a2|"A7"c2 "D/f+"a2 f2|"G"B2 d2 B2|"C"cB "E7"c2 e2|
"G"d2 "^glitter"B2 G2|"Bm"d3 B "Em"dB|"Am"cB "D7"AG F2|"G"G,4 z2:|
V:3 clef=bass name="String Ensemble" subname="Strings"
%%MIDI program 48
|:"G"G,2 B,2 z D|"D"A,2 F2 z A,|"G"G,2 B,2 z D|"Em"E,2 G,2 z B,|
"G"G,2 B,2 z D|"C"C2 E,2 z G,|"D"A,2 D2 z F,|"G"G,3- G,2 B,|
"D"D2 F2 z A,|"A7"A,3 E2 C|"D"D2 F2 "G/B"A,2|"C"C,2 E,2 G,2|
"G"G,2 D2 z B,|"Bm"B,2 D2 z F|"Am"A,2 E2 z C|"G"G,3- G,3:|



X:1
T:Colonel's Polka Promenade
C:OrchestrAI
M:2/4
L:1/8
Q:1/4=120
K:Bb
V:1 clef=treble name="Accordion"
%%MIDI program 21
|: "Bb"d2 f2 | g2 fb | "Eb"e2 gf | "F"f4 |
"Bb"d2 f2 | g2 fb | "Eb"e2 c'2 | "Bb"b2 z2 |
"Bb"d'b af | "Gm"g2 e2 | "Eb"eg ce | "F7"f2 fd |
"Bb"B2 Bc | "Gm"d2 cB | "Eb"A2 G2 | "Bb"B4 :|
V:2 clef=bass name="Tuba"
%%MIDI program 58
|: "Bb"B,2 D2 | F2 D2 | "Eb"G,2 B,2 | "F"F,2 C2 |
"Bb"B,2 D2 | F2 D2 | "Eb"G,2 B,2 | "F"C4 |
"Bb"B,2 D2 | F2 D2 | "Eb"G,2 E2 | "F7"F2 C2 |
"Bb"B,2 D2 | "Gm"G,2 B,2 | "Eb"G,2 F2 | "Bb"B,4 :|



X:1
T:Elegy for the Fallen
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=50
K:Bm
V:1 clef=treble name="Violin"
%%MIDI program 40
|:"Bm"D4 F2 | "Em"G4 E2 | "F#7"A3 F E2 | "Bm"B,4 z2 |
"D"A4 d2 | "Em"F3 E D2 | "G" G4 B2 | "A"A3 F F2 |
"Bm"D4 F2 | "G"G3 F E2 | "Em"E4 G2 | "A"A4 z2 |
"F#7"A4 c2 | "Bm"B4 A2 | "Em"G3 F G2 | "D"A4 z2 :|
V:2 clef=alto name="Viola"
%%MIDI program 41
|:"Bm"B,3 E D2 | "Em"E4 z2 | "F#7"F3 A c2 | "Bm"B,4 z2 |
"D"D3 F A2 | "Em"E4 z2 | "G"G,3 B, D2 | "A"A,4 z2 |
"Bm"B,3 E D2 | "G"G4 z2 | "Em"E3 G F2 | "A"A4 z2 |
"F#7"F3 A c2 | "Bm"B3 E D2 | "Em"E3 F G2 | "D"D4 z2 :|
V:3 clef=treble name="Cello"
%%MIDI program 42
|:"Bm"B,,4 D2 | "Em"E4 B,2 | "F#7"F2 A3 c | "Bm"B,,4 z2 |
"D"A,4 F2 | "Em"E4 G2 | "G"G,4 B,2 | "A"A,4 E2 |
"Bm"B,,4 D2 | "G"G4 B,2 | "Em"E2 G3 B | "A"A,4 E2 |
"F#7"F2 A3 c | "Bm"B,,4 D2 | "Em"E4 G2 | "D"D4 z2 :|



X:1
T:Ancient Waves
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=84
K:Em
V:1 clef=treble name="Flute"
%%MIDI program 73
|: "Em"B3 z B2 | "D"A3 F E2 | "C"G4 F2 | E6 | "Em"B3 z B2 | "D"A3 F E2 | "G"B4 A2 | "Em"E6 |
"Em"E3 F G2 | "Am"A4 F2 | "D"A3 F G2 | "Bm"B6 | "Em"G3 F E2 | "C"D3 E G2 | "Am"A4 G2 | "Em"E6 :|
V:2 clef=treble name="String Ensemble"
%%MIDI program 48
|: "Em"e3 g b2 | "D"f3 a d'2 | "C"g4 f2 | e6 | "Em"e3 g b2 | "D"f3 a d'2 | "G"g4 f2 | "Em"e6 |
"Em"e3 f g2 | "Am"a4 f2 | "D"d3 f a2 | "Bm"b6 | "Em"g3 f e2 | "C"c3 d g2 | "Am"a4 g2 | "Em"e6 :|
V:3 clef=bass name="Synth Pad"
%%MIDI program 91
|: "Em"B,6 | "D"A,6 | "C"G,6 | "E"B,6 | "Em"B,6 | "D"A,6 | "G"B,6 | "Em"E,6 |
"Em"E,4 G,2 | "Am"A,6 | "D"D,6 | "Bm"B,6 | "Em"G,6 | "C"C,6 | "Am"A,6 | "Em"E,6 :|
V:4 clef=bass name="Bassoon"
%%MIDI program 70
|: "Em"B,,4 E,2 | "D"A,,4 D,2 | "C"G,,4 C2 | "E"B,,6 | "Em"B,,4 E,2 | "D"A,,4 D,2 | "G"G,,4 B,,2 | "Em"E,6 |
"Em"E,4 G,2 | "Am"A,,4 A,2 | "D"F,4 D,2 | "Bm"B,,4 F,2 | "Em"G,,4 E,2 | "C"C,4 C2 | "Am"A,,4 A,2 | "Em"E,6 :|



X:1
T:Musical Tapestry
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=90
K:G
V:1 clef=treble name="Piano"
%%MIDI program 0
|: "G" D2 G2 B3  | w: Peaceful Intro
"G" c3    | w: Ebb and flow
"C" E3    | w: Gentle breeze
"D7" F    | w: Rising sun
"G" D2 G2 B2  | w: New day
"G" c5    | w: Quiet thought
"Am" A2 E2 A  | w: Whispered secret
"D7" B3    | w: Lingering echo
"G" G2  :| w: Soft conclusion
Q:1/4=120
K:C
V:2 clef=treble name="Flute"
%%MIDI program 73
|: "C" e2 | w: Lively Passage
"Cmaj7" e3   |
|:"Am" a2 f2 e2 |
|:"F" d2 c2 B2 |
|:"G" e2 d2 c2 |
|:"C" E2 G2 E2 |
|:"F" f2 e2 d2 |
|:"Am" E2 G2 z2 |
"F" A2 c2 B2 :|
"G" G3 z z2 :| w: Dance away
Q:1/4=90
K:Em
V:3 clef=treble name="Violin"
%%MIDI program 40
|: "Em" B2 G2 E2 | w: Introspection
"Am" c2 A3  |
|:"D" d3 B A2 |
|:"G" B3 G E2 |
|:"Em" B2 A2 G2 |
|:"C" E2 F2 G2 |
|:"B7" F2 D2 F2 |
"Em" E6 :| w: Deep consideration
Q:1/4=130
K:D
V:4 clef=treble name="Horns"
%%MIDI program 60
|: "D" F2 A2 d2 | w: Triumphant End
"D/C#" F2 E2  |
|:"Bm" d2 f2 a2 |
|:"A" e2 c2 A2 |
|:"G" D2 B2 G2 |
|:"D" A3 F2 A |
|:"G" B2 d2 B2 |
"D" A6 :| w: Victorious finish



X:1
T:Glory of the Battle
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=165
K:Dmin
V:1 clef=treble name="Trumpets"
%%MIDI program 61
|: "Dm"D2^CD D4 F4 A2d2 | "Gm"G2G2 A2B2 c2d2 e2^fg | "A7"a2^gf e4 c4 A2^G2 | "Dm"d2D2 F2A2 d4 D4 :|
|: "F"c2 cA F4 D4 C2 C2 | "Bb"d2 dA F4 D4 c2 B2 | "Gm"B2 AB G4 E4 D2 D2 | "A7"E2 E2 E4 c2 e2 e2 z2 :|
|: "Dm"A2 AF D4 F4 A2 d2 | "Gm"g2 g2 g4 e2 d2 G2 z2 | "A7"a2^g2 f2e2 d2c2 A2^G2 | "Dm"d2d2 a2^ga d4 d4 :|
V:2 clef=treble name="Strings"
%%MIDI program 49
K:Dm
|: "Dm"D,2A,2 D8 A,4 z4 | "Gm"G,2D2 G8 D4 z4 | "A7"A,2E2 A,8 E4 z4 | "Dm"D,2A,2 F8 A,8 :|
|: "F"c4 c8 c2 F8 | "Bb"B,4 B,8 B,2 B,8 | "Gm"G,2D2 G8 G,8 | "A7"e2 A,4 E8 A,8 :|
|: "Dm"D,2A,2 D8 F8 A,8 | "Gm"G,2D2 G8 e8 D8 | "A7"A,2E2 A,8 c8 E8 | "Dm"D,2A,2 d8 F8 A,8 :|
V:3 clef=treble name="Synth Lead"
%%MIDI program 81
|: "Dm"f3e d2F2 A4 f4 | "Gm"g3f g2B2 d4 g4 | "A7"a3^g a2c2 e4 a4 | "Dm"d3c d2F2 A4 d4 :|
|: "F"c3B c2F2 A4 c4 | "Bb"b3a b2d2 f4 b4 | "Gm"g3f g2B2 d4 g4 | "A7"e3^d e2E2 c4 e4 :|
|: "Dm"f3e d2F2 A4 f4 | "Gm"g3f g2B2 d4 g4 | "A7"a3^g a2c2 e4 a4 | "Dm"d3c d2F2 A4 d4 :|
V:4 clef=bass name="Bass" octave=-1
%%MIDI program 43
|: "Dm"D,8 A,8 D,  | "Gm"G,8 D8 G,  | "A7"A,8 E8 A,  | "Dm"D,16 A,16 z4 :|
|: "F"F,16 C16 F,15 1 | "Bb"B,,16 F,16 B,,15 1 | "Gm"G,16 D16 G,15 1 | "A7"A,16 E16 A,15 1 :|
|: "Dm"D,8 F,8 A,  | "Gm"G,8 B,8 e  | "A7"A,8 c8 e  | "Dm"D8 F8 A 1 :|"



X:1
T:Test
Z:Arr.  Joel Breit
Z:Polk the Bear
%%score (1 2)
L:1/8
Q:1/2=80
M:4/4
K:Gm
V:1 treble nm="Harmonica" snm="Harm."
L:1/8
V:1
"D7" d2 AB cd/c/ BA |"Gm" G2 GB d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
$"Cm" z c2 e g2 fe | "Bb" z d2 B d2 cB |"D7" A3 B c2 d2 |"Gm" B2 G2 G2 z2 |
$"Gm" d4 B4 |"D7" c4 A4 |"Gm" B4 G4 |"D7" ^F4 A4 |
$"Gm" d4 B4 |"D7" c4 A4 |"Bb" B2 d2 g4 |"D7" ^f8 :|
V:2 bass clef=bass nm="Tuba" snm="Tba."
L:1/8
V:2
"D7" [D,2F,2] [D,2A,2] | "Gm" [G,,2G,2] [D,2G,2] | "D7" [A,,2C2] [A,,2C2] | "Gm" [G,,2D,2] [G,,2D,2] |
$"Cm" [C,2G,2] [C2E2] | "Bb" [B,,2F,2] [B,,2D2] | "D7" [A,,2C2] [A,,2C2] | "Gm" [G,,2D,2] [G,,2D,2] |
$"Gm" [G,,2D,2] [G,2B,2] | "D7" [A,,2^F,2] [A,,2C2] | "Gm" [G,,2D,2] [G,2B,2] | "D7" [A,,2^F,2] [A,,2C2] |
$"Gm" [G,,2D,2] [G,2B,2] | "D7" [A,,2^F,2] [A,,2C2] | "Bb" [B,,2D,2] [B,,2F,2] | "D7" [A,,2^F,2] [A,,2C2] :|



X: 1
T: The Enchanted Valley with Second Voice
M: 2/4
L: 1/16
K: Gm
V:1
|: "Gm"G3A (Bcd=e) | f4 (g2dB) | ({d}c3B) G2E2 | F4 (D2=E^F) |
"Dm"G3A (Bcd=e) | f4 d2f2 | (g2a2 b2).g2 | {b}(a2g2 f2).d2 |
"Gm"(d2{ed}c2) B2B2 | (A2G2 {A}G2F2) | "D7"(GABc) (d2{ed}cA) | "Gm"G2G2 G2z2 |
"Gm"G2 | "Cm"B2c2 (dcAB) | "Gm"G2G2 G2G2 | "Cm"B2d2 (gfdc) | "D7"d2g2 (g3ga) |
"Gm"(bagf) (gd)(dc) | "F"(B2AG) F2D2 | "Bb"(GABc) (d2d2) | "D"(bgfd) cAF2 |
"Gm"G2A2 (B2{cB}AG) | "D7"A3G F2D2 | "Gm"(GABc) (d2{ed}cA) | G2G2 G2z2 :|
|: "Dm"A2^F2 (ABAF) | "Cm"G4- G2G2 | "Gm"A2B2 (c2BA) | G6- G2 |
"F"A2c2 (d2cB) | "Bb"d4- d3d | "Eb"(cBAG) "F"(AFGA) | "Gm"B4- B2z2 |
"Gm"(d>e)(dc) B2(A>B) | "Cm"c4- c2c2 | "Gm"(d>e)(dc) B2(A>B) | "D7"A4- A2A2 |
"Gm"B2A2 "Cm"G2^F2 | "D7"G6- G2 | "Gm"BAGF "D"(G2^F2) | "Gm"G4 G4 :|
V:2
|: "Gm"D3E (FGA=B) | c4 (d2AF) | ({A}B3A) D2C2 | G,4 (B,2^C=E) |
"Dm"D3E (FGA=B) | c4 A2c2 | (d2e2 f2).d2 | {f}(e2d2 c2).A2 |
"Gm"(A2{BA}B2) D2D2 | (F2E2 {F}E2G,2) | "D7"(D=EFG) (A2{BA}BF) | "Gm"D2D2 D2z2 |
"Gm"D2 | "Cm"F2G2 (FEDC) | "Gm"D2D2 D2D2 | "Cm"F2A2 (cABG) | "D7"A2d2 (d3d=f) |
"Gm"(gfed) (dc)(cd) | "F"(FA^GF) G2B2 | "Bb"(D=EFG) (A2A2) | "D"(dcAF) G,AC2 |
"Gm"D2E2 (D2{ED}CD) | "D7"F3E G2B,D= | "Gm"(D=EFG) (A2{BA}BF) | D2D2 D2z2 :|
|: "Dm"F2^C2 (FED=C) | "Cm"D4- D2D2 | "Gm"F2G2 (A2FG) | D6- D2 |
"F"F2A2 (G2FE) | "Bb"c4- c3c | "Eb"(B,AGF) "F"(GCEG) | "Gm"c4- c2z2 |
"Gm"(A>B)(AB) D2(F>G) | "Cm"F4- F2F2 | "Gm"(A>B)(AB) D2(F>G) | "D7"F4- F2F2 |
"Gm"D2C2 "Cm"F2^C2 | "D7"D6- D2 | "Gm"DFED "D"(D2^C2) | "Gm"D4 D4 :|



X:1
T:Pirate's Polka Galore
C:OrchestrAI
M:2/4
L:1/16
Q:1/4=120
K:Dmaj
V:1 clef=treble name="Fiddle"
%%MIDI program 110
|: "D"A2 | "D"d2f2 a2A2 | "G"B2d2 g2B2 | "D"A2F2 A2d2 | "A7"c2e2 a2c'2 |
"D"d2f2 a2A2 | "G"B2d2 g2B2 | "A7"A2c2 e2c2 |1 "D"d4- d2 z2 :|2 "D"d4- d2 z2 ||
|: "Bm"f2 | "Bm"b2f2 b2a2 | "A"A2e2 "G"B2d2 | "D"f2A2 d2F2 | "E7"G2B2 e2G2 |
"A"a2e2 "G"f2d2 | "D"f2A2 d2f2 | "A7"e2A2 g2f2 |1 "E7"e4- e2 z2 :|2 "A7"a4- a2 z2 |]
V:2 clef=treble name="Accordion"
%%MIDI program 21
|: "D"fg | "D"a2f2 d2A2 | "G"g2e2 B2d2 | "D"fga2 A2d2 | "A7"e2c2 A2e2 |
"D"f2d2 "A7"e2A2 | "G"g2B2 d2g2 | "A7"eag2 c2e2 |1 "D"d4- d2 z2 :|2 "D"a4- a2 z2 ||
|: "Bm"af | "Bm"b2a2 f3g | "A"e4 "G"g4 | "D"ABAG F3A | "E7"G4 F2E2 |
"A"E4 "G"B4 | "D"D2E2 F2G2 | "A7"A2c2 A2G2 |1 "E7"E4 E2 z2 :|2 "A7"e4 e2 z2 |]
V:3 clef=bass name="Tuba"
%%MIDI program 58
|: "D"D2 | "D"[D,F]2 [D2F2] [D2F2] z3 | "G"[G,B,]2 [G2B2] [G2B2] z3 | "D"[D2F2] [D,F]2 [D2A,2] [D,A,]2 z2 |
"A7"[E2A,2] [E2C2] [E2A,2] [E2C2] | "D"[D2F2] [D,F]2 [D2A,2] [D,A,]2 z2 | "G"[G2B2] [G,B,]2 [D2G,2] [DG,]2 z2 |
"A7"[E2C2][E2A,2] [E2C2] [E2A,2] |1 "D"[D2F2] [D2A,2] [D2A,2] [D2F2] :|2 "D"[D2A,2] [D2A,2]- [D2A,2] z2 ||
|: "Bm"A2 | "Bm"[B,2F2] [B,2F2] [B,2A,2] [B,2A,2] | "A"[A,2E2] [A,2E2] [A,2E2] [A,2E2] |
"G"[G,2B,2] [G,2B,2] [D2G,2] [D2G,2] | "E7"[E2G,2] [E2G,2] [E2B,2] [E2B,2] |
"A"[A,2E2][A,2E2] "G"[G,2B,2] [G,2B,2] | "D"[D2F2] [D2A,2] [D2F2] [D2A,2] |
"A7"[A,2E2] [A,2E2] c2 A2- |1 "E7"[E2B,2] [E2B,2] [E2B,2] [E2G,2] :|2 "A7"[A,2E2] [A,2E2] [A,2E2] [A,2C2] |]



X:1
T:Epic Boss Confrontation
C:OrchestrAI
M:12/8
L:1/16
Q:1/4=140
K:Em
% The fast-paced rhythm and orchestral arrangement create a gripping atmosphere
V:1 clef=treble name="Brass" subname="Main Melody"
%%MIDI program 61
|: "Em"G4B4 e2 d4c5  | "D"A4^F4 A2 G4^F5  | "C"G4E4 G2 F4D5  | "Bm"B2A2G2 F4A4 B2 z2 |
"Em"e4G4 B2 e4G5  | "D"f4A4 d2 f4A5  | "C"g4B4 e2 g4B5  | "Bm"a8- a2 b4^f5  :|
V:2 clef=treble name="Strings" subname="Rhythmic Drive"
%%MIDI program 49
|: "Em"e2 z4 e2 z4 e2 z4 | "D"d2 z4 d2 z4 d2 z4 | "C"c2 z4 c2 z4 c2 z4 | "Bm"b2 z4 b2 z4 b2 z4 |
"Em"e2 z4 e2 z4 e2 z4 | "D"f2 z4 f2 z4 f2 z4 | "C"g2 z4 g2 z4 g2 z4 | "Bm"a2 z4 a2 z4 a2 z4 :|
V:3 clef=bass name="Bass"
%%MIDI program 43
|: "Em"E,4E,4 E,4E,4 E,3 | "D"D,4D,4 D,4D,4 D,3 | "C"C,4C,4 C,4C,4 C,3 | "Bm"B,4B,4 B,4B,4 B,3 |
"Em"E,4E,4 E,4E,4 E,3 | "D"D,4D,4 D,4D,4 D,3 | "C"C,4C,4 C,4C,4 C,3 | "Bm"B,4B,4 B,4B,4 B,3 :|
V:4 clef=treble-8 name="Percussive Synth"
%%MIDI program 118
|: "Em"[^G,^G,]8 [E,E,]8 z | "D"[^F,^F,]8 [D,D,]8 z | "C"[G,G,]8 [C,C,]8 | "Bm"[A,A,]8 [B,,B,,]8 |
"Em"[^G,^G,]8 [E,E,]8 z | "D"[^F,^F,]8 [D,D,]8 z | "C"[G,G,]8 [C,C,]8 | "Bm"[A,A,]8 [B,,B,,]8 :|



X:1
T:Jurassic Echoes
C:OrchestrAI
M:3/4
L:1/8
Q:1/4=70
K:Em
V:1 clef=treble name="Horn"
%%MIDI program 61
|:"Em" B,2 G,2 E2 | B,3 G, E2 | "D" F2 A,2 D2 | F3 A, D2 | "C" E2 G,2 C2 | E3 G, C2 | "Bm" D2 F2 B,2 | D3 F B,2 |
"Am" C2 E2 A,2 | C3 E A,2 | "G" B,2 D2 G,2 | B,3 D G,2 | "Em" B,2 G,2 E2 | "D" F2 A,2 D2 | "C" E4 z2 | "B7" B,6 :|
V:2 clef=bass name="Tuba"
%%MIDI program 58
|:"Em" E,4 z2 | E,4 z2 | "D" D4 z2 | D4 z2 | "C" C4 z2 | C4 z2 | "Bm" B,4 z2 | B,4 z2 |
"Am" A,4 z2 | A,4 z2 | "G" G,4 z2 | G,4 z2 | "Em" E,4 z2 | "D" D4 z2 | "C" C4 z2 | "B7" B,6 :|
V:3 clef=perc stafflines=1 name="Temple Blocks"
%%MIDI program 115
|:"Em" [K:perc] zEzG | zEzGzE | "D" zDzFzD | zDzFzD | "C" zCzEzC | zCzEzC | "Bm" zB, zD zB, | zB, zD zB, |
"Am" zA, zE zA, | zA, zE zA, | "G" zG, zB, zG, | zG, zB, zG, | "Em" zEzGzE | "D" zDzFzD | "C" zCzEzC | "B7" zB, zB, zB, :|



X:1
T:Bandstand Frolic
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=144
K:Cmaj
V:1 clef=treble
%%MIDI program 56
|:"C"G8- G4 z4 | "G"E8- E4 z4 | "Am"c8- c4 "F"d4  | "G"B8- B4 z4 |
"C"e2e2 e4 d2c2 z4 | "G"B2B2 B4 B4 z4 | "C"G2G2 G2E2 "F"F2D2 z4 |1 "G"E8- E4 z4 :|2 "G"E8- E4 "C"c4  |
K:Cmaj
V:2 clef=treble
%%MIDI program 71
|:"C"c8- c4 z4 | "G"g8- g4 z4 | "Am"e8- e4 "F"F4  | "G"d8- d4 z4 |
"C"G2G2 G4 F2E2 z4 | "G"d2d2 d4 d4 z4 | "C"c2c2 c2A2 "F"A2F2 z4 |1 "G"g8- g4 z4 :|2 "G"g8- g4 "C"G4  |
K:Cmaj
V:3 clef=bass
%%MIDI program 70
|:"C"C,8 C,8 | "G"G,8 G,8 | "Am"A,8 A,8 | "F"F,8 F,8 |
"C"C,8 z8 | "G"G,8 z8 | "C"C,8 z8 |1 "G"G,8 z8 :|2 "G"G,8- G,4 "C"C,4  |
K:Cmaj
V:4 clef=bass
%%MIDI program 33
|:"C"E,16 | "G"B,16 | "Am"C16 | "F"A,16 |
"C"C,8 E,8 | "G"G,8 B,8 | "C"C,8 E,8 |1 "G"G,8 B,8 :|2 "G"G,8 "C"G,4 C4 |
[| "C"C4z4 C4z4 | "G"B,4z4 D4z4 | "Am"A4z4 E4z4 | "F"F4z4 A4z4 |
"C"G,4C4 E4G4 | "G"B,4D4 G4B4 | "C"c4e4 g4c'4 | "G"d4b4 g4e4 |
"C"C4G4 E4C4 | "F"A4F4 C4A,4 | "C"G,4C4 E4G4 | "C"C,4z4 C4z4 |]



X:1
T:This Week in Google - Podcast Intro
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=132
K:Cmaj
V:1 clef=treble
%%MIDI program 81
|: "C"c4 E2 G2 | "G"B3 A G2 E2 | "Am"A6 z2 | "F"F3 E D2 C2 |
| "C"G2 E2 C4 | "G"z B2 D4 z | "Am"E4 "Em"B3 G |1 "F"F4 z4 :|2 "F"F4 z2 z2 |
V:2 clef=treble
%%MIDI program 81
|: [E2G2]z2 z2 [C2E2]
| [D2B,2]z2 [A,2D2]
z2 | [C2E2] z2 z4 | [F2A2] z2 z4 |
| [G2B,2]z2 z2 [E2G2]
| z4 z4 | [E2G2] z2 [B,2G2] z2 |1 z4 z4 :|2 z4 z2 z2 |
V:3 clef=bass
%%MIDI program 38
|: "C"C,6 z2 | "G"B,,6 z2 | "Am"A,,4 "Em"G,,4 | "F"F,,6 z2 |
| "C"C,,4 E,4 | "G"G,,4 z2 z2 | "Am"A,,4 "Em"B,,4 |1 "F"F,,4 z4 :|2 "F"F,,4 z2 z2 |



X:2
T:A Journey Through Time
C:OrchestrAI
M:4/4
L:1/8
Q:1/4=120
K:C
V:1 clef=treble name="Oboe"
%%MIDI program 68
"C" C4 | "G" G4 | "Am" A4 c4 z4 | "F" F4 |
"C" C4 | "G" G4 | "Am" A4 c4 z4 | "F" F4 |
"C" C4 | "G7" G4 c4 z4 | "C" C4 | "G7" G4 c4 z4 |
"F" F4 | "Am" A4 c4 z4 | "Dm" D4 f4 z4 | "G7" G4 c4 z4 |
V:2 clef=treble name="Trumpet"
%%MIDI program 56
"C" C5 | "G" G5 | "Am" A5 c5 z2 | "F" F5 |
"C" C5 | "G" G5 | "Am" A5 c5 z2 | "F" F5 |
"C" C5 | "G7" G5 c5 z2 | "C" C5 | "G7" G5 c5 z2 |
"F" F5 | "Am" A5 c5 z2 | "Dm" D5 f5 z2 | "G7" G5 c5 z2 |
V:3 clef=alto name="English Horn"
%%MIDI program 69
"C" e4 d4 c4 | "G" g4 f4 e4 | "Am" a4 g4 f4 | "F" f4 e4 d4 |
"C" e4 d4 c4 | "G" g4 f4 e4 | "Am" a4 g4 f4 | "F" f4 e4 d4 |
"C" e4 d4 c4 | "G7" g4 f4 e4 | "C" e4 d4 c4 | "G7" g4 f4 e4 |
"F" f4 e4 d4 | "Am" a4 g4 f4 | "Dm" d4 f4 e4 | "G7" g4 f4 e4 |
V:4 clef=tenor name="Bassoon"
%%MIDI program 70
"C" C3 | "G" G3 | "Am" A3 c3 z6 | "F" F3 |
"C" C3 | "G" G3 | "Am" A3 c3 z6 | "F" F3 |
"C" C3 | "G7" G3 c3 z6 | "C" C3 | "G7" G3 c3 z6 |
"F" F3 | "Am" A3 c3 z6 | "Dm" D3 f3 z6 | "G7" G3 c3 z6 |



X:1
T:Intensity of Remembrance
C:OrchestrAI
M:4/4
L:1/16
Q:1/4=72
K:Bm
%%staves [V1 V2 V3]
V:1 clef=treble name="Violin I"
%%MIDI program 40
|:"Bm"[B4d4] (3(ded) c2B2 A4 z4 | "F#m"[c4A4] (3(cBA) G2F2 E4 z4 | "G"[d4B4] G4 F4 E4 | "A"E4 (3(cBA) F2E2 D4 z4 |
"Bm"D4 (3(FFF) G4 A3B c4 | "Em"[B2b4] (3(gfe) f2e2 (3(ede) | "F#m"[A2c4] (3(ABA) G4 F4 z6 |1 "Bm"[B4d4] E4 F2G2 A3 z :|2 "Bm"[B4d4] E4 z2 z6 || [B4d4] |:
V:2 clef=treble name="Violin II"
%%MIDI program 40
|:"Bm"[f2B2] (3(FBF) E2f2 d4 z6 | "F#m"[e2A2] (3(EAE) D2e2 c4 z6 | "G"[d2B2] (3(DBD) C2d2 B4 z6 | "A"B4 (3(GGF) A2B2 c4 z4 |
"Bm"c4 (3(eee) d4 (3(cBA) B4 z4 | "Em"[f2b2] (3(dcB) c2B2 A4 z6 | "F#m"[e2c2] (3(BAB) C4 B4 z6 |1 "Bm"[f2B2] d4 (3(dcd) e4 z6 :|2 "Bm"[f2B2] d4 || z4 |:
V:3 clef=bass name="Cello"
%%MIDI program 42
|:"Bm"B,4 D2B,2 F3E D4 | "F#m"A,4 C2A,2 E3D C4 | "G"B,4 D2B,2 G3F E4 | "A"E4 C2A,2 F2E2 D4 |
"Bm"D3E F4 A,2B,2 C4 | "Em"B,4 (3(GFE) F2E2 D4 z4 | "F#m"A,4 (3(ABA) G2F2 E4 z4 |1 "Bm"B, 2 B,2 A,2B,2 c3d B,4 :|2 "Bm"B,2B,2 A,2B,2 z8 || D4 |:`;

export default AllCreations;