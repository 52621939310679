import React from "react";
import {
	Button,
	Card,
	CardBody,
	CardImg,
	CardText,
	CardTitle,
	Col,
	Container,
	Row,
} from "reactstrap";

import ABCEditorImage from "../assets/images/ABCEditor.png";
import JuxComposeImage from "../assets/images/JuxCompose.png";
import OrcasOpusImage from "../assets/images/OrcasOpusImage.png";
import OrcheImage from "../assets/images/Orche.png";
import VictoriousFanfareImage from "../assets/images/VictoriousFanfare.png";

const HomeContent = () => {
	return (
		<Container className="mt-5">
			<div className="text-center my-5">
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{/* <img src={OrcheImage} width="70" height="70" alt="Orche" />{" "} */}
					<h1>OrchestrAI</h1>
				</div>
				<p className="lead">
					OrchestrAI is a tool that uses large language models like
					GPT-4 to help generate and refine music compositions in ABC
					notation. This notation style allows for ledgeable and
					editable music that can be viewed and played in the browser,
					shared with others, or exported to MIDI.
				</p>
				<Button
					color="primary"
					href="/compose"
					className="primary-button mr-2"
				>
					Compose Now{" "}
					<span className="icon-square flex-shrink-0">
						<i className={`bi bi-music-note-beamed`}></i>
					</span>
				</Button>
			</div>
			{/* TODO - lots of duplicate code here */}
			<Row className="justify-content-center" style={{ display: "flex" }}>
				<Col xl={4} md={6} style={{ display: "flex" }}>
					<Card className="my-2" style={{ flex: 1 }}>
						<CardImg
							top
							width="100%"
							src={OrcasOpusImage}
							alt="OrchestrAI Image"
						/>
						<CardBody>
							<CardTitle tag="h5">
								Can Language Models Write Music?
							</CardTitle>
							<CardText>
								This 2023 paper explored the possibility of
								composing original music using the GPT-4
								assistants API.
							</CardText>
							<Button
								color="secondary"
								href="/Article.pdf"
								className="primary-button"
							>
								View Article{" "}
								<span className="icon-square flex-shrink-0">
									<i className={`bi bi-journal-text`}></i>
								</span>
							</Button>
						</CardBody>
					</Card>
				</Col>
				<Col xl={4} md={6} style={{ display: "flex" }}>
					<Card className="my-2" style={{ flex: 1 }}>
						<CardImg
							alt="Music Battle Image"
							src={JuxComposeImage}
							top
							width="100%"
						/>
						<CardBody>
							<CardTitle tag="h5">JuxCompose</CardTitle>
							<CardText>
								The JuxCompose tool allows you to compare
								multiple ABC notation compositions side-by-side.
							</CardText>
							<Button
								color="secondary"
								href="/juxcompose"
								className="primary-button"
							>
								Try It Out{" "}
								<span className="icon-square flex-shrink-0">
									<i className={`bi bi-columns-gap`}></i>
								</span>
							</Button>
						</CardBody>
					</Card>
				</Col>
				<Col xl={4} md={6} style={{ display: "flex" }}>
					<Card className="my-2" style={{ flex: 1 }}>
						<CardImg
							alt="Music Battle Image"
							src={ABCEditorImage}
							top
							width="100%"
						/>
						<CardBody>
							<CardTitle tag="h5">ABC Editor</CardTitle>
							<CardText>
								Have a composition from elsewhere? Use our ABC
								Editor to load and edit your tunes.
							</CardText>
							<Button
								color="secondary"
								href="/abcEditor"
								className="primary-button"
							>
								Try It Out{" "}
								<span className="icon-square flex-shrink-0">
									<i className={`bi bi-pencil-square`}></i>
								</span>
							</Button>
						</CardBody>
					</Card>
				</Col>
				<Col xl={4} md={6} style={{ display: "flex" }}>
					<Card className="my-2" style={{ flex: 1 }}>
						<CardImg
							alt="Sheet Music Image"
							src={VictoriousFanfareImage}
							top
							width="100%"
						/>
						<CardBody>
							<CardTitle tag="h5">Portfolio</CardTitle>
							<CardText>
								View some of the best compositions generated by
								OrchestrAI on the portfolio page.
							</CardText>
							<Button
								href="/portfolio"
								className="primary-button"
							>
								View{" "}
								<span className="icon-square flex-shrink-0">
									<i className={`bi bi-trophy`}></i>
								</span>
							</Button>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default HomeContent;
